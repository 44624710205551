<div *ngIf="isValidHtml && !bphChange.hide && bphChange.globalShowDetail"
     [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'">
  <div *ngIf="!showAdditionalConfirmation" style="padding-bottom: 20px">
    <button (click)="executeOnClickJs()" *ngIf="!bphChange.isShowInfoText"
            [attr.aria-label]="confChange.label[selectedLanguage]"
            [disabled]="clickedOneTime || bphChange.isDisabled || bphChange.globalReadOnlyMode === 'true'"
            [ngClass]="isLastPosition ? 'zeroRightMargin' : ''"
            [style.background-color]="(bphChange.isDisabled || bphChange.globalReadOnlyMode === 'true') ? '' : confChange.buttonColor"
            class="eCase-dialog-positive-action-button" mat-raised-button>
      <span *ngIf="!bphChange.isDisabled">{{confChange.buttonText[selectedLanguage]}}</span>
      <span
        *ngIf="bphChange.isDisabled">{{confChange.disabledButtonText[selectedLanguage] ? confChange.disabledButtonText[selectedLanguage] : confChange.buttonText[selectedLanguage]}}</span>
    </button>
    <ng-container *ngIf="bphChange.isShowInfoText">
      <span [innerHTML]="bphChange.infoText[selectedLanguage]"></span>
    </ng-container>
    <ng-container *ngIf="bphChange?.error[selectedLanguage]">
      <br>
      <div style="margin-top: 10px"><span class="tableFormFieldError">{{bphChange.error[selectedLanguage]}}</span></div>

    </ng-container>
  </div>
  <div *ngIf="showAdditionalConfirmation" style="padding-bottom: 20px">
    <button (click)="openDialog()" *ngIf="!bphChange.isShowInfoText"
            [attr.aria-label]="confChange.label[selectedLanguage]"
            [disabled]="clickedOneTime || bphChange.isDisabled || bphChange.globalReadOnlyMode === 'true'"
            [ngClass]="isLastPosition ? 'zeroRightMargin' : ''"
            [style.background-color]="(bphChange.isDisabled || bphChange.globalReadOnlyMode === 'true') ? '' : confChange.buttonColor"
            class="eCase-dialog-positive-action-button" mat-raised-button>
      <span *ngIf="!bphChange.isDisabled">{{confChange.buttonText[selectedLanguage]}}</span>
      <span
        *ngIf="bphChange.isDisabled">{{confChange.disabledButtonText[selectedLanguage] ? confChange.disabledButtonText[selectedLanguage] : confChange.buttonText[selectedLanguage]}}</span>
    </button>
    <ng-container *ngIf="bphChange.isShowInfoText">
      <span [innerHTML]="bphChange.infoText[selectedLanguage]"></span>
    </ng-container>
    <ng-container *ngIf="bphChange?.error[selectedLanguage]">
      <br>
      <span class="tableFormFieldError">{{bphChange.error[selectedLanguage]}}</span>
    </ng-container>
  </div>
</div>

<td *ngIf="isValidTable" [id]="'td_' + confChange.name + '_' + rowNumber"
    [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
    style="border:0;width: 10%;padding-right:10px;padding-left:10px">
  <div *ngIf="!showAdditionalConfirmation" class="field {{bphChange.error_class}} hide_{{bphChange.show}}">
    <button (click)="executeOnClickJs()" *ngIf="!bphChange.isShowInfoText"
            [attr.aria-label]="confChange.label[selectedLanguage]"
            [disabled]="clickedOneTime || bphChange.isDisabled || bphChange.globalReadOnlyMode === 'true'"
            [ngClass]="isLastPosition ? 'zeroRightMargin' : ''"
            [style.background-color]="(bphChange.isDisabled || bphChange.globalReadOnlyMode === 'true') ? '' : confChange.buttonColor"
            class="eCase-dialog-positive-action-button" mat-raised-button>
      <span *ngIf="!bphChange.isDisabled">{{confChange.buttonText[selectedLanguage]}}</span>
      <span
        *ngIf="bphChange.isDisabled">{{confChange.disabledButtonText[selectedLanguage] ? confChange.disabledButtonText[selectedLanguage] : confChange.buttonText[selectedLanguage]}}</span>
    </button>
    <ng-container *ngIf="bphChange.isShowInfoText">
      <span [innerHTML]="bphChange.infoText[selectedLanguage]"></span>
    </ng-container>
    <ng-container *ngIf="bphChange?.error[selectedLanguage]">
      <br>
      <span class="tableFormFieldError">{{bphChange.error[selectedLanguage]}}</span>
    </ng-container>
  </div>
  <div *ngIf="showAdditionalConfirmation" class="field {{bphChange.error_class}} hide_{{bphChange.show}}">
    <button (click)="openDialog()" *ngIf="!bphChange.isShowInfoText"
            [attr.aria-label]="confChange.label[selectedLanguage]"
            [disabled]="clickedOneTime || bphChange.isDisabled || bphChange.globalReadOnlyMode === 'true'"
            [ngClass]="isLastPosition ? 'zeroRightMargin' : ''"
            [style.background-color]="(bphChange.isDisabled || bphChange.globalReadOnlyMode === 'true') ? '' : confChange.buttonColor"
            class="eCase-dialog-positive-action-button" mat-raised-button>
      <span *ngIf="!bphChange.isDisabled">{{confChange.buttonText[selectedLanguage]}}</span>
      <span
        *ngIf="bphChange.isDisabled">{{confChange.disabledButtonText[selectedLanguage] ? confChange.disabledButtonText[selectedLanguage] : confChange.buttonText[selectedLanguage]}}</span>
    </button>
    <ng-container *ngIf="bphChange.isShowInfoText">
      <span [innerHTML]="bphChange.infoText[selectedLanguage]"></span>
    </ng-container>
    <ng-container *ngIf="bphChange?.error[selectedLanguage]">
      <br>
      <span class="tableFormFieldError">{{bphChange.error[selectedLanguage]}}</span>
    </ng-container>
  </div>
</td>


<td *ngIf="isValidTableReadonly  && !bphChange.hideCell && !confChange.hideCell"
    [attr.rowspan]="bphChange.rowspan"
    [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
    style="border:0;width: 1%;padding-left:10px;padding-right:10px">
  <div *ngIf="!showAdditionalConfirmation" class="field hide_{{bphChange.show}} {{bphChange.error_class}}">

    <button (click)="executeOnClickJs()" *ngIf="!bphChange.isShowInfoText"
            [attr.aria-label]="confChange.label[selectedLanguage]"
            [disabled]="clickedOneTime || bphChange.isDisabled || bphChange.globalReadOnlyMode === 'true'"
            [ngClass]="isLastPosition ? 'zeroRightMargin' : ''"
            [style.background-color]="(bphChange.isDisabled || bphChange.globalReadOnlyMode === 'true') ? '' : confChange.buttonColor"
            class="eCase-dialog-positive-action-button" mat-raised-button>
      <span *ngIf="!bphChange.isDisabled">{{confChange.buttonText[selectedLanguage]}}</span>
      <span
        *ngIf="bphChange.isDisabled">{{confChange.disabledButtonText[selectedLanguage] ? confChange.disabledButtonText[selectedLanguage] : confChange.buttonText[selectedLanguage]}}</span>
    </button>
    <ng-container *ngIf="bphChange.isShowInfoText">
      <span [innerHTML]="bphChange.infoText[selectedLanguage]"></span>
    </ng-container>
    <ng-container *ngIf="bphChange?.error[selectedLanguage]">
      <br>
      <span class="tableFormFieldError">{{bphChange.error[selectedLanguage]}}</span>
    </ng-container>
  </div>
  <div *ngIf="showAdditionalConfirmation" class="field hide_{{bphChange.show}} {{bphChange.error_class}}">

    <button (click)="openDialog()" *ngIf="!bphChange.isShowInfoText"
            [attr.aria-label]="confChange.label[selectedLanguage]"
            [disabled]="clickedOneTime || bphChange.isDisabled || bphChange.globalReadOnlyMode === 'true'"
            [ngClass]="isLastPosition ? 'zeroRightMargin' : ''"
            [style.background-color]="(bphChange.isDisabled || bphChange.globalReadOnlyMode === 'true') ? '' : confChange.buttonColor"
            class="eCase-dialog-positive-action-button" mat-raised-button>
      <span *ngIf="!bphChange.isDisabled">{{confChange.buttonText[selectedLanguage]}}</span>
      <span
        *ngIf="bphChange.isDisabled">{{confChange.disabledButtonText[selectedLanguage] ? confChange.disabledButtonText[selectedLanguage] : confChange.buttonText[selectedLanguage]}}</span>
    </button>
    <ng-container *ngIf="bphChange.isShowInfoText">
      <span [innerHTML]="bphChange.infoText[selectedLanguage]"></span>
    </ng-container>
    <ng-container *ngIf="bphChange?.error[selectedLanguage]">
      <br>
      <span class="tableFormFieldError">{{bphChange.error[selectedLanguage]}}</span>
    </ng-container>
  </div>
</td>
