import {Component, Inject, Input, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {eCaseAnimations, ECaseDataTable, ECaseSnackBarService, ECaseUtils, ParametersService} from "synto-common";
import {TranslateService} from "@ngx-translate/core";
import {AddNewSignerDialogComponent} from "./add-new-signer-dialog/add-new-signer-dialog.component";
import {Observable} from "rxjs/internal/Observable";
import {of} from "rxjs/internal/observable/of";
import {DOCUMENT} from "@angular/common";

/**
* Created by sahilb@evision.ca on 2022-02-23.
*/
@Component({
  selector: 'app-add-view-signature-status-dialog',
  templateUrl: './add-view-signature-status-dialog.component.html',
  styleUrls: ['./add-view-signature-status-dialog.component.scss'],
  animations: eCaseAnimations
})
export class AddViewSignatureStatusDialogComponent extends ECaseDataTable implements OnInit {

  displayedColumns = ['email', 'name', 'formattedSignatureDate', 'action'];
  filterObject = {
    'email': '',
    'name': '',
    'formattedSignatureDate': ''
  }
  /**
   * Signer array structure
   * [{
   * 'name': 'Full name',
   * 'email': 'user@evision.ca',
   * 'formattedSignatureDate': Date formatted in the client's date format if data is there. If signature is pending then empty string.
   * 'isSignatureGiven' : true/false based on if signature is provided or not.,
   * 'crmCommunicationId' : Numerical value
   * }]
   */
  @Input() signers: any[];
  @Input() crmCommunicationId: number;
  @Input() isReadOnlyModeEnabled = false;

  constructor(public dialogRef: MatDialogRef<AddViewSignatureStatusDialogComponent>, translate: TranslateService,
              parameterService: ParametersService, private dialog: MatDialog, private eCaseSnackBarService: ECaseSnackBarService,
              @Inject(DOCUMENT) private document: Document) {
    super(translate, parameterService);
    this.dialogRef.disableClose = true;
    this.signers = this.signers ? this.signers : [];
    this.serviceArray = this.signers;
  }

  /**
   * internalUsers array structure
   * [{
   * 'ssoUserId': 9999999999999,
   * 'name': 'Full name',
   * 'email': 'user@evision.ca'
   * }]
   *
   * externalUsers array structure
   * [{
   * 'name': 'Full name',
   * 'email': 'user@evision.ca'
   * }]
   */
  @Input() newSignersAdditionFunction = (internalUsers: any[], externalUsers: any[], crmCommunicationId: number): Observable<any> => {
    return of({
      'status': false,
      'errorMessage': 'Not implemented'
    });
  };

  /**
   * Users array structure
   * [{
   * 'name': 'Full name',
   * 'email': 'user@evision.ca'
   * }]
   */
  @Input() signerDeletionFunction = (users: any[], crmCommunicationId: number): Observable<any> => {
    return of({
      'status': false,
      'errorMessage': 'Not implemented'
    });
  };

  ngOnInit(): void {
    this.signers = this.signers ? this.signers : [];
    this.sortActive = 'name';
    this.sortDirection = 'asc';
    this.serviceArray = this.signers;
    this.originalArray = this.signers;
    this.setDataSourceAttributes();
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  addNewSigner(): void {
    const dialogRef = this.dialog.open(AddNewSignerDialogComponent, {
      width: '800px'
    });
    dialogRef.componentInstance.existingSigner = this.signers;
    dialogRef.afterClosed().subscribe((decision) => {
      if (decision) {
        this.newSignersAdditionFunction(decision.internalUsers, decision.externalUsers, this.crmCommunicationId).subscribe((response: any) => {
          if (response.status) {
            this.crmCommunicationId = response.crmCommunicationId;
            this.signers = this.signers.concat(decision.internalUsers.map(user => {
              user.formattedSignatureDate = '';
              user.isSignatureGiven = false;
              user.crmCommunicationId = response.crmCommunicationId;
              return user;
            })).concat(decision.externalUsers.map(user => {
              user.formattedSignatureDate = '';
              user.isSignatureGiven = false;
              user.crmCommunicationId = response.crmCommunicationId;
              return user;
            }));
            this.serviceArray = this.signers;
            this.originalArray = this.signers;
            this.setDataSourceAttributes();
            this.eCaseSnackBarService.show('success', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.newsignersadded'));
            if (response.redirectUrl && response.redirectUrl !== '') {
              this.document.location.href = response.redirectUrl;
            }
          } else {
            this.eCaseSnackBarService.show('failure', response.errorMessage);
          }
        });
      }
    });
  }

  isAllSignersSigned(): boolean {
    return this.dataSource.data.length > 0 && this.dataSource.data.every(row => row.isSignatureGiven);
  }

  delete(row): void {
    this.signerDeletionFunction([{
      'email' : row.email,
      'name' : row.name,
    }], this.crmCommunicationId).subscribe((response: any) => {
      if (response.status) {
        this.signers = this.signers.filter(user => user.email !== row.email);
        this.serviceArray = this.signers;
        this.originalArray = this.signers;
        this.setDataSourceAttributes();
        this.eCaseSnackBarService.show('success', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.signerdeleted'));
      } else {
        this.eCaseSnackBarService.show('failure', response.errorMessage);
      }
    });
  }

}
