<div *ngIf="isValidHtml">
  <div  *ngIf="bphChange.globalShowDetail">
  <div class="ui grid field">
  <span class="three wide column righttext">
    {{confChange.label[selectedLanguage]}}
  </span>
    <span class="eleven wide column field" [attr.aria-label]="confChange.label[selectedLanguage]">
    {{getOutput(confChange.content,selectedLanguage)}}
  </span>
  </div>
  </div>
</div>




<td *ngIf="!row.isTotal && isValidTable">
  <div>
    <span [attr.aria-label]="confChange.label[selectedLanguage]">{{getOutput(confChange.content,lang)}}</span>
  </div>
</td>

<td *ngIf="!row.isTotal && ! bphChange.hideCell  && !confChange.hideCell  && isValidTableReadonly" [attr.rowspan]="bphChange.rowspan">
  <div>
    <span>{{getOutput(confChange.content,lang)}}</span>
  </div>
</td>
