<div *ngIf="isValidHtml && !bphChange.hide && bphChange.globalShowDetail" [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
     [style.padding-bottom] = "!bphChange.error[selectedLanguage] ? '1.34375em' : '10px'">
  <div>
      <div class="field ui grid">
        <div class="formLabel five wide righttext column">
          <ng-container *ngIf="confChange.condensedLabel && confChange.condensedLabel[selectedLanguage]">
            <span style="font-weight: bolder" [innerHTML]="confChange.condensedLabel[selectedLanguage]"></span>
            <br>
          </ng-container>
          <span *ngIf="confChange.isRequired && ((list.length > 1 && confChange.label[selectedLanguage]!== ' ') || (list.length === 1 && confChange.label[selectedLanguage]!== ' ')) "
                style="color:red;">* </span>
          <span
            [innerHTML]="confChange.label[selectedLanguage]"></span>
          <span *ngIf="confChange.hasTooltip" style="padding-top: 10px;">

           <mat-icon matTooltip='{{confChange.tooltipText[selectedLanguage]}}'
                     matTooltipClass="tooltip">help_outline</mat-icon>       </span>
          <span *ngIf="confChange.hasDialog">
            <mat-icon (click)="openDialog()"
                      style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;">help_outline</mat-icon>
          </span>
        </div>
        <div [class.column]="!confChange.inline" [ngClass]="{'inline field  fields ui ':confChange.inline}"
             class="nine wide  field">
          <span *ngIf="confChange.isRequired && list.length > 1 && confChange.label[selectedLanguage]=== ' '"
                style="color:red;">* </span>
          <div *ngFor="let option of removeItemsToHide(list); let i = index" [style.display]="option.hide ? 'none' : ''">
            <div fxLayout="row" fxLayoutAlign="start">
             <div [style]="confChange.enableOnClickOnlyOnCheckbox ? 'display: flex; align-items: baseline;' : ''">
              <span *ngIf="confChange.isRequired && list.length === 1 && confChange.label[selectedLanguage]=== ' '" style="color:red;padding-right: 3px">* </span>
              <mat-checkbox *ngIf="bphChange.value[i]"
                            (ngModelChange)="change($event,confChange,bphChange,i,option)"
                            [(ngModel)]="bphChange.value[i].value"
                            [attr.aria-label]="confChange.label[selectedLanguage]"
                            [disabled]="confChange.isReadOnlyField || disableOnValue(bphChange,confChange,i) || bphChange.value[i].isDisabled || bphChange.globalReadOnlyMode === 'true'"
                            [style.margin-right]="confChange.enableOnClickOnlyOnCheckbox ? '8px' : ''"
                            [ngClass]="bphChange.error[selectedLanguage] ? 'accentBorder' : 'noColor'">
                {{!confChange.enableOnClickOnlyOnCheckbox ? option.label[selectedLanguage] : null}}
              </mat-checkbox>
              <span *ngIf="confChange.enableOnClickOnlyOnCheckbox">{{option.label[selectedLanguage]}}</span>

               <ng-container *ngIf="confChange.enableOnClickOnlyOnCheckbox && checkIfDialogOptionsAreEnabled(option.value)">
                <span>
                  <mat-icon (click)="openDialogForOptions(option.value)" style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important; padding-left: 4px">help_outline</mat-icon>
                </span>
               </ng-container>
             </div>
            </div>
          </div>
          <ng-container *ngIf="confChange.hasOther && isOtherOptionChecked()">
            <br>
            <label [for]="confChange.blockName + 'other value'"><span *ngIf="confChange.isOtherFieldRequired"
                                                                      style="color: red">* </span>{{confChange.otherPlaceholder[selectedLanguage]}}
            </label>
            <mat-form-field appearance="outline" [ngClass]="bphChange.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''" class="noBottomMargin">
              <input maxlength="100" [(ngModel)]="bphChange.otherValue" [attr.aria-label]="confChange.otherPlaceholder[selectedLanguage]"
                     [id]="confChange.blockName + 'other value'"
                     matInput
                     type="text"/>
            </mat-form-field>
          </ng-container>
          <span *ngIf="bphChange?.error[selectedLanguage]" class="tableFormFieldError">{{bphChange.error[selectedLanguage]}}</span>
        </div>
      </div>
    </div>
  </div>



<td *ngIf="isValidTable &&  !row.isTotal && !bphChange.hide" [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
    style="border:0;width:1%;padding-right:10px;padding-left:10px">
  <div class="field {{bphChange.error_class}} hide_{{bphChange.show}}">
    <div [ngClass]="confChange.align ? (confChange.align.includes('left') ? 'align-start' : (confChange.align.includes('right') ? 'align-end' : 'align-center')) : 'align-center'">
     <div fxLayout="{{(!confChange.inline || confChange.inline === false) ? 'column' : 'row'}}" *ngFor="let option of removeItemsToHide(list); let i = index" [style.display]="option.hide ? 'none' : ''">
        <mat-checkbox *ngIf="bphChange.value[i]"
                      (ngModelChange)="change($event,confChange,bphChange,i,option)" [style.margin]="confChange.align ? '' : '0 auto'"
                      [(ngModel)]="bphChange.value[i].value"
                      [attr.aria-label]="confChange.label[selectedLanguage]"
                      [disabled]="confChange.isReadOnlyField || disableOnValue(bphChange,confChange,i) || bphChange.value[i].isDisabled || bphChange.globalReadOnlyMode === 'true'"
                      [ngClass]="bphChange.error[selectedLanguage] ? 'accentBorder' : (confChange.align ? (confChange.align.includes('left') ? 'align-start' : (confChange.align.includes('right') ? 'align-end' : 'align-center')) : 'align-center')">
          {{option.label[selectedLanguage]}}
        </mat-checkbox>

    </div>
    <ng-container *ngIf="confChange.hasOther && isOtherOptionChecked()">
      <br>
      <label [for]="confChange.blockName + 'other value'"><span *ngIf="confChange.isOtherFieldRequired"
                                                                style="color: red">* </span>{{confChange.otherPlaceholder[selectedLanguage]}}
      </label>
      <mat-form-field appearance="outline">
        <input [(ngModel)]="bphChange.otherValue" [attr.aria-label]="confChange.otherPlaceholder[selectedLanguage]" [id]="confChange.blockName + 'other value'"
               matInput
               type="text"/>
      </mat-form-field>
    </ng-container>
    <span class="tableFormFieldError" *ngIf="bphChange?.error[selectedLanguage]">{{bphChange.error[selectedLanguage]}}</span>
  </div>



<td *ngIf=" isValidTableReadonly && !bphChange.hideCell && !bphChange.hideCell  && !row.isTotal " [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
    [attr.rowspan]="bphChange.rowspan"
    style="border:0;width: 10%;text-align: center;padding-right:20px">
  <div class="field hide_{{bphChange.show}}">
    {{getValue(bphChange, selectedLanguage)}}
  </div>
  <div>
    <span class="tableFormFieldError"
          *ngIf="bphChange?.error[selectedLanguage]">{{bphChange.error[selectedLanguage]}}</span>
  </div>
</td>

