import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {DocumentListingComponent} from "synto-common/synto-core";
import {DialogComponent} from '../../dialog/dialog.component';


@Component({
  selector: 'app-dialog',
  templateUrl: './fb-button-component-dialog.component.html',
  styleUrls: ['./fb-button-component-dialog.component.css']
})
export class FbButtonComponentDialogComponent implements OnInit {

  @Input() confChange;
  @Input() dataForComponent;
  @Input() selectedLanguage: any;
  @ViewChild('vc', {static: true, read: ViewContainerRef}) vc;

  constructor(public dialogRef: MatDialogRef<FbButtonComponentDialogComponent>,
              private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit() {
    let componentFactory;
    let componentData: any;
    switch (this.confChange.renderedComponent.name) {
      case 'DocumentListingComponent':
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(DocumentListingComponent);
        componentData = {
          isPreviewBeforeDownload: this.confChange.renderedComponent.properties.isPreviewBeforeDownload,
          columnsToBeHidden: this.confChange.renderedComponent.properties.columnsToBeHidden,
          defaultSortedColumn: this.confChange.renderedComponent.properties.defaultSortedColumn,
          defaultSortOrder: this.confChange.renderedComponent.properties.defaultSortOrder,
          accessLocation: this.confChange.renderedComponent.properties.accessLocation,
          context: this.confChange.renderedComponent.properties.context,
          dataSourceArray: this.dataForComponent,
        }
        break;
      default: {
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(DialogComponent);
        const dialogText = {};
        dialogText[this.selectedLanguage] = 'No component selected for rendering';
        componentData = {
          dialog: dialogText,
          showConfirmOption: false,
          selectedLanguage: this.selectedLanguage
        }
        break;
      }
    }
    this.vc.clear();
    const cmpRef = this.vc.createComponent(componentFactory);
    Object.keys(componentData).forEach(key => {
      cmpRef.instance[key] = componentData[key];
    });
    this.vc.insert(cmpRef.hostView);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }


}
