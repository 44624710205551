import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'fb-currency',
  templateUrl: './fb-currency.component.html',
  styleUrls: ['./fb-currency.component.css']
})
export class FbCurrencyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
