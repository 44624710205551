import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from '../dialog/dialog.component';
import {EcaseHttpService, ECaseUtils, ECaseUtilsGlobal, PendingChangesGuard, RefreshDataService} from 'synto-common';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from "rxjs/internal/Subscription";


@Component({
  selector: 'fb-autoComplete',
  templateUrl: './fb-auto-complete.component.html',
  styleUrls: ['./fb-auto-complete.component.scss']
})
export class FbAutoCompleteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() globalPrj: any;
  @Input() selectedLanguage: any;
  @Input() rowNumber;
  @Input() selectedSectionId;
  @Output() emitOutputEvent = new EventEmitter<any>();
  lang: any;
  searchResult = [];
  options = [];
  dataFromUrl = [];
  row: any;
  autoCompleteValue;
  form: FormGroup;
  resultCloned = [];
  otherValue = {
    'label': {},
    'sortingKey': ECaseUtilsGlobal.OTHER_VALUE_TERM_ID,
    'value': ECaseUtilsGlobal.OTHER_VALUE_TERM_ID
  }; // Labels are added in ngOnInit method

  fbAutoCompleteFormControl: FormControl = new FormControl();
  refreshDataChangeSubscription: Subscription;
  getNewValueSubscription: Subscription;

  constructor(private translate: TranslateService,
              private ecaseHttpService: EcaseHttpService,
              private refreshDataService: RefreshDataService,
              private pendingChangesGuard: PendingChangesGuard,
              public dialog: MatDialog) {
    this.confChange = {};
    this.confChange.label = {};
    this.confChange.initValue = (currentValue): void => {
      currentValue.value = {};
      ecaseHttpService.get('/api/getLanguages').subscribe((language: any) => {
        currentValue.value.label[language.id] = '';
        this.confChange.label[language.id] = '';
      });
      currentValue.error = {};
      currentValue.getValue = (): any => this.bphChange.value;
    };
    this.confChange.inputDone = (input, currentValue): void => {
      currentValue.value = {
        'value': input.id_value,
        'label': input.display
      };
    };
    this.row = {};

    this.selectedLanguage = this.translate.getDefaultLang();
  }


  ngOnInit(): void {
    this.translate.langs.forEach((lang) => {
      if (lang && lang !== 'undefined') {
        this.otherValue.label[lang] = ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.other', lang);
      }
    });
    if (!this.confChange.searchType) {
      this.confChange.searchType = 1;
    }
    if (!this.bphChange) {
      this.bphChange = {};
      this.bphChange.value = {};
      this.bphChange.value.label = {};
      this.bphChange.error = {};
      this.bphChange.source = {};
    }
    if (!this.bphChange.value) {
      this.bphChange.value = {};
    }
    if (!this.bphChange.error) {
      this.bphChange.error = {};
    }
    if (!this.bphChange.source) {
      this.bphChange.source = {};
    }
    if (!this.bphChange.value.label) {
      this.bphChange.value.label = {};
    }

    this.form = new FormGroup({
      autoCompleteFormControl: new FormControl('', [Validators.required, requireMatch]),
    });
    this.getNewValueSubscription = this.refreshDataService.getNewValueForAutocomplete().subscribe((data) => {
      if (data && (data.name === this.confChange.name) && (this.rowNumber === undefined || this.rowNumber === data.rowNumber) && data.value) {
        this.bphChange.value = data.value;
        this.form.controls.autoCompleteFormControl.patchValue(data.value);
        this.emitOutputEvent.emit(this);
      }
    });


    this.refreshDataChangeSubscription = this.refreshDataService.getNewValue().subscribe((data) => {

      if (this.confChange.isUrlParamterised && data && data.bphChange) {
        if (this.confChange.paramterPath.includes(data.name)) {
          this.getDataFromUrl(data.bphChange.value);
        }
      } else {
        if (data && (data.name === this.confChange.name) && (this.rowNumber === undefined || this.rowNumber === data.rowNumber) && (this.bphChange.list && !data.newBphChange)) {
          this.bphChange.value = {};
          this.fbAutoCompleteFormControl.setValue('');
          this.form.controls.autoCompleteFormControl.patchValue({});
          this.searchResult = this.bphChange.list.sort((a, b) => {
            if (a.sortingKey > b.sortingKey) {
              return 1;
            } else if (a.sortingKey < b.sortingKey) {
              return -1;
            } else {
              return a.label[this.selectedLanguage].localeCompare(b.label[this.selectedLanguage]);
            }
          });
          if (this.confChange.isReadOnlyField || this.bphChange.globalReadOnlyMode === 'true' || this.bphChange.disabled) {
            this.fbAutoCompleteFormControl.disable();
            this.form.controls.autoCompleteFormControl.disable();
          } else {
            this.fbAutoCompleteFormControl.enable();
            this.form.controls.autoCompleteFormControl.enable();
          }
        } else if (data && (data.name === this.confChange.name) && (this.rowNumber === undefined || this.rowNumber === data.rowNumber) && data.newBphChange) {
          this.bphChange = data.newBphChange;
          if (this.confChange.isReadOnlyField || this.bphChange.globalReadOnlyMode === 'true' || this.bphChange.disabled) {
            this.fbAutoCompleteFormControl.disable();
            this.form.controls.autoCompleteFormControl.disable();
          } else {
            this.fbAutoCompleteFormControl.enable();
            this.form.controls.autoCompleteFormControl.enable();
          }
          this.fbAutoCompleteFormControl.setValue(this.bphChange.value.label[this.selectedLanguage]);
          this.form.controls.autoCompleteFormControl.patchValue(this.bphChange.value);
          this.autoCompleteValue = this.bphChange.value.label[this.selectedLanguage];
        }
      }

    });
    this.row = {};
    /*   if (!(this.confChange.minlength)) {
         if (!this.confChange.isDataFromUrl) {
           this.confChange.minlength = 3;
         } else {
           this.confChange.minlength = 0;
         }
       }*/
    this.bphChange.listName = this.confChange.listName;
    if (this.bphChange.value) {

      if (this.confChange.lovSQL && this.confChange.parameters && this.confChange.parameters.length > 0 && this.bphChange.value.data) {
        console.log('IAM IN THIS CASE DAAAAAAAAAAAAAAAAAAAAAAA');
        this.fbAutoCompleteFormControl.setValue(this.bphChange.value.data[(this.confChange.parameters[0]).text]);
        this.autoCompleteValue = this.bphChange.value.data[(this.confChange.parameters[0]).text];
        this.form.controls.autoCompleteFormControl.patchValue(this.bphChange.value);
        this.refreshDataService.setNewValueForStaticText(this.confChange.name, this.bphChange.value.data, true);
      } else if (this.bphChange.value.label) {
        this.fbAutoCompleteFormControl.setValue(this.bphChange.value.label[this.selectedLanguage]);
        this.form.controls.autoCompleteFormControl.patchValue(this.bphChange.value);
        this.autoCompleteValue = this.bphChange.value.label[this.selectedLanguage];
      }
    }
    if (!this.bphChange.error) {
      this.bphChange.error = {};
    }

    if (this.confChange.isDataFromUrl) {

      this.getDataFromUrl('');
    }
    if (this.confChange.isChildren) {
      this.searchResult = this.resultCloned;
    }
    if (!this.confChange.parameters) {
      this.confChange.parameters = [];
    }

    this.bphChange.getValue = (): any => this.bphChange.value;

    if (this.globalConf.lovs[this.confChange.listName]) {
      this.searchResult = this.globalConf.lovs[this.confChange.listName].list;
      if (this.confChange.hasOther && this.searchResult.filter(item => item.value === this.otherValue.value).length === 0) {
        this.searchResult.push(this.otherValue);
      }
      this.searchResult = this.searchResult.sort((a, b) => {
        const x = a.label[this.selectedLanguage];
        const y = b.label[this.selectedLanguage];
        if (a.sortingKey > b.sortingKey) {
          return 1;
        } else if (a.sortingKey < b.sortingKey) {
          return -1;
        } else {
          return x.localeCompare(y);
        }
      });
      this.options = _.cloneDeep(this.searchResult);
    }
    if (this.confChange.lovSQL) {
      if(this.confChange.lovSQL.isLov) {
        //if it's an LOV
        this.searchResult = this.globalConf.lovs[this.confChange.lovSQL.name].list;
        this.options = this.searchResult;
      } else {
        this.searchResult = this.globalPrj.formData[this.confChange.lovSQL.name].data.map((item) => {
          const obj: any = {};
          obj.label = {};
          Object.keys(item).filter(key => key.startsWith('LABEL_')).forEach((key) => {
            obj.label[key.replace('LABEL_', '').toLowerCase()] = item[key];
          });
          obj.value = item['VALUE'];
          return obj;
        });
        this.options = ECaseUtils.eCaseCloneDeep(this.searchResult);
      }
      // this.searchResult = this.searchResult.concat(this.globalConf.lovs[this.confChange.lovSQL.name].list);
    }
    if (((this.confChange.isReadOnlyField ||
      this.bphChange.disabled ||
      this.bphChange.globalReadOnlyMode) === true || (this.confChange.isReadOnlyField || this.bphChange.globalReadOnlyMode) === 'true')) {
      this.fbAutoCompleteFormControl.disable();
      this.form.controls.autoCompleteFormControl.disable();

    }

    if (this.confChange.putValueInFormData && this.confChange.putValueInFormDataKey) {
      if (!this.globalPrj.formData) {
        this.globalPrj.formData = {};
      }
      this.globalPrj.formData[this.confChange.putValueInFormDataKey] = this.bphChange.value;
    }
    console.log('*****init***************');
    console.log(this.searchResult);
    this.searchResult = this.searchResult.sort((a, b) => {
      const x = a.label[this.selectedLanguage] ? a.label[this.selectedLanguage] : '';
      const y = b.label[this.selectedLanguage] ? b.label[this.selectedLanguage] : '';

      if (a.sortingKey > b.sortingKey) {
        return 1;
      } else if (a.sortingKey < b.sortingKey) {
        return -1;
      } else {
        return x.localeCompare(y);
      }
    });
    this.filterValue('');
    this.refreshDataService.displayResult = this.searchResult;
    // this.refreshDataService.setNewValue(this.confChange.name, this.bphChange.value);


    if (this.confChange.isRealtimeDataFromUrl) {
      console.log('TRUE!!!!!');
      this.searchResult = [];
    }
    this.form.valueChanges.pipe(debounceTime(100)).subscribe((val: any) => {
      const value = val['autoCompleteFormControl'];
      const minLength = this.confChange.minlength ? this.confChange.minlength : 1;

      if (this.confChange.isRealtimeDataFromUrl && value.length >= minLength) {
        this.processRealtimeData(value);
      }
    });
  }

  getTranslationValue(code): string{
    return ECaseUtils.getTranslatedValueFromKey(this.translate, code, this.selectedLanguage);
  }

  processRealtimeData(value): void {
    let obj: any;

    /*As more services are implemented, just add an additional condition to handle your logic*/
    if (this.confChange.realtimeDataUrl === 'api/getAllInstitutionForAutoComplete') {
      obj = {
        searchString: value,
        classificationId: (this.confChange.includedClassfications ? this.confChange.includedClassfications : null),
        excludedClassificationIds: (this.confChange.excludedClassificationIds ? this.confChange.excludedClassificationIds : null),
        countryId: null,
        showArchivedOrganizations: this.confChange.showArchivedOrganizations
      };

      this.refreshDataService.getAllInstitutionForAutoComplete(obj).subscribe((response: any) => {

        console.log(response);
        // All pre-processing can be done here
        this.searchResult = response;
      });
    } else if (this.confChange.realtimeDataUrl === 'api/getAllUsersForAutoComplete') {
      obj = {
        searchString: value,
        roleId: null,
        roleClassificationId: null,
        isFilterOutLoggedInUser: this.confChange.isFilterOutLoggedInUser
      };

      this.refreshDataService.getAllUsersForAutoComplete(obj).subscribe((response: any) => {
        console.log(response);
        // All pre-processing can be done here
        this.searchResult = response;
      });
    }
    else if (this.confChange.realtimeDataUrl === 'api/getAllUsersByEmailForAutoComplete') {
      obj = {
        searchString: value,
        roleId: null,
        roleClassificationId: null,
        isFilterOutLoggedInUser: this.confChange.isFilterOutLoggedInUser,
        ssoUserIdsToFilterOut: this.confChange.ssoUserIdsToFilterOut ? this.confChange.ssoUserIdsToFilterOut : null,
        selectedCrmPositionId: this.confChange.selectedCrmPositionId ?  this.confChange.selectedCrmPositionId : null
      };

      this.refreshDataService.getAllUsersByEmailForAutoComplete(obj).subscribe((response: any) => {
        console.log(response);
        // All pre-processing can be done here
        this.searchResult = response;
      });
    }else if (this.confChange.realtimeDataUrl === 'api/getAllUsersForAutoCompleteApplications') {
      obj = {
        searchString: value,
        roleId: null,
        roleClassificationId: null
      };

      this.refreshDataService.getAllUsersForAutoCompleteApplications(obj).subscribe((response: any) => {
        console.log(response);
        // All pre-processing can be done here
        this.searchResult = response;
      });
    } else if (this.confChange.realtimeDataUrl === 'api/getAllUsersByEmailWithoutPositionForAutoComplete') {
      obj = {
        searchString: value,
        roleId: null,
        roleClassificationId: null,
        isFilterOutLoggedInUser: this.confChange.isFilterOutLoggedInUser
      };

      this.refreshDataService.getAllUsersByEmailWithoutPositionForAutoComplete(obj).subscribe((response: any) => {
        console.log(response);
        // All pre-processing can be done here
        this.searchResult = response;
      });
    } else if (this.confChange.realtimeDataUrl === 'api/getTeamLeaderAndStructureByEmailForAutocomplete') {
      obj = {
        searchString: value
      };

      this.refreshDataService.getTeamLeaderAndStructureByEmailForAutocomplete(obj).subscribe((response: any) => {
        console.log(response);
        // All pre-processing can be done here
        this.searchResult = response;
      });
    } else if (this.confChange.realtimeDataUrl === 'api/getAllUserWithStructureByEmailForAutocomplete') {
      obj = {
        searchString: value
      };

      this.refreshDataService.getAllUserWithStructureByEmailForAutocomplete(obj).subscribe((response: any) => {
        console.log(response);
        // All pre-processing can be done here
        this.searchResult = response;
      });
    } else {
      this.searchResult = [];
    }

  }

  openDialog(): void {
    this.dialog.open(DialogComponent, {
      width: '600px',
      data:
        {
          dialog: this.confChange.dialogText,
          selectedLanguage: this.selectedLanguage
        }
    });
  }

  onChange(newValue): void {
    console.log(this.confChange);
    console.log(newValue);
    if (newValue) {
      this.autoCompleteValue = newValue;
    } else {

      this.autoCompleteValue = '';
    }
    this.refreshDataService.displayResult = this.searchResult;
  }

  getWordCount(str): number {
    try {
      return (str === '' ? 0 : str.split(' ').filter(item => item !== '').length);
    } catch (e) {
      return 0;
    }
  }

  changeValueEvent($event): void {
    console.log(this.bphChange);
    console.log($event);
    if (this.confChange.countUnit === 'ecase.common.words') {
      if (this.getWordCount(this.bphChange.otherValue) >= this.confChange.otherValueFieldMaxLength) {
        this.bphChange.otherValue = $event.target.value.toString().split(' ').slice(0, this.confChange.otherValueFieldMaxLength).join(' ');
        //this.bphChange.otherValue.split(" ").slice(0, this.confChange.otherValueFieldMaxLength).join(" ")
      } else {
        this.bphChange.otherValue = $event.target.value;
      }
    } else {
      if (this.getTextLength(this.bphChange.otherValue) >= this.confChange.otherValueFieldMaxLength) {
        this.bphChange.otherValue = $event.target.value.toString().slice(0, this.confChange.otherValueFieldMaxLength);
        //this.bphChange.otherValue.slice(0, this.confChange.otherValueFieldMaxLength).join("")
      } else {
        this.bphChange.otherValue = $event.target.value;
      }
    }
    this.emitOutputEvent.emit(this.bphChange.value);
  }


  filterValue(data): void {
    console.log(data);
    if (!this.confChange.isRealtimeDataFromUrl) {
      if (this.confChange.isDataFromUrl) {
        this.searchResult = this.confChange.searchType === 1 ? this.dataFromUrl.filter(list => (list.label[this.selectedLanguage].toLowerCase())
            .startsWith(data.toString().toLowerCase())) :
          this.dataFromUrl.filter(list => (list.label[this.selectedLanguage].toLowerCase()).includes(data.toString().toLowerCase()));
      } else if (this.options.length > 0) {
        console.log('1');
        if (this.confChange.lovSQL) {
          console.log('2');
          if (this.confChange.parameters.length > 0) {
            console.log('3');
            this.searchResult = this.confChange.searchType === 1 ? this.options.filter((item) => {
              if (item && item.hasOwnProperty('data')) {
                (item.data[(this.confChange.parameters[0]).text].toLowerCase()).startsWith(data.toString().toLowerCase());
              }
            }) : this.options.filter((item) => {
              if (item && item.hasOwnProperty('data')) {
                (item.data[(this.confChange.parameters[0]).text].toLowerCase()).includes(data.toString().toLowerCase());
              }
            });

          } else {
            console.log('4');
            if(this.confChange.minlength <= data.toString().length) {
              this.searchResult = this.confChange.searchType === 1 ? this.options.filter(item => (item.value.toLowerCase()).startsWith(data.toString().toLowerCase())) :
                this.options.filter(item => (item.value.toLowerCase()).includes(data.toString().toLowerCase()));
            } else {
              this.searchResult = [];
            }
            if (this.searchResult.length === 0) {
              const minLength = this.confChange.minlength ? this.confChange.minlength : 1;
              if (data.toString().length >= minLength) {
                console.log('6');
                this.searchResult = this.confChange.searchType === 1 ? this.options.filter(option => (option.label[this.selectedLanguage]
                    .toLowerCase()).startsWith(data.toString().toLowerCase())) :
                  this.options.filter(option => (option.label[this.selectedLanguage] !== null) ? (option.label[this.selectedLanguage].toLowerCase()).includes(data.toString().toLowerCase()) : false);
              } else {
                console.log('7');
                this.searchResult = [];
              }
            }
          }
        } else {
          console.log('5');
          const minLength = this.confChange.minlength ? this.confChange.minlength : 1;
          if (data.toString().length >= minLength) {
            console.log('6');
            this.searchResult = this.confChange.searchType === 1 ? this.options.filter(option => (option.label[this.selectedLanguage]
                .toLowerCase()).startsWith(data.toString().toLowerCase())) :
              this.options.filter(option => (option.label[this.selectedLanguage].toLowerCase()).includes(data.toString().toLowerCase()));
          } else {
            console.log('7');
            this.searchResult = [];
          }
        }
      }
    } else {
      if(this.confChange.minlength <= data.toString().length) {
        this.processRealtimeData(data);
      }
    }
    console.log(this.options);
    console.log(this.searchResult);
  }

  sortArrayAlphabetically(sortingArray): any[] {
    return sortingArray.sort((a, b) => {
      const x = a.label[this.selectedLanguage] ? a.label[this.selectedLanguage] : '';
      const y = b.label[this.selectedLanguage] ? b.label[this.selectedLanguage] : '';
      if (a.sortingKey > b.sortingKey) {
        return 1;
      } else if (a.sortingKey < b.sortingKey) {
        return -1;
      } else {
        return x.localeCompare(y);
      }
    });
  }

  removeAlreadyAssignedValues(list): any[] {
    if (this.confChange.isValueUniqueForSubBlock && this.confChange.selectedContainerName) {
      const currentRows = (this.globalPrj[this.selectedSectionId][this.confChange.selectedContainerName]['rows'])
        .map(row => row[this.confChange.name].value.value).filter(item => item);
      return list.filter(item => !currentRows.includes(item.value));
    } else {
      return list;
    }
  }

  setAutoCompleteValue($event): void {
    console.log(this.searchResult);
    this.bphChange.value = $event.option.value;
    if (this.confChange.lovSQL && this.confChange.parameters && this.confChange.parameters.length > 0) {
      this.refreshDataService.setNewValueForStaticText(this.confChange.name, $event.option.value.data, true);
      this.fbAutoCompleteFormControl.setValue($event.option.value.data[(this.confChange.parameters[0]).text]);
      this.form.controls.autoCompleteFormControl.patchValue($event.option.value);
      this.autoCompleteValue = $event.option.value.data[(this.confChange.parameters[0]).text];
    } else {
      this.fbAutoCompleteFormControl.setValue($event.option.value.label[this.selectedLanguage]);
      this.form.controls.autoCompleteFormControl.patchValue($event.option.value);
      this.autoCompleteValue = $event.option.value.label[this.selectedLanguage];
      if ($event.option.value.data) {
        this.refreshDataService.setNewValueForStaticText(this.confChange.name, $event.option.value.data);
      }
    }
    if (this.confChange.putValueInFormData && this.confChange.putValueInFormDataKey) {
      if (!this.globalPrj.formData) {
        this.globalPrj.formData = {};
      }
      this.globalPrj.formData[this.confChange.putValueInFormDataKey] = this.bphChange.value;
    }
    this.refreshDataService.displayResultForKids = this.resultCloned.filter(item => item.parentOrganisationId === this.bphChange.value.value);
    if (this.confChange.isEnableOutputEvent || this.confChange.enableOutputEvent) {
      if (this.confChange.onSelectFunctionNames) {
        for (const functionName of this.confChange.onSelectFunctionNames) {
          this.confChange[functionName]({
            'bphChange': this.bphChange,
            'confChange': this.confChange,
            'otherValue': this.otherValue,
            'prj': this.globalPrj,
            'conf': this.globalConf,
            'refreshDataService': this.refreshDataService
          }, this.rowNumber);
        }
      }
      this.emitOutputEvent.emit({
        'bphChange': this.bphChange,
        'confChange': this.confChange,
        'otherValue': this.otherValue,
        'prj': this.globalPrj,
        'conf': this.globalConf,
        'refreshDataService': this.refreshDataService
      });
    } else {
      this.emitOutputEvent.emit(this.bphChange.value);
    }
  }

  displayFn(selectedValue?: any): string | undefined {
    try {
      if (typeof selectedValue === 'string' && selectedValue) {
        return selectedValue;
      } else {
        if (this.confChange.lovSQL && this.confChange.parameters && this.confChange.parameters.length > 0) {
          if (selectedValue && selectedValue.data) {
            return selectedValue.data[(this.confChange.parameters[0]).text];
          } else {
            return '';
          }

        } else {
          return (selectedValue && selectedValue.label) ? selectedValue.label[this.selectedLanguage] : '';
        }
      }
    } catch (e) {
      console.log('errror', e);
    }

  }

  getTextLength(l): number {
    try {
      return l.length;
    } catch (e) {
      return 0;
    }
  }


  getDataFromUrl(componentValue): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.bphChange && changes.confChange && this.confChange && this.bphChange) {
      if (this.confChange.name === changes.confChange.currentValue.name) {
        if (changes.bphChange.currentValue) {
          try {
            if (changes.bphChange.currentValue.value) {
              if (changes.bphChange.currentValue.value.data) {
                this.ngOnInit();
                if (this.confChange.lovSQL && this.confChange.parameters && this.confChange.parameters.length > 0) {
                  if (changes.bphChange.currentValue.value) {
                    this.fbAutoCompleteFormControl.patchValue(changes.bphChange.currentValue.value.data[(this.confChange.parameters[0]).text]);
                    this.autoCompleteValue = changes.bphChange.currentValue.value.data[(this.confChange.parameters[0]).text];
                  }
                } else {
                  this.fbAutoCompleteFormControl.patchValue(changes.bphChange.currentValue.value.label[this.selectedLanguage]);
                  this.autoCompleteValue = changes.bphChange.currentValue.value.label[this.selectedLanguage];
                  // this.fbAutoCompleteFormControl.setValue(changes.bphChange.currentValue.value.label[this.selectedLanguage]);
                }
                if (this.confChange.putValueInFormData && this.confChange.putValueInFormDataKey) {
                  if (!this.globalPrj.formData) {
                    this.globalPrj.formData = {};
                  }
                  this.globalPrj.formData[this.confChange.putValueInFormDataKey] = this.bphChange.value;
                }
              }
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    }
  }

  onChangeValueEvent(event): void {
    console.log('this methods is calling');
    this.pendingChangesGuard.isPristine = false;
    if (event === '') {
      this.bphChange.value = {label: {}};
    }
    if (this.bphChange && this.bphChange.value && this.bphChange.value.data) {
      this.refreshDataService.setNewValueForStaticText(this.confChange.name, this.bphChange.value.data);
      /* const testVariable: any = window;
       testVariable.isFormModified = ECaseUtils.isGlobalPrjPristine(this.globalPrj, testVariable.pristinePrj);*/
    }
  }


  ngOnDestroy(): void {
    if (this.refreshDataChangeSubscription) {
      this.refreshDataChangeSubscription.unsubscribe();
    }
    if (this.getNewValueSubscription) {
      this.getNewValueSubscription.unsubscribe();
    }
  }

  isEmpty(obj): boolean {
    return Object.keys(obj).length === 0;
  }

}

const requireMatch = (control: AbstractControl): any => {
  const selection: any = control.value;
  if (typeof selection === 'string') {
    return {incorrect: true};
  }
  return null;
};
