<div *ngIf="isValidHtml && !bphChange.hide" [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'" >
  <div *ngIf="bphChange.globalShowDetail">
    <div class="field {{ bphChange.error_class}} hide_{{ bphChange.show}}">
      <div class="ui grid field">
        <div class="formLabel five wide column righttext">
          <ng-container *ngIf="confChange.condensedLabel && confChange.condensedLabel[selectedLanguage]">
            <span style="font-weight: bolder" [innerHTML]="confChange.condensedLabel[selectedLanguage]"></span>
            <br>
          </ng-container>
          <span *ngIf="confChange.isRequired" style="color: red">* </span>
          <span [innerHTML]="confChange.label[selectedLanguage]"></span>
          <span *ngIf="confChange.isShowDateFormat" style="white-space: nowrap"><br>({{confChange.dateFormatToBeShown[selectedLanguage]}})</span>
          <span *ngIf="confChange.hasTooltip" style="padding-top: 10px;">
               <mat-icon  matTooltipClass="tooltip" matTooltip='{{confChange.tooltipText[selectedLanguage]}}' >help_outline</mat-icon>
          </span>
          <span *ngIf="confChange.hasDialog" style="padding-top: 10px;">
             <mat-icon style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;" (click)="openDialog()">help_outline</mat-icon>
          </span>

        </div>

        <div class="nine wide column field" [id] = "selectedSectionId + '_' + confChange.name" [style.width]="getWidthFromMaxlength() ">

          <mat-form-field appearance="outline" *ngIf="confChange.selectedContainerType !== 'SchedulerTable'"
                          [style]="(confChange.reduceWidthByHalf) ? 'width: 50%' : 'width: 100%'" [ngClass]="bphChange.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid accentBorder' : ''">
            <input matInput [min]="minDate" [max]="maxDate" [(ngModel)]="bphChange.value" [matDatepicker]="picker" (click)="picker.open()"
                   (dateInput)="addEvent($event)" [attr.aria-label]="confChange.label[selectedLanguage]" (input)="changeValueEvent($event)"
                   [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'" [lang]="selectedLanguage"></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="confChange.selectedContainerType === 'SchedulerTable'" [ngClass]="bphChange.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid accentBorder' : ''">
            <input matInput [min]="bphChange.minDate" [max]="bphChange.maxDate" [(ngModel)]="bphChange.value" [matDatepicker]="picker" [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'" (click)="picker.open()"
                   [attr.aria-label]="confChange.label[selectedLanguage]" (input)="changeValueEvent($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'" [lang]="selectedLanguage"></mat-datepicker>
          </mat-form-field>
        </div>
        <div *ngIf="bphChange?.error[selectedLanguage]" [style.margin-top]="bphChange?.error[selectedLanguage] ? '-20px' : '0'">
          <span class="tableFormFieldError">{{bphChange.error[selectedLanguage]}} </span>
        </div>
      </div>
    </div>
  </div>
</div>

<td  [id] = "'td_' + selectedSectionId + '_' + confChange.name + '_' + rowNumber" *ngIf=" isValidTable && !row.isTotal" style="border:0;width: 10%;padding-right:10px;padding-left:10px"
    [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'" class="selectClearPadding">
  <div class="field {{ bphChange.error_class}} hide_{{ bphChange.show}}">
    <mat-form-field appearance="outline" [ngClass]="bphChange.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''">
      <input matInput [attr.aria-label]="confChange.label[selectedLanguage]" [(ngModel)]="bphChange.value" (click)="picker.open()"
             [matDatepicker]="picker" [max]="bphChange.maxDate ? bphChange.maxDate : maxDate" [min]="bphChange.minDate ? bphChange.minDate : minDate" (input)="changeValueEvent($event)" (dateInput)="addEvent(event)"
             [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'" [lang]="selectedLanguage"></mat-datepicker>
    </mat-form-field>
    <span *ngIf="bphChange?.error[selectedLanguage]" class="formFieldError">{{ bphChange.error[selectedLanguage]}}</span>
  </div>
</td>


<td style="border:0;width: 1%;text-align: center" *ngIf="isValidTableReadonly && !bphChange.hideCell  && !confChange.hideCell && !row.isTotal " [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
    [attr.rowspan]=" bphChange.rowspan">
  <div class="field {{ bphChange.error_class}} hide_{{ bphChange.show}}">
    {{ bphChange.value | eCaseDateFormatter}}
    <span *ngIf="bphChange?.error[selectedLanguage]" class="tableFormFieldError">{{ bphChange.error[selectedLanguage]}}</span>
  </div>
</td>
