<div>
  <table>
    <tr>
      <td>
        <i class="material-icons" style="font-size: 50pt">warning</i>
      </td>
      <td style="padding-left: 50px">
        <h1 mat-dialog-title
            style="margin: 0"> {{getTranslatedValue('ecase.researcherdasboard.projects.validationfailed')}}</h1>
        <h4
          style="margin: 0"> {{getTranslatedValue('ecase.researcherdasboard.projects.reviewtocontinue')}}</h4>
      </td>
    </tr>
  </table>
</div>
<mat-dialog-content>
  <div>
    <table class="angular-table" style="width: 100%;box-shadow: none;border: 0;">
      <thead class="angular-table-thead">
      <tr class="angular-table-tr">
        <th class="angular-table-th">
          Section
        </th>
        <th class="angular-table-th">
          {{ lang === 'en' ? 'Field' : 'Champ'}}
        </th>
        <th class="angular-table-th">
          {{ lang === 'en' ? 'Validation errors' : 'Erreur de validation'}}
        </th>
      </tr>

      </thead>
      <tbody>
      <tr *ngFor="let error of errors" class="angular-table-tr">
        <td *ngIf="!error.isDuplicateSection" [attr.rowspan]="error.rowSpan" class="angular-table-td"
            style="border-right: 0;text-align: center;">
          {{error.section[lang]}}
        </td>
        <td class="angular-table-td" style="border-left: 1px solid rgba(0, 0, 0, 0.12); text-align: center;">
          <span *ngIf="error.blockLabel && getLength(error.blockLabel[lang]) >= 50" matTooltip="{{stripHtml(error.blockLabel[lang])}}">{{addEllipses(error.blockLabel[lang])}}</span>
          <span *ngIf="error.blockLabel && getLength(error.blockLabel[lang]) < 50" [innerHTML]="error.blockLabel[lang]"></span>
        </td>
        <td class="angular-table-td" style="border-left: 1px solid rgba(0, 0, 0, 0.12); text-align: center;">
          {{error.errorMessage[lang]}}
        </td>
      </tr>

      </tbody>
    </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="eCase-dialog-action-button-align">
    <button (click)="onClose()" class="eCase-dialog-negative-action-button"
            mat-raised-button
            style=" margin-left: 0 !important; color: white;">{{lang === 'en' ? 'Close' : 'Fermer'}}
    </button>
  </div>
</mat-dialog-actions>
