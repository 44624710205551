import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-ecase-file-renamer',
  templateUrl: './ecase-file-renamer.component.html',
  styleUrls: ['./ecase-file-renamer.component.scss']
})
export class EcaseFileRenamerComponent implements OnInit {

  @Input() fileName: string;
  regex = new RegExp('[^.]+$');
  suffix = '';
  fileRenamerForm: FormGroup;

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<EcaseFileRenamerComponent>) {
  }

  ngOnInit(): void {
    if (!this.fileName) {
      this.fileName = '';
    }
    const matches = this.fileName.match(this.regex);
    if (matches.length > 0) {
      this.suffix = '.' + matches[0];
    }
    const obj = {
      newFileName: [this.fileName.replace(this.suffix, ''), [Validators.required, this.newNameValidator]],
    };
    this.fileRenamerForm = this.formBuilder.group(obj);
  }

  newNameValidator: ValidatorFn = (control: FormControl) => {
    const newName = control.value;
    if (newName && ((newName + this.suffix) === this.fileName)) {
      return {
        oldName: true
      };
    }
    return null;
  };

  getTextLength(l): number {
    try {
      return this.fileRenamerForm.controls[l].value ? ((this.fileRenamerForm.controls[l].value).toString()).length : 0;
    } catch (e) {
      return 0;
    }
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    let result;
    if (this.fileRenamerForm.controls['newFileName'].value.endsWith(this.suffix)) {
      result = this.fileRenamerForm.controls['newFileName'].value;
    } else {
      result = this.fileRenamerForm.controls['newFileName'].value + this.suffix;
    }
    this.dialogRef.close(result);
  }

}
