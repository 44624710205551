<div class="external-wrapper">

<div *ngIf="isFormInfoLoaded" fxLayout="column" id="external-referee-id" class="referee-container">
      {{selectedLang}}
    <div class="title" >{{ 'ecase.reference.title' | translate }}</div>

    <form *ngIf="externalRefereeForm" [formGroup]="externalRefereeForm" name="externalRefereeForm" (ngSubmit)="onSubmit()">
        <mat-label> {{ 'ecase.reference.studentName.label' | translate }}</mat-label>
        <mat-form-field appearance="outline" class="mt-0" disabled="true">
            <input matInput [formControlName]="'name'">
        </mat-form-field>
        <mat-label>{{ 'ecase.reference.studentEmail.label' | translate }}</mat-label>
        <mat-form-field appearance="outline" class="mt-0" disabled="true">
            <input matInput [formControlName]="'email'">
        </mat-form-field>
        <mat-label>{{ 'ecase.reference.applicationId.label' | translate }}</mat-label>
        <mat-form-field appearance="outline" class="mt-0" disabled="true">
            <input matInput [formControlName]="'caseApplicationId'">
        </mat-form-field>
        <mat-label>{{ 'ecase.reference.awardName.label' | translate }}</mat-label>
        <mat-form-field appearance="outline" class="mt-0" disabled="true">
            <input matInput [formControlName]="'awardName'">
        </mat-form-field>

        <p>{{ 'ecase.reference.step1.text' | translate }}</p>
        <div class="file-input-container">
<!--            <label for="downloadFile" class="file-input-button">{{ lang === 'en' ? 'Download' : 'Télécharger' }}</label>-->
            <div id="downloadFile" class="file-input-button" (click)="downloadFile()" >{{ 'ecase.common.download' | translate }}</div>
        </div>

        <p [innerHTML]=" 'ecase.reference.step2.text' | translate "></p>

        <div class="file-input-container">
            <label for="uploadFile" class="shadow-button">
            <span class="icon-wrapper">
                <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">cloud_upload</mat-icon>
            </span>
                {{ 'ecase.reference.upload.label' | translate }}</label>
            <input type="file" id="uploadFile" (change)="onFileInput($event)" class="file-input" [formControlName]="'uploadFile'" [required]>
            <span class="upload-file-name" *ngIf="documentSelected[0]">{{ documentSelected[0].name }}</span>
        </div>
        <p>{{ 'ecase.reference.step3.text' | translate}}</p>
        <mat-label>{{ 'ecase.reference.email.label' | translate }}</mat-label>
        <mat-form-field appearance="outline" class="mt-0" disabled="true">
            <input matInput [formControlName]="'checkEmail'">
        </mat-form-field>
        <mat-dialog-actions>
            <div class="submit-row">
                <button [disabled]="!externalRefereeForm.valid" mat-raised-button class="submit-button" type="submit">{{ 'ecase.commons.submit' | translate }}</button>
            </div>
        </mat-dialog-actions>

    </form>
</div>

</div>
