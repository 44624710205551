<div *ngIf="bphChange.globalShowDetail && !bphChange.hide" fxLayout="{{confChange.isElementInline ? 'row' : 'column'}}"
     fxLayoutAlign="{{confChange.alignment ? confChange.alignment : 'start'}}">
  <ng-container *ngFor="let subBlock of confChange.subBlocks;last as isLast;let i=index;">
    <ng-container [ngSwitch]="subBlock.templateName">
      <ng-container *ngSwitchCase="'statictext'">
        <ng-container *ngTemplateOutlet="statictext; context: subBlock.templateContext"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'textarea'">
        <ng-container *ngTemplateOutlet="textarea; context: subBlock.templateContext"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Radiobutton'">
        <ng-container *ngTemplateOutlet="Radiobutton; context: subBlock.templateContext"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Checkbox'">
        <ng-container
          *ngTemplateOutlet="Checkbox; context: subBlock.templateContext"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'select'">
        <ng-container *ngTemplateOutlet="select; context: subBlock.templateContext"></ng-container>
      </ng-container>
      <!--  <ng-container *ngSwitchCase="'Container'">
          <ng-container *ngTemplateOutlet="Container; context: subBlock.templateContext"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Table'">
          <ng-container *ngTemplateOutlet="Table; context: subBlock.templateContext"></ng-container>
        </ng-container>-->
      <ng-container *ngSwitchCase="'datepicker'">
        <ng-container *ngTemplateOutlet="datepicker; context: subBlock.templateContext"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'SubmitButton'">
        <ng-container *ngTemplateOutlet="SubmitButton; context: subBlock.templateContext"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'upload'">
        <ng-container
          *ngTemplateOutlet="upload; context: {'templateContext' : subBlock.templateContext, 'blockIndex' : (blockIndex + ((i + 1) / 100))}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'text'">
        <ng-container *ngTemplateOutlet="text; context: subBlock.templateContext"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Signature'">
        <ng-container *ngTemplateOutlet="Signature; context: subBlock.templateContext"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'AutoComplete'">
        <ng-container *ngTemplateOutlet="AutoComplete; context: subBlock.templateContext"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'button'">
        <ng-container
          *ngTemplateOutlet="button; context: {'subBlock' : subBlock.templateContext, 'isLastPosition' : isLast}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchDefault></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #AutoComplete let-confChange="confChange" let-isValidHtml="isValidHtml"
             let-isValidTable="isValidTable" let-isValidTableReadonly="isValidTableReadonly">
  <fb-autoComplete
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(confChange,(globalPrj[selectedSectionId])[containerConfChange.name][confChange.name]) : doNothing()'
    [bphChange]='(globalPrj[selectedSectionId])[containerConfChange.name][confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    style='position: static;'>
  </fb-autoComplete>
</ng-template>
<ng-template #upload let-blockIndex="blockIndex" let-blockName="blockName" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-upload
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(confChange,(globalPrj[selectedSectionId])[containerConfChange.name][blockName]) : doNothing()'
    [blockIndex]="blockIndex" [bphChange]='(globalPrj[selectedSectionId])[containerConfChange.name][confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    style='position: static;padding-right:20px;'>
  </fb-upload>
</ng-template>

<ng-template #Signature let-blockName="blockName"  let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly">
  <fb-signature  [bphChange]='(globalPrj[selectedSectionId])[containerConfChange.name][confChange.name]'
                 [confChange]='confChange'
                 [globalConf]='globalConf'
                 [globalPrj]='globalPrj'
                 [isValidHtml]='isValidHtml'
                 [isValidTableReadonly]='isValidTableReadonly'
                 [isValidTable]='isValidTable'
                 [selectedLanguage]='selectedLanguage'
                 [selectedSectionId]='selectedSectionId'
                style='position: static;'>
  </fb-signature>
</ng-template>

<ng-template #text let-blockName="blockName"  let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly">
  <fb-text
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(confChange,(globalPrj[selectedSectionId])[containerConfChange.name][blockName]) : doNothing()'
    [bphChange]='(globalPrj[selectedSectionId])[containerConfChange.name][confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    style='position: static;padding-right:20px;'>
  </fb-text>
</ng-template>
<ng-template #button let-confChange="subBlock.confChange"
             let-isLastPosition="isLastPosition"
             let-isValidHtml="subBlock.isValidHtml" let-isValidTable="subBlock.isValidTable"
             let-isValidTableReadonly="subBlock.isValidTableReadonly">
  <fb-button [bphChange]='(globalPrj[selectedSectionId])[containerConfChange.name][confChange.name]' [confChange]='confChange'
             [sections]="sections"
             [globalConf]='globalConf'
             [globalPrj]='globalPrj'
             [isFormbuilderModeEnabled]="isFormbuilderModeEnabled"
             [isLastPosition]="isLastPosition"
             [isValidHtml]='isValidHtml'
             [isValidTableReadonly]='isValidTableReadonly'
             [isValidTable]='isValidTable'
             [offlineModeEnabled]='offlineModeEnabled'
             [selectedLanguage]='selectedLanguage'
             [selectedSectionId]='selectedSectionId'
             style='position: static;'></fb-button>
</ng-template>
<ng-template #SubmitButton  let-confChange="confChange" let-isLast="isLast"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly">
  <fb-submit-button [bphChange]='(globalPrj[selectedSectionId])[containerConfChange.name][confChange.name]' [confChange]='confChange'
                    [globalConf]='globalConf'
                    [globalPrj]='globalPrj'
                    [isFormbuilderModeEnabled]="isFormbuilderModeEnabled"
                    [isValidHtml]='isValidHtml'
                    [isValidTableReadonly]='isValidTableReadonly'
                    [isValidTable]='isValidTable'
                    [selectedLanguage]='selectedLanguage'
                    [selectedSectionId]='selectedSectionId'
                    [style.padding-right.px]="isLast ? 0 : 20"
                    style='position: static;'></fb-submit-button>
</ng-template>
<ng-template #statictext  let-confChange="confChange" let-isValidHtml="isValidHtml"
             let-isValidTable="isValidTable" let-isValidTableReadonly="isValidTableReadonly">
  <fb-static-text [bphChange]='(globalPrj[selectedSectionId])[containerConfChange.name][confChange.name]'
                  [confChange]='confChange'
                  [globalConf]='globalConf'
                  [globalPrj]='globalPrj'
                  [isValidHtml]='isValidHtml'
                  [isValidTableReadonly]='isValidTableReadonly'
                  [isValidTable]='isValidTable'
                  [selectedLanguage]='selectedLanguage'
                  style='position: static;'>
  </fb-static-text>
</ng-template>
<ng-template #textarea let-blockName="blockName"  let-confChange="confChange"
             let-isValidHtml="isValidHtml"
             let-isValidTable="isValidTable" let-isValidTableReadonly="isValidTableReadonly">
  <fb-text-area
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(confChange,(globalPrj[selectedSectionId])[containerConfChange.name][blockName]) : doNothing()'
    [bphChange]='(globalPrj[selectedSectionId])[containerConfChange.name][confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    style='position: static;'>
  </fb-text-area>
</ng-template>
<ng-template #Radiobutton let-blockName="blockName"  let-confChange="confChange"
             let-isValidHtml="isValidHtml"
             let-isValidTable="isValidTable" let-isValidTableReadonly="isValidTableReadonly">
  <fb-radiobutton (emitOutputEvent)="executeFunction(confChange,(globalPrj[selectedSectionId])[containerConfChange.name][blockName])"
                  [bphChange]='(globalPrj[selectedSectionId])[containerConfChange.name][confChange.name]'
                  [confChange]='confChange'
                  [globalConf]='globalConf'
                  [globalPrj]='globalPrj'
                  [isValidHtml]='isValidHtml'
                  [isValidTableReadonly]='isValidTableReadonly'
                  [isValidTable]='isValidTable'
                  [selectedLanguage]='selectedLanguage'
                  style='position: static;'>
  </fb-radiobutton>
</ng-template>
<ng-template #Checkbox let-blockName="blockName"  let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly">
  <fb-checkbox (emitOutputEvent)="executeFunction(confChange,(globalPrj[selectedSectionId])[containerConfChange.name][blockName])"
               [bphChange]='(globalPrj[selectedSectionId])[containerConfChange.name][confChange.name]'
               [confChange]='confChange'
               [globalConf]='globalConf'
               [globalPrj]='globalPrj'
               [isValidHtml]='isValidHtml'
               [isValidTableReadonly]='isValidTableReadonly'
               [isValidTable]='isValidTable'
               [selectedLanguage]='selectedLanguage'
               style='position: static;'>
  </fb-checkbox>
</ng-template>

<ng-template #select  let-confChange="confChange" let-isValidHtml="isValidHtml"
             let-isValidTable="isValidTable" let-isValidTableReadonly="isValidTableReadonly">
  <fb-select (emitOutputEvent)="executeFunction(confChange,(globalPrj[selectedSectionId])[containerConfChange.name][confChange.name])"
             [bphChange]='(globalPrj[selectedSectionId])[containerConfChange.name][confChange.name]'
             [confChange]='confChange'
             [globalConf]='globalConf'
             [globalPrj]='globalPrj'
             [isValidHtml]='isValidHtml'
             [isValidTableReadonly]='isValidTableReadonly'
             [isValidTable]='isValidTable'
             [selectedLanguage]='selectedLanguage'
             [selectedSectionId]='selectedSectionId'
             style='position: static;'>
  </fb-select>
</ng-template>
<ng-template #datepicker let-blockName="blockName"  let-confChange="confChange" let-isValidHtml="isValidHtml"
             let-isValidTable="isValidTable" let-isValidTableReadonly="isValidTableReadonly">
  <fb-datepicker
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(confChange,(globalPrj[selectedSectionId])[containerConfChange.name][blockName]) : doNothing()'
    [bphChange]='(globalPrj[selectedSectionId])[containerConfChange.name][confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    style='position: static;'>
  </fb-datepicker>
</ng-template>
