import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'fb-calculated-output',
  templateUrl: './fb-calculated-output.component.html',
  styleUrls: ['./fb-calculated-output.component.css']
})
export class FbCalculatedOutputComponent implements OnInit {
  lang: any;
  @Input() selectedLanguage: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() globalPrj: any;
  row: any;
  i: number;


  constructor(private translate: TranslateService) {
    this.confChange = {};
    this.row = {};
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.value.label = {};
    this.confChange.label = {};
    this.bphChange.error = {};
    this.bphChange.source = {};
  }

  getCalculatedOutput(label, lang, outputContent) {
    const r = '';
    eval('let prj= this.globalPrj;');
    try {
      eval('r = ' + label);
      if (outputContent)
        {outputContent.value = r;}

    } catch (e) {
      console.error(e);
      return 0;
    }
  }

  ngOnInit() {

    this.selectedLanguage = this.translate.getDefaultLang();
    /*this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;

    });*/

    eval('this.confChange.initValue =function(currentValue,localConf){\n' +
      '\t\n' +
      '}');
  }

}
