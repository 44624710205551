import {Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {ECaseUtils} from 'synto-common';
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs/internal/Subscription";


@Component({
  selector: 'app-ecase-multi-select-autocomplete-field',
  templateUrl: './ecase-multi-select-autocomplete-field.component.html',
  styleUrls: ['./ecase-multi-select-autocomplete-field.component.css']
})
export class EcaseMultiSelectAutocompleteFieldComponent implements OnInit, OnChanges, DoCheck, OnDestroy {
  @Input() selectPlaceholder ="ecase.common.table.filter.input.placeholder";
  @Input() placeholder: string;
  @Input() options;
  @Input() disabled = false;
  @Input() display = "display";
  formControlInput: FormControl

  @Input('formControlInput') set _control(value: AbstractControl) {
    this.formControlInput = value as FormControl
  }

  @Input() errorMsg = "Field is required";
  @Input() showErrorMsg = false;
  @Input() selectedOptions;
  @Input() multiple = true;
  searchFieldValue = '';
  subscription: Subscription;

  // New Options
  @Input() labelCount = 1;
  @Input() appearance = "outline";

  @Output()
  selectionChange: EventEmitter<any> = new EventEmitter();

  @ViewChild("selectElem") selectElem;

  filteredOptions: Array<any> = [];
  selectedValue: Array<any> = [];
  selectAllChecked = false;
  displayString = "";
  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    if (!this.formControlInput) {
      this.formControlInput = new FormControl();
    }
    this.subscription = this.formControlInput.valueChanges.subscribe((newValue) => {
        if (newValue === null) {
          this.searchFieldValue = '';
        }
    });
    }

  ngOnChanges() {
    if (this.disabled) {
      this.formControlInput.disable();
    } else {
      this.formControlInput.enable();
    }
    this.filteredOptions = this.options;
    if (this.selectedOptions) {
      this.selectedValue = this.selectedOptions;
    } else if (this.formControlInput.value) {
      this.selectedValue = this.formControlInput.value;
    }
  }

  ngDoCheck() {
    if (this.selectedValue.length > 0) {
      // this.selectionChange.emit(this.selectedValue);
    }
  }

  toggleDropdown() {
    this.selectElem.toggle();
  }

  toggleSelectAll(val) {
    if (val.checked) {
      this.filteredOptions.forEach(option => {
        if (!this.selectedValue.includes(option.id)) {
          this.selectedValue = this.selectedValue.concat([option.id]);
        }
      });
    } else {
      const filteredValues = this.getFilteredOptionsValues();
      this.selectedValue = this.selectedValue.filter(
        item => !filteredValues.includes(item)
      );
    }
    this.selectionChange.emit(this.selectedValue);
  }

  filterItem(value) {
    this.filteredOptions = this.options.filter(
      item => {
        return ECaseUtils.getTranslatedValueFromKey(this.translate, item.fullName).toLowerCase().includes(value.toLowerCase());
      }
    );
    this.selectAllChecked = true;
    this.filteredOptions.forEach(item => {
      if (!this.selectedValue.includes(ECaseUtils.getTranslatedValueFromKey(this.translate, item.fullName).toLowerCase())) {
        this.selectAllChecked = false;
      }
    });
    if (!this.filteredOptions.length) {
      this.selectAllChecked = false;
    }
  }

  hideOption(option) {
    return !(this.filteredOptions.indexOf(option) > -1);
  }

  // Returns plain strings array of filtered values
  getFilteredOptionsValues() {
    const filteredValues = [];
    this.filteredOptions.forEach(option => {
      filteredValues.push(option.id);
    });
    return filteredValues;
  }

  onDisplayString() {
    this.displayString = "";
    if (this.selectedValue && this.selectedValue.length) {
      let displayOption = [];
      if (this.multiple) {
        // Multi select display
        for (let i = 0; i < this.labelCount; i++) {
          displayOption[i] = this.options.filter(
            option => option.id === this.selectedValue[i]
          )[0];
        }
        if (displayOption.length) {
          for (let i = 0; i < displayOption.length; i++) {
            if (displayOption[i] && displayOption[i].fullName) {
              this.displayString += ECaseUtils.getTranslatedValueFromKey(this.translate, displayOption[i].fullName) + ",";
            }
          }
          this.displayString = this.displayString.slice(0, -1);
          if (
            this.selectedValue.length > 1 &&
            this.selectedValue.length > this.labelCount
          ) {
            this.displayString += ` (+${this.selectedValue.length -
            this.labelCount} ${ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.others')})`; //
          }
        }
      } else {
        // Single select display
        displayOption = this.options.filter(
          option => option.id === this.selectedValue
        );
        if (displayOption.length) {
          this.displayString = displayOption[0][this.display];
        }
      }
    }
    return this.displayString;
  }

  onSelectionChange(val) {
    const filteredValues = this.getFilteredOptionsValues();
    let count = 0;
    if (this.multiple) {
      this.selectedValue.filter(item => {
        if (filteredValues.includes(item)) {
          count++;
        }
      });
      this.selectAllChecked = count === this.filteredOptions.length;
    }
    this.selectedValue = val.value;
    this.selectionChange.emit(this.selectedValue);
  }
  // fixHeightConstraint(): void {
  //   setTimeout(() => {
  //       const container = document.getElementsByClassName('cdk-overlay-pane');
  //       const size = container.length;
  //       for (let i = 0; i < size; i++) {
  //        // const documentSelectField = container.item(i).getElementsByClassName('ng-tns-c131-35 ng-trigger ng-trigger-transformPanel mat-select-panel mat-primary');
  //        // for (let j = 0; j < documentSelectField.length; j++) {
  //         container.item(i).setAttribute('style', 'top: 563.156px !important;\n' +
  //             'left: 330.5px!important;');
  //        // }
  //       }
  //     }, 10);
  //
  // }
  public trackByFn(index, item) {
    return item.value;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
