<div *ngIf="isValidHtml" [id]="confChange.name + 'noPaddingAndMargin'" class="noPaddingMargin" fxLayout="row"
     fxLayoutAlign="{{confChange.alignment}}">
  <div *ngIf="bphChange?.globalShowDetail">
    <div *ngIf="!bphChange.hide" [ngClass]="confChange.rightAlign ? 'right-align' : 'left-align'"
         class="ui grid field hide_{{bphChange.show}}">
      <div class="formLabel five wide column righttext"></div>
      <div class="nine wide column field" style="flex-direction: row;" [style.display]="(confChange.dataSQL || confChange.takesValueFromJS) ? 'inline-flex !important' : ''">
        <span *ngIf="confChange.isRequired" style="color: red; padding-right: 5px;">* </span>
        <span *ngIf="!confChange.isStaticTextBeingControlled" [attr.aria-label]="confChange.label[selectedLanguage]"
              [style]="confChange.style"
              [innerHTML]="confChange.label[selectedLanguage]"
              [ngClass]="{'bold': confChange.isBold}"></span>
        <span *ngIf="confChange.isStaticTextBeingControlled && !confChange.isValueParameterized"
              [style]="confChange.style"
              [attr.aria-label]="confChange.label[selectedLanguage]"
              [ngClass]="{'bold': confChange.isBold}">{{bphChange.value[confChange.paramterName] | translate}}  </span>

        <span *ngIf="confChange.isStaticTextBeingControlled && confChange.isValueParameterized" [attr.aria-label]="confChange.label[selectedLanguage]"
              [style]="confChange.style"
              [ngClass]="{'bold': confChange.isBold}" fxLayout="row">
            <span [innerHTML]="confChange.label[selectedLanguage]"></span>
          <span style="padding-left: 5px" [innerHTML]="(bphChange.value[confChange.paramterName + '_' + selectedLanguage] ? bphChange.value[confChange.paramterName + '_' + selectedLanguage] : bphChange.value[confChange.paramterName]) | translate">
          </span>
        </span>

        <span *ngIf="confChange.dataSQL" [attr.aria-label]="confChange.label[selectedLanguage]" [innerHTML]="getCalculatedOutputForDataSQL() + getPostScriptLabel()"
              [style]="confChange.style"
              [ngClass]="{'bold': confChange.isBold}"
              style="padding-left: 5px;">
       </span>
        <span *ngIf="confChange.takesValueFromJS" [attr.aria-label]="confChange.label[selectedLanguage]" [innerHTML]="getCalculatedGlobalSubtotalFromJS() + getPostScriptLabel()"
              [style]="confChange.style"
              [ngClass]="{'bold': confChange.isBold}"
              style="padding-left: 5px;">
       </span>
        <span *ngIf="confChange.hasTooltip">
           <mat-icon [matTooltipPosition]="confChange.toolTipPosition ? 'confChange.toolTipPosition' : 'above'" matTooltip='{{confChange.tooltipText[selectedLanguage]}}'
                     matTooltipClass="tooltip">
          help_outline
        </mat-icon>
       </span>
        <span *ngIf="confChange.hasDialog">
         <mat-icon (click)="openDialog()" style="cursor: pointer;">help_outline</mat-icon>
       </span>
        <span *ngIf="bphChange?.error[selectedLanguage]"
              style="float: left; font-weight: 700;color:red; padding: 14px;">{{bphChange.error[selectedLanguage]}} </span>
      </div>
    </div>
  </div>
</div>

<div [style.display]="confChange.isCustomLabel ? 'flex' : ''"
     [style.justify-content]="confChange.alignment ? confChange.alignment : null ">
  <td *ngIf="isValidTable && !row.isTotal " style="border:0;text-align: center;padding-right:20px !important; display: block">
    <div class="hide_{{bphChange.show}} noPaddingMargin">
      <span *ngIf="!confChange.isStaticTextBeingControlled" [attr.aria-label]="confChange.label[selectedLanguage]"
            [style]="confChange.style"
            [innerHTML]="confChange.isCustomLabel ? bphChange.value : confChange.label[selectedLanguage]"
            [ngClass]="{'bold': confChange.isBold}"></span>
      <div *ngIf="confChange.isStaticTextBeingControlled" [attr.aria-label]="confChange.label[selectedLanguage]"
           [innerHTML]="getCalculatedOutput() + getPostScriptLabel()" [ngClass]="{'bold': confChange.isBold}"></div>
      <span *ngIf="confChange.dataSQL" [attr.aria-label]="confChange.label[selectedLanguage]" [innerHTML]="'<p>'+getCalculatedOutputForDataSQL() + getPostScriptLabel() +'</p>'"
            [style]="confChange.style"
            [ngClass]="{'bold': confChange.isBold}"
            style="padding-left: 5px;"></span>
    </div>
  </td>
</div>


<td *ngIf="isValidTableReadonly && !bphChange.hideCell && !confChange.hideCell && !row.isTotal  "
    [attr.rowspan]="bphChange.rowspan"
    style="border:0;width: 10%;text-align: center;padding-right: 20px !important;">
  <div class="noPaddingMargin">
    <span *ngIf="!confChange.isStaticTextBeingControlled" [attr.aria-label]="confChange.label[selectedLanguage]"
          [innerHTML]="confChange.isCustomLabel ? bphChange.value : confChange.label[selectedLanguage]"
          [style]="confChange.style"
          [ngClass]="{'bold': confChange.isBold}"></span>
    <div *ngIf="confChange.isStaticTextBeingControlled" [attr.aria-label]="confChange.label[selectedLanguage]"
         [innerHTML]="getCalculatedOutput() + getPostScriptLabel()" [ngClass]="{'bold': confChange.isBold}"></div>
    <span *ngIf="confChange.dataSQL" [attr.aria-label]="confChange.label[selectedLanguage]" [innerHTML]="'<p>'+getCalculatedOutputForDataSQL() + getPostScriptLabel() + '</p>'"
          [style]="confChange.style"
          [ngClass]="{'bold': confChange.isBold}"
          style="padding-left: 5px;"></span>
  </div>
</td>
