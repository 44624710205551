import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {editor} from 'monaco-editor';
import {DiffEditorModel} from 'ngx-monaco-editor';
import IMarker = editor.IMarker;


@Component({
  selector: 'ecase-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.scss']
})
export class CodeEditorComponent implements OnInit, AfterViewInit {

  @Input() content;
  @Input() modifiedContent;
  @Input() mode;
  @Input() language;
  @Input() isReadOnly: boolean;
  @Input() isDisabled: boolean;
  @Input() knownVariables: string[];
  @Output() onContentChange = new EventEmitter<string>();
  @Output() validationErrors = new EventEmitter<IMarker[]>();
  @Input() ariaLabel;
  editorOptions;
  editor: any;
  originalModel: DiffEditorModel = {
    code: '',
    language: ''
  };

  modifiedModel: DiffEditorModel = {
    code: '',
    language: ''
  };

  _contentHeight;

  @Input('contentHeight') set contentHeight(height) {
    this._contentHeight = height;
    if (height && this._codeEditorElement) {
      const editorContainer = this._codeEditorElement.nativeElement.getElementsByClassName('editor-container')[0];
      if (editorContainer) {
        editorContainer.setAttribute('style', 'min-height:' + this._contentHeight + 'px;');
      }
    }
  }

  private _codeEditorElement: ElementRef;

  @ViewChild('codeEditor', {static: false, read: ElementRef}) set codeEditorElement(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this._codeEditorElement = content;
    }
  };

  contentChanged(event): void {
    this.onContentChange.emit(event);
  }

  init(event): void {
    this.editor = event;
    if (typeof this.editor.onDidChangeModelDecorations === 'function') {

      /*
      TODO: getModel() does not exist anymore in the event. We need to figure out another way to do this
      this.editor.onDidChangeModelDecorations(() => {

        const model = this.editor.getModel();
        if (model === null || (model.getModeId() !== 'javascript' && model.getModeId() !== 'json' )) {
          return;
        }

        const owner = model.getModeId();
        const markers = (window as any).monaco.editor.getModelMarkers({owner}).filter(marker => this.knownVariables && this.knownVariables
          .filter(variable => marker.message.includes(variable)).length === 0);
        console.log(markers);
        this.validationErrors.emit(markers);
      });
      */

    }
  }


  ngOnInit(): void {
    if (!this.language) {
      throw new Error('Language input is not defined');
    }
    if (!this.ariaLabel) {
      this.ariaLabel = 'Code editor';
    }
    if (!this.content || this.content === '') {
      this.content = '\n\n\n\n';
    }
    if (!this.modifiedContent || this.modifiedContent === '') {
      this.modifiedContent = '\n\n\n\n';
    }
    if (!this.mode) {
      this.mode = 'single';
    }
    if (this.mode === 'diff') {
      this.originalModel.code = this.content;
      this.originalModel.language = this.language;
      this.modifiedModel.code = this.modifiedContent;
      this.modifiedModel.language = this.language;
    }
    this.editorOptions = {
      'value': this.content,
      'language': this.language,
      'readOnly': !!this.isReadOnly,
      'ariaLabel': this.ariaLabel,
      'automaticLayout': true,
      'dragAndDrop': true
    };
  }

  ngAfterViewInit(): void {
    if (this._contentHeight) {
      const editorContainer = this._codeEditorElement.nativeElement.getElementsByClassName('editor-container')[0];
      if (editorContainer) {
        editorContainer.setAttribute('style', 'min-height:' + this._contentHeight + 'px;');
      }
    }
  }

}
