import {Component, DoCheck, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ECaseUtils, ECaseUtilsGlobal, PendingChangesGuard} from 'synto-common';

@Component({
  selector: 'fb-checkbox',
  templateUrl: './fb-checkbox.component.html',
  styleUrls: ['./fb-checkbox.component.scss']
})
export class FbCheckboxComponent implements OnInit, DoCheck {
  @Input() selectedLanguage: any;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() globalConf: any;
  @Input() globalPrj: any;
  @Output() emitOutputEvent = new EventEmitter<any>();
  isValid: boolean;
  lang: any;
  row: any;
  list: any[] = [];
  otherValue = {
    'label': {},
    'value': ECaseUtilsGlobal.OTHER_VALUE_TERM_ID,
    'sortingKey': ECaseUtilsGlobal.OTHER_VALUE_TERM_ID
  }; // Labels are added in ngOnInit method
  differ: KeyValueDiffer<string, any>;


  constructor(private translate: TranslateService, public dialog: MatDialog, private pendingChangesGuard: PendingChangesGuard, private differs: KeyValueDiffers) {
    this.confChange = {};
    this.confChange.label = {};
    this.isValid = false;
    this.bphChange = {};
    this.bphChange.value = [];
    this.bphChange.listName = this.confChange.listName;
    this.bphChange.error = {};
    this.row = {};
    this.differ = this.differs.find({}).create();
  }

  ngDoCheck(): void {
    const change = this.differ.diff(this.bphChange);
    if (change) {
      if (this.bphChange.value.filter(item => item.value).length === 0 && this.bphChange.value.length === 0) {
        this.list.forEach((listItem) => {
          this.bphChange.value.push({'value': false, 'option': listItem});
        });
      }
      if (this.bphChange.value.some(e => !e)) {
        this.bphChange.value = [];
        this.list.forEach((listItem) => {
          this.bphChange.value.push({'value': false, 'option': listItem});
        });
      }
    }
  }

  removeItemsToHide(list: any[]): any[] {
    if (this.confChange.lovItemsToHide && this.confChange.lovItemsToHide.length > 0) {
      list = list.map((item) => {
        item.hide = this.confChange.lovItemsToHide.includes(item.value);
        return item;
      });
    }
    if (this.bphChange.lovItemsToHide && this.bphChange.lovItemsToHide.length > 0) {
      list = list.map((item) => {
        item.hide = this.bphChange.lovItemsToHide.includes(item.value);
        return item;
      });
    }
    return list.sort((a, b) => {
      if (a.option && b.option) {
        return a.option.sortingKey - b.option.sortingKey;
      } else {
        return 0;
      }
    });
  }

  checkIfDialogOptionsAreEnabled(option): boolean {
    return this.confChange.showDialogForOptions && this.confChange.optionsDialogList.filter(item => item.id === option).length > 0;
  }


  change(event, c, s, i, option): void {
    s.value[i].value = event;
    if (event) {
      s.value[i].option = option;
    }
    let b = false;
    for (let j = 0; j < s.value.length; j++) {


      if (s.value[j].value && j === c.disableValue) {
        b = true;
        break;
      }
    }
    if (b) {
      for (let j = 0; j < s.value.length; j++) {
        if (c.disableValue !== j) {


          s.value[j].value = false;
        }
      }
    }
    if (!s.listName && this.confChange.listName) {
      s.listName = this.confChange.listName;
    }
    this.globalConf.lovs[s.listName].list.forEach((e) => {
      if (e.value === event) {
        s.value.label = e.label;
        if (c.subList) {
          // eslint-disable-next-line no-eval
          eval('globalPrj.' + c.subList + '.listName=e.subList;');
        }
      }
    });
    this.changeValueEvent(option);
    /*
    const testVariable: any = window;
    testVariable.isFormModified = ECaseUtils.isGlobalPrjPristine(this.globalPrj, testVariable.pristinePrj);
    */
    console.log(this.list);
  }


  disableOnValue(currentValue, localConf, i): boolean {
    if (localConf.isDisableOnValue) {
      try {
        let b = false;
        for (let j = 0; j < currentValue.value.length; j++) {
          if (currentValue.value[j].value && (j === localConf.disableValue || (""+j) ===  localConf.disableValue )) {
            b = true;
            break;
          }
        }
        if (b && localConf.disableValue !== i && (""+i) !==  localConf.disableValue ) {
          currentValue.value[i].value = false;
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    } else {
      return false;
    }

  }

  openDialog(): void {
    this.dialog.open(DialogComponent, {
      width: '600px',
      data:
        {
          dialog: this.confChange.dialogText,
          selectedLanguage: this.selectedLanguage
        }
    });
  }


  openDialogForOptions(option): void {
    const dialogObj = this.confChange.optionsDialogList.filter(item => item.id = option).map(item => item.dialogText)[0];
    this.dialog.open(DialogComponent, {
      width: '600px',
      data:
        {
          dialog: dialogObj,
          selectedLanguage: this.selectedLanguage
        }
    });
  }


  filter(arrayToBeFiltered: any[], labelToBeFiltered): any {
    arrayToBeFiltered.forEach((element) => {
      if (element.label[this.translate.getDefaultLang()] && element.label[this.translate.getDefaultLang()].includes(labelToBeFiltered)) {
        return element;
      }
    });
    return {};
  }

  isSortingKeySame(list: any[]): boolean {
    return list.every(item => item.sortingKey === list[0].sortingKey);
  }

  ngOnInit(): void {
    console.log('2222222222222229999999999')
    if (!(this.bphChange)) {
      this.bphChange = {};
      this.bphChange.value = [];
      this.bphChange.error = {};
    }

    if (!this.confChange.optionsDialogList) {
      this.confChange.optionsDialogList = [];
    }

    if (!(this.bphChange.value)) {
      this.bphChange.value = [];
    }
    if (this.bphChange.value === '') {
      this.bphChange.value = [];
    }
    if (!(this.bphChange.error)) {
      this.bphChange.error = {};
    }
    this.bphChange.listName = this.confChange.listName;
    this.confChange.getValue = (currentValue, lang): string => {
      if (currentValue.value && currentValue.value.label) {
        return currentValue.value.label[lang];
      } else {
        return '';
      }
    };

    this.confChange.initValue = (currentValue, localConf): void => {
      currentValue.value = {};
      currentValue.error = {};
      currentValue.listName = localConf.listName;
      currentValue.getValue = (): any => this.bphChange.value.value;
    };
    // clone deep to avoid missing with this list of it's used by other components, example if is other is enabled in one but not the other
    this.list = ECaseUtils.eCaseCloneDeep(Object.assign((this.globalConf.lovs[this.bphChange.listName].list)));
    if (this.hasDuplicates(this.list) === true) {
      this.list = this.globalConf.lovs[this.confChange.listName].list.sort((a, b) => a.label[this.translate.getDefaultLang()]
        .localeCompare(b.label[this.translate.getDefaultLang()]));
    }


    let index = this.list.indexOf(this.filter(this.list, 'None of these Methods'));
    const customNoFundingCheck = this.list.indexOf(this.filter(this.list, 'No Funding'));
    console.log(customNoFundingCheck);
    if (customNoFundingCheck >= 0) {
      this.list.unshift(this.list[customNoFundingCheck]);
      this.list.splice(customNoFundingCheck + 1, 1);
    }

    console.log(this.list);
    if (index >= 0) {
      this.list.push(this.list.splice(index, 1)[0]);
    } else {
      index = this.list.indexOf(this.filter(this.list, 'other'));
      if (index >= 0) {
        this.list.push(this.list.splice(index, 1)[0]);
      }
    }

    this.confChange.change = (nv, c, s, i, option): void => {
      s.value[i].value = nv;
      if (nv) {
        s.value[i].option = option;
      }
      let b = false;
      for (let j = 0; j < s.value.length; j++) {
        if (s.value[j].value && j === c.disableValue) {
          b = true;
          break;
        }
      }
      if (b) {
        for (let j = 0; j < s.value.length; j++) {
          if (c.disableValue !== j) {
            s.value[j].value = false;
          }
        }
      }
      if (this.globalConf.lov) {
        this.list.forEach((e) => {
          if (e.value === nv) {
            s.value.label = e.label;
            if (c.subList) {
              // eslint-disable-next-line no-eval
              eval('globalPrj.' + c.subList + '.listName=e.subList;');
            }
          }
        });
      }
      console.log(nv);
      console.log(i);
      console.log(c);
      console.log(s);
      console.log(option);
      if (this.bphChange.hasOwnProperty('bphChange')) {
        delete this.bphChange.bphChange;
      }
      console.log(this.bphChange);

    };

    if (this.isSortingKeySame(this.list)) {
      this.list = this.list.sort((a, b) => a.label[this.translate.getDefaultLang()].localeCompare(b.label[this.translate.getDefaultLang()]));
    } else {
      this.list = this.list.sort((a, b) => {
        if (a.sortingKey < b.sortingKey) {
          return -1;
        } else if (a.sortingKey > b.sortingKey) {
          return 1;
        }
        return 0;
      });
    }
    try {
      console.log('>>>>>>>>>>>>');
      this.translate.langs.forEach((lang) => {
        if (lang && lang !== 'undefined') {
          this.otherValue.label[lang] = ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.other', lang);
        }
      });
      if (this.confChange.hasOther) {
        this.translate.langs.forEach((lang) => {
          if (lang && lang !== 'undefined') {
            this.otherValue.label[lang] = ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.other', lang);
          }
        });
        this.list.push(this.otherValue);
      }

      // creating an array with all option every time in case new items are added.
      var newVal = [];
      this.list.forEach((item, i) => {
        if (!newVal[i]) {
          newVal[i] = {};
          newVal[i].value = false;
          newVal[i].option = item;
        }
      });
      // getting the values from the existing array and putting them into the newly creatde one
      this.bphChange.value.forEach(elm => {
        newVal.forEach( oelm => {
          if(oelm.option.value === elm.option.value){
            oelm.value = elm.value;
            oelm.isDisabled = elm.isDisabled;
          }
        })
      } )
      this.bphChange.value = newVal;

      if (this.bphChange.value.filter(item => item.value).length === 0 && this.bphChange.value.length === 0) {
        this.list.forEach((listItem) => {
          this.bphChange.value.push({'value': false, 'option': listItem});
        });
      } else if (this.list.length > 0 && this.bphChange.value.length !== this.list.length) {
        this.list.forEach((listItem) => {
          if (this.bphChange.value.findIndex(value => value.option.value === listItem.value) === -1) {
            this.bphChange.value.push({'value': false, 'option': listItem});
          }
        });
      } else {
        if (this.confChange.hasOther && !this.bphChange.value.find(item => item.option.value === this.otherValue.value)) {
          this.bphChange.value.push({'value': false, 'option': this.otherValue});
        }
      }
    } catch (e) {
      console.error(e);
    }

    this.confChange.oldValue = ECaseUtils.eCaseCloneDeep(this.bphChange.value);
  }

  isOtherOptionChecked(): any {
    return this.bphChange.value.find(item => item.value && item.option.value === this.otherValue.value);
  }


  hasDuplicates(array: any[]): boolean {
    const valuesSoFar = [];
    array.forEach((item) => {
      const value = item.sortingKey;
      if (valuesSoFar.indexOf(value) !== -1) {
        return true;
      }
      valuesSoFar.push(value);
    });
    return false;
  }


  getValue(currentValue, lang): string {
    if (currentValue.value) {
      const checkedCheckboxes = currentValue.value.filter(item => item.value);
      let result = '';
      checkedCheckboxes.forEach((item, index) => {
        if (index < checkedCheckboxes.length - 1) {
          result = result + item.option.label[lang] + ',';
        } else {
          result = result + item.option.label[lang];
        }
      });
      return result;
    } else {
      return '';
    }
  }


  changeValueEvent(event): void {
    if (this.bphChange.isEnableOutputEvent || this.confChange.enableOutputEvent) {
      this.emitOutputEvent.emit(event);
    }
    this.pendingChangesGuard.isPristine = false;
  }


}
