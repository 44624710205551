import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET, Router} from '@angular/router';
import {ECaseUtils, SectionBreadCrumbService} from 'synto-common';
import {Platform} from '@angular/cdk/platform';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';


export class IBreadcrumb {

  label: string;
  params?: Params;
  url: string;

  constructor(label, url, params) {
    this.label = label;
    this.params = params;
    this.url = url;

  }
}

@Component({
  selector: 'breadcrumb',
  template: `
    <div *ngIf="breadcrumbs?.length > 0 && !getWidthFromMaxlength()">
      <ol class="breadcrumb" style="margin-top: 0">
        <li *ngFor="let breadcrumb of filterEmptyBreadcrumb(breadcrumbs);last as isLast">
          <ng-container *ngIf="!breadcrumb.params['programTypeClassificationId']">
            <a *ngIf="!isLast " [routerLink]="[breadcrumb.url, breadcrumb.params]">{{breadcrumb.label | translate }}</a>
            <span *ngIf="isLast" class="isLast">{{breadcrumb.label | translate }}</span>
          </ng-container>
          <ng-container *ngIf="breadcrumb.params['programTypeClassificationId']">
            <a [routerLink]="[breadcrumb.url+'/applications']">{{breadcrumb.label | translate }}</a>
          </ng-container>
        </li>
      </ol>
      <button id="buttonToFocusOn" aria-label="buttonToFocusOn" [title]="'buttonToFocusOn'"
              onclick="alert('')"></button>
    </div>
    <!------------------------------mobile-------------------------------------->
    <div *ngIf="breadcrumbs?.length > 0 && getWidthFromMaxlength()">
      <ol class="breadcrumb" style="margin-top: 0">
        <span> ... > </span>
        <li *ngFor="let breadcrumb of  filterEmptyBreadcrumb(breadcrumbs);last as isLast">
          <a *ngIf="!isLast" [routerLink]="[breadcrumb.url, breadcrumb.params]">{{breadcrumb.label | translate }}</a>
          <span *ngIf="isLast" class="isLast">{{breadcrumb.label | translate }}</span>
        </li>
      </ol>
      <button id="buttonToFocusOn" aria-label="buttonToFocusOn" [title]="'buttonToFocusOn'"
              onclick="alert('')"></button>
    </div>
  `,
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs: IBreadcrumb[];
  allBreadcrumbs = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sectionBreadcrumb: SectionBreadCrumbService, public platform: Platform,
    private translate: TranslateService,
  ) {
    this.breadcrumbs = [];
  }


  filterEmptyBreadcrumb(breadcrumbs: IBreadcrumb[]): IBreadcrumb[] {
    this.allBreadcrumbs = breadcrumbs.filter(item => item.label && item.label !== '');
    if (this.getWidthFromMaxlength()) {
      const lengthBreadcrumb = (ECaseUtils.getTranslatedValueFromKey(this.translate, this.allBreadcrumbs[this.allBreadcrumbs.length - 1].label).length) +
        (ECaseUtils.getTranslatedValueFromKey(this.translate, this.allBreadcrumbs[this.allBreadcrumbs.length - 2].label).length);
      if (lengthBreadcrumb > 30) {
        this.allBreadcrumbs = this.allBreadcrumbs.slice(this.allBreadcrumbs.length - 1,);
      } else {
        this.allBreadcrumbs = this.allBreadcrumbs.slice(this.allBreadcrumbs.length - 2,);
      }
    }
    return ECaseUtils.removeDuplicates(this.allBreadcrumbs, 'label');
  }

  ngOnInit(): void {
    this.sectionBreadcrumb.setSectionforBreadCrumb.subscribe({
      next: (value) => {
        this.setBreadCrumbs(value);
      },
      error: (e) => {
        console.error(e);
      }
    });
    this.sectionBreadcrumb.removeforBreadCrumb.subscribe({
        next: () => {
          this.breadcrumbs.pop();
        },
        error: (e) => {
          console.error(e);
        }
      }
    );
    this.sectionBreadcrumb.removeforBreadCrumbAtIndex.subscribe({
        next: (value: any) => {
          this.breadcrumbs.splice(value.index, value.length ? value.length : 1);
        }, error:
          (e) => {
            console.error(e);
          }
      }
    );
    this.sectionBreadcrumb.addCrumbs.subscribe({
        next: (value: any) => {
          this.breadcrumbs.splice.apply(this.breadcrumbs, [value.index ? value.index : this.breadcrumbs.length - 1, 0].concat(value.crumbs));
        },
        error: (e) => {
          console.error(e);
        }
      }
    );

    if (this.breadcrumbs.length === 0) {
      const root: ActivatedRoute = this.activatedRoute.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
    }

    console.log('BREADCRUMB');
    // subscribe to the NavigationEnd event
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe({
      next: () => {
        const root: ActivatedRoute = this.activatedRoute.root;
        this.breadcrumbs = this.getBreadcrumbs(root);
      }, error: error => console.error(error)
    });
  }

  setBreadCrumbs(formName): void {
    const selectedFormIBreadcrumb: IBreadcrumb = new IBreadcrumb(formName, 'sectionID', {});
    this.breadcrumbs.push(selectedFormIBreadcrumb);
  }

  getWidthFromMaxlength(): boolean {
    return this.platform.ANDROID || (this.platform.IOS && !navigator.userAgent.match(/(iPad)/));
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    // get the child routes
    const children: ActivatedRoute[] = route.children;
    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // verify the custom data property "breadcrumb" is specified on the route
      // if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
      //   return this.getBreadcrumbs(child, url, breadcrumbs);
      // }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      // append route URL to URL
      url += `/${routeURL}`;

      // add breadcrumb
      const breadcrumb: IBreadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        params: child.snapshot.params,
        url: url
      };
      breadcrumbs.push(breadcrumb);

      if (child.snapshot.data.firstChild && !child.snapshot.firstChild) {
        this.router.navigate([child.snapshot.data.firstChild]).then(() => {
        });
      }

      // recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }

    // we should never get here, but just in case
    return breadcrumbs;
  }

}
