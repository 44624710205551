import {Component, Input, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {eCaseAnimations} from 'synto-common';




@Component({
  selector: 'app-crm-person-search-dialog',
  templateUrl: './crm-person-search-dialog.component.html',
  styleUrls: ['./crm-person-search-dialog.component.scss'],
  animations: eCaseAnimations
})
export class CrmPersonSearchDialogComponent implements OnInit {

  @Input() onlySSOUsers = false;

  constructor(public dialogRef: MatDialogRef<CrmPersonSearchDialogComponent>) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  onSectionComplete(event) {
    this.dialogRef.close(event);
  }

  onCancel() {
    this.dialogRef.close(false);
  }

}
