import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from '../dialog/dialog.component';
import {ECaseNumberFormatterPipe, ECaseUtils, RefreshDataService} from 'synto-common';
import {Subscription} from "rxjs/internal/Subscription";

@Component({
  selector: 'fb-static-text',
  templateUrl: './fb-static-text.component.html',
  styleUrls: ['./fb-static-text.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FbStaticTextComponent implements OnInit, AfterViewInit, OnDestroy {
  isValid: boolean;
  lang: any;
  @Input() selectedLanguage: any;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() globalConf: any;
  @Input() globalPrj: any;
  @Input() rowNumber: number;
  row: any;
  data;
  errors = [];
  subscription: Subscription;

  constructor(private translate: TranslateService, private refreshDataService: RefreshDataService,
              public dialog: MatDialog, private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe) {
    this.confChange = {};
    this.isValid = false;
    this.bphChange = {};
    this.row = {};

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.errors = [];
    console.log('This is the takes value...');
    console.log(this.confChange.takesValueFromJS);
    console.log(this.confChange);
    console.log(this.bphChange);
    console.log(this.confChange.alignment);


    if (!(this.bphChange)) {
      this.bphChange = {};
    }
    if (!(this.bphChange.error)) {
      this.bphChange.error = {};
    }
    if (!(this.confChange)) {
      this.confChange = {};
    }
    if (!(this.bphChange.value)) {
      this.bphChange.value = {};
    }
    if (!this.confChange.style) {
      this.confChange.style = {};
    }

    this.subscription =this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;
    });
    this.refreshDataService.getNewValueForStaticText().subscribe((data) => {
      if (this.confChange.isValueParameterized) {
        if (this.confChange.paramterPath.includes(data.name)) {
          this.bphChange.value = data.value;
          if (typeof data.value !== 'string') {
            this.bphChange.value[this.confChange.paramterName] = ECaseUtils.getTranslatedValueFromKey(this.translate, this.bphChange.value[this.confChange.paramterName]);
          }
        }
      }
    });
    if (this.confChange.tooltipText) {
      this.confChange.tooltipText[this.selectedLanguage] = ECaseUtils.encodeStringToUTF8String(this.confChange.tooltipText[this.selectedLanguage]);
    }

    eval('this.confChange.initValue =function(currentValue,localConf){\n' +
      '\t\n' +
      '}');
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '600px',
      data:
        {
          dialog: this.confChange.dialogText,
          selectedLanguage: this.selectedLanguage
        }
    });
  }

  getCalculatedOutput() {

    if (this.rowNumber || this.rowNumber === 0) {
      const rowNumber = this.rowNumber;
      let calculatedValue: any = '';
      try {
        eval('calculatedValue = this.globalPrj.' + this.confChange.paramterPath);
        if (this.bphChange) {
          if (this.confChange.isValueParameterized) {
            if (calculatedValue.value.data[this.confChange.paramterName + '_' + this.selectedLanguage]) {
              this.bphChange.value = calculatedValue.value.data[this.confChange.paramterName + '_' + this.selectedLanguage];
            } else {
              this.bphChange.value = calculatedValue.value.data[this.confChange.paramterName];
            }
          } else {
            this.bphChange.value = calculatedValue;
          }
        }
      } catch (e) {
        // console.error(e);
        return this.confChange.label[this.selectedLanguage];
      }
      return this.bphChange.value;
    } else {
      return this.confChange.label[this.selectedLanguage];
    }

  }

  getPostScriptLabel() {
    return this.confChange.postScriptLabel ? this.confChange.postScriptLabel[this.selectedLanguage] : '';
  }

  getCalculatedOutputForDataSQL() {
    let calculatedValue: any = '';
    try {
      eval('calculatedValue = this.globalPrj.formData.' + this.confChange.dataSQL + '.data[0].' + this.confChange.paramterNameForDataSql);
      if (this.bphChange) {
        this.bphChange.value = calculatedValue;
        return this.bphChange.value;
      } else {
        return this.confChange.label[this.selectedLanguage];
      }
    } catch (e) {
      if (!this.errors.includes(e.name)) {
        this.errors.push(e.name);
        console.warn('Errors in block' , this.confChange);
        console.error(e);
      }
      return this.confChange.label[this.selectedLanguage];
    }
  }

  getCalculatedGlobalSubtotalFromJS() {
    let calculatedGlobalSubtotal: any = '';
    try {
      if (this.confChange.calculateGlobalSubtotal) {
        this.globalConf.eCaseNumberFormatterPipe = this.eCaseNumberFormatterPipe;
        this.globalConf.rowNumber = this.rowNumber;
        calculatedGlobalSubtotal = this.confChange.calculateGlobalSubtotal(this.globalConf, this.globalPrj, this.selectedLanguage);
      }
      this.bphChange.value = calculatedGlobalSubtotal;
      return this.bphChange.value;
    } catch (e) {
      console.error(e);
    }
  }

  ngAfterViewInit(): void {
    if (!this.isValid && this.confChange.disableMargin) {
      /*setTimeout(() => {
        if (document.getElementById(this.confChange.name + 'noPaddingAndMargin') && document.getElementById(this.confChange.name + 'noPaddingAndMargin') !== null) {
          const documentInputField = document.getElementById(this.confChange.name + 'noPaddingAndMargin').getElementsByTagName('p');
          const size = documentInputField.length;
          for (let i = 0; i < size; i++) {
            documentInputField.item(i).setAttribute('style', 'margin:0;');
          }
        }
      }, 100);*/
    }
  }


}
