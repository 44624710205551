import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import BpmnViewer from 'bpmn-js/lib/NavigatedViewer';
import {Observable} from 'rxjs/internal/Observable';
import {Subscription} from 'rxjs/internal/Subscription';
import {EcaseHttpService, ECaseSnackBarService, ECaseUtils} from 'synto-common';
import {HttpResponse} from '@angular/common/http';
import {from} from "rxjs/internal/observable/from";

@Component({
  selector: 'ecase-bpmn-viewer',
  templateUrl: './bpmn-viewer.component.html',
  styleUrls: ['./bpmn-viewer.component.scss']
})
export class BpmnViewerComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {
  @ViewChild('ref', {static: true, read: ElementRef}) el;
  @Input() showDownloadButton: boolean;
  @Output() private importDone: EventEmitter<any> = new EventEmitter();
  @Input() private url: string;
  @Input() private workFlowName: string;
  isWorkflowLoaded = false;
  error: string;
  private bpmnJS: BpmnViewer;

  constructor(private eCaseSnackBarService: ECaseSnackBarService, private eCaseHttpService: EcaseHttpService) {
  }

  ngOnInit(): void {
    document.addEventListener('keydown', (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'ArrowLeft') {
        e.preventDefault();
      }
    });
  }

  ngAfterContentInit(): void {
    const container = document.getElementById('diagram-container');
    this.bpmnJS = new BpmnViewer({
      container,
      keyboard: {
        bindTo: document
      }
    });
    this.bpmnJS.on('import.done', ({error}) => {
      if (!error) {
        // this.bpmnJS.get('canvas').zoom('fit-viewport');
      }
    });
    this.bpmnJS.attachTo(this.el.nativeElement);
    setTimeout(() => {
      const documentLinks = document.getElementById('diagram-container').getElementsByTagName('a');
      for (const documentLink of documentLinks[Symbol.iterator]()) {
        if (documentLink.getAttributeNames().includes('title') && documentLink.getAttribute('title') === 'Powered by bpmn.io') {
          documentLink.remove();
        }
      }
    }, 10);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // re-import whenever the url changes
    if (changes.url) {
      this.isWorkflowLoaded = false;
      this.loadUrl(changes.url.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.bpmnJS.destroy();
  }

  /**
   * Load diagram from URL and emit completion event
   */
  loadUrl(url: string): Subscription {
    return (
      this.eCaseHttpService.get(url).subscribe({
        next: (response) => {
          if (response.status) {
            this.importDiagram(response.xml).subscribe(() => {
              this.resizeCanvas();
              this.importDone.emit({
                type: 'success'
              });
              this.isWorkflowLoaded = true;
            });
          } else {
            this.error = response.errorMessage.toString();
            this.importDone.emit({
              type: 'error',
              error: response.errorMessage
            });
            this.isWorkflowLoaded = true;
          }
        },
        error: (err) => {
          this.isWorkflowLoaded = true;
          this.error = err.toString();
          this.importDone.emit({
            type: 'error',
            error: err
          });
        }
      })
    );
  }

  resizeCanvas(): void {
    const svg = document.getElementById('diagram-container').getElementsByTagName('svg');
    if (svg.length > 0) {
      svg[0].setAttribute('style', 'min-height: 400px;');
    }
  }

  downloadSvg(): Subscription {
    return this.exportDiagram().subscribe((svg) => {
      const obj = {
        'svg': svg.svg,
        'width': 3000,
        'height': 1000,
        'fileName': this.workFlowName,
        'backgroundColor': 'white'
      };
      return this.eCaseHttpService.post('/api/convertAndDownloadSVGToPNG', obj, {
        observe: 'response',
        responseType: 'blob'
      }).subscribe({
        next: (response: HttpResponse<Blob>) => {
          ECaseUtils.generateHyperLinkForDownload(response);
        },
        error: (error) => {
          this.eCaseSnackBarService.show('failure', error.errorMessage);
        }
      });
    });
  }

  toggleFullScreen(): void {
    this.exportDiagram().subscribe((svg) => {
      const obj = {
        'svg': svg.svg,
        'width': 3000,
        'height': 1000,
        'fileName': this.workFlowName,
        'backgroundColor': 'white'
      };
      this.eCaseHttpService.post('/api/convertAndDownloadSVGToPNG', obj, {
        observe: 'response',
        responseType: 'blob'
      }).subscribe({
        next: (response: HttpResponse<Blob>) => {
          const url = URL.createObjectURL(response.body);
          const win = open(url);
          // so the Garbage Collector can collect the blob
          win.onload = (): void => URL.revokeObjectURL(url);
        },
        error: (error) => {
          this.eCaseSnackBarService.show('failure', error.errorMessage);
        }
      });
    });
  }

  /**
   * Creates a Promise to import the given XML into the current
   * BpmnJS instance, then returns it as an Observable.
   *
   * @see https://github.com/bpmn-io/bpmn-js-callbacks-to-promises#importxml
   */
  private importDiagram(xml: string): Observable<{ warnings: Array<any> }> {
    return from(this.bpmnJS.importXML(xml) as Promise<{ warnings: Array<any> }>);
  }

  private exportDiagram(): Observable<any> {
    return from(this.bpmnJS.saveSVG() as Promise<any>);
  }
}
