<ng-container *ngIf="!isWorkflowLoaded">
  <div style="margin: 0 auto">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-container>
<span *ngIf="isWorkflowLoaded && error">{{error}}</span>
<div *ngIf="isWorkflowLoaded" style="float: right">
  <button (click)="toggleFullScreen()"
          class="eCase-dialog-positive-action-button" id="ecase_bpmn_viewer_go_full_scrren"
          mat-raised-button style=" margin-right: 15px;">
    {{'ecase.common.gofullscreen' | translate}}
  </button>
  <button (click)="downloadSvg()" *ngIf="showDownloadButton"
          class="eCase-dialog-positive-action-button"
          id="ecase_bpmn_viewer_download" mat-raised-button style=" margin-right: 0 !important;">
    {{'ecase.common.download' | translate}}
  </button>
</div>
<div *ngIf="isWorkflowLoaded">
  <br>
  <br>
  <br>
</div>
<span *ngIf="isWorkflowLoaded">{{'ecase.common.bpmnvieweraccessibilitytext' | translate}}</span>
<div #ref [ngClass]="isWorkflowLoaded ? 'border-class' : ''" class="diagram-container" id="diagram-container"></div>

