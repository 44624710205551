<div *ngIf="isValidHtml">
  <div *ngIf="bphChange.globalShowDetail">
    <div *ngIf="!bphChange.hide" class="field {{bphChange.error_class}} hide_{{bphChange.show}}">
      <div class="field ui grid">
        <label class="three wide column righttext"><span *ngIf="confChange.isRequired">*</span>{{confChange.label[selectedLanguage]}}
          <label [lang]="selectedLanguage" matTooltip="_CONF__CHANGE_.tooltipText"
                 *ngIf="confChange.hasTooltip == true"></label>
        </label>
        <span *ngIf="confChange.hasDialog" style="padding-top: 10px;">
         <mat-icon style="cursor: pointer;" (click)="openDialog()">help_outline</mat-icon>
      </span>
        <div class="eleven wide column field">
          <input type="text" [attr.aria-label]="confChange.label[selectedLanguage]" name="search_field" [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'"
                 placeholder="{{confChange.searchQuery}}" [(ngModel)]="bphChange.searchQuery"/>
          <a class="ui submit button" (click)="confChange.search(confChange,bphChange)">{{confChange.labelName}}</a>
          <p *ngIf="confChange.useCustomized != true">
            {{confChange.defaultResult}}
          </p>
          <span style="float: left; font-weight: 700;">{{bphChange.error[selectedLanguage]}}</span>
        </div>
      </div>
    </div>
  </div>
</div>


<td style="border:0;width: 10%;text-align: center;padding-right:20px" *ngIf="isValidTable &&  !row.isTotal  ">
  <div class="field {{bphChange.error_class}} hide_{{bphChange.show}}">
    <div class="eleven wide column field">
      <input type="text" name="search_field" placeholder="{{confChange.searchQuery}}" [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'"
             [attr.aria-label]="confChange.label[selectedLanguage]" [(ngModel)]="bphChange.searchQuery"/>
      <a class="ui submit button" (click)="confChange.search(confChange,bphChange)">
        {{confChange.labelName}}</a>
      <p *ngIf="confChange.useCustomized != true">
        {{confChange.defaultResult}}
      </p>
      <span style="float: left; font-weight: 700;">{{bphChange.error[lang]}}</span>
    </div>
  </div>
</td>

<td *ngIf="isValidTableReadonly  && !bphChange.hideCell && !confChange.hideCell && !row.isTotal "
    [attr.rowspan]="bphChange.rowspan">
  <div class="field {{bphChange.error_class}} hide_{{bphChange.show}}">
    {{confChange.getValue(bphChange,lang)}}
    <label>{{bphChange.error[lang]}}</label>
  </div>
</td>
