import {Component, Input, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ECaseUtils} from 'synto-common';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'ecase-validation-dialog',
  templateUrl: 'ecase-validation-dialog.component.html'
})
export class ValidationDialogComponent implements OnInit {
  @Input() errors;
  @Input() lang;

  constructor(public dialogRef: MatDialogRef<ValidationDialogComponent>,private translate: TranslateService) {
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  getLength(labelString): number {
    return labelString ? this.stripHtml(labelString).length : 0;
  }

  stripHtml(labelString): string {
    return ECaseUtils.replaceAllWithRegex(labelString, '<[^>]*>', '');
  }

  addEllipses(labelString) {
    return this.stripHtml(labelString).substr(0, 50) + '...';
  }

  getTranslatedValue(key){
    return ECaseUtils.getTranslatedValueFromKey(this.translate, key, this.lang);
  }

  ngOnInit() {
  }
}
