import {
  ComponentModule,
  ECaseSnackBarService,
  PendingChangesGuard,
  RefreshDataService,
  SharedModule
} from 'synto-common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FormComponent} from './form.component';
import {DialogService} from './dialog.service';
import {FbAutoCompleteComponent} from "../../formbuilder/fb-auto-complete/fb-auto-complete.component";
import {FbTextComponent} from "../../formbuilder/fb-text/fb-text.component";
import {FbRadiobuttonComponent} from "../../formbuilder/fb-radiobutton/fb-radiobutton.component";
import {FbTableComponent} from "../../formbuilder/fb-table/fb-table.component";
import {FbSearchComponent} from "../../formbuilder/fb-search/fb-search.component";
import {FbCurrencyComponent} from "../../formbuilder/fb-currency/fb-currency.component";
import {FbOutputComponent} from "../../formbuilder/fb-output/fb-output.component";
import {FbUploadComponent} from "../../formbuilder/fb-upload/fb-upload.component";
import {FbSeparatorComponent} from "../../formbuilder/fb-separator/fb-separator.component";
import {FbContainerComponent} from "../../formbuilder/fb-container/fb-container.component";
import {FbCalculatedOutputComponent} from "../../formbuilder/fb-calculated-output/fb-calculated-output.component";
import {FbSelectComponent} from "../../formbuilder/fb-select/fb-select.component";
import {FbStaticTextComponent} from "../../formbuilder/fb-static-text/fb-static-text.component";
import {FbDatepickerComponent} from "../../formbuilder/fb-datepicker/fb-datepicker.component";
import {FbCheckboxComponent} from "../../formbuilder/fb-checkbox/fb-checkbox.component";
import {FbTextAreaComponent} from "../../formbuilder/fb-text-area/fb-text-area.component";
import {FbButtonComponent} from "../../formbuilder/fb-button/fb-button.component";
import {FbRichTextQuillComponent} from "../../formbuilder/fb-rich-text-quill/fb-rich-text-quill.component";
import {FbWarningTextComponent} from "../../formbuilder/fb-warning-text/fb-warning-text.component";
import {FbDynamicMatrixComponent} from "../../formbuilder/fb-dynamic-matrix/fb-dynamic-matrix.component";
import {FbMultiYearTableComponent} from "../../formbuilder/fb-multi-year-table/fb-multi-year-table.component";
import {FbMatSliderComponent} from "../../formbuilder/fb-mat-slider/fb-mat-slider.component";
import {
  FbReviewCriteriaGridComponent
} from "../../formbuilder/fb-review-criteria-grid/fb-review-criteria-grid.component";
import {
  AttachExistingDocumentDialogComponent
} from "../../formbuilder/fb-upload/attach-existing-document-dialog/attach-existing-document-dialog.component";
import {
  FbBudgetExpenditureItemsComponent
} from "../../formbuilder/fb-budget-expenditure-items/fb-budget-expenditure-items.component";
import {FbTeamTableComponent} from "../../formbuilder/fb-team-table/fb-team-table.component";
import {FbBudgetT4Component} from "../../formbuilder/fb-budget-t4/fb-budget-t4.component";
import {FbBudgetT3Component} from "../../formbuilder/fb-budget-t3/fb-budget-t3.component";
import {FbSubmitButtonComponent} from "../../formbuilder/fb-submit-button/fb-submit-button.component";
import {DialogComponent} from "../../formbuilder/dialog/dialog.component";
import {FbObjectivesComponent} from "../../formbuilder/fb-objectives/fb-objectives.component";
import {FbTooltipComponent} from "../../formbuilder/fb-tooltip/fb-tooltip.component";
import {FbMultiYearTableT2Component} from "../../formbuilder/fb-multi-year-table-t2/fb-multi-year-table-t2.component";
import {FbSchedulerTableComponent} from "../../formbuilder/fb-scheduler-table/fb-scheduler-table.component";
import {FbBudgetT1Component} from "../../formbuilder/fb-budget-t1/fb-budget-t1.component";
import {FbBudgetT2Component} from "../../formbuilder/fb-budget-t2/fb-budget-t2.component";
import {ValidationDialogComponent} from "../validation-dialog/ecase-validation-dialog.component";
import {FbSignatureComponent} from "../../formbuilder/fb-signature/fb-signature.component";
import {
  FbButtonComponentDialogComponent
} from "../../formbuilder/fb-button/fb-button-component-dialog/fb-button-component-dialog.component";
import {FbBudgetT5Component} from "../../formbuilder/fb-budget-t5/fb-budget-t5.component";
import {FbBudgetT6Component} from "../../formbuilder/fb-budget-t6/fb-budget-t6.component";
import {FbFundedTeamsComponent} from "../../formbuilder/fb-funded-teams/fb-funded-teams.component";
import {FbAccordionComponent} from "../../formbuilder/fb-accordion/fb-accordion.component";
import {FbSimpleTableComponent} from "../../formbuilder/fb-simple-table/fb-simple-table.component";
import {FbBudgetTableComponent} from "../../formbuilder/fb-budget-table/fb-budget-table.component";

const routes: Routes = [
  {
    path: 'form',
    canDeactivate: [PendingChangesGuard],
    component: FormComponent
  },
  {
    path: 'form/:sectionId',
    canDeactivate: [PendingChangesGuard],
    component: FormComponent
  }];
@NgModule({
  declarations: [FormComponent,
    ValidationDialogComponent,
    FbAutoCompleteComponent,
    FbStaticTextComponent,
    FbRichTextQuillComponent,
    FbButtonComponent,
    FbTextComponent,
    FbTextAreaComponent,
    FbCheckboxComponent,
    FbRadiobuttonComponent,
    FbDatepickerComponent,
    FbStaticTextComponent,
    FbSelectComponent,
    FbTableComponent,
    FbBudgetTableComponent,
    FbCalculatedOutputComponent,
    FbContainerComponent,
    FbSearchComponent,
    FbSeparatorComponent,
    FbUploadComponent,
    FbCurrencyComponent,
    FbOutputComponent,
    FbWarningTextComponent,
    FbAutoCompleteComponent,
    FbSubmitButtonComponent,
    FbDynamicMatrixComponent,
    DialogComponent,
    FbObjectivesComponent,
    FbTooltipComponent,
    FbMultiYearTableComponent,
    FbMultiYearTableT2Component,
    FbSchedulerTableComponent,
    FbBudgetT1Component,
    FbMatSliderComponent,
    FbBudgetT2Component,
    FbBudgetT3Component,
    FbBudgetT4Component,
    FbBudgetT5Component,
    FbBudgetT6Component,
    FbReviewCriteriaGridComponent,
    FbTeamTableComponent,
    FbBudgetExpenditureItemsComponent,
    AttachExistingDocumentDialogComponent,
    FbSignatureComponent,
    FbButtonComponentDialogComponent, FbFundedTeamsComponent,
    FbAccordionComponent, FbSimpleTableComponent
  ],
  imports: [SharedModule, RouterModule, ComponentModule, RouterModule.forChild(routes)],
  providers: [ECaseSnackBarService, RefreshDataService, DialogService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [FormComponent,
    FbAutoCompleteComponent,
    ValidationDialogComponent,
    FbStaticTextComponent,
    FbRichTextQuillComponent,
    FbButtonComponent,
    FbTextComponent,
    FbTextAreaComponent,
    FbCheckboxComponent,
    FbRadiobuttonComponent,
    FbDatepickerComponent,
    FbStaticTextComponent,
    FbSelectComponent,
    FbTableComponent,
    FbCalculatedOutputComponent,
    FbContainerComponent,
    FbSearchComponent,
    FbSeparatorComponent,
    FbUploadComponent,
    FbCurrencyComponent,
    FbOutputComponent,
    FbWarningTextComponent,
    FbAutoCompleteComponent,
    FbSubmitButtonComponent,
    FbDynamicMatrixComponent,
    DialogComponent,
    FbObjectivesComponent,
    FbTooltipComponent,
    FbMultiYearTableComponent,
    FbMultiYearTableT2Component,
    FbSchedulerTableComponent,
    FbBudgetT1Component,
    FbMatSliderComponent,
    FbBudgetT2Component,
    FbBudgetT3Component,
    FbBudgetT4Component,
    FbBudgetT5Component,
    FbBudgetT6Component,
    FbReviewCriteriaGridComponent,
    FbTeamTableComponent,
    FbBudgetExpenditureItemsComponent,
    AttachExistingDocumentDialogComponent,
    FbSignatureComponent,
    FbButtonComponentDialogComponent,
    FbAccordionComponent,
    FbSimpleTableComponent

  ]
})
export class FormModule {
}
