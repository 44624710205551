import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {EcaseHttpService} from './ecase-http.service';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs/internal/observable/throwError';
import {ECaseSnackBarService} from './snack-bar.service';
import {FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CommitteeService {

  static SELF_ASSESSMENT_STAGE_TYPE_ID: any = 1;
  static REVIEW_STAGE_TYPE_ID: any = 2;
  static REVIEW_MEETING_STAGE_TYPE_ID: any = 3;
  static COMMITTEE_ROLE_EXTERNAL: any = 6;
  static REVIEW_ROLE_INTERNAL: any = 1;
  static REVIEW_ROLE_EXTERNAL: any = 5;

  static PER_MEMBER_STATUS_SCREEN_CODE = 'perMemberStatus';
  static PER_APPLICATION_STATUS_SCREEN_CODE = 'perApplicationStatus';
  static REVIEW_STATUS_SCREEN_CODE = 'reviewStatus';
  static REVIEW_MEETING_SCREEN_CODE = 'reviewMeetingStatus';

  public languages;
  isCommitteeSelected = false;
  isSelfAssessment: Subject<any> = new Subject<any>();
  isReviewMeeting: Subject<any> = new Subject<any>();
  isSelfAssessmentValue;
  isReviewMeetingValue;
  selectedRatingScale: any;
  isRatingScaleEdited: boolean;
  oldFormGroup: FormGroup;
  // To use when components use multiple form groups
  oldFormGroups: Array<FormGroup> = [];
  isUseOldFormGroup = false;
  enableConfigCustomizations: boolean;

  private _listners = new Subject<any>();
  private _committeeSelectedSubject = new Subject<any>();
  private resetCommitteeDashboardSubject = new Subject<any>();

  constructor(private eCaseHttpService: EcaseHttpService, private snackBarService: ECaseSnackBarService) {
    this.fetchLanguages();
  }

  get onCommitteeSelected() {
    return this._committeeSelectedSubject.asObservable();
  }

  get onCommitteeDashboardReset() {
    return this.resetCommitteeDashboardSubject.asObservable();
  }

  triggerWhenSelfAssessmentIsEnabled(event) {
    this.isSelfAssessment.next(event);
  }

  triggerWhenReviewMeetingIsEnabled(event) {
    this.isReviewMeeting.next(event);
  }

  selectCommittee(event) {
    this.isCommitteeSelected = event;
    this._committeeSelectedSubject.next(event);
  }

  resetCommitteeDashboard(event) {
    this.resetCommitteeDashboardSubject.next(event);
  }

  addCommittee(newCommittee) {
    return this.eCaseHttpService.post('/api/addCommittee', newCommittee);
  }

  updateCommittee(committee) {
    return this.eCaseHttpService.post('/api/updateCommittee', committee);
  }

  updateCommitteeStage(committeeStage) {
    return this.eCaseHttpService.post('/api/updateCommitteeStage', committeeStage);
  }

  deleteCommittee(committeeId) {
    return this.eCaseHttpService.post('/api/deleteCommittee', {'committeeId': committeeId});
  }

  getReviewForms() {
    return this.eCaseHttpService.get('/api/getReviewForms');
  }

  getCommitteeFormSupportedLanguage(cpmLibraryFormId){
    return this.eCaseHttpService.post('/api/getCommitteeFormSupportedLanguage', cpmLibraryFormId);
  }

  getRoles() {
    return this.eCaseHttpService.get('/api/getActiveReviewCommitteeUserRoles');
  }

  searchReviewersForApplicationViaClarivate(applicationNumber: number) {
    return this.eCaseHttpService.get('/api/searchReviewersForApplicationViaClarivate/' + applicationNumber);
  }

  createSystemUsersFromClarivateReviewers(users: any[]) {
    return this.eCaseHttpService.post('/api/createSystemUsersFromClarivateReviewers', {
      'users': users
    });
  }

  addCommitteeUsersWithRoles(users, committee) {
    return this.eCaseHttpService.post('/api/addCommitteeUsersWithRoles', {
      'committeeId': committee,
      'users': users
    }).pipe(
      catchError(this.handleError)
    );
  }

  removeCommitteeUsersWithRoles(users, committee) {
    return this.eCaseHttpService.post('/api/removeCommitteeUsersWithRoles', {
      'committeeId': committee,
      'users': users
    }).pipe(
      catchError(this.handleError)
    );
  }

  getCommittees(paginationObj) {
    return this.eCaseHttpService.post(`/api/getAllReviewCommittees`, paginationObj);
  }

  getCommittesForAutoComplete(searchString) {
    return this.eCaseHttpService.post('/api/getReviewCommitteesForAutocomplete', {'searchString' : searchString});
  }

  clonePanel(data: any) {
    return this.eCaseHttpService.post('/api/review/clonePanel', data);
  }

  getCommittee(committeeId) {
    return this.eCaseHttpService.post('/api/getReviewCommittee', {'committeeId': committeeId});
  }


  getPermissionCategories() {
    return this.eCaseHttpService.get('/api/getReviewCommitteePermissionsAndCategories');
  }

  getCommitteePermissionRoleMappings(committeeId: number) {
    return this.eCaseHttpService.post('/api/getCommitteePermissionRoleMappings', {id: committeeId});
  }

  getCommitteeMetadatas(committeeId: number) {
    return this.eCaseHttpService.post('/api/getCommitteeMetadatas', {id: committeeId});
  }

  updateCommitteeStageMetadatas(committeeStage) {
    return this.eCaseHttpService.post('/api/updateCommitteeStageMetadatas', committeeStage);
  }

  saveCommitteePermissionRoles(committeeId: number, mappings) {
    return this.eCaseHttpService.post(`/api/saveCommitteePermissionRoles/${committeeId}`, mappings);
  }

  getCommitteeApplications(committeeId) {
    return this.eCaseHttpService.post('/api/getCommitteeApplications', {
      'committeeId': committeeId
    });
  }

  deleteGridInCommittee(committeeId, rcgId) {
    return this.eCaseHttpService.post('/api/deleteGridInCommittee ', {
      'committeeId': committeeId,
      'rcgid': rcgId

    });
  }

  insertCriteriaIntoCommittee(committeeId, grids) {
    return this.eCaseHttpService.post('/api/insertCriteriaIntoCommittee', {
      'committeeId': committeeId,
      'grids': grids
    });
  }

  getAllGridsInCommittee(committeeId) {
    return this.eCaseHttpService.post('/api/getAllGridsInCommittee', {
      'committeeId': committeeId
    });
  }

  removeCommitteeApplications(committeeId, applications) {
    return this.eCaseHttpService.post('/api/removeCommitteeApplications', {
      'committeeId': committeeId,
      'applications': applications
    });
  }

  addCommitteeApplications(committeeId, applications) {
    return this.eCaseHttpService.post('/api/addCommitteeApplications', {
      'committeeId': committeeId,
      'applications': applications
    });
  }

  getCommitteeUsers(id: number) {
    return this.eCaseHttpService.post('/api/getCommitteeUsers', {'committeeId': id});
  }

  getAvailableUsersForAutoComplete(searchString: string, committeeId: number) {
    return this.eCaseHttpService.post('/api/getAvailableUsersForAutoComplete', {'searchString': searchString});
  }

  getAvailableUsers() {
    return this.eCaseHttpService.get('/api/getAvailableUsers');
  }

  getMembersForApplication(applicationId: number, csId: number) {
    return this.eCaseHttpService.post('/api/getMembersForApplication', {
      'applicationId': applicationId,
      'csId': csId
    });
  }

  getInfoToInsertMember(committeeId, caseApplicationId, ssoUserId) {
    return this.eCaseHttpService.post('/api/getInfoToInsertMember', {
      'committeeId': committeeId,
      'caseApplicationId': caseApplicationId,
      'ssoUserId': ssoUserId
    });
  }

  insertMemberInApplication(rcsca, rcsu, rrrId, status?) {
    return this.eCaseHttpService.post('/api/insertMemberInApplication', {
      'rcsca': rcsca,
      'rcsu': rcsu,
      'rrrId': rrrId,
      'status': status
    });
  }

  deleteMemberFromApplication(rcsca, rcsu) {
    return this.eCaseHttpService.post('/api/deleteMemberFromApplication', {
      'rcsca': rcsca,
      'rcsu': rcsu
    });
  }

  getAllReviewRoles() {
    return this.eCaseHttpService.get('/api/getAllReviewRoles');
  }


  fetchLanguages() {
    this.eCaseHttpService.get('/api/getLanguages').subscribe((data) => {
      this.languages = data;
    });
  }

  getEligibilityApplications(applications) {
    return this.eCaseHttpService.post('/api/searchEligibleApplicationsForCommittee ', applications);

  }

  deleteReviewRatingScales(id) {
    return this.eCaseHttpService.post('/api/deleteReviewRatingScales', {id});
  }

  getReviewRatingScales() {
    return this.eCaseHttpService.get('/api/getReviewRatingScales');
  }

  getAllCommentTypes() {
    return this.eCaseHttpService.get('/api/getAllCommentTypes');
  }

  getAllCommentTypesForCommittee(committeeStageId) {
    return this.eCaseHttpService.post('/api/getAllCommentTypesForCommittee', {
      'committeeStageId': committeeStageId
    });
  }

  updateAndInsertCommentTypeOverride(data) {
    return this.eCaseHttpService.post('/api/updateAndInsertCommentTypeOverride', data);
  }


  getAllCommentTypesCategory() {
    return this.eCaseHttpService.get('/api/getAllCommentTypesCategory');
  }

  deleteCommentType(id) {
    return this.eCaseHttpService.post('/api/deleteCommentType', {id});
  }

  updateCommentType(data) {
    return this.eCaseHttpService.post('/api/updateCommentType', data);
  }

  addCommentType(data) {
    return this.eCaseHttpService.post('/api/addCommentType', data);
  }


  deleteReviewRatingScalesDetail(id) {
    return this.eCaseHttpService.post('/api/deleteReviewRatingScalesDetail', {id});
  }

  addRatingScaleDetails(data) {
    return this.eCaseHttpService.post('/api/addRatingScaleDetails', data);
  }

  updateReviewRatingScalesDetail(data) {
    return this.eCaseHttpService.post('/api/updateReviewRatingScalesDetail', data);
  }


  updateReviewRatingScales(data) {
    return this.eCaseHttpService.post('/api/updateReviewRatingScales', data);
  }

  addRatingScale(data) {
    return this.eCaseHttpService.post('/api/addRatingScale', data);
  }


  getReviewRatingScalesDetails(rsId) {
    return this.eCaseHttpService.post('/api/getReviewRatingScalesDetails', {'rsId': rsId});
  }

  updateReviewRatingScalesDetailsWeight(obj) {
    return this.eCaseHttpService.post('/api/updateRatingScaleDetailWeight', obj);
  }

  updateCommentTypeWeight(obj) {
    return this.eCaseHttpService.post('/api/updateCommentTypeWeight', obj);
  }

  getReviewGrids() {
    return this.eCaseHttpService.get('/api/getReviewGrids');
  }

  addReviewCriteriaGrid(data) {
    console.log(data);
    return this.eCaseHttpService.post('/api/addReviewCriteriaGrid', data);
  }

  updateReviewCriteriaGrid(data) {
    return this.eCaseHttpService.post('/api/updateReviewCriteriaGrid', data);
  }

  deleteReviewCriteriaGrid(data) {
    return this.eCaseHttpService.post('/api/deleteReviewCriteriaGrid', {id: data});
  }

  addReviewCriteria(data) {
    return this.eCaseHttpService.post('/api/addReviewCriteria', data);
  }

  updateReviewCriteria(data) {
    return this.eCaseHttpService.post('/api/updateReviewCriteria', data);
  }

  deleteReviewCriteria(data) {
    return this.eCaseHttpService.post('/api/deleteReviewCriteria', {id: data});
  }

  getAllNominationStrategies() {
    return this.eCaseHttpService.get('/api/getAllReviewNominationStrategies');
  }

  getAllStatisticMethods() {
    return this.eCaseHttpService.get('/api/getAllReviewNominationStatistics');
  }

  getAllRatingScalesCalculationMethods() {
    return this.eCaseHttpService.get('/api/getAllReviewRatingScaleCalculationMethods');
  }

  getReviewerPortalPreview(entityType: string, entityId: number, langCode: string) {
    return this.eCaseHttpService.get(`/api/getReviewerPortalPreview/${entityType}/${entityId}/${langCode}`, {responseType: 'text'});
  }

  getReviewerPortalPDF(entityType: string, entityId: number, langCode: string) {
    return this.eCaseHttpService.get(`/api/getReviewerPortalPDF/${entityType}/${entityId}/${langCode}`, {responseType: 'text'});
  }

  handleError(error) {
    let errorMessage = '';
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    return throwError(errorMessage);
  }

  getCommitteeDashboard(committeeId: number) {
    return this.eCaseHttpService.get('/api/getReviewCommitteeDashboard/' + committeeId);
  }

  getCommitteeMonitoringScreen(monitoringScreen: string, committeeId: number) {
    return this.eCaseHttpService.get(`/api/getReviewCommitteeMonitoringScreen/${monitoringScreen}/${committeeId}`);
  }

  getCommitteeMonitoringApplicationDetails(committeeId: number, applicationId: number) {
    return this.eCaseHttpService.get(`/api/getReviewCommitteeMonitoringScreen/perApplicationStatus/applicationDetails/${committeeId}/${applicationId}`);
  }

  getCommitteeMonitoringMeetingDetails(committeeId: number, applicationId: number) {
    return this.eCaseHttpService.get(`/api/getReviewCommitteeMonitoringScreen/reviewMeetingStatus/meetingDetails/${committeeId}/${applicationId}`);
  }

  saveCommitteeStageUserRoleMetadatas(committeeStageId: number, metadataKey: string, userRolesCustomizations: any) {
    return this.eCaseHttpService.post(`/api/saveCommitteeStageUserRoleMetadatas/${committeeStageId}/${metadataKey}`, userRolesCustomizations);
  }

  saveCommitteeStageCaseApplicationMetadatas(applicationId: string, applicationCustomizations: any) {
    return this.eCaseHttpService.post(`/api/saveCommitteeStageCaseApplicationMetadatas/${applicationId}`, applicationCustomizations);
  }

  saveCommitteeStageUserMetadatas(ssoUserId: string, memberCustomizations: any) {
    return this.eCaseHttpService.post(`/api/saveCommitteeStageUserMetadatas/${ssoUserId}`, memberCustomizations);
  }

  getCommitteeComment(committeeId: any) {
    return this.eCaseHttpService.get(`/api/getReviewCommitteeMonitoringScreen/getCommitteeComment/${committeeId}`);
  }

  saveCommitteeComment(committeeInfo: any) {
    return this.eCaseHttpService.post('/api/getReviewCommitteeMonitoringScreen/saveReviewCommitteeComment', committeeInfo);
  }
}
