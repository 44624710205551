import {Component, Input, OnInit} from '@angular/core';
import {DialogComponent} from "../dialog/dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'fb-funded-teams',
  templateUrl: './fb-funded-teams.component.html',
  styleUrls: ['./fb-funded-teams.component.scss']
})
export class FbFundedTeamsComponent implements OnInit {

  @Input() selectedLanguage: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() showSaveNavBar: boolean;
  @Input() bphChange: any;
  @Input() globalPrj: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() selectedSectionId: string;
  @Input() offlineModeEnabled;
  @Input() blockIndex: number;
  panelOpenState = false;

  constructor(private translate: TranslateService, private matDialog: MatDialog) {
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.value.label = {};
    this.bphChange.error = {};
    this.bphChange.source = {};
    this.isValidHtml = true;
    this.isValidTable = false;
    this.isValidTableReadonly = false;
    this.translate = translate;
  }

  ngOnInit(): void {

    if (!this.bphChange) {
      this.bphChange = {};
      this.bphChange.error = {};
      this.bphChange.rows = [];
    }
    if (!this.bphChange.rows) {
      this.bphChange.rows = [];
    }


  }


  openDialog(confChange) {
    this.matDialog.open(DialogComponent, {
      width: '600px',
      data: {dialog: confChange.dialogText, selectedLanguage: this.translate.getDefaultLang()}
    });
  }

}
