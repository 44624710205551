import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import {ECaseNumberFormatterPipe, ECaseUtils, PendingChangesGuard, RefreshDataService} from 'synto-common';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'fb-multi-year-table',
  templateUrl: './fb-multi-year-table.component.html',
  styleUrls: ['./fb-multi-year-table.component.scss']
})
export class FbMultiYearTableComponent implements OnInit, OnDestroy {
  @Input() selectedLanguage: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() bphChange: any;
  @Input() globalPrj: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() blockIndex: number;
  @Output() emitOutputEvent = new EventEmitter<any>();
  @Output() updateOutputEvent = new EventEmitter<any>();
  @Input() selectedSectionId: string;
  rowIndex: number;
  filteredRoleList = [];
  isHideTable = false;
  yearArray = [];
  backUpRows = [];
  clonedqualificationLov = [];
  firstRowOfAddedManpower: any;
  editMode = false;


  constructor(private translate: TranslateService,
              private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe,
              private refreshDataService: RefreshDataService, public dialog: MatDialog,
              private pendingChangesGuard: PendingChangesGuard,) {
  }

  parseInt(l) {
    return parseInt(l, 10);
  }

  ngOnInit() {
    this.confChange.text_duration_months.valueLimit = 12;
    this.confChange.text_time_allocated_percentage.valueLimit = 100;
    this.confChange.text_time_allocated_percentage.maxlength = 3;
    this.confChange.textarea_comments.maxlength = 300;
    this.confChange.text_subtotal.currency = 'KD';
    this.confChange.text_funds_from_kfas.currency = 'KD';
    this.confChange.text_funds_from_other_institution.currency = 'KD';
    this.confChange.textarea_comments.countUnit = 'ecase.common.words';
    this.confChange.text_funds_from_other_institution.isReadOnlyField = true;
    this.confChange.hasGlobalTotal = true;
    this.confChange.select_title.isEnableOutputEvent = true;
    this.clonedqualificationLov = cloneDeep(this.globalConf.lovs[this.confChange.select_qualification_classification.listName + ''].list);
    this.filteredRoleList = this.globalConf.lovs[this.confChange.select_title.listName + ''].list.filter(item =>
      item.value !== 33967 && item.value !== 33968);
    if (!this.bphChange) {
      this.bphChange = {};
      this.bphChange.error = {};
      this.bphChange.rows = [];
      this.bphChange.multiYearPath = this.confChange.noOfYears;
      this.bphChange.type = this.confChange.type;
    }
    if (typeof this.bphChange.globalReadOnlyMode !== 'boolean' && this.bphChange.globalReadOnlyMode.includes('undefined')) {
      this.bphChange.globalReadOnlyMode = false;
    }
    if (!this.bphChange.noOfYears) {
      this.bphChange.noOfYears = this.confChange.noOfYears ? this.confChange.noOfYears : 1;
    }
    if (!this.bphChange.rows) {
      this.bphChange.rows = [];
    }
    this.bphChange.rows.forEach((row, index) => {
      if (index !== this.getHeaderRowIndex(index, +(this.bphChange.multiYearPath))) {
        this.bphChange.rows[index].isHidden = true;
      }
    });

    this.yearArray = Array(Number(this.bphChange.noOfYears)).fill(0).map((x, i) => i + 1);
    /*this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;
    });*/
    this.bphChange.getValue = function() {
      return this.bphChange.value;
    };

    this.confChange.initValue = function(currentValue, localConf) {
      currentValue.value = '';
      currentValue.error = {};
      currentValue.getValue = function() {
        return this.bphChange.value;
      };
    };
    setTimeout(() => {
      this.hideMultiYearRows();
    }, 300);
    this.backUpRows = cloneDeep(this.bphChange.rows);
    this.pendingChangesGuard.isPristine = false;
  }

  getTranslatedLabel(key: string){

    return ECaseUtils.getTranslatedValueFromKey(this.translate,key,this.selectedLanguage);
  }

  getYearErrorMessages(headerRowIndex, componentName, columnIndex) {
    if (this.bphChange.rows.length > 0 && this.getHeaderRowIndex(headerRowIndex, this.parseInt(this.bphChange.noOfYears)) === headerRowIndex) {
      for (let i = 1; i <= this.bphChange.noOfYears; i++) {
        const multiYearRowIndex = this.multiYearRowIndexCalculator((headerRowIndex + i), this.parseInt(this.bphChange.noOfYears));
        if (this.bphChange.rows[headerRowIndex + i]
          && (this.bphChange.rows[headerRowIndex + i][(multiYearRowIndex.toString() + columnIndex)])[componentName].error
          && (this.bphChange.rows[headerRowIndex + i][(multiYearRowIndex.toString() + columnIndex)])[componentName].error[this.selectedLanguage]) {
          return (isEmpty((this.bphChange.rows[headerRowIndex + i][(multiYearRowIndex.toString() +
            columnIndex)])[componentName].error)) ? undefined : (this.bphChange.rows[headerRowIndex +
          i][(multiYearRowIndex.toString() + columnIndex)])[componentName].error;
        }
      }
      return undefined;
    } else {
      return (isEmpty((this.bphChange.rows[headerRowIndex][((this.multiYearRowIndexCalculator((headerRowIndex),
        this.parseInt(this.bphChange.noOfYears))).toString() + columnIndex)])[componentName].error) ? undefined :
        (this.bphChange.rows[headerRowIndex][((this.multiYearRowIndexCalculator((headerRowIndex),
          this.parseInt(this.bphChange.noOfYears))).toString() + columnIndex)])[componentName].error);
    }
  }

  getHeaderRowErrorMessages(headerRowIndex, componentName, columnIndex) {
    if (this.bphChange.rows.length > 0 && this.getHeaderRowIndex(headerRowIndex, this.parseInt(this.bphChange.noOfYears)) === headerRowIndex) {
      const multiYearRowIndex = this.multiYearRowIndexCalculator((headerRowIndex), this.parseInt(this.bphChange.noOfYears));
      if (this.bphChange.rows[headerRowIndex]
        && (this.bphChange.rows[headerRowIndex][(multiYearRowIndex.toString() + columnIndex)])[componentName].error
        && (this.bphChange.rows[headerRowIndex][(multiYearRowIndex.toString() + columnIndex)])[componentName].error[this.selectedLanguage]) {
        return (isEmpty((this.bphChange.rows[headerRowIndex][(multiYearRowIndex.toString() +
          columnIndex)])[componentName].error)) ? undefined : (this.bphChange.rows[headerRowIndex][(multiYearRowIndex.toString() + columnIndex)])[componentName].error;
      }
      return undefined;
    } else {
      return (isEmpty((this.bphChange.rows[headerRowIndex][((this.multiYearRowIndexCalculator((headerRowIndex),
        this.parseInt(this.bphChange.noOfYears))).toString() + columnIndex)])[componentName].error) ? undefined :
        (this.bphChange.rows[headerRowIndex][((this.multiYearRowIndexCalculator((headerRowIndex),
          this.parseInt(this.bphChange.noOfYears))).toString() + columnIndex)])[componentName].error);
    }
  }

  getMultiYearGlobalTotal(blockData, column, j, blockConf, currency, totalText) {
    const rows = blockData.rows || [];
    blockData.multiYearPath = blockData.noOfYears ? blockData.noOfYears : blockConf.noOfYears;
    const noOfYears = parseInt(blockData.noOfYears, 10) || 1;
    if (totalText) {
      return totalText[this.selectedLanguage];
    }
    let sum = 0;
    if (blockConf.type === 'MultiYearTableT2') {
      for (let i = 0; i < rows.length; i++) {
        let value = 0;
        if ((rows[i])[i.toString() + j.toString()] &&
          (rows[i])[i.toString() + j.toString()][column] &&
          !isNaN((rows[i])[i.toString() + j.toString()][column].value)) {
          value = Number((rows[i])[i.toString() + j.toString()][column].value);
        }
        sum = sum + value;
      }
    } else {
      if ((typeof column !== 'undefined') && column !== '') {
        for (let i = 0; i < rows.length; i++) {
          if (i % (noOfYears + 1) !== 0 || (i % (noOfYears + 1) === 0 && j === 0)) {
            let value = 0;
            if ((rows[i])[this.multiYearRowIndexCalculator(i, noOfYears).toString() + j.toString()] &&
              (rows[i])[this.multiYearRowIndexCalculator(i, noOfYears).toString() + j.toString()][column] &&
              !isNaN((rows[i])[this.multiYearRowIndexCalculator(i, noOfYears).toString() + j.toString()][column].value)) {
              value = Number((rows[i])[this.multiYearRowIndexCalculator(i, noOfYears).toString() + j.toString()][column].value);
            }
            sum = sum + value;
          }
        }
      } else {
        sum = null;
      }
    }
    return this.eCaseNumberFormatterPipe.transform(sum, !!currency);
  }

  updateFieldValues(blockConf, blockData, rowIndex, columnIndex, tableBlockData, noOfYears, currency) {
    console.log('sdfsdfsdf');
    if (this.confChange.updateEventEmitterEnabled) {
      const obj: any = {};
      obj.blockConf = blockConf;
      obj.blockData = blockData;
      obj.rowIndex = rowIndex;
      obj.columnIndex = columnIndex;
      obj.tableBlockData = tableBlockData;
      obj.noOfYears = noOfYears;
      obj.currency = currency;
      obj.prj = this.globalPrj;
      this.confChange.updateOtherFundsValue(obj, this.eCaseNumberFormatterPipe);
      this.updateOutputEvent.emit(obj);
      for (const functionName of this.confChange.updateOutputEventFunctions) {
        this.confChange[functionName](obj, this.eCaseNumberFormatterPipe);
      }
    }
    this.pendingChangesGuard.isPristine = false;
  }


  updateHeaderRowValue(blockConf, blockData, rowIndex, columnIndex, tableBlockData, noOfYears, currency) {
    noOfYears = isNaN(parseInt(tableBlockData.noOfYears, 10)) ? 1 : parseInt(tableBlockData.noOfYears, 10);
    if (!isNaN(blockData.bphChange.value)) {
      const rowHeaderIndex = this.rowIndex;
      tableBlockData.rows = tableBlockData.rows || [];
      let sum = 0;
      for (let i = 1; i < (noOfYears + 1); i++) {
        let value = 0;
        if (tableBlockData &&
          tableBlockData.rows &&
          tableBlockData.rows[rowHeaderIndex + i] &&
          (tableBlockData.rows[rowHeaderIndex + i])[i.toString() + columnIndex.toString()] &&
          !isNaN((tableBlockData.rows[rowHeaderIndex + i])[i.toString() + columnIndex.toString()][blockConf.name].value)) {
          value = Number((tableBlockData.rows[rowHeaderIndex + i])[i.toString() + columnIndex.toString()][blockConf.name].value);
        }
        sum = sum + value;
      }
      ((tableBlockData.rows[rowHeaderIndex])['0' + columnIndex.toString()])[blockConf.name].value = this.eCaseNumberFormatterPipe.transform(sum, !!currency);
    }
    this.pendingChangesGuard.isPristine = false;
  }

  addMultiYearRow(blockData, blockConf) {
    this.editMode = false;
    blockData.rows = blockData.rows || [];
    this.backUpRows = cloneDeep(blockData.rows);
    blockData.noOfYears = blockData.noOfYears ? blockData.noOfYears : blockConf.noOfYears;
    const noOfYears = isNaN(parseInt(blockData.noOfYears, 10)) ? 1 : parseInt(blockData.noOfYears, 10);
    const parentRowIndex = blockData.rows.length > 0 ? (Math.max.apply(Math, blockData.rows.map(function(o) {
      return o.parentRowIndex ? o.parentRowIndex : 0;
    })) + 1) : 1;
    for (let j = 0; j < (noOfYears + 1); j++) {
      const aRow: any = {};
      aRow.icon = 'add_circle_outline';
      aRow.parentRowIndex = parentRowIndex;
      aRow.isParentRow = j === 0;
      aRow.rowIndex = j === 0 ? blockData.rows.length + j + 1 : undefined;
      aRow.yearIndex = j;
      aRow.isVisible = true;
      let i = 0;
      aRow.uniqueRowIndex = blockData.rows.length > 0 ? (Math.max.apply(Math, blockData.rows.map(function(o) {
        return o.uniqueRowIndex ? o.uniqueRowIndex : 0;
      })) + 1) : 1;
      blockConf.subBlocks.forEach((entity) => {
        aRow[(j.toString()) + (i.toString())] = {};
        aRow[(j.toString()) + (i.toString())].value = '';
        aRow[(j.toString()) + (i.toString())].error = {};
        aRow[(j.toString()) + (i.toString())].showInputComponent = (j === 0 && (i === 4 || i === 5 || i === 6 || i === 7 || i === 8)) || (j !== 0 && (i === 0 || i === 1 || i === 2 || i === 3 || i === 9 || i === 10));
        if (j === 0) {
          aRow[(j.toString()) + (i.toString())].value = '-';
        } else if (j !== 0 && (i === 2 || i === 3 || i === 8 || i === 9 || i === 10)) {
          aRow[(j.toString()) + (i.toString())].value = '';
        } else if (j !== 0 && (i === 1)) {
          aRow[(j.toString()) + (i.toString())].value = blockConf.yearLabel[this.selectedLanguage] + ' ' + j;
        }
        if (!blockConf[entity.name]) {
          blockConf[entity.name] = entity;
        }
        blockConf[entity.name].rowSpan = 1;
        if (blockConf[entity.name].isFirstColumnComponent) {
          blockConf[entity.name].showComponent = j === 0;
        } else {
          blockConf[entity.name].showComponent = j !== 0;
        }
        (aRow[(j.toString()) + (i.toString())])[entity.name] = {};
        (aRow[(j.toString()) + (i.toString())])[entity.name].error = {};
        (aRow[(j.toString()) + (i.toString())])[entity.name].globalShowDetail = true;
        (aRow[(j.toString()) + (i.toString())])[entity.name].enableOutputEvent = j !== 0;
        if (entity.name === 'select_title') {
          (aRow[(j.toString()) + (i.toString())])[entity.name].list = this.filteredRoleList;
          this.refreshDataService.setNewValueForSelect({'name': 'select_title'});
        }
        i++;
      });
      aRow.isHidden = j !== 0;
      aRow.showEdit = j === 0;
      aRow.showDelete = j === 0;
      aRow.rowDeleteButtonHide = j !== 0;
      blockData.rows.push(aRow);
      if (j === 0) {
        this.firstRowOfAddedManpower = aRow;
      }
    }
    let ind = 1;
    blockData.rows.forEach((item,index) => {
      if (((noOfYears + 1) * (Math.floor(index / (noOfYears + 1)))) === index) {
        blockData.rows[index].rowIndex = ind;
        ind = ind + 1;
      } else {
        blockData.rows[index].rowIndex = undefined;
      }
    });
    this.rowIndex = (blockData.rows.length - noOfYears - 1);
    this.isHideTable = true;
    this.toggleOtherFieldsInTheSection(false);
    this.emitOutputEvent.emit(false);
    this.refreshDataService.toggleShowSaveNavBar(false);
    this.getAllInputsInTable();
    this.pendingChangesGuard.isPristine = false;
  }

  toggleOtherFieldsInTheSection(action) {   // Action = false if user want to hide other fields otherwise true.
    const globalArray = [];
    console.log(this.globalPrj[this.selectedSectionId]);
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== this.confChange.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = action;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = !action;
      } catch (e) {
      }
    }
  }


  getAllInputsInTable() {
    setTimeout(() => {
      if (document.getElementById('table_' + this.confChange.name)) {
        const documentSelectField = document.getElementById('table_' + this.confChange.name).getElementsByClassName('mat-form-field-wrapper');
        const size = documentSelectField.length;
        for (let i = 0; i < size; i++) {
          documentSelectField.item(i).setAttribute('style', 'padding:0;');
        }
      }
    }, 100);
    this.pendingChangesGuard.isPristine = false;
  }

  toggleMultiYearRows(i, blockData, blockConf) {
    blockData.multiYearPath = blockData.noOfYears ? blockData.noOfYears : blockConf.noOfYears;
    const noOfYears = parseInt(blockData.noOfYears, 10) || 1;
    blockData.rows = blockData.rows || [];
    (blockData.rows[i]).isHidden = false;
    const rows = cloneDeep(blockData.rows);
    for (let j = 1; j < (noOfYears + 1); j++) {
      const row = cloneDeep(rows[i + j]);
      row.isHidden = rows[i].icon === 'remove_circle_outline';
      rows[i + j] = cloneDeep(row);
    }
    rows[i].icon = rows[i].icon === 'remove_circle_outline' ? 'add_circle_outline' : 'remove_circle_outline';
    blockData.rows = cloneDeep(rows);
  }

  hideMultiYearRows() {
    if (this.bphChange.rows.length > 0) {
      for (let i = 0; i < this.bphChange.rows.length; i++) {
        if (this.getHeaderRowIndex(i, !this.bphChange.noOfYears) === i) {
          this.bphChange.rows[i].icon = 'add_circle_outline';
        } else {
          this.bphChange.rows[i].isHidden = true;
        }
      }
    }
  }

  getMultiYearRowTotal(blockConf, rowIndex, tableBlockData, currency) {
    tableBlockData.noOfYears = tableBlockData.noOfYears ? tableBlockData.noOfYears : blockConf.noOfYears;
    const noOfYears = parseInt(tableBlockData.noOfYears, 10) || 1;
    let currencySign = '0,0$';
    if (!blockConf.currency) {
      currencySign = '0,0';
    } else {
      currencySign = blockConf.currency + '0,0';
    }
    tableBlockData.rows = tableBlockData.rows || [];
    let sum = 0;
    if (blockConf.type === 'MultiYearTableT2') {
      for (let i = 0; i < noOfYears; i++) {
        let value = 0;
        if (tableBlockData &&
          tableBlockData.rows &&
          tableBlockData.rows[rowIndex] &&
          (tableBlockData.rows[rowIndex])[rowIndex.toString() + i.toString()] &&
          !isNaN((tableBlockData.rows[rowIndex])[rowIndex.toString() + i.toString()][blockConf.subBlocks[0].name].value)) {
          value = Number((tableBlockData.rows[rowIndex])[rowIndex.toString() + i.toString()][blockConf.subBlocks[0].name].value);
        }
        sum = sum + value;
      }
    } else {
      const calculatedRowIndex = this.multiYearRowIndexCalculator(rowIndex, noOfYears);
      for (let i = 0; i < blockConf.subBlocks.length; i++) {
        let value = 0;
        if (tableBlockData &&
          tableBlockData.rows &&
          tableBlockData.rows[rowIndex] &&
          (tableBlockData.rows[rowIndex])[calculatedRowIndex.toString() + i.toString()] &&
          !isNaN((tableBlockData.rows[rowIndex])[calculatedRowIndex.toString() + i.toString()][blockConf.subBlocks[i].name].value)) {
          value = Number((tableBlockData.rows[rowIndex])[calculatedRowIndex.toString() + i.toString()][blockConf.subBlocks[i].name].value);
        }
        sum = sum + value;
        if (rowIndex % (noOfYears + 1) === 0) {
          sum = sum + Number((tableBlockData.rows[rowIndex])['0' + i.toString()].value
            .replace(/,/g, '')
            .replace(/-/g, '0')
            .replace(blockConf.currency ? blockConf.currency : '$', '')
          );
        }
      }
    }
    this.pendingChangesGuard.isPristine = false;
    return this.eCaseNumberFormatterPipe.transform(sum, !!currency);
  }

  multiYearRowIndexCalculator(rowIndex, noOfYears) {
    noOfYears = isNaN(noOfYears) ? 1 : noOfYears;
    return rowIndex % (noOfYears + 1);
  }

  getMultiYearTotalGlobalTotal(tableBlockData, blockConf) {
    const rows = tableBlockData.rows || [];
    blockConf.subBlocks = blockConf.subBlocks || [];
    tableBlockData.noOfYears = tableBlockData.noOfYears ? tableBlockData.noOfYears : blockConf.noOfYears;
    const noOfYears = parseInt(tableBlockData.noOfYears, 10) || 1;
    let sum = 0;
    if (blockConf.type === 'MultiYearTableT2') {
      for (let j = 0; j < noOfYears; j++) {
        const column = (blockConf.subBlocks[0]).name;
        if ((typeof column !== 'undefined') && column !== '') {
          for (let i = 0; i < rows.length; i++) {
            let value = 0;
            if ((rows[i])[i.toString() + j.toString()] &&
              (rows[i])[i.toString() + j.toString()][column] &&
              !isNaN((rows[i])[i.toString() + j.toString()][column].value)) {
              value = Number((rows[i])[i.toString() + j.toString()][column].value);
            }
            sum = sum + value;
          }
        } else {
          sum = null;
        }
      }
    } else {
      for (let j = 0; j < blockConf.subBlocks.length; j++) {
        const column = (blockConf.subBlocks[j]).name;
        if ((typeof column !== 'undefined') && column !== '') {
          for (let i = 0; i < rows.length; i++) {
            if (i % (noOfYears + 1) !== 0 || (i % (noOfYears + 1) === 0 && j === 0)) {
              let value = 0;
              if ((rows[i])[this.multiYearRowIndexCalculator(i, noOfYears).toString() + j.toString()] &&
                (rows[i])[this.multiYearRowIndexCalculator(i, noOfYears).toString() + j.toString()][column] &&
                !isNaN((rows[i])[this.multiYearRowIndexCalculator(i, noOfYears).toString() + j.toString()][column].value)) {
                value = Number((rows[i])[this.multiYearRowIndexCalculator(i, noOfYears).toString() + j.toString()][column].value);
              }
              sum = sum + value;
            }
          }
        } else {
          sum = null;
        }
      }
    }
    this.pendingChangesGuard.isPristine = false;
    return this.eCaseNumberFormatterPipe.transform(sum, !!blockConf.currency);
  }

  editMultiYearRow(i) {
    this.backUpRows = cloneDeep(this.bphChange.rows);
    this.rowIndex = i;
    this.isHideTable = true;
    this.editMode = true;
    this.getAllInputsInTable();
    this.emitOutputEvent.emit(false);
    this.refreshDataService.toggleShowSaveNavBar(false);
    this.toggleOtherFieldsInTheSection(false);
  }

  deleteMultiYearRow(i, blockData, blockConf) {
    if (this.confChange.isConfirmDelete) {
      this.confChange.confirmDelete(this.globalPrj, this.globalConf, i, this.backUpRows);
    }
    blockData.noOfYears = blockData.noOfYears ? blockData.noOfYears : blockConf.noOfYears;
    const noOfYears = isNaN(parseInt(blockData.noOfYears, 10)) ? 1 : parseInt(blockData.noOfYears, 10);
    const rowModulesIndex = (i % (noOfYears + 1)).toString();
    if (blockConf.subBlocks) {
      for (let ii = 0; ii < blockConf.subBlocks.length; ii++) {
        if (blockConf.subBlocks[ii] && blockConf.subBlocks[ii].type === 'upload') {
          const subBlockName = blockConf.subBlocks[ii].name;
          if (((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName].value ) {
            for (let iiTobd = 0; iiTobd < ((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName].value.length; iiTobd++) {
              if(((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName].value[iiTobd] && ((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName].value[iiTobd].fileUploaded ){
                if (!this.globalPrj.upload_to_be_deleted) {
                  this.globalPrj.upload_to_be_deleted = [];
                }
                this.globalPrj.upload_to_be_deleted.push(((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName].value[iiTobd].id);
              }
            }
          }
        }
      }
    }

    blockData.rows.splice(i, noOfYears + 1);
    blockData.rows.forEach((item, index) => {
      blockData.rows[index].rowIndex = index + 1;
    });
    // this.resuffleTable(blockData, blockConf);
    this.pendingChangesGuard.isPristine = false;
  }

  getHeaderRowIndex(rowIndex, noOfYears) {
    noOfYears = isNaN(noOfYears) ? 1 : noOfYears;
    return (noOfYears + 1) * (Math.floor(rowIndex / (noOfYears + 1)));
  }

  closeDetails() {
    this.isHideTable = false;
    this.emitOutputEvent.emit(true);
    this.refreshDataService.toggleShowSaveNavBar(true);
    if (this.confChange.isAddRowFunctionActive && !this.editMode) {
      this.confChange.addRow(this.globalPrj, this.globalConf);
    } else if (this.confChange.isEditRowFunctionActive && this.editMode) {
      this.confChange.editRow(this.globalPrj, this.globalConf, this.rowIndex, this.backUpRows);
    }
    this.backUpRows = cloneDeep(this.bphChange.rows);
    this.editMode = false;
    this.toggleOtherFieldsInTheSection(true);
    this.pendingChangesGuard.isPristine = false;
  }

  cancel() {
    this.bphChange.rows = this.backUpRows;
    this.isHideTable = false;
    this.editMode = false;
    this.emitOutputEvent.emit(true);
    this.refreshDataService.toggleShowSaveNavBar(true);
    this.toggleOtherFieldsInTheSection(true);
    this.pendingChangesGuard.isPristine = false;
  }

  ngOnDestroy(): void {
    this.emitOutputEvent.emit(true);
    this.refreshDataService.toggleShowSaveNavBar(true);
    this.toggleOtherFieldsInTheSection(true);
  }

  updateQualifications(event) {
    const selectedTitle = event.bphChange.value.value;
    console.log('Selected Title', selectedTitle);
    if (selectedTitle === 33962) {
      (this.bphChange.rows[this.rowIndex])[((this.multiYearRowIndexCalculator(this.rowIndex, this.parseInt
      (this.bphChange.noOfYears))).toString() + 1)].select_qualification_classification.list = this.clonedqualificationLov.filter(item =>
        item.value === 33943);
    } else if (selectedTitle === 33960) {
      (this.bphChange.rows[this.rowIndex])[((this.multiYearRowIndexCalculator(this.rowIndex, this.parseInt
      (this.bphChange.noOfYears))).toString() + 1)].select_qualification_classification.list = this.clonedqualificationLov.filter(item =>
        item.value === 33946);
    } else if (selectedTitle === 33961 ) {
      (this.bphChange.rows[this.rowIndex])[((this.multiYearRowIndexCalculator(this.rowIndex, this.parseInt
      (this.bphChange.noOfYears))).toString() + 1)].select_qualification_classification.list = this.clonedqualificationLov.filter(item =>
        item.value === 33944);
    } else if (selectedTitle === 33955) {
      (this.bphChange.rows[this.rowIndex])[((this.multiYearRowIndexCalculator(this.rowIndex, this.parseInt
      (this.bphChange.noOfYears))).toString() + 1)].select_qualification_classification.list = this.clonedqualificationLov.filter(item =>
        item.value === 33945);
    } else if (selectedTitle === 33959) {
      (this.bphChange.rows[this.rowIndex])[((this.multiYearRowIndexCalculator(this.rowIndex, this.parseInt
      (this.bphChange.noOfYears))).toString() + 1)].select_qualification_classification.list = this.clonedqualificationLov.filter(item =>
        item.value === 33946);
    } else if (selectedTitle === 33956) {
      (this.bphChange.rows[this.rowIndex])[((this.multiYearRowIndexCalculator(this.rowIndex, this.parseInt
      (this.bphChange.noOfYears))).toString() + 1)].select_qualification_classification.list = this.clonedqualificationLov.filter(item =>
        item.value === 33947 );
    }  else if (selectedTitle === 33957) {
      (this.bphChange.rows[this.rowIndex])[((this.multiYearRowIndexCalculator(this.rowIndex, this.parseInt
      (this.bphChange.noOfYears))).toString() + 1)].select_qualification_classification.list = this.clonedqualificationLov.filter(item =>
        item.value === 33948 );
    }  else if (selectedTitle === 33958) {
      (this.bphChange.rows[this.rowIndex])[((this.multiYearRowIndexCalculator(this.rowIndex, this.parseInt
      (this.bphChange.noOfYears))).toString() + 1)].select_qualification_classification.list = this.clonedqualificationLov.filter(item =>
        item.value === 33948 || item.value === 33947 );
    } else {
      (this.bphChange.rows[this.rowIndex])[((this.multiYearRowIndexCalculator(this.rowIndex, this.parseInt
      (this.bphChange.noOfYears))).toString() + 1)].select_qualification_classification.list = this.clonedqualificationLov;
    }
    this.refreshDataService.setNewValueForSelect({'name': 'select_qualification_classification'});
  }

  convertNumberToCurrencyFormat(value, currency) {
    return this.eCaseNumberFormatterPipe.transform(value, !!currency);
  }
}
