import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'fb-multi-year-table-t2',
  templateUrl: './fb-multi-year-table-t2.component.html',
  styleUrls: ['./fb-multi-year-table-t2.component.css']
})
export class FbMultiYearTableT2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
