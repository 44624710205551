<div *ngIf= "pathname !== '/'" class="page-layout simple right-sidenav" id="dynamic-form">
  <mat-sidenav-container style="background-color: white">
    <mat-sidenav #serverRenderedSideNav *ngIf="showSectionsNavBar && showHeaderAndSectionNavBar"
                 align="start" class="sidenav left-sidenav" mode="side" opened="true"
                 style="width:210px; min-width:210px;">
      <!-- SIDENAV HEADER -->
      <div class="header" fxLayout="column" fxLayoutAlign="space-between"
           style="padding-top: 10px;padding-bottom: 24px;">
        <div class="logo" fxLayout="row" fxLayoutAlign="start center" style="padding-left: 15px;">
          <div class="logo" fxLayout="row" fxLayoutAlign="start center">
            <button (click)="toggleSectionNavBar()" mat-icon-button>
              <mat-icon>list</mat-icon>
            </button>
            <span style="font-size: 20px">{{getTranslatedValue('ecase.common.sections')}}</span>
          </div>
        </div>
        <!-- / SIDENAV HEADER -->
        <!-- SIDENAV CONTENT -->
        <div class="content">
          <div class="nav">
            <ng-container *ngFor="let section of sections; let i = index">
              <div *ngIf="!section.hide"
                   [style.background-color]="section.selected ? 'lightgrey' : 'white'" aria-label="inbox"
                   class="nav-item">
                <ng-container *ngIf="!section.isCategory">
                  <a (click)="sectionClicked(section,i,true)" *ngIf="!section.hide" class="nav-link" matRipple style="padding-bottom: 5px;
                                  padding-top: 5px;
                                  width: 190px;
                                  min-height: 50px;
                                  height: auto !important;"
                     [ngClass]="[
                     section.groupItemObj && section.groupItemObj.isBelongToGroup ? 'section-group-item' : '',
                     section.groupItemObj && section.groupItemObj.isAtFirstPostionInGroup ? 'section-group-first' : '',
                     section.groupItemObj && section.groupItemObj.isAtLastPostionInGroup ? 'section-group-last' : '']">
                    <mat-icon style= "color: #2e7d32 !important;" *ngIf="conf[section.id].fbValid === true || (conf[section.id].fbValid !== false && prj[section.id].fbValid === true)" class="nav-link-icon">check_circle</mat-icon>
                    <mat-icon style= "color: #e65100 !important;" *ngIf="conf[section.id].fbValid === false || (conf[section.id].fbValid !== true && prj[section.id].fbValid === false)" class="nav-link-icon">warning</mat-icon>
                    <mat-icon
                      *ngIf="!prj[section.id].fbValid && (prj[section.id].fbValid !== true && prj[section.id].fbValid !== false) &&
                             !conf[section.id].fbValid && (conf[section.id].fbValid !== true && conf[section.id].fbValid !== false)"
                      class="nav-link-icon"></mat-icon>
                    <span [innerHTML]="section.title[lang].replace('\/','/<wbr>')"
                          [style.font-weight]="section.selected ? 'bolder' : 'normal'"
                          class="title"></span>
                  </a>
                </ng-container>
                <ng-container *ngIf="section.isCategory">
                   <span [innerHTML]="section.title[lang].replace('\/','/<wbr>')"
                         [style.font-weight]="section.selected ? 'bolder' : 'normal'"
                         class="nav-link-icon" style="padding-bottom: 5px;
                                                      padding-top: 15px;
                                                      width: 190px;
                                                      min-height: 50px;
                                                      font-weight: bolder;
                                                      display: block;
                                                      text-align: center;
                                                      color: #797979;
                                                      height: auto !important;"
                   ></span>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- / SIDENAV CONTENT -->
    </mat-sidenav>
    <mat-sidenav-content class="mat-elevation-z2">
      <div class="center" fxFlex>
        <!-- HEADER -->
        <div *ngIf="showHeaderAndSectionNavBar"
             class="header p-24"
             fxLayout="column" fxLayoutAlign="space-between start"
             style="background-image: none;height: fit-content;min-height: 60px;padding-top:10px!important;padding-bottom:0!important">
          <!-- TOOLBAR -->
          <div class="toolbar w-100-p" fxFlex fxLayout="row" fxLayoutAlign="space-between start" style="border: 0 !important;">
            <div fxLayout="row" fxLayoutAlign="start">
              <button (click)="toggleSectionNavBar()" class="sidenav-toggle" mat-icon-button>
                <mat-icon style="color: black">menu</mat-icon>
              </button>
              <span style="color: black;font-size: 20px;margin-top: 6px">{{previousSection.title[lang]}}</span>

            </div>
            <div *ngIf="showSaveNavBar && !prj.hideTopSaveButtons" fxLayout="row" fxLayoutAlign="end">
              <button (click)="saveDialogBefore('Validate')" [attr.aria-label]="getTranslatedValue('ecase.common.validate')"
                      [disabled]="previousSection.isSectionRestricted || isValidateButtonClicked || globalReadOnlyMode || prj.isDisableSaveAndValidateButtons"
                      *ngIf="!prj[previousSection.id].isUpperValidationButtonHidden && !prj[previousSection.id].hideValidateButtons"
                      class="eCase-dialog-positive-action-button" mat-raised-button
                      style="margin-right: 20px">{{getTranslatedValue('ecase.common.validate')}}
                <mat-icon class="ml-5" *ngIf="isValidateButtonClicked"><mat-spinner diameter="20"></mat-spinner></mat-icon>
              </button>
              <ng-container *ngTemplateOutlet="formRevisionPublishChangesButton; context: {'isAddMarginRight': true}"></ng-container>
              <button (click)="saveDialogBefore('Save')" [attr.aria-label]="getTranslatedValue('ecase.common.save')"
                      [disabled]="previousSection.isSectionRestricted || isSaveButtonClicked || globalReadOnlyMode || prj.isDisableSaveAndValidateButtons"
                      *ngIf="!prj[previousSection.id].isUpperSaveButtonHidden && !prj[previousSection.id].hideSaveButtons"
                      class="eCase-dialog-positive-action-button"
                      mat-raised-button style="margin-right: 20px">
                {{getTranslatedValue('ecase.common.save')}}
                <mat-icon class="ml-5" *ngIf="isSaveButtonClicked"><mat-spinner diameter="20"></mat-spinner></mat-icon>
              </button>
              <div class="dropdown" style="margin-right: 8px">
                <button class="dropbtn" mat-raised-button *ngIf="!prj[previousSection.id].isUpperPrintButtonHidden" [disabled]="isPrintButtonClicked">
                  {{getTranslatedValue('ecase.common.print')}}
                  <mat-icon class="ml-5" *ngIf="isPrintButtonClicked"><mat-spinner diameter="20"></mat-spinner></mat-icon>
                </button>
                <div class="dropdown-content" *ngIf="!isPrintButtonClicked">
                  <a (click)="export(menuItem)" *ngFor="let menuItem of exportMenu"
                     style="text-decoration: none;">{{getTranslatedValue(menuItem)}}</a>
                </div>
              </div>
            </div>
            <div *ngIf="isFormRevisionMode && !(showSaveNavBar && !prj.hideTopSaveButtons)">
              <ng-container *ngTemplateOutlet="formRevisionPublishChangesButton; context: {'isAddMarginRight': false}"></ng-container>
            </div>
          </div>
          <!-- / TOOLBAR -->
        </div>
        <div *ngIf="isFormRevisionMode && !showHeaderAndSectionNavBar">
          <ng-container *ngTemplateOutlet="formRevisionPublishChangesButton; context: {'isAddMarginRight': false}"></ng-container>
        </div>
        <!-- / HEADER -->
        <!-- CONTENT -->
        <div
          [style]="isNoPaddingOnFormContainerEnabled ? 'overflow: auto;padding-bottom: 5px;' : (isFormbuilderModeEnabled ? 'overflow: auto;padding-left: 5px;padding-bottom: 5px;' : 'padding-left: 32px;padding-right: 32px;padding-bottom: 32px;overflow: auto;')"
          class="content mat-white-bg">
          <form autocomplete="off">
            <div id="contentNova">


              <ng-container *ngFor="let section of sections">
                <ng-container *ngIf="!section.hide">
                  <ng-container *ngTemplateOutlet="sectionTemplate; context: section.templateContext"></ng-container>
                </ng-container>
              </ng-container>


            </div>
          </form>
        </div>
        <div *ngIf="showHeaderAndSectionNavBar" class="header"
             style="background-image: none;height: 80px;min-height: 80px;padding-right: 24px;">
          <div *ngIf="showSaveNavBar && (prj.showSaveAndContinue || lastSectionIndex !== (sectionSelected.index === undefined ? 0 : sectionSelected.index))"
               fxLayout="row"
               fxLayoutAlign="end">
            <button (click)="saveDialogBefore('Validate')" [attr.aria-label]="getTranslatedValue('ecase.common.validate')"
                    [disabled]="previousSection.isSectionRestricted || isValidateButtonClicked || globalReadOnlyMode || prj.isDisableSaveAndValidateButtons"
                    *ngIf="!prj[previousSection.id].isLowerValidationButtonHidden && !prj[previousSection.id].hideValidateButtons"
                    class="eCase-dialog-positive-action-button" mat-raised-button
                    style="margin-right: 20px">{{getTranslatedValue('ecase.common.validate')}}
              <mat-icon class="ml-5" *ngIf="isValidateButtonClicked"><mat-spinner diameter="20"></mat-spinner></mat-icon>
            </button>
            <ng-container *ngTemplateOutlet="formRevisionPublishChangesButton; context: {'isAddMarginRight': true}"></ng-container>
            <button (click)="saveDialogBefore('Save and continue')"
                    [attr.aria-label]="getTranslatedValue('ecase.common.saveandcontinue')"
                    *ngIf="!prj[previousSection.id].isLowerSaveButtonHidden && !prj[previousSection.id].hideSaveButtons"
                    [disabled]="previousSection.isSectionRestricted || isSaveButtonClicked || globalReadOnlyMode || prj.isDisableSaveAndValidateButtons"
                    class="eCase-dialog-positive-action-button"
                    mat-raised-button
                    style="margin-right: 20px">{{getTranslatedValue('ecase.common.saveandcontinue')}}
              <mat-icon class="ml-5" *ngIf="isSaveButtonClicked"><mat-spinner diameter="20"></mat-spinner></mat-icon>
            </button>
            <div class="dropup" fxLayout="column" fxLayoutAlign="start" style="margin-right: 8px">
              <button class="dropbtn" mat-raised-button *ngIf="!prj[previousSection.id].isLowerPrintButtonHidden" [disabled]="isPrintButtonClicked">
                {{getTranslatedValue('ecase.common.print')}}
                <mat-icon class="ml-5" *ngIf="isPrintButtonClicked"><mat-spinner diameter="20"></mat-spinner></mat-icon>
              </button>
              <div class="dropup-content" *ngIf="!isPrintButtonClicked">
                <a (click)="export(menuItem)" *ngFor="let menuItem of exportMenu"
                   style="text-decoration: none;">{{getTranslatedValue(menuItem)}}</a>
              </div>
            </div>
          </div>
        </div>
        <!-- / FOOTER -->
      </div>
    </mat-sidenav-content>
    <!-- / SIDENAV -->
    <!-- CENTER -->

    <!-- / CENTER -->

  </mat-sidenav-container>
</div>


<ng-template #formRevisionPublishChangesButton let-isAddMarginRight="isAddMarginRight">
  <button (click)="saveDialogBefore('Save', undefined, true)" [attr.aria-label]="getTranslatedValue('ecase.common.publish.save')"
          *ngIf="isFormRevisionMode && publishSaveUrl" [disabled]="!isFormRevisionSyncPending && (isRevisionSaveButtonClicked || pendingChangesGuard.isPristine)"
          class="eCase-dialog-positive-action-button"
          [style.margin-right.px]="isAddMarginRight ? 20 : ''"
          mat-raised-button>
    {{getTranslatedValue('ecase.common.publish.save')}}
    <mat-icon class="ml-5" *ngIf="isRevisionSaveButtonClicked"><mat-spinner diameter="20"></mat-spinner></mat-icon>
  </button>
</ng-template>

<ng-template #statictext let-blockName="blockName" let-bphChange="bphChange" let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly" let-selectedSectionId="selectedSectionId">
  <fb-static-text [bphChange]='(prj[selectedSectionId])[blockName]'
                  [confChange]='confChange'
                  [globalConf]='conf'
                  [globalPrj]='prj'
                  [isValidHtml]='isValidHtml'
                  [isValidTableReadonly]='isValidTableReadonly'
                  [isValidTable]='isValidTable'
                  [selectedLanguage]='lang'
                  style='position: static;'>
  </fb-static-text>
</ng-template>
<ng-template #textarea let-blockName="blockName" let-bphChange="bphChange" let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly" let-selectedSectionId="selectedSectionId">
  <fb-text-area [bphChange]='(prj[selectedSectionId])[blockName]'
                [confChange]='confChange'
                (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction2($event, confChange,(prj[selectedSectionId])[blockName]) : doNothing()'
                (newTitle)="changeTitle($event)"
                [globalConf]='conf'
                [globalPrj]='prj'
                [isValidHtml]='isValidHtml'
                [isValidTableReadonly]='isValidTableReadonly'
                [isValidTable]='isValidTable'
                [selectedLanguage]='lang'
                style='position: static;'>
  </fb-text-area>
</ng-template>
<ng-template #Radiobutton let-blockName="blockName" let-bphChange="bphChange" let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly" let-selectedSectionId="selectedSectionId">
  <fb-radiobutton (emitOutputEvent)="executeFunction($event,confChange,(prj[selectedSectionId])[blockName])"
                  [bphChange]='(prj[selectedSectionId])[blockName]'
                  [confChange]='confChange'
                  [globalConf]='conf'
                  [globalPrj]='prj'
                  [isValidHtml]='isValidHtml'
                  [isValidTableReadonly]='isValidTableReadonly'
                  [isValidTable]='isValidTable'
                  [selectedLanguage]='lang'
                  style='position: static;'>
  </fb-radiobutton>
</ng-template>
<ng-template #select let-blockName="blockName" let-bphChange="bphChange" let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly" let-selectedSectionId="selectedSectionId">
  <fb-select (emitOutputEvent)="executeFunction($event,confChange,(prj[selectedSectionId])[blockName])"
             [bphChange]='(prj[selectedSectionId])[blockName]'
             [confChange]='confChange'
             [globalConf]='conf'
             [globalPrj]='prj'
             [isValidHtml]='isValidHtml'
             [isValidTableReadonly]='isValidTableReadonly'
             [isValidTable]='isValidTable'
             [selectedLanguage]='lang'
             [selectedSectionId]='selectedSectionId'
             style='position: static;'>
  </fb-select>
</ng-template>
<ng-template #Container let-blockName="templateContext.blockName" let-bphChange="templateContext.bphChange" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-selectedSectionId="templateContext.selectedSectionId">
  <fb-container [bphChange]='(prj[selectedSectionId])[blockName]'
                [sections]="sections"
                [confChange]='confChange'
                [globalConf]='conf'
                [globalPrj]='prj'
                [isFormbuilderModeEnabled]="isFormbuilderModeEnabled"
                [isValidHtml]='isValidHtml'
                [isValidTableReadonly]='isValidTableReadonly'
                [isValidTable]='isValidTable'
                [offlineModeEnabled]="offline"
                [selectedLanguage]='lang'
                [blockIndex]='blockIndex'
                [selectedSectionId]='selectedSectionId'
                style='position: static;'>
  </fb-container>
</ng-template>
<ng-template #text let-blockName="blockName" let-bphChange="bphChange" let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly" let-selectedSectionId="selectedSectionId">
  <fb-text
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction2($event, confChange,(prj[selectedSectionId])[blockName]) : doNothing()'
    (emitOutputEventForSchedulerTable)='(confChange.enableOutputEventForSchedulerTable) ? resetSchedulerTable((prj[selectedSectionId])[blockName]) : doNothing()'
    (focusin)="confChange.isFocusInEnabled ? executeFunction($event,confChange,(prj[selectedSectionId])[blockName]) : doNothing()"
    (newTitle)="changeTitle($event)"
    [bphChange]='(prj[selectedSectionId])[blockName]'
    [confChange]='confChange'
    [globalConf]='conf'
    [globalPrj]='prj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='lang'
    [selectedSectionId]='selectedSectionId'
    style='position: static;'>
  </fb-text>
</ng-template>
<ng-template #button let-blockName="blockName" let-bphChange="bphChange" let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly" let-selectedSectionId="selectedSectionId">
  <fb-button [bphChange]='(prj[selectedSectionId])[blockName]'
             [sections]="sections"
             [confChange]='confChange'
             [globalConf]='conf'
             [globalPrj]='prj'
             [isValidHtml]='isValidHtml'
             [isValidTableReadonly]='isValidTableReadonly'
             [isValidTable]='isValidTable'
             [offlineModeEnabled]='offline'
             [isFormbuilderModeEnabled]="isFormbuilderModeEnabled"
             [selectedLanguage]='lang'
             style='position: static;'>
  </fb-button>
</ng-template>
<ng-template #upload let-blockName="templateContext.blockName" let-bphChange="templateContext.bphChange" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-selectedSectionId="templateContext.selectedSectionId">
  <fb-upload
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction($event,confChange,(prj[selectedSectionId])[blockName]) : doNothing()'
    [bphChange]='(prj[selectedSectionId])[blockName]'
    [confChange]='confChange'
    [formId]="formID"
    [globalConf]='conf'
    [globalPrj]='prj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [offlineModeEnabled]='offline'
    [selectedLanguage]='lang'
    [selectedSectionId]="selectedSectionId"
    [blockIndex]="blockIndex"
    style='position: static;'>
  </fb-upload>
</ng-template>
<ng-template #Table let-blockName="templateContext.blockName" let-bphChange="templateContext.bphChange" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-selectedSectionId="templateContext.selectedSectionId">
  <fb-table [bphChange]='(prj[selectedSectionId])[blockName]'
            [confChange]='confChange'
            [sections]='sections'
            [showSaveNavBar]='showSaveNavBar'
            [globalConf]='conf'
            [globalPrj]='prj'
            [isValidHtml]='isValidHtml'
            [isValidTableReadonly]='isValidTableReadonly'
            [isValidTable]='isValidTable'
            [offlineModeEnabled]='offline'
            [selectedLanguage]='lang'
            [selectedSectionId]='selectedSectionId'
            [blockIndex]='blockIndex'
            style='position: static;'>
  </fb-table>
</ng-template>
<ng-template #BudgetTable let-blockName="templateContext.blockName" let-bphChange="templateContext.bphChange" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-selectedSectionId="templateContext.selectedSectionId">
  <fb-budget-table [bphChange]='(prj[selectedSectionId])[blockName]'
            [confChange]='confChange'
            [sections]='sections'
            [showSaveNavBar]='showSaveNavBar'
            [globalConf]='conf'
            [globalPrj]='prj'
            [isValidHtml]='isValidHtml'
            [isValidTableReadonly]='isValidTableReadonly'
            [isFormRevisionMode]='isFormRevisionMode'
            [isValidTable]='isValidTable'
            [offlineModeEnabled]='offline'
            [selectedLanguage]='lang'
            [selectedSectionId]='selectedSectionId'
            [blockIndex]='blockIndex'
            style='position: static;'>
  </fb-budget-table>
</ng-template>
<ng-template #SubmitButton let-blockName="blockName" let-bphChange="bphChange" let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly" let-selectedSectionId="selectedSectionId">
  <fb-submit-button [bphChange]='(prj[selectedSectionId])[blockName]'
                    [sections]="sections"
                    [confChange]='confChange'
                    [globalConf]='conf'
                    [globalPrj]='prj'
                    [isFormbuilderModeEnabled]="isFormbuilderModeEnabled"
                    [isValidHtml]='isValidHtml'
                    [isValidTableReadonly]='isValidTableReadonly'
                    [isValidTable]='isValidTable'
                    [selectedLanguage]='lang'
                    [selectedSectionId]='selectedSectionId'
                    style='position: static;padding-right:20px;'>
  </fb-submit-button>
</ng-template>
<ng-template #Checkbox let-blockName="blockName" let-bphChange="bphChange" let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly" let-selectedSectionId="selectedSectionId">
  <fb-checkbox (emitOutputEvent)="executeFunction($event,confChange,(prj[selectedSectionId])[blockName])"
               [bphChange]='(prj[selectedSectionId])[blockName]'
               [confChange]='confChange'
               [globalConf]='conf'
               [globalPrj]='prj'
               [isValidHtml]='isValidHtml'
               [isValidTableReadonly]='isValidTableReadonly'
               [isValidTable]='isValidTable'
               [selectedLanguage]='lang'
               style='position: static;'>
  </fb-checkbox>
</ng-template>
<ng-template #datepicker let-blockName="blockName" let-bphChange="bphChange" let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly" let-selectedSectionId="selectedSectionId">
  <fb-datepicker
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction($event,confChange,(prj[selectedSectionId])[blockName]) : doNothing()'
    [bphChange]='(prj[selectedSectionId])[blockName]'
    [confChange]='confChange'
    [globalConf]='conf'
    [globalPrj]='prj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='lang'
    [selectedSectionId]='selectedSectionId'
    style='position: static;'>
  </fb-datepicker>
</ng-template>
<ng-template #AutoComplete let-blockName="blockName" let-bphChange="bphChange" let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly" let-selectedSectionId="selectedSectionId">
  <fb-autoComplete
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction($event,confChange,(prj[selectedSectionId])[blockName]) : doNothing()'
    [bphChange]='(prj[selectedSectionId])[blockName]'
    [confChange]='confChange'
    [globalConf]='conf'
    [globalPrj]='prj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='lang'
    [selectedSectionId]='selectedSectionId'
    style='position: static;'>
  </fb-autoComplete>
</ng-template>
<ng-template #dynamicMatrix let-blockName="blockName" let-bphChange="bphChange" let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly" let-selectedSectionId="selectedSectionId">
  <fb-dynamic-matrix [bphChange]='(prj[selectedSectionId])[blockName]'
                     [confChange]='confChange'
                     [globalConf]='conf'
                     [globalPrj]='prj'
                     [isValidHtml]='isValidHtml'
                     [isValidTableReadonly]='isValidTableReadonly'
                     [isValidTable]='isValidTable'
                     [selectedLanguage]='lang'
                     style='position: static;'>
  </fb-dynamic-matrix>
</ng-template>
<ng-template #MultiYearTable let-blockName="templateContext.blockName" let-bphChange="templateContext.bphChange" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-selectedSectionId="templateContext.selectedSectionId">
  <fb-multi-year-table [bphChange]='(prj[selectedSectionId])[blockName]'
                       [confChange]='confChange'
                       [globalConf]='conf'
                       [globalPrj]='prj'
                       [isValidHtml]='isValidHtml'
                       [isValidTableReadonly]='isValidTableReadonly'
                       [isValidTable]='isValidTable'
                       [selectedLanguage]='lang'
                       [selectedSectionId]='selectedSectionId'
                       [blockIndex]='blockIndex'
                       style='position: static;'>
  </fb-multi-year-table>
</ng-template>
<ng-template #SchedulerTable let-blockName="blockName" let-bphChange="bphChange" let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly" let-selectedSectionId="selectedSectionId">
  <fb-scheduler-table [bphChange]='(prj[selectedSectionId])[blockName]'
                      [confChange]='confChange'
                      [globalConf]='conf'
                      [globalPrj]='prj'
                      [isValidHtml]='isValidHtml'
                      [isValidTableReadonly]='isValidTableReadonly'
                      [isValidTable]='isValidTable'
                      [selectedLanguage]='lang'
                      [selectedSectionId]='selectedSectionId'
                      style='position: static;'>
  </fb-scheduler-table>
</ng-template>
<ng-template #BudgetType1 let-blockName="templateContext.blockName" let-bphChange="templateContext.bphChange" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-selectedSectionId="templateContext.selectedSectionId">
  <fb-budget-t1 [bphChange]='(prj[selectedSectionId])[blockName]'
                [confChange]='confChange'
                [formId]="formID"
                [globalConf]='conf'
                [globalPrj]='prj'
                [isValidHtml]='isValidHtml'
                [isValidTable]='isValidTable'
                [selectedLanguage]='lang'
                [selectedSectionId]="selectedSectionId"
                [blockIndex]='blockIndex'
                style='position: static;'>
  </fb-budget-t1>
</ng-template>
<ng-template #BudgetType2 let-blockName="templateContext.blockName" let-bphChange="templateContext.bphChange" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-selectedSectionId="templateContext.selectedSectionId">
  <fb-budget-t2 [bphChange]='(prj[selectedSectionId])[blockName]'
                [confChange]='confChange'
                [formId]="formID"
                [globalConf]='conf'
                [globalPrj]='prj'
                [isValidHtml]='isValidHtml'
                [isValidTable]='isValidTable'
                [selectedLanguage]='lang'
                [selectedSectionId]="selectedSectionId"
                [blockIndex]='blockIndex'
                style='position: static;'>
  </fb-budget-t2>
</ng-template>
<ng-template #BudgetType3 let-blockName="templateContext.blockName" let-bphChange="templateContext.bphChange" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-selectedSectionId="templateContext.selectedSectionId">
  <fb-budget-t3
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction($event,confChange,(prj[selectedSectionId])[blockName]) : doNothing()'
    [bphChange]='(prj[selectedSectionId])[blockName]'
    [confChange]='confChange'
    [globalConf]='conf'
    [globalPrj]='prj'
    [isValidHtml]='isValidHtml'
    [isValidTable]='isValidTable'
    [selectedLanguage]='lang'
    [selectedSectionId]='selectedSectionId'
    [blockIndex]='blockIndex'
    style='position: static;'>
  </fb-budget-t3>
</ng-template>
<ng-template #BudgetType4 let-blockName="templateContext.blockName" let-bphChange="templateContext.bphChange" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-selectedSectionId="templateContext.selectedSectionId">
  <fb-budget-t4
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction($event,confChange,(prj[selectedSectionId])[blockName]) : doNothing()'
    [bphChange]='(prj[selectedSectionId])[blockName]'
    [confChange]='confChange'
    [formId]="formID"
    [globalConf]='conf'
    [globalPrj]='prj'
    [isValidHtml]='isValidHtml'
    [isValidTable]='isValidTable'
    [selectedLanguage]='lang'
    [selectedSectionId]='selectedSectionId'
    [blockIndex]='blockIndex'
    style='position: static;'>
  </fb-budget-t4>
</ng-template>

<ng-template #BudgetType5 let-blockName="templateContext.blockName" let-bphChange="templateContext.bphChange" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-selectedSectionId="templateContext.selectedSectionId">
  <fb-budget-t5
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction($event,confChange,(prj[selectedSectionId])[blockName]) : doNothing()'
    [bphChange]='(prj[selectedSectionId])[blockName]'
    [confChange]='confChange'
    [formId]="formID"
    [globalConf]='conf'
    [globalPrj]='prj'
    [isValidHtml]='isValidHtml'
    [isValidTable]='isValidTable'
    [selectedLanguage]='lang'
    [selectedSectionId]='selectedSectionId'
    [blockIndex]='blockIndex'
    style='position: static;'>
  </fb-budget-t5>
</ng-template>

<ng-template #BudgetType6 let-blockName="templateContext.blockName" let-bphChange="templateContext.bphChange" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-selectedSectionId="templateContext.selectedSectionId">
  <fb-budget-t6
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction($event,confChange,(prj[selectedSectionId])[blockName]) : doNothing()'
    [bphChange]='(prj[selectedSectionId])[blockName]'
    [confChange]='confChange'
    [formId]="formID"
    [globalConf]='conf'
    [globalPrj]='prj'
    [isValidHtml]='isValidHtml'
    [isValidTable]='isValidTable'
    [selectedLanguage]='lang'
    [selectedSectionId]='selectedSectionId'
    [blockIndex]='blockIndex'
    style='position: static;'>
  </fb-budget-t6>
</ng-template>

<ng-template #MatSlider let-blockName="blockName" let-bphChange="bphChange" let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly" let-selectedSectionId="selectedSectionId">
  <fb-mat-slider [bphChange]='(prj[selectedSectionId])[blockName]'
                 [confChange]='confChange'
                 [globalConf]='conf'
                 [globalPrj]='prj'
                 [isValidHtml]='isValidHtml'
                 [isValidTableReadonly]='isValidTableReadonly'
                 [isValidTable]='isValidTable'
                 [selectedLanguage]='lang'
                 style='position: static;'>
  </fb-mat-slider>
</ng-template>
<ng-template #richtext let-blockName="blockName" let-bphChange="bphChange" let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly" let-selectedSectionId="selectedSectionId">
  <fb-rich-text-quill [bphChange]='(prj[selectedSectionId])[blockName]'
                      [confChange]='confChange'
                      [globalConf]='conf'
                      [globalPrj]='prj'
                      [isValidHtml]='isValidHtml'
                      [isValidTableReadonly]='isValidTableReadonly'
                      [isValidTable]='isValidTable'
                      [selectedLanguage]='lang'
                      [selectedSectionId]='selectedSectionId'
                      style='position: static;'>
  </fb-rich-text-quill>
</ng-template>
<ng-template #Signature let-blockName="blockName" let-bphChange="bphChange" let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly" let-selectedSectionId="selectedSectionId">
  <fb-signature [bphChange]='(prj[selectedSectionId])[blockName]'
                      [confChange]='confChange'
                      [globalConf]='conf'
                      [globalPrj]='prj'
                      [isValidHtml]='isValidHtml'
                      [isValidTableReadonly]='isValidTableReadonly'
                      [isValidTable]='isValidTable'
                      [selectedLanguage]='lang'
                      [selectedSectionId]='selectedSectionId'
                      style='position: static;'>
  </fb-signature>
</ng-template>
<ng-template #ReviewCriteriaGrid let-blockName="blockName" let-bphChange="bphChange" let-confChange="confChange"
             let-isValidHtml="isValidHtml" let-isValidTable="isValidTable"
             let-isValidTableReadonly="isValidTableReadonly" let-selectedSectionId="selectedSectionId">
  <fb-review-criteria-grid
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction($event,confChange,(prj[selectedSectionId])[blockName]) : doNothing()'
    [globalPrj]='prj'
    [selectedSectionId]="selectedSectionId"
    [confChange]='confChange'
    [bphChange]='(prj[selectedSectionId])[blockName]'
    [globalConf]='conf'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [offlineModeEnabled]='offline'
    [selectedLanguage]='lang'
    [globalReadOnlyMode]="globalReadOnlyMode"
    style='position: static;'>
  </fb-review-criteria-grid>
</ng-template>
<ng-template #TeamTable let-blockName="templateContext.blockName" let-bphChange="templateContext.bphChange" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-selectedSectionId="templateContext.selectedSectionId">
  <fb-team-table [bphChange]='(prj[selectedSectionId])[blockName]'
                 [confChange]='confChange'
                 [showSaveNavBar]='showSaveNavBar'
                 [globalConf]='conf'
                 [globalPrj]='prj'
                 [isValidHtml]='isValidHtml'
                 [isValidTableReadonly]='isValidTableReadonly'
                 [isValidTable]='isValidTable'
                 [offlineModeEnabled]='offline'
                 [selectedLanguage]='lang'
                 [selectedSectionId]='selectedSectionId'
                 [blockIndex]='blockIndex'
                 style='position: static;'>
  </fb-team-table>
</ng-template>
<ng-template #BudgetExpenditureItems let-blockName="templateContext.blockName" let-bphChange="templateContext.bphChange" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-selectedSectionId="templateContext.selectedSectionId">
  <fb-budget-expenditure-items [bphChange]='(prj[selectedSectionId])[blockName]'
                               [confChange]='confChange'
                               [formId]="formID"
                               [globalConf]='conf'
                               [globalPrj]='prj'
                               [isValidHtml]='isValidHtml'
                               [isValidTableReadonly]='isValidTableReadonly'
                               [isValidTable]='isValidTable'
                               [selectedLanguage]='lang'
                               [selectedSectionId]='selectedSectionId'
                               [blockIndex]='blockIndex'
                               style='position: static;'>
  </fb-budget-expenditure-items>
</ng-template>

<ng-template #FundedTeams let-blockName="templateContext.blockName" let-bphChange="templateContext.bphChange" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-selectedSectionId="templateContext.selectedSectionId">
  <fb-funded-teams [bphChange]='(prj[selectedSectionId])[blockName]'
                       [confChange]='confChange'
                       [showSaveNavBar]='showSaveNavBar'
                       [globalConf]='conf'
                       [globalPrj]='prj'
                       [isValidHtml]='isValidHtml'
                       [isValidTableReadonly]='isValidTableReadonly'
                       [isValidTable]='isValidTable'
                       [selectedLanguage]='lang'
                       [offlineModeEnabled]="offline"
                       [selectedSectionId]='selectedSectionId'
  ></fb-funded-teams>
</ng-template>
<ng-template #Accordion let-blockName="templateContext.blockName" let-bphChange="templateContext.bphChange" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-selectedSectionId="templateContext.selectedSectionId">
  <fb-accordion [bphChange]='(prj[selectedSectionId])[blockName]'
                [sections]="sections"
                [confChange]='confChange'
                [globalConf]='conf'
                [globalPrj]='prj'
                [isFormbuilderModeEnabled]="isFormbuilderModeEnabled"
                [isValidHtml]='isValidHtml'
                [isValidTableReadonly]='isValidTableReadonly'
                [isValidTable]='isValidTable'
                [offlineModeEnabled]="offline"
                [selectedLanguage]='lang'
                [blockIndex]='blockIndex'
                [selectedSectionId]='selectedSectionId'
                style='position: static;'>
  </fb-accordion>
</ng-template>
<ng-template #SimplifiedTable let-blockName="templateContext.blockName" let-bphChange="templateContext.bphChange" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-selectedSectionId="templateContext.selectedSectionId">
  <fb-simple-table [bphChange]='(prj[selectedSectionId])[blockName]'
                [sections]="sections"
                [confChange]='confChange'
                [globalConf]='conf'
                [globalPrj]='prj'
                [isFormbuilderModeEnabled]="isFormbuilderModeEnabled"
                [isValidHtml]='isValidHtml'
                [isValidTableReadonly]='isValidTableReadonly'
                [isValidTable]='isValidTable'
                [offlineModeEnabled]="offline"
                [selectedLanguage]='lang'
                [blockIndex]='blockIndex'
                [selectedSectionId]='selectedSectionId'
                style='position: static;'>
  </fb-simple-table>
</ng-template>

<ng-template #sectionTemplate let-blocks="blocks" let-sectionID="sectionID">
  <div *ngIf="isSelectedSection(sectionID)">
    <div class="ui ">
      <ng-container *ngFor="let block of blocks;let blockIndex=index;">
        <div class="here" [style.padding-bottom.em]="(prj[sectionID][block.templateContext.confChange.name] && !prj[sectionID][block.templateContext.confChange.name].hide && (prj[sectionID][block.templateContext.confChange.name].globalShowDetail !== false) && block.templateContext.confChange.doubleSpaceSeparation) ? 1.34375 : 0">
          <ng-container [ngSwitch]="block.templateName">
            <ng-container *ngSwitchCase="'statictext'">
              <ng-container *ngTemplateOutlet="statictext; context: block.templateContext"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'textarea'">
              <ng-container *ngTemplateOutlet="textarea; context: block.templateContext"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'Radiobutton'">
              <ng-container *ngTemplateOutlet="Radiobutton; context: block.templateContext"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'select'">
              <ng-container *ngTemplateOutlet="select; context: block.templateContext"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'Container'">
              <ng-container *ngTemplateOutlet="Container; context: {templateContext : block.templateContext, blockIndex : (blockIndex + 1)}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'Table'">
              <ng-container *ngTemplateOutlet="Table; context: {templateContext : block.templateContext, blockIndex : (blockIndex + 1)}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'BudgetTable'">
              <ng-container *ngTemplateOutlet="BudgetTable; context: {templateContext : block.templateContext, blockIndex : (blockIndex + 1)}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'SubmitButton'">
              <ng-container *ngTemplateOutlet="SubmitButton; context: block.templateContext"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'upload'">
              <ng-container *ngTemplateOutlet="upload; context: {templateContext : block.templateContext, blockIndex : (blockIndex + 1)}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'text'">
              <ng-container *ngTemplateOutlet="text; context: block.templateContext"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'Checkbox'">
              <ng-container *ngTemplateOutlet="Checkbox; context: block.templateContext"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'datepicker'">
              <ng-container *ngTemplateOutlet="datepicker; context: block.templateContext"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'AutoComplete'">
              <ng-container *ngTemplateOutlet="AutoComplete; context: block.templateContext"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'dynamicMatrix'">
              <ng-container *ngTemplateOutlet="dynamicMatrix; context: block.templateContext"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'MultiYearTable'">
              <ng-container *ngTemplateOutlet="MultiYearTable; context: {templateContext : block.templateContext, blockIndex : (blockIndex + 1)}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'SchedulerTable'">
              <ng-container *ngTemplateOutlet="SchedulerTable; context: block.templateContext"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'BudgetType1'">
              <ng-container *ngTemplateOutlet="BudgetType1; context: {templateContext : block.templateContext, blockIndex : (blockIndex + 1)}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'BudgetType2'">
              <ng-container *ngTemplateOutlet="BudgetType2; context: {templateContext : block.templateContext, blockIndex : (blockIndex + 1)}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'BudgetType3'">
              <ng-container *ngTemplateOutlet="BudgetType3; context: {templateContext : block.templateContext, blockIndex : (blockIndex + 1)}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'BudgetType4'">
              <ng-container *ngTemplateOutlet="BudgetType4; context: {templateContext : block.templateContext, blockIndex : (blockIndex + 1)}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'BudgetType5'">
              <ng-container *ngTemplateOutlet="BudgetType5; context: {templateContext : block.templateContext, blockIndex : (blockIndex + 1)}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'BudgetType6'">
              <ng-container *ngTemplateOutlet="BudgetType6; context: {templateContext : block.templateContext, blockIndex : (blockIndex + 1)}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'MatSlider'">
              <ng-container *ngTemplateOutlet="MatSlider; context: block.templateContext"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'button'">
              <ng-container *ngTemplateOutlet="button; context: block.templateContext"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'ReviewCriteriaGrid'">
              <ng-container *ngTemplateOutlet="ReviewCriteriaGrid; context: block.templateContext"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'richtext'">
              <ng-container *ngTemplateOutlet="richtext; context: block.templateContext"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'Signature'">
              <ng-container *ngTemplateOutlet="Signature; context: block.templateContext"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'TeamTable'">
              <ng-container *ngTemplateOutlet="TeamTable; context: {templateContext : block.templateContext, blockIndex : (blockIndex + 1)}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'BudgetExpenditureItems'">
              <ng-container *ngTemplateOutlet="BudgetExpenditureItems; context: {templateContext : block.templateContext, blockIndex : (blockIndex + 1)}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'FundedTeams'">
              <ng-container *ngTemplateOutlet="FundedTeams; context: {templateContext : block.templateContext, blockIndex : (blockIndex + 1)}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'Accordion'">
              <ng-container *ngTemplateOutlet="Accordion; context: {templateContext : block.templateContext, blockIndex : (blockIndex + 1)}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'SimplifiedTable'">
              <ng-container *ngTemplateOutlet="SimplifiedTable; context: {templateContext : block.templateContext, blockIndex : (blockIndex + 1)}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault></ng-container>

          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>


