import {Component, Input, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'ecase-attach-existing-document-dialog',
  templateUrl: './attach-existing-document-dialog.component.html',
  styleUrls: ['./attach-existing-document-dialog.component.scss']
})
export class AttachExistingDocumentDialogComponent implements OnInit {

  @Input() documents: any[];
  @Input() lang;
  selectedDocument: number;
  selectedDocuments: number[];

  constructor(public dialogRef: MatDialogRef<AttachExistingDocumentDialogComponent>) {
  }

  ngOnInit(): void {
    if (!this.documents) {
      this.documents = [];
    }
    this.selectedDocuments = [];
    this.documents = this.documents.map((e) => {
      e.value = Number(e.value);
      return e;
    }).sort((a, b) => {
      const x = ((a.label[this.lang]) ? (a.label[this.lang]) : '').toLowerCase();
      const y = ((b.label[this.lang]) ? (b.label[this.lang]) : '').toLowerCase();
      return x.localeCompare(y);
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    if (this.selectedDocument) {
      this.selectedDocuments.push(this.selectedDocument);
      this.dialogRef.close(this.selectedDocuments);
    }
  }

}
