<div *ngIf="isValidHtml && !bphChange.hide">
  <div *ngIf="bphChange.globalShowDetail">

    <div *ngIf="!showAdditionalConfirmation">
      <button (click)="saveAndSubmit()"
              [style.background-color]="(bphChange.isDisabled || confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true') ? '' : confChange.color"
              [disabled]="clickedOneTime || confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'"
              color="accent"
              mat-raised-button>
        {{confChange.label[selectedLanguage]}}
        <mat-icon class="ml-5" *ngIf="clickedOneTime"><mat-spinner diameter="20" color="accent"></mat-spinner></mat-icon>
      </button>
    </div>

    <div *ngIf="showAdditionalConfirmation">
      <button (click)="submitForm(true)"
              [style.background-color]="(bphChange.isDisabled || confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true') ? '' : confChange.color"
              [disabled]="clickedOneTime || confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'"
              color="accent"
              mat-raised-button>
        {{confChange.label[selectedLanguage]}}
        <mat-icon class="ml-5" *ngIf="clickedOneTime"><mat-spinner diameter="20"></mat-spinner></mat-icon>
      </button>
    </div>
  </div>
</div>
<td *ngIf="isValidTable && !row.isTotal">
  <button (click)="submitForm(false)" [disabled]="clickedOneTime || confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'"
          [style.background-color]="(bphChange.isDisabled || confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true') ? '' : confChange.color"
          color="accent"
          mat-raised-button>
    {{confChange.label[selectedLanguage]}}
    <mat-icon class="ml-5" *ngIf="clickedOneTime"><mat-spinner diameter="20"></mat-spinner></mat-icon>
  </button>
</td>


<td *ngIf="isValidTableReadonly && !row.isTotal && !bphChange.hideCell && !confChange.hideCell"
    [attr.rowspan]="bphChange.rowspan"
    style="text-align: center; margin-top: 15px;">
  <button (click)="submitForm(false)" [disabled]="clickedOneTime || confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'" color="accent"
          [style.background-color]="(bphChange.isDisabled || confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true') ? '' : confChange.buttonColor"
          mat-raised-button>
    {{confChange.label[selectedLanguage]}}
    <mat-icon class="ml-5" *ngIf="clickedOneTime"><mat-spinner diameter="20"></mat-spinner></mat-icon>
  </button>
</td>
