<div fxLayout="column">
  <div>
    <button (click)="downloadAll()" *ngIf="dataSource.data.length > 1"
            [attr.aria-label]="'ecase.common.downloadall' | translate"
            class="eCase-dialog-positive-action-button"
            id="ecase_document_listing_download_all_btn"
            mat-button
            style="float: right;margin-right: 0 !important;">{{ 'ecase.common.downloadall' | translate }}
    </button>
    <button (click)="addNewDocument()" *ngIf="showAddNewDocumentButton"
            [attr.aria-label]="'ecase.common.allnewdocument' | translate"
            class="eCase-dialog-positive-action-button"
            id="ecase_document_listing_add_new_document_btn"
            mat-button
            [ngClass]="dataSource.data.length <= 1 ? 'zero-margin-right': ''"
            style="float: right;">{{ 'ecase.common.addDocument' | translate }}
    </button>
  </div>
  <div class="responsive-table-style" style="overflow: auto">
    <table (matSortChange)="sortColumn($event)" [@animateStagger]="{value:'50'}" [dataSource]="dataSource"
           id="document_listing_table"
           mat-table
           matSort
           matSortActive="{{sortActive}}"
           matSortDirection="{{sortDirection}}">

      <ng-container matColumnDef="resetFilter">
        <th *matHeaderCellDef mat-header-cell style="max-width: 50px"></th>
        <td *matCellDef="let row" [title]="'ecase.common.resetFilter' | translate" mat-cell
            style="max-width: 5px;width: 5px"></td>
      </ng-container>

      <ng-container matColumnDef="fileName">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <u>{{ 'ecase.common.fileName' | translate }}
          </u>
        </th>
        <td *matCellDef="let row" [title]="'ecase.common.fileName' | translate"
            mat-cell>
          <span *ngIf="isPreviewBeforeDownload" style="padding: 5px"><a (click)="preview(row)" [title]="(row.edmRepositoryId === -4 ? row.cpmFormTypeLabel[lang] + ' ' + row.fileName : row.fileName)"
                                        class="a-truncate"> {{(row.edmRepositoryId === -4 ? (row.cpmFormTypeLabel[lang] + ' ' + (row.fileName | translate)) : row.fileName) | translate}}</a></span>
          <span *ngIf="!isPreviewBeforeDownload" style="padding: 5px"><a (click)="download(row)" [title]="(row.edmRepositoryId === -4 ? row.cpmFormTypeLabel[lang] + ' ' + row.fileName : row.fileName)"
                                        class="a-truncate"> {{(row.edmRepositoryId === -4 ? (row.cpmFormTypeLabel[lang] + ' ' + (row.fileName | translate)) : row.fileName) | translate}}</a></span>
        </td>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <u>{{ 'ecase.common.createdBy' | translate }}</u>
        </th>
        <td *matCellDef="let row" [title]="'ecase.common.createdBy' | translate"
            mat-cell>
          <span style="padding: 5px">{{row.createdBy}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="formattedCreationDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <u>{{ 'ecase.common.dateCreated' | translate }}
            <br>({{'ecase.defaultDatetime' | translate }})</u></th>
        <td *matCellDef="let row" [title]="'ecase.common.dateCreated' | translate"
            mat-cell><span style="padding: 5px">
        {{row.formattedCreationDate | eCaseDateFormatter:syntoDate }}
      </span></td>
      </ng-container>

      <ng-container matColumnDef="formattedLastUpdatedDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <u>{{ 'ecase.common.lastUpdatedDate' | translate }}
            <br>({{'ecase.defaultDatetime' | translate }})</u></th>
        <td *matCellDef="let row" [title]="'ecase.common.lastupdatedDate' | translate"
            mat-cell><span style="padding: 5px">
        {{row.formattedLastUpdatedDate | eCaseDateFormatter:syntoDate }}
      </span></td>
      </ng-container>

      <ng-container matColumnDef="formattedSize">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <u>{{ 'ecase.common.size' | translate }}</u></th>
        <td *matCellDef="let row" [title]="'ecase.common.size' | translate"
            mat-cell><span style="padding: 5px">
        {{row.formattedSize}}
      </span></td>
      </ng-container>

      <ng-container matColumnDef="documentTypeLabel">
        <th *matHeaderCellDef mat-header-cell mat-sort-header style="max-width: 200px">
          <u>{{ 'ecase.common.documentType' | translate }}
          </u></th>
        <td *matCellDef="let row" [title]="'ecase.common.documentType' | translate" style="max-width: 200px"
            mat-cell>
          <span style="padding: 5px">{{row.documentTypeLabel[lang]}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="cpmFormTypeId">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <u>{{ 'ecase.common.cpmFormTypeId' | translate }}
          </u></th>
        <td *matCellDef="let row" [title]="'ecase.common.cpmFormTypeId' | translate"
            mat-cell>
          <span style="padding: 5px">{{row.cpmFormTypeLabel[lang]}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="documentCategoryLabel">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <u>{{ 'ecase.common.documentCategoryId' | translate }}
          </u></th>
        <td *matCellDef="let row" [title]="'ecase.common.documentCategoryId' | translate"
            mat-cell>
          <span style="padding: 5px">{{row.documentCategoryLabel[lang]}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="teamMemberRoleLabel">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <u>{{ 'ecase.common.createdByRole' | translate }}
          </u></th>
        <td *matCellDef="let row" [title]="'ecase.common.createdByRole' | translate"
            mat-cell>
          <span style="padding: 5px">{{row.teamMemberRoleLabel[lang]}}</span>
        </td>
      </ng-container>

      <ng-container class="filter_row_first_cell mat-elevation-z4" matColumnDef="header-row-zeroth-group"
                    matRipple>
        <th *matHeaderCellDef class="mat-table_filtered_header_cell" mat-header-cell>
          <span class='nodisplay'>.</span>
          <input *ngIf="showImage" alt="clear" class="row_filter_icon"
                 disabled matRipple
                 src="../../../../assets/images/etc/icons8-conversion.svg" type="image">
          <input (click)="resetFilters(true)" *ngIf="!showImage" alt="clear"
                 class="row_filter_icon" src="../../../../assets/images/etc/icons8-clear-filters.svg"
                 type="image">
        </th>
      </ng-container>

      <ng-container matColumnDef="header-row-first-group">
        <th *matHeaderCellDef class="mat-table_filtered_header_cell" mat-header-cell>
          <div class="form-item">
            <div class="select-wrap">
              <select (change)="applyFilter()" [(ngModel)]="filterObjectForSelectFields.cpmFormTypeId"
                      [attr.aria-label]="'ecase.common.cpmFormTypeId' | translate"
                      class="select_filter filter-items__element--select"
                      id="ecase_document_listing_cpmFormTypeId">
                <option selected value="">
                  {{'ecase.alltasks.select' | translate}}
                </option>
                <option *ngFor="let formType of formTypes" [value]="formType.id">
                  {{formType.value[lang]}}
                </option>
              </select>
            </div>
          </div>
        </th>
      </ng-container>

      <ng-container matColumnDef="header-row-two-group">
        <th *matHeaderCellDef class="mat-table_filtered_header_cell" mat-header-cell>

          <input (keyup)="applyFilter()" [(ngModel)]="filterObject.fileName"
                 [attr.aria-label]="'ecase.common.docTitle' | translate"
                 [placeholder]="'ecase.admindashboard.search' | translate"
                 class="searchFields"
                 id="document_listing_docTitle"
                 type="text">
        </th>
      </ng-container>


      <ng-container matColumnDef="header-row-third-group">
        <th *matHeaderCellDef class="mat-table_filtered_header_cell" mat-header-cell>
          <div class="form-item">
            <div class="select-wrap">
              <select (change)="applyFilter()" [(ngModel)]="filterObjectForSelectFields.documentCategoryId"
                      [attr.aria-label]="'ecase.common.documentCategoryId' | translate"
                      class="select_filter filter-items__element--select"
                      id="ecase_document_listing_documentCategoryId">
                <option selected value="">
                  {{'ecase.alltasks.select' | translate}}
                </option>
                <option *ngFor="let documentCat of documentCategories" [value]="documentCat.id">
                  {{documentCat.value[lang]}}
                </option>
              </select>
            </div>
          </div>
        </th>
      </ng-container>


      <ng-container matColumnDef="header-row-fourth-group">
        <th *matHeaderCellDef class="mat-table_filtered_header_cell" mat-header-cell>

          <div class="form-item">
            <div class="select-wrap">
              <select (change)="applyFilter()" [(ngModel)]="filterObjectForSelectFields.documentTypeId"
                      [attr.aria-label]="'ecase.common.documentType' | translate"
                      class="select_filter filter-items__element--select"
                      id="ecase_document_listing_documentType">
                <option selected value="">
                  {{'ecase.alltasks.select' | translate}}
                </option>
                <option *ngFor="let documentType of documentTypes" [value]="documentType.id">
                  {{documentType.value[lang]}}
                </option>
              </select>
            </div>
          </div>

        </th>

      </ng-container>


      <ng-container matColumnDef="header-row-fifth-group">
        <th *matHeaderCellDef class="mat-table_filtered_header_cell" mat-header-cell>
          <input (keyup)="applyFilter()" [(ngModel)]="filterObject.formattedSize"
                 [attr.aria-label]="'ecase.common.size' | translate"
                 [placeholder]="'ecase.admindashboard.search' | translate"
                 class="searchFields"
                 id="document_listing_size"
                 type="text">
        </th>
      </ng-container>

      <ng-container matColumnDef="header-row-sixth-group">
        <th *matHeaderCellDef class="mat-table_filtered_header_cell" mat-header-cell>

          <div class="form-item">
            <div class="select-wrap">
              <select (change)="applyFilter()" [(ngModel)]="filterObjectForSelectFields.teamMemberRoleId"
                      [attr.aria-label]="'ecase.common.createdByRole' | translate"
                      class="select_filter filter-items__element--select"
                      id="ecase_document_listing_createdByRole">
                <option selected value="">
                  {{'ecase.alltasks.select' | translate}}
                </option>
                <option *ngFor="let role of roles" [value]="role.id">
                  {{role.value[lang]}}
                </option>
              </select>
            </div>
          </div>
        </th>
      </ng-container>

      <ng-container matColumnDef="header-row-seventh-group">
        <th *matHeaderCellDef class="mat-table_filtered_header_cell" mat-header-cell>
          <input (keyup)="applyFilter()" [(ngModel)]="filterObject.formattedCreationDate"
                 [attr.aria-label]="'ecase.common.dateCreated' | translate"
                 [placeholder]="'ecase.admindashboard.search' | translate"
                 class="searchFields"
                 id="document_listing_dateCreated"
                 type="text">
        </th>
      </ng-container>

      <ng-container matColumnDef="header-row-eighth-group">
        <th *matHeaderCellDef class="mat-table_filtered_header_cell" mat-header-cell>

          <input (keyup)="applyFilter()" [(ngModel)]="filterObject.formattedLastUpdatedDate"
                 [attr.aria-label]="'ecase.common.lastUpdatedDate' | translate"
                 [placeholder]="'ecase.admindashboard.search' | translate"
                 class="searchFields"
                 id="ecase_document_listing_lastUpdatedDate"
                 type="text">
        </th>
      </ng-container>

      <ng-container matColumnDef="header-row-ninth-group">
        <th *matHeaderCellDef class="mat-table_filtered_header_cell" mat-header-cell>

          <input (keyup)="applyFilter()" [(ngModel)]="filterObject.createdBy"
                 [attr.aria-label]="'ecase.common.createdBy' | translate"
                 [placeholder]="'ecase.admindashboard.search' | translate"
                 class="searchFields"
                 id="ecase_document_listing_createdBy"
                 type="text">
        </th>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matHeaderRowDef="filterColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>

    <mat-paginator *ngIf="dataSource.data.length> 0"
                   [length]=dataSource.data.length
                   [pageSizeOptions]="pageSizeOptions"
                   [pageSize]="pageSize"
                   showFirstLastButtons></mat-paginator>
  </div>
</div>
