<div *ngIf="isValidHtml">
  <div *ngIf="bphChange.globalShowDetail">
  <div class="ui grid field">
  <span class="three wide column righttext">
    {{confChange.label[lang]}}
  </span>
    <span class="eleven wide column field" [attr.aria-label]="confChange.label[selectedLanguage]">
    {{getCalculatedOutput(confChange.content,lang, bphChange)}}
  </span>
  </div>
  </div>
</div>

<td *ngIf="isValidTable&& !row.isTotal ">
  <div>
    <span>{{getCalculatedOutput(confChange.content,lang,bphChange)}}</span>
  </div>
</td>

<td *ngIf="isValidTableReadonly  && !bphChange.hideCell && !confChange.hideCell  && !row.isTotal " [attr.rowspan]="bphChange.rowspan">
  <div>
    <span>{{getCalculatedOutput(confChange.content,lang, bphChange)}}</span>
  </div>
</td>
