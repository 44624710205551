import {NgModule} from '@angular/core';
import {FormModule} from "./form/form.module";


@NgModule({
  declarations: [],
  imports: [FormModule],
  exports: [FormModule]
})
export class FormCommonModule { }
