import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {ECaseShortcutsComponent} from './shortcuts.component';
import {SharedModule} from 'synto-common';

@NgModule({
    declarations: [
        ECaseShortcutsComponent
    ],
    imports     : [
        SharedModule,
        RouterModule
    ],
    exports     : [
        ECaseShortcutsComponent
    ]
})
export class ECaseShortcutsModule
{
}
