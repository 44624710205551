<div *ngIf=" bphChange.globalShowDetail && isValidHtml && !bphChange.hide" [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'" [style.padding-bottom] = "!bphChange.error[selectedLanguage] ? '1.34375em' : '10px'">
  <div class="ui grid field">
        <div class="formLabel five wide column righttext">
          <ng-container *ngIf="confChange.condensedLabel && confChange.condensedLabel[selectedLanguage]">
            <span style="font-weight: bolder" [innerHTML]="confChange.condensedLabel[selectedLanguage]"></span>
            <br>
          </ng-container>
          <span *ngIf="confChange.isRequired" style="color: red">* </span>
          <span *ngIf="ifConditionallyRequired" style="color: red">* </span>

          <span [innerHTML]="confChange.label[selectedLanguage]"></span>
          <span *ngIf="confChange.hasTooltip" style="padding-top: 10px !important;">

               <mat-icon matTooltip='{{confChange.tooltipText[selectedLanguage]}}' matTooltipClass="tooltip" [matTooltipPosition]="confChange.toolTipPosition ? 'confChange.toolTipPosition' : 'above'">
              help_outline
               </mat-icon>
          </span>
          <span *ngIf="confChange.hasDialog" style="padding-top: 10px;">
              <button  mat-icon-button (click)="openDialog()"  style="margin-left: -10px;margin-bottom: -10px;">
             <mat-icon style="cursor: pointer; vertical-align: middle !important; padding-top: -2px !important;">help_outline</mat-icon></button>
          </span>
        </div>
        <div class="nine wide column field" style="margin-top: 5px" >
          <div (click)="parentDivOnClick(); $event.stopPropagation()" *ngIf="!(bphChange.value.length > 0) || confChange.isMultipleFileUploadAllowed"
               [ngClass]="((confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === true || (confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === 'true') ? 'disabled-file-upload' : 'custom-file-upload'"
               [style.width.px]="selectedLanguage=== 'en' ? 125 : 201"
               style="padding: 0px !important;margin-bottom: 15px">
            <table (click)="isTableClicked($event);$event.stopPropagation();$event.stopImmediatePropagation();" style="width: 100%"  [style.background]="(focus === true || ((confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === true || (confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === 'true') ) ? '#C7C7C7' : '#FFFFFF'">
              <tr  >
                <td
                     [style.background]="focus === true ? '#C7C7C7' : '#FFFFFF'"
                  [style.background]="((confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === true || (confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === 'true') ? 'rgba(0,0,0,0)' : '#FFFFFF'"
                  style="border: 0;">
                  <button (focusin)="focusInFunction()" (focusout)="focusOutFunction()"
                          (change)="uploadDone($event,bphChange,confChange)" mat-icon-button ><mat-icon
                    (change)="uploadDone($event,bphChange,confChange)" >cloud_upload</mat-icon></button>

                </td>
                <td (click)="$event.stopPropagation();$event.stopImmediatePropagation();"  [style.background]="focus === true ? '#C7C7C7' : '#FFFFFF'"
                  [style.background]="((confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === true || (confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === 'true') ? 'rgba(0,0,0,0)' : '#FFFFFF'"
                  style="border: 0;">
                  <label (click)="$event.stopPropagation();$event.stopImmediatePropagation();" *ngIf="!confChange.isMultipleFileUploadAllowed"
                         [for]="confChange.name + '_' + rowNumber"
                         class="label">{{getTranslatedLabel("ecase.formbuilder.UploaOnedLabel")}}</label>
                  <label (click)="$event.stopPropagation();$event.stopImmediatePropagation();" *ngIf="confChange.isMultipleFileUploadAllowed"
                         [for]="confChange.name + '_' + rowNumber"
                         class="label">{{getTranslatedLabel("ecase.formbuilder.UploadLabel")}}</label>
                  <div *ngIf="confChange.isUploadExistingDocument" (click)="openExistingDocumentDialog()">
                    <input (click)="$event.preventDefault()" (change)="uploadDone($event,bphChange,confChange)"  [accept]="confChange.typesAdmin"
                           [attr.aria-label]="confChange.label[selectedLanguage]"
                           [disabled]="(confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === 'true'"
                           [id]="confChange.name"
                           [multiple]="confChange.isMultipleFileUploadAllowed"
                           [name]="confChange.name"
                           [ngClass]="bphChange.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''"
                           type="file" />
                  </div>
                  <div *ngIf="!confChange.isUploadExistingDocument">
                    <input (click)="test($event)" (change)="uploadDone($event,bphChange,confChange)"  [accept]="confChange.typesAdmin"
                           [attr.aria-label]="confChange.label[selectedLanguage]"
                           [disabled]="(confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === 'true'"
                           [id]="confChange.name + '_' + rowNumber"
                           [multiple]="confChange.isMultipleFileUploadAllowed"
                           [name]="confChange.name"
                           [ngClass]="bphChange.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''"
                           type="file" />
                  </div>

                </td>
              </tr>
            </table>
          </div>

          <div *ngIf="!tvalue"  >
            <!--<div *ngIf="isMultipleFilesStored">-->
            <div *ngIf="bphChange.value.length>1" style="margin-top: -12px !important;" >
              <div *ngFor="let file of bphChange.value;let i = index">
                <!--<a target="_blank" href="/api/viewAttachedRepositoryDocument/{{id}}">{{bphChange.value.fileName[i]}}</a>-->
                <a (click)="checkIsSaved()" href="/api/viewAttachedRepositoryDocument/{{bphChange.value[i].id}}"
                   target="_blank"  >{{bphChange.value[i].fileName}}</a>
                <button  (click)="deleteFile(bphChange.value[i],i)"
                        [disabled]="((confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === true || (confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === 'true')"
                        mat-icon-button>
                  <mat-icon   aria-label="Delete">delete</mat-icon>
                </button>
              </div>
            </div>
            <div *ngIf="bphChange.value.length === 1"  >
              <a *ngIf="!isMultipleFilesStored" (click)="checkIsSaved()" href="/api/viewAttachedRepositoryDocument/{{bphChange.value[0].id}}"
                 target="_blank"  >{{bphChange.value[0].fileName}}</a>
              <button   (click)="deleteFile(bphChange.value[0],-999)" *ngIf="!isMultipleFilesStored"
                      [disabled]="((confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === true || (confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === 'true')"
                      mat-icon-button>
                <mat-icon
                           aria-label="Delete">delete</mat-icon  >
              </button>
            </div>
          </div>
          <div [style.margin-top]="bphChange?.error[selectedLanguage] ? '5px' : '0'">
            <span *ngIf="bphChange?.error[selectedLanguage]" class="tableFormFieldError">{{bphChange.error[selectedLanguage]}}</span>
          </div>
        </div>


        <div *ngIf="confChange.staticDocumentType === 2 && !bphChange.hideDocumentTypeSelector">
          <label for="documentType"><span style="color: red">* </span> {{getTranslatedLabel('ecase.formbuilder.staticDocumentType')}} </label>
          <mat-form-field appearance="outline" style="width:100%">
            <mat-select [(ngModel)]="bphChange.documentType" id="documentType">
              <mat-option *ngFor="let type of confChange.documentTypes" [value]="type.id">{{(type.value && type.value[selectedLanguage]) ? type.value[selectedLanguage] : type.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

<!--[style.padding-bottom] = "!bphChange.hide ? '1.34375em' : '0'"-->


<td *ngIf="isValidTable && !row.isTotal " [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
  style="border:0;width: 10%;padding-right:10px !important;padding-left:10px">
  <div class="field {{bphChange.error_class}} dimmable" *ngIf="!bphChange.hide">
    <div *ngIf="!(bphChange.value.length > 0) || confChange.isMultipleFileUploadAllowed" [style.background]="focus === true ? '#C7C7C7' : '#FFFFFF'">
      <div (click)="parentDivOnClick();$event.stopPropagation()" [ngClass]="((confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === true || (confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === 'true') ? 'disabled-file-upload' : 'custom-file-upload'"
           style="min-width: 125px" [style.background]="focus === true ? '#C7C7C7' : '#FFFFFF'">
        <table (click)="isTableClicked($event)"   [style.background]="focus === true ? '#C7C7C7' : '#FFFFFF'"style="width: 100%">
          <tr>
            <td   [style.background]="focus === true ? '#C7C7C7' : '#FFFFFF'"
                  [style.background]="((confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === true || (confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === 'true') ? 'rgba(0,0,0,0)' : '#FFFFFF'"
                style="border: 0;">
              <button (focusin)="focusInFunction()" (focusout)="focusOutFunction()"
                      (change)="uploadDone($event,bphChange,confChange)" mat-icon-button ><mat-icon
                (change)="uploadDone($event,bphChange,confChange)" >cloud_upload</mat-icon></button>

            </td>
            <td
              [style.background]="focus === true ? '#C7C7C7' : '#FFFFFF'"
               [style.background]="((confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === true || (confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === 'true') ? 'rgba(0,0,0,0)' : '#FFFFFF'"
                style="border: 0;">
              <label (click)="$event.stopPropagation()" *ngIf="!confChange.isMultipleFileUploadAllowed"
                     [for]="confChange.name + randomNumber"
                     class="label">{{getTranslatedLabel("ecase.formbuilder.UploaOnedLabel")}}</label>

              <label (click)="$event.stopPropagation()" *ngIf="confChange.isMultipleFileUploadAllowed" [for]="confChange.name+ randomNumber"
                     class="label">{{getTranslatedLabel("ecase.formbuilder.UploadLabel")}} </label>
              <input (change)="uploadDone($event,bphChange,confChange)" [accept]="confChange.typesAdmin" [attr.aria-label]="confChange.label[selectedLanguage]"
                     [disabled]="(confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === 'true'"
                     [id]="confChange.name + randomNumber"
                     [multiple]="confChange.isMultipleFileUploadAllowed"
                     [name]="confChange.name + randomNumber"
                     [ngClass]="bphChange.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''" type="file"/>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div *ngIf="confChange.staticDocumentType === 2 && !bphChange.hideDocumentTypeSelector">
      <mat-form-field appearance="outline" style="width:100%">
        <mat-select [(ngModel)]="bphChange.staticDocumentType" id="staticDocumentTypeInTable"
                    placeholder="{{ getTranslatedLabel('ecase.formbuilder.staticDocumentType') }}">
          <mat-option *ngFor="let type of confChange.documentTypes" [value]="type.id">{{(type.value && type.value[selectedLanguage]) ? type.value[selectedLanguage] : type.value}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div *ngIf="!tvalue">

      <div *ngIf="bphChange.value.length>1" style="margin-top: -10px !important;">
        <div *ngFor="let file of bphChange.value;let i = index">
          <!--<a target="_blank" href="/api/viewAttachedRepositoryDocument/{{id}}">{{bphChange.value.fileName[i]}}</a>-->
          <a (click)="checkIsSaved()" href="/api/viewAttachedRepositoryDocument/{{bphChange.value[i].id}}"
             target="_blank">{{bphChange.value[i].fileName}}</a>
          <button (click)="deleteFile(bphChange.value[i],i)" [disabled]="((confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === true || (confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === 'true')"
                  mat-icon-button>
            <mat-icon aria-label="Delete">delete</mat-icon>
          </button>
        </div>
      </div>

      <div *ngIf="bphChange.value.length === 1">
        <a *ngIf="!isMultipleFilesStored"  (click)="checkIsSaved()" href="/api/viewAttachedRepositoryDocument/{{bphChange.value[0].id}}"
           target="_blank">{{bphChange.value[0].fileName}}</a>
        <button (click)="deleteFile(bphChange.value[0],-999)" *ngIf="!isMultipleFilesStored" [disabled]="((confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === true || (confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === 'true')"
                mat-icon-button>
          <mat-icon aria-label="Delete">delete</mat-icon>
        </button>
      </div>
      <!--<div *ngIf="isMultipleFilesStored">-->
      <!--<div *ngFor="let id of bphChange.value.id;let i = index">-->
      <!--<a target="_blank" href="/api/viewAttachedRepositoryDocument/{{id}}">{{bphChange.value.fileName[i]}}</a>-->
      <!--<button mat-icon-button (click)="deleteFile(bphChange.value,i)"-->
      <!--[disabled]="((confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === true || (confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === 'true')">-->
      <!--<mat-icon aria-label="Delete">delete</mat-icon>-->
      <!--</button>-->
      <!--</div>-->
      <!--</div>-->
      <!--<a *ngIf="!isMultipleFilesStored" target="_blank"-->
      <!--href="/api/viewAttachedRepositoryDocument/{{bphChange.value.id}}">{{bphChange.value.fileName}}</a>-->
      <!--<button *ngIf="!isMultipleFilesStored" mat-icon-button (click)="deleteFile(bphChange.value,-999)"-->
      <!--[disabled]="((confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === true || (confChange.isReadOnlyField || bphChange.globalReadOnlyMode) === 'true')">-->
      <!--<mat-icon aria-label="Delete">delete</mat-icon>-->
      <!--</button>-->
    </div>

    <span *ngIf="bphChange?.error[selectedLanguage]" class="formFieldError">{{bphChange.error[selectedLanguage]}}</span>
  </div>
</td>


<td *ngIf="isValidTableReadonly && !row.isTotal && !bphChange.hideCell  && !confChange.hideCell" [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
    [attr.rowspan]="bphChange.rowspan"
    style="border:0;width: 10%;text-align: center;">
  <div *ngIf="!row.isTotal" class="field {{bphChange.error_class}}">
    <div *ngIf="bphChange.value.length > 0 || isvalue ">
      <ng-container *ngFor="let file of bphChange.value;let j = index">
        <div *ngIf="isMultipleFilesStored">
          <a *ngFor="let id of file.id;let i = index" href="/api/viewAttachedRepositoryDocument/{{id}}"
             (click)="checkIsSaved()"
             target="_blank">{{file.fileName[i]}}</a>
        </div>
        <a *ngIf="!isMultipleFilesStored" href="/api/viewAttachedRepositoryDocument/{{file.id}}"
           (click)="checkIsSaved()"
           target="_blank">{{file.fileName}}</a>
      </ng-container>
    </div>
    <label *ngIf="bphChange?.error[selectedLanguage]" class="tableFormFieldError">{{bphChange.error[selectedLanguage]}}</label>
  </div>
</td>
