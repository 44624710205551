<mat-card-title>{{'ecase.common.addDocument'| translate }}</mat-card-title>
<mat-dialog-content>
  <div>
    <mat-label
      for="ecase_common_documentType"><span style="color:red">* </span>{{'ecase.common.documentType'| translate}}</mat-label>
    <mat-form-field appearance="outline">
      <mat-select id="ecase_common_documentType" [(ngModel)]="documentTypeId"
                  [attr.aria-label]="'ecase.common.documentType'| translate">
        <mat-option>{{'ecase.common.pleaseSelect' | translate }}</mat-option>
        <mat-option *ngFor="let documentType of documentTypes" [value]="documentType.id">{{documentType.value[selectedLang]}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="(allowMultipleFileUpload || (!allowMultipleFileUpload && uploadedFiles.length === 0))">
    <label for="fileToBeReplaced"
           class="md-button md-raised md-primary"><span style="color:red">* </span>{{(allowMultipleFileUpload ? 'ecase.common.filestoupload' : 'ecase.common.filetoupload') | translate}}</label>&nbsp;
    <br>
    <input #uploader
           id="fileToBeReplaced"
           [multiple]="allowMultipleFileUpload"
           [attr.aria-label]="(allowMultipleFileUpload ? 'ecase.common.filestoupload' : 'ecase.common.filetoupload') | translate"
           (change)="uploadFiles($event)"
           hidden
           type="file"/>
    <button
      style="margin-top: 5px;margin-bottom: 10px"
      mat-raised-button
      (click)="uploader.click()">
      <mat-icon>cloud_upload</mat-icon>
      <span style="padding-left: 5px">{{(allowMultipleFileUpload ? 'filestoUpload' : 'filetoUpload') | translate}}</span>
    </button>
  </div>
  <div *ngIf="uploadedFiles">
    <ng-container *ngFor="let file of uploadedFiles; let i=index;">
      <span>{{file.name}}</span>
      <button (click)="removeFile(i)" mat-icon-button color="accent" aria-label="Button to delete the uploaded file">
        <mat-icon>delete</mat-icon>
      </button>
      <br>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button class="eCase-dialog-positive-action-button"
          id="ecase_common_addDocument_add"
          style=" margin-right: 15px;" (click)="onAddNewDocument()"
          [disabled]="!documentTypeId || uploadedFiles.length === 0"
          mat-raised-button>{{'add'| translate }}
  </button>
  <button class="eCase-dialog-negative-action-button"
          id="ecase_common_addDocument_cancel"
          (click)="onCancelAddingDocument()"
          mat-raised-button>{{'cancel'| translate }}
  </button>
</mat-dialog-actions>
