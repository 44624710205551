<div *ngIf="isValidHtml && !bphChange.hide && bphChange.globalShowDetail"
     [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'">
  <div class="ui form field {{bphChange.error_class}}">
    <div class="ui grid field">
      <div class="formLabel five wide column righttext">
        <ng-container *ngIf="confChange.condensedLabel && confChange.condensedLabel[selectedLanguage]">
          <span style="font-weight: bolder" [innerHTML]="confChange.condensedLabel[selectedLanguage]"></span>
          <br>
        </ng-container>
        <span *ngIf="confChange.isRequired" style="color: red">* </span>
        <span [innerHTML]="confChange.label[selectedLanguage]"></span>
        <span *ngIf="confChange.hasTooltip" style="padding-top: 10px;">
             <mat-icon matTooltip="{{confChange.tooltipText[selectedLanguage]}}"
                       matTooltipClass="tooltip">help_outline</mat-icon>
          </span>
        <span *ngIf="confChange.hasDialog" style="padding-top: 10px;">
             <mat-icon (click)="openDialog()" style="cursor: pointer;">help_outline</mat-icon>
          </span>
      </div>
      <div class="nine wide column field">
        <form [formGroup]="form">
          <mat-form-field
            [ngClass]="{'disabled-autocomplete' : confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true' || bphChange.disabled, 'ng-invalid mat-form-field-invalid accentBorder' : bphChange.error[selectedLanguage]}"
            appearance="outline">
            <input (input)="onChangeValueEvent($event.target.value)" (keyup)="filterValue($event.target.value)"
                   [attr.aria-label]="confChange.label[selectedLanguage]"
                   [placeholder]="confChange.placeholder && confChange.placeholder[selectedLanguage] ? confChange.placeholder[selectedLanguage] : ''"
                   [readonly]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true' || bphChange.disabled"
                   [formControlName]="'autoCompleteFormControl'"
                   [matAutocomplete]="auto"
                   matInput
                   type="text">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setAutoCompleteValue($event)"
                              [displayWith]="displayFn.bind(this)">
              <ng-container *ngIf="confChange.showColumnsInAutoCompleteOptions &&searchResult.length>0">
                <mat-option *ngFor="let option of removeAlreadyAssignedValues(sortArrayAlphabetically(searchResult))" [value]="option">
                  <ng-container *ngFor="let parameter of  confChange.parameters;let i = index">
                    <span
                      *ngIf="i===0 && option &&option.data ">{{option.data[parameter.text]}}
                    </span>
                    <small *ngIf="i>0 && option && option.data && parameter &&parameter.text">
                      {{parameter.text.charAt(0).toUpperCase() +
                    parameter.text.substr(1).toLowerCase()}} : {{option.data[parameter.text] | translate}}
                    </small>
                    <span *ngIf="confChange.parameters.length-1 !== i">
              </span>
                  </ng-container>
                </mat-option>
              </ng-container>
              <ng-container *ngIf="!confChange.showColumnsInAutoCompleteOptions">
                <mat-option *ngFor="let option of removeAlreadyAssignedValues(sortArrayAlphabetically(searchResult))" [value]="option">
                  {{ option.label[selectedLanguage]}}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
          <ng-container
            *ngIf="confChange.hasOther && (bphChange.value.value === otherValue.value || bphChange.value.value == confChange.otherValue)">
            <label [for]="confChange.blockName + 'other value'"><span *ngIf="confChange.isOtherFieldRequired"
                                                                      style="color: red">* </span>{{confChange.otherPlaceholder[selectedLanguage]}}
            </label>
            <mat-form-field *ngIf="confChange.otherValueFieldType === 'ecase.formbuilder.autocomplete.textarea'"
                            appearance="outline">
              <textarea (input)="changeValueEvent($event)" [attr.aria-label]="confChange.otherPlaceholder[selectedLanguage]"
                        [id]="confChange.blockName + 'other value'"
                        [ngModel]="bphChange.otherValue"
                        matInput
                        rows="5"
                        type="text"
                        value="{{bphChange.otherValue}}"></textarea>
            </mat-form-field>
            <mat-form-field *ngIf="confChange.otherValueFieldType === 'ecase.formbuilder.autocomplete.textfield'"
                            appearance="outline">
              <input (input)="changeValueEvent($event)" [attr.aria-label]="confChange.otherPlaceholder[selectedLanguage]"
                     [id]="confChange.blockName + 'other value'" [ngModel]="bphChange.otherValue"
                     matInput
                     type="text"
                     value="{{bphChange.otherValue}}"/>
            </mat-form-field>
            <p *ngIf="confChange.otherValueFieldMaxLength && confChange.countUnit === 'ecase.common.characters'"
               class="counter-style">{{getTextLength(bphChange.otherValue)}} / {{confChange.otherValueFieldMaxLength}}
              {{'ecase.common.characters' | translate}}</p>
            <p *ngIf="confChange.otherValueFieldMaxLength && confChange.countUnit === 'ecase.common.words'"
               class="counter-style">{{getWordCount(bphChange.otherValue)}} / {{confChange.otherValueFieldMaxLength}}
              {{'ecase.common.words' | translate}}</p>
          </ng-container>
          <div [style.margin-top.px]="(
               form.controls['autoCompleteFormControl'].touched && form.controls['autoCompleteFormControl'].hasError('required') && confChange.isRequired
               || form.controls['autoCompleteFormControl'].touched && form.controls['autoCompleteFormControl'].hasError('incorrect')
               || bphChange.error[selectedLanguage]) ? '-20' : ''" fxLayout="column"
               fxLayoutAlign="start">
                <span
                  *ngIf="form.controls['autoCompleteFormControl'].touched && form.controls['autoCompleteFormControl'].hasError('required') && confChange.isRequired"
                  class="tableFormFieldError">
              {{getTranslationValue('ecase.formBuilder.fbAutoComplete.errorNoValue')}}</span>
            <span
              *ngIf="form.controls['autoCompleteFormControl'].touched && form.controls['autoCompleteFormControl'].hasError('incorrect') && !form.controls['autoCompleteFormControl'].hasError('required')"
              class="tableFormFieldError">
               {{getTranslationValue('ecase.formBuilder.fbAutoComplete.selectValue')}}
            </span>
            <span *ngIf="bphChange.error[selectedLanguage]"
                  class="tableFormFieldError">{{bphChange.error[selectedLanguage]}}</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<td *ngIf="isValidTable && !row.isTotal && bphChange&& !bphChange.hideCell  && !confChange.hideCell"
    [attr.rowspan]="bphChange.rowspan"
    [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
    class="autocompleteClearPadding"
    style="border:0;padding-right:10px !important;padding-left:10px; width:10%">
  <div class="field hide_{{bphChange.show}} {{bphChange.error_class}}">
    <mat-form-field [ngClass]="bphChange.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''"
                    appearance="outline"
                    style="width: 100%">
      <input (keyup)="filterValue($event.target.value)" [attr.aria-label]="confChange.label[selectedLanguage]"
             [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true' || bphChange.disabled"
             [formControl]="fbAutoCompleteFormControl"
             [placeholder]="confChange.placeholder && confChange.placeholder[selectedLanguage] ? confChange.placeholder[selectedLanguage] : ''"
             [matAutocomplete]="auto"
             [maxlength]="confChange.maxlength"
             matInput
             type="text">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setAutoCompleteValue($event)">
        <ng-container *ngIf="confChange.showColumnsInAutoCompleteOptions">
          <mat-option *ngFor="let option of removeAlreadyAssignedValues(sortArrayAlphabetically(searchResult))" [value]="option">
            <ng-container *ngFor="let parameter of  confChange.parameters;let i = index">
                    <span
                      *ngIf="i===0 && option">{{option.data[parameter.text]}}
                    </span>
              <small *ngIf="i>0 && option">{{parameter.text.charAt(0).toUpperCase() +
              parameter.text.substr(1).toLowerCase()}} :
                {{option.data[parameter.text]}}
              </small>
              <span *ngIf="confChange.parameters.length-1 !== i">
              |
              </span>
            </ng-container>
          </mat-option>
        </ng-container>
        <ng-container *ngIf="!confChange.showColumnsInAutoCompleteOptions">
          <mat-option *ngFor="let option of removeAlreadyAssignedValues(sortArrayAlphabetically(searchResult))" [value]="option">
            {{ option.label[selectedLanguage]}}
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
    <ng-container
      *ngIf="confChange.hasOther && (bphChange.value.value === otherValue.value || bphChange.value.value == confChange.otherValue)">
      <label [for]="confChange.blockName + 'other value'"><span *ngIf="confChange.isOtherFieldRequired"
                                                                style="color: red">* </span>{{confChange.otherPlaceholder[selectedLanguage]}}
      </label>
      <mat-form-field *ngIf="confChange.otherValueFieldType === 'ecase.formbuilder.autocomplete.textarea'"
                      appearance="outline">
              <textarea (keyup)="changeValueEvent($event)" [(ngModel)]="bphChange.otherValue"
                        [attr.aria-label]="confChange.otherPlaceholder[selectedLanguage]"
                        [id]="confChange.blockName + 'other value'"
                        matInput
                        rows="5"
                        type="text"></textarea>
      </mat-form-field>
      <mat-form-field *ngIf="confChange.otherValueFieldType === 'ecase.formbuilder.autocomplete.textfield'"
                      appearance="outline">
        <input (keyup)="changeValueEvent($event)" [(ngModel)]="bphChange.otherValue"
               [attr.aria-label]="confChange.otherPlaceholder[selectedLanguage]"
               [id]="confChange.blockName + 'other value'"
               matInput
               type="text"/>
      </mat-form-field>
      <p *ngIf="confChange.otherValueFieldMaxLength && confChange.countUnit === 'ecase.common.characters'"
         class="counter-style">{{getTextLength(bphChange.otherValue)}} / {{confChange.otherValueFieldMaxLength}}
        {{'ecase.common.characters' | translate}}</p>
      <p *ngIf="confChange.otherValueFieldMaxLength && confChange.countUnit === 'ecase.common.words'"
         class="counter-style">{{getWordCount(bphChange.otherValue)}} / {{confChange.otherValueFieldMaxLength}}
        {{'ecase.common.words' | translate}}</p>
    </ng-container>
    <span *ngIf="bphChange?.error[selectedLanguage]"
          class="tableFormFieldError">{{bphChange.error[selectedLanguage]}}</span>
  </div>
</td>


<td *ngIf="isValidTableReadonly && bphChange && !bphChange.hideCell && !confChange.hideCell  && !row.isTotal "
    [attr.rowspan]="bphChange.rowspan"
    [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
    style="border:0;width: 10%;text-align: center;padding-left:10px;padding-right:10px">
  <div class="field {{bphChange.error_class}} hide_{{bphChange.show}}">
    <ng-container
      *ngIf="bphChange.value && (bphChange.value.value !== otherValue.value && bphChange.value.value != confChange.otherValue)">
      <span
        *ngIf="confChange.parameters && confChange.parameters.length>0 && bphChange.value.data">{{bphChange.value.data[(confChange.parameters[0]).text]}}</span>
      <span
        *ngIf="!(confChange.parameters && confChange.parameters.length>0)">{{bphChange.value.label[selectedLanguage]}}</span>
    </ng-container>
    <ng-container
      *ngIf="confChange.hasOther && (bphChange.value.value === otherValue.value || bphChange.value.value == confChange.otherValue)">
      <span>{{bphChange.otherValue}}</span>
    </ng-container>
    <span *ngIf="bphChange.error[selectedLanguage]"
          class="tableFormFieldError">{{bphChange.error[selectedLanguage]}}</span>
  </div>
</td>
