import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  dialogData: any;
  DialogTextTwo: any;
  selectedLanguage: any;
  showConfirmOption = false;
  showActionButtons;
  styleClass;
  applicationId: any;

  constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    console.log("3333333333333333")
    console.log(this.data)
    this.dialogData = this.data.dialog;
    this.DialogTextTwo = this.data.DialogTextTwo;
    this.selectedLanguage = this.data.selectedLanguage;
    if (this.data.showConfirmOption) {
      this.showConfirmOption = this.data.showConfirmOption;
    }
    if (this.data.showActionButtons !== undefined) {
      this.showActionButtons = this.data.showActionButtons;
    }
    if (this.showActionButtons === undefined) {
      this.showActionButtons = true;
    }
   // this.styleClass = this.data.styleClass;
    this.applicationId = this.data.applicationId;

  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }


}
