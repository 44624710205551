<div style="padding-bottom: 1.34375em;"
     [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
     *ngIf="!bphChange.hide && bphChange.globalShowDetail && !confChange.showDetails"
     class="{{bphChange.error_class}} hide_{{bphChange.show}}">

  <div *ngIf="!isHideTable">
    <table [id]="'multiYearTable' + confChange.name"
           style="width: 100%;margin-bottom: 10px;margin-top: 20px"
           class="angular-table {{bphChange.error_class}}">
      <thead class="angular-table-thead">
      <tr class="angular-table-tr" *ngIf="confChange.showSuperHeader">
        <ng-template ngFor let-aBlock [ngForOf]="confChange.headers">
          <th style="text-align: center" class="angular-table-th" [innerHTML]="aBlock.label[selectedLanguage]"
              [attr.colspan]="aBlock.colspan">

          </th>
        </ng-template>
      </tr>
      <tr class="angular-table-tr">
        <th class="angular-table-th"></th>
        <!-- <ng-template ngFor let-aBlock [ngForOf]="confChange.subBlocks">
           <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)"
               *ngIf="!aBlock.hideCell"
               style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
             <span *ngIf="aBlock.isRequired" style='color: red'>* </span>
             <span [innerHTML]="aBlock.label[selectedLanguage]"></span>
             <fb-tooltip [selectedLanguage]="selectedLanguage" [tooltipText]="aBlock.tooltipText"
                         *ngIf="aBlock.hasTooltip == true"></fb-tooltip>
           </th>
         </ng-template>-->
        <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)"
            *ngIf="!confChange.subBlocks[0].hideCell"
            style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="confChange.subBlocks[0].isRequired" style='color: red'>* </span>
          <span [innerHTML]="confChange.subBlocks[0].label[selectedLanguage]"></span>
          <fb-tooltip [selectedLanguage]="selectedLanguage" [tooltipText]="confChange.subBlocks[0].tooltipText"
                      *ngIf="confChange.subBlocks[0].hasTooltip == true"></fb-tooltip>
        </th>
        <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)"
            *ngIf="!confChange.subBlocks[1].hideCell"
            style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="confChange.subBlocks[1].isRequired" style='color: red'>* </span>
          <span [innerHTML]="confChange.subBlocks[1].label[selectedLanguage]"></span>
          <fb-tooltip [selectedLanguage]="selectedLanguage" [tooltipText]="confChange.subBlocks[1].tooltipText"
                      *ngIf="confChange.subBlocks[1].hasTooltip == true"></fb-tooltip>
        </th>
        <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)"
            *ngIf="!confChange.subBlocks[2].hideCell && !confChange.subBlocks[3].hideCell"
            style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="confChange.subBlocks[2].isRequired" style='color: red'>* </span>
          <span *ngIf="confChange.subBlocks[2].mergedLabel" [innerHTML]="confChange.subBlocks[2].mergedLabel[selectedLanguage]"></span>
          <span *ngIf="!confChange.subBlocks[2].mergedLabel" [innerHTML]="confChange.subBlocks[2].label[selectedLanguage]"></span>
          <fb-tooltip [selectedLanguage]="selectedLanguage" [tooltipText]="confChange.subBlocks[2].tooltipText"
                      *ngIf="confChange.subBlocks[2].hasTooltip == true"></fb-tooltip>
          <!-- <fb-tooltip [selectedLanguage]="selectedLanguage" [tooltipText]="confChange.subBlocks[3].tooltipText" *ngIf="confChange.subBlocks[3].hasTooltip == true"></fb-tooltip>-->
        </th>
        <!--   <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)" *ngIf="!confChange.subBlocks[3].hideCell" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
             <span *ngIf="confChange.subBlocks[3].isRequired" style='color: red'>* </span>
             <span [innerHTML]="confChange.subBlocks[3].label[selectedLanguage]"></span>
             <fb-tooltip [selectedLanguage]="selectedLanguage" [tooltipText]="confChange.subBlocks[3].tooltipText" *ngIf="confChange.subBlocks[3].hasTooltip == true"></fb-tooltip>
           </th>-->
        <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)"
            *ngIf="!confChange.subBlocks[4].hideCell"
            style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="confChange.subBlocks[4].isRequired" style='color: red'>* </span>
          <span [innerHTML]="confChange.subBlocks[4].label[selectedLanguage]"></span>
          <fb-tooltip [selectedLanguage]="selectedLanguage" [tooltipText]="confChange.subBlocks[4].tooltipText"
                      *ngIf="confChange.subBlocks[4].hasTooltip == true"></fb-tooltip>
        </th>
        <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)"
            *ngIf="!confChange.subBlocks[5].hideCell"
            style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="confChange.subBlocks[5].isRequired" style='color: red'>* </span>
          <span [innerHTML]="confChange.subBlocks[5].label[selectedLanguage]"></span>
          <fb-tooltip [selectedLanguage]="selectedLanguage" [tooltipText]="confChange.subBlocks[5].tooltipText"
                      *ngIf="confChange.subBlocks[5].hasTooltip == true"></fb-tooltip>
        </th>
        <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)"
            *ngIf="!confChange.subBlocks[6].hideCell"
            style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="confChange.subBlocks[6].isRequired" style='color: red'>* </span>
          <span [innerHTML]="confChange.subBlocks[6].label[selectedLanguage]"></span>
          <fb-tooltip [selectedLanguage]="selectedLanguage" [tooltipText]="confChange.subBlocks[6].tooltipText"
                      *ngIf="confChange.subBlocks[6].hasTooltip == true"></fb-tooltip>
        </th>
        <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)"
            *ngIf="!confChange.subBlocks[7].hideCell"
            style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="confChange.subBlocks[7].isRequired" style='color: red'>* </span>
          <span [innerHTML]="confChange.subBlocks[7].label[selectedLanguage]"></span>
          <fb-tooltip [selectedLanguage]="selectedLanguage" [tooltipText]="confChange.subBlocks[7].tooltipText"
                      *ngIf="confChange.subBlocks[7].hasTooltip == true"></fb-tooltip>
        </th>
        <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)"
            *ngIf="!confChange.subBlocks[8].hideCell"
            style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="confChange.subBlocks[8].isRequired" style='color: red'>* </span>
          <span [innerHTML]="confChange.subBlocks[8].label[selectedLanguage]"></span>
          <fb-tooltip [selectedLanguage]="selectedLanguage" [tooltipText]="confChange.subBlocks[8].tooltipText"
                      *ngIf="confChange.subBlocks[8].hasTooltip == true"></fb-tooltip>
        </th>
        <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)"
            *ngIf="!confChange.subBlocks[9].hideCell"
            style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="confChange.subBlocks[9].isRequired" style='color: red'>* </span>
          <span [innerHTML]="confChange.subBlocks[9].label[selectedLanguage]"></span>
          <fb-tooltip [selectedLanguage]="selectedLanguage" [tooltipText]="confChange.subBlocks[9].tooltipText"
                      *ngIf="confChange.subBlocks[9].hasTooltip == true"></fb-tooltip>
        </th>
        <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)"
            *ngIf="!confChange.subBlocks[10].hideCell"
            style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="confChange.subBlocks[10].isRequired" style='color: red'>* </span>
          <span [innerHTML]="confChange.subBlocks[10].label[selectedLanguage]"></span>
          <fb-tooltip [selectedLanguage]="selectedLanguage" [tooltipText]="confChange.subBlocks[10].tooltipText"
                      *ngIf="confChange.subBlocks[10].hasTooltip == true"></fb-tooltip>
        </th>
        <th class="angular-table-th" *ngIf="confChange.readOnlyTable"></th>
        <th class="angular-table-th" *ngIf="confChange.hasRowTotal"
            [innerHTML]="confChange.rowTotalColumnLabel[selectedLanguage]"></th>
        <th *ngIf="confChange.showEdit" class="angular-table-th">
          {{confChange.editLabel ? confChange.editLabel[selectedLanguage] : '' }}
        </th>
        <th *ngIf="confChange.showDelete" class="angular-table-th">
          {{confChange.deleteLabel[selectedLanguage]}}
        </th>
      </tr>
      </thead>

      <tbody>
      <ng-container *ngFor="let row of bphChange.rows; let i = index">
        <tr [hidden]="row.isHidden" class="angular-table-tr" [class]="row.isTotal?'active':''"
            style="border-bottom: 1px solid rgba(0,0,0,.12);">
          <!-- _BPH_MT_SUBCONTENT_S -->
          <td style='border:0;width: 10%;text-align: center'>
            <button
              *ngIf='!row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 0)].showInputComponent'
              (click)='toggleMultiYearRows(i,bphChange,confChange)'
              mat-icon-button color='accent'>
              <mat-icon>{{row.icon}}</mat-icon>
            </button>
          </td>

          <fb-select [selectedSection]='selectedSectionId'
                     [style.background-color]="row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 0)].value === '' ? 'lightgrey' : 'white'"
                     style='position: static;display: table-cell;vertical-align: middle;'
                     [selectedLanguage]='selectedLanguage'
                     [isValidHtml]='false'
                     [isValidTable]='false'
                     [isValidTableReadonly]='true'
                     [bphChange]='row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 0)].select_title'
                     [confChange]='confChange.select_title' [globalConf]='globalConf'
                     [globalPrj]='globalPrj' [rowNumber]='i'></fb-select>

          <fb-select [selectedSection]='selectedSectionId'
                     [style.background-color]="row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 1)].value === (confChange.yearLabel[selectedLanguage] + ' '+ i) ? 'lightgrey' : 'white'"
                     style='position: static;display: table-cell;vertical-align: middle;'
                     [selectedLanguage]='selectedLanguage'
                     [isValidHtml]='false'
                     [isValidTable]='false'
                     [isValidTableReadonly]='true'
                     [bphChange]='row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 1)].select_qualification_classification'
                     [confChange]='confChange.select_qualification_classification'
                     [globalConf]='globalConf' [globalPrj]='globalPrj' [rowNumber]='i'></fb-select>

          <!--   <fb-text
               (emitOutputEvent)='updateHeaderRowValue(confChange.text_first_name,$event,i, 2,bphChange,parseInt(bphChange.noOfYears),confChange.currency)'
               [style.background-color]="row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 2)].value === '' ? 'lightgrey' : 'white'"
               [selectedSection]='selectedSection' style='position: static;display: table-cell;vertical-align: middle;'
               [selectedLanguage]='selectedLanguage'
               [isValidHtml]='false'
               [isValidTable]='false'
               [isValidTableReadonly]='true'
               [bphChange]='row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 2)].text_first_name'
               [confChange]='confChange.text_first_name' [globalConf]='globalConf'
               [globalPrj]='globalPrj' [rowNumber]='i'></fb-text>

             <fb-text
               (emitOutputEvent)='updateHeaderRowValue(confChange.text_last_name,$event,i, 3,bphChange,parseInt(bphChange.noOfYears),confChange.currency)'
               [style.background-color]="row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 3)].value === '' ? 'lightgrey' : 'white'"
               [selectedSection]='selectedSection' style='position: static;display: table-cell;vertical-align: middle;'
               [selectedLanguage]='selectedLanguage'
               [isValidHtml]='false'
               [isValidTable]='false'
               [isValidTableReadonly]='true'
               [bphChange]='row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 3)].text_last_name'
               [confChange]='confChange.text_last_name' [globalConf]='globalConf'
               [globalPrj]='globalPrj' [rowNumber]='i'></fb-text>-->


          <td
            [attr.rowspan]="row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 2)].text_first_name.rowspan"
            [style.text-align]="'center'"
            [style.padding-left]="0"
            [style.background-color]="(row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 2)].value + row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 3)].value) === '' ? 'lightgrey' : 'white'"
            style="border:0;width: 1%;">
            <div style="text-align: center;margin-bottom: 0"
                 [ngClass]="row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 2)].text_first_name?.error[selectedLanguage] ? 'hasError' : 'noError'">
              {{row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() +
              2)].text_first_name.value}}
              {{row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() +
              3)].text_last_name.value}}
              <span
                *ngIf="row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 2)].text_first_name?.error[selectedLanguage]"
                class="tableFormFieldError">{{row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 2)].text_first_name.error[selectedLanguage]}}</span>
            </div>
          </td>


          <td
            *ngIf="!confChange.subBlocks[4].hideCell"
            [attr.rowspan]="row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 4)].text_time_allocated_percentage.rowspan"
            [style.text-align]="'center'"
            [style.padding-left]="0"
            style="border:0;width: 1%;">
            <div style="text-align: center;margin-bottom: 0"
                 [ngClass]="getYearErrorMessages(i,'text_time_allocated_percentage',4) ? 'hasError' : 'noError'">
              {{row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() +
              4)].text_time_allocated_percentage.value}}
              <span *ngIf="getYearErrorMessages(i,'text_time_allocated_percentage',4)"
                    class="tableFormFieldError">{{getYearErrorMessages(i,'text_time_allocated_percentage',4)[selectedLanguage]}}</span>
            </div>
          </td>

          <!-- <fb-text
             (emitOutputEvent)='updateHeaderRowValue(confChange.text_time_allocated_percentage,$event,i, 4,bphChange,parseInt(bphChange.noOfYears),confChange.currency)'
             [selectedSection]='selectedSection' style='position: static;display: table-cell;vertical-align: middle;'
             [selectedLanguage]='selectedLanguage'
             [isValidHtml]='false'
             [isValidTable]='false'
             [isValidTableReadonly]='true'
             [bphChange]='row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 4)].text_time_allocated_percentage'
             [confChange]='confChange.text_time_allocated_percentage'
             [globalConf]='globalConf' [globalPrj]='globalPrj' [rowNumber]='i'></fb-text>-->


          <td
            *ngIf="!confChange.subBlocks[5].hideCell"
            [attr.rowspan]="row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 5)].text_duration_months.rowspan"
            [style.text-align]="'center'"
            [style.padding-left]="0"
            style="border:0;width: 1%;">
            <div style="text-align: center;margin-bottom: 0"
                 [ngClass]="(getYearErrorMessages(i,'text_duration_months',5) || getHeaderRowErrorMessages(i,'text_duration_months',5)) ? 'hasError' : 'noError'">
              {{row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() +
              5)].text_duration_months.value}}
              <span *ngIf="getYearErrorMessages(i,'text_duration_months',5)"
                    class="tableFormFieldError">{{getYearErrorMessages(i,'text_duration_months',5)[selectedLanguage]}}</span>
              <span *ngIf="getHeaderRowErrorMessages(i,'text_duration_months',5)"
                    class="tableFormFieldError">{{getHeaderRowErrorMessages(i,'text_duration_months',5)[selectedLanguage]}}</span>
            </div>
          </td>

          <!--  <fb-text
              (emitOutputEvent)='updateHeaderRowValue(confChange.text_duration_months,$event,i, 5,bphChange,parseInt(bphChange.noOfYears),confChange.currency)'
              [selectedSection]='selectedSection' style='position: static;display: table-cell;vertical-align: middle;'
              [selectedLanguage]='selectedLanguage'
              [isValidHtml]='false'
              [isValidTable]='false'
              [isValidTableReadonly]='true'
              [bphChange]='row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 5)].text_duration_months'
              [confChange]='confChange.text_duration_months' [globalConf]='globalConf'
              [globalPrj]='globalPrj' [rowNumber]='i'></fb-text>-->


          <td
            [attr.rowspan]="row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 6)].text_subtotal.rowspan"
            [style.text-align]="'center'"
            [style.padding-left]="0"
            style="border:0;width: 1%;">
            <div style="text-align: center;margin-bottom: 0"
                 [ngClass]="getYearErrorMessages(i,'text_subtotal',6) ? 'hasError' : 'noError'">
              {{row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() +
              6)].text_subtotal.value}}
              <span *ngIf="getYearErrorMessages(i,'text_subtotal',6)"
                    class="tableFormFieldError">{{getYearErrorMessages(i,'text_subtotal',6)[selectedLanguage]}}</span>
            </div>
          </td>

          <!--   <fb-text style='position: static;display: table-cell;vertical-align: middle;'
                      (emitOutputEvent)='updateHeaderRowValue(confChange.text_subtotal,$event,(rowIndex + row), 6,bphChange,parseInt(bphChange.noOfYears),confChange.currency)'
                      [selectedSection]='selectedSection'
                      [selectedLanguage]='selectedLanguage'
                      [isValidHtml]='false'
                      [isValidTable]='false'
                      [isValidTableReadonly]='true'
                      [bphChange]='row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 6)].text_subtotal'
                      [confChange]='confChange.text_subtotal' [globalConf]='globalConf'
                      [globalPrj]='globalPrj'></fb-text>-->

          <td
            [attr.rowspan]="row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 7)].text_funds_from_kfas.rowspan"
            [style.text-align]="'center'"
            [style.padding-left]="0"
            style="border:0;width: 1%;">
            <div style="text-align: center;margin-bottom: 0"
                 [ngClass]="getYearErrorMessages(i,'text_funds_from_kfas',7) ? 'hasError' : 'noError'">
              {{row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() +
              7)].text_funds_from_kfas.value}}
              <span *ngIf="getYearErrorMessages(i,'text_funds_from_kfas',7)"
                    class="tableFormFieldError">{{getYearErrorMessages(i,'text_funds_from_kfas',7)[selectedLanguage]}}</span>
            </div>
          </td>


          <!--   <fb-text style='position: static;display: table-cell;vertical-align: middle;'
                      (emitOutputEvent)='updateHeaderRowValue(confChange.text_funds_from_kfas,$event,(rowIndex + row), 7,bphChange,parseInt(bphChange.noOfYears),confChange.currency)'
                      [selectedSection]='selectedSection'
                      [selectedLanguage]='selectedLanguage'
                      [isValidHtml]='false'
                      [isValidTable]='false'
                      [isValidTableReadonly]='true'
                      [bphChange]='row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 7)].text_funds_from_kfas'
                      [confChange]='confChange.text_funds_from_kfas' [globalConf]='globalConf'
                      [globalPrj]='globalPrj'></fb-text>-->


          <td
            [attr.rowspan]="row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 8)].text_funds_from_other_institution.rowspan"
            [style.text-align]="'center'"
            [style.padding-left]="0"
            style="border:0;width: 1%;">
            <div style="text-align: center;margin-bottom: 0"
                 [ngClass]="getYearErrorMessages(i,'text_funds_from_other_institution',8) ? 'hasError' : 'noError'">
              {{row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() +
              8)].text_funds_from_other_institution.value}}
              <span *ngIf="getYearErrorMessages(i,'text_funds_from_other_institution',8)"
                    class="tableFormFieldError">{{getYearErrorMessages(i,'text_funds_from_other_institution',8)[selectedLanguage]}}</span>
            </div>
          </td>

          <!--   <fb-text style='position: static;display: table-cell;vertical-align: middle;'
                      (emitOutputEvent)='updateHeaderRowValue(confChange.text_funds_from_other_institution,$event,(rowIndex + row), 8,bphChange,parseInt(bphChange.noOfYears),confChange.currency)'
                      [selectedSection]='selectedSection'
                      [selectedLanguage]='selectedLanguage'
                      [isValidHtml]='false'
                      [isValidTable]='false'
                      [isValidTableReadonly]='true'
                      [bphChange]='row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 8)].text_funds_from_other_institution'
                      [confChange]='confChange.text_funds_from_other_institution' [globalConf]='globalConf'
                      [globalPrj]='globalPrj'></fb-text>-->

          <fb-text-area [selectedSection]='selectedSectionId'
                        [style.background-color]="row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 9)].value === '' ? 'lightgrey' : 'white'"
                        style='position: static;display: table-cell;vertical-align: middle;'
                        [selectedLanguage]='selectedLanguage'
                        [isValidHtml]='false'
                        [isValidTable]='false'
                        [isValidTableReadonly]='true'
                        maxlength="3000"
                        [bphChange]='row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 9)].textarea_comments'
                        [confChange]='confChange.textarea_comments'
                        [globalConf]='globalConf' [globalPrj]='globalPrj' [rowNumber]='i'></fb-text-area>

          <fb-upload [selectedSectionId]='selectedSectionId'
                     [style.background-color]="row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 10)].value === '' ? 'lightgrey' : 'white'"
                     style='position: static;display: table-cell;vertical-align: middle;'
                     [selectedLanguage]='selectedLanguage'
                     [isValidHtml]='false'
                     [isValidTable]='false'
                     [isValidTableReadonly]='true'
                     [blockIndex]='(blockIndex + (i / 100))'
                     [bphChange]='row[((multiYearRowIndexCalculator(i,parseInt(bphChange.noOfYears))).toString() + 10)].upload_documentation'
                     [confChange]='confChange.upload_documentation'
                     [globalConf]='globalConf' [globalPrj]='globalPrj' [rowNumber]='i'></fb-upload>

          <!-- _BPH_MT_SUBCONTENT_E -->
          <ng-template [ngIf]="confChange.hasRowTotal">
            <td style="border: 0;
            width: 10%;
            text-align: center;
            padding: 10px;" class="angular-table-td">
              {{getMultiYearRowTotal(confChange,i,bphChange,confChange.currency)}}
            </td>
          </ng-template>
<!--          <td class="angular-table-td"-->
<!--              style="text-align: center; padding-right: 15px !important;">-->
<!--            <button mat-icon-button (click)="menuTrigger.openMenu();$event.stopPropagation()"-->
<!--                    #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" class="mat-icon-button"-->
<!--                    aria-label="More"-->
<!--                    *ngIf="row.showEdit || row.showDelete"-->
<!--                    style="background-color: lightgrey"-->
<!--                    matTooltip="{{ 'ecase.researcherdasboard.projects.humanEthics.more' | translate }}">-->
<!--              <mat-icon class="matIconColor">more_vert</mat-icon>-->
<!--            </button>-->
<!--            <mat-menu #menu="matMenu">-->
<!--              <button *ngIf="row.showEdit" mat-menu-item-->
<!--                      (click)="editMultiYearRow(i)">{{confChange.editLabel[selectedLanguage]}}-->
<!--              </button>-->
<!--              <button *ngIf="row.showDelete && !row.isTotal" mat-menu-item-->
<!--                      (click)="deleteMultiYearRow(i,bphChange,confChange)">{{confChange.deleteLabel[selectedLanguage]}}-->
<!--              </button>-->

<!--            </mat-menu>-->
<!--          </td>-->
          <td *ngIf="confChange.showEdit && !row.isTotal" class="angular-table-td"
              style="text-align: center;">
            <button (click)="editMultiYearRow(i)"
                    mat-icon-button>
              <mat-icon>create</mat-icon>
            </button>
          </td>
          <td *ngIf="confChange.showDelete && !row.isTotal" class="angular-table-td"
              style="text-align: center;">
            <button (click)="deleteMultiYearRow(i,bphChange,confChange)"
                    mat-icon-button>
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
      </ng-container>
      <tr class="active angular-table-tr" *ngIf="confChange.hasGlobalTotal">
        <td style="border: 0;
    width: 10%;
    text-align: center;
    padding: 10px;" class="angular-table-td">
          {{confChange.globalTotalLabel[selectedLanguage]}}

        </td>
        <td class="angular-table-td" style="border: 0;width: 10%;text-align: center;padding: 10px;">
          <ng-template [ngIf]="confChange.subBlocks[0].hasGlobalTotal">
            {{getMultiYearGlobalTotal(bphChange,confChange.subBlocks[0].name,0,confChange,confChange.currency,confChange.subBlocks[0].globalTotalText)}}
          </ng-template>
        </td>
        <td class="angular-table-td" style="border: 0;width: 10%;text-align: center;padding: 10px;">
          <ng-template [ngIf]="confChange.subBlocks[1].hasGlobalTotal">
            {{getMultiYearGlobalTotal(bphChange,confChange.subBlocks[1].name,1,confChange,confChange.currency,confChange.subBlocks[1].globalTotalText)}}
          </ng-template>
        </td>
        <td class="angular-table-td" style="border: 0;width: 10%;text-align: center;padding: 10px;">
          <ng-template [ngIf]="confChange.subBlocks[2].hasGlobalTotal">
            {{getMultiYearGlobalTotal(bphChange,confChange.subBlocks[2].name,2,confChange,confChange.currency,confChange.subBlocks[2].globalTotalText)}}
          </ng-template>
        </td>
        <!--<td class="angular-table-td" style="border: 0;width: 10%;text-align: center;padding: 10px;">
          <ng-template [ngIf]="confChange.subBlocks[3].hasGlobalTotal">
            {{getMultiYearGlobalTotal(bphChange,confChange.subBlocks[3].name,3,confChange,confChange.currency,confChange.subBlocks[3].globalTotalText)}}
          </ng-template>
        </td>-->
        <td class="angular-table-td" *ngIf="!confChange.subBlocks[4].hideCell" style="border: 0;width: 10%;text-align: center;padding: 10px;">
          <ng-template [ngIf]="confChange.subBlocks[4].hasGlobalTotal">
            {{getMultiYearGlobalTotal(bphChange,confChange.subBlocks[4].name,4,confChange,confChange.currency,confChange.subBlocks[4].globalTotalText)}}
          </ng-template>
        </td>
        <td class="angular-table-td" *ngIf="!confChange.subBlocks[5].hideCell" style="border: 0;width: 10%;text-align: center;padding: 10px;">
          <ng-template [ngIf]="confChange.subBlocks[5].hasGlobalTotal">
            {{getMultiYearGlobalTotal(bphChange,confChange.subBlocks[5].name,5,confChange,confChange.currency,confChange.subBlocks[5].globalTotalText)}}
          </ng-template>
        </td>
        <td class="angular-table-td" style="border: 0;width: 10%;text-align: center;padding: 10px;">
          <ng-template [ngIf]="confChange.subBlocks[6].hasGlobalTotal">
            {{getMultiYearGlobalTotal(bphChange,confChange.subBlocks[6].name,6,confChange,confChange.currency,confChange.subBlocks[6].globalTotalText)}}
          </ng-template>
        </td>
        <td class="angular-table-td" style="border: 0;width: 10%;text-align: center;padding: 10px;">
          <ng-template [ngIf]="confChange.subBlocks[7].hasGlobalTotal">
            {{getMultiYearGlobalTotal(bphChange,confChange.subBlocks[7].name,7,confChange,confChange.currency,confChange.subBlocks[7].globalTotalText)}}
          </ng-template>
        </td>
        <td class="angular-table-td" style="border: 0;width: 10%;text-align: center;padding: 10px;">
          <ng-template [ngIf]="confChange.subBlocks[8].hasGlobalTotal">
            {{getMultiYearGlobalTotal(bphChange,confChange.subBlocks[8].name,8,confChange,confChange.currency,confChange.subBlocks[8].globalTotalText)}}
          </ng-template>
        </td>
        <td class="angular-table-td" style="border: 0;width: 10%;text-align: center;padding: 10px;">
          <ng-template [ngIf]="confChange.subBlocks[9].hasGlobalTotal">
            {{getMultiYearGlobalTotal(bphChange,confChange.subBlocks[9].name,9,confChange,confChange.currency,confChange.subBlocks[9].globalTotalText)}}
          </ng-template>
        </td>
        <td class="angular-table-td" style="border: 0;width: 10%;text-align: center;padding: 10px;">
          <ng-template [ngIf]="confChange.subBlocks[10].hasGlobalTotal">
            {{getMultiYearGlobalTotal(bphChange,confChange.subBlocks[10].name,10,confChange,confChange.currency,confChange.subBlocks[10].globalTotalText)}}
          </ng-template>
        </td>
        <td style="border: 0;
    width: 10%;
    text-align: center;
    padding: 10px;" class="angular-table-td">
          <!--  {{getMultiYearTotalGlobalTotal(bphChange,confChange)}}-->
        </td>
        <td class="angular-table-td" *ngIf="confChange.readOnlyTable">

        </td>
      </tr>
      </tbody>
    </table>


      <button *ngIf="confChange.showAddRow"
              (click)="addMultiYearRow(bphChange,confChange)"
              [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'"
              mat-raised-button color="accent">{{confChange.label[selectedLanguage]}}
      </button>
        <br>
      <span *ngIf="bphChange?.error[selectedLanguage]"
            class="tableFormFieldError">{{bphChange.error[selectedLanguage]}}</span>

  </div>
  <div *ngIf="isHideTable" fxLayout="column" fxLayoutAlign="start">
    <fb-select [selectedSection]='selectedSectionId'
               (emitOutputEvent)='updateQualifications($event)'
               [selectedLanguage]='selectedLanguage'
               [isValidHtml]='true'
               [isValidTable]='false'
               [isValidTableReadonly]='false'
               [bphChange]='(bphChange.rows[rowIndex])[((multiYearRowIndexCalculator(rowIndex,parseInt(bphChange.noOfYears))).toString() + 0)].select_title'
               [confChange]='confChange.select_title' [globalConf]='globalConf'
               [globalPrj]='globalPrj' [rowNumber]='rowIndex'></fb-select>

    <fb-select [selectedSection]='selectedSectionId'
               [selectedLanguage]='selectedLanguage'
               [isValidHtml]='true'
               [isValidTable]='false'
               [isValidTableReadonly]='false'
               [bphChange]='(bphChange.rows[rowIndex])[((multiYearRowIndexCalculator(rowIndex,parseInt(bphChange.noOfYears))).toString() + 1)].select_qualification_classification'
               [confChange]='confChange.select_qualification_classification'
               [globalConf]='globalConf' [globalPrj]='globalPrj' [rowNumber]='rowIndex'></fb-select>

    <fb-text
      (emitOutputEvent)='updateHeaderRowValue(confChange.text_first_name,$event,rowIndex, 2,bphChange,parseInt(bphChange.noOfYears),confChange.currency)'
      [selectedSection]='selectedSectionId'
      [selectedLanguage]='selectedLanguage'
      [isValidHtml]='true'
      [isValidTable]='false'
      [isValidTableReadonly]='false'
      [bphChange]='(bphChange.rows[rowIndex])[((multiYearRowIndexCalculator(rowIndex,parseInt(bphChange.noOfYears))).toString() + 2)].text_first_name'
      [confChange]='confChange.text_first_name' [globalConf]='globalConf'
      [globalPrj]='globalPrj' [rowNumber]='rowIndex'></fb-text>

    <fb-text
      (emitOutputEvent)='updateHeaderRowValue(confChange.text_last_name,$event,rowIndex, 3,bphChange,parseInt(bphChange.noOfYears),confChange.currency)'
      [selectedSection]='selectedSectionId'
      [selectedLanguage]='selectedLanguage'
      [isValidHtml]='true'
      [isValidTable]='false'
      [isValidTableReadonly]='false'
      [bphChange]='(bphChange.rows[rowIndex])[((multiYearRowIndexCalculator(rowIndex,parseInt(bphChange.noOfYears))).toString() + 3)].text_last_name'
      [confChange]='confChange.text_last_name' [globalConf]='globalConf'
      [globalPrj]='globalPrj' [rowNumber]='rowIndex'></fb-text>

    <table class="angular-table" style="margin-bottom: 1.34375em;" [id]="'table_' + confChange.name">
      <thead class="angular-table-thead">
      <tr class="angular-table-tr">
        <th class="angular-table-th" style="text-align: center;">{{confChange.yearLabel[selectedLanguage]}}</th>
        <th class="angular-table-th"
            *ngIf="!confChange.subBlocks[4].hideCell"
            style="text-align: center;"><span *ngIf="confChange.subBlocks[4].isRequired" style='color: red'>* </span>{{confChange.subBlocks[4].label[selectedLanguage]}}
        </th>
        <th class="angular-table-th"
            *ngIf="!confChange.subBlocks[5].hideCell"
            style="text-align: center;">
          <span *ngIf="confChange.subBlocks[5].isRequired" style='color: red'>* </span>{{confChange.subBlocks[5].label[selectedLanguage]}}
        </th>
        <th class="angular-table-th" style="text-align: center;">
          <span *ngIf="confChange.subBlocks[6].isRequired" style='color: red'>* </span>{{confChange.subBlocks[6].label[selectedLanguage]}}
        </th>
        <th class="angular-table-th" style="text-align: center;">
          <span *ngIf="confChange.subBlocks[7].isRequired" style='color: red'>* </span>{{confChange.subBlocks[7].label[selectedLanguage]}}
        </th>
        <th class="angular-table-th" style="text-align: center;">
          <span *ngIf="confChange.subBlocks[8].isRequired" style='color: red'>* </span>{{confChange.subBlocks[8].label[selectedLanguage]}}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr class="angular-table-tr" *ngFor="let row of yearArray">
        <td class="angular-table-td" style="text-align: center">
          {{confChange.yearLabel[selectedLanguage]}} {{' ' + row}}
        </td>

        <fb-text
          *ngIf="!confChange.subBlocks[4].hideCell"
          style="position: static;display: table-cell;vertical-align: middle;border-top: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 1px solid rgba(0, 0, 0, 0.12);"
          (emitOutputEvent)='updateHeaderRowValue(confChange.text_time_allocated_percentage,$event, (rowIndex + row), 4,bphChange,parseInt(bphChange.noOfYears), undefined)'
          [selectedSection]='selectedSectionId'
          [selectedLanguage]='selectedLanguage'
          [isValidHtml]='false'
          [isValidTable]='true'
          [isValidTableReadonly]='false'
          [bphChange]='(bphChange.rows[rowIndex + row])[(row.toString() + 4)].text_time_allocated_percentage'
          [rowNumber]='rowIndex'
          [confChange]='confChange.text_time_allocated_percentage'
          [globalConf]='globalConf' [globalPrj]='globalPrj'></fb-text>

        <fb-text
          *ngIf="!confChange.subBlocks[5].hideCell"
          style="position: static;display: table-cell;vertical-align: middle;border-top: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 1px solid rgba(0, 0, 0, 0.12);"
          (emitOutputEvent)='updateHeaderRowValue(confChange.text_duration_months,$event,(rowIndex + row), 5,bphChange,parseInt(bphChange.noOfYears), undefined)'
          [selectedSection]='selectedSectionId'
          [selectedLanguage]='selectedLanguage'
          [isValidHtml]='false'
          [isValidTable]='true'
          [isValidTableReadonly]='false'
          [rowNumber]='rowIndex'
          [bphChange]='(bphChange.rows[rowIndex + row])[(row.toString() + 5)].text_duration_months'
          [confChange]='confChange.text_duration_months' [globalConf]='globalConf'
          [globalPrj]='globalPrj'></fb-text>

        <fb-text
          style="position: static;display: table-cell;vertical-align: middle;border-top: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 1px solid rgba(0, 0, 0, 0.12);"
          (emitOutputEvent)='updateFieldValues(confChange.text_subtotal,$event,(rowIndex + row), 6,bphChange,parseInt(bphChange.noOfYears),confChange.currency);updateHeaderRowValue(confChange.text_subtotal,$event,(rowIndex + row), 6,bphChange,parseInt(bphChange.noOfYears),confChange.currency);'
          [selectedSection]='selectedSectionId'
          [selectedLanguage]='selectedLanguage'
          [isValidHtml]='false'
          [isValidTable]='true'
          [rowNumber]='rowIndex'
          [isValidTableReadonly]='false'
          [bphChange]='(bphChange.rows[rowIndex + row])[(row.toString() + 6)].text_subtotal'
          [confChange]='confChange.text_subtotal' [globalConf]='globalConf'
          [globalPrj]='globalPrj'></fb-text>


        <fb-text
          style="position: static;display: table-cell;vertical-align: middle;border-top: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 1px solid rgba(0, 0, 0, 0.12);"
          (emitOutputEvent)='updateFieldValues(confChange.text_funds_from_kfas,$event,(rowIndex + row), 7,bphChange,parseInt(bphChange.noOfYears),confChange.currency);updateHeaderRowValue(confChange.text_funds_from_kfas,$event,(rowIndex + row), 7,bphChange,parseInt(bphChange.noOfYears),confChange.currency);'
          [selectedSection]='selectedSectionId'
          [selectedLanguage]='selectedLanguage'
          [isValidHtml]='false'
          [isValidTable]='true'
          [rowNumber]='rowIndex'
          [isValidTableReadonly]='false'
          [bphChange]='(bphChange.rows[rowIndex + row])[(row.toString() + 7)].text_funds_from_kfas'
          [confChange]='confChange.text_funds_from_kfas' [globalConf]='globalConf'
          [globalPrj]='globalPrj'></fb-text>

        <fb-text
          style="position: static;display: table-cell;vertical-align: middle;border-top: 1px solid rgba(0, 0, 0, 0.12);border-bottom: 1px solid rgba(0, 0, 0, 0.12);"
          (emitOutputEvent)='updateHeaderRowValue(confChange.text_funds_from_other_institution,$event,(rowIndex + row), 8,bphChange,parseInt(bphChange.noOfYears),confChange.currency)'
          [selectedSection]='selectedSectionId'
          [selectedLanguage]='selectedLanguage'
          [isValidHtml]='false'
          [isValidTable]='true'
          [rowNumber]='rowIndex'
          [isValidTableReadonly]='false'
          [bphChange]='(bphChange.rows[rowIndex + row])[(row.toString() + 8)].text_funds_from_other_institution'
          [confChange]='confChange.text_funds_from_other_institution' [globalConf]='globalConf'
          [globalPrj]='globalPrj'></fb-text>

      </tr>
      <tr class="active angular-table-tr">
        <td style="border: 0;
    width: 10%;
    text-align: center;
    padding: 10px;" class="angular-table-td">
          {{confChange.globalTotalLabel[selectedLanguage]}}
        </td>
        <td class="angular-table-td" *ngIf="!confChange.subBlocks[4].hideCell"
            style="border: 0;
    width: 10%;
    text-align: center;
    padding: 10px;">

        </td>
        <td class="angular-table-td" *ngIf="!confChange.subBlocks[5].hideCell"
            style="border: 0;
    width: 10%;
    text-align: center;
    padding: 10px;">

        </td>
        <td class="angular-table-td"
            style="border: 0;
    width: 10%;
    text-align: center;
    padding: 10px;">
           {{convertNumberToCurrencyFormat(((bphChange.rows[rowIndex])[('06')])['text_subtotal'].value, confChange.currency)}}
        </td>
        <td class="angular-table-td"
            style="border: 0;
    width: 10%;
    text-align: center;
    padding: 10px;">
          {{convertNumberToCurrencyFormat(((bphChange.rows[rowIndex])[('07')])['text_funds_from_kfas'].value, confChange.currency)}}
        </td>
        <td class="angular-table-td"
            style="border: 0;
    width: 10%;
    text-align: center;
    padding: 10px;">
          {{convertNumberToCurrencyFormat(((bphChange.rows[rowIndex])[('08')])['text_funds_from_other_institution'].value, confChange.currency)}}
        </td>
      </tr>
      </tbody>
    </table>
    <fb-text-area [selectedSection]='selectedSectionId'
                  maxlength="3000"
                  [selectedLanguage]='selectedLanguage'
                  [isValidHtml]='true'
                  [isValidTable]='false'
                  [isValidTableReadonly]='false'
                  [bphChange]='(bphChange.rows[rowIndex])[((multiYearRowIndexCalculator(rowIndex,parseInt(bphChange.noOfYears))).toString() + 9)].textarea_comments'
                  [confChange]='confChange.textarea_comments'
                  [globalConf]='globalConf' [globalPrj]='globalPrj' [rowNumber]='rowIndex'></fb-text-area>

    <fb-upload [selectedSectionId]='selectedSectionId'
               [selectedLanguage]='selectedLanguage'
               [isValidHtml]='true'
               [isValidTable]='false'
               [isValidTableReadonly]='false'
               [bphChange]='(bphChange.rows[rowIndex])[((multiYearRowIndexCalculator(rowIndex,parseInt(bphChange.noOfYears))).toString() + 10)].upload_documentation'
               [confChange]='confChange.upload_documentation'
               [blockIndex]='(blockIndex + (rowIndex / 100))'
               [globalConf]='globalConf' [globalPrj]='globalPrj' [rowNumber]='rowIndex'></fb-upload>

    <div fxLayout="row" fxLayoutAlign="start">
      <button style=" background-color: #4FC3F7; color: #FFFFFF; margin-right: 1%"
              (click)="closeDetails()"
              mat-raised-button>{{getTranslatedLabel("ecase.common.save")}}
      </button>
      <button (click)="cancel()" mat-raised-button color="accent">{{getTranslatedLabel("ecase.common.cancel") }}
      </button>
    </div>
  </div>
</div>
