<div id="skiptocontent">
  <a id="skiplink" href="javascript:setfocuson()">{{'ecase.common.skipToMainContent' | translate}}</a>
</div>
<div style="padding-top: 5px;" *ngIf="isShowEnvironment && !isBackendDown"
     [style.background-color]="environment !== 'DEV' ? '#0ea432': '#1e70bf'">
  <span style="padding:20px;font-weight: bold"> Environment -- {{environment}}</span>
</div>
<div *ngIf="isUserMasquerading" fxLayout="row" fxLayoutAlign="space-between" class="masquerading">
  <div fxLayout="row" class="align-center">
    <img class="masquerading-icon" src="./../assets/images/etc/masquerading-icon.svg" alt="Masquerading icon"/>
    <span
      class="masquerading-text">{{originalUserProfile.fullname}} {{'ecase.common.isconnectedas'| translate}} {{masqueradedUserProfile.fullname}}</span>
  </div>
  <div>
    <button class="eCase-dialog-negative-action-button" (click)="endMasquerading()"
            mat-raised-button>{{'ecase.common.exit'| translate }}
    </button>
  </div>
</div>
<eCase-login *ngIf="showLogIn && isLanguagesLoaded && !isBackendDown" [class.withEnvironmentBar]="isShowEnvironment"></eCase-login>
<ecase-error-code-screen *ngIf="isBackendDown"></ecase-error-code-screen>
<ecase-downloader></ecase-downloader>
