import {
  AfterViewInit,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnInit,
  Output
} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from '../dialog/dialog.component';
import {PendingChangesGuard, RefreshDataService} from 'synto-common';
import {DateAdapter} from '@angular/material/core';
import {Platform} from '@angular/cdk/platform';
import {FormatWidth, getLocaleDateFormat} from '@angular/common';
import moment from 'moment';

@Component({
  selector: 'fb-datepicker',
  templateUrl: './fb-datepicker.component.html',
  styleUrls: ['./fb-datepicker.component.scss']
})
export class FbDatepickerComponent implements OnInit, AfterViewInit, DoCheck {

  _CONF__CHANGE_: any;
  minDate: Date;
  maxDate: Date;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() selectedLanguage: any;
  @Input() globalPrj: any;
  @Input() rowNumber;
  @Input() selectedSectionId: string;
  @Output() emitOutputEvent = new EventEmitter<any>();
  lang: any;
  row: any;
  differ: KeyValueDiffer<string, any>;

  constructor(private translate: TranslateService,
              private refreshDataService: RefreshDataService,
              public dialog: MatDialog,
              public platform: Platform,
              private pendingChangesGuard: PendingChangesGuard,
              private differs: KeyValueDiffers,
              private adapter: DateAdapter<any>) {
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.error = {};
    this.confChange = {};
    this.confChange.label = {};
    this.confChange.dateFormatToBeShown = {};
    this.row = {};
    this.differ = this.differs.find({}).create();
  }

  private getDateFormat() {
    return getLocaleDateFormat( this.selectedLanguage, FormatWidth.Short );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '600px',
      data:
        {
          dialog: this.confChange.dialogText,
          selectedLanguage: this.selectedLanguage
        }
    });
    console.log('donee');
  }

  ngDoCheck() {
    const change = this.differ.diff(this.bphChange);
    if (change) {
      change.forEachChangedItem((item) => {
        if (item.key === 'hide' && item.currentValue === false) {
          if (!(this.confChange.isReadOnlyField || this.bphChange.globalReadOnlyMode === 'true')) {
            this.removeGreyBackground();
          }
        }
      });
    }
  }

  ngOnInit() {
    this.adapter.setLocale(this.selectedLanguage);
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.adapter.setLocale(params.lang);
      // this.selectedLanguage = params.lang;
    });
    console.log('Add event');
    console.log(this.emitOutputEvent);
    console.log(this.confChange);
    if (!this.bphChange) {
      console.log('first');
      this.bphChange = {};
      this.bphChange.value = {};
      this.bphChange.textValue = '';
      this.bphChange.error = {};
    }

    if (this.confChange.selectedContainerType === 'SchedulerTable' && this.confChange.schedulerTableMultiyearPath) {
      if (!this.bphChange.minDate) {
        this.bphChange.minDate = '';
      }

      if (!this.bphChange.maxDate) {
        this.bphChange.maxDate = '';
      }
      const prj = this.globalPrj;
      const tempArray = this.confChange.schedulerTableMultiyearPath.split('-');
      if (eval(tempArray[0]) && eval(tempArray[0]) !== '' && eval(tempArray[1]) && eval(tempArray[1]) !== '') {
        const firstDate = new Date(eval(tempArray[0].trim())).getFullYear();
        const secondDate = new Date(eval(tempArray[1].trim())).getFullYear();

        if (firstDate < secondDate) {
          this.bphChange.minDate = eval(tempArray[0]);
          this.bphChange.maxDate = eval(tempArray[1]);
        } else if (secondDate < firstDate) {
          this.bphChange.minDate = eval(tempArray[1]);
          this.bphChange.maxDate = eval(tempArray[0]);
        } else {
          const firstMonth = new Date(eval(tempArray[0].trim())).getMonth();
          const secondMonth = new Date(eval(tempArray[1].trim())).getMonth();
          if (firstMonth < secondMonth) {
            this.bphChange.minDate = eval(tempArray[0]);
            this.bphChange.maxDate = eval(tempArray[1]);
          } else if (secondMonth < firstMonth) {
            this.bphChange.minDate = eval(tempArray[1]);
            this.bphChange.maxDate = eval(tempArray[0]);
          } else {
            this.bphChange.minDate = eval(tempArray[0]);
            this.bphChange.maxDate = eval(tempArray[0]);
          }
        }
      }
    }
    if (!this.bphChange.error) {
      this.bphChange.error = {};
    }
    if (!this.bphChange.value) {
      console.log('second');
      this.bphChange.value = '';
      this.bphChange.textValue = '';
    } else {
      this.bphChange.textValue = this.bphChange.value.toString();
    }

    if (this.confChange.isMinDateToday) {
      if (!this.minDate) {
        this.minDate = new Date(Date.now());
      }
    } else if (this.confChange.isMinDateTomorrow) {
      if (!this.minDate) {
        const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        this.minDate = tomorrow;
      }
    }
    console.log("min year 1");
    if (this.confChange.minYear) {
      console.log("min year 2");
      if (this.minDate) {
        console.log("min year 3");
        this.minDate.setFullYear(this.confChange.minYear);
      } else {
        this.minDate = new Date();
        this.minDate.setMonth(0);
        this.minDate.setDate(1);
        this.minDate.setFullYear(this.confChange.minYear);
      }
    }
    if (this.confChange.isMaxDateToday) {
      this.maxDate = new Date(Date.now());
    } else if (this.confChange.maxYear) {
      const d = new Date();
      d.setMonth(11);
      d.setDate(31);
      d.setFullYear(this.confChange.maxYear);
      this.maxDate = d;
    }


    this.bphChange.getValue = function() {
      return this.bphChange.value;

    };

    /*
    this.confChange.dateselected = function (date, currentValue) {
      currentValue.value = date;
      currentValue.sort_date = this.globalComponent.useOrderDateReplaceRegex(date);
    };
    */
    this.confChange.initValue = function(currentValue, localConf) {
      currentValue.value = '';
      currentValue.error = {};
      currentValue.getValue = function() {
        return this.bphChange.value;
      };
    };

    /*  if (!this.bphChange.globalReadOnlyMode) {
        console.log('third');
        this.bphChange.value = "";
      }*/

  }

  // addEvent(type, event) {
  //   this.emitOutputEvent.emit(event);
  // }


  ngAfterViewInit(): void {
    this.getAllInputs();
    if (!(this.confChange.isReadOnlyField || this.bphChange.globalReadOnlyMode === 'true')) {
      this.removeGreyBackground();
    }
  }

  getAllInputs() {
    if (!this.isValidHtml) {
      setTimeout(() => {
        if (document.getElementById('td_' + this.confChange.name + '_' + this.rowNumber)) {
          const documentSelectField = document.getElementById('td_' + this.confChange.name + '_' + this.rowNumber).getElementsByClassName('mat-form-field-wrapper');
          const size = documentSelectField.length;
          for (let i = 0; i < size; i++) {
            documentSelectField.item(i).setAttribute('style', 'padding:0;');
          }
        }
      }, 100);
    }
  }

  removeGreyBackground() {
    setTimeout(() => {
      if (document.getElementById('td_' + this.confChange.name + '_' + this.rowNumber)) {
        const documentSelectField = document.getElementById('td_' + this.confChange.name + '_' + this.rowNumber).getElementsByClassName('mat-form-field-outline');
        const size = documentSelectField.length;
        for (let i = 0; i < size; i++) {
          documentSelectField.item(i).setAttribute('style', 'padding:0;');
        }
      }
      if (document.getElementById(this.selectedSectionId + '_' + this.confChange.name)) {
        const documentSelectField = document.getElementById(this.selectedSectionId + '_' + this.confChange.name).getElementsByClassName('mat-form-field-outline');
        const size = documentSelectField.length;
        for (let i = 0; i < size; i++) {
          documentSelectField.item(i).setAttribute('style', 'background-color:white !important;');
        }
      }
    }, 10);
  }


  changeValueEvent(event) {
    this.pendingChangesGuard.isPristine = false;
    this.refreshDataService.setNewValueForStaticText(this.confChange.name, this.bphChange.value);
    console.log('right here');
    console.log(event.target.value);
    this.bphChange.textValue = event.target.value;
    console.log(this.confChange);
  }

  addEvent(event) {
    if (moment.isMoment(this.bphChange.value)) {
      this.bphChange.textValue = moment(this.bphChange.value).utc().format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    } else {
      this.bphChange.textValue = this.bphChange.value ? this.bphChange.value.toString() : '';
    }
    console.log('ccccccccc');
    console.log(event);
    console.log(this.bphChange);
    this.pendingChangesGuard.isPristine = false;
    this.emitOutputEvent.emit(event);
  }


  getWidthFromMaxlength() {
    if (this.platform.ANDROID || this.platform.IOS) {
      return '100%';
    } else {
      return '25%';
    }
  }

}
