import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {PendingChangesGuard} from 'synto-common';


@Component({
  selector: 'fb-mat-slider',
  templateUrl: './fb-mat-slider.component.html',
  styleUrls: ['./fb-mat-slider.component.scss']
})

export class FbMatSliderComponent implements OnInit {
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() globalPrj: any;
  @Input() selectedLanguage;
  // minValueForStartMonthSlider = 1;
  thumbLabel = true;
  startDateStr = "";
  endDateStr = "";
  hasMonthLabel = false;
  monthLabel;

  // minValueForEndMonthSlider = 1;

  constructor(private translate: TranslateService,
              private translateService: TranslateService,
              private pendingChangesGuard: PendingChangesGuard,
              public dialog: MatDialog) {
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.error = {};
  }



  onInputChange(event) {

    this.bphChange.minValueForEndMonthSlider = event.value;
    this.bphChange.startMonthSliderInitialValue = event.value;
    this.bphChange.startMonthSliderInitialValueTextForPdf = this.hasMonthLabel ? this.confChange.monthLabel[event.value - 1] : this.bphChange.startMonthSliderInitialValue;
    console.log(this.bphChange.endMonthSliderInitialValue);

    if (event.value > 1 && this.bphChange.endMonthSliderInitialValue === 1) {
      console.log('aaaaaa');
      this.bphChange.endMonthSliderInitialValue = event.value;
      this.bphChange.endMonthSliderInitialValueTextForPdf = this.hasMonthLabel ? this.confChange.monthLabel[event.value - 1] : this.bphChange.endMonthSliderInitialValue;
    }
    this.pendingChangesGuard.isPristine = false;
  }

  onInputChangeOfEndMonthSlider(event) {
    console.log('sssssssss');
    console.log(event.value);
    this.bphChange.maxValueForStartMonthSlider = event.value;
    this.bphChange.endMonthSliderInitialValue = event.value;
    this.bphChange.endMonthSliderInitialValueTextForPdf = this.hasMonthLabel ? this.confChange.monthLabel[event.value - 1] : this.bphChange.endMonthSliderInitialValue;
    if (event.value < this.bphChange.startMonthSliderInitialValue) {
      this.bphChange.startMonthSliderInitialValue = 1;
      this.bphChange.startMonthSliderInitialValueTextForPdf = this.hasMonthLabel ? this.confChange.monthLabel[0] : this.bphChange.startMonthSliderInitialValue ;
    }
    this.pendingChangesGuard.isPristine = false;
  }


  openDialog(dataToDisplay) {
    if (typeof dataToDisplay === 'string') {
      const dummyString = dataToDisplay;
      dataToDisplay = {};
      this.translateService.langs.forEach((lang) => {
        dataToDisplay[lang] = dummyString;
      });
    }
    this.dialog.open(DialogComponent, {
      width: '600px',
      data:
        {
          dialog: dataToDisplay,
          selectedLanguage: this.selectedLanguage
        }
    });
  }


  ngOnInit() {
    console.log('this is conf change');
    console.log(this.confChange);
    console.log('this. is bphchange');
    console.log(this.bphChange);
    let customMaxArray;
    for (const key in this.globalPrj.task_schedule) {
      if (key === this.confChange.selectedContainerName) {
        customMaxArray = this.globalPrj.task_schedule[key]["monthArray"];
        if(this.globalPrj.task_schedule[key].customLabelList){
          this.monthLabel = this.globalPrj.task_schedule[key].customLabelList
          this.hasMonthLabel = true;
        }
      }
    }
    this.confChange["monthLabel"] = this.monthLabel;
    this.confChange["hasMonthLabel"] = this.hasMonthLabel;
    if (!(this.bphChange)) {
      this.bphChange = {};
      this.bphChange.value = '';
      this.bphChange.error = {};
      this.bphChange.source = {};
    } else {
      if (!this.bphChange.value) {
        this.bphChange.value = '';
      }
      if (!this.bphChange.error) {
        this.bphChange.error = {};
      }
      if (!this.bphChange.source) {
        this.bphChange.source = {};
      }
    }
    if (!this.bphChange.maxValueForStartMonthSlider) {
      this.bphChange.maxValueForStartMonthSlider = 12;
      if(customMaxArray){
        this.bphChange.maxValueForStartMonthSlider = customMaxArray.length;
      }

    }

    if (!this.bphChange.maxValueForEndMonthSlider) {
      this.bphChange.maxValueForEndMonthSlider = 12;
      if(customMaxArray){
        this.bphChange.maxValueForEndMonthSlider = customMaxArray.length;
      }
    }

    if (!this.bphChange.startMonthSliderInitialValue) {
      this.bphChange.startMonthSliderInitialValue = 1;
      this.bphChange.startMonthSliderInitialValueTextForPdf = this.hasMonthLabel ? this.confChange.monthLabel[0] : this.bphChange.startMonthSliderInitialValue;
    }

    if (!this.bphChange.endMonthSliderInitialValue) {
      this.bphChange.endMonthSliderInitialValue = 1;
      this.bphChange.endMonthSliderInitialValueTextForPdf = this.hasMonthLabel ?  this.confChange.monthLabel[0] : this.bphChange.endMonthSliderInitialValue;
    }

    if (!this.bphChange.minValueForStartMonthSlider) {
      this.bphChange.minValueForStartMonthSlider = 1;
    }

    if (!this.bphChange.minValueForEndMonthSlider) {
      this.bphChange.minValueForEndMonthSlider = 1;
    }

    const prj = this.globalPrj;
    if (this.confChange.selectedContainerType === 'SchedulerTable' && this.confChange.schedulerTableMultiyearPath) {
      if (eval(this.confChange.schedulerTableMultiyearPath) !== '') {
        this.bphChange.maxValueForStartMonthSlider = eval(this.confChange.schedulerTableMultiyearPath);
        this.bphChange.maxValueForEndMonthSlider = eval(this.confChange.schedulerTableMultiyearPath);
      } else {
        this.bphChange.maxValueForStartMonthSlider = 12;
        this.bphChange.maxValueForEndMonthSlider = 12;
        if(customMaxArray){
          this.bphChange.maxValueForStartMonthSlider = customMaxArray.length;
          this.bphChange.maxValueForEndMonthSlider = customMaxArray.length;
        }

      }
    }
    if (this.bphChange.endMonthSliderInitialValue > 1) {
      this.bphChange.maxValueForStartMonthSlider = this.bphChange.endMonthSliderInitialValue - 1;
    }

    /* if(this.bphChange.endMonthSliderInitialValue > 1){
      this.bphChange.maxValueForStartMonthSlider = this.bphChange.minValueForEndMonthSlider - 1;
    }*/
    for (const key in prj.task_schedule) {

      if (key === this.confChange.selectedContainerName) {
        for (const row of prj.task_schedule[key].rowsForSchedulerTable) {
          if (row.userSelectedStartMonth === 0 && row.userSelectedMaxMonth === 0 && row.rowIndex === prj.task_schedule.schedulertable_task_schedule.selectedRowIndex) {
            this.bphChange.endMonthSliderInitialValue = 1;
            this.bphChange.startMonthSliderInitialValue = 1;
            this.bphChange.startMonthSliderInitialValueTextForPdf = this.hasMonthLabel ?  this.confChange.monthLabel[0] : this.bphChange.startMonthSliderInitialValue ;
            this.bphChange.endMonthSliderInitialValueTextForPdf = this.hasMonthLabel ?  this.confChange.monthLabel[0] : this.bphChange.endMonthSliderInitialValue;
          }
        }
      }
    }
    /*this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;
    });*/
  }

}
