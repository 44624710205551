import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {
  eCaseAnimations,
  ECaseDateFormatterPipe,
  ECaseNumberFormatterPipe,
  ECaseUtils,
  LovDataService,
  ParametersService
} from 'synto-common';
import {Subscription} from 'rxjs/internal/Subscription';


/**
 * Created by sahilb@evision.ca on 2020-02-19.
 */
@Component({
  selector: 'ecase-treetable',
  templateUrl: './treetable.component.html',
  styleUrls: ['./treetable.component.scss'],
  animations: eCaseAnimations
})
export class TreeTableComponent implements OnInit, OnDestroy {


  @Input() displayedColumns: any[];
  @Input() displayedColumnsProperties: any[];
  @Input() displayedColumnsI18nCodes: any[];
  @Input() isActionsEnabled: boolean;
  @Input() actions: any[];
  @Output() editRowEvent = new EventEmitter<any>();
  @Output() addRowEvent = new EventEmitter<any>();
  @Output() rowMoveEvent = new EventEmitter<any>();
  @Output() actionTriggerEvent = new EventEmitter<any>();
  @Output() checkBoxChangeEvent = new EventEmitter<any>();
  @Output() hyperLinkClickEvent = new EventEmitter<any>();
  @Output() inputChangeClickEvent = new EventEmitter<any>();
  selectedLanguage = '';
  translateSubscription: Subscription;
  yesNoList = [];
  yesValue = '';

  constructor(private translate: TranslateService, private lovDataService: LovDataService, private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe,
              private parametersService: ParametersService, private eCaseDateFormatterPipe: ECaseDateFormatterPipe,) {
  }

  _dataSource: any[];

  get dataSource(): any[] {
    return this._dataSource;
  }

  @Input() set dataSource(dataSource: any[]) {
    this._dataSource = ECaseUtils.processDataSourceForTreeTable(dataSource, 0);
  }

  toggleChildren(row) {
    row.isChildrenVisible = !row.isChildrenVisible;
  }

  ngOnInit() {
    this.selectedLanguage = this.translate.getDefaultLang();
    this.translateSubscription = this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;
      if (this.yesNoList && this.yesNoList.length > 0) {
        this.yesValue = this.yesNoList[0].label[this.selectedLanguage];
      }
    });
    if (!this._dataSource) {
      this._dataSource = [];
    }
    if (!this.displayedColumns) {
      this.displayedColumns = [];
    }
    if (!this.displayedColumnsI18nCodes) {
      this.displayedColumnsI18nCodes = [];
    }
    if (!this.displayedColumnsProperties) {
      this.displayedColumnsProperties = [];
    } else {
      this.lovDataService.getAllTermsForSelectedTaxonomy('1').subscribe((response: any) => {
        this.yesNoList = response.data.allTerms.filter(item => item.id === 1);
        this.yesValue = this.yesNoList[0].label[this.selectedLanguage];
      });
    }
    if (!this.actions) {
      this.actions = [];
    }
    if (!this.isActionsEnabled) {
      this.isActionsEnabled = false;
    }
  }


  moveRow(list, index, moveByIndex, row) {
    const y = list[index];
    list[index] = list[index + moveByIndex];
    list[index + moveByIndex] = y;
    const obj: any = {};
    obj.row = row;
    obj.moveByIndex = moveByIndex;
    obj.currentIndex = index;
    obj.list = list;
    this.rowMoveEvent.emit(obj);
  }

  editRow(row) {
    this.editRowEvent.emit(row);
  }

  addRow(row) {
    this.addRowEvent.emit(row);
  }

  isObject(obj) {
    return (obj instanceof Object && obj !== null);
  }

  isNumeric(obj) {
    return !isNaN(obj);
  }

  triggerAction(row, action, index) {
    const obj: any = {};
    obj.row = row;
    obj.action = action;
    obj.currentIndex = index;
    this.actionTriggerEvent.emit(obj);
  }

  onCheckBoxChange(event, rowIndex, columnName, row) {
    const obj: any = {};
    if (this.displayedColumnsProperties.filter(item => item.columnName === columnName && item['isCheckBoxEnabled']).length > 0 &&
      (this.displayedColumnsProperties.filter(item => item.columnName === columnName && item['isCheckBoxEnabled'])[0])['propertyToBeUpdated']) {
      row[(this.displayedColumnsProperties.filter(item => item.columnName === columnName && item['isCheckBoxEnabled'])[0])['propertyToBeUpdated']] = event.checked;
    }
    obj.row = row;
    obj.columnName = columnName;
    obj.checked = event.checked;
    obj.currentIndex = rowIndex;
    this.checkBoxChangeEvent.emit(obj);
  }

  onInputFieldChange(event, rowIndex, columnName, row) {
    if (event.target.value.length > this.getDisplayedColumnsPropertyValue(columnName, 'maxLength')) {
      row[columnName] = event.target.value.slice(0, this.getDisplayedColumnsPropertyValue(columnName, 'maxLength'));
    } else {
      const obj: any = {};
      if (this.displayedColumnsProperties.filter(item => item.columnName === columnName && item['isTextField']).length > 0 &&
        (this.displayedColumnsProperties.filter(item => item.columnName === columnName && item['isTextField'])[0])['propertyToBeUpdated']) {
        row[(this.displayedColumnsProperties.filter(item => item.columnName === columnName && item['isTextField'])[0])['propertyToBeUpdated']] = event.target.value;
      }
      obj.row = row;
      obj.columnName = columnName;
      obj.newValue = event.target.value;
      obj.currentIndex = rowIndex;
      this.inputChangeClickEvent.emit(obj);
    }
  }

  getDisplayedColumnsPropertyValue(columnName, property) {
    if (this.displayedColumnsProperties &&
      this.displayedColumnsProperties.length > 0 &&
      this.displayedColumnsProperties.filter(item => item.columnName === columnName && item[property]).length > 0) {
      return this.displayedColumnsProperties.filter(item => item.columnName === columnName && item[property])[0][property];
    }
  }

  getMaxLengthForInputField(columnName, row): number {
    if (this.displayedColumnsProperties.filter(item => item.columnName === columnName && item['isTextField']).length > 0 &&
      (this.displayedColumnsProperties.filter(item => item.columnName === columnName && item['isTextField'])[0])['propertyToBeUpdated']) {
      return row[(this.displayedColumnsProperties.filter(item => item.columnName === columnName && item['isTextField'])[0])['propertyToBeUpdated']] ? row[(this.displayedColumnsProperties.filter(item => item.columnName === columnName && item['isTextField'])[0])['propertyToBeUpdated']].toString().length : 0;
    } else {
      return 0;
    }
  }

  onHyperLinkClick(rowIndex, columnName, row) {
    const obj: any = {};
    obj.row = row;
    obj.columnName = columnName;
    obj.currentIndex = rowIndex;
    this.hyperLinkClickEvent.emit(obj);
  }


  isPropertyEnabled(columnName, property): boolean {
    return (this.displayedColumnsProperties &&
      this.displayedColumnsProperties.length > 0 &&
      this.displayedColumnsProperties.filter(item => item.columnName === columnName && item[property]).length > 0);
  }

  isShownInChildrenOnly(columnName, property, row): boolean {
    return (this.displayedColumnsProperties &&
      this.displayedColumnsProperties.length > 0 &&
      this.displayedColumnsProperties.filter(item => item.columnName === columnName && item[property]).length > 0) &&
      row.children.length === 0;
  }

  ngOnDestroy(): void {
    this.translateSubscription.unsubscribe();
  }

  convertToDate(value, columnName) {
    if (this.displayedColumnsProperties &&
      this.displayedColumnsProperties.length > 0 &&
      this.displayedColumnsProperties.filter(item => item.columnName === columnName && item.isDateField).length > 0) {
      const format = this.displayedColumnsProperties.filter(item => item.columnName === columnName && item.isDateField)[0].dateFormat;
      return this.eCaseDateFormatterPipe.transform(value, format);
    } else {
      return value;
    }
  }

  getDataFormat(columnName) {
    const format =  this.displayedColumnsProperties.filter(item => item.columnName === columnName && item.isDateField)[0].key ;
    return '(' + ECaseUtils.getTranslatedValueFromKey(this.translate,format,this.selectedLanguage)+ ')';
   // return "(" + this.displayedColumnsProperties.filter(item => item.columnName === columnName && item.isDateField)[0].dateFormat.toLowerCase() + ")";
  }

  convertNumberToNumericFormat(value, columnName) {
    if (this.displayedColumnsProperties &&
      this.displayedColumnsProperties.length > 0 &&
      this.displayedColumnsProperties.filter(item => item.columnName === columnName && item.isCurrency).length > 0) {
      return this.eCaseNumberFormatterPipe.transform(value, true);
    } else {
      return this.eCaseNumberFormatterPipe.transform(value);
    }
  }
}
