import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  AdminCrmService,
  CrmPersonSearchService,
  eCaseAnimations,
  ECaseDataTable,
  ECaseDateFormatterPipe,
  EcaseHttpService,
  ECaseSnackBarService,
  ECaseTranslationLoaderService,
  ECaseUtils,
  ECaseUtilsGlobal,
  LovDataService,
  ParametersService
} from 'synto-common';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {map} from 'rxjs/operators';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs/internal/Observable';
import {SelectionModel} from '@angular/cdk/collections';
import cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'app-crm-person-search',
  templateUrl: './crm-person-search.component.html',
  styleUrls: ['./crm-person-search.component.scss'],
  animations: eCaseAnimations
})
export class CrmPersonSearchComponent extends ECaseDataTable implements OnInit, OnDestroy {

  @Output() onSectionComplete = new EventEmitter<any>();
  @Output() onBackPressed = new EventEmitter<any>();
  @Input() isOrganizationPermissionRestricted;
  @Input() onlySSOUsers = false;
  searchCrmPersonForm: FormGroup;
  structureClassificationLov = [];
  crmPersonDataSource: any[];
  roleList = [];
  rolesList = [];
  crmOrganizationList = [];
  statusList = [];
  countryLov = [];
  provinceLov = [];
  positionLov = [];
  allProgramsList: any[] = [];
  competitionList: any[] = [];
  originalCompetitionList: any[] = [];
  selectedLang = 'en';
  disableSearchBtn = true;
  displayForm = false;
  displayedColumns = ['select', 'fullName', 'mainAffiliationOrganizationLabel', 'username', 'lastUpdateDate', 'lastUpdateBy'];
  sortActive = 'fullName';
  sortDirection = 'asc';
  filterObject = {
    fullName: '',
    mainAffiliationOrganizationLabel: '',
    username: '',
    lastUpdateDateFormatted: '',
    lastUpdateBy: ''
  };
  filteredOptions: Observable<string[]>;
  filteredOptionsForProgram: Observable<string[]>;
  displayResults = false;
  provinceArray = [];
  columns = [];
  subscription;
  maxResultLimit;

  isSearch = false;
  usersSelected = [];
  isLoading = true;

  public selection = new SelectionModel<any>(true, []);

  constructor(translate: TranslateService,
              private eCaseHttpService: EcaseHttpService,
              private eCaseSnackBarService: ECaseSnackBarService,
              parameterService: ParametersService,
              private lovDataService: LovDataService,
              private formBuilder: FormBuilder,
              public crmPersonSearchService: CrmPersonSearchService,
              public adminCrmService: AdminCrmService,
              public datePipe: ECaseDateFormatterPipe, private translationLoader: ECaseTranslationLoaderService) {

    super(translate, parameterService);
    this.serviceArray = this.crmPersonDataSource;
    this.originalArray = this.crmPersonDataSource;
    this.selectedLang = this.translate.getDefaultLang();
    this.isSearch = this.crmPersonSearchService.isUserSearch;
  }

  /*    The combination of the sortColumnWithAccents() and renewDataSourceAccents() methods is used in order to
    *   handle tables with accented data.
 * */

  sortColumn(event: any, childPath?: string): void {
    this.dataSource.data = ECaseUtils.sortColumn(event, this.dataSource.data, this.translate, childPath);
    this.renewDataSource(this.dataSource.data);
  }

  renewDataSource(newDataArray): void {
    this.dataSource = new MatTableDataSource(newDataArray);
    this.sortingDataAccessorWithIgnoreCase();
    this.dataSource.paginator = this.paginator.toArray()[0];
  }

  ngOnInit(): void {
    this.translationLoader.loadTranslationsForTaxonomyWithObservable([1]).subscribe(()=> {

      this.crmPersonSearchService.selectedUsers = [];
      this.maxResultLimit = +(this.parameterService.parameter['/core/ui/max_search_results_number']);
      ECaseUtils.scrollToTop('.content-wrapper');
      this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
        this.selectedLang = params.lang;
      });

      const obj = {};
      this.getAllCompetitionWithProgramId(-1);
      this.lovDataService.getAllTermsWithSequence(
        [ECaseUtilsGlobal.STRUCTURE_CLASSIFICATION_TAXONOMY_ID,
          Number(ECaseUtilsGlobal.COUNTRY_TAXONOMY_ID),
          Number(ECaseUtilsGlobal.APPLICATION_STATUS_TAXONOMY_ID),
          Number(ECaseUtilsGlobal.PROVINCE_TAXONOMY_ID), 21]).subscribe((result: any) => {
        // this.adminCrmService.getRoleListForCrmPerson().subscribe((response: any) => {
        this.adminCrmService.getRoleListForSsoUser().subscribe((response: any) => {
          this.adminCrmService.getCrmOrgListForCrmPersonSearch().subscribe((_response: any) => {
            this.getAllProgramsWithoutRestriction().subscribe((innerReponse: any) => {
              this.isLoading = false;
              if (innerReponse.status && response.status && _response.status) {
                this.rolesList = response.roles;
                this.allProgramsList = innerReponse.data;
                this.crmOrganizationList = this.sortListAlphabetically(_response.organizations);
              }
              if (result.hasOwnProperty('status') && !result.status) {
                this.eCaseSnackBarService.show('failure');
              } else {
                this.rolesList = this.rolesList.sort((a, b) => {
                  const x = (a.ssoRoleLabel[this.selectedLang]).toLowerCase();
                  const y = (b.ssoRoleLabel[this.selectedLang]).toLowerCase();
                  return (a.ssoRoleLabel[this.selectedLang]).toLowerCase().localeCompare((b.ssoRoleLabel[this.selectedLang]).toLowerCase());
                });

                this.countryLov = ECaseUtils.sortLovArrayAlphabeticallyAndBySortingKey((result.data
                  .filter(item => item.taxonomyId === Number(ECaseUtilsGlobal.COUNTRY_TAXONOMY_ID)))[0].terms, this.selectedLang);
                this.statusList = (result.data
                  .filter(item => item.taxonomyId === Number(ECaseUtilsGlobal.APPLICATION_STATUS_TAXONOMY_ID)))[0].terms
                  .sort((a, b) => {
                    const x = ((a && a.value) ? a.value[this.selectedLang] : '').toLowerCase();
                    const y = ((b && b.value) ? b.value[this.selectedLang] : '').toLowerCase();
                    return x.localeCompare(y);
                  });
                this.provinceLov = this.sortLovArrayAlphabetically((result.data
                  .filter(item => item.taxonomyId === Number(ECaseUtilsGlobal.PROVINCE_TAXONOMY_ID)))[0].terms, this.selectedLang);
                this.structureClassificationLov = (result.data.filter(item => item.taxonomyId === Number(ECaseUtilsGlobal.STRUCTURE_CLASSIFICATION_TAXONOMY_ID)))[0].terms;
                this.positionLov = (result.data.filter(item => item.taxonomyId === 21))[0].terms;
                this.provinceArray = this.provinceLov;
                obj['name'] = [''];
                obj['role'] = [''];
                obj['position'] = [''];
                obj['email'] = [''];
                obj['applicationNumber'] = [''];
                obj['organization'] = [''];
                obj['program'] = [''];
                obj['competition'] = [''];
                obj['phoneNumber'] = [''];
                obj['city'] = [''];
                obj['status'] = [''];
                const classificationObj = {};
                this.structureClassificationLov.map((item: any) => {
                  classificationObj[item.id] = new FormControl(false);
                });
                const rolesObj = {};
                this.roleList.map((item: any) => {
                  rolesObj[item.ssoRoleId] = new FormControl(false);
                });
                obj['classification'] = new FormGroup(classificationObj);
                obj['roles'] = new FormGroup(rolesObj);
                obj['country'] = [''];
                obj['province'] = [''];
                obj['isArchived'] = [''];

                this.searchCrmPersonForm = this.formBuilder.group(obj);
                this.displayForm = true;
              }

              this.filteredOptions = this.searchCrmPersonForm.controls['organization'].valueChanges
                .pipe(
                  map((value) => {
                    if (typeof value === 'string') {
                      return this._filter(value);
                    }
                  })
                );

              this.filteredOptionsForProgram = this.searchCrmPersonForm.controls['program'].valueChanges
                .pipe(
                  map((value) => {
                    if (typeof value === 'string') {
                      return this._filterForProgram(value);
                    }
                  })
                );


              this.searchCrmPersonForm.valueChanges.subscribe((formChangeEvent: any) => {
                let isFormEmpty = true;
                for (const key in formChangeEvent) {
                  if (formChangeEvent.hasOwnProperty(key) &&
                    this.searchCrmPersonForm.get(key) &&
                    this.searchCrmPersonForm.get(key).value != null &&
                    this.searchCrmPersonForm.get(key).value !== '' &&
                    this.searchCrmPersonForm.get(key).value !== false) {
                    isFormEmpty = false;
                    break;
                  }
                }
                this.disableSearchBtn = isFormEmpty;
              });
              this.searchCrmPersonForm.controls['isArchived'].patchValue('N');
              this.searchCrmPersonForm.markAsDirty();

              if (this.crmPersonSearchService.preserveDataSource) {
                this.searchCrmPersonForm = this.crmPersonSearchService.preserveFormData;
                this.filterObject = this.crmPersonSearchService.preserveFilterObject;
                this.crmPersonDataSource = this.crmPersonSearchService.dataSourceCrmPerson;
                this.serviceArray = this.crmPersonDataSource;
                this.originalArray = this.crmPersonDataSource;
                this.dataSource = new MatTableDataSource(this.crmPersonDataSource);
                this.sortColumn({active: this.sortActive, direction: this.sortDirection});
                this.applyFilter();
                this.displayResults = true;
              }

              this.crmPersonSearchService.preserveDataSource = false;
            });
          });
        });
      });
    });
  }

  // Methods for organization list auto-complete
  displayFn(user): string | undefined {
    const label = this.crmOrganizationList.filter(item => item.crmOrganizationId === user).map(item => item.crmOrganizationLabel[this.selectedLang])[0];
    return label ? label : undefined;
  }

  // Methods for program list auto-complete
  displayFnForProgram(user): string | undefined {
    const label = this.allProgramsList.filter(item => item.id === user).map(item => item.programFullName[this.selectedLang])[0];
    return label ? label : undefined;
  }

  onCountryChange(event): void {
    if (event.value) {
      this.provinceArray = this.lovDataService.sortLovArrayAlphabetically(this.provinceLov.filter(item => item.parentTermId === event.value), this.selectedLang);
    } else {
      this.provinceArray = this.provinceLov;
    }
  }

  onProvinceChange(event): void {
    if (event.value) {
      this.searchCrmPersonForm.get('country').patchValue(this.provinceArray.filter(item => (item.id === event.value))[0].parentTermId);
    }
  }

  searchCrmPerson(): void {
    const orgSearch: any = {};

    orgSearch.name = this.searchCrmPersonForm.get('name').value ? this.searchCrmPersonForm.get('name').value : undefined;
    orgSearch.position = this.searchCrmPersonForm.get('position').value ? this.searchCrmPersonForm.get('position').value : undefined;
    orgSearch.emailAddress = this.searchCrmPersonForm.get('email').value ? this.searchCrmPersonForm.get('email').value : undefined;
    orgSearch.applicationNumber = this.searchCrmPersonForm.get('applicationNumber').value ? +(this.searchCrmPersonForm.get('applicationNumber').value) : undefined;
    orgSearch.phone = this.searchCrmPersonForm.get('phoneNumber').value ? (this.searchCrmPersonForm.get('phoneNumber').value).toString() : undefined;
    orgSearch.city = this.searchCrmPersonForm.get('city').value ? this.searchCrmPersonForm.get('city').value : undefined;
    orgSearch.organizationClassifications = this.searchCrmPersonForm.get('classification').value ? this.searchCrmPersonForm.get('classification').value : undefined;
    orgSearch.status = this.searchCrmPersonForm.get('status').value ? this.searchCrmPersonForm.get('status').value : undefined;
    // orgSearch.roles = this.searchCrmPersonForm.get('roles').value ? (this.searchCrmPersonForm.get('roles').value) : undefined;
    orgSearch.country = this.searchCrmPersonForm.get('country').value ? (this.searchCrmPersonForm.get('country').value) : undefined;
    orgSearch.organization = this.searchCrmPersonForm.get('organization').value ? (this.searchCrmPersonForm.get('organization').value) : undefined;
    orgSearch.province = this.searchCrmPersonForm.get('province').value ? (this.searchCrmPersonForm.get('province').value) : undefined;
    orgSearch.programId = this.searchCrmPersonForm.get('program').value ? (this.searchCrmPersonForm.get('program').value) : undefined;
    orgSearch.competitionId = this.searchCrmPersonForm.get('competition').value ? (this.searchCrmPersonForm.get('competition').value) : undefined;
    orgSearch.isArchived = this.searchCrmPersonForm.get('isArchived').value && this.searchCrmPersonForm.get('isArchived').value ? (this
      .searchCrmPersonForm.get('isArchived').value) : undefined;
    orgSearch.onlySSOUsers = this.onlySSOUsers;
    orgSearch.selectedLanguage = this.selectedLang;

    const arrayOfClassificationIds: any[] = [];
    const arrayOfRoleIds: any[] = [];
    for (const key in orgSearch.organizationClassifications) {
      if (orgSearch.organizationClassifications.hasOwnProperty(key) && orgSearch.organizationClassifications[key] === true) {
        arrayOfClassificationIds.push(+(key));
      }
    }

    // for (const key in orgSearch.roles) {
    //   if (orgSearch.roles[key] === true) {
    //     arrayOfRoleIds.push(+(key))
    //   }
    // }
    const rol = this.searchCrmPersonForm.get('role').value ? this.searchCrmPersonForm.get('role').value : undefined;
    if (rol) {
      arrayOfRoleIds.push(rol);
    }
    orgSearch.organizationClassifications = arrayOfClassificationIds;
    orgSearch.roles = arrayOfRoleIds;

    const searchMethod =  !this.isOrganizationPermissionRestricted ? this.crmPersonSearchService.searchCrmPerson(orgSearch) :
      this.crmPersonSearchService.searchCrmPersonWithOrganizationPermissionRestricted(orgSearch);
    searchMethod.subscribe((response: any) => {
      if (response.hasOwnProperty('status') && !response.status) {
        let message = "";
        if(response.message && response.message !== "") {
          message = ECaseUtils.getTranslatedValueFromKey(this.translate, response.message);
        }
        this.eCaseSnackBarService.show('failure', message);
      } else {
        if (response.persons.length > 0 && response.persons.length > this.maxResultLimit) {
          this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.refineSearchMsg'));
        } else {

          this.displayResults = true;
          response.persons = response.persons.map((item) => {
            item.lastUpdateDateFormatted = this.datePipe.transform(item.lastUpdateDate, this.syntoDate);
            item.selected = false;
            return item;
          });

          this.crmPersonDataSource = response.persons;
          this.serviceArray = response.persons;
          this.originalArray = response.persons;
          this.dataSource = new MatTableDataSource(this.crmPersonDataSource);
          this.sortColumn({active: this.sortActive, direction: this.sortDirection});
          setTimeout(() => {
            const e = document.getElementById('crm-search-id-for-toggling');
            e.scrollIntoView();
          }, 300);
        }
      }
    });
  }

  getAllProgramsWithoutRestriction(): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllProgramsWithoutRestriction');
  }

  getAllCompetitionWithProgramId(id): void {
    this.eCaseHttpService.post('/api/getAllCompetitionWithProgramIdWithoutRestriction', {programId: id}).subscribe((response: any) => {
      if (response.status) {
        this.competitionList = response.data;
        this.competitionList = this.competitionList.sort((a, b) => {
          const x = (a.competitionFullName[this.selectedLang] === null || a.competitionFullName[this.selectedLang] === undefined) ? '' : (a
            .competitionFullName[this.selectedLang]).toLowerCase();
          const y = (b.competitionFullName[this.selectedLang] === null || b.competitionFullName[this.selectedLang] === undefined) ? '' : (b
            .competitionFullName[this.selectedLang]).toLowerCase();
          return x.localeCompare(y);
        });
        this.originalCompetitionList = cloneDeep(this.competitionList);
      }
    });
  }

  filterCompetitions(id): void {
    this.competitionList = this.originalCompetitionList.filter(item => item.programId === id);
  }

  clear(): void {
    this.provinceArray = this.provinceLov;
    this.competitionList = this.originalCompetitionList;
    this.searchCrmPersonForm.reset();
    this.originalArray = [];
    this.resetFilters(true);
    this.dataSource = new MatTableDataSource(this.originalArray);
    this.displayResults = false;
  }

  onItemSelected(event, row: any): void {
    if (event.checked) {
      this.usersSelected.push(row);
      row.selected = true;
    } else {
      const index = this.usersSelected.indexOf(row);
      if (index >= 0) {
        this.usersSelected.splice(index, 1);
      }
    }
  }

  addPeople(): void {
    if (this.usersSelected.length > 0) {
      this.usersSelected.forEach((itemTemp) => {
        let itExists = false;
        this.crmPersonSearchService.selectedUsers.forEach((itemPerm) => {
          if (itemTemp.crmPersonId === itemPerm.id) {
            itExists = true;
            return;
          }
        });
        if (!itExists) {
          const obj: any = {
            id: itemTemp.crmPersonId,
            ssoUserId: itemTemp.ssoUserId,
            username: itemTemp.username,
            fullName: itemTemp.fullName,
            lastName: itemTemp.lastName,
            firstName: itemTemp.firstName
          };
          this.crmPersonSearchService.selectedUsers.push(obj);
        }
      });
    }
    this.back();
    this.onSectionComplete.emit(this.crmPersonSearchService.selectedUsers);
  }

  back(): void {
    this.crmPersonSearchService.changeShowUserSearchFlag(false);
    this.isSearch = false;
    this.onBackPressed.emit(true);
  }

  masterToggle(): void {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    this.usersSelected = [];
    if (numSelected > numRows / 2) {
      this.selection.clear();
      this.dataSource.data.forEach((row) => {
        row.selected = false;
      });
    } else {
      this.dataSource.data.forEach((row) => {
        this.usersSelected.push(row);
        row.selected = true;
        this.selection.select(row);
      });
    }
  }

  sortListAlphabetically(sortingArray): any {
    sortingArray = sortingArray.sort((a, b) => {
      a.crmOrganizationLabel[this.selectedLang] = (a.crmOrganizationLabel[this.selectedLang] === null || a.crmOrganizationLabel[this.selectedLang] === undefined) ? '' : a
        .crmOrganizationLabel[this.selectedLang];
      b.crmOrganizationLabel[this.selectedLang] = (b.crmOrganizationLabel[this.selectedLang] === null || b.crmOrganizationLabel[this.selectedLang] === undefined) ? '' : b
        .crmOrganizationLabel[this.selectedLang];
      return (a.crmOrganizationLabel[this.selectedLang]).toLowerCase().localeCompare((b.crmOrganizationLabel[this.selectedLang]).toLowerCase());
    });
    return sortingArray;
  }

  /**
   * METHOD COPIED FROM ADMIN-CRM.SERVICE.
   THE METHOD WITH SAME NAME IN LOVSERVICE DOESN'T WORK FOR THESE LISTS
   */
  sortLovArrayAlphabetically(sortingArray, selectedLang): any[] {
    sortingArray = sortingArray.sort((a, b) => {
      let result = 0;
      try {
        a.value[selectedLang] = a.value[selectedLang] === null ? '' : a.value[selectedLang];
        b.value[selectedLang] = b.value[selectedLang] === null ? '' : b.value[selectedLang];
        result = (a.value[selectedLang]).toLowerCase().localeCompare((b.value[selectedLang]).toLowerCase());
      } catch (e) {
        console.error(e);
      }
      return result;
    });
    return sortingArray;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.crmOrganizationList.filter((option: any) => {
      if (value.length > 2 && option.crmOrganizationLabel[this.selectedLang]) {
        return option.crmOrganizationLabel[this.selectedLang].toLowerCase().includes(filterValue);
      }
    });
  }

  private _filterForProgram(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allProgramsList.filter((option: any) => {
      if (value.length > 2 && option.programFullName[this.selectedLang]) {
        return option.programFullName[this.selectedLang].toLowerCase().includes(filterValue);
      }
    }).sort((a, b) => {
      const x = (a.programFullName[this.selectedLang] === null || a.programFullName[this.selectedLang] === undefined) ? '' : (a.programFullName[this.selectedLang]).toLowerCase();
      const y = (b.programFullName[this.selectedLang] === null || b.programFullName[this.selectedLang] === undefined) ? '' : (b.programFullName[this.selectedLang]).toLowerCase();
      return x.localeCompare(y);
    });
  }


}
