import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter, HostBinding, Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from "@angular/forms";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {FocusMonitor} from "@angular/cdk/a11y";
import {Subject} from "rxjs/internal/Subject";
import _ from "lodash";
import {Subscription} from "rxjs/internal/Subscription";
import {DOCUMENT} from "@angular/common";
import {Observable} from "rxjs/internal/Observable";


@Component({
  selector: 'app-ecase-dynamic-multi-select-autocomplete-field',
  templateUrl: './ecase-dynamic-multi-select-autocomplete-field.component.html',
  styleUrls: ['./ecase-dynamic-multi-select-autocomplete-field.component.css']
})
export class EcaseDynamicMultiSelectAutocompleteFieldComponent implements OnInit, OnDestroy {
  static nextId = 0;

  @Input() selectPlaceholder ="ecase.common.table.filter.input.placeholder";
  @Input() placeholder: string;
  @Input() serviceUrl: string;
  @Input() labelObjName: string;
  @Input() labelIdName: string;
  @Input() responseObjName: string;
  @Input() selectedLang: string;
  @Input() additionalSearchObject = null;
  @Input() httpService;
  @Input() disabled = false;
  @Input() display = "display";
  @Input() clearFieldEventEmitter: Observable<Boolean>  ;
  formControlInput: FormControl
  @ViewChild('inputTrigger', { read: MatAutocompleteTrigger }) inputTrigger: MatAutocompleteTrigger;

  @Input() errorMsg = "Field is required";
  @Input() showErrorMsg = false;
  @Input() selectedOptions;
  isAllSelected: boolean = false;

  // New Options
  //@Input() labelCount = 1;
  @Input() appearance = "outline";

  @Output()
  selectionChange: EventEmitter<any> = new EventEmitter();
  selectable = true;
  removable = true;
  selectedItems: Array<any> = [];
  filteredOptions: Array<any> = [];
  selectedValue: Array<any> = [];
  selectAllChecked = false;
  displayString = "";
  focused = false;
  searchStringField = '';
  stateChanges = new Subject<void>();

  @HostBinding('attr.aria-describedby') describedBy = '';
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }
  constructor(
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
    this.filteredOptions.forEach((it) => it.selected = this.selectedOptions.includes(it[this.labelIdName]));
  }

  writeValue(value: any) {
    console.log(value, 'Inside writeValue')
  }

  ngOnInit() {
    this.clearFieldEventEmitter.subscribe( isClearFields => {
      if(isClearFields){
        this.clear();
      }
    });
  }

  clicker() {
    console.log(this.inputTrigger);
    this.inputTrigger.openPanel();
  }

  filter() {
    if(this.searchStringField.length > 2) {
      this.httpService.post(this.serviceUrl, {
        'searchString': this.searchStringField.toLowerCase(),
        'additionalSearch' : this.additionalSearchObject !== null ? this.additionalSearchObject : undefined
      }).subscribe((response) => {
        if(response.status) {
          const list = this.sortAlphabetically(response[this.responseObjName]);
          list.forEach(it => {
            it.id = it[this.labelIdName];
            it.selected = this.selectedItems.filter(itSelected => itSelected[this.labelIdName] === it[this.labelIdName]).length > 0;
          });
          this.filteredOptions = list;
        } else {
          this.filteredOptions = [];
        }
      });
    } else {
      this.filteredOptions = [];
    }
    console.log(this.filteredOptions);
  }

  sortAlphabetically(sortingArray): any[] {
    return sortingArray.sort((a, b) => {
      if(a[this.labelObjName] && a[this.labelObjName][this.selectedLang] &&
        b[this.labelObjName] && b[this.labelObjName][this.selectedLang]
      ) {
        const x = a[this.labelObjName][this.selectedLang] ? a[this.labelObjName][this.selectedLang] : '';
        const y = b[this.labelObjName][this.selectedLang] ? b[this.labelObjName][this.selectedLang] : '';
        if (a.sortingKey > b.sortingKey) {
          return 1;
        } else if (a.sortingKey < b.sortingKey) {
          return -1;
        } else {
          return x.localeCompare(y);
        }
      } else {
        return 1;
      }
    });
  }


  toggleSelectAll(){
    this.isAllSelected = !this.isAllSelected;



    if(this.isAllSelected) {
      this.filteredOptions.forEach(it => {
        const i = this.selectedItems.findIndex(value => value[this.labelIdName] === it[this.labelIdName]);
        if(i === -1) {
          this.selectedItems.push(it);
        }
      });
    } else {
      this.selectedItems = [];
    }
    this.filteredOptions.forEach(it => {
      it.selected = this.isAllSelected
    });
    setTimeout(() => {
      // @ts-ignore
      this.emptySearchValue();

      this.searchStringField = '';
      this.selectionChange.emit( this.selectedItems );
    }, 100);
  }


  toggleSelection(event) {
    console.log("=======> toggle", event);

    const i = this.selectedItems.findIndex(value => value[this.labelIdName] === event.option.value);
    if(i === -1) {
      this.selectedItems.push(this.filteredOptions.find(it => it[this.labelIdName] === event.option.value));
      this.filteredOptions.forEach((it) => {
        if(it[this.labelIdName] === event.option.value) {
          it.selected = true;
        }
      });
    } else {
      this.selectedItems.splice(i, 1);
      this.filteredOptions.forEach((it) => {
        if(it[this.labelIdName] === event.option.value) {
          it.selected = false;
        }
      });
    }
    this.searchStringField = '';
    this.emptySearchValue();
    this.selectionChange.emit(this.selectedItems);
  }

  remove(item: string): void {
    const index = this.selectedItems.indexOf(item);

    if (index >= 0) {
      this.selectedItems.splice(index, 1);
      this.filteredOptions.forEach((it) => {
        if(it[this.labelIdName] === item[this.labelIdName]) {
          it.selected = false;
        }
      });
      this.selectionChange.emit(this.selectedItems);
    }
  }

  ngOnDestroy() {
    this.fm.stopMonitoring(this.elRef.nativeElement);
    this.stateChanges.complete();
  }

  emptySearchValue() {
    document.querySelectorAll('.inputSearchDynamicAutoComplete').forEach((item, index) => {
      console.log(item, index);
      // @ts-ignore
      item.value = '';
    });
  }

  clear(){

    this.emptySearchValue();
    this.searchStringField = '';
    this.selectable = true;
    this.removable = true;
    this.selectedItems = [];
    this.filteredOptions = [];
    this.selectedValue = [];
    this.selectAllChecked = false;
    this.displayString = "";
    this.focused = false;
    this.searchStringField = '';
    this.selectionChange.emit( this.selectedItems);

  }


}
