import {Injectable} from '@angular/core';
import {GeneraldialogComponent} from 'synto-common';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class DialogService {

  constructor(public dialog: MatDialog) {
  }

  openDialog(data: string): Observable<any> {
    const dialogRef = this.dialog.open(GeneraldialogComponent, {
      width: '700px',
    });
    const instance = dialogRef.componentInstance;
    instance.data = data;
    return dialogRef.afterClosed();
  }
}
