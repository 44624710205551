import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from '../dialog/dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {
  EcaseHttpService,
  ECaseNumberFormatterPipe,
  ECaseSnackBarService,
  ECaseUtils,
  ECaseUtilsGlobal,
  PendingChangesGuard,
  RefreshDataService
} from 'synto-common';
import {Subscription} from 'rxjs/internal/Subscription';
import {DialogService} from '../../formbuilderForms/form/dialog.service';
import * as _ from 'lodash';
import {Platform} from '@angular/cdk/platform';


@Component({
  selector: 'fb-budget-t4',
  templateUrl: './fb-budget-t4.component.html',
  styleUrls: ['./fb-budget-t4.component.scss']
})
export class FbBudgetT4Component implements OnInit, OnDestroy {
  @Input() selectedLanguage: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() bphChange: any;
  @Input() globalPrj: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() selectedSectionId: string;
  @Input() formId;
  @Input() blockIndex: number;
  @Input() offlineModeEnabled;
  @Output() emitOutputEvent = new EventEmitter<any>();
  showDetails = false;
  selectedTableRowIndex = -1;
  copyBlockData: any;
  subscription: Subscription;
  globalComponent;
  topLevelBudgetItems = [];
  dataObjectForRecursiveSubCategorySelect: any = {};
  otherValue = {
    'otherValue': '',
    'label': {
      'en': 'Other',
      'fr': 'Autre'
    },
    'value': ECaseUtilsGlobal.OTHER_VALUE_TERM_ID,
    'sortingKey': ECaseUtilsGlobal.OTHER_VALUE_TERM_ID
  }; // Labels are added in ngOnInit method
  originalRowData = [];
  dialogText = {
    // Test Data
    '333670_8342' : {
      'en' : 'Grants awarded by a Quebec delegation must be registered with the Ministère des Relations internationales et de la Francophonie (MRIF).',
      'fr' : 'Les subventions accordées par une délégation du Québec doivent être inscrites au poste du ministère des Relations internationales et de la Francophonie (MRIF).'
    },
    '333668_8444' : {
      'en' : 'Grants awarded under the Summer Jobs program must be registered with Service Canada.',
      'fr' : 'Les subventions accordées dans le cadre du programme Emploi d\'été doivent être inscrites au poste de Service Canada.'
    },
    '333668_999999999' : {
      'en' : 'Grants awarded under a program administered by a Community Futures Development Corporation (SADC), a Business Assistance Center (CAE) or Musicaction must be entered under “Other – specify”.',
      'fr' : 'Les subventions accordées dans le cadre d’un programme administré par une Société d’aide au développement des collectivités (SADC), un Centre d’aide aux entreprises (CAE) ou Musicaction doivent être inscrites au poste « Autre – précisez ».'
    },
    '333672_' : {
      'en' : 'This section excludes discretionary assistance from MNAs and regional departments of departments, which must be recorded in Section 1 or Section 2. However, discretionary assistance from an elected municipal official must be entered under “Municipality – specify”.',
      'fr' : 'Cette section exclut les aides discrétionnaires des députés et des directions régionales des ministères qui doivent être comptabilisés à la section 1 ou à la section 2. Toutefois, une aide discrétionnaire d’un élu municipal doit être inscrite au poste « Municipalité – précisez ».'
    },
    '333671_' : {
      'en' : 'Monetary sponsorships granted by Hydro-Québec, Loto-Québec, or any other government corporation (eg SÉPAQ, Radio-Canada) are recorded under Sponsorships from government corporations.',
      'fr' : 'Les commandites monétaires octroyées par Hydro-Québec, Loto-Québec, ou toute autre société d\'État (ex. : SÉPAQ, Radio-Canada) s\'inscrivent au poste Commandites de sociétés d\'État.'
    },
    '333667_' : {
      'en' : 'Private sector sponsorships include all monetary contributions from private companies or other organizations received.<br><br>These can be for-profit organizations (OBL), non-profit organizations (NPO), or financial institutions (eg Desjardins).<br><br>This section excludes advertising revenues which are instead accounted for in section 7 C) “Other revenues”.',
      'fr' : 'Les commandites du Secteur privé regroupent l’ensemble des contributions monétaires d\'entreprises privées ou d’autres organismes reçues.<br><br>Il peut s’agir d’organismes à but lucratif (OBL), d’organismes à but non lucratif (OBNL), ou d’institutions financières (ex. : Desjardins).<br><br>Cette section exclut les revenus publicitaires qui sont plutôt comptabilisés dans la section 7 C) « Autres revenus ».'
    },
    '334131_' : {
      'en' : 'Goods and services sponsorships represent all exchanges of materials or services that are provided free of charge by a third party. They must be recorded in the financial statements when their fair value is reasonably estimated, when they are used in the ordinary course of the festival or event, and when they should otherwise have been purchased. <br><br>Synonyms are sometimes used such as contributions of goods and services, contracts of exchange, exchanges of goods and services.',
      'fr' : 'Les commandites en biens et services représentent l’ensemble des échanges de matériel ou de services qui sont fournis gratuitement par un tiers. Ils doivent être comptabilisés aux états financiers lorsque leur juste valeur fait l\'objet d\'une estimation raisonnable, qu’ils sont utilisés dans le cadre du fonctionnement courant du festival ou de l\'événement, et qu\'ils auraient dû autrement être achetés.<br><br>Des synonymes sont parfois employés, tels apports de biens et services, contrats d\'échange, échanges de biens et services.'
    },
    '334132_' : {
      'en' : 'This section reports on all the income generated by the activities of the festival or event (attendance, sales, financial income or other).',
      'fr' : 'Cette section fait état de l’ensemble des revenus générés par les activités du festival ou de l’événement (fréquentation, ventes, revenus financiers ou autres).'
    },
    '334133_' : {
      'en' : 'This section reports on all the income generated by the alternative activities put in place due to the health measures required by the COVID-19 pandemic.',
      'fr' : 'Cette section fait état de l\'ensemble des revenus générés par les activités alternatives mises en place en raison des mesures sanitaires exigées par la pandémie de la COVID-19.'
    },
    '334504_' : {
      'en' : 'This section reports on all the income generated by the alternative activities put in place due to the health measures required by the COVID-19 pandemic.',
      'fr' : 'Cette section fait état de l\'ensemble des revenus générés par les activités alternatives mises en place en raison des mesures sanitaires exigées par la pandémie de la COVID-19.'
    },
    // Prod Data
    '332418_4411' : {
      'en' : 'Grants awarded by a Quebec delegation must be registered with the Ministère des Relations internationales et de la Francophonie (MRIF).',
      'fr' : 'Les subventions accordées par une délégation du Québec doivent être inscrites au poste du ministère des Relations internationales et de la Francophonie (MRIF).'
    },
    '332416_4747' : {
      'en' : 'Grants awarded under the Summer Jobs program must be registered with Service Canada.',
      'fr' : 'Les subventions accordées dans le cadre du programme Emploi d\'été doivent être inscrites au poste de Service Canada.'
    },
    '332416_999999999' : {
      'en' : 'Grants awarded under a program administered by a Community Futures Development Corporation (SADC), a Business Assistance Center (CAE) or Musicaction must be entered under “Other – specify”.',
      'fr' : 'Les subventions accordées dans le cadre d’un programme administré par une Société d’aide au développement des collectivités (SADC), un Centre d’aide aux entreprises (CAE) ou Musicaction doivent être inscrites au poste « Autre – précisez ».'
    },
    '332420_' : {
      'en' : 'This section excludes discretionary assistance from MNAs and regional departments of departments, which must be recorded in Section 1 or Section 2. However, discretionary assistance from an elected municipal official must be entered under “Municipality – specify”.',
      'fr' : 'Cette section exclut les aides discrétionnaires des députés et des directions régionales des ministères qui doivent être comptabilisés à la section 1 ou à la section 2. Toutefois, une aide discrétionnaire d’un élu municipal doit être inscrite au poste « Municipalité – précisez ».'
    },
    '332419_' : {
      'en' : 'Monetary sponsorships granted by Hydro-Québec, Loto-Québec, or any other government corporation (e.g. SÉPAQ, Radio-Canada) are recorded under Sponsorships from government corporations.',
      'fr' : 'Les commandites monétaires octroyées par Hydro-Québec, Loto-Québec, ou toute autre société d\'État (ex. : SÉPAQ, Radio-Canada) s\'inscrivent au poste Commandites de sociétés d\'État.'
    },
    '332415_' : {
      'en' : 'Private sector sponsorships include all monetary contributions from private companies or other organizations received.<br><br>These can be for-profit organizations (OBL), non-profit organizations (NPO), or financial institutions (eg Desjardins).<br><br>This section excludes advertising revenues which are instead accounted for in section 7 C) “Other revenues”.',
      'fr' : 'Les commandites du Secteur privé regroupent l’ensemble des contributions monétaires d\'entreprises privées ou d’autres organismes reçues.<br><br>Il peut s’agir d’organismes à but lucratif (OBL), d’organismes à but non lucratif (OBNL), ou d’institutions financières (ex. : Desjardins).'
    }
  };
  categoriesForWhichOtherAndIsTotalIsHidden = [332415, 334131, 334132, 334504];

  constructor(private translate: TranslateService,
              private matDialog: MatDialog,
              public platform: Platform,
              private http: EcaseHttpService,
              private eCaseSnackBarService: ECaseSnackBarService,
              private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe,
              private refreshDataService: RefreshDataService,
              private dialogService: DialogService,
              private pendingChangesGuard: PendingChangesGuard) {
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.value.label = {};
    this.bphChange.error = {};
    this.bphChange.source = {};
    this.isValidHtml = true;
    this.isValidTable = false;
    this.isValidTableReadonly = false;
  }

  openDialog(dialogText) {
    const dialogRef = this.matDialog.open(DialogComponent, {
      width: '600px',
      data: {dialog: dialogText, selectedLanguage: this.translate.getDefaultLang()}
    });
    console.log('donee');
  }

  getDialogText(value) {
    return value ? this.dialogText[value.toString()] : undefined;
  }

  ngOnInit() {
    this.translate.langs.forEach((lang) => {
      if (lang && lang !== 'undefined') {
        this.otherValue.label[lang] = ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.other', lang);
      }
    });
    if (!this.confChange.tableLabel) {
      this.confChange.tableLabel = {};
    }
    if (!this.bphChange) {
      this.bphChange = {};
      this.bphChange.error = {};
      this.bphChange.rows = [];
    }
    if (!this.bphChange.rows) {
      this.bphChange.rows = [];
    }

   /* this.subscription = this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;
    });*/

    if (this.globalConf.lovs['17'] && this.globalConf.lovs['17'].list) {
      if (this.confChange.showLOVTable) {
        this.topLevelBudgetItems = this.globalConf.lovs['78'].list.filter(item => item.parentTermId === 334132)
          .map((item) => {
            item.taxonomyId = 78;
            return item;
          }).sort((a, b) => {
            if (a.sortingKey < b.sortingKey) {
              return -1;
            } else if (a.sortingKey > b.sortingKey) {
              return 1;
            }
            return 0;
          });
      } else {
        this.topLevelBudgetItems = this.globalConf.lovs['17'].list.filter(item => item.parentTermId === 208 || item.parentTermId === 209
          || item.parentTermId === 210 || item.parentTermId === 211 || item.parentTermId === 212)
          .map((item) => {
            item.taxonomyId = 17;
            return item;
          }).sort((a, b) => {
            if (a.sortingKey < b.sortingKey) {
              return -1;
            } else if (a.sortingKey > b.sortingKey) {
              return 1;
            }
            return 0;
          }).concat(this.globalConf.lovs['78'].list.filter(item => !item.parentTermId)
            .map((item) => {
              item.taxonomyId = 78;
              return item;
            }).sort((a, b) => {
              if (a.sortingKey < b.sortingKey) {
                return -1;
              } else if (a.sortingKey > b.sortingKey) {
                return 1;
              }
              return 0;
            }));
      }
      this.prepopulateTable();
      this.processPdfHideEntry();
    }
  }

  processPdfHideEntry() {
    this.bphChange.rows.forEach((row, i) => {
      if (this.bphChange.rows[i - 1] && this.bphChange.rows[i - 1].fundingCategory && this.categoriesForWhichOtherAndIsTotalIsHidden.includes(this.bphChange.rows[i - 1].fundingCategory.value)) {
        this.bphChange.rows[i].hideInPdf = true;
      }
    });
  }

  findNestedSubCategories(category, previousSelectedSubCategory, previousSubCategories) {
    const subCat = this.originalRowData.filter(_row => !_row.isTotal)
      .filter(item => item.fundingCategory.value === category.value && item.fundingSubCategory.filter(_item => _item.value === this.otherValue.value).length > 0)
      .map(item => item.fundingSubCategory.filter(_item => _item.value === this.otherValue.value)[0]);
    this.otherValue.otherValue = subCat.length > 0 ? subCat[0].otherValue : '';
    let isSubCategoriesAvailable;
    let subCategories;
    if (previousSelectedSubCategory.value === ECaseUtilsGlobal.OTHER_VALUE_TERM_ID) {
      subCategories = [];
    } else {
      if (category.taxonomyId === 17) {
        isSubCategoriesAvailable = this.globalPrj.formData[this.confChange.selectedLovSQL].data.filter(item => Number(item.ORGANIZATION_CLASSIFICATION_ID) === Number(previousSelectedSubCategory.value)).length > 0;
        subCategories = ((isSubCategoriesAvailable && !this.categoriesForWhichOtherAndIsTotalIsHidden.includes(category.value)) ? this.globalPrj.formData[this.confChange.selectedLovSQL].data.filter(item => Number(item.ORGANIZATION_CLASSIFICATION_ID) === Number(previousSelectedSubCategory.value)).concat([this.otherValue]) : this.globalPrj.formData[this.confChange.selectedLovSQL].data.filter(item => Number(item.ORGANIZATION_CLASSIFICATION_ID) === Number(previousSelectedSubCategory.value)));
      } else {
        isSubCategoriesAvailable = this.globalConf.lovs['78'].list.filter(item => item.parentTermId === Number(previousSelectedSubCategory.value) && item.parentTermId !== 334132).length > 0;
        subCategories = ((isSubCategoriesAvailable && !this.categoriesForWhichOtherAndIsTotalIsHidden.includes(category.value)) ? this.globalConf.lovs['78'].list.filter(item => item.parentTermId === Number(previousSelectedSubCategory.value) && item.parentTermId !== 334132).concat([this.otherValue]) : this.globalConf.lovs['78'].list.filter(item => item.parentTermId === Number(previousSelectedSubCategory.value) && item.parentTermId !== 334132));
      }
    }
    subCategories = subCategories.map((item) => {
      if (item.label) {
        return item;
      } else {
        const obj: any = {};
        obj.label = {};
        Object.keys(item).filter(key => key.startsWith('LABEL_')).forEach((key) => {
          obj.label[key.replace('LABEL_', '').toLowerCase()] = item[key];
        });
        obj.value = item['VALUE'];
        obj.sortingKey = Number(item['SORTING_KEY'] ? item['SORTING_KEY'] : '1');
        const subCat = this.originalRowData.filter(_row => !_row.isTotal)
          .filter(item => item.fundingCategory.value === category.value && item.fundingSubCategory.filter(_item => _item.value === obj.value).length > 0)
          .map(item => item.fundingSubCategory.filter(_item => _item.value === obj.value)[0]);
        obj.otherValue = subCat.length > 0 ? subCat[0].otherValue : '';
        return obj;
      }
    }).sort((a, b) => {
      if (a.sortingKey < b.sortingKey) {
        return -1;
      } else if (a.sortingKey > b.sortingKey) {
        return 1;
      }
      return 0;
    });
    if (subCategories.length > 0) {
      subCategories.forEach((subCategory) => {
        this.findNestedSubCategories(category, subCategory, previousSubCategories.concat([subCategory]));
      });
    } else {
      this.addRow(this.bphChange, this.confChange);
      const lastRow = this.bphChange.rows[this.bphChange.rows.length - 1];
      lastRow['fundingCategory'] = category;
      lastRow['fundingSubCategory'] = previousSubCategories;
      let filteredValues = this.originalRowData.filter(_row => !_row.isTotal).filter(_row => _row.fundingCategory.value === category.value);
      for (const x of _.range(previousSubCategories.length)) {
        filteredValues = filteredValues.filter((_row) => {
          if (_row.fundingSubCategory[x]) {
            return _row.fundingSubCategory[x].value === previousSubCategories[x].value;
          } else {
            return false;
          }
        });
      }
      lastRow['actualAmount'] = filteredValues.length > 0 ? filteredValues[0].actualAmount : {'value': ''};
      lastRow['provisionalAmount'] = filteredValues.length > 0 ? filteredValues[0].provisionalAmount : {'value': ''};
      //  lastRow['comment'] = filteredValues.length > 0 ? filteredValues[0].comment : {'value': ''};
      //  lastRow['justificationDocument'] = filteredValues.length > 0 ? filteredValues[0].justificationDocument : {};
      this.closeDetails(this.confChange, this.bphChange);
    }
  }

  getCategoryIndex(value): number {
    return (this.topLevelBudgetItems.indexOf(this.topLevelBudgetItems.filter(item => item.value === value)[0]) + 1);
  }

  prepopulateTable() {
    this.originalRowData = _.cloneDeep(this.bphChange.rows);
    this.bphChange.rows = [];
    this.topLevelBudgetItems.forEach((budgetItem) => {
      let isSubCategoriesAvailable;
      let subCategories;
      if (budgetItem.taxonomyId === 17) {
        isSubCategoriesAvailable = this.globalPrj.formData[this.confChange.selectedLovSQL].data.filter(item => Number(item.ORGANIZATION_CLASSIFICATION_ID) === budgetItem.value).length > 0;
        subCategories = ((isSubCategoriesAvailable && !this.categoriesForWhichOtherAndIsTotalIsHidden.includes(budgetItem.value)) ? this.globalPrj.formData[this.confChange.selectedLovSQL].data.filter(item => Number(item.ORGANIZATION_CLASSIFICATION_ID) === budgetItem.value).concat([this.otherValue]) : this.globalPrj.formData[this.confChange.selectedLovSQL].data.filter(item => Number(item.ORGANIZATION_CLASSIFICATION_ID) === budgetItem.value));
      } else {
        isSubCategoriesAvailable = this.globalConf.lovs['78'].list.filter(item => item.parentTermId === budgetItem.value && item.parentTermId !== 334132).length > 0;
        subCategories = ((isSubCategoriesAvailable && !this.categoriesForWhichOtherAndIsTotalIsHidden.includes(budgetItem.value)) ? this.globalConf.lovs['78'].list.filter(item => item.parentTermId === budgetItem.value && item.parentTermId !== 334132).concat([this.otherValue]) : this.globalConf.lovs['78'].list.filter(item => item.parentTermId === budgetItem.value && item.parentTermId !== 334132));
      }
      subCategories = subCategories.map((item) => {
        if (item.label) {
          return item;
        } else {
          const obj: any = {};
          obj.label = {};
          Object.keys(item).filter(key => key.startsWith('LABEL_')).forEach((key) => {
            obj.label[key.replace('LABEL_', '').toLowerCase()] = item[key];
          });
          obj.value = item['VALUE'];
          obj.sortingKey = Number(item['SORTING_KEY'] ? item['SORTING_KEY'] : '1');
          const subCat = this.originalRowData.filter(_row => !_row.isTotal)
            .filter(item => item.fundingCategory.value === budgetItem.value && item.fundingSubCategory.filter(_item => _item.value === obj.value).length > 0)
            .map(item => item.fundingSubCategory.filter(_item => _item.value === obj.value)[0]);
          obj.otherValue = subCat.length > 0 ? subCat[0].otherValue : '';
          return obj;
        }
      }).sort((a, b) => {
        if (a.sortingKey < b.sortingKey) {
          return -1;
        } else if (a.sortingKey > b.sortingKey) {
          return 1;
        }
        return 0;
      });
      if (subCategories.length > 0) {
        subCategories.forEach((subCategory) => {
          this.findNestedSubCategories(budgetItem, subCategory, [subCategory]);
        });
      } else {
        this.addRow(this.bphChange, this.confChange);
        const lastRow = this.bphChange.rows[this.bphChange.rows.length - 1];
        lastRow['fundingCategory'] = budgetItem;
        lastRow['fundingSubCategory'] = [{
          'otherValue': ''
        }];
        let filteredValues = this.originalRowData.filter(_row => !_row.isTotal).filter(_row => _row.fundingCategory.value === budgetItem.value);
        lastRow['actualAmount'] = filteredValues.length > 0 ? filteredValues[0].actualAmount : {'value': ''};
        lastRow['provisionalAmount'] = filteredValues.length > 0 ? filteredValues[0].provisionalAmount : {'value': ''};
        //  lastRow['comment'] = filteredValues.length > 0 ? filteredValues[0].comment : {'value': ''};
        //   lastRow['justificationDocument'] = filteredValues.length > 0 ? filteredValues[0].justificationDocument : {};
        this.closeDetails(this.confChange, this.bphChange);
      }
      console.log(this.bphChange.rows);
    });
  }


  onSubCategoryChange(event, dataObject) {
    (this.bphChange.rows[this.selectedTableRowIndex])['fundingSubCategory'][dataObject.level] = dataObject.subCategories.filter(item => item.value === event)[0];
    let subCategories = [];
    if (event === ECaseUtilsGlobal.OTHER_VALUE_TERM_ID) {
      subCategories = [];
    } else {
      const isSubCategoriesAvailable = this.globalPrj.formData[this.confChange.selectedLovSQL].data.filter(item => Number(item.ORGANIZATION_CLASSIFICATION_ID) === Number(event)).length > 0;
      subCategories = (isSubCategoriesAvailable ? this.globalPrj.formData[this.confChange.selectedLovSQL].data.filter(item => Number(item.ORGANIZATION_CLASSIFICATION_ID) === Number(event)).concat([this.otherValue]) : this.globalPrj.formData[this.confChange.selectedLovSQL].data.filter(item => Number(item.ORGANIZATION_CLASSIFICATION_ID) === event)).sort((a, b) => {
        if (a.sortingKey < b.sortingKey) {
          return -1;
        } else if (a.sortingKey > b.sortingKey) {
          return 1;
        }
        return 0;
      });
    }

    subCategories = subCategories.map((item) => {
      if (item.label) {
        return item;
      } else {
        const obj: any = {};
        obj.label = {};
        Object.keys(item).filter(key => key.startsWith('LABEL_')).forEach((key) => {
          obj.label[key.replace('LABEL_', '').toLowerCase()] = item[key];
        });
        obj.value = item['VALUE'];
        return obj;
      }
    });

    this.updateDataObjectForRecursiveSubCategorySelect(this.dataObjectForRecursiveSubCategorySelect, subCategories, 0, dataObject.level, event, false);
  }


  getWidthFromPlatform() {
    if (this.platform.ANDROID || this.platform.IOS) {
      return '100%';
    } else {
      return '50%';
    }
  }

  getTextLength(l): number {
    try {
      return l.toString().length;
    } catch (e) {
      return 0;
    }
  }

  getWordCount(str): number {
    try {
      return (str === '' ? 0 : str.split(' ').filter(item => item !== '').length);
    } catch (e) {
      return 0;
    }
  }

  preventMaxlengthViolation(tempObject, key, maxLength, index) {
    if (this.getTextLength(tempObject) >= maxLength) {
      tempObject = tempObject.toString().split('').slice(0, maxLength).join('');
      (this.bphChange.rows[index])[key].value = tempObject;
    }
    if (tempObject) {
      tempObject = tempObject.toString().replace(/[^0-9,.-]/g, '');
      (this.bphChange.rows[index])[key].value = tempObject;
    }
    this.pendingChangesGuard.isPristine = false;
    if (this.confChange.enableOutputEvent) {
      this.emitOutputEvent.emit(this.bphChange);
    }
  }

  updateDataObjectForRecursiveSubCategorySelect(dataObject, subCategories, currentLevel, requiredLevel, selectedSubCategory, keepCurrentSubCategoryValue) {
    if (currentLevel === requiredLevel) {
      dataObject['dataObject'] = {'subCategories': subCategories, 'level': (currentLevel + 1)};
      if (subCategories.length === 0 || selectedSubCategory === ECaseUtilsGlobal.OTHER_VALUE_TERM_ID) {
        // do nothing
      } else {
        if (subCategories.length > 0 && !keepCurrentSubCategoryValue) {
          (this.bphChange.rows[this.selectedTableRowIndex])['fundingSubCategory'][(currentLevel + 1)] = {};
        }
      }
    } else {
      this.updateDataObjectForRecursiveSubCategorySelect(dataObject['dataObject'], subCategories, (currentLevel + 1), requiredLevel, selectedSubCategory, keepCurrentSubCategoryValue);
    }
  }

  getTotal(rows, column, j, tcolumn, row, currency) {
    let r = 0;
    if ((typeof column !== 'undefined') && column !== '') {
      for (let i = j - 1; i >= 0; i--) {
        if (rows[i].isTotal && rows[i].column === tcolumn) {
          break;
        }
        if (!rows[i].isTotal) {
          let newCellVal = 0;
          if (rows[i][column] && !isNaN(parseInt(rows[i][column]['value'], 10))) {
            newCellVal = Math.round(parseFloat(rows[i][column]['value']));
          }
          r = r + newCellVal;
        }
        if (row) {
          if (!row[column]) {
            row[column] = {};
          }
          row[column]['value'] = r;
        }
      }
    } else {
      r = null;
    }
    return this.eCaseNumberFormatterPipe.transform(r, !!currency, this.translate.getDefaultLang(), true);
  }

  deleteRow(blockData, blockConf, i) {
    if (blockConf.isConfirmDelete) {
      blockConf.confirmDelete(this.globalPrj, this.globalConf, this.matDialog, this.translate, i, this, this.dialogService);
    } else {
      this.deleteTableRow(blockData, blockConf, i);
    }
  }

  deleteTableRow(blockData, blockConf, i) {
    this.globalPrj.isConfirmationSaved = false;
    if (blockConf.subBlocks) {
      for (let ii = 0; ii < blockConf.subBlocks.length; ii++) {
        if (blockConf.subBlocks[ii] && blockConf.subBlocks[ii].type === 'upload') {
          const subBlockName = blockConf.subBlocks[ii].name;
          if (blockData.rows[i][subBlockName].value) {
            for (let iiTobd = 0; iiTobd < blockData.rows[i][subBlockName].value.length; iiTobd++) {
              if (blockData.rows[i][subBlockName].value[iiTobd] && blockData.rows[i][subBlockName].value[iiTobd].fileUploaded) {
                if (!this.globalPrj.upload_to_be_deleted) {
                  this.globalPrj.upload_to_be_deleted = [];
                }
                this.globalPrj.upload_to_be_deleted.push(blockData.rows[i][subBlockName].value[iiTobd].id);
              }
            }
          }
        }
      }
    }
    blockData.rows.splice(i, 1);
    this.reshuffleTable(blockData, blockConf);
    this.bphChange = _.cloneDeep(blockData);
    (this.globalPrj[this.selectedSectionId])[blockConf.name] = this.bphChange;
  }

  reshuffleTable(blockData, blockConf) {
    if (blockConf && blockConf.showGroupBy) {
      blockData.rows = _.filter(blockData.rows, function(e) {
        return !e.isTotal;
      });
      if (blockData.rows.length === 0) {
        return;
      }
      const groupByColumns = blockConf.groupedBy.split(',');
      blockData.rows = _.orderBy(blockData.rows, groupByColumns);
      for (let i = groupByColumns.length - 1; i >= 0; i--) {
        if (i - 1 >= 0) {
          this.reshuffleColumn(blockData, blockConf, groupByColumns[i], i, groupByColumns[i - 1]);
        } else {
          this.reshuffleColumn(blockData, blockConf, groupByColumns[i], i, undefined);
        }
      }
    }
  }

  reshuffleColumn(blockData, blockConf, gbcolumn, k, gPreviouscolumn) {
    let oldCol = 0;
    const indexes = [];
    const groupColumn = gbcolumn.split('.')[0];
    blockData.rows = blockData.rows.sort((a, b) => {
      if (a.uniqueRowIndex < b.uniqueRowIndex) {
        return -1;
      } else if (a.uniqueRowIndex > b.uniqueRowIndex) {
        return 1;
      }
      return 0;
    });
    if (gPreviouscolumn) {
      eval('oldCol = blockData.rows[0].' + gbcolumn + ' +\'_\' +blockData.rows[0].' + gPreviouscolumn);
    } else {
      eval('oldCol = blockData.rows[0].' + gbcolumn);
    }
    let rowSpanRow: any = {};
    eval('rowSpanRow = blockData.rows[0].' + groupColumn);
    rowSpanRow.rowspan = 0;
    let newCol;
    for (let i = 0; i < blockData.rows.length; i++) {
      if (!blockData.rows[i].isTotal) {
        if (gPreviouscolumn) {
          eval('newCol = blockData.rows[i].' + gbcolumn + ' +\'_\' +blockData.rows[i].' + gPreviouscolumn);
        } else {
          eval('newCol = blockData.rows[i].' + gbcolumn);
        }
        console.log('groupColumn');
        console.log(groupColumn);
        if (oldCol !== newCol) {
          eval('blockData.rows[i].' + groupColumn + '.hideCell=false');
          indexes.push(i);
          oldCol = newCol;
          eval('rowSpanRow = blockData.rows[i].' + groupColumn);
          rowSpanRow.rowspan = 1;
        } else {
          eval('blockData.rows[i].' + groupColumn + '.hideCell=true');
          rowSpanRow.rowspan++;
        }
      } else {
        rowSpanRow.rowspan++;
      }
    }
    const clonedData = _.cloneDeep(blockData.rows);
    ECaseUtils.groupBy(clonedData, item => item[groupColumn]['value']).forEach((groupedRow) => {
      groupedRow.forEach((row, index) => {
        ((clonedData[clonedData.indexOf(row)])[groupColumn])['hideCell'] = index !== 0;
      });
    });
    blockData.rows = _.cloneDeep(clonedData);

    indexes.push(blockData.rows.length + 1);
    let moved = 0;
    blockConf.subBlocks = [
      {'name': 'fundingCategory'},
      {'name': 'fundingSubCategory'},
      {'name': 'actualAmount'},
      {'name': 'provisionalAmount'}
      /* {'name': 'comment'},
       {'name': 'justificationDocument'}*/
    ];
    for (let i = 0; i < blockConf.subBlocks.length; i++) {
      if (blockConf.subBlocks[i].name === groupColumn && !blockConf.subBlocks[i].noSubTotal) {
        for (let i = 0; i < indexes.length; i++) {
          blockData.rows.splice(indexes[i] + moved, 0, {'isTotal': true, 'position': k, 'column': groupColumn});
          moved++;
        }
      }
    }
  }

  orderTable(blockData, blockConf) {
    if (blockConf && blockConf.showOrderBy) {
      blockData.rows = _.filter(blockData.rows, function(e) {
        return !e.isTotal;
      });
      const groupByColumns = blockConf.orderedBy.split(',');
      if (blockConf.orderedByTypes) {
        const groupByTypes = blockConf.orderedByTypes.split(',');
        blockData.rows = _.orderBy(blockData.rows, groupByColumns, groupByTypes);
      } else {
        blockData.rows = _.orderBy(blockData.rows, groupByColumns);
      }
    }
  }


  onCategoryChange(event, keepCurrentSubCategoryValue) {
    (this.bphChange.rows[this.selectedTableRowIndex])['fundingCategory'] = this.topLevelBudgetItems.filter(item => item.value === event)[0];
    const isSubCategoriesAvailable = this.globalPrj.formData[this.confChange.selectedLovSQL].data.filter(item => Number(item.ORGANIZATION_CLASSIFICATION_ID) === event).length > 0;
    let subCategories = (isSubCategoriesAvailable ? this.globalPrj.formData[this.confChange.selectedLovSQL].data.filter(item => Number(item.ORGANIZATION_CLASSIFICATION_ID) === event).concat([this.otherValue]) : this.globalPrj.formData[this.confChange.selectedLovSQL].data.filter(item => Number(item.ORGANIZATION_CLASSIFICATION_ID) === event)).sort((a, b) => {
      if (a.sortingKey < b.sortingKey) {
        return -1;
      } else if (a.sortingKey > b.sortingKey) {
        return 1;
      }
      return 0;
    });
    if (subCategories.length > 0 && !keepCurrentSubCategoryValue) {
      (this.bphChange.rows[this.selectedTableRowIndex])['fundingSubCategory'][0] = {};
    }
    subCategories = subCategories.map((item) => {
      if (item.label) {
        return item;
      } else {
        const obj: any = {};
        obj.label = {};
        Object.keys(item).filter(key => key.startsWith('LABEL_')).forEach((key) => {
          obj.label[key.replace('LABEL_', '').toLowerCase()] = item[key];
        });
        obj.value = item['VALUE'];
        return obj;
      }
    });
    this.dataObjectForRecursiveSubCategorySelect = {'subCategories': subCategories, 'level': 0};
  }

  cancel(blockConf, blockData) {
    blockConf.showDetails = false;
    this.showDetails = false;
    this.orderTable(blockData, blockConf);
    this.reshuffleTable(blockData, blockConf);
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = true;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
      } catch (e) {
      }
    }
    this.refreshDataService.toggleShowSaveNavBar(true);
    this.bphChange = _.cloneDeep(this.copyBlockData);
    (this.globalPrj[this.selectedSectionId])[blockConf.name] = this.bphChange;
  }

  closeDetails(blockConf, blockData) {
    blockConf.showDetails = false;
    this.showDetails = false;
    this.orderTable(blockData, blockConf);
    this.reshuffleTable(blockData, blockConf);
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = true;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
      } catch (e) {
      }
    }
    if (blockConf.isAddRowFunctionActive) {
      blockConf.addRow(this.globalPrj, this.globalConf, this, this.selectedLanguage);
    }
    if (blockConf.isEditRowFunctionActive) {
      if (typeof blockConf.editRow === 'function') {
        blockConf.editRow(this.globalPrj, this.globalConf, this, this.selectedTableRowIndex, blockData);
      }
    }
    const data: any = {};
    data.operation = 'Save Without Validation';
    //   this.refreshDataService.saveForm(data);
    this.refreshDataService.toggleShowSaveNavBar(true);
  }

  getCleanedJson(json, isCleanError) {
    if (json === null || !json) {
      json = {};
    }
    if (json['pdf']) {
      json['pdf'] = {};
    }
    if (isCleanError) {
      if (json['error'] || typeof json['error'] === 'object') {
        json['error'] = {};
      }
      if (json['error_class'] === '' || json['error_class'] === 'error' || typeof json['error'] === 'string') {
        json['error_class'] = '';
      }
    }
    for (const key in json) {
      if (!json.hasOwnProperty(key)) {
        continue;
      }
      if (typeof json[key] === 'object') {
        this.getCleanedJson(json[key], isCleanError);
      }
    }
    return json;
  }

  ngOnDestroy(): void {
    this.confChange.showDetails = false;
    this.showDetails = false;
   // this.subscription.unsubscribe();
  }

  concatFundingSubCategory(subCategories: any[]) {
    return subCategories.filter(item => item.label).map((item) => {
      if (item.otherValue && item.otherValue !== '') {
        return item.label[this.selectedLanguage] + ' / ' + item.otherValue;
      } else {
        return item.label[this.selectedLanguage];
      }
    }).join(' / ');
  }

  isIncludeOther(subCategories: any[]): boolean {
    return subCategories.filter(item => item.value === ECaseUtilsGlobal.OTHER_VALUE_TERM_ID).length > 0;
  }

  getLevel(subCategories: any[]): number {
    return subCategories.indexOf(subCategories.filter(item => item.value === ECaseUtilsGlobal.OTHER_VALUE_TERM_ID)[0]);
  }

  addRow(blockData, blockConf, rowData?) {
    this.dataObjectForRecursiveSubCategorySelect = {};
    // blockConf.isAddRowFunctionActive = true;
    blockConf.isEditRowFunctionActive = false;
    blockConf.showDetails = false;
    this.showDetails = false;
    this.confChange = _.cloneDeep(blockConf);
    this.bphChange = _.cloneDeep(blockData);
    this.copyBlockData = _.cloneDeep(this.bphChange);
    console.log('ddddddddddd');
    console.log(this.confChange);
    if (!this.confChange.tableLabel) {
      this.bphChange.globalShowDetail = false;
    }

    this.refreshDataService.toggleShowSaveNavBar(false);
    this.confChange = blockConf;
    blockData.rows = blockData.rows || [];
    const aRow: any = {};
    aRow.uniqueRowIndex = blockData.rows.length > 0 ? (Math.max.apply(Math, blockData.rows.map(function(o) {
      return o.uniqueRowIndex ? o.uniqueRowIndex : 0;
    })) + 1) : 1;
    const globalArray = [];
    this.globalPrj = this.globalPrj ? this.globalPrj : _.cloneDeep(this.getCleanedJson(this.globalPrj, false));
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    /*blockConf.subBlocks.forEach(function (entity) {
      aRow[entity.name] = {};
      if (!blockConf[entity.name]) {
        blockConf[entity.name] = entity;
      }
      blockConf[entity.name].rowSpan = 1;
    });*/
    aRow['fundingCategory'] = {};
    aRow['fundingSubCategory'] = [];
    aRow['actualAmount'] = {};
    aRow['provisionalAmount'] = {};
    aRow['comment'] = {};
    aRow['justificationDocument'] = {};

    blockData.rows.push(aRow);
    blockData['selectedRowIndex'] = blockData.rows.length - 1;
    this.selectedTableRowIndex = blockData.rows.length - 1;
    blockData.selectedRow = aRow;
    blockConf.showDetails = true;
    this.showDetails = true;
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = false;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = true;
      } catch (e) {
      }
    }
    blockConf.subBlocks.forEach(function(entity) {
      aRow[entity.name].globalShowDetail = true;
    });
    window.scrollTo(0, 0);
    this.bphChange = blockData;
    this.confChange = blockConf;
  }

  getGlobalTotal(rows, column, currency) {
    let r = 0;
    if ((typeof column !== 'undefined') && column !== '') {
      for (let i = 0; i < rows.length; i++) {
        if (!rows[i].isTotal) {
          let newCellVal = 0;
          if (rows[i][column] && !isNaN(parseInt(rows[i][column]['value'], 10))) {
            newCellVal = Math.round(parseFloat(rows[i][column]['value']));
          }
          r = r + newCellVal;
          const newTotalRow = {};
          newTotalRow['isTotal'] = true;
          newTotalRow['value'] = r;
        }
      }
    } else {
      r = null;
    }
    return this.eCaseNumberFormatterPipe.transform(r, !!currency, this.translate.getDefaultLang(), true);
  }

  editRow(blockData, blockConf, i) {
    blockConf.isAddRowFunctionActive = false;
    blockConf.isEditRowFunctionActive = true;
    this.selectedTableRowIndex = i;
    this.globalPrj.isConfirmationSaved = false;
    blockData.selectedRow = blockData.rows[i];
    blockConf.showDetails = true;
    this.showDetails = true;
    this.refreshDataService.toggleShowSaveNavBar(false);
    if (blockData.rows[i].fundingCategory && blockData.rows[i].fundingCategory.value) {
      this.dataObjectForRecursiveSubCategorySelect = {};
      this.onCategoryChange(blockData.rows[i].fundingCategory.value, true);
      blockData.rows[i].fundingSubCategory.forEach((fundingSubCategory, index) => {
        const isSubCategoriesAvailable = this.globalConf.lovs['17'].list.filter(item => item.parentTermId === fundingSubCategory.value).length > 0;
        const subCategories = (isSubCategoriesAvailable ? this.globalConf.lovs['17'].list.filter(item => item.parentTermId === fundingSubCategory.value).concat([this.otherValue]) : this.globalConf.lovs['17'].list.filter(item => item.parentTermId === fundingSubCategory.value)).sort((a, b) => {
          if (a.sortingKey < b.sortingKey) {
            return -1;
          } else if (a.sortingKey > b.sortingKey) {
            return 1;
          }
          return 0;
        });
        this.updateDataObjectForRecursiveSubCategorySelect(this.dataObjectForRecursiveSubCategorySelect, subCategories, 0, index, fundingSubCategory.value, true);
      });
    }
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = false;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = true;
      } catch (e) {
      }
    }
    blockData['selectedRowIndex'] = i;
    this.copyBlockData = _.cloneDeep(this.bphChange);
    window.scrollTo(0, 0);
  }

  convertNumberToNumericFormat(currency, value) {
    return value ? this.eCaseNumberFormatterPipe.transform(value, !!currency) : '';
  }

  deleteFile(fileUploaded, row) {
    console.log(fileUploaded);
    row.uploadFile = [];
    this.globalPrj.upload_to_be_deleted.push(fileUploaded.id);
  }

  uploadDone(event, row) {
    row.uploadFile = [];
    const documentPath = event.target.value;

    let invalidFlag = false;
    if (event.target.files) {
      for (const file of event.target.files) {
        if (file.size > (5 * 1048576)) {
          alert(this.selectedLanguage === 'en' ? 'The size of the file is too big' : 'La taille du fichier est trop importante');
          invalidFlag = true;
          break;
        }
      }
    }

    if (!invalidFlag) {
      if (documentPath.includes('.pdf')) {
        const formData = new FormData();
        formData.append(event.target.files[0].name, event.target.files[0]);
        formData.append('documentTypeId', '2032902');
        formData.append('selectedSectionId', this.selectedSectionId);
        formData.append('formId', this.formId);
        formData.append('blockIndex', this.blockIndex.toString());
        this.http.post('/api/fileUpload', formData).subscribe((_data: any) => {
          console.log(_data);
          if (_data.hasOwnProperty('status') && !_data.status) {
            alert(this.selectedLanguage === 'en' ? 'Upload failed' : 'Le téléchargement a échoué');
          } else {
            if (_data.docsIds.length === 1) {
              const upload_id = _data.docsIds[0];
              const upload_name = _data.docsNames[0];
              console.log(upload_name);
              const parsed_uploadName = upload_name.substring(upload_name.lastIndexOf('\\') + 1);
              console.log(parsed_uploadName);
              const tempObject = {
                'id': upload_id,
                'fileName': parsed_uploadName,
                'fileUploaded': true,
                'creation_date': new Date()
              };
              console.log(tempObject);
              row.uploadFile.push(tempObject);
              this.eCaseSnackBarService.show('success', this.selectedLanguage === 'en' ? 'File(s) uploaded' : 'Fichier téléchargé');
              this.pendingChangesGuard.isPristine = false;
            }
            if (_data.docsIds.length > 0) {
              this.globalPrj.docsIds = [...this.globalPrj.docsIds, ..._data.docsIds];
            }
          }
        }, error => this.eCaseSnackBarService.show('failure', error.errorMessage));
      } else {
        const msg = ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.uploadRequiredExtensionsMessage', this.selectedLanguage) + '.pdf';
        alert(msg);
      }
    }

  }


}
