import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {Subject} from 'rxjs/internal/Subject';
import {FormGroup} from '@angular/forms';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class CompetitionService {
  oldFormGroup: FormGroup;
  isUseOldFormGroup = false;
  isForOrganization = false;
  arrayOfProgram = [];
  programId;
  isNavigationToChildrenProgram: boolean;
  isNavigationToChildrenCompetition: boolean;
  isBasicConfig: boolean;
  isCompetitionClosed: boolean;
  programsArray = [];
  selectedProgram: any;
  competitionArray = [];
  applicationsArray = [];
  competitionAplication = new Subject();
  competitionId: string;
  partnerAccessDate;
  dmsPackage;
  enableTabsOnProgramAddsubject = new Subject();
  enableReviewTabsOnProgramAddsubject = new Subject();
  programScreenScrollTopSubject = new Subject();
  enableInstitutionTabsOnProgramAddsubject = new Subject();
  competitionDetailsScreenScrollTopSubject = new Subject();
  configFinanceScreenScrollTopSubject = new Subject();
  financeDataArray = [];
  isProgramSelected = false;
  pageNumber;
  pageSize;
  pageNumberFilter;
  dataSourceresultFilter = [];
  newprogramId;
  classificationId;
  programTypeId;
  isArchived;
  isMultiTeamFundingEnabled: boolean;
  internalCode;
  fullName = {};
  shortName = {};
  identifierFullName;
  configApplicationFormData = [];

  // ACCESS LIST AND INVITATION  USERS
  extensionAccessForCompetitionsAfterDeadData = [];
  cpmCompetitionInvitedUsersData = [];
  nvlExtensionEndEDate;
  publishedDate;
  cpmCompetitionFormListOfFormType;
  applicationItemsAccessList = [];
  isCpmNotificationEventAndIsUserHasPrivilege = false;
  notificationEmailsAfterDeadLine = [];
  languagesUsersAccessList = [];
  notificationEmailsInvitationUsers = [];
  isCpmCompetitionFormExist = false;
  isCpmOpenInvitationYes = false;
  users = [];
  showPackageAndConsolidation: boolean;
  showPackageComposition: boolean;
  isUserHasPrivilege: boolean;
  eligibilityArray = [];
  triggerSubjectForArchivedProgram: Subject<any> = new Subject<any>();
  formLibrarySelect = [];
  formTypeLov = [];
  defaultSupportedLanguagesForAddCompetitionForm = [];
  isConfigurationAllowed: boolean;
  isSubFormSubmissionRequiredToSubmitMainApp: boolean;
  closingDate='';
  startDate = '';
  closingDateNOTExtended='';

  // BATCH COMMUNICATIONS TAB
  templateSelectedForViewer: any = {};
  listBatchCommunications=[];
  templatesForAwardArray = [];
  templateTableDataSource = [];
  templateSelectedToView;

  // Post Award
  requiredPostAwardReports = [];
  postAwardFormsArray = [];
  reportSchedules = [];
  postAwardNotifications = [];
  isPaDatesRelativeProjectStartDate;
  isPaReviewAutomaticAssignment;

  boardCommitteeWasChanged = false;
  programName ='';


  constructor(private eCaseHttpService: EcaseHttpService) {

  }

  getComplementaryInformationFormDataForFields(formId: number, cpmCompetitionId): Observable<any> {
    return this.eCaseHttpService.post('/api/getComplementaryInformationFormDataForFieldsInProjectDetailsForCompetition', {
      'formId': formId,
      'cpmCompetitionId': cpmCompetitionId,
      'caseJsonDataRepositoryId': -1,
      'isFormForConsent': true
    });
  }

  getComplementaryInformationFormDataForFieldsDummy(formId: number, cpmCompetitionId, caseJsonDataRepositoryId: number): Observable<any> {
    return this.eCaseHttpService.post('/api/getComplementaryInformationFormDataForFieldsDummy', {
      'competitions':  [cpmCompetitionId],
      'projectId':  -1,
      'projectTitle': 'new project',
      'applicationTitle': 'New application',
      'formId': formId,
      'caseJsonDataRepositoryId': caseJsonDataRepositoryId,
      'teamMemberId': -1,
      'isFormForConsent': false
    });
  }

  findCompetitionByUrl(url: string): void{
    if(url.match('cpm\\/\\d{1,10}\\/config\\/\\d{1,10}')){
      console.log('here');
      console.log(url.match('cpm\\/\\d{1,10}\\/config\\/\\d{1,10}'));
    }
  }

  savePaReportBasicConfiguration(cpmCompetitionId, isPaDatesRelativeProjectStartDate, isPaReviewAutomaticAssignment): Observable<any> {
    return this.eCaseHttpService.post('/api/savePaReportBasicConfiguration',
      {
        'cpmCompetitionId': cpmCompetitionId,
        'isPaDatesRelativeProjectStartDate': isPaDatesRelativeProjectStartDate,
        'isPaReviewAutomaticAssignment': isPaReviewAutomaticAssignment,
      });
  }

  deletePostAwardReport(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/deletePostAwardReport', obj);
  }
  deleteReportSchedule(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteReportSchedule', obj);
  }
  deleteReportNotification(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteReportNotification', obj);
  }
  getAllPostAwardPhaseWorkflows(): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllPostAwardPhaseWorkflows');
  }
  addOrUpdateReportNotification(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdateReportNotification', obj);
  }

  addOrUpdateReportSchedule(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdateReportSchedule', obj);
  }
  getPostAwardInformationForCompetition(competitionId): Observable<any> {
    return this.eCaseHttpService.post('/api/getPostAwardInformationForCompetition', competitionId);
  }


  getAllPostAwardEmailTemplates(): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllPostAwardEmailTemplates');
  }

  addOrUpdatePostAwardReport(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdatePostAwardReport', obj);
  }

  updateReportScheduleAndNotifications(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/updateReportScheduleAndNotifications', obj);
  }

  deleteConsolidatedPackage(competitionId, dmsPackageId): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteConsolidatedPackage',
      {
        'competitionId': competitionId,
        'dmsPackageId': dmsPackageId
      });
  }

  getDmsPackageNames(competitionId): Observable<any> {
    return this.eCaseHttpService.get('/api/getDmsPackageNames/' + competitionId);
  }
  getAllFinalizationPhaseWorkflows(competitionId: number): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllFinalizationPhaseWorkflows/' + competitionId);
  }

  validateUserForPrivilege(ssoUserId): Observable<any> {
    return this.eCaseHttpService.get('/api/validateUserForPrivilege/' + ssoUserId);
  }

  setAwardFinalizationInformation(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/setAwardFinalizationInformation', obj);
  }
  addOrDeleteTemplate(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrDeleteTemplate', obj);
  }

  getAwardFinalizationInformation(cpmCompetitionId): Observable<any> {
    return this.eCaseHttpService.post('/api/getAwardFinalizationInformation' , {'cpmCompetitionId': cpmCompetitionId});
  }

  savePackageComposition(packageComposition, packageId, competitionId): Observable<any> {
    return this.eCaseHttpService.post('/api/savePackageComposition', {
      'packageComposition': packageComposition,
      'packageId': packageId,
      'competitionId': competitionId
    });
  }

  addCompetitionPackage(competitionPackage): Observable<any> {
    return this.eCaseHttpService.post('/api/addCompetitionPackage', competitionPackage);
  }

  getPackageCompostition(competitionId, dmsPackageId): Observable<any> {
    return this.eCaseHttpService.post('/api/getPackageCompostition',
      {
        'competitionId': competitionId,
        'dmsPackageId': dmsPackageId
      });
  }

  updateMergeOrderForPackageComposition(competitionId, dmsPackageId, rows): Observable<any> {
    return this.eCaseHttpService.post('/api/updateMergeOrderForPackageComposition', {
      'competitionId': competitionId,
      'dmsPackageId': dmsPackageId,
      'rows': rows
    });
  }

  checkForMultiValuedVariablesWithApplicationId(templateId, appId, lang): Observable<any> {
    return this.eCaseHttpService.post('/api/checkForMultiValuedVariablesWithApplicationId', {
      'caseApplicationId': appId,
      'templateId': templateId,
      'lang': lang,
    });
  }

  checkForMultiValuedVariablesWithCompetitionId(templateId, competitionId, lang): Observable<any> {
    return this.eCaseHttpService.post('/api/checkForMultiValuedVariablesWithCompetitionId', {
      'competitionId': competitionId,
      'templateId': templateId,
      'lang': lang,
    });
  }


  choosePackageToBeConsolidated(competitionId): Observable<any> {
    return this.eCaseHttpService.post('/api/choosePackageToBeConsolidated', {'competitionId': competitionId});
  }

  getPackagesToBeConsolidated(competitionId): Observable<any> {
    return this.eCaseHttpService.post('/api/getPackagesToBeConsolidated', {'competitionId': competitionId});
  }

  addOrUpdateProgram(programItem): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdateProgram', programItem);
  }

  getAllPrograms(): Observable<any> {

    return this.eCaseHttpService.get('/api/getAllProgramsCompetitions');
  }

  deleteProgram(programId): Observable<any> {
    return this.eCaseHttpService.post('/api/markProgramAsDeleted ', {'programId': programId});
  }

  addOrUpdateCompetition(competitionItem): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdateCompetition', competitionItem);
  }

  updateOrderOfDisaplyForEligiblityCriteria(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/updateOrderOfDisaplyForEligiblityCriteria', obj);
  }

  deleteCompetition(competitionId): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteCpm', {'competitionId': competitionId});
  }

  deleteSubApplication(subApplicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteSubApplication', {'subApplicationId': subApplicationId});
  }

  getSelectedCompetition(programId: number): Observable<any> {
    return this.eCaseHttpService.get('/api/competitions/' + programId);
  }

  getAllCpmKpiAndItems(programId): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllKpiAndItems/' + programId);
  }

  addNewCpmKpi(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addNewCpmKpi', obj);
  }

  addNewCpmRiskItem(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addNewCpmRiskItem', obj);
  }

  addNewCriteria(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addNewCriteria', obj);
  }

  addOrUpdateKpiToProgram(kpis): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdateKpiToProgram', kpis);
  }

  addOrUpdateRiskManagementElementsToProgram(rmes): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdateRiskManagementElementsToProgram', rmes);
  }

  updateRiskIdentificationAndPlan(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/updateRiskIdentificationAndPlan', obj);
  }

  editProgram(programId: string): Observable<any> {
    return this.eCaseHttpService.get('/api/editProgram/' + programId);
  }

  editCompetition(competitionId: string): Observable<any> {
    return this.eCaseHttpService.get('/api/editCompetition/' + competitionId);
  }

  getAllApplicationsFormByCompetitionId(competitionId: string): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllCompetitionForm/' + competitionId);
  }

  getAllApplicationForCpmCompetitionAccess(competitionId, ssoUserId, cpmFormTypeId): Observable<any> {
    return this.eCaseHttpService.post('/api/getAllApplicationForCpmCompetitionAccess' , {
      'competitionId' : competitionId,
      'ssoUserId' : ssoUserId,
      'cpmFormTypeId' : cpmFormTypeId
    });
  }


  addOrUpdateApplicationForm(formItem): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdateApplicationForm', formItem);
  }

  addOrUpdateSubApplicationForm(formItem): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdateSubApplicationForm', formItem);
  }

  getAllFinanceInfo(competitionId): Observable<any> {
    return this.eCaseHttpService.get('/api/allBoardCommittee/' + competitionId);
  }

  findProgramAdminAccess(programId: string): Observable<any> {
    return this.eCaseHttpService.get('/api/AllUsersProgramsByProgramId/' + programId);
  }

  // addProgramAdminAccess(program): Observable<any> {
  //   return this.eCaseHttpService.post('/api/AddUserAdminAccess',program);
  // }
  // deleteProgramAdminAccess(programId): Observable<any> {
  //   return this.eCaseHttpService.post('/api/deleteUserProgram' ,{'programId': programId});
  // }

  updateCompetitionFinance(boardCommittee): Observable<any> {
    return this.eCaseHttpService.post('/api/updateCpmCompetitionFinance', boardCommittee);
  }

  addOrUpdateBoardCommittee(boardCommittee): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdateBoardCommittee', boardCommittee);
  }

  deleteCpmBoardCommittee(cpmBoardCommitteeId): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteCpmBoardCommittee', {'cpmBoardCommitteeId': cpmBoardCommitteeId});
  }


  // findProgramAdminAccess(programId: string): Observable<any> {
  //   return this.eCaseHttpService.get('/api/AllUsersProgramsByProgramId/' + programId);
  // }

  addProgramAdminAccess(program): Observable<any> {
    return this.eCaseHttpService.post('/api/AddUserAdminAccess', program);
  }

  deleteProgramAdminAccess(ssoUserProgramId: number): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteUserProgram', {'ssoUserProgramId': ssoUserProgramId});
  }

  updateProgramAdminAccessIsManager(ssoUserProgramId: number, isManager): Observable<any> {
    return this.eCaseHttpService.post('/api/updateUserProgramIsManager', {
      'ssoUserProgramId': ssoUserProgramId,
      'isManager': isManager
    });
  }

  updateProgramAdminAccessIsCounsellor(ssoUserProgramId: number, isCounsellor): Observable<any> {
    return this.eCaseHttpService.post('/api/updateUserProgramIsCounsellor', {
      'ssoUserProgramId': ssoUserProgramId,
      'isCounsellor': isCounsellor
    });
  }

  closeCompetition(competitionId): Observable<any> {
    return this.eCaseHttpService.post('/api/closeCompetition', {'competitionId': competitionId});
  }

  publishOrUnpublished(competitionId): Observable<any> {
    return this.eCaseHttpService.post('/api/publishOrUnpublished', {'competitionId': competitionId});
  }

  updateConfigCpm(cpm): Observable<any> {
    return this.eCaseHttpService.post('/api/updateConfigCpm', cpm);
  }


  deleteCpmForm(id: number): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteCpmForm', {'id': id});
  }

  publishCpmForm(id): Observable<any> {
    return this.eCaseHttpService.post('/api/pubCpmForm', {'id': id});
  }


  deleteKpiToProgram(id): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteProgramKpi', {'id': id});
  }

  deleteCpmProgramRiskItem(id): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteProgramRisk', {'id': id});
  }

  getInvitationsAccessAndExtensionAfterDeadLine(competitionId): Observable<any> {
    return this.eCaseHttpService.get('/api/extDeadLineInvitationUser/' + competitionId);

  }

  addExtensionAccess(accessItem): Observable<any> {
    return this.eCaseHttpService.post('/api/addExtensionAccess', accessItem);
  }

  addExtensionAccessWithAttachments(accessItem: any, attachments: File[]): Observable<any> {
    const formData = new FormData();
    for (const file of attachments) {
      if (file.name) {
        formData.append(file.name, file);
      }
    }
    formData.append('payLoad', JSON.stringify(accessItem));
    return this.eCaseHttpService.post('/api/addExtensionAccess', formData);
  }

  addCpmInvitationUser(accessItem): Observable<any> {
    return this.eCaseHttpService.post('/api/addCpmInvitationUser', accessItem);
  }

  deleteExtensionAccess(id, crmCommunicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteExtensionAccess', {'id': id, 'crmCommunicationId': crmCommunicationId});
  }

  deleteCpmInvitationUser(id): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteCpmInvitationUser', {'id': id});
  }

  addEligibilityCriteria(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addEligibilityCriteria', obj);
  }

  getAllCriteriaEligibility(): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllCriteriaEligibility/' + this.competitionId);
  }

  onSaveEligibilityCriteria(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/saveEligibilityCriteria', obj);
  }

  onDeleteEligibilityCriteria(id: number): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteCpmCompetitionEligibilityCriteria', {'id': id});
  }


  getDataProgram(): any[] {

    this.arrayOfProgram = [
      {
        code: 'PARE',
        description: 'Research Assistance Contract on Teaching and Learning (PAREA)',
        categoryProgram: 'Grant Contract ',
        actif: 'yes'
      },
      {
        code: 'PARI',
        description: 'Research and Transfer Assistance Contract (PART) - Social Innovation Component',
        typeProgram: 'Grant Contract ',
        categoryProgram: 'Grant Contract ',
        actif: 'yes'
      },
      {
        code: 'PART',
        description: 'Research and Transfer Assistance Contract (PART) - Technological Innovation Component',
        typeProgram: 'Grant Contract ',
        categoryProgram: 'College sector',
        actif: 'yes'
      },

      {
        code: 'PSCC',
        description: 'Contract to help disseminate research results at the college level (PADRRC)',
        typeProgram: 'Grant Contract ',
        categoryProgram: 'College sector',
        actif: 'yes'
      }


    ];
    return this.arrayOfProgram;
  }


  // selectProgram(event) {
  //   this.isProgramSelected = event;
  //
  // }

  triggerWhenProgramIsLoaded(data: any): void {
    this.triggerSubjectForArchivedProgram.next(data);
  }

  getAllBudgetChartItems(): Observable<any> {
    return this.eCaseHttpService.post('/api/getAllBudgetChartItems', {'cpmCompetitionId': this.competitionId});
  }

  deleteBudgetChartItem(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteBudgetChartItem', obj);
  }

  addBudgetChartItem(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addBudgetChartItem', obj);
  }

  getAllPartners(): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllPartners');
  }

  getAllBudgetChartsForAdding(): Observable<any> {
    return this.eCaseHttpService.post('/api/getAllBudgetChartsForAdding', {'cpmCompetitionId': this.competitionId});
  }

  addExpenditureItem(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addExpenditureItem', obj);
  }

  editExpenditureItem(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/editExpenditureItem', obj);
  }

  editBudgetChartItem(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/editBudgetChartItem', obj);
  }

  getAllExpenditureItems(): Observable<any> {
    return this.eCaseHttpService.post('/api/getAllExpenditureItems', {'cpmCompetitionId': this.competitionId});
  }

  deleteExpenditureItem(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteExpenditureItem', obj);
  }


  // ROLES AND PERMISSIONS TAB
  getRolesAndPermission(cpmCompetitionId): Observable<any> {
    return this.eCaseHttpService.post('/api/getRolesAndPermission', {
      cpmCompetitionId: cpmCompetitionId
    });
  }


  getFormTypeList(cpmCompetitionId): Observable<any> {
    return this.eCaseHttpService.get('/api/getFormTypesForCpmCompetition/' + +(cpmCompetitionId));
  }


  deleteRolesAndPermission(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteRoleAndPermission', obj);
  }

  saveRolesAndPermissions(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addRolesAndPermissions', obj);
  }

  updateRolesAndPermissionConsentRequired(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/updateConsentRequired', obj);
  }

  // ALL SERVICE USED IN BATCH COMMUNICATIONS TAB

  getBatchCommunication(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/getBatchCommunicationsForCompetition', obj);
  }

  getEmailTemplates(filterWithCrmEmailTemplateId?): Observable<any> {
    if (filterWithCrmEmailTemplateId) {
      return this.eCaseHttpService.get('/api/getAllEmailTemplate?filterWithCrmEmailTemplateId=' + filterWithCrmEmailTemplateId);
    } else {
      return this.eCaseHttpService.get('/api/getAllEmailTemplate');
    }
  }

  saveBatchCommunication(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdateCommunicationForCompetition', obj);
  }

  deleteBatchCommunication(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteCommunicationForCompetition', obj);
  }
  getAllBoardPlanningInfo(competitionId): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllBoardPlanning/' + competitionId);
  }

  getAllActiveCompetitionsForGeneralDirectory(): Observable<any> {
    return this.eCaseHttpService.get('api/getAllActiveCompetitionsForGeneralDirectory');
  }

  uploadProspectusFile(formData): Observable<any>{
    return this.eCaseHttpService.post('api/uploadProspectusFile', formData);
  }
  deleteProspectusFile(obj): Observable<any>{
    return this.eCaseHttpService.post('/api/deleteProspectusFile', obj);
  }

  getProgramsSimpleList(programTypeId) {
    let url = '/api/getAllProgramsSimpleList';
    url += programTypeId === null ? '' : '?pgrmType=' + programTypeId;
    return this.eCaseHttpService.get(url);
  }

  getCpmByProgramId(programId) {
    return this.eCaseHttpService.get('/api/getCpmByProgramIdSimpleList/' + programId);
  }

  cloneCompetition(obj){
    return this.eCaseHttpService.post('/api/cloneCompetition', obj);
  }

  updatePackagesToBeConsolidated(obj){
    return this.eCaseHttpService.post('/api/updatePackagesToBeConsolidated',obj)
  }

  getCounsellorsByCompetitionId(competitionId): Observable<any> {
    return this.eCaseHttpService.get('/api/getCounsellorsByCompetitionId/' + competitionId);
  }

  getApprovalCyclesByCompetitionId(competitionId, documentType): Observable<any> {
    return this.eCaseHttpService.get('/api/getApprovalCyclesByCompetitionId/' + competitionId + '/' + documentType);
  }

  deleteApprovalCycle(id, competitionId): Observable<any> {
    return this.eCaseHttpService.get('/api/deleteApprovalCycle/' + competitionId + '/' + id);
  }

  addOrUpdateApprovalCycle(id, description, competitionId): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdateApprovalCycle', {
      'id': id,
      'description': description,
      'competitionId': competitionId,
    });
  }

}

