<mat-dialog-content>
  <form [formGroup]="fileRenamerForm" autocomplete="off">
    <mat-label for="ecase_common_new_file_name"><span
      style="color: red">* </span>{{'ecase.common.newFileName' | translate}}</mat-label>
    <mat-form-field appearance="outline">
      <input [attr.aria-label]="'ecase.common.newFileName'| translate"
             [formControlName]="'newFileName'"
             autocomplete="off"
             maxlength="200"
             id="ecase_common_new_file_name"
             matInput>
      <span *ngIf="suffix && suffix !== ''" matSuffix>{{suffix}}</span>
      <mat-error *ngIf="fileRenamerForm.controls['newFileName'].errors?.required &&
              (fileRenamerForm.controls['newFileName'].dirty || fileRenamerForm.controls['newFileName'].touched)">
        {{'ecase.common.thisfieldismandatory' | translate}}</mat-error>
      <mat-error *ngIf="fileRenamerForm.controls['newFileName'].errors?.oldName &&
              (fileRenamerForm.controls['newFileName'].dirty || fileRenamerForm.controls['newFileName'].touched)">
        {{'ecase.common.newFileNameSameAsOldFileNameError' | translate}}</mat-error>
    </mat-form-field>
    <div class="field">
      <p class="counter-style">{{getTextLength('newFileName')}} / 200</p>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button class="eCase-dialog-positive-action-button" [disabled]="fileRenamerForm.invalid"
          style=" margin-right: 15px;" (click)="onConfirm()"
          mat-raised-button>{{'ecase.common.confirm'| translate }}
  </button>
  <button class="eCase-dialog-negative-action-button"
          (click)="onClose()"
          mat-raised-button>{{'ecase.common.close'| translate }}
  </button>
</mat-dialog-actions>
