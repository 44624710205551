import {Component, Input, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {EcaseHttpService} from 'synto-common';


@Component({
  selector: 'fb-search',
  templateUrl: './fb-search.component.html',
  styleUrls: ['./fb-search.component.css']
})
export class FbSearchComponent implements OnInit {

  lang: any;
  @Input() selectedLanguage;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  row: any;

  constructor(private translate: TranslateService, private http: EcaseHttpService, public dialog: MatDialog) {
    this.confChange = {};
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.error = {};
    this.confChange.label = {};
    this.row = {};
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;
    });
    this.confChange.search = function(c, s) {
      const content = s.searchQuery;
      const urlQuery = 'apiDemo/offlineCompetitions/rest/api/angularservices/searchForQuery/' + content;

      eval('\tglobalComponent.http.get(urlQuery).subscribe(function (res) {  \n' +
        '\t\tvar responseJson = JSON.parse(res._body)\n' +
        '\t\tif(c.useCustomized && c.customizedFileds){\n' +
        '\t\t\tvar fields = c.customizedFileds.split(",")\n' +
        '\t\t\tvar ii = 0;\n' +
        '\t\t\tfor(var key in responseJson){\n' +
        '\t\t\t\tvar field = fields[ii++].split("\\:")\n' +
        '\t\t\t\teval("globalComponent.prj." + field[0] + " = responseJson[\'" + field[1] + "\']")\n' +
        '\t\t\t}\n' +
        '\t\t}\n' +
        '\t\tif(!c.useCustomized){\n' +
        '\t\t\tc.defaultResult = JSON.stringify(responseJson)\n' +
        '\t\t}\n' +
        '\t})');

    };
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '600px',
      data:
        {
          dialog: this.confChange.dialogText,
          selectedLanguage: this.selectedLanguage
        }
    });
    console.log('donee');
  }

}
