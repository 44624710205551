import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'fb-signature',
  templateUrl: './fb-signature.component.html',
  styleUrls: ['./fb-signature.component.css']
})
export class FbSignatureComponent implements OnInit {
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() globalPrj: any;
  @Input() selectedSectionId;
  lang: any;
  @Input() selectedLanguage;


  constructor() {
    this.confChange = {};
    this.bphChange = {};
  }

  ngOnInit() {
  }
}
