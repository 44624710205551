import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {eCaseAnimations} from 'synto-common';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {JSON} from "tinymce";

@Component({
  selector: 'app-multi-valued-variables-select-dialog',
  templateUrl: './multi-valued-variables-select-dialog.html',
  styleUrls: ['./multi-valued-variables-select-dialog.css'],
  animations: eCaseAnimations
})
export class MultiValuedVariablesSelectDialog implements OnInit {
  multiValuedVariablesForm: FormGroup;
  @Input() multiValuedVariables: any;
  keys = [];
  selectedLang = '';

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<MultiValuedVariablesSelectDialog>,
              private translateService: TranslateService) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.selectedLang = this.translateService.getDefaultLang();
    if (!this.multiValuedVariables) {
      this.multiValuedVariables = {};
    }
    this.keys = Object.keys(this.multiValuedVariables).map((key) => {
      const obj: any = {};
      obj.name = key;
      if (this.multiValuedVariables[key]['dataType'] === 'JsObject') {
        obj.orignalValues = [];
        obj.values = this.multiValuedVariables[key]['value'].map(value => {
          const valueObj = JSON.parse(value);
          const result = Object.keys(valueObj).filter(_key => !_key.endsWith('_label')).map(_key => valueObj[_key]).join(' | ');
          const resultWithUpdatedLabels = Object.keys(valueObj).filter(_key => !_key.endsWith('_label') && valueObj[_key] && valueObj[_key] !== '')
            .map(_key => valueObj[_key + '_label'] ? ((JSON.parse(valueObj[_key + '_label'])[this.selectedLang]).toString() + valueObj[_key]) : valueObj[_key])
            .join(' | ');
          obj.orignalValues.push({
            'result': result,
            'originalVariableObject': valueObj
          })
          return {result, resultWithUpdatedLabels};
        });
      } else {
        obj.values = this.multiValuedVariables[key]['value'].map(e => ({'result': e, 'resultWithUpdatedLabels': e}));
      }
      obj.sortingKey = this.multiValuedVariables[key]['sortingKey'];
      obj.label = this.multiValuedVariables[key]['label'];
      obj.dataType = this.multiValuedVariables[key]['dataType'];
      return obj;
    }).sort((a, b) => (a.sortingKey > b.sortingKey) ? 1 : ((b.sortingKey > a.sortingKey) ? -1 : 0));

    const obj: any = {};
    this.keys.forEach((key) => {
      obj[key.name] = ['', [Validators.required]];
    });
    this.multiValuedVariablesForm = this.formBuilder.group(obj);
    if (this.keys.length === 0) {
      this.onVariablesSelection();
    }
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onVariablesSelection() {
    let obj: any = {};
    this.keys.forEach((key) => {
      if (key.dataType === 'JsObject') {
        const originalVariableObject = key.orignalValues.filter(e => e.result === this.multiValuedVariablesForm.controls[key.name].value)[0]['originalVariableObject'];
        obj[key.name] = this.multiValuedVariablesForm.controls[key.name].value;
        obj = _.merge(obj, originalVariableObject);
      } else {
        obj[key.name] = this.multiValuedVariablesForm.controls[key.name].value;
      }
    });
    this.dialogRef.close(obj);
  }

}
