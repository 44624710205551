<div *ngIf="!bphChange.hide && !confChange.showDetails && !bphChange.showDetails && !globalConf.showDetails"
     [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
     class="{{bphChange.error_class}} hide_{{bphChange.show}}"
     style="margin-bottom: 1.34375em">
  <div class="formLabel">
    <ng-container *ngIf="confChange.condensedLabel && confChange.condensedLabel[selectedLanguage]">
      <span style="font-weight: bolder" [innerHTML]="confChange.condensedLabel[selectedLanguage]"></span>
      <br>
    </ng-container>
    <span *ngIf="confChange.isRequired" style="color: red">* </span>
    <span *ngIf="confChange.tableLabel" [innerHTML]="confChange.tableLabel[selectedLanguage]"></span>
    <span *ngIf="confChange.hasTooltip" style="padding-top: 10px;">
             <mat-icon matTooltip="{{confChange.tooltipText[selectedLanguage]}}"
                       matTooltipClass="tooltip">help_outline</mat-icon>
          </span>

    <span *ngIf="confChange.hasDialog" style="padding-top: 10px;">
             <mat-icon (click)="openDialog(confChange)"
                       style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;">help_outline</mat-icon>
          </span>
  </div>
  <div *ngIf="confChange.showTotalRows && confChange.showTotalRowsVerticalAligment === 'top' || confChange.showTotalRowsVerticalAligment === 'both'"
       [style.float]="confChange.showTotalRowsHorizontalAlignment">
    <p style="font-weight: bolder; font-size: 12px;">{{confChange.totalRowsLabel[selectedLanguage]}} {{bphChange.rows.length}}</p>
  </div>
  <div [style]="confChange.scrollableDataTable ? 'height: 720px; width: 100%; overflow: auto;' : ''">
    <table [id]="selectedSectionId + '_' + confChange.name"
           class="angular-table {{bphChange.error_class}}" style="width: 100%;margin-bottom: 10px;margin-top: 5px">
      <thead style="height: 100px" class="angular-table-thead sticky-header">
      <tr class="angular-table-tr">
        <ng-template [ngForOf]="confChange.subBlocks" let-aBlock ngFor>
          <th *ngIf="!aBlock.hideCell"
              [ngClass]="aBlock.align ? (aBlock.align.includes('left') ? 'align-start' : (aBlock.align.includes('right') ? 'align-end' : 'align-center')) : 'align-center'"
              [style.width.%]="(confChange.isWidthSpecifiedForSubBlocks && aBlock.columnWidthPercentage) ? aBlock.columnWidthPercentage : ''"
              class="angular-table-th"
              style="border-bottom: 1px solid rgba(0,0,0,.12);min-width: 80px;">
            <div fxLayout="row" style="align-items: center;"
                 [style.justify-content]="aBlock.textHeadAlignment ? 'left' : 'center'">
              <span *ngIf="aBlock.isRequired" style="color: red">* </span>
              <span *ngIf="!aBlock.isTableColumnLabel" [innerHTML]="aBlock.label[selectedLanguage]"
                    [style.cursor]="aBlock.isSortable ? 'pointer' : ''"
                    [style.font-weight]="aBlock.sortActive ? 'bolder' : ''"
                    (click)="sortRows(aBlock)"></span>
              <span *ngIf="aBlock.isTableColumnLabel" [innerHTML]="aBlock.tableColumnLabel[selectedLanguage]"
                    [style.cursor]="aBlock.isSortable ? 'pointer' : ''"
                    [style.font-weight]="aBlock.sortActive ? 'bolder' : ''"
                    (click)="sortRows(aBlock)"></span>
              <span *ngIf="aBlock.isShowDateFormat"
                    style="white-space: nowrap"><br>({{aBlock.dateFormatToBeShown[selectedLanguage]}}
                )</span>
              <mat-icon *ngIf="aBlock.isSortable && aBlock.sortActive" style="cursor: pointer" (click)="sortRows(aBlock)">
                {{aBlock.sortDirection === 'asc' ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
              </mat-icon>
            </div>
            <fb-tooltip *ngIf="aBlock.hasTooltip == true && !aBlock.hideTooltipHeader" [lang]="selectedLanguage"
                        [tooltipText]="aBlock.tooltipText"></fb-tooltip>
            <span *ngIf="aBlock.hasDialog" style="padding-top: 10px;">
             <mat-icon (click)="openDialog(aBlock)"
                       style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;">help_outline</mat-icon>
            </span>
          </th>
        </ng-template>
      </tr>
      </thead>
      <thead *ngIf="confChange.isColumnsFiltered" class="angular-table-thead sticky-filter">
      <tr class="angular-table-tr">
        <ng-container *ngFor="let aBlock of confChange.subBlocks; let subBlockIndex=index">
          <th *ngIf="!aBlock.hideCell"
              [style.width.%]="(confChange.isWidthSpecifiedForSubBlocks && aBlock.columnWidthPercentage) ? aBlock.columnWidthPercentage : ''"
              class="angular-table-th mat-table_filtered_header_cell">
            <span class='nodisplay'>.</span>
            <input *ngIf="showFilteredImage && subBlockIndex === 0" alt="clear" class="row_filter_icon"
                   disabled matRipple
                   src="../../../../assets/images/etc/icons8-conversion.svg" type="image">
            <input (click)="resetFilters()" *ngIf="!showFilteredImage && subBlockIndex === 0" alt="clear"
                   class="row_filter_icon" src="../../../../assets/images/etc/icons8-clear-filters.svg"
                   type="image">
            <input *ngIf="isFilterFieldVisible(aBlock)" class="searchFields" type="text"
                   [(ngModel)]="aBlock.valueToFilter"
                   [attr.aria-label]="'ecase.common.search' | eCaseTranslate"
                   [placeholder]="'ecase.common.search' | eCaseTranslate"
                   (keyup)="applyFilter($event, aBlock)">
          </th>
        </ng-container>
      </tr>
      </thead>

      <tbody>
      <ng-container *ngFor="let row of bphChange.rows; let i = index">
        <tr *ngIf="!row.isHidden" class="angular-table-tr"
            [style.background-color]="row.isActivateTableRowColor ? row.tableRowColor : ''"
            [style.font-weight]="row.isActivateTableRowFont ? row.tableRowFont : ''"
            style="border-bottom: 1px solid rgba(0,0,0,.12);">
          <ng-container *ngFor="let subBlock of confChange.subBlocks">
            <td *ngIf="!subBlock.hideCell && (subBlock.type === 'text' || subBlock.type === 'statictext')"
                style="width: 10%;padding-right:10px !important;padding-left:10px">
              <span>{{row[subBlock.name]['value']}}</span></td>
            <td *ngIf="!subBlock.hideCell && subBlock.type === 'button'"
                style="width: 10%;padding-right:10px !important;padding-left:10px">
              <button
                (click)="subBlock.showAdditionalConfirmation ? openButtonDialog(i, subBlock) : executeOnClickJs(i, subBlock)"
                [disabled]="row[subBlock.name].isDisabled || row[subBlock.name].globalReadOnlyMode === 'true'"
                [style.background-color]="(row[subBlock.name].isDisabled || row[subBlock.name].globalReadOnlyMode === 'true') ? '' : confChange.buttonColor"
                class="eCase-dialog-positive-action-button" mat-raised-button type="button">
                <span>{{subBlock.buttonText[selectedLanguage]}}</span>
              </button>
            <td
              *ngIf="!subBlock.hideCell && !(subBlock.type === 'text' || subBlock.type === 'statictext' || subBlock.type === 'button')"></td>
          </ng-container>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>

  <div *ngIf="confChange.showTotalRows && confChange.showTotalRowsVerticalAligment === 'bottom' || confChange.showTotalRowsVerticalAligment === 'both'"
       [style.float]="confChange.showTotalRowsHorizontalAlignment">
    <p style="font-weight: bolder; font-size: 12px;">{{confChange.totalRowsLabel[selectedLanguage]}} {{bphChange.rows.length}}</p>
  </div>
  <ng-container *ngIf="confChange.isTablePaginated">
    <mat-paginator [length]="bphChange.rows.length"
                   [pageSize]="pageSize"
                   [pageIndex]="pageIndex"
                   [pageSizeOptions]="pageSizeOptions"
                   (page)="updatePagination($event)"
                   aria-label="Select page">
    </mat-paginator>
  </ng-container>
</div>

