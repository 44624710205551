import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  EcaseHttpService,
  ECaseNumberFormatterPipe,
  ECaseSnackBarService, ECaseUtils,
  LoginAuthenticationService, PendingChangesGuard, RefreshDataService
} from 'synto-common';
import {DialogComponent} from "../dialog/dialog.component";
import {MatDialog} from "@angular/material/dialog";
import cloneDeep from "lodash/cloneDeep";
import {PageEvent} from "@angular/material/paginator";
import {
  FbButtonComponentDialogComponent
} from "../fb-button/fb-button-component-dialog/fb-button-component-dialog.component";
import {Observable} from "rxjs/internal/Observable";
import {of} from "rxjs/internal/observable/of";
import {Router} from "@angular/router";

@Component({
  selector: 'fb-simple-table',
  templateUrl: './fb-simple-table.component.html',
  styleUrls: ['./fb-simple-table.component.scss']
})
export class FbSimpleTableComponent implements OnInit {
  @Input() selectedLanguage: any;
  @Input() selectedSectionId: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() isFormbuilderModeEnabled: boolean;
  @Input() bphChange: any;
  @Input() globalPrj: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() offlineModeEnabled;
  @Input() blockIndex: number;
  @Input() sections;
  containerConfChange: any;
  showFilteredImage = true;
  beforeFilteringRows = [];
  pageSizeOptions = [5, 10, 25, 100];
  pageSize = 10;
  pageIndex = 0;


  constructor(private translate: TranslateService, private matDialog: MatDialog, private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe,
              private loginAuthenticationService: LoginAuthenticationService, private eCaseHttpService: EcaseHttpService,
              private eCaseSnackBarService: ECaseSnackBarService,  private refreshDataService: RefreshDataService,
              private router: Router, private pendingChangesGuard: PendingChangesGuard) {
    this.confChange = {};
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.value.row = [];
    this.confChange.label = {};
    this.bphChange.error = {};
    this.bphChange.source = {};
  }

  ngOnInit(): void {
    this.containerConfChange = this.confChange;
    if (!this.bphChange) {
      this.bphChange = {};
      this.bphChange.error = {};
      this.bphChange.rows = [];
    }
    if (!this.bphChange.rows) {
      this.bphChange.rows = [];
    }

    if (!this.bphChange.error) {
      this.bphChange.error = {};
    }
    if (this.confChange.paginationValues) {
      this.pageSizeOptions = this.confChange.paginationValues.split(',').map(e => Number(e));
      this.pageSize = this.pageSizeOptions[0];
      this.bphChange.rows = this.bphChange.rows.map((row, index) => {
        row.isHidden = index > (this.pageSize  - 1);
        return row;
      });
    }
    this.beforeFilteringRows = cloneDeep(this.bphChange.rows);


    this.refreshDataService.getNewTableList().subscribe((data) => {
      console.log('change table list')
      if (data && (data.name === this.confChange.name) && data.rows) {
        this.bphChange.rows = data.rows;
        this.beforeFilteringRows = cloneDeep(this.bphChange.rows)
      }
    });
  }




  createArrayOfLength(n): any[] {
    return Array.from(Array(n).keys());
  }

  openDialog(confChange) {
    this.matDialog.open(DialogComponent, {
      width: '600px',
      data: {dialog: confChange.dialogText, selectedLanguage: this.selectedLanguage}
    });
    console.log('donee');
  }

  resetFilters(): void {
    this.bphChange.rows = cloneDeep(this.beforeFilteringRows);
    this.pageIndex = 0;
    if (this.confChange.isTablePaginated) {
      this.bphChange.rows = this.bphChange.rows.map((row, index) => {
        row.isHidden = index > (this.pageSize - 1);
        return row;
      });
    }
    this.showFilteredImage = true;
    this.confChange.subBlocks.forEach(subBlock => {
      subBlock.valueToFilter = '';
    });
  }

  updatePagination(pageEvent: PageEvent): void {
    if (pageEvent.pageIndex === 0) {
      this.bphChange.rows = this.bphChange.rows.map((row, index) => {
        row.isHidden = index > (pageEvent.pageSize - 1);
        return row;
      });
    } else {
      this.bphChange.rows = this.bphChange.rows.map((row, index) => {
        row.isHidden = (index <= ((pageEvent.pageIndex * pageEvent.pageSize) - 1)) || (index > ((pageEvent.pageIndex * pageEvent.pageSize) + pageEvent.pageSize - 1));
        return row;
      });
    }
  }

  sortRows(subBlock: any): void {
    if (subBlock.isSortable) {
      if (subBlock.sortDirection === 'asc') {
        subBlock.sortDirection = 'desc';
      } else if (subBlock.sortDirection === 'desc') {
        subBlock.sortDirection = 'asc';
      } else {
        subBlock.sortDirection = 'asc';
      }
      this.confChange.subBlocks.filter(e => e.name !== subBlock.name).forEach(block => {
        block.sortActive = false;
      })
      const direction = subBlock.sortDirection === 'asc' ? 1 : -1
      this.bphChange.rows = this.bphChange.rows.sort((a, b) => {
        if (subBlock.type === 'text' || subBlock.type === 'statictext') {
          if (!a[subBlock.name].value) {
            a[subBlock.name].value = ''
          }
          if (!b[subBlock.name].value) {
            b[subBlock.name].value = ''
          }
          return (direction * a[subBlock.name].value.toString().toLowerCase().localeCompare(b[subBlock.name].value.toString().toLowerCase()));
        } else if (subBlock.type === 'select' || subBlock.type === 'AutoComplete') {
          if (!a[subBlock.name].value) {
            a[subBlock.name].value = {};
            a[subBlock.name].value.label = {};
            a[subBlock.name].value.label[this.selectedLanguage] = '';
          }
          if (!a[subBlock.name].value.label) {
            a[subBlock.name].value.label = {};
            a[subBlock.name].value.label[this.selectedLanguage] = '';
          }
          if (!b[subBlock.name].value) {
            b[subBlock.name].value = {};
            b[subBlock.name].value.label = {};
            b[subBlock.name].value.label[this.selectedLanguage] = '';
          }
          if (!b[subBlock.name].value.label) {
            b[subBlock.name].value.label = {};
            b[subBlock.name].value.label[this.selectedLanguage] = '';
          }
          return (direction * a[subBlock.name].value.label[this.selectedLanguage].toString().toLowerCase().localeCompare(b[subBlock.name].value.label[this.selectedLanguage].toString().toLowerCase()));
        } else {
          return 0
        }
      });
      this.pageIndex = 0;
      if (this.confChange.isTablePaginated) {
        this.bphChange.rows = this.bphChange.rows.map((row, index) => {
          row.isHidden = index > (this.pageSize - 1);
          return row;
        });
      }
      subBlock.sortActive = true;
    }
  }

  filterHideCellSubBlocks(subBlocks: any[]): any[] {
    return subBlocks.filter(e => !e.hideCell);
  }

  getAlignClass(): string {
    switch (this.confChange.totalTextAlign) {
      case  'start':
        return 'align-start';
      case 'end' :
        return 'align-end';
      case undefined :
        return '';
    }
  }

  getGlobalTotal(rows, column, j, row, currency, totalText, confChange) {
    if (totalText && !confChange.isCountRows) {
      return totalText[this.selectedLanguage];
    } else {
      let r = 0;
      if ((typeof column !== 'undefined') && column !== '') {
        for (let i = 0; i < rows.length; i++) {
          if (!rows[i].isTotal && !rows[i].skipTotal) {
            let newCellVal = 0;
            if (rows[i][column] && confChange.type === 'Checkbox') {
              const arr = rows[i][column]['value'];
              newCellVal = arr.filter(item => item.value === true).length;
            } else if (rows[i][column] && confChange.type === 'select') {
              const arr = rows[i][column]['value'];
              newCellVal = arr.value ? 1 : 0;
            } else if (rows[i][column] && confChange.type === 'text' && confChange.isCountRows) {
              const arr = rows[i][column]['value'];
              newCellVal = arr ? 1 : 0;
            } else if (rows[i][column] && !isNaN(parseInt(rows[i][column]['value'], 10))) {
              newCellVal = parseFloat(rows[i][column]['value']);
            }
            r = r + newCellVal;
          }
        }
        // r = Math.round(r * 10) / 10;
        const newTotalRow = {};
        newTotalRow['isTotal'] = true;
        newTotalRow['value'] = Math.round(r * 100) / 100;
      } else {
        r = null;
      }
      let total;
      if (confChange.inputSuffix) {
        total = (confChange.isCountRows ? (totalText[this.selectedLanguage] + ' ' + this.eCaseNumberFormatterPipe.transform(r, currency, null, this.selectedLanguage, null, confChange.decimalPlacesAllowed))
          : this.eCaseNumberFormatterPipe.transform(r, currency, null, this.selectedLanguage, null, confChange.decimalPlacesAllowed)) + ' ' + confChange.inputSuffix;
      } else {
        total = confChange.isCountRows ? (totalText[this.selectedLanguage] + ' ' + this.eCaseNumberFormatterPipe.transform(r, currency, null, this.selectedLanguage, null, confChange.decimalPlacesAllowed))
          : this.eCaseNumberFormatterPipe.transform(r, currency, null, this.selectedLanguage, null, confChange.decimalPlacesAllowed);
      }
      return total;
    }
  }

  isFilterFieldVisible(subBlock): boolean {
    return subBlock.type === 'text' || subBlock.type === 'statictext' || subBlock.type === 'select' || subBlock.type === 'AutoComplete';
  }

  applyFilter(event, subBlock): void {
    if (this.confChange.isColumnsFiltered) {
      if (!event.target.value || event.target.value === '') {
        this.resetFilters();
      } else {
        this.pageIndex = 0;
        this.bphChange.rows = this.bphChange.rows.filter(row => {
          if (subBlock.type === 'text' || subBlock.type === 'statictext') {
            if (!row[subBlock.name].value) {
              row[subBlock.name].value = ''
            }
            return row[subBlock.name].value.toString().toLowerCase().includes(event.target.value.toLowerCase());
          } else if (subBlock.type === 'select' || subBlock.type === 'AutoComplete') {
            if (!row[subBlock.name].value) {
              row[subBlock.name].value = {};
              row[subBlock.name].value.label = {};
              row[subBlock.name].value.label[this.selectedLanguage] = '';
            }
            if (!row[subBlock.name].value.label) {
              row[subBlock.name].value.label = {};
              row[subBlock.name].value.label[this.selectedLanguage] = '';
            }
            return row[subBlock.name].value.label[this.selectedLanguage].toLowerCase().includes(event.target.value.toLowerCase());
          } else {
            return true;
          }
        });
        if (this.confChange.isTablePaginated) {
          this.bphChange.rows = this.bphChange.rows.map((row, index) => {
            row.isHidden = index > (this.pageSize - 1);
            return row;
          });
        }
        this.showFilteredImage = false;
      }
    }
  }

  getTotal(rows, column, j, tcolumn, row, currency, totalText, type, confChange) {
    if (totalText) {
      return totalText[this.selectedLanguage];
    } else {
      let r = 0;
      if ((typeof column !== 'undefined') && column !== '') {
        for (let i = j - 1; i >= 0; i--) {
          if (rows[i].isTotal && rows[i].column === tcolumn) {
            break;
          }
          if (!rows[i].isTotal) {
            let newCellVal = 0;
            if (rows[i][column] && type === 'Checkbox') {
              const arr = rows[i][column]['value'];
              newCellVal = arr.filter(item => item.value === true).length;
            } else if (rows[i][column] && !isNaN(parseInt(rows[i][column]['value'], 10))) {
              newCellVal = parseFloat(rows[i][column]['value']);
            }
            r = r + newCellVal;
          }
          if (row) {
            if (!row[column]) {
              row[column] = {};
            }
            row[column]['value'] = r;
          }
        }
        row[column]['value'] = Math.round(r * 100) / 100;
      } else {
        r = null;
      }
      let currencySign = '0,0$';
      if (!currency) {
        currencySign = '';
      }
      return this.eCaseNumberFormatterPipe.transform(r, currency, null, this.selectedLanguage, null, confChange.decimalPlacesAllowed);
    }
  }

  getTotalDynamic(rows, column, j, dynaIndex, row, currency, totalText) {
    if (totalText) {
      return totalText[this.selectedLanguage];
    } else {
      let r = 0;
      if ((typeof column !== 'undefined') && column !== '') {
        for (let i = j - 1; i >= 0; i--) {
          if (rows[i].isTotal && rows[i].column === undefined) {
            break;
          }
          if (!rows[i].isTotal) {
            let newCellVal = 0;
            if (rows[i][column] && !isNaN(parseInt(rows[i][column]['value'][dynaIndex], 10))) {
              newCellVal = Math.round(parseFloat(rows[i][column]['value'][dynaIndex]) * 100) / 100;
            }
            r = r + newCellVal;
          }
          if (row) {
            if (!row[column]) {
              row[column] = {};
            }
            row[column]['value'] = r;
          }
        }
      } else {
        r = null;
      }
      let currencySign = '0,0$';
      if (!currency) {
        currencySign = '';
      }
      return currencySign;
    }
  }


  openButtonDialog(rowNumber: number, subBlockConfChange: any) {
    if (subBlockConfChange.isShowComponentOnClick) {
      const globalPrj = this.globalPrj;
      const confChange = subBlockConfChange;
      const globalConf = this.globalConf;
      const globalComponent = this;
      if (subBlockConfChange.componentMetadataJS) {
        eval(subBlockConfChange.componentMetadataJS.replace('this.rowNumber', rowNumber).replace('rowIndex', rowNumber));
      }
      this.globalPrj = globalPrj;
      subBlockConfChange = confChange;
      const updatedObjectToPostJS = cloneDeep(subBlockConfChange.renderedComponent.objectToPostJS);
      Object.keys(subBlockConfChange.renderedComponent.objectToPostJS).map(key => {
        const evalString = (updatedObjectToPostJS[key] + "").includes('rowIndex') ? updatedObjectToPostJS[key].replace('rowIndex', rowNumber) : updatedObjectToPostJS[key] ;
        updatedObjectToPostJS[key] = eval('const calculateValue = (globalPrj) => { return ' + evalString + '}; calculateValue(globalPrj)');
      });
      subBlockConfChange.renderedComponent.context = subBlockConfChange.renderedComponent?.properties?.context || {};
      const updatedContext = cloneDeep(subBlockConfChange.renderedComponent.context);
      Object.keys(subBlockConfChange.renderedComponent.context).map(key => {
        const evalString = (updatedContext[key] + "").includes('rowIndex') ? updatedContext[key].replace('rowIndex', rowNumber) : updatedContext[key] ;
        updatedContext[key] = eval('const calculateValue = (globalPrj) => { return ' + evalString + '}; calculateValue(globalPrj)');
      });
      subBlockConfChange.renderedComponent.objectToPostJS = cloneDeep(updatedObjectToPostJS);
      subBlockConfChange.renderedComponent.properties.context = cloneDeep(updatedContext);
      subBlockConfChange.renderedComponent.context = cloneDeep(updatedContext);
      if (subBlockConfChange.componentDataLoadUrl && subBlockConfChange.renderedComponent.objectToPostJS) {
        this.eCaseHttpService.post(this.loginAuthenticationService.baseUrl + subBlockConfChange.componentDataLoadUrl, subBlockConfChange.renderedComponent.objectToPostJS)
          .subscribe((response: any) => {
            if (response.status) {
              this.openRenderedComponent(response.data, rowNumber, subBlockConfChange);
            } else {
              this.eCaseSnackBarService.show('failure', response.errorMessage);
            }
          });
      } else {
        this.openRenderedComponent([], rowNumber, subBlockConfChange);
      }
    } else {
      const dialogRef = this.matDialog.open(DialogComponent, {
        width: '600px',
        data:
          {
            dialog: subBlockConfChange.dialogText,
            showConfirmOption: true,
            selectedLanguage: this.selectedLanguage
          }
      });
      dialogRef.afterClosed().subscribe((decision) => {
        if (decision) {
          this.executeOnClickJs(rowNumber, subBlockConfChange);
        }
      });
    }
  }

  openRenderedComponent(dataForComponent: any, rowNumber, subBlockConfChange): void {
    console.log(subBlockConfChange);
    if (subBlockConfChange.beforeRenderDataMapJS) {
      eval(subBlockConfChange.beforeRenderDataMapJS);
    }
    const dialogRef = this.matDialog.open(FbButtonComponentDialogComponent, {
      width: '800px'
    })
    const instance = dialogRef.componentInstance;
    instance.dataForComponent = dataForComponent;
    instance.confChange = subBlockConfChange;
    instance.selectedLanguage = this.selectedLanguage;
    dialogRef.afterClosed().subscribe((decision) => {
      if (decision) {
        this.executeOnClickJs(rowNumber, subBlockConfChange);
      }
    });
  }

  executeOnClickJs(rowNumber, subBlockConfChange: any) {
    try {
      this.globalConf.baseUrl = this.loginAuthenticationService.baseUrl;
      this.globalConf.refreshDataService = this.refreshDataService;
      this.globalConf.router = this.router;
      this.globalConf.pendingChangesGuard = this.pendingChangesGuard;
      this.globalConf.eCaseUtils = ECaseUtils;
      this.globalConf.sections = this.sections;
      let modifiedHttpService = this.eCaseHttpService;
      if (this.isFormbuilderModeEnabled) {
        modifiedHttpService = cloneDeep(this.eCaseHttpService);
        modifiedHttpService.get = (url: string, options?, isIgnoreError?): Observable<any> => {
          this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.submitdisabledinformbuildermode', this.selectedLanguage));
          return of({
            'status': false,
            'errorMessage': ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.submitdisabledinformbuildermode')
          });
        };
        modifiedHttpService.post = (url: string, body: any | null, options?, isIgnoreError?): Observable<any> => {
          this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.submitdisabledinformbuildermode', this.selectedLanguage));
          return of({
            'status': false,
            'errorMessage': ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.submitdisabledinformbuildermode')
          });
        };
      }
      subBlockConfChange.executeOnClickJs(this.globalConf, this.globalPrj, this.selectedLanguage, rowNumber, modifiedHttpService, this.eCaseSnackBarService);
    } catch (e) {
      console.error(e);
    }
    if (subBlockConfChange.isSaveOnClickEnabled) {
      const data: any = {};
      data.operation = 'Save Without Validation';
      data.prj = this.globalPrj;
      this.refreshDataService.saveForm(data);
    }
  }


}
