<div *ngIf="isValidHtml && bphChange.globalShowDetail && !bphChange.hide"
     [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'">
  <div class="field {{bphChange.error_class}} hide_{{bphChange.show}}">
    <div class="field ui grid">
      <div  class="formLabel five wide column righttext">
        <ng-container *ngIf="confChange.condensedLabel && confChange.condensedLabel[selectedLanguage]">
          <span style="font-weight: bolder" [innerHTML]="confChange.condensedLabel[selectedLanguage]"></span>
          <br>
        </ng-container>
        <span *ngIf="confChange.isRequired || bphChange.isRequired " style="color: red">* </span>
        <span [innerHTML]="confChange.label[selectedLanguage]"> </span>
        <span *ngIf="confChange.hasTooltip" style="padding-top: 10px;">
           <mat-icon matTooltip='{{confChange.tooltipText[selectedLanguage]}}' matTooltipClass="tooltip" [matTooltipPosition]="confChange.toolTipPosition ? 'confChange.toolTipPosition' : 'above'">
          help_outline
        </mat-icon>
      </span>

        <span *ngIf="confChange.hasDialog" style="padding-top: 10px;">
          <button type="button" mat-icon-button (click)="openDialog()"  style="margin-left: -8px;margin-bottom: -10px;">
         <mat-icon style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important; ">help_outline</mat-icon></button>
      </span>
      </div>
      <div class="nine wide column field" [style.padding-bottom]="bphChange.error[selectedLanguage] ? '15px' : 0">
        <mat-form-field appearance="outline" [ngClass]="bphChange.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid accentBorder' : ''"
                        style="width:100% ; ">
          <textarea [id]="selectedSection +'_'+  confChange.name" (keyup)="changeValueEvent($event)" eCaseDragAndDropTextarea
                    [(ngModel)]="bphChange.value" [attr.aria-label]="confChange.label[selectedLanguage]"
                    [maxlength]="confChange.maxLengthChars"
                    [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'" cols="30"
                    [placeholder]="confChange.placeholder && confChange.placeholder[selectedLanguage] ? confChange.placeholder[selectedLanguage]  : ''"
                    [type]="confChange.inputType"
                    [style.text-align]="confChange.textAlignment"
                    matInput
                    (focusout)="focusOutFunction()"
                    (focusin)="focusInFunction()"
                    [rows]="confChange.rows ? confChange.rows : 5"></textarea>
        </mat-form-field>
        <span *ngIf="bphChange?.error[selectedLanguage]" class="formFieldError">{{bphChange.error[selectedLanguage]}}</span>
        <div  *ngIf="!confChange.isReadOnlyField && !confChange.hideCharacterCount" class="field" [style.margin-top]="bphChange?.error[selectedLanguage] ? '-20px' : '0'" style="text-align: right;">
          <p *ngIf="confChange.maxlength && confChange.countUnit ==='ecase.common.words'" class="counter-style"  >{{getWordCount(bphChange.value)}} /
            {{confChange.maxlength}} {{getTranslatedValue('ecase.common.words')}}</p>
          <p *ngIf="confChange.maxlength && confChange.countUnit ==='ecase.common.characters'" class="counter-style" >{{getTextLength(bphChange.value)}} /
            {{confChange.maxlength}} {{getTranslatedValue('ecase.common.characters')}}</p>
        </div>
      </div>
    </div>
  </div>
</div>


<td  *ngIf="isValidTable" [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'" style="border:0;width: 10%;text-align: center;padding-left:10px;padding-right:10px !important;">
  <div *ngIf="!row.isTotal" class="field {{bphChange.error_class}} hide_{{bphChange.show}}" >
    <mat-form-field appearance="outline" [ngClass]="bphChange.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid accentBorder' : ''" style="width: 100%;" >
    <textarea [id]="selectedSection +'_'+  confChange.name" (keyup)="changeValueEvent($event)" [(ngModel)]="bphChange.value"
              [style.text-align]="confChange.textAlignment" [attr.maxlength]="confChange.maxlength"
              [type]="confChange.inputType"
              [maxlength]="confChange.maxLengthChars"
              [attr.aria-label]="confChange.label[selectedLanguage]"
              [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'" cols="30" matInput
              [placeholder]="confChange.placeholder && confChange.placeholder[selectedLanguage] ? confChange.placeholder[selectedLanguage]  : ''"
              rows="5"></textarea>
    </mat-form-field>

    <!--<div [style.margin-top]="bphChange?.error[selectedLanguage] ? '-20px' : '0'">-->
    <ng-container *ngIf="!confChange.hideCharacterCount">
      <div style="text-align: right;">
        <p *ngIf="confChange.maxlength && confChange.countUnit ==='ecase.common.words'" class="counter-style"  >{{getWordCount(bphChange.value)}} /
          {{confChange.maxlength}} {{getTranslatedValue('ecase.common.words')}}</p>
        <p *ngIf="confChange.maxlength && confChange.countUnit ==='ecase.common.characters'" class="counter-style" >{{getTextLength(bphChange.value)}} /
          {{confChange.maxlength}} {{getTranslatedValue('ecase.common.characters')}}</p>
        <span *ngIf="bphChange?.error[selectedLanguage]" class="formFieldError">{{bphChange.error[selectedLanguage]}}</span>
      </div>
    </ng-container>

  </div>
</td>


<td *ngIf="isValidTableReadonly && !bphChange.hideCell  && !confChange.hideCell" [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
    [attr.rowspan]="bphChange.rowspan && !row.isTotal "
    style="border:0;width: 1%;text-align: center;padding-right:20px">
  <div style="overflow: auto; max-height: 120px;" class="field hide_{{bphChange.show}} {{bphChange.error_class}}">
    {{bphChange.value}}
    <span class="tableFormFieldError" *ngIf="bphChange?.error[selectedLanguage]">{{bphChange.error[selectedLanguage]}}</span>
  </div>
</td>
