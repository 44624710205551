<ng-container *ngIf="mode === 'single'">
  <ngx-monaco-editor #codeEditor (ngModelChange)="contentChanged($event)" (onInit)="init($event)" [(ngModel)]="content"
                     [disabled]="isDisabled" [options]="editorOptions" aria-label="{{ariaLabel}}"
                     class="code-editor border"></ngx-monaco-editor>
</ng-container>
<ng-container *ngIf="mode === 'diff'">
  <ngx-monaco-diff-editor #codeEditor (ngModelChange)="contentChanged($event)" (onInit)="init($event)"
                          [disabled]="isDisabled"
                          [modifiedModel]="modifiedModel"
                          [options]="editorOptions" [originalModel]="originalModel" aria-label="{{ariaLabel}}"
                          class="code-editor border"></ngx-monaco-diff-editor>
</ng-container>
<ng-container *ngIf="mode && (mode !== 'diff' && mode !== 'single')">
  <p>Invalid mode provided : {{mode}}</p>
  <p>Possible choices are 'diff' or 'single'</p>
</ng-container>

