import {eCaseAnimations, ECaseSnackBarService, ECaseUtils, ECaseUtilsGlobal, EcaseHttpService} from 'synto-common';
import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from "rxjs/internal/Observable";
import {of} from "rxjs/internal/observable/of";


@Component({
  selector: 'app-new-document-dialog',
  templateUrl: './add-new-document-dialog.html',
  styleUrls: ['./add-new-document-dialog.scss'],
  animations: eCaseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class AddNewDocumentDialogComponent implements OnInit {

  supportedExtensions = ECaseUtilsGlobal.FILE_UPLOADER_SUPPORTED_EXTENSIONS;
  documentTypeId;
  uploadedFiles: File[] = [];
  selectedLang = '';
  @Input() externalData: any;
  @Input() documentTypes: any[];
  @Input() allowMultipleFileUpload: boolean;

  constructor(private formBuilder: FormBuilder, private translate: TranslateService, private eCaseSnackBarService: ECaseSnackBarService,
              public dialogRef: MatDialogRef<AddNewDocumentDialogComponent>, private ecaseHttpService: EcaseHttpService) {
    this.dialogRef.disableClose = true;
  }

  @Input() addNewDocumentFunction = (eCaseHttpService: EcaseHttpService, formData: FormData): Observable<any> => {
    return of({
      'status': false,
      'errorMessage': 'Not implemented'
    });
  };

  ngOnInit(): void {
    this.selectedLang = this.translate.getDefaultLang();
    if (!this.externalData) {
      this.externalData = {};
    }
    if (!this.documentTypes) {
      this.documentTypes = [];
    }
  }

  uploadFiles($event): void {
    for (const file of $event.target.files) {
      const reducer = (accumulator, currentValue): any => !accumulator ? (accumulator || file.name.endsWith(currentValue)) : accumulator;
      if (!this.supportedExtensions.reduce(reducer, false)) {
        alert(ECaseUtils.getTranslatedValueFromKey(this.translate, 'invalidTypeFile'));
        break;
      } else if (file.size > (25 * 1048576)) {
        alert(ECaseUtils.getTranslatedValueFromKey(this.translate, 'invalidSizeFile'));
        break;
      } else {
        this.uploadedFiles.push(file)
      }
    }
  }

  removeFile(index): void {
    this.uploadedFiles.splice(index, 1);
  }

  onCancelAddingDocument(): void {
    this.dialogRef.close(false);
  }

  onAddNewDocument(): void {
    const formData = new FormData();
    const obj: any = {};
    obj.documentTypeId = this.documentTypeId
    Object.keys(this.externalData).forEach(key => {
      obj[key] = this.externalData[key];
    })
    for (const file of this.uploadedFiles) {
      formData.append(file.name, file);
    }
    formData.append('payLoad', JSON.stringify(obj));
    this.addNewDocumentFunction(this.ecaseHttpService, formData).subscribe({
      next: (response: any) => {
        console.log(response);
        if (response.hasOwnProperty('status') && response.message === 'Success') {
          this.eCaseSnackBarService.show('success', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.documentadded'));
          this.dialogRef.close(true);
        } else {
          this.eCaseSnackBarService.show('failure');
        }
      }, error: (error1) => {
        console.log(error1);
      }
    });
  }

}
