<div *ngIf="!bphChange.hide && !confChange.showDetails && !showDetails"
     [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
     class="{{bphChange.error_class}} hide_{{bphChange.show}}"
     style="margin-bottom: 1.34375em">
  <div class="formLabel">
    <span *ngIf="confChange.isRequired" style="color: red">* </span>{{confChange.tableLabel[selectedLanguage]}}
    <span *ngIf="confChange.hasTooltip" style="padding-top: 10px;">
             <mat-icon matTooltip="{{confChange.tooltipText[selectedLanguage]}}"
                       matTooltipClass="tooltip">help_outline</mat-icon>
          </span>

    <span *ngIf="confChange.hasDialog" style="padding-top: 10px;">
             <mat-icon (click)="openDialog(confChange.dialogText)"
                       style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;">help_outline</mat-icon>
          </span>
  </div>
  <ng-container
    *ngIf="!confChange.hasShowNodata || (confChange.hasShowNodata && !confChange.showAddRow && bphChange.rows.length > 0)">
    <table class="angular-table {{bphChange.error_class}}"
           style="width: 100%;margin-bottom: 10px;margin-top: 5px">
      <thead class="angular-table-thead">

      <tr class="angular-table-tr">

        <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="confChange.fundingCategory.isRequired" style="color: red">* </span>
          <span [innerHTML]="confChange.fundingCategory.label[selectedLanguage]"></span>
          <fb-tooltip *ngIf="confChange.fundingCategory.hasTooltip == true" [lang]="selectedLanguage"
                      [tooltipText]="confChange.fundingCategory.tooltipText"></fb-tooltip>
        </th>

        <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="confChange.fundingSubCategory.isRequired" style="color: red">* </span>
          <span [innerHTML]="confChange.fundingSubCategory.label[selectedLanguage]"></span>
          <fb-tooltip *ngIf="confChange.fundingSubCategory.hasTooltip == true" [lang]="selectedLanguage"
                      [tooltipText]="confChange.fundingSubCategory.tooltipText"></fb-tooltip>
        </th>
        <!--first year-->
        <ng-container *ngFor="let year of bphChange.years">
          <th  class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
            <span *ngIf="confChange['provisionalAmount' + year].isRequired" style="color: red">* </span>
            <span [innerHTML]="confChange['provisionalAmount' + year].label[selectedLanguage]"></span>
            <fb-tooltip *ngIf="confChange['provisionalAmount' + year].hasTooltip == true" [lang]="selectedLanguage"
                        [tooltipText]="confChange['provisionalAmount' + year].tooltipText"></fb-tooltip>
          </th>
        </ng-container>

        <!--secone year-->

        <!--        <th  class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">-->
        <!--          <span *ngIf="confChange.provisionalAmount2.isRequired" style="color: red">* </span>-->
        <!--          <span [innerHTML]="confChange.provisionalAmount2.label[selectedLanguage]"></span>-->
        <!--          <fb-tooltip *ngIf="confChange.provisionalAmount.hasTooltip == true" [lang]="selectedLanguage"-->
        <!--                      [tooltipText]="confChange.provisionalAmount.tooltipText"></fb-tooltip>-->
        <!--        </th>-->


        <!--        &lt;!&ndash;tride  year&ndash;&gt;-->
        <!--        <th  class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">-->
        <!--          <span *ngIf="confChange.provisionalAmount3.isRequired" style="color: red">* </span>-->
        <!--          <span [innerHTML]="confChange.provisionalAmount3.label[selectedLanguage]"></span>-->
        <!--          <fb-tooltip *ngIf="confChange.provisionalAmount.hasTooltip == true" [lang]="selectedLanguage"-->
        <!--                      [tooltipText]="confChange.provisionalAmount.tooltipText"></fb-tooltip>-->
        <!--        </th>-->

        <!--        <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">-->
        <!--          <span *ngIf="confChange.actualAmount.isRequired" style="color: red">* </span>-->
        <!--          <span [innerHTML]="confChange.actualAmount.label[selectedLanguage]"></span>-->
        <!--          <fb-tooltip *ngIf="confChange.actualAmount.hasTooltip == true" [lang]="selectedLanguage"-->
        <!--                      [tooltipText]="confChange.actualAmount.tooltipText"></fb-tooltip>-->
        <!--        </th>-->

        <!-- <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
           <span *ngIf="confChange.comment.isRequired" style="color: red">* </span>
           <span [innerHTML]="confChange.comment.label[selectedLanguage]"></span>
           <fb-tooltip *ngIf="confChange.comment.hasTooltip == true" [lang]="selectedLanguage"
                       [tooltipText]="confChange.comment.tooltipText"></fb-tooltip>
         </th>

         <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
           <span *ngIf="confChange.justificationDocument.isRequired" style="color: red">* </span>
           <span [innerHTML]="confChange.justificationDocument.label[selectedLanguage]"></span>
           <fb-tooltip *ngIf="confChange.justificationDocument.hasTooltip == true" [lang]="selectedLanguage"
                       [tooltipText]="confChange.justificationDocument.tooltipText"></fb-tooltip>
         </th>-->

        <!--<th class="angular-table-th" style="text-align: center;">

        </th>

        <th class="angular-table-th" style="text-align: center;">
          {{confChange.deleteLabel[selectedLanguage]}}
        </th>-->
      </tr>
      </thead>

      <tbody>
      <ng-container *ngFor="let row of bphChange.rows; let i = index">
        <tr *ngIf="!row.isTotal "
            [style.border-bottom]="(row.fundingSubCategory[row.fundingSubCategory.length -1].value !== 881) ? '1px solid rgba(0, 0, 0, .12)' : ''">
          <td *ngIf="!row.fundingCategory.hideCell"
              [attr.rowspan]="row.fundingCategory.rowspan"
              [style.border-right]="(row.fundingCategory.rowspan && row.fundingCategory.rowspan > 1) ? '1px solid rgba(0, 0, 0, .12)' : ''"
              class="angular-table-td">
            <span>{{getCategoryIndex(row.fundingCategory.value)}}
              - {{(row.fundingCategory.label ? row.fundingCategory.label[selectedLanguage] : '') | uppercase}}</span>
            <span
              *ngIf="getDialogText(row.fundingCategory.value + '_')"
              style="padding-top: 10px;">
          <button (click)="openDialog(getDialogText(row.fundingCategory.value + '_'))" mat-icon-button
                  style="margin-left: -8px;margin-bottom: -10px;"
                  type="button">
         <mat-icon style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important; ">help_outline</mat-icon></button>
          </span>
            <div *ngIf="row.fundingCategory.error && row.fundingCategory.error[selectedLanguage]"
                 [ngClass]="row.fundingCategory.error && row.fundingCategory.error[selectedLanguage] ? 'hasError' : 'noError'">
              <span class="formFieldError">{{row.fundingCategory.error[selectedLanguage]}}</span>
            </div>
          </td>

          <td class="angular-table-td" *ngIf="row.fundingSubCategory[row.fundingSubCategory.length -1].value !== 881">
            <span *ngIf="row.fundingSubCategory[row.fundingSubCategory.length -1].value !== 881">
              {{concatFundingSubCategory(row.fundingSubCategory)}}</span>
            <span
              *ngIf="getDialogText(row.fundingCategory.value + '_'+ row.fundingSubCategory[row.fundingSubCategory.length -1].value)"
              style="padding-top: 10px;">
          <button
            (click)="openDialog(getDialogText(row.fundingCategory.value + '_'+ row.fundingSubCategory[row.fundingSubCategory.length -1].value))"
            mat-icon-button
            style="margin-left: -8px;margin-bottom: -10px;"
            type="button">
         <mat-icon style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important; ">help_outline</mat-icon></button>
          </span>
<!--            || row.fundingCategory.value === 875-->
            <ng-container *ngIf="isIncludeOther(row.fundingSubCategory)">
              <br>
              <div *eCaseVar="getLevel(row.fundingSubCategory) as level">
                <label
                  [for]="'subCategoryOtherValue' + i + '_' + level">{{confChange.fundingSubCategory.otherValuelabel[selectedLanguage]}}</label>
                <mat-form-field appearance="outline">
                  <input
                    [(ngModel)]="row['fundingSubCategory'][level].otherValue"
                    [id]="'subCategoryOtherValue' + i + '_' + level"
                    matInput maxlength="100">
                </mat-form-field>
                <p class="counter-style">
                  {{getTextLength(row['fundingSubCategory'][level].otherValue)}}
                  / 100
                  {{'ecase.common.characters' | translate}}</p>
              </div>
            </ng-container>

            <div *ngIf="row.fundingSubCategory.error && row.fundingSubCategory.error[selectedLanguage]"
                 [ngClass]="row.fundingSubCategory.error && row.fundingSubCategory.error[selectedLanguage] ? 'hasError' : 'noError'">
              <span class="formFieldError">{{row.fundingSubCategory.error[selectedLanguage]}}</span>
            </div>
          </td>
          <!-- years 1,2,3-->
          <ng-container   *ngFor="let year of bphChange.years">
            <td *ngIf="row.fundingSubCategory[row.fundingSubCategory.length -1].value !== 881" class="angular-table-td td-padding" style="padding-right: 15px !important;">
              <div>
                <!-- <label for="provisionalAmount"><span
                   style="color: red">*</span> {{confChange.provisionalAmount.label[selectedLanguage]}}</label>-->
                <mat-form-field appearance="outline">
                  <input (keyup)="preventMaxlengthViolation(row['provisionalAmount'+ year].value, 'provisionalAmount'+ year, 9, i)"
                         [(ngModel)]="row['provisionalAmount' + year].value"
                         [id]="'provisionalAmount' + year" matInput
                         [disabled]="confChange['provisionalAmount' + year].isReadOnlyField"
                         maxlength="9" style="text-align: right"
                         type="number">
                  <span matSuffix>{{confChange['provisionalAmount' + year].currency}}</span>
                </mat-form-field>
                <p class="counter-style">
                  {{getTextLength(row['provisionalAmount'+ year].value)}} / 9
                  {{'ecase.common.characters' | translate}}</p>
              </div>
              <!-- <span>{{convertNumberToNumericFormat(confChange.provisionalAmount.currency, row.provisionalAmount.value)}}</span>-->
              <div *ngIf="row['provisionalAmount' + year].error && row['provisionalAmount' + year].error[selectedLanguage]"
                   [ngClass]="row['provisionalAmount' + year].error && row['provisionalAmount' + year].error[selectedLanguage] ? 'hasError' : 'noError'">
                <span class="formFieldError">{{row['provisionalAmount' + year].error[selectedLanguage]}}</span>
              </div>
            </td>
          </ng-container>

        </tr>
        <tr *ngIf="row.isTotal && !categoriesForWhichOtherAndIsTotalIsHidden.includes(bphChange.rows[i - 1].fundingCategory.value)
        && bphChange.rows[i - 1]?.fundingCategory?.value !== 875" class="angular-table-tr boldfont"
            style="border-bottom: 1px solid rgba(0,0,0,.12);">

          <td class="angular-table-td td-padding">
            <span>{{confChange.subTotalLabel[selectedLanguage]}}</span>
          </td>

          <td class="angular-table-td td-padding">
          </td>
          <!--currancy 3 years -->
          <ng-container *ngFor="let year of bphChange.years">
            <td  class="angular-table-td td-padding" style="text-align: right !important;padding-right: 18px !important">
            <span>
              {{getTotal(bphChange.rows, 'provisionalAmount'+ year, i, row.column, row, confChange['provisionalAmount' + year].currency)}}
            </span>
            </td>
          </ng-container>

          <!--currancy 3 years 2 -->
          <!--          <td  class="angular-table-td td-padding" style="text-align: right !important;">-->
          <!--            <span>-->
          <!--              {{getTotal(bphChange.rows, 'provisionalAmount2', i, row.column, row, confChange.provisionalAmount2.currency)}}-->
          <!--            </span>-->
          <!--          </td>-->
          <!--          &lt;!&ndash;currancy 3 years 3 &ndash;&gt;-->
          <!--          <td   class="angular-table-td td-padding" style="text-align: right !important;padding-right: 18px !important;">-->
          <!--            <span>-->
          <!--              {{getTotal(bphChange.rows, 'provisionalAmount3', i, row.column, row, confChange.provisionalAmount3.currency)}}-->
          <!--            </span>-->
          <!--          </td>-->

          <!--          <td class="angular-table-td td-padding" style="text-align: right !important;padding-right: 18px !important;">-->
          <!--            <span>-->
          <!--              {{getTotal(bphChange.rows, 'actualAmount', i, row.column, row, confChange.actualAmount.currency)}}-->
          <!--            </span>-->
          <!--          </td>-->

          <!-- <td class="angular-table-td td-padding">
           </td>

           <td class="angular-table-td td-padding">
           </td>-->

          <!-- <td class="angular-table-td td-padding">
           </td>

           <td class="angular-table-td td-padding">
           </td>-->

        </tr>

      </ng-container>

      <tr *ngIf="confChange.hasGlobalTotal" class="active angular-table-tr boldfont">

        <td class="angular-table-td td-padding">
          <span>{{confChange.totalLabel[selectedLanguage]}}</span>
        </td>

        <td class="angular-table-td td-padding">
        </td>
        <!-- trre years -->
        <ng-container *ngFor="let year of bphChange.years">
          <td  class="angular-table-td td-padding" style="text-align: right !important;padding-right: 18px !important;">
            <span>
              {{getGlobalTotal(bphChange.rows, 'provisionalAmount'+ year, confChange['provisionalAmount' + year].currency)}}
            </span>
          </td>
        </ng-container>
        <!--        <td  class="angular-table-td td-padding" style="text-align: right !important;padding-right: 18px !important;">-->
        <!--            <span>-->
        <!--              {{getGlobalTotal(bphChange.rows, 'provisionalAmount', confChange.provisionalAmount.currency)}}-->
        <!--            </span>-->
        <!--        </td>-->
        <!--        <td  class="angular-table-td td-padding" style="text-align: right !important;padding-right: 18px !important;">-->
        <!--            <span>-->
        <!--              {{getGlobalTotal(bphChange.rows, 'provisionalAmount2', confChange.provisionalAmount2.currency)}}-->
        <!--            </span>-->
        <!--        </td>-->
        <!--        <td  class="angular-table-td td-padding" style="text-align: right !important;padding-right: 18px !important;">-->
        <!--            <span>-->
        <!--              {{getGlobalTotal(bphChange.rows, 'provisionalAmount3', confChange.provisionalAmount3.currency)}}-->
        <!--            </span>-->
        <!--        </td>-->

        <!--        <td class="angular-table-td td-padding" style="text-align: right !important;padding-right: 18px !important;">-->
        <!--            <span>-->
        <!--              {{getGlobalTotal(bphChange.rows, 'actualAmount', confChange.actualAmount.currency)}}-->
        <!--            </span>-->
        <!--        </td>-->

        <!-- <td class="angular-table-td td-padding">
         </td>

         <td class="angular-table-td td-padding">
         </td>-->

        <!--  <td class="angular-table-td td-padding">
          </td>

          <td class="angular-table-td td-padding">
          </td>-->

      </tr>
      </tbody>
    </table>
  </ng-container>

  <!--<div fxLayout="column" style="align-items: flex-start">
    <button
      (click)="addRow(bphChange,confChange)"
      [style.margin-bottom]="bphChange.error[selectedLanguage] ? '5px' : 0"
      color="accent"
      mat-raised-button style="width:max-content">{{confChange.addRowlabel[selectedLanguage]}}
    </button>
    <span *ngIf="bphChange.error[selectedLanguage]" class="tableSpanError">{{bphChange.error[selectedLanguage]}}</span>
  </div>-->
  <span *ngIf="bphChange.error[selectedLanguage]" class="tableSpanError">{{bphChange.error[selectedLanguage]}}</span>
</div>


<!--<div *ngIf="!bphChange.show && confChange.showDetails && showDetails" class="fb_table_dertails">

  <div>
    <label for="fundingCategory"><span
      style="color: red">*</span> {{confChange.fundingCategory.label[selectedLanguage]}}</label>
    <mat-form-field appearance="outline">
      <mat-select (ngModelChange)="onCategoryChange($event, false)"
                  [(ngModel)]="(bphChange.rows[selectedTableRowIndex])['fundingCategory'].value"
                  id="fundingCategory">
        <mat-option *ngFor="let budgetItem of topLevelBudgetItems" [value]="budgetItem.value">
          {{budgetItem.label[selectedLanguage]}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <ng-container
    *ngIf="dataObjectForRecursiveSubCategorySelect.subCategories && dataObjectForRecursiveSubCategorySelect.subCategories.length > 0">
    <ng-container
      *ngTemplateOutlet="RecursiveSubCategorySelect; context:{ 'dataObject': dataObjectForRecursiveSubCategorySelect}"></ng-container>
  </ng-container>


  <ng-template #RecursiveSubCategorySelect let-dataObject="dataObject">
    <div *ngIf="(bphChange.rows[selectedTableRowIndex])['fundingSubCategory'][dataObject.level]">
      <label><span style="color: red">*</span> {{confChange.fundingSubCategory.label[selectedLanguage]}}</label>
      <mat-form-field appearance="outline">
        <mat-select (ngModelChange)="onSubCategoryChange($event, dataObject)"
                    [(ngModel)]="(bphChange.rows[selectedTableRowIndex])['fundingSubCategory'][dataObject.level].value">
          <mat-option *ngFor="let subCategory of dataObject.subCategories" [value]="subCategory.value">
            {{subCategory.label[selectedLanguage]}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container
        *ngIf="(bphChange.rows[selectedTableRowIndex])['fundingSubCategory'][dataObject.level].value === otherValue.value">
        <br>
        <div [style.width]="getWidthFromPlatform()">
          <label [for]="'subCategoryOtherValue' + dataObject.level"><span
            style="color: red">*</span> {{confChange.fundingSubCategory.otherValuelabel[selectedLanguage]}}
          </label>
          <mat-form-field appearance="outline">
            <input
              [(ngModel)]="(bphChange.rows[selectedTableRowIndex])['fundingSubCategory'][dataObject.level].otherValue"
              [id]="'subCategoryOtherValue' + dataObject.level"
              matInput maxlength="100">
          </mat-form-field>
          <p class="counter-style">
            {{getTextLength((bphChange.rows[selectedTableRowIndex])['fundingSubCategory'][dataObject.level].otherValue)}}
            / 100
            {{'ecase.common.characters' | translate}}</p>
        </div>

      </ng-container>
    </div>
    <ng-container
      *ngIf="dataObject.dataObject && dataObject.dataObject.subCategories && dataObject.dataObject.subCategories.length > 0">
      <ng-container
        *ngTemplateOutlet="RecursiveSubCategorySelect; context:{ 'dataObject': dataObject.dataObject}"></ng-container>
    </ng-container>
  </ng-template>

  <div [style.width]="getWidthFromPlatform()">
    <label for="actualAmount"><span style="color: red">*</span> {{confChange.actualAmount.label[selectedLanguage]}}
    </label>
    <mat-form-field appearance="outline">
      <input
        (keyup)="preventMaxlengthViolation((bphChange.rows[selectedTableRowIndex])['actualAmount'].value, 'actualAmount', 9)"
        [(ngModel)]="(bphChange.rows[selectedTableRowIndex])['actualAmount'].value" id="actualAmount"
        matInput maxlength="9"
        type="number">
    </mat-form-field>
    <p class="counter-style">
      {{getTextLength((bphChange.rows[selectedTableRowIndex])['actualAmount'].value)}} / 9
      {{'ecase.common.characters' | translate}}</p>
  </div>

  <div [style.width]="getWidthFromPlatform()">
    <label for="provisionalAmount"><span
      style="color: red">*</span> {{confChange.provisionalAmount.label[selectedLanguage]}}</label>
    <mat-form-field appearance="outline">
      <input
        (keyup)="preventMaxlengthViolation((bphChange.rows[selectedTableRowIndex])['provisionalAmount'].value, 'provisionalAmount', 9)"
        [(ngModel)]="(bphChange.rows[selectedTableRowIndex])['provisionalAmount'].value" id="provisionalAmount"
        matInput maxlength="9"
        type="number">
    </mat-form-field>
    <p class="counter-style">
      {{getTextLength((bphChange.rows[selectedTableRowIndex])['provisionalAmount'].value)}} / 9
      {{'ecase.common.characters' | translate}}</p>
  </div>

  <div>
    <label for="comment">{{confChange.comment.label[selectedLanguage]}}</label>
    <mat-form-field appearance="outline">
      <textarea [(ngModel)]="(bphChange.rows[selectedTableRowIndex])['comment'].value" id="comment"
                matInput maxlength="4000" rows="4" type="text">
      </textarea>
    </mat-form-field>
    <p class="counter-style">
      {{getTextLength((bphChange.rows[selectedTableRowIndex])['comment'].value)}} / 4000
      {{'ecase.common.characters' | translate}}</p>
  </div>

  <div fxLayout="column" fxLayoutAlign="start" style="margin-bottom: 1.34375em;">
    <span>{{confChange.justificationDocument.label[selectedLanguage]}}</span>
    <label
      *ngIf="!((bphChange.rows[selectedTableRowIndex])['justificationDocument'].uploadFile) || (bphChange.rows[selectedTableRowIndex])['justificationDocument'].uploadFile.length === 0"
      [style.width.px]="selectedLanguage=== 'en' ? 125 : 201"
      class="myLabel mat-elevation-z3">
      <mat-icon style="vertical-align: bottom; margin-right: 12px;">cloud_upload</mat-icon>
      <input (change)="uploadDone($event, (bphChange.rows[selectedTableRowIndex])['justificationDocument'])"
             accept="application/pdf" id="upload_doc"
             type="file"/>
      <span style="vertical-align: text-bottom;">{{"filetoUpload" | translate}}</span>
    </label>
    <label *ngIf="(bphChange.rows[selectedTableRowIndex])['justificationDocument'].uploadFile?.length > 0">
      <a
        href="/api/viewAttachedRepositoryDocument/{{(bphChange.rows[selectedTableRowIndex])['justificationDocument'].uploadFile[0].id}}"
        target="_blank">{{(bphChange.rows[selectedTableRowIndex])['justificationDocument'].uploadFile[0].fileName}}</a>
      <button mat-icon-button>
        <mat-icon
          (click)="deleteFile((bphChange.rows[selectedTableRowIndex])['justificationDocument'].uploadFile[0], (bphChange.rows[selectedTableRowIndex])['justificationDocument'])"
          aria-label="Delete">
          delete
        </mat-icon>
      </button>
    </label>
  </div>


  <button (click)="closeDetails(confChange,bphChange)" mat-raised-button
          style=" background-color: #4FC3F7; color: #FFFFFF; margin-right: 1%">
    <span>{{"ecase.common.save" | translate}}</span>
  </button>
  <button (click)="cancel(confChange,bphChange)" color="accent" mat-raised-button>
    <span>{{"ecase.common.cancel" | translate}}</span>
  </button>
</div>-->
