<style>
  .warning_item{
    margin: 20px 0 !important;
    border-radius: 10px;
    padding: 1em;

  }
  .warning_item .warning{
    font-size: 2em;
  }

</style>

<div *ngIf="!bphChange.hide" class="ui ignored info message hide_{{bphChange.show}} warning_item" [style.background-color]="confChange.backgroundColor" [style.color]="bphChange.fontColor">
  <i class="warning sign icon"></i>
  <span [innerHTML] ="confChange.text[selectedLanguage]"></span>
</div>
