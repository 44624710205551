<div *ngIf="!bphChange.hide && !confChange.showDetails && !showDetails"
     [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
     class="{{bphChange.error_class}} hide_{{bphChange.show}}"
     style="margin-bottom: 1.34375em">
  <div class="formLabel">
    <span *ngIf="confChange.isRequired" style="color: red">* </span>
    <span *ngIf="confChange.tableLabel" [innerHTML]="confChange.tableLabel[selectedLanguage]"></span>
    <span *ngIf="confChange.hasTooltip" style="padding-top: 10px;">
             <mat-icon matTooltip="{{confChange.tooltipText[selectedLanguage]}}"
                       matTooltipClass="tooltip">help_outline</mat-icon>
          </span>

    <span *ngIf="confChange.hasDialog" style="padding-top: 10px;">
             <mat-icon (click)="openDialog(confChange)"
                       style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;">help_outline</mat-icon>
          </span>
  </div>
  <ng-container
    *ngIf="!confChange.hasShowNodata || (confChange.hasShowNodata && !confChange.showAddRow && bphChange.rows.length > 0)">
    <table class="angular-table {{bphChange.error_class}}"
           style="width: 100%;margin-bottom: 10px;margin-top: 5px" [id]="selectedSectionId + '_' + confChange.name">
      <thead class="angular-table-thead">
      <tr *ngIf="confChange.showSuperHeader" class="angular-table-tr">
        <ng-template [ngForOf]="confChange.headers" let-aBlock ngFor>
          <th [attr.colspan]="aBlock.colspan" [style.border-left]="(aBlock.colspan && aBlock.colspan > 1) ? '1px solid rgba(0, 0, 0, .12)' : ''"
              [style.border-right]="(aBlock.colspan && aBlock.colspan > 1) ? '1px solid rgba(0, 0, 0, .12)' : ''"
              class="angular-table-th"
              style="text-align: center">
            <span *ngIf="aBlock.isRequired" style="color: red">* </span>
            <span [innerHTML]="aBlock.label[selectedLanguage]"></span>
          </th>
        </ng-template>
      </tr>
      <tr class="angular-table-tr header-table">
        <th class="angular-table-th"></th>
        <th *ngIf="confChange.listName" class="angular-table-th">{{confChange.listNameToShow}}</th>
        <ng-template [ngForOf]="confChange.subBlocks" let-aBlock ngFor>
          <th *ngIf="aBlock.type != 'Table' && !aBlock.hideCell" class="angular-table-th"
              style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
            <span *ngIf="aBlock.isRequired" style="color: red">* </span>
            <span [innerHTML]="aBlock.label[selectedLanguage]"></span>
            <fb-tooltip *ngIf="aBlock.hasTooltip == true" [lang]="selectedLanguage"
                        [tooltipText]="aBlock.tooltipText"></fb-tooltip>
          </th>
        </ng-template>
        <th *ngIf="confChange.teamLeaderConsent"></th>
        <th *ngIf="confChange.teamLeaderConsent"></th>
        <th *ngIf="confChange.readOnlyTable && !confChange.hideEditButton" class="angular-table-th"
            style="text-align: center;padding-right: 10px !important; border-bottom: 1px solid rgba(0, 0, 0, 0.12); min-width: 80px; padding-bottom: 8px !important;">
          {{'editDetails' | translate}}
        </th>        <th *ngIf="confChange.showDelete" class="angular-table-th" style="text-align: center;">
          {{confChange.deleteLabel[selectedLanguage]}}
        </th>
      </tr>
      </thead>

      <tbody>
      <ng-container *ngFor="let row of bphChange.rows; let i = index">
        <tr [class]="row.isTotal?active: ''" class="angular-table-tr main-line">
          <td class="angular-table-td" style="border: 0;
              width: 2%;
              text-align: center;"
              (click)="toggleRow(row)">
            <mat-icon style="cursor: pointer; margin-right: 5px;">
              {{!row.expanded ? 'add_circle_outline' : 'remove_circle_outline'}}
            </mat-icon>
          </td>
          <td *ngIf="confChange.listName" class="angular-table-td" style="border: 0;
              width: 10%;
              text-align: center;
              padding: 10px;">
            {{(conf.lovs[confChange.listName]).list[i].label[selectedLanguage]}}
          </td>

          <ng-container *ngIf="!row.isTotal">
            <ng-container *ngFor="let subBlock of confChange.subBlocks" (click)="toggleRow(row)">
              <ng-container *ngIf="!subBlock.hideCell" [ngSwitch]="subBlock.templateName">
                <ng-container *ngSwitchCase="'statictext'">
                  <ng-container
                    *ngTemplateOutlet="statictext; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'textarea'">
                  <ng-container
                    *ngTemplateOutlet="textarea; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'Radiobutton'">
                  <ng-container
                    *ngTemplateOutlet="Radiobutton; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'select'">
                  <ng-container
                    *ngTemplateOutlet="select; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'Container'">
                  <ng-container
                    *ngTemplateOutlet="Container; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'Table'">
                  <ng-container
                    *ngTemplateOutlet="Table; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'SubmitButton'">
                  <ng-container
                    *ngTemplateOutlet="SubmitButton; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'upload'">
                  <ng-container
                    *ngTemplateOutlet="upload; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'text'">
                  <ng-container
                    *ngTemplateOutlet="text; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'Checkbox'">
                  <ng-container
                    *ngTemplateOutlet="Checkbox; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'datepicker'">
                  <ng-container
                    *ngTemplateOutlet="datepicker; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'AutoComplete'">
                  <ng-container
                    *ngTemplateOutlet="AutoComplete; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'button'">
                  <ng-container
                    *ngTemplateOutlet="button; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'richtext'">
                  <ng-container
                    *ngTemplateOutlet="richtext; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <!-- _BPH_T_SUBCONTENT_S --> <!-- _BPH_T_SUBCONTENT_E -->
          <ng-container *ngIf="row.isTotal">
            <ng-template [ngForOf]="confChange.subBlocks" let-p="index" let-subBlock ngFor>
              <ng-container *ngIf="subBlock.type!== 'text_dynamic_column'">
                <td *ngIf="p>=row.position" class="angular-table-td">
                  <ng-template [ngIf]="subBlock.hasGlobalTotal">
                    {{getTotal(bphChange.rows, subBlock.name, i, row.column, row, subBlock.currency, subBlock.totalText, subBlock.type)}}
                  </ng-template>
                </td>
              </ng-container>
              <ng-container *ngIf="subBlock.type === 'text_dynamic_column'">
                <ng-template [ngForOf]="subBlock.subBlocks" let-dynaIndex="index" let-subCell ngFor>
                  <td *ngIf="p>=row.position" class="angular-table-td">
                    <ng-container *ngIf="subBlock.hasGlobalTotal">
                      {{getTotalDynamic(bphChange.rows, subBlock.name, i, dynaIndex, row, subBlock.currency, subBlock.totalText)}}
                    </ng-container>
                  </td>
                </ng-template>
              </ng-container>
            </ng-template>
          </ng-container>

          <td *ngIf="confChange.teamLeaderConsent && !row.isTotal">
            <div class="field hide_{{bphChange.show}}">
              <button (click)="sendApprovals(row, i)"
                      [attr.aria-label]="row.button_send_approval_request.label[selectedLanguage]"
                      [disabled]="row.button_send_approval_request.isDisabled || bphChange.globalReadOnlyMode === 'true'"
                      class="eCase-dialog-positive-action-button" mat-raised-button>
                <span *ngIf="!row.button_send_approval_request.notApplicable && !row.button_send_approval_request.isShowInfoText">{{row.button_send_approval_request.label[selectedLanguage]}}</span>
                <span *ngIf="row.button_send_approval_request.isShowInfoText" style="color:black;" [innerHTML]="row.button_send_approval_request.infoText[selectedLanguage]"></span>
              </button>
            </div>
          </td>
          <td *ngIf="confChange.teamLeaderConsent && !row.isTotal">
            <div class="field hide_{{bphChange.show}}">
              <button (click)="withdrawApprovals(row)"
                      [attr.aria-label]="row.button_withdraw_request.label[selectedLanguage]"
                      [disabled]="row.button_withdraw_request.isDisabled || bphChange.globalReadOnlyMode === 'true'"
                      class="eCase-dialog-positive-action-button" mat-raised-button>
                <span *ngIf="!row.button_withdraw_request.notApplicable && !row.button_withdraw_request.isShowInfoText">{{row.button_withdraw_request.label[selectedLanguage]}}</span>
                <span *ngIf="row.button_withdraw_request.isShowInfoText" style="color:black;" [innerHTML]="row.button_withdraw_request.infoText[selectedLanguage]"></span>
              </button>
            </div>
          </td>

          <td *ngIf="confChange.teamLeaderConsent && row.isTotal"></td>
          <td *ngIf="confChange.teamLeaderConsent && row.isTotal"></td>

          <td *ngIf="confChange.readOnlyTable && !row.isTotal " class="angular-table-td"
              style="text-align: center;">
            {{bphChange.isShowInfoText}}
            <button
              (click)="editRow(bphChange,confChange,i)"
              *ngIf="!row.rowEditButtonHide"
              [disabled]="confChange.isReadOnlyField || (bphChange.globalReadOnlyMode && bphChange.globalReadOnlyMode === 'true')"
              mat-icon-button>
              <mat-icon>create</mat-icon>
            </button>
          </td>
          <td *ngIf="confChange.showDelete && !row.isTotal " class="angular-table-td"
              style="text-align: center;">
            <!--*ngIf="!row.rowDeleteButtonHide"-->

            <button (click)="deleteRow(bphChange,confChange,i)"
                    *ngIf="!row.rowDeleteButtonHide"
                    [disabled]="confChange.isReadOnlyField || row.isDeleteDisabled || (bphChange.globalReadOnlyMode && bphChange.globalReadOnlyMode === 'true')"
                    [style.opacity]="(confChange.isReadOnlyField || row.isDeleteDisabled || (bphChange.globalReadOnlyMode && bphChange.globalReadOnlyMode === 'true')) ? 0.5 : 1"
                    mat-icon-button>
              <mat-icon>delete</mat-icon>
            </button>
          </td>
          <td *ngIf="row.isTotal && confChange.readOnlyTable" class="angular-table-td">

          </td>
          <td *ngIf="row.isTotal && confChange.showDelete" class="angular-table-td">

          </td>
        </tr>
        <!-- EXPANDABLE ROW -->
        <tr class="angular-table-tr" *ngIf="!row.isTotal && (confChange.subBlocks && confChange.subBlocks[0] && confChange.subBlocks[0].templateContext)"
        [hidden]="!row.expanded">
          <td class="sub-table-td" [attr.colspan]="(confChange.subBlocks.length + (confChange.readOnlyTable ? 1 : 0) + (confChange.showDelete ? 1 : 0) + (confChange.teamLeaderConsent ? 2 : 0))">
            <fb-table [bphChange]='bphChange.rows[i][confChange.subBlocks[0].templateContext.confChange.name]'
                      [confChange]='confChange.subBlocks[0].templateContext.confChange'
                      [globalConf]='globalConf'
                      [showSaveNavBar]='showSaveNavBar'
                      [globalPrj]='globalPrj'
                      [teamTableIndex]="i"
                      [teamTableBlockName]="confChange.name"
                      [teamTableNestedTableBlockName]="confChange.subBlocks[0].templateContext.confChange.name"
                      [isValidHtml]='confChange.subBlocks[0].templateContext.isValidHtml'
                      [isValidTableReadonly]='confChange.subBlocks[0].templateContext.isValidTableReadonly'
                      [isValidTable]='confChange.subBlocks[0].templateContext.isValidTable'
                      [selectedLanguage]='selectedLanguage'
                      [selectedSectionId]='selectedSectionId'
                      style='position: static;'>
            </fb-table>
          </td>
        </tr>
      </ng-container>
      <tr *ngIf="confChange.hasGlobalTotal" class="active angular-table-tr">
        <td *ngFor="let aBlock of filterHideCellSubBlocks(confChange.subBlocks); let i = index" class="angular-table-td"
            style="white-space:nowrap;">
          <ng-template [ngIf]="aBlock.hasGlobalTotal">
            {{getGlobalTotal(bphChange.rows, aBlock.name, i, bphChange.rows[bphChange.rows.length -1], aBlock.currency, aBlock.globalTotalText, aBlock)}}
          </ng-template>
        </td>
        <td *ngIf="confChange.teamLeaderConsent"></td>
        <td *ngIf="confChange.teamLeaderConsent"></td>
        <td *ngIf="confChange.readOnlyTable" class="angular-table-td">

        </td>
        <td *ngIf="confChange.showDelete" class="angular-table-td">

        </td>
      </tr>
      <tr *ngIf="confChange.showTableRowCountRow" class="active angular-table-tr">
        <td class="angular-table-td">
          <span *ngIf="confChange.tableRowCountRowLabel">{{confChange.tableRowCountRowLabel[selectedLanguage]}}</span>
        </td>
        <td class="angular-table-td">
          <span>{{bphChange.rows.length}}</span>
        </td>
        <ng-container *ngIf="confChange.subBlocks.length > 2">
          <td *ngFor="let aBlock of createArrayOfLength(confChange.subBlocks.length - 2); let i = index" class="angular-table-td">

          </td>
        </ng-container>
        <td *ngIf="confChange.teamLeaderConsent"></td>
        <td *ngIf="confChange.teamLeaderConsent"></td>
        <td *ngIf="confChange.readOnlyTable" class="angular-table-td">

        </td>
        <td *ngIf="confChange.showDelete" class="angular-table-td">

        </td>
      </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-container *ngIf="confChange.hasShowNodata && !confChange.showAddRow && bphChange.rows.length === 0">
    <span>{{confChange.showNodatatext[selectedLanguage]}}</span>
  </ng-container>
  <div fxLayout="column" style="align-items: flex-start">
    <button
      (click)="addRow(bphChange,confChange)"
      *ngIf="confChange.showAddRow && !confChange.subBlocks[0].showDetails && (!confChange.isLimitOnRowEnabled || (confChange.isLimitOnRowEnabled && bphChange.rows.length < confChange.rowLimit))"
      [disabled]="confChange.isReadOnlyField || (bphChange.globalReadOnlyMode && bphChange.globalReadOnlyMode === 'true')"
      [style.margin-bottom]="bphChange.error[selectedLanguage] ? '5px' : 0"
      color="accent"
      mat-raised-button style="width:max-content">{{confChange.label[selectedLanguage]}}
    </button>
    <span *ngIf="bphChange.error[selectedLanguage]" class="tableSpanError">{{bphChange.error[selectedLanguage]}}</span>
  </div>
</div>

<div *ngIf="!bphChange.show && confChange.readOnlyTable && confChange.showDetails && showDetails"
     class="fb_team_table_details">
  <!-- _BPH_TE_SUBCONTENT_S --> <!-- _BPH_TE_SUBCONTENT_E -->

  <ng-container *ngFor="let subBlock of confChange.subBlocks">
    <ng-container [ngSwitch]="subBlock.templateName">
      <ng-container *ngSwitchCase="'statictext'">
        <ng-container
          *ngTemplateOutlet="statictext; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'textarea'">
        <ng-container
          *ngTemplateOutlet="textarea; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Radiobutton'">
        <ng-container
          *ngTemplateOutlet="Radiobutton; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'select'">
        <ng-container
          *ngTemplateOutlet="select; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Container'">
        <ng-container
          *ngTemplateOutlet="Container; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Table'">
        <ng-container
          *ngTemplateOutlet="Table; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'SubmitButton'">
        <ng-container
          *ngTemplateOutlet="SubmitButton; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'upload'">
        <ng-container
          *ngTemplateOutlet="upload; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'text'">
        <ng-container
          *ngTemplateOutlet="text; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Checkbox'">
        <ng-container
          *ngTemplateOutlet="Checkbox; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'datepicker'">
        <ng-container
          *ngTemplateOutlet="datepicker; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'AutoComplete'">
        <ng-container
          *ngTemplateOutlet="AutoComplete; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'button'">
        <ng-container
          *ngTemplateOutlet="button; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'richtext'">
        <ng-container
          *ngTemplateOutlet="richtext; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault></ng-container>
    </ng-container>
  </ng-container>

  <button (click)="closeDetails(confChange,bphChange)"
          mat-raised-button
          style=" background-color: #4FC3F7; color: #FFFFFF; margin-right: 1%">
    <span>{{getTranslatedLabel("ecase.common.save")}}</span>
  </button>
  <button (click)="cancel(confChange,bphChange)"
          color="accent" mat-raised-button><span>{{getTranslatedLabel("ecase.common.cancel")}}</span>
  </button>
</div>


<ng-template #SubmitButton let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-submit-button
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj' [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    style='position: static;display: table-cell;vertical-align: middle;padding-right:20px;'></fb-submit-button>
</ng-template>
<ng-template #statictext let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-static-text
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-static-text>
</ng-template>
<ng-template #textarea let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-text-area
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-text-area>
</ng-template>
<ng-template #Radiobutton let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-radiobutton
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-radiobutton>
</ng-template>
<ng-template #select let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-select (emitOutputEvent)='(confChange.isEnableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
             *ngIf="!isValidTableReadonly"
             [bphChange]='((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name])'
             [confChange]='confChange'
             [globalConf]='globalConf'
             [globalPrj]='globalPrj'
             [isValidHtml]='isValidHtml'
             [isValidTableReadonly]='isValidTableReadonly'
             [isValidTable]='isValidTable'
             [selectedLanguage]='selectedLanguage'
             [selectedSectionId]='selectedSectionId'
             [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-select>


  <ng-container
    *eCaseVar="(((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]) ? ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]) : {}) as componentBphChange">
    <td *ngIf="isValidTableReadonly && !componentBphChange.hideCell && !confChange.hideCell"
        [attr.rowspan]="componentBphChange.rowspan"
        [ngClass]="(componentBphChange?.error && componentBphChange?.error[selectedLanguage]) ? 'hasError' : 'noError'"
        [style.border-right]="(componentBphChange.rowspan && componentBphChange.rowspan > 1) ? '1px solid rgba(0, 0, 0, .12)' : ''"
        [style.width.%]="(100 / widthDividerForDynamicWidth)"
        style="border:0;text-align: center;padding-left:10px;padding-right:10px">
      <div class="field {{componentBphChange.error_class}} hide_{{bphChange.show}}">
        {{getValueForFbSelectSubBlock(componentBphChange, selectedLanguage)}}
        <span *ngIf="(componentBphChange?.error && componentBphChange?.error[selectedLanguage])"
              class="tableFormFieldError">{{componentBphChange.error[selectedLanguage]}}</span>
      </div>
    </td>
  </ng-container>
</ng-template>

<ng-template #text let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-text
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-text>
</ng-template>
<ng-template #upload let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-upload
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [offlineModeEnabled]='offlineModeEnabled'
    [selectedLanguage]='selectedLanguage'
    [blockIndex]='(blockIndex + ((rowIndex + 1) / 100))'
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-upload>
</ng-template>
<ng-template #button let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-button
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [offlineModeEnabled]='offlineModeEnabled'
    [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
    [selectedLanguage]='selectedLanguage'
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-button>
</ng-template>
<ng-template #Checkbox let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-checkbox
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-checkbox>
</ng-template>
<ng-template #datepicker let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-datepicker
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-datepicker>
</ng-template>
<ng-template #AutoComplete let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-autoComplete
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-autoComplete>
</ng-template>
<ng-template #richtext let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-rich-text-quill
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-rich-text-quill>
</ng-template>
