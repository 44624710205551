<mat-dialog-content>
  <mat-card-title>{{'ecase.common.internalusers'| eCaseTranslate}}</mat-card-title>
  <div style="width: 100%">
    <button style="float: right;margin-right: 0 !important;" mat-raised-button class="eCase-dialog-positive-action-button" (click)="addInternalUser()">
      {{'ecase.common.addinternaluser'| eCaseTranslate}}
    </button>
  </div>
  <br>
  <br>
  <form [formGroup]="addNewSignerForm" autocomplete="off">
    <mat-label>{{ 'ecase.common.internalusers' | translate }}</mat-label>
    <mat-form-field appearance="outline">
      <mat-chip-list [formControlName]="'internalUserEmails'"
                     [attr.aria-label]="'case.common.internalusersemails' | translate">
        <mat-chip *ngFor="let user of internalSigners" (removed)="removeUser(user.ssoUserId)">
          {{user.name}} - {{user.email}}
          <mat-icon matChipRemove >cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-form-field>

    <mat-divider></mat-divider>
    <br>
    <mat-card-title>{{'ecase.common.externalUsers'| eCaseTranslate}}</mat-card-title>
    <div style="width: 100%">
      <button style="float: right;margin-right: 0 !important;" mat-raised-button class="eCase-dialog-positive-action-button" (click)="addExternalUser()">
        {{'ecase.common.addexternaluser'| eCaseTranslate}}
      </button>
    </div>

    <table mat-table class="ecase-mat-table"
           style="margin-top: 0px"
           id="ecase_e_signature_table"
           [dataSource]="dataSource"
           [@animateStagger]="{value:'50'}"
           matSortActive="{{sortActive}}"
           (matSortChange)="sortColumn($event)"
           matSortDirection="{{sortDirection}}"
           matSort>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><u>{{'ecase.common.justemail' | translate}}</u>
        </th>
        <ng-container *matCellDef="let row; let i = index">
          <td mat-cell [title]="'ecase.common.justemail' | translate" style="padding-right: 24px">
            <mat-form-field appearance="outline">
              <input [attr.aria-label]="'ecase.common.externaluser.email' | translate"
                     [formControlName]="'email' + (i + 1)"
                     matInput maxlength="100">
              <mat-error *ngIf="addNewSignerForm.get('email' + (i + 1)).errors?.required"> {{'ecase.common.thisfieldismandatory' | translate}}</mat-error>
              <mat-error *ngIf="addNewSignerForm.get('email' + (i + 1)).errors?.email"> {{'pleaseEnterValidEmailAddress' | translate}}</mat-error>
            </mat-form-field>
            <p  class="counter-style">{{getTextLength('email' + (i + 1))}} / 100</p>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><u>{{'ecase.common.name' |
          translate}}</u></th>
        <ng-container *matCellDef="let row; let i = index">
          <td mat-cell [title]="'ecase.common.name' | translate" style="padding-right: 24px">
            <mat-form-field appearance="outline">
              <input [attr.aria-label]="'ecase.common.externaluser.name' | translate"
                     [formControlName]="'name' + (i + 1)"
                     matInput maxlength="100">
              <mat-error
                *ngIf="addNewSignerForm.get('name' + (i + 1)).errors?.required"> {{'ecase.common.thisfieldismandatory' | translate}}</mat-error>
            </mat-form-field>
            <p  class="counter-style">{{getTextLength('name' + (i + 1))}} / 100</p>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef><span class='nodisplay'>.</span>
        </th>
        <td mat-cell *matCellDef="let row;" [title]="'ecase.common.table.action' | eCaseTranslate">
          <button (click)="menuTrigger.openMenu();$event.stopPropagation()"
                  #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"
                  class="mat-icon-button eCase-in-table-mat-button"
                  id="ecase_e_signature_table_delete"
                  aria-label="{{'ecase.common.table.actionMore'| eCaseTranslate}}"
                  matTooltip="{{'ecase.common.table.actionMore'| eCaseTranslate}}">
            <mat-icon>more_vert</mat-icon>
            <span>{{ 'ecase.common.table.action' | eCaseTranslate }}</span>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item
                    [disabled]="row.isSignatureGiven"
                    (click)="delete(row.email)"
                    id="eCase_adminDashboard_crm.organization_action_edit">
              {{'ecase.common.delete' | eCaseTranslate}}
            </button>

          </mat-menu>
        </td>
      </ng-container>

      <!-- Header row first group -->
      <ng-container class="filter_row_first_cell mat-elevation-z4" matRipple matColumnDef="header-row-first-group">
        <th mat-header-cell *matHeaderCellDef
            class="mat-table_filtered_header_cell">
          <span class='nodisplay'>.</span>
          <input type="image" *ngIf="showImage" disabled
                 matRipple class="row_filter_icon"
                 src="../../../../assets/images/etc/icons8-conversion.svg" alt="clear">
          <input type="image" *ngIf="!showImage" class="row_filter_icon"
                 src="../../../../assets/images/etc/icons8-clear-filters.svg" alt="clear" (click)="resetFilters(true)">
          <input type="text" class="searchFields"
                 id="ecase_e_signature_table_name_filter"
                 [attr.aria-label]="'ecase.common.name' | eCaseTranslate"
                 [(ngModel)]="filterObject.name"
                 [ngModelOptions]="{standalone: true}"
                 [placeholder]="'ecase.common.search' | eCaseTranslate"
                 (keyup)="applyFilter()">
        </th>
      </ng-container>

      <ng-container matColumnDef="header-row-second-group">
        <th mat-header-cell *matHeaderCellDef class="mat-table_filtered_header_cell"><span class='nodisplay'>.</span>
          <input type="text" class="searchFields"
                 id="ecase_e_signature_table_email_filter"
                 [attr.aria-label]="'ecase.common.email' | eCaseTranslate"
                 [(ngModel)]="filterObject.email"
                 [ngModelOptions]="{standalone: true}"
                 [placeholder]="'ecase.common.search' | eCaseTranslate"
                 (keyup)="applyFilter()">
        </th>
      </ng-container>

      <ng-container matColumnDef="header-row-third-group">
        <th mat-header-cell *matHeaderCellDef class="mat-table_filtered_header_cell"><span class='nodisplay'>.</span>

        </th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-header-row *matHeaderRowDef="['header-row-first-group',
      'header-row-second-group', 'header-row-third-group']"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator *ngIf="externalSigners.length > 0" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                   showFirstLastButtons></mat-paginator>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div style="width: 100%">
    <button style="float: right" class="eCase-dialog-negative-action-button" (click)="onClose()" mat-button>
      {{'ecase.common.cancel' | translate }}
    </button>
    <button style="float: right" class="eCase-dialog-positive-action-button"
            [disabled]="addNewSignerForm.invalid || (externalSigners.length === 0 && addNewSignerForm.get('internalUserEmails').value === '')"
            (click)="onConfirm()"
            mat-button>
      {{'ecase.common.add' | translate }}
    </button>
  </div>
</mat-dialog-actions>
