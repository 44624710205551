import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {
  ECaseConfirmDialogComponent,
  EcaseHttpService,
  ECaseNumberFormatterPipe,
  ECaseSnackBarService, ECaseTranslationLoaderService,
  ECaseUtils,
  ECaseUtilsGlobal,
  HtmlComponent,
  LoginAuthenticationService,
  ParametersService,
  PendingChangesGuard,
  RefreshDataService
} from 'synto-common';
import {Subscription} from 'rxjs/internal/Subscription';
import {DialogService} from '../../formbuilderForms/form/dialog.service';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import {map} from "rxjs/operators";

@Component({
  selector: 'fb-team-table',
  templateUrl: './fb-team-table.component.html',
  styleUrls: ['./fb-team-table.component.scss']
})
export class FbTeamTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() selectedLanguage: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() showSaveNavBar: boolean;
  @Input() bphChange: any;
  @Input() globalPrj: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() selectedSectionId: string;
  @Input() offlineModeEnabled;
  @Input() blockIndex: number;
  showDetails = false;
  selectedTableRowIndex = -1;
  copyBlockData: any;
  subscription: Subscription;
  globalComponent;
  widthDividerForDynamicWidth = 1;
  expandedElement = undefined;
  selectedTeamTableIndex: number;
  teamLeaderLabels;

  constructor(private translate: TranslateService, private matDialog: MatDialog, private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe,
              private refreshDataService: RefreshDataService, private dialogService: DialogService, private loginAuthenticationService: LoginAuthenticationService,
              private parameterService: ParametersService, private eCaseHttpService: EcaseHttpService,
              private eCaseSnackBarService: ECaseSnackBarService, private pendingChangesGuard: PendingChangesGuard,
              private ecaseTranslationLoader: ECaseTranslationLoaderService) {
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.value.label = {};
    this.bphChange.error = {};
    this.bphChange.source = {};
    this.isValidHtml = true;
    this.isValidTable = false;
    this.isValidTableReadonly = false;
    this.translate = translate;
  }

  openDialog(confChange) {
    const dialogRef = this.matDialog.open(DialogComponent, {
      width: '600px',
      data: {dialog: confChange.dialogText, selectedLanguage: this.translate.getDefaultLang()}
    });
  }

  ngOnInit() {
    //TODS add those settings to admin
    //this.confChange.teamLeaderConsent = true;
    console.log('confChangeconfChangeconfChangeconfChangeconfChange');
    console.log(this.confChange);
    if (!this.confChange.teamLeaderComponent){
      this.confChange.teamLeaderComponent = 'autocomplete_leader_name';
    }
    if (!this.confChange.teamLeaderRole) {
      //this.confChange.teamLeaderRole = 332717;
    }

    this.eCaseHttpService.post('/api/getTranslationsForI18nCodes', ['ecase.taxonomy.12.332717']).subscribe(response =>{
      console.log("get the value", response);
      let objLabel = {};
      if(response.i18nCodes) {
        for(const keyLang in response.i18nCodes) {
          objLabel[keyLang] = response.i18nCodes[keyLang]['ecase.taxonomy.12.332717'];
        }
        this.teamLeaderLabels = objLabel;
      }
      console.log("teamleaderlabels", objLabel);



    });

    // "isConfirmDelete" : true
    // "isConfirmDeleteText": {}
    // "nonEditableField" : [ "autocomplete_leader_name" ]
    // "addLeaderToSubTable" : true,

    this.widthDividerForDynamicWidth = this.confChange.subBlocks.length - 1;
    if (this.confChange.readOnlyTable) {
      this.widthDividerForDynamicWidth = this.widthDividerForDynamicWidth + 1;
    }
    if (this.confChange.showDelete) {
      this.widthDividerForDynamicWidth = this.widthDividerForDynamicWidth + 1;
    }
    if (!this.confChange.tableLabel) {
      this.confChange.tableLabel = {};
    }
    if (!this.bphChange) {
      this.bphChange = {};
      this.bphChange.error = {};
      this.bphChange.rows = [];
    }
    if (!this.bphChange.rows) {
      this.bphChange.rows = [];
    }

    if (this.bphChange.rows.length === 0 && this.confChange.listName) {
      const filteredLov = this.globalConf.lovs[this.confChange.listName];
      for (let ii = 0; ii < filteredLov.list.length; ii++) {
        this.addRow(this.bphChange, this.confChange);
      }
    }
   /* this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;
    });*/
    this.subscription = this.refreshDataService.onNewTableRowAdd().subscribe((data) => {
      if (data.confChange.name === this.confChange.name) {
        this.addRow(data.bphChange, data.confChange);
      }
    });
    if(this.confChange.teamLeaderConsent){
      this.initiateLeaderConsent();
    }
  }

  initiateLeaderConsent(){
    this.bphChange.rows.map((row) => {
      if(!row.button_send_approval_request){
        row.button_send_approval_request = {
          label: {
            en: 'Send request',
            fr: 'Envoyer la demande'
          },
          isDisabled: false
        };
        row.button_withdraw_request = {
          label: {
            en: 'Withdraw request',
            fr: 'Retirer la demande'
          },
          isDisabled: true
        };
        //row.rowEditButtonHide = true;
      }
      const teamMemberSsoUserId = row[this.confChange.teamLeaderComponent].value.value;
      const teamMemberRoleId = row.teamMemberRoleId;
      console.log('==================================///');
      console.log(teamMemberSsoUserId);
      console.log(this.globalPrj.formData);
      //row.rowEditButtonHide = true;
      const consentRow = this.globalPrj.formData.TEAM_MEMBER_CONSENTS.data
        .filter(item => Number(item.SSO_USER_ID) === Number(teamMemberSsoUserId) && Number(item.TEAM_MEMBER_ROLE_ID) === Number(teamMemberRoleId))[0];
      if (consentRow) {
        const isConsentGiven = consentRow.IS_CONSENT_GIVEN;
        const dateOfConsent = consentRow.DATE_OF_CONSENT;
        const taskId = consentRow.TASK_ID;
        const date = new Date(dateOfConsent);
        const dateFormat = this.parameterService.parameter['/core/ui/default_date/format'];
        const d2 = moment(dateOfConsent).format(dateFormat.toUpperCase());
        if (isConsentGiven === 'true') {
          row.button_send_approval_request.infoText = {
            'en': ('Date of approval: ' + d2 +' '  + '<a target=\'_blank\' href=\'' + this.loginAuthenticationService.baseUrl + '/api/viewTeamMemberForm/' + this.globalPrj.applicationId + '/' + teamMemberSsoUserId + '/' + taskId + '\'>View</a>'),
            'fr': ('Date de l\'approbation: ' + d2 +' '  + '<a target=\'_blank\' href=\'' + this.loginAuthenticationService.baseUrl + '/api/viewTeamMemberForm/' + this.globalPrj.applicationId + '/' + teamMemberSsoUserId + '/' + taskId + '\'>Afficher</a>')
          };
          row.button_send_approval_request.isValid = true;
          row.button_send_approval_request.isShowInfoText = true;
          row.button_withdraw_request.isDisabled = true;
          row.rowDeleteButtonHide = false;
        } else if (isConsentGiven === 'false') {

          row.button_send_approval_request.infoText = {
            'en': ('Date of refusal: ' + d2 ),
            'fr': ('Date de refus: ' + d2 )
          };
          row.button_send_approval_request.isValid = false;
          row.button_send_approval_request.isShowInfoText = true;
          row.button_withdraw_request.isDisabled = false;
          row.rowDeleteButtonHide = true;

        } else if (isConsentGiven === ' ') {
          row.button_send_approval_request.isDisabled = true;
          row.button_send_approval_request.isValid = false;
          row.button_withdraw_request.isDisabled = false;
          row.rowDeleteButtonHide = true;
        }
      }
    });
    this.validateTable();
  }

  sendApprovals(row, rowIndex){
    row.button_send_approval_request.isDisabled = true;
   // row.rowDeleteButtonHide = true;
    row.rowDeleteButtonHide = false;
    //row.rowEditButtonHide = true;

    const obj: any = {};
    obj.rows = [];
    obj.taskId = this.globalPrj.taskId;
    obj.caseApplicationId = this.globalPrj.caseApplicationId;
    obj.cpmCompetitionId = this.globalPrj.cpmCompetitionID;
    obj.createdBy = this.globalPrj.createdBy.toString();
    obj.isAmdPaReport = (this.globalPrj.paReportId && this.globalPrj.paReportId > 0)
    obj.rows.push({

      'roleId' : row.teamMemberRoleId ? row.teamMemberRoleId : this.confChange.teamLeaderRole,
      'teamMemberSsoUserId' : row[this.confChange.teamLeaderComponent].value.value,
      'caseApplicationTeamId' : (!row.caseApplicationTeamId || row.caseApplicationTeamId < 0 ? undefined : row.caseApplicationTeamId),
      'isReadWriteAccess' : 902,
      'operationType' : 1,
      'isCreateNewTeam': (!row.caseApplicationTeamId || row.caseApplicationTeamId < 0)
    });
    row.button_send_approval_request.isValid = false;
    row.button_send_approval_request.isClicked = true;
    row.button_withdraw_request.isDisabled = false;
    console.log("TEST OBJ")
    this.eCaseHttpService.post('/api/addOrUpdateSyntoTeamMembers', obj).subscribe((response) => {
      if (response.status) {
        if (!row.caseApplicationTeamId || row.caseApplicationTeamId < 0) {
          row.caseApplicationTeamId = response.caseApplicationTeamId;
        }

        this.eCaseSnackBarService.show('success', (this.selectedLanguage === 'en' ? 'Request sent': 'Demande envoyée'));
        const data: any = {};
        this.globalPrj[this.selectedSectionId][this.confChange.name].rows[rowIndex] = row;
        data.operation = 'Save Without Validation';
        data.prj = this.globalPrj;
        this.refreshDataService.saveForm(data);
      } else {
        this.eCaseSnackBarService.show('failure', response.errorMessage);
      }
    });
  }

  withdrawApprovals(row){
    row.button_withdraw_request.isDisabled = true;
    row.rowEditButtonHide = false;
    row.rowDeleteButtonHide = false;

    const obj: any = {};
    obj.rows = [];
    obj.taskId = this.globalPrj.taskId;
    obj.caseApplicationId = this.globalPrj.caseApplicationId;
    obj.cpmCompetitionId = this.globalPrj.cpmCompetitionID;
    obj.createdBy = this.globalPrj.createdBy.toString();
    obj.rows.push({
      'roleId' : row.teamMemberRoleId ? row.teamMemberRoleId : this.confChange.teamLeaderRole,
      'teamMemberSsoUserId' : row[this.confChange.teamLeaderComponent].value.value,
      'isReadWriteAccess' : 902,
      'operationType' : 0,
    });
    obj.isAmdPaReport = (this.globalPrj.paReportId && this.globalPrj.paReportId > 0);
    row.button_send_approval_request.isValid = false;
    row.button_send_approval_request.isClicked = false;
    row.button_withdraw_request.isDisabled = true;
    row.button_send_approval_request.isDisabled = false;
    row.button_send_approval_request.isShowInfoText = false;
    row.button_send_approval_request.infoText = {};
    this.validateTable();

    this.eCaseHttpService.post('/api/addOrUpdateSyntoTeamMembers', obj).subscribe((response) => {
      if (response.status) {
        this.eCaseSnackBarService.show('success', (this.selectedLanguage === 'en' ? 'Request withdrawn': 'Demande retirée'));
      } else {
        this.eCaseSnackBarService.show('failure', response.errorMessage);
      }
    });
    const data: any = {};
    data.operation = 'Save Without Validation';
    data.prj = this.globalPrj;
    this.refreshDataService.saveForm(data);
  }

  getTranslatedLabel(key: string) {

    return ECaseUtils.getTranslatedValueFromKey(this.translate, key, this.selectedLanguage);
  }

  ngAfterViewInit() {
    this.confChange.subBlocks = this.confChange.subBlocks.map((subBlock) => {
      subBlock.templateName = subBlock.type;
      subBlock.templateContext = {
        'bphChange': this.bphChange,
        'confChange': subBlock,
        'isValidHtml': false,
        'isValidTable': !subBlock.readOnlyTable,
        'isValidTableReadonly': subBlock.readOnlyTable
      };
      subBlock.templateContext2 = {
        'bphChange': this.bphChange,
        'confChange': subBlock,
        'isValidHtml': true,
        'isValidTable': false,
        'isValidTableReadonly': false
      };
      return subBlock;
    });
  }

  executeFunction(confChange, bphChange, rowIndex) {
    for (const functionName of confChange.updateOutputEventFunctions) {
      confChange[functionName](bphChange.value, rowIndex, this.globalPrj, this.globalConf, this.selectedLanguage, this.refreshDataService);
    }
  }


  doNothing() {
  }

  addRow(blockData, blockConf) {
    blockConf.isAddRowFunctionActive = true;
    blockConf.isEditRowFunctionActive = false;
    blockConf.showDetails = true;
    this.showDetails = true;
    this.confChange = _.cloneDeep(blockConf);
    this.bphChange = _.cloneDeep(blockData);
    this.copyBlockData = _.cloneDeep(this.bphChange);
    if (!this.confChange.tableLabel) {
      this.bphChange.globalShowDetail = false;
    }
    if (blockConf.readOnlyTable) {
      this.refreshDataService.toggleShowSaveNavBar(false);
    }

    this.confChange = blockConf;
    blockData.rows = blockData.rows || [];
    const aRow: any = {};
    aRow.uniqueRowIndex = blockData.rows.length > 0 ? (Math.max.apply(Math, blockData.rows.map(function(o) {
      return o.uniqueRowIndex ? o.uniqueRowIndex : 0;
    })) + 1) : 1;
    const globalArray = [];
    this.globalPrj = this.globalPrj ? this.globalPrj : _.cloneDeep(this.getCleanedJson(this.globalPrj, false));
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    blockConf.subBlocks.forEach(function(entity) {
      aRow[entity.name] = {};
      if (!blockConf[entity.name]) {
        blockConf[entity.name] = entity;
      }
      blockConf[entity.name].rowSpan = 1;
    });
    if(this.confChange.teamLeaderConsent){
      if(!aRow.button_send_approval_request){
        aRow.button_send_approval_request = {
          label: {
            en: 'Send request',
            fr: 'Envoyer la demande'
          },
          isDisabled: false
        };
        aRow.button_withdraw_request = {
          label: {
            en: 'Withdraw request',
            fr: 'Retirer la demande'
          },
          isDisabled: true
        };
      }
    }
    if (!aRow.caseApplicationTeamId) {
      aRow.caseApplicationTeamId = -1 * blockData.rows.length;
    }
    blockData.rows.push(aRow);
    blockData['selectedRowIndex'] = blockData.rows.length - 1;
    this.selectedTableRowIndex = blockData.rows.length - 1;
    if (blockConf.readOnlyTable) {
      this.globalConf.showDetails = true;
      blockData.selectedRow = aRow;
      blockConf.showDetails = true;
      this.showDetails = true;
      for (let k = 0; k < globalArray.length; k++) {
        try {
          ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = false;
          ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = true;
        } catch (e) {
        }
      }
      blockConf.subBlocks.forEach(function(entity) {
        aRow[entity.name].globalShowDetail = true;
      });
      window.scrollTo(0, 0);
    }
    if (blockConf.type !== 'SchedulerTable') {
      if (!this.confChange.tableLabel[this.selectedLanguage]) {
        this.bphChange.globalShowDetail = false;
      } else {
        this.bphChange.globalShowDetail = true;
      }
    }
    if (this.confChange.addRowFunction) {
      this.confChange.addRowFunction(this.globalPrj, this.globalConf, this, this.selectedLanguage);
    }
    this.bphChange = blockData;
    this.confChange = blockConf;
    this.validateTable();
    this.selectedTeamTableIndex = blockData.rows.length - 1;
  }

  getTotal(rows, column, j, tcolumn, row, currency, totalText, type) {
    if (totalText) {
      return totalText[this.selectedLanguage];
    } else {
      let r = 0;
      if ((typeof column !== 'undefined') && column !== '') {
        for (let i = j - 1; i >= 0; i--) {
          if (rows[i].isTotal && rows[i].column === tcolumn) {
            break;
          }
          if (!rows[i].isTotal) {
            let newCellVal = 0;
            if (rows[i][column] && type === 'Checkbox') {
              const arr = rows[i][column]['value'];
              newCellVal = arr.filter(item => item.value === true).length;
            } else if (rows[i][column] && !isNaN(parseInt(rows[i][column]['value'], 10))) {
              newCellVal = parseFloat(rows[i][column]['value']);
            }
            r = r + newCellVal;
          }
          if (row) {
            if (!row[column]) {
              row[column] = {};
            }
            row[column]['value'] = r;
          }
        }
        row[column]['value'] = Math.round(r * 100) / 100;
      } else {
        r = null;
      }
      let currencySign = '0,0$';
      if (!currency) {
        currencySign = '';
      }
      return this.eCaseNumberFormatterPipe.transform(r, currency, this.selectedLanguage);
    }
  }

  mathFloor(number) {
    return Math.floor(number);
  }

  parseInt(number) {
    return parseInt(number, 10);
  }

  multiYearRowIndexCalculator(rowIndex, noOfYears) {
    noOfYears = isNaN(noOfYears) ? 1 : noOfYears;
    return rowIndex % (noOfYears + 1);
  }

  getHeaderRowIndex(rowIndex, noOfYears) {
    noOfYears = isNaN(noOfYears) ? 1 : noOfYears;
    return (noOfYears + 1) * (Math.floor(rowIndex / (noOfYears + 1)));
  }
  cleanErrors(blockData){
    for (var key in blockData.selectedRow) {
      if (blockData.selectedRow.hasOwnProperty(key) && blockData.selectedRow[key] && blockData.selectedRow[key].hasOwnProperty('error')) {
        blockData.selectedRow[key].error = {};
      }
    }
  }
  closeDetails(blockConf, blockData) {
    this.cleanErrors(blockData);
    let isRowValid = true;
    for(var key in blockData.selectedRow){
        if(blockData.selectedRow.hasOwnProperty(key) && blockData.selectedRow[key] && blockData.selectedRow[key].hasOwnProperty('error')){
          if(blockConf[key] && blockConf[key].isRequired){
            const objError = {
              en: 'This field is required',
              fr: 'Ce champ est obligatoire'
            };
            let componentType = blockConf[key].blockName;
            let componentPath = blockData.selectedRow[key].value;
            if(componentType === "checkbox" || componentType.includes("checkbox")){
              componentType = "checkbox";
              if(componentPath.filter(item => item.value === true).length === 0){
                blockData.selectedRow[key].error = objError;
                isRowValid = false;
              }
            } else if(componentType === "upload" || componentType.includes("upload")) {
              if(componentPath.length === 0) {
                isRowValid = false;
                blockData.selectedRow[key].error = objError;
              }
            } else if(componentType !== "text" && componentType !== "textarea"){
              componentPath = componentPath.value;
            }

            if(componentType !== "checkbox" &&
                componentType !== "upload" &&
                componentType !== "statictext" &&
                (!componentPath || componentPath === null || componentPath === "")){
              blockData.selectedRow[key].error = objError;
              isRowValid = false;
            }
          }
        }
    }
    if(!isRowValid){
      return;
    }
    blockConf.showDetails = false;
    this.globalConf.showDetails = false;
    this.showDetails = false;
    this.orderTable(blockData, blockConf);
    this.reshuffleTable(blockData, blockConf);
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = true;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
      } catch (e) {
      }
    }
    if(this.confChange.nonEditableField){
      this.confChange.nonEditableField.forEach((field) =>{
        this.bphChange.rows[this.selectedTableRowIndex][field].disabled = true;
      });
    }
    if (blockConf.isAddRowFunctionActive) {
      blockConf.addRow(this.globalPrj, this.globalConf, this, this.selectedLanguage, blockData);
    }
    if (blockConf.isEditRowFunctionActive) {
      if (typeof blockConf.editRow === 'function') {
        blockConf.editRow(this.globalPrj, this.globalConf, this, this.selectedTableRowIndex, blockData);
      }
    }

    if(this.confChange.addLeaderToSubTable){
      console.log("ADD TEAM TO SUB TABLE");
      const subTablePrj = this.bphChange.rows[this.selectedTableRowIndex].sub_table;
      const subTableConf = this.confChange.subBlocks[0];
      subTablePrj.rows = subTablePrj.rows || [];
      if(subTablePrj.rows.length === 0) {
        const subRow: any = {};
        subRow.uniqueRowIndex = subTablePrj.rows.length > 0 ? (Math.max.apply(Math, subTablePrj.rows.map(function(o) {
          return o.uniqueRowIndex ? o.uniqueRowIndex : 0;
        })) + 1) : 1;
        subTableConf.subBlocks.forEach(function(entity) {
          subRow[entity.name] = {};
          if (!subTableConf[entity.name]) {
            subTableConf[entity.name] = entity;
          }
          subTableConf[entity.name].rowSpan = 1;
          subRow[entity.name].globalShowDetail = true;
        });
        // subRow.name
        if (subRow.text_member_name) {
          if (this.bphChange.rows[this.selectedTableRowIndex].text_leader_name && this.bphChange.rows[this.selectedTableRowIndex].text_leader_name.value) {
            subRow.text_member_name.value = this.bphChange.rows[this.selectedTableRowIndex].text_leader_name.value.split(', ')[0];
            subRow.text_member_name.disabled = true;
          }
        }
        if (subRow.text_member_last_name) {
          if (this.bphChange.rows[this.selectedTableRowIndex].text_leader_name && this.bphChange.rows[this.selectedTableRowIndex].text_leader_name.value) {
            subRow.text_member_last_name.value = this.bphChange.rows[this.selectedTableRowIndex].text_leader_name.value.split(', ')[0];
            subRow.text_member_last_name.disabled = true;
          }
        }
        if (subRow.text_member_first_name) {
          if (this.bphChange.rows[this.selectedTableRowIndex].text_leader_name && this.bphChange.rows[this.selectedTableRowIndex].text_leader_name.value) {
            subRow.text_member_first_name.value = this.bphChange.rows[this.selectedTableRowIndex].text_leader_name.value.split(', ')[1];
            subRow.text_member_first_name.disabled = true;
          }
        }
        if (subRow.text_member_email) {
          if (this.bphChange.rows[this.selectedTableRowIndex].text_leader_email && this.bphChange.rows[this.selectedTableRowIndex].text_leader_email.value) {
            subRow.text_member_email.value = this.bphChange.rows[this.selectedTableRowIndex].text_leader_email.value;
            subRow.text_member_email.disabled = true;
          }
        }
        if (subRow.select_member_role) {

          subRow.select_member_role['value'] = {};
          subRow.select_member_role.value['label'] = this.teamLeaderLabels;
          subRow.select_member_role.value['value'] = this.confChange.teamLeaderRole;
          subRow.select_member_role.disabled = true;
        }
        if (subRow.autocomplete_member_institution) {
          //subRow.autocomplete_member_institution.label[this.selectedLanguage] = this.bphChange.rows[this.selectedTableRowIndex].text_institution.value;
        }
        subRow.isTeamLeaderRow = true;
        subRow.rowDeleteButtonHide = true;
        // first name
        // email
        // institution
        subTablePrj.rows.push(subRow);
      }
    }

    //const data: any = {};
    //  data.operation = 'Save Without Validation';
    // this.refreshDataService.saveForm(data);
    this.pendingChangesGuard.isPristine = false;
    this.refreshDataService.toggleShowSaveNavBar(true);
    setTimeout(() => {
      const element = document.getElementById(this.selectedSectionId + '_' + this.confChange.name);
      element.scrollIntoView();
    }, 100);
  }

  getTotalDynamic(rows, column, j, dynaIndex, row, currency, totalText) {
    if (totalText) {
      return totalText[this.selectedLanguage];
    } else {
      let r = 0;
      if ((typeof column !== 'undefined') && column !== '') {
        let tcolumn;
        for (let i = j - 1; i >= 0; i--) {
          if (rows[i].isTotal && rows[i].column === tcolumn) {
            break;
          }
          if (!rows[i].isTotal) {
            let newCellVal = 0;
            if (rows[i][column] && !isNaN(parseInt(rows[i][column]['value'][dynaIndex], 10))) {
              newCellVal = Math.round(parseFloat(rows[i][column]['value'][dynaIndex]) * 100) / 100;
            }
            r = r + newCellVal;
          }
          if (row) {
            if (!row[column]) {
              row[column] = {};
            }
            row[column]['value'] = r;
          }
        }
      } else {
        r = null;
      }
      let currencySign = '0,0$';
      if (!currency) {
        currencySign = '';
      }
      return currencySign;
    }
  }

  editRow(blockData, blockConf, i) {
    this.selectedTeamTableIndex = i;
    blockConf.isAddRowFunctionActive = false;
    blockConf.isEditRowFunctionActive = true;
    this.selectedTableRowIndex = i;
    if (blockConf.type === 'SchedulerTable') {
      blockData.rowsForSchedulerTable[i].rowIndex = i;
      blockData.selectedRowIndex = i;
    }
    this.globalPrj.isConfirmationSaved = false;
    if (blockConf.readOnlyTable) {
      this.refreshDataService.toggleShowSaveNavBar(false);
    }
    /*this.addMode = false;
    this.isRemoveMode = false;
    this.isEditMode = true;
    this.editMode = true;
    this.isRowTeamEditToUpdate = true;
    this.selectedblockConf = blockConf; */
    blockData.selectedRow = blockData.rows[i];
    blockConf.showDetails = true;
    this.showDetails = true;
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = false;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = true;
      } catch (e) {
      }
    }
    blockData['selectedRowIndex'] = i;
    if (this.confChange.editRowFunction) {
      this.confChange.editRowFunction(this.globalPrj, this.globalConf, this, this.selectedLanguage);
    }
    this.copyBlockData = _.cloneDeep(this.bphChange);
    window.scrollTo(0, 0);
  }

  orderTableBycolumn(blockData, blockConf, column) {
    if (blockConf && blockConf.showOrderBy) {
      blockData.rows = _.filter(blockData.rows, function(e) {
        return !e.isTotal;
      });
      blockData.rows = _.orderBy(blockData.rows, column);
    }
  }

  orderTable(blockData, blockConf) {
    if (blockConf && blockConf.showOrderBy) {
      blockData.rows = _.filter(blockData.rows, function(e) {
        return !e.isTotal;
      });
      const groupByColumns = blockConf.orderedBy.split(',');
      if (blockConf.orderedByTypes) {
        const groupByTypes = blockConf.orderedByTypes.split(',');
        blockData.rows = _.orderBy(blockData.rows, groupByColumns, groupByTypes);
      } else {
        blockData.rows = _.orderBy(blockData.rows, groupByColumns);
      }
    }
  }

  createArrayOfLength(n): any[] {
    return Array.from(Array(n).keys());
  }

  reshuffleTable(blockData, blockConf) {
    if (blockConf && blockConf.showGroupBy) {
      blockData.rows = _.filter(blockData.rows, function(e) {
        return !e.isTotal;
      });
      if (blockData.rows.length === 0) {
        return;
      }
      const groupByColumns = blockConf.groupedBy.split(',');
      blockData.rows = _.orderBy(blockData.rows, groupByColumns);
      for (let i = groupByColumns.length - 1; i >= 0; i--) {
        if (i - 1 >= 0) {
          this.reshuffleColumn(blockData, blockConf, groupByColumns[i], i, groupByColumns[i - 1]);
        } else {
          this.reshuffleColumn(blockData, blockConf, groupByColumns[i], i, undefined);
        }
      }
    }
  }

  getValueForFbSelectSubBlock(currentValue, lang) {
    if (currentValue.value && currentValue.value.value >= ECaseUtilsGlobal.OTHER_VALUE_TERM_ID) {
      return currentValue.otherValue;
    } else {
      if (currentValue.value && currentValue.value.label) {
        return currentValue.value.label[lang];
      } else {
        return '';
      }
    }
  }

  reshuffleColumn(blockData, blockConf, gbcolumn, k, gPreviouscolumn) {
    let oldCol = 0;
    const indexes = [];
    const groupColumn = gbcolumn.split('.')[0];
    if (gPreviouscolumn) {
      eval('oldCol = blockData.rows[0].' + gbcolumn + ' +\'_\' +blockData.rows[0].' + gPreviouscolumn);
    } else {
      eval('oldCol = blockData.rows[0].' + gbcolumn);
    }
    let rowSpanRow: any = {};
    eval('rowSpanRow = blockData.rows[0].' + groupColumn);
    rowSpanRow.rowspan = 0;
    let newCol;
    for (let i = 0; i < blockData.rows.length; i++) {
      if (!blockData.rows[i].isTotal) {
        if (gPreviouscolumn) {
          eval('newCol = blockData.rows[i].' + gbcolumn + ' +\'_\' +blockData.rows[i].' + gPreviouscolumn);
        } else {
          eval('newCol = blockData.rows[i].' + gbcolumn);
        }
        if (oldCol !== newCol) {
          eval('blockData.rows[i].' + groupColumn + '.hideCell=false');
          indexes.push(i);
          oldCol = newCol;
          eval('rowSpanRow = blockData.rows[i].' + groupColumn);
          rowSpanRow.rowspan = 1;
        } else {
          eval('blockData.rows[i].' + groupColumn + '.hideCell=true');
          rowSpanRow.rowspan++;
        }
      } else {
        rowSpanRow.rowspan++;
      }
    }
    eval('blockData.rows[0].' + groupColumn + '.hideCell=false');
    indexes.push(blockData.rows.length + 1);
    let moved = 0;
    for (let i = 0; i < blockConf.subBlocks.length; i++) {
      if (blockConf.subBlocks[i].name === groupColumn && !blockConf.subBlocks[i].noSubTotal) {
        for (let i = 0; i < indexes.length; i++) {
          blockData.rows.splice(indexes[i] + moved, 0, {'isTotal': true, 'position': k, 'column': groupColumn});
          moved++;
        }
      }
    }
  }

  filterHideCellSubBlocks(subBlocks: any[]): any[] {
    return subBlocks.filter(e => !e.hideCell);
  }

  deleteRow(blockData, blockConf, i) {
    console.log('DELETEDELETEDELETEDELETEDELETEDELETE');
    console.log(blockData);
    console.log(blockConf);
    if (blockConf.isConfirmDelete) {
      if(typeof blockConf.confirmDelete === 'function'){
        blockConf.confirmDelete(this.globalPrj, this.globalConf, this.matDialog, this.translate, i, this, this.dialogService);
      }else{
        if(this.confChange.isConfirmDeleteText){
          const confirmDialogRef = this.matDialog.open(ECaseConfirmDialogComponent, {
            width: '700px',
          });
          const instance = confirmDialogRef.componentInstance;
          instance.confirmMessage = this.confChange.isConfirmDeleteText[this.selectedLanguage];
          confirmDialogRef.afterClosed().subscribe((decision) => {
            if(decision){
              const deletedRow = cloneDeep(blockData.rows[i]);
              deletedRow.isDeleteTeam = true;
              this.deleteTableRow(blockData, blockConf, i);
              if (this.confChange.deleteRowFunction) {
                this.confChange.deleteRowFunction(this.globalPrj, this.globalConf, this, this.selectedLanguage, deletedRow);
              }
              this.removeTeamMember(deletedRow);
            }
          });
        }
      }
    } else {
      this.deleteTableRow(blockData, blockConf, i);
      if (this.confChange.deleteRowFunction) {
        this.confChange.deleteRowFunction(this.globalPrj, this.globalConf, this, this.selectedLanguage);
      }
    }
    this.validateTable();
  }

  removeTeamMember(row): void {
    if (row[this.confChange.teamLeaderComponent] && row[this.confChange.teamLeaderComponent].value && row[this.confChange.teamLeaderComponent].value.value) {
      const obj: any = {};
      obj.rows = [];
      obj.taskId = this.globalPrj.taskId;
      obj.caseApplicationId = this.globalPrj.caseApplicationId;
      obj.cpmCompetitionId = this.globalPrj.cpmCompetitionID;
      obj.createdBy = this.globalPrj.createdBy.toString();
      obj.isAmdPaReport = (this.globalPrj.paReportId && this.globalPrj.paReportId > 0);
      obj.rows.push({
        'roleId' : this.confChange.teamLeaderRole,
        'teamMemberSsoUserId' : row[this.confChange.teamLeaderComponent].value.value,
        'isReadWriteAccess' : 902,
        'operationType' : 0,
        'isDeleteTeam' : row.isDeleteTeam,
        'caseApplicationTeamId' : row.caseApplicationTeamId
      });
      this.eCaseHttpService.post('/api/addOrUpdateSyntoTeamMembers', obj).subscribe((response) => {
        if (response.status) {
          //this.eCaseSnackBarService.show('success', (this.selectedLanguage === 'en' ? 'Request sent': 'Demande envoyée'));
        } else {
          this.eCaseSnackBarService.show('failure', response.errorMessage);
        }
      });
      const data: any = {};
      data.operation = 'Save Without Validation';
      data.prj = this.globalPrj;
      this.refreshDataService.saveForm(data);
    }
  }

  validateTable(){
    console.log('VALAALIDAAAAAAAAAATE!!!!!!!!');
    this.bphChange.isTableValid = this.bphChange.rows.filter(row =>  !row.button_send_approval_request.notApplicable && !row.button_send_approval_request.isValid).length == 0;
    console.log(this.bphChange.rows);
    console.log(this.bphChange.isTableValid);
    if(this.bphChange.isTableValid){
      this.globalConf[this.selectedSectionId].customSuccessValidationMessage = {
        'en' : ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.formbuilder.validTeamTable.message'),
        'fr' : ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.formbuilder.validTeamTable.message')
      };
    }else{
      this.globalConf[this.selectedSectionId].customSuccessValidationMessage = {
        'en' : 'Approval is required by all team leaders.',
        'fr' : 'Approval is required by all team leaders.',
      };
    }
  }

  // Do not delete, used in block js of some tables.
  deleteMultiYearRow(i, blockData, blockConf) {
    blockData.noOfYears = blockData.noOfYears ? blockData.noOfYears : blockConf.noOfYears;
    const noOfYears = isNaN(parseInt(blockData.noOfYears, 10)) ? 1 : parseInt(blockData.noOfYears, 10);
    const rowModulesIndex = (i % (noOfYears + 1)).toString();
    if (blockConf.subBlocks) {
      for (let ii = 0; ii < blockConf.subBlocks.length; ii++) {
        if (blockConf.subBlocks[ii] && blockConf.subBlocks[ii].type === 'upload') {
          const subBlockName = blockConf.subBlocks[ii].name;
          if (((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName]?.value) {
            for (let iiTobd = 0; iiTobd < blockData.rows[i][rowModulesIndex + ii.toString()][subBlockName].value.length; iiTobd++) {
              if (((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName].value[iiTobd] && ((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName].value[iiTobd].fileUploaded) {
                if (!this.globalPrj.upload_to_be_deleted) {
                  this.globalPrj.upload_to_be_deleted = [];
                }
                this.globalPrj.upload_to_be_deleted.push(((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName].value[iiTobd].id);
              }
            }
          }
        }
      }
    }

    blockData.rows.splice(i, noOfYears + 1);
    // this.resuffleTable(blockData, blockConf);
  }

  deleteTableRow(blockData, blockConf, i) {
    console.log('DDDDDDDDDDDDDDDDDD');
    console.log(blockData);
    console.log(blockData);
    this.pendingChangesGuard.isPristine = false;
    this.globalPrj.isConfirmationSaved = false;
    /*this.isRemoveMode = true;
    this.isRowTeamEditToUpdate = false;
    this.addMode = false;
    this.isEditMode = false;*/
    if (blockConf.subBlocks) {
      for (let ii = 0; ii < blockConf.subBlocks.length; ii++) {
        if (blockConf.subBlocks[ii] && blockConf.subBlocks[ii].type === 'upload') {
          const subBlockName = blockConf.subBlocks[ii].name;
          if (blockData.rows[i][subBlockName].value) {
            for (let iiTobd = 0; iiTobd < blockData.rows[i][subBlockName].value.length; iiTobd++) {
              if (blockData.rows[i][subBlockName].value[iiTobd] && blockData.rows[i][subBlockName].value[iiTobd].fileUploaded) {
                if (!this.globalPrj.upload_to_be_deleted) {
                  this.globalPrj.upload_to_be_deleted = [];
                }
                this.globalPrj.upload_to_be_deleted.push(blockData.rows[i][subBlockName].value[iiTobd].id);
              }
            }
          }
        }
      }
    }
    blockData.rows.splice(i, 1);
    this.reshuffleTable(blockData, blockConf);
    this.bphChange = _.cloneDeep(blockData);
    (this.globalPrj[this.selectedSectionId])[blockConf.name] = this.bphChange;
    this.validateTable();
  }

  getGlobalTotal(rows, column, j, row, currency, totalText, confChange) {
    if (totalText && !confChange.isCountRows) {
      return totalText[this.selectedLanguage];
    } else {
      let r = 0;
      if ((typeof column !== 'undefined') && column !== '') {
        for (let i = 0; i < rows.length; i++) {
          if (!rows[i].isTotal) {
            let newCellVal = 0;
            if (rows[i][column] && confChange.type === 'Checkbox') {
              const arr = rows[i][column]['value'];
              newCellVal = arr.filter(item => item.value === true).length;
            } else if (rows[i][column] && confChange.type === 'select') {
              const arr = rows[i][column]['value'];
              newCellVal = arr.value ? 1 : 0;
            } else if (rows[i][column] && confChange.type === 'text' && confChange.isCountRows) {
              const arr = rows[i][column]['value'];
              newCellVal = arr ? 1 : 0;
            } else if (rows[i][column] && !isNaN(parseInt(rows[i][column]['value'], 10))) {
              newCellVal = parseFloat(rows[i][column]['value']);
            }
            r = r + newCellVal;
          }
        }
        r = Math.round(r * 10) / 10;
        const newTotalRow = {};
        newTotalRow['isTotal'] = true;
        newTotalRow['value'] = Math.round(r * 100) / 100;
      } else {
        r = null;
      }
      let currencySign = '0,0$';
      if (!currency) {
        currencySign = '0,0';
      }
      let total = '';
      if (confChange.inputSuffix) {
        total = (confChange.isCountRows ? (totalText[this.selectedLanguage] + ' ' + this.eCaseNumberFormatterPipe.transform(r, currency, this.selectedLanguage)) : this.eCaseNumberFormatterPipe.transform(r, currency, this.selectedLanguage)) + ' ' + confChange.inputSuffix;
      } else {
        total = confChange.isCountRows ? (totalText[this.selectedLanguage] + ' ' + this.eCaseNumberFormatterPipe.transform(r, currency, this.selectedLanguage)) : this.eCaseNumberFormatterPipe.transform(r, currency, this.selectedLanguage);
      }
      return total;
    }
  }

  calculateMultiplication(n1, n2) {
    let res = 0;
    try {
      if (n1 !== null && n2 !== null) {
        res = n1 * n2;
      }
    } catch (e) {
      return 0;
    }
    return res;
  }

  calculateDivision(n1, n2) {
    let res = 0;
    try {
      if (n1 !== null && n2 !== null && n2 !== 0) {
        res = n1 / n2;
      }
    } catch (e) {
      return 0;
    }
    return res;
  }

  getExpressionOutputNumber(row, label, lang, i) {
    let r;
    try {
      eval('r = ' + label);
    } catch (e) {
    }
    if (isNaN(r)) {
      r = '';
    }
    return r;
  }

  getExpressionOutput(row, label, lang, i) {
    const r = '';
    try {
      eval('r = ' + label);
    } catch (e) {
    }
    return r;
  };

  cancel(blockConf, blockData) {
    blockConf.showDetails = false;
    this.showDetails = false;
    this.orderTable(blockData, blockConf);
    this.reshuffleTable(blockData, blockConf);
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = true;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
      } catch (e) {
      }
    }
    this.refreshDataService.toggleShowSaveNavBar(true);
    this.bphChange = _.cloneDeep(this.copyBlockData);
    (this.globalPrj[this.selectedSectionId])[blockConf.name] = this.bphChange;
    setTimeout(() => {
      const element = document.getElementById(this.selectedSectionId + '_' + this.confChange.name);
      element.scrollIntoView();
    }, 100);
  }

  getCleanedJson(json, isCleanError) {
    if (json === null || !json) {
      json = {};
    }
    if (json['pdf']) {
      json['pdf'] = {};
    }
    if (isCleanError) {
      if (json['error'] || typeof json['error'] === 'object') {
        json['error'] = {};
      }
      if (json['error_class'] === '' || json['error_class'] === 'error' || typeof json['error'] === 'string') {
        json['error_class'] = '';
      }
    }
    for (const key in json) {
      if (!json.hasOwnProperty(key)) {
        continue;
      }
      if (typeof json[key] === 'object') {
        this.getCleanedJson(json[key], isCleanError);
      }
    }
    return json;
  }

  ngOnDestroy(): void {
    this.globalConf.showDetails = false;
    this.confChange.showDetails = false;
    this.showDetails = false;
    this.refreshDataService.toggleShowSaveNavBar(true);
    this.subscription.unsubscribe();
  }

  convertHTMLComponentToJSONObject(htmlComponent: HtmlComponent): any {
    const convertedObject: any = {};
    convertedObject.name = htmlComponent.name;
    convertedObject.type = htmlComponent.type;
    convertedObject.htmlPdf = htmlComponent.htmlPdf;
    convertedObject.blockName = htmlComponent.blockName;
    convertedObject.isNew = htmlComponent.isNew;
    convertedObject.hasErrorInComponent = htmlComponent.hasErrorInComponent;
    return convertedObject;
  }

  toggleRow(row){
    if(!row.expanded){
      row.expanded = true;
    }else{
      row.expanded = false;
    }
  }
}
