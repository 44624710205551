import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {ECaseUtils, RefreshDataService} from 'synto-common';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
  selector: 'fb-scheduler-table',
  templateUrl: './fb-scheduler-table.component.html',
  styleUrls: ['./fb-scheduler-table.component.scss']
})
export class FbSchedulerTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() selectedLanguage: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() bphChange: any;
  @Input() globalPrj: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() selectedSectionId: string;
  showDetails = false;
  selectedTableRowIndex = -1;
  copyBlockData: any;
  subscription: Subscription;
  addMode = false;
  isEditMode = false;
  isCustomLabel = false;
  constructor(private translate: TranslateService, private matDialog: MatDialog, private refreshDataService: RefreshDataService) {
    this.bphChange = {};
    this.bphChange.error = {};
    this.bphChange.rows = this.bphChange.rows || [];
    this.bphChange.rowsForSchedulerTable = this.bphChange.rowsForSchedulerTable || [];
    this.bphChange.monthArray = this.bphChange.monthArray || [];
    this.bphChange.customLabelList = this.bphChange.customLabelList || [];
    this.bphChange.getValue = function() {
      return this.bphChange.value;
    };

  }

  ngOnInit() {
    if (!this.bphChange) {
      this.bphChange = {};
      this.bphChange.error = {};
      this.bphChange.rows = [];
    }
    if (!this.bphChange.rows) {
      this.bphChange.rows = [];
    } else {
     // this.bphChange.rowsForSchedulerTable = this.bphChange.rows;
    }
    this.bphChange.multiYearPath = this.confChange.noOfYears;
    this.bphChange.type = this.confChange.type;
    this.bphChange.yearArray = this.confChange.yearArray;
    this.bphChange.selectedYearsArray = this.confChange.selectedYearsArray;
    /*this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;
    });*/
    this.isCustomLabel = this.confChange.hasCustomLabel;
  }

  ngAfterViewInit(): void {
    this.confChange.subBlocks = this.confChange.subBlocks.map((subBlock) => {
      subBlock.templateName = subBlock.type;
      subBlock.templateContext = {
        'bphChange': this.bphChange,
        'confChange': subBlock,
        'isValidHtml': false,
        'isValidTable': !subBlock.readOnlyTable,
        'isValidTableReadonly': subBlock.readOnlyTable
      };
      subBlock.templateContext2 = {
        'bphChange': this.bphChange,
        'confChange': subBlock,
        'isValidHtml': true,
        'isValidTable': false,
        'isValidTableReadonly': false
      };
      return subBlock;
    });
  }

  deleteRowForSchedulerTable(blockData, blockConf, i) {
    blockData.rowsForSchedulerTable.splice(i, 1);
    blockData.rows.splice(i, 1);
    console.log('after delete......');
    console.log(blockData);
  }

  filterOutMatSlider(subBlocks) {
    return subBlocks.filter(a => a.type !== 'MatSlider');
  }
  getTranslatedLabel(key: string){

    return ECaseUtils.getTranslatedValueFromKey(this.translate,key,this.selectedLanguage);
  }

  editRow(blockData, blockConf, i) {
    this.addMode = false;
    this.isEditMode = true;
    this.selectedTableRowIndex = i;
    if (blockConf.type === 'SchedulerTable') {
      blockData.rowsForSchedulerTable[i].rowIndex = i;
      blockData.rows[i].rowIndex = i;
      blockData.selectedRowIndex = i;
    }
    this.globalPrj.isConfirmationSaved = false;
    if (blockConf.readOnlyTable) {
      this.refreshDataService.toggleShowSaveNavBar(false);
    }
    /*this.isRemoveMode = false;
    this.editMode = true;
    this.isRowTeamEditToUpdate = true;
    this.selectedblockConf = blockConf; */
    blockData.selectedRow = blockData.rows[i];
    blockConf.showDetails = true;
    this.showDetails = true;
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = false;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = true;
      } catch (e) {
      }
    }
    blockData['selectedRowIndex'] = i;
    this.copyBlockData = _.cloneDeep(this.bphChange);
    window.scrollTo(0, 0);
  }

  addRow(blockData, blockConf) {
    blockConf.showDetails = false;
    this.showDetails = false;
    this.addMode = true;
    this.isEditMode = false;
 /*   this.isRowTeamEditToUpdate = false;
    this.isRemoveMode = false;*/
    this.confChange = _.cloneDeep(blockConf);
    this.bphChange = _.cloneDeep(blockData);
    this.copyBlockData = _.cloneDeep(this.bphChange);
    console.log('ddddddddddd');
    console.log(this.confChange);
    if (!this.confChange.tableLabel) {
      this.bphChange.globalShowDetail = false;
    }
    if (blockConf.readOnlyTable) {
      this.refreshDataService.toggleShowSaveNavBar(false);
    }

    this.confChange = blockConf;
    blockData.rows = blockData.rows || [];
    const aRow: any = {};
    aRow.uniqueRowIndex = blockData.rows.length > 0 ? (Math.max.apply(Math, blockData.rows.map(function(o) {
      return o.uniqueRowIndex ? o.uniqueRowIndex : 0;
    })) + 1) : 1;
    const globalArray = [];
    this.globalPrj = this.globalPrj ? this.globalPrj : _.cloneDeep(this.getCleanedJson(this.globalPrj, false));
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    blockConf.subBlocks.forEach(function(entity) {
      aRow[entity.name] = {};
      if (!blockConf[entity.name]) {
        blockConf[entity.name] = entity;
      }
      blockConf[entity.name].rowSpan = 1;
    });
    blockData.rows.push(aRow);
    blockData['selectedRowIndex'] = blockData.rows.length - 1;
    this.selectedTableRowIndex = blockData.rows.length - 1;
    if (blockConf.readOnlyTable) {
      blockData.selectedRow = aRow;
      blockConf.showDetails = true;
      this.showDetails = true;
      for (let k = 0; k < globalArray.length; k++) {
        try {
          ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = false;
          ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = true;
        } catch (e) {
        }
      }
      blockConf.subBlocks.forEach(function(entity) {
        aRow[entity.name].globalShowDetail = true;
      });
      window.scrollTo(0, 0);
    }
    if (blockConf.type !== 'SchedulerTable') {
      if (!this.confChange.tableLabel[this.selectedLanguage]) {
        console.log('no label');
        this.bphChange.globalShowDetail = false;
      } else {
        this.bphChange.globalShowDetail = true;
      }
    }
    this.bphChange = blockData;
    this.confChange = blockConf;
  }

  getCleanedJson(json, isCleanError) {
    if (json === null || !json) {
      json = {};
    }
    if (json['pdf']) {
      json['pdf'] = {};
    }
    if (isCleanError) {
      if (json['error'] || typeof json['error'] === 'object') {
        json['error'] = {};
      }
      if (json['error_class'] === '' || json['error_class'] === 'error' || typeof json['error'] === 'string') {
        json['error_class'] = '';
      }
    }
    for (const key in json) {
      if (!json.hasOwnProperty(key)) {
        continue;
      }
      if (typeof json[key] === 'object') {
        this.getCleanedJson(json[key], isCleanError);
      }
    }
    return json;
  }

  closeDetailsForSchedulerTable(blockData, blockConf) {
    if (blockConf.type === 'SchedulerTable') {
       if (this.addMode) {
         const tempRowObject = {};
         for (const subBlock of blockConf.subBlocks) {
           if (subBlock.type === 'text') {
             for (const key in blockData.rows[blockData.rows.length - 1]) {
               if (key === subBlock.name) {
                 tempRowObject['taskTitle'] = blockData.rows[blockData.rows.length - 1][key].value;
               }
             }
           } else if (subBlock.type === 'MatSlider') {
             for (const key in blockData.rows[blockData.rows.length - 1]) {
               if (key === subBlock.name) {
                 tempRowObject['userSelectedStartMonth'] = blockData.rows[blockData.rows.length - 1][key].startMonthSliderInitialValue;
                 tempRowObject['userSelectedMaxMonth'] = blockData.rows[blockData.rows.length - 1][key].endMonthSliderInitialValue;
               }
             }
           }
         }
         console.log('final object.....');
         blockData.rowsForSchedulerTable.push(tempRowObject);
         console.log(blockData);
       }
       else if (this.isEditMode) {
         console.log('inside edit mode');
         console.log(blockData);
         for (const subBlock of blockConf.subBlocks) {
           for (const key in blockData.selectedRow) {
             if (subBlock.type === 'text' && subBlock.name === key) {
               for (const row of blockData.rowsForSchedulerTable) {
                 if (row.rowIndex === blockData.selectedRowIndex) {
                   row['taskTitle'] = blockData.selectedRow[key].value;
                 }
               }
             } else if (subBlock.type === 'MatSlider' && subBlock.name === key) {
               for (const row of blockData.rowsForSchedulerTable) {
                 if (row.rowIndex === blockData.selectedRowIndex) {
                   row['userSelectedStartMonth'] = blockData.selectedRow[key].startMonthSliderInitialValue;
                   row['userSelectedMaxMonth'] = blockData.selectedRow[key].endMonthSliderInitialValue;
                 }
               }
             }
           }
         }
         console.log('final blockdata is');
         console.log(blockData);
       }
    }
    this.addMode = false;
    this.isEditMode = false;
  }

  closeDetails(blockConf, blockData) {
    blockConf.showDetails = false;
    this.showDetails = false;
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = true;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
      } catch (e) {
      }
    }
    if (blockConf.isAddRowFunctionActive) {
      blockConf.addRow(this.globalPrj, this.globalConf, this, this.selectedLanguage);
    }
    if (blockConf.isEditRowFunctionActive) {
      blockConf.editRow(this.globalPrj, this.globalPrj, this, this.selectedTableRowIndex, blockData);
    }
    const data: any = {};
    data.operation = 'Save Without Validation';
    this.closeDetailsForSchedulerTable(blockData, blockConf);
    this.refreshDataService.saveForm(data);
    this.refreshDataService.toggleShowSaveNavBar(true);
  }

  cancel(blockConf, blockData) {
    console.log('fdsfsd');
    blockConf.showDetails = false;
    this.showDetails = false;
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = true;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
      } catch (e) {
      }
    }
    this.refreshDataService.toggleShowSaveNavBar(true);
    this.bphChange = _.cloneDeep(this.copyBlockData);
    (this.globalPrj[this.selectedSectionId])[blockConf.name] = this.bphChange;
  }

  ngOnDestroy(): void {
  }

}
