
<ng-container *ngIf="confChange.selectedContainerType === 'SchedulerTable'">
  <table>
    <tr>
  <td style="text-align: left !important;">
    <mat-slider (change)="onInputChange($event)" style="width: 80%;"
                [max]="bphChange.maxValueForStartMonthSlider"
                [min]="bphChange.minValueForStartMonthSlider"
                [thumbLabel]="thumbLabel"
                [(ngModel)]="bphChange.startMonthSliderInitialValue">
    </mat-slider>
  </td>
      <td style="text-align: left !important;">
    <mat-slider style="width: 80%;"
      (change)="onInputChangeOfEndMonthSlider($event)"
      [max]="bphChange.maxValueForEndMonthSlider"
      [min]="bphChange.minValueForEndMonthSlider"
      [thumbLabel]="thumbLabel"
      [(ngModel)]="bphChange.endMonthSliderInitialValue">
    </mat-slider>
  </td>
    </tr>

    <tr>
      <td>
        <span style="display: flex;">
       <label style="display:block; margin-right: 1%;" for="startMonth">{{confChange.startMonth[selectedLanguage]}}</label>
           <button  mat-icon-button (click)="openDialog(confChange.dialogForStartMonth)"  style="margin-left: -10px;margin-top: -10px;">
      <mat-icon *ngIf="confChange.dialogForStartMonth"
                style="cursor: pointer; font-size: 21px;"
                (click)="openDialog(confChange.dialogForStartMonth)">help_outline
      </mat-icon></button>
     </span>

      </td>

      <td style="text-align: left !important;">
        <label style="display:block" for="endMonth">{{confChange.endMonth[selectedLanguage]}}</label>
      </td>
</tr>

<tr>
  <ng-container *ngIf="!confChange.hasMonthLabel">
   <td style="text-align: left !important;">
     <mat-form-field appearance="outline" style="width: 40%;">
       <input id="startMonth" matInput type="number" placeholder="Min value" [disabled]="true"
              [(ngModel)]="bphChange.startMonthSliderInitialValue">
     </mat-form-field>
   </td>

      <td style="text-align: left !important;">
        <mat-form-field appearance="outline" style="width: 40%;" >
          <input id="endMonth"
                 [disabled]="true"
                 matInput type="number" placeholder="Min value" [(ngModel)]="bphChange.endMonthSliderInitialValue">
        </mat-form-field>
      </td>
    </ng-container>
  <ng-container *ngIf="confChange.hasMonthLabel">
    <td style="text-align: left !important;">
      <mat-form-field appearance="outline" style="width: 40%;">
        <input id="startMonth" matInput type="text" placeholder="Min value" [disabled]="true"
               [(ngModel)]="confChange.monthLabel[bphChange.startMonthSliderInitialValue - 1]">
      </mat-form-field>
    </td>

    <td style="text-align: left !important;">
      <mat-form-field appearance="outline" style="width: 40%;" >
        <input id="endMonth"
               [disabled]="true"
               matInput type="text" placeholder="Min value" [(ngModel)]="confChange.monthLabel[bphChange.endMonthSliderInitialValue - 1]">
      </mat-form-field>
    </td>
  </ng-container>

    </tr>

  </table>
</ng-container>
