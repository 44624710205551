import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from '../dialog/dialog.component';
import {
  EcaseHttpService,
  ECaseNumberFormatterPipe,
  ECaseSnackBarService,
  ECaseUtils,
  PendingChangesGuard,
  RefreshDataService
} from 'synto-common';
import {TranslateService} from '@ngx-translate/core';
import cloneDeep from 'lodash/cloneDeep';
import {Platform} from '@angular/cdk/platform';


@Component({
  selector: 'fb-budget-t2',
  templateUrl: './fb-budget-t2.component.html',
  styleUrls: ['./fb-budget-t2.component.scss']
})
export class FbBudgetT2Component implements OnInit, OnDestroy {

  @Input() url: any;
  @Input() selectedLanguage;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() globalConf: any;
  @Input() globalPrj: any;
  @Input() selectedSectionId;
  @Input() formId;
  @Input() blockIndex: number;
  @Output() emitOutputEvent = new EventEmitter<any>();
  @Output() emitSaveEvent = new EventEmitter<any>();
  @Output() emitOutputEventForBudgetTableAddRow = new EventEmitter<any>();


  groupedByTableOneRows: Map<any, any> = new Map();
  hasOptionSelected: boolean;
  isShowTableRow: boolean;
  tables: any[] = [];
  showEditManpowerTable: boolean;
  showSecondTable: boolean;
  manPowerEditRow;
  manPowerEditRowIndex;
  selectedTableToEdit;
  searchResultForCategory: any = {};
  searchResultForSubCategory;
  manPowerTable = [];
  tempObjectForNotManpowerTable: any = {};
  indexOfEditedTable;
  oldBPHChange;
  subCategoryList = [];
  showSummaryTable: boolean;
  topLevelBudgetItems = [];
  dataObjectForRecursiveSubCategorySelect: any = {};
  showQuantityField = false;
  showUploadField = false;
  showMainTable = true;

  constructor(public dialog: MatDialog,
              private translate: TranslateService,
              private http: EcaseHttpService,
              public platform: Platform,
              private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe,
              private eCaseSnackBarService: ECaseSnackBarService,
              private pendingChangesGuard: PendingChangesGuard,
              private refreshDataService: RefreshDataService,
              private translateService: TranslateService) {

  }

  getMapValue(category) {
    if (this.groupedByTableOneRows) {
      this.groupedByTableOneRows = ECaseUtils.groupBy(this.bphChange.numberOfBudgetTables[1].rows, item => item.category.value);
    }
    return this.groupedByTableOneRows.get(category.value);
  }

  getTextLength2(l): number {
    try {
      l = l.toString();
      if (l.length > 9) {
        l = l.slice(0, 9);
      }
      return l.length;
    } catch (e) {
      return 0;
    }
  }

  onCategoryChange(event, keepCurrentSubCategoryValue) {
    this.tempObjectForNotManpowerTable.category = this.topLevelBudgetItems.filter(item => item.value === event)[0];
    const subCategories = this.globalConf.lovs['68'].list.filter(item => item.parentTermId === event).sort((a, b) => {
      if (a.sortingKey < b.sortingKey) {
        return -1;
      } else if (a.sortingKey > b.sortingKey) {
        return 1;
      }
      return 0;
    });
    if (subCategories.length > 0 && !keepCurrentSubCategoryValue) {
      this.tempObjectForNotManpowerTable.subCategory[0] = {};
    }
    if (subCategories.length === 0) {
      const selectedBudgetItem = this.globalPrj.formData[this.confChange.selectedLovSQL].data.filter(item => Number(item.BUDGET_ITEM_ID) === event);
      if (selectedBudgetItem && selectedBudgetItem[0]) {
        this.showUploadField = selectedBudgetItem[0].IS_JUSTIFICATION_DOCUMENT_REQUIRED === 'Y';
        this.showQuantityField = selectedBudgetItem[0].IS_QUANTITY_REQUIRED === 'Y';
      }
    } else {
      this.showUploadField = false;
      this.showQuantityField = false;
    }
    this.dataObjectForRecursiveSubCategorySelect = {'subCategories': subCategories, 'level': 0};
    this.tempObjectForNotManpowerTable.isUploadMandatory = false;
    this.tempObjectForNotManpowerTable.isQuantityMandatory = false;
    this.pendingChangesGuard.isPristine = false;
  }

  updateDataObjectForRecursiveSubCategorySelect(dataObject, subCategories, currentLevel, requiredLevel, selectedSubCategory, keepCurrentSubCategoryValue) {
    if (currentLevel === requiredLevel) {
      dataObject['dataObject'] = {'subCategories': subCategories, 'level': (currentLevel + 1)};
      if (subCategories.length === 0) {
        const selectedBudgetItem = this.globalPrj.formData[this.confChange.selectedLovSQL].data.filter(item => Number(item.BUDGET_ITEM_ID) === selectedSubCategory);
        if (selectedBudgetItem && selectedBudgetItem[0]) {
          this.showUploadField = selectedBudgetItem[0].IS_JUSTIFICATION_DOCUMENT_REQUIRED === 'Y';
          this.showQuantityField = selectedBudgetItem[0].IS_QUANTITY_REQUIRED === 'Y';
        }
      } else {
        if (subCategories.length > 0 && !keepCurrentSubCategoryValue) {
          this.tempObjectForNotManpowerTable.subCategory[(currentLevel + 1)] = {};
        }
        this.showUploadField = false;
        this.showQuantityField = false;
      }
      this.tempObjectForNotManpowerTable.isUploadMandatory = this.showUploadField;
      this.tempObjectForNotManpowerTable.isQuantityMandatory = this.showQuantityField;
    } else {
      this.updateDataObjectForRecursiveSubCategorySelect(dataObject['dataObject'], subCategories, (currentLevel + 1), requiredLevel, selectedSubCategory, keepCurrentSubCategoryValue);
    }
    this.pendingChangesGuard.isPristine = false;
  }

  onSubCategoryChange(event, dataObject) {
    this.tempObjectForNotManpowerTable.subCategory[dataObject.level] = dataObject.subCategories.filter(item => item.value === event)[0];
    const subCategories = this.globalConf.lovs['68'].list.filter(item => item.parentTermId === event).sort((a, b) => {
      if (a.sortingKey < b.sortingKey) {
        return -1;
      } else if (a.sortingKey > b.sortingKey) {
        return 1;
      }
      return 0;
    });
    this.updateDataObjectForRecursiveSubCategorySelect(this.dataObjectForRecursiveSubCategorySelect, subCategories, 0, dataObject.level, event, false);
  }

  getWidthFromPlatform() {
    if (this.platform.ANDROID || this.platform.IOS) {
      return '100%';
    } else {
      return '50%';
    }
  }


  getTextLength(l): number {
    try {
      if (l.toString().length <= this.confChange.maxlength) {
        return l ? l.toString().length : 0;
      } else {
        return this.confChange.maxlength;
      }
    } catch (e) {
      return 0;
    }
  }

  getWordCount(str): number {
    try {
      return (str === '' ? 0 : str.split(' ').filter(item => item !== '').length);
    } catch (e) {
      return 0;
    }
  }

  preventMaxlengthViolation(tempObject, key, maxLength) {
    if (this.getTextLength(tempObject) >= maxLength) {
      tempObject = tempObject.toString().split('').slice(0, maxLength).join('');
      this.tempObjectForNotManpowerTable[key] = Number(tempObject);
    }
    this.pendingChangesGuard.isPristine = false;
  }

  changeValueEvent(tempObject, key) {
    if (!this.confChange.isMaxLengthWords) {
      if (this.getTextLength(tempObject) >= this.confChange.maxlength) {
        tempObject = tempObject.split('').slice(0, this.confChange.maxlength).join('');
      }
    } else {
      if (this.getWordCount(tempObject) >= this.confChange.maxlength) {
        tempObject = tempObject.toString().split(' ').slice(0, this.confChange.maxlength).join(' ');
        this.tempObjectForNotManpowerTable[key] = tempObject;
      }
    }
    this.pendingChangesGuard.isPristine = false;
  }

  getYearErrorMessages(headerRowIndex, componentName) {
    if (this.tables[1].rows.length > 0) {
      for (let i = 0; i < this.bphChange.numberOfYears; i++) {
        if (this.tables[1].rows[headerRowIndex].expandRows[i][componentName]
          && this.tables[1].rows[headerRowIndex].expandRows[i][componentName].error
          && this.tables[1].rows[headerRowIndex].expandRows[i][componentName].error[this.selectedLanguage]) {
          return this.tables[1].rows[headerRowIndex].expandRows[i][componentName].error;
        }
      }
    }
  }

  ngOnInit() {
    if (this.globalConf.lovs['68'] && this.globalConf.lovs['68'].list) {
      const competitionBudgetItems = this.globalPrj.formData[this.confChange.selectedLovSQL].data.map(item => Number(item.BUDGET_ITEM_ID));
      this.topLevelBudgetItems = this.globalConf.lovs['68'].list.filter(item => competitionBudgetItems.includes(item.value) && !item.parentTermId).sort((a, b) => {
        if (a.sortingKey < b.sortingKey) {
          return -1;
        } else if (a.sortingKey > b.sortingKey) {
          return 1;
        }
        return 0;
      });
    }
    this.tempObjectForNotManpowerTable.subCategory = [];
    if (!this.bphChange) {
      this.bphChange.numberOfBudgetTables = [];
    } else {
      this.tables = this.bphChange.numberOfBudgetTables;
    }


    if (!this.bphChange.numberOfBudgetTables) {
      this.bphChange.numberOfBudgetTables = [{
        'hasOptionSelected': true,
        'isManPowerTable': true,
        'rows': []
      },
        {
          'hasOptionSelected': false,
          'isManPowerTable': false,
          'rows': []
        }
      ];
    }

    if (!this.bphChange.numberOfBudgetTables[1]) {
      this.bphChange.numberOfBudgetTables[1] =
        {
          'hasOptionSelected': false,
          'isManPowerTable': false,
          'rows': []
        };
    }

    if (!this.bphChange.yearRows) {
      this.bphChange.yearRows = [];
      for (let i = 0; i < this.bphChange.numberOfYears; i++) {
        this.bphChange.yearRows.push(0);
      }
    }

    if (!this.bphChange.numberOfBudgetTables[0].yearRows) {
      this.bphChange.numberOfBudgetTables[0].yearRows = [];
      for (let i = 0; i < this.bphChange.numberOfYears; i++) {
        this.bphChange.numberOfBudgetTables[0].yearRows.push(0);
      }
    }

    console.log('4444444');
    if (this.bphChange.numberOfBudgetTables[1] && !this.bphChange.numberOfBudgetTables[1].yearRows) {
      this.bphChange.numberOfBudgetTables[1].yearRows = [];
      for (let i = 0; i < this.bphChange.numberOfYears; i++) {
        this.bphChange.numberOfBudgetTables[1].yearRows.push(0);
      }
    }


    console.log('BUDGET COMPONENT');
    console.log(this.confChange);

    if (this.confChange.isBudgetTypeInOpexSection !== undefined && this.confChange.isBudgetTypeInOpexSection === true) {
      this.bphChange.numberOfBudgetTables[0] =
        {
          'hasOptionSelected': true,
          'isManPowerTable': true,
          'rows': []
        };
    } else if (this.confChange.isBudgetTypeInCapexSection !== undefined && this.confChange.isBudgetTypeInCapexSection === true) {
      this.bphChange.numberOfBudgetTables[0] =
        {
          'hasOptionSelected': true,
          'isManPowerTable': true,
          'rows': []
        };
    }

    if (!this.globalPrj.upload_to_be_deleted) {
      this.globalPrj.upload_to_be_deleted = [];
    }

    if (this.showSummaryTable && this.bphChange.numberOfBudgetTables[1].rows) {
      this.groupedByTableOneRows = ECaseUtils.groupBy(this.bphChange.numberOfBudgetTables[1].rows, item => item.category.value);
    }

    console.log(this.groupedByTableOneRows);
    if (!this.tables) {
      this.tables = [];
    }

    this.tables.forEach((table) => {
      table.rows.forEach((row) => {
        row.descriptionError = {};
        row.descriptionError.error = {};
        row.subCategoryError = {};
        row.subCategoryError.error = {};
        row.categoryError = {};
        row.categoryError.error = {};
        row.uploadError = {};
        row.uploadError.error = {};
        row.quantityError = {};
        row.quantityError.error = {};

        row.expandRows.forEach((expandRow) => {
          expandRow.subTotalError = {};
          expandRow.subTotalError.error = {};
          expandRow.fundsFromKfasError = {};
          expandRow.fundsFromKfasError.error = {};
          expandRow.fundsFromOtherInstitutionError = {};
          expandRow.fundsFromOtherInstitutionError.error = {};

        });
      });
    });

    this.calculateYearTotals(this.bphChange.numberOfBudgetTables[0]);
    this.calculateYearTotals(this.bphChange.numberOfBudgetTables[1]);
    console.log('*************');
    this.calculateTableTotals();
    console.log(this.tables);

  }

  calculateTableTotals() {
    this.bphChange.numberOfBudgetTables[1].rows.forEach((item) => {
      this.onKfasFundsInput(this.bphChange.numberOfBudgetTables[1], item);
      this.onSubTotalInput(this.bphChange.numberOfBudgetTables[1], item);
      this.onOtherInstitutionFundsInput(this.bphChange.numberOfBudgetTables[1], item);

    });

    this.bphChange.numberOfBudgetTables[0].rows.forEach((item) => {
      this.onKfasFundsInput(this.bphChange.numberOfBudgetTables[0], item);
      this.onSubTotalInput(this.bphChange.numberOfBudgetTables[0], item);
      this.onOtherInstitutionFundsInput(this.bphChange.numberOfBudgetTables[0], item);

    });
  }

  getTranslatedLabel(key: string) {
    return ECaseUtils.getTranslatedValueFromKey(this.translate, key, this.selectedLanguage);
  }


  ngOnDestroy() {
    this.emitOutputEvent.emit(true);
    this.refreshDataService.toggleShowSaveNavBar(true);
  }

  onEditManpowerTableRow(manPowerTableRow, index, table) {
    this.oldBPHChange = cloneDeep(this.bphChange);
    this.showEditManpowerTable = true;
    this.indexOfEditedTable = index;
    this.manPowerEditRow = manPowerTableRow;
    this.manPowerEditRowIndex = index;
    this.selectedTableToEdit = table;
    this.emitOutputEvent.emit(false);
    this.refreshDataService.toggleShowSaveNavBar(false);

  }


  confirmEditingTheManpowerTableRow(row) {

    this.showEditManpowerTable = false;
    console.log(this.bphChange);
    // this.emitSaveEvent.emit();
    this.emitOutputEvent.emit(true);
    this.refreshDataService.toggleShowSaveNavBar(true);

  }

  uploadDone1(event, row) {
    row.uploadFile = [];
    const documentPath = event.target.value;
    if (documentPath.includes('.pdf')) {
      console.log('GOOD');
      const formData = new FormData();
      formData.append(event.target.files[0].name, event.target.files[0]);
      formData.append('documentTypeId', '10307');
      formData.append('selectedSectionId', this.selectedSectionId);
      formData.append('formId', this.formId);
      formData.append('blockIndex', this.blockIndex.toString());
      console.log(formData);
      console.log(event);

      this.http.post('/api/fileUpload', formData).subscribe((_data: any) => {
        console.log(_data);
        if (_data.hasOwnProperty('status') && !_data.status) {
          alert(this.selectedLanguage === 'en' ? 'Upload failed' : 'Le téléchargement a échoué');
        } else {
          if (_data.docsIds.length === 1) {
            const upload_id = _data.docsIds[0];
            const upload_name = _data.docsNames[0];
            console.log(upload_name);
            const parsed_uploadName = upload_name.substring(upload_name.lastIndexOf('\\') + 1);
            console.log(parsed_uploadName);
            const tempObject = {
              'id': upload_id,
              'fileName': parsed_uploadName,
              'fileUploaded': true,
              'creation_date': new Date()
            };
            console.log(tempObject);
            row.uploadFile.push(tempObject);
            this.eCaseSnackBarService.show('success', this.selectedLanguage === 'en' ? 'File(s) uploaded' : 'Fichier téléchargé');
            this.pendingChangesGuard.isPristine = false;
          }
          if (_data.docsIds.length > 0) {
            this.globalPrj.docsIds = [ ...this.globalPrj.docsIds , ..._data.docsIds];
          }
        }
      }, error => this.eCaseSnackBarService.show('failure', error.errorMessage));
    } else {
      const msg = ECaseUtils.getTranslatedValueFromKey(this.translateService, 'ecase.common.uploadRequiredExtensionsMessage', this.selectedLanguage) + '.pdf';
      alert(msg);
    }
    console.log(this.bphChange);

  }

  deleteFile(fileUploaded, row) {
    console.log(fileUploaded);
    row.uploadFile = [];
    this.globalPrj.upload_to_be_deleted.push(fileUploaded.id);
  }


  openDialog(dataToDisplay) {
    if (typeof dataToDisplay === 'string') {
      const dummyString = dataToDisplay;
      dataToDisplay = {};
      this.translateService.langs.forEach((lang) => {
        dataToDisplay[lang] = dummyString;
      });
    }
    this.dialog.open(DialogComponent, {
      width: '600px',
      data:
        {
          dialog: dataToDisplay,
          selectedLanguage: this.selectedLanguage
        }
    });
  }


  showTableRow(row, index) {
    console.log(index);
    row.isShowTableRow = !row.isShowTableRow;
  }

  // test() {
  //   console.log('WHEN');
  // }

  onSubTotalInput(table, row, expandRow?, $event?) {
    console.log('FFFFFFFFFFFF');
    console.log(row);
    console.log(expandRow);

    if ($event && expandRow.subTotal !== null) {
      const t = $event.target;
      if (t.hasAttribute('maxlength')) {
        t.value = t.value.slice(0, t.getAttribute('maxlength'));
        expandRow.subTotal = +((expandRow.subTotal).toString().slice(0, t.getAttribute('maxlength')));
        console.log('SUBTOTAL', expandRow.subTotal);
      }
    }

    let total = 0;
    let totalOfAllRows = 0;
    let superTotalOfEverything = 0;
    if (row.expandRows) {
      for (const _expandRow of row.expandRows) {
        // expandRow.fundsFromOtherInstitution = expandRow.subTotal;
        total = +(total + _expandRow.subTotal);
      }
    }

    row.grandSubTotal = this.eCaseNumberFormatterPipe.transform(total, !!this.confChange.currency);
    table.rows.forEach((item) => {
      if (item.grandSubTotal) {
        totalOfAllRows += (this.eCaseNumberFormatterPipe.reverseFormatToNumber(item.grandSubTotal.toString(), !!this.confChange.currency));
      }

    });

    table.grandSubTotal = this.eCaseNumberFormatterPipe.transform(totalOfAllRows, !!this.confChange.currency);
    this.bphChange.numberOfBudgetTables.forEach((item) => {
      const grandSubTotal = item.grandSubTotal ? +(this.eCaseNumberFormatterPipe.reverseFormatToNumber(item.grandSubTotal.toString(), !!this.confChange.currency)) : 0;
      superTotalOfEverything += (this.eCaseNumberFormatterPipe.reverseFormatToNumber(grandSubTotal.toString(), !!this.confChange.currency));
    });

    this.bphChange.grandSubTotal = this.eCaseNumberFormatterPipe.transform(superTotalOfEverything, !!this.confChange.currency);
    console.log(this.bphChange);
    this.calculateYearTotals(table);
    if (expandRow) {
      expandRow.fundsFromOtherInstitution = expandRow.subTotal - expandRow.fundsFromKfas;
      this.onOtherInstitutionFundsInput(table, row);
    }
    this.pendingChangesGuard.isPristine = false;
  }

  calculateYearTotals(table) {
    if (table) {
      const yearRows = [];
      const totalYearRows = [];
      for (let i = 0; i < this.bphChange.numberOfYears; i++) {
        let yearCount = 0;
        table.rows.forEach((item) => {
          if (item.expandRows[i]) {
            // yearCount += +(item.expandRows[i].subTotal) + +(item.expandRows[i].fundsFromOtherInstitution) + +(item.expandRows[i].fundsFromKfas)
            yearCount += +(item.expandRows[i].subTotal);
          }
        });
        yearRows.push(this.eCaseNumberFormatterPipe.transform(yearCount, !!this.confChange.currency));
      }
      table.yearRows = yearRows;
      for (let i = 0; i < this.bphChange.numberOfYears; i++) {
        const totalForTheYear = (this.eCaseNumberFormatterPipe.reverseFormatToNumber(this.bphChange.numberOfBudgetTables[0].yearRows[i].toString()) + this.eCaseNumberFormatterPipe.reverseFormatToNumber(this.bphChange.numberOfBudgetTables[1].yearRows[i].toString()));
        totalYearRows.push(this.eCaseNumberFormatterPipe.transform(totalForTheYear, !!this.confChange.currency));
      }
      this.bphChange.yearRows = totalYearRows;
    }
  }

  onKfasFundsInput(table, row, expandRow?, $event?) {
    let total = 0;
    let totalOfAllRows = 0;
    let superTotalOfEverything = 0;

    if ($event && expandRow.subTotal !== null) {
      const t = $event.target;
      if (t.hasAttribute('maxlength')) {
        t.value = t.value.slice(0, t.getAttribute('maxlength'));
        expandRow.fundsFromKfas = +((expandRow.fundsFromKfas).toString().slice(0, t.getAttribute('maxlength')));
        console.log('KFAS TOTAL: ', expandRow.fundsFromKfas);
      }
    }

    if (row.expandRows) {
      for (const _expandRow of row.expandRows) {
        total = +(total + _expandRow.fundsFromKfas);
      }
    }

    row.grandTotalForKfasFunds = this.eCaseNumberFormatterPipe.transform(total, !!this.confChange.currency);

    table.rows.forEach((item) => {
      if (item.grandTotalForKfasFunds) {
        totalOfAllRows += +(this.eCaseNumberFormatterPipe.reverseFormatToNumber(item.grandTotalForKfasFunds.toString(), !!this.confChange.currency));
      }

    });
    table.grandTotalForKfasFunds = this.eCaseNumberFormatterPipe.transform(totalOfAllRows, !!this.confChange.currency);

    this.bphChange.numberOfBudgetTables.forEach((item) => {
      const kfsFunds = item.grandTotalForKfasFunds ? this.eCaseNumberFormatterPipe.reverseFormatToNumber(item.grandTotalForKfasFunds.toString(), !!this.confChange.currency) : 0;
      superTotalOfEverything += +(kfsFunds);
    });
    this.bphChange.grandTotalForKfasFunds = this.eCaseNumberFormatterPipe.transform(superTotalOfEverything, !!this.confChange.currency);
    // this.calculateYearTotals(table);
    if (expandRow) {
      expandRow.fundsFromOtherInstitution = expandRow.subTotal - expandRow.fundsFromKfas;
      this.onOtherInstitutionFundsInput(table, row);

    }
    this.pendingChangesGuard.isPristine = false;
  }

  onOtherInstitutionFundsInput(table, row) {
    let total = 0;
    let totalOfAllRows = 0;
    let superTotalOfEverything = 0;
    if (row.expandRows) {
      for (const expandRow of row.expandRows) {
        total = +(total + expandRow.fundsFromOtherInstitution);
        console.log('gggggggggggggggg');
        console.log(expandRow.fundsFromOtherInstitution);
        // expandRow = expandRow.toString();
        //  if(expandRow.fundsFromOtherInstitution.length>9){
        //    expandRow.fundsFromOtherInstitution=expandRow.fundsFromOtherInstitution.slice(0,9)
        //  }
        //  val = expandRow.fundsFromOtherInstitution;
      }
    }
    //   this.changeValueEventvalue2(val);
    row.grandTotalForOtherFunds = this.eCaseNumberFormatterPipe.transform(total, !!this.confChange.currency);
    table.rows.forEach((item) => {
      if (item.grandTotalForOtherFunds) {
        totalOfAllRows += +(this.eCaseNumberFormatterPipe.reverseFormatToNumber(item.grandTotalForOtherFunds.toString(), !!this.confChange.currency));
      }
    });
    table.grandTotalForOtherFunds = this.eCaseNumberFormatterPipe.transform(totalOfAllRows, !!this.confChange.currency);
    this.bphChange.numberOfBudgetTables.forEach((item) => {
      const grandTotalForOtherFunds = item.grandTotalForOtherFunds ? this.eCaseNumberFormatterPipe.reverseFormatToNumber(item.grandTotalForOtherFunds.toString(), !!this.confChange.currency) : 0;
      superTotalOfEverything += +(grandTotalForOtherFunds);
    });
    this.bphChange.grandTotalForOtherFunds = this.eCaseNumberFormatterPipe.transform(superTotalOfEverything, !!this.confChange.currency);
    // this.calculateYearTotals(table);
    this.pendingChangesGuard.isPristine = false;
  }

  onDeleteTable(row, rowIndex) {
    console.log(row);
    if (this.confChange.isConfirmDelete) {
      this.confChange.confirmDelete(this.globalPrj, this.globalConf, rowIndex);
    }
    this.tables[1].rows.splice(rowIndex, 1);
    console.log(this.bphChange);
    this.onSubTotalInput(this.bphChange.numberOfBudgetTables[1], {});
    this.onOtherInstitutionFundsInput(this.bphChange.numberOfBudgetTables[1], {});
    this.onKfasFundsInput(this.bphChange.numberOfBudgetTables[1], {});
  }

  onEditTable(row, rowIndex) {
    this.oldBPHChange = cloneDeep(this.bphChange);
    this.showSecondTable = true;
    this.tempObjectForNotManpowerTable = row;
    if (row.category && row.category.value) {
      this.dataObjectForRecursiveSubCategorySelect = {};
      this.onCategoryChange(row.category.value, true);
      row.subCategory.forEach((subcategory, index) => {
        const subCategories = this.globalConf.lovs['68'].list.filter(item => item.parentTermId === subcategory.value).sort((a, b) => {
          if (a.sortingKey < b.sortingKey) {
            return -1;
          } else if (a.sortingKey > b.sortingKey) {
            return 1;
          }
          return 0;
        });
        this.updateDataObjectForRecursiveSubCategorySelect(this.dataObjectForRecursiveSubCategorySelect, subCategories, 0, index, subcategory.value, true);
      });
    }
    this.indexOfEditedTable = rowIndex;
    this.emitOutputEvent.emit(false);
    this.refreshDataService.toggleShowSaveNavBar(false);
    if (this.confChange.isBudgetTypeInCapexSection !== undefined && this.confChange.isBudgetTypeInCapexSection === true) {
      for (const key in this.globalPrj.budget) {
        if (key !== this.confChange.name && this.globalPrj.budget[key] && this.globalPrj.budget[key].globalShowDetail !== undefined) {
          this.globalPrj.budget[key].globalShowDetail = false;
        }
      }
    }
    this.pendingChangesGuard.isPristine = false;
  }

  onAddTable() {
    this.dataObjectForRecursiveSubCategorySelect = {};
    this.tempObjectForNotManpowerTable.subCategory = [];
    this.tempObjectForNotManpowerTable.category = {};
    this.showMainTable = false;
    this.tempObjectForNotManpowerTable.isUploadMandatory = false;
    this.tempObjectForNotManpowerTable.isQuantityMandatory = false;
    this.oldBPHChange = cloneDeep(this.bphChange);
    let tempObject: any = {};
    if (this.confChange.isBudgetTypeInCapexSection !== undefined && this.confChange.isBudgetTypeInCapexSection === true) {
      tempObject = {
        'category': this.tempObjectForNotManpowerTable.category,
        'subCategory': this.tempObjectForNotManpowerTable.subCategory,
        'uploadFile': [],
        'description': '',
        'selectedSubCategoryList': [],
        'isRowAdded': false,
        'expandRows': [],
        'isShowTableRow': false,
        'descriptionError': {},
        'subCategoryError': {},
        'categoryError': {},
        'uploadError':{},
        'quantityError':{}
      };
    } else {

      tempObject = {
        'category': this.tempObjectForNotManpowerTable.category,
        'subCategory': this.tempObjectForNotManpowerTable.subCategory,
        'uploadFile': [],
        'description': '',
        'selectedSubCategoryList': [],
        'isRowAdded': false,
        'expandRows': [],
        'isShowTableRow': false,
        'descriptionError': {},
        'subCategoryError': {},
        'categoryError': {},
        'uploadError':{},
        'quantityError':{}
      };
    }
    tempObject.uploadError.error = {};
    tempObject.quantityError.error = {};
    tempObject.descriptionError.error = {};
    tempObject.subCategoryError.error = {};
    tempObject.categoryError.error = {};
    tempObject.uniqueRowIndex = this.bphChange.numberOfBudgetTables[1] && this.bphChange.numberOfBudgetTables[1].rows.length > 0 ? (Math.max.apply(Math, this.bphChange.numberOfBudgetTables[1].rows.map(function(o) {
      return o.uniqueRowIndex ? o.uniqueRowIndex : 0;
    })) + 1) : 1;

    if (this.bphChange.numberOfYears) {
      for (let i = 0; i < this.bphChange.numberOfYears; i++) {
        const yearLabelObject = cloneDeep(this.confChange.yearLabel);
        // Object.keys(this.confChange.yearLabel).forEach(key => {
        //   yearLabelObject[key] = this.confChange.yearLabel[key] + " " + (i + 1);
        // })
        const expandRow: any = {
          'label': yearLabelObject,
          'subTotal': '',
          'fundsFromKfas': '',
          'fundsFromOtherInstitution': '',
        };
        expandRow.subTotalError = {};
        expandRow.subTotalError.error = {};
        expandRow.fundsFromKfasError = {};
        expandRow.fundsFromKfasError.error = {};
        expandRow.fundsFromOtherInstitutionError = {};
        expandRow.fundsFromOtherInstitutionError.error = {};
        tempObject.expandRows.push(expandRow);
      }
    }

    this.tempObjectForNotManpowerTable = tempObject;
    this.showSecondTable = true;
    this.indexOfEditedTable = undefined;
    this.emitOutputEvent.emit(false);
    this.refreshDataService.toggleShowSaveNavBar(false);
    this.pendingChangesGuard.isPristine = false;
  }

  concatBudgetItemSubCategory(subCategories: any[]) {
    return subCategories.filter(item => item.label).map(item => item.label[this.selectedLanguage]).join(' / ');
  }

  confirm() {
    this.showSecondTable = false;
    const tempObject = cloneDeep(this.tempObjectForNotManpowerTable);
    if (this.indexOfEditedTable !== undefined) {
      this.bphChange.numberOfBudgetTables[1].rows[this.indexOfEditedTable] = tempObject;
    } else {
      this.bphChange.numberOfBudgetTables[1].rows.push(tempObject);
    }
    if (this.confChange.isAddRowFunctionActive && this.indexOfEditedTable === undefined) {
      this.confChange.addRow(this.globalPrj, this.globalConf, this.tempObjectForNotManpowerTable);
    } else if (this.confChange.isEditRowFunctionActive && this.indexOfEditedTable !== undefined) {
      const oldData = this.oldBPHChange.numberOfBudgetTables[1].rows[this.indexOfEditedTable];
      this.confChange.editRow(this.globalPrj, this.globalConf, this.indexOfEditedTable, oldData);
    }

    this.onSubTotalInput(this.bphChange.numberOfBudgetTables[1], tempObject);
    this.onOtherInstitutionFundsInput(this.bphChange.numberOfBudgetTables[1], tempObject);
    this.onKfasFundsInput(this.bphChange.numberOfBudgetTables[1], tempObject);
    this.emitOutputEvent.emit(true);
    this.refreshDataService.toggleShowSaveNavBar(true);
    this.showMainTable = true;
    this.pendingChangesGuard.isPristine = false;
  }

  cancel() {
    this.showMainTable = true;
    this.bphChange = cloneDeep(this.oldBPHChange);
    (this.globalPrj[this.confChange.selectedSection.id])[this.confChange.name] = this.bphChange;
    this.tables = this.bphChange.numberOfBudgetTables;
    this.showSecondTable = false;
    this.showEditManpowerTable = false;
    this.emitOutputEvent.emit(true);
    this.refreshDataService.toggleShowSaveNavBar(true);

  }

  replaceDescriptionLabelWithYears(value) {
    if (value) {
      let newValue = value[this.selectedLanguage];
      newValue = newValue.replace('$numberOfYears', this.bphChange.numberOfYears);
      return newValue;
    } else {
      return '';
    }
  }

  convertNumberToCurrencyFormat(value, currency) {
    return this.eCaseNumberFormatterPipe.transform(value, !!currency);
  }

}
