<div *ngIf="!bphChange.hide && bphChange.globalShowDetail"
     [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'">

  <ng-container *ngIf="!showEditManpowerTable && !showSecondTable">
    <ng-container *ngIf="!showSummaryTable && showMainTable">
      <table *ngFor="let table of tables; let i = index;"
             class="angular-table">
        <ng-container *ngIf="!table.isManPowerTable">
          <thead class="angular-table-thead">
          <tr class="angular-table-tr">
            <ng-container>
              <th></th>
              <th class="angular-table-th"><span style="color: red">*</span>
                {{confChange.categoryLabel[selectedLanguage]}}
              </th>
              <th class="angular-table-th"><span style="color: red">*</span>
                {{confChange.subCategoryLabel[selectedLanguage]}}

                <mat-icon (click)="openDialog(confChange.dialogForSubCategory)"
                          *ngIf="confChange.hasDialogForSubCategory"
                          style="cursor: pointer; font-size: 21px;">help_outline
                </mat-icon>

              </th>

              <th class="angular-table-th"><span style="color: red">*</span>
                {{confChange.descriptionLabel[selectedLanguage]}}
              </th>
              <th class="angular-table-th"><span style="color: red">*</span>
                {{confChange.subtotalLabel[selectedLanguage]}}
              </th>
              <th class="angular-table-th"><span style="color: red">*</span>
                {{confChange.fundsFromKfasLabel[selectedLanguage]}}
              </th>
              <th class="angular-table-th"><span style="color: red">*</span>
                {{confChange.fundsFromOtherInstitutionLabel[selectedLanguage]}}
              </th>
              <th class="angular-table-th">{{confChange.commentsLabel[selectedLanguage]}}</th>
              <th class="angular-table-th">{{confChange.quantityLabel[selectedLanguage]}}</th>
              <th class="angular-table-th">{{confChange.documentaionLabel[selectedLanguage]}}</th>
              <th class="angular-table-th">Actions</th>
            </ng-container>

          </tr>
          </thead>
          <tbody *ngIf="table.rows.length > 0">
            <ng-container *ngFor="let row of table.rows; let rowIndex = index;">
              <tr class="angular-table-tr">

                <td class="angular-table-td">
                  <mat-icon (click)="showTableRow(row, rowIndex)" style="cursor: pointer; margin-right: 5px;">
                    {{row.isShowTableRow ? 'remove_circle_outline' : 'add_circle_outline'}}
                  </mat-icon>
                </td>

                <td *ngIf="!table.isManPowerTable" class="angular-table-td wrap-table-text" width="10%">
                  <span>{{row.category.label ? row.category.label[selectedLanguage] : ''}}</span>
                  <div
                    [ngClass]="row?.categoryError && row?.categoryError.error[selectedLanguage] ? 'hasError' : 'noError'">
                   <span *ngIf="row?.categoryError && row?.categoryError.error[selectedLanguage]"
                         class="formFieldError">{{row?.categoryError.error[selectedLanguage]}}</span>
                  </div>
                </td>



                <ng-container *ngIf="row.category || table.hasOptionSelected">
                  <td *ngIf="!table.isManPowerTable" class="angular-table-td wrap-table-text">
                    <span>{{concatBudgetItemSubCategory(row.subCategory)}}</span>

                    <div
                      [ngClass]="row?.subCategoryError && row?.subCategoryError.error[selectedLanguage] ? 'hasError' : 'noError'">
                  <span *ngIf="row?.subCategoryError && row?.subCategoryError.error[selectedLanguage]"
                        class="formFieldError">{{row?.subCategoryError.error[selectedLanguage]}}</span>
                    </div>
                  </td>

                  <td *ngIf="!table.isManPowerTable" class="angular-table-td wrap-table-text">
                    <div style="overflow: auto; max-height: 120px;">{{row.description}}</div>
                    <div
                      [ngClass]="row?.descriptionError && row?.descriptionError.error[selectedLanguage] ? 'hasError' : 'noError'">
                  <span *ngIf="row?.descriptionError && row?.descriptionError.error[selectedLanguage]"
                        class="formFieldError">{{row?.descriptionError.error[selectedLanguage]}}</span>
                    </div>
                  </td>
                  <td class="angular-table-td">
                    <span>{{row.grandSubTotal}}</span>
                    <div
                      [ngClass]="getYearErrorMessages(rowIndex,'subTotalError') && getYearErrorMessages(rowIndex,'subTotalError')[selectedLanguage] ? 'hasError' : 'noError'">
                  <span
                    *ngIf="getYearErrorMessages(rowIndex,'subTotalError') && getYearErrorMessages(rowIndex,'subTotalError')[selectedLanguage]"
                    class="formFieldError">
                    {{getYearErrorMessages(rowIndex, 'subTotalError')[selectedLanguage]}}
                  </span>
                    </div>
                  </td>
                  <td class="angular-table-td">
                    <span>{{row.grandTotalForKfasFunds}}</span>
                    <div
                      [ngClass]="getYearErrorMessages(rowIndex,'fundsFromKfasError') && getYearErrorMessages(rowIndex,'fundsFromKfasError')[selectedLanguage] ? 'hasError' : 'noError'">
                  <span
                    *ngIf="getYearErrorMessages(rowIndex,'fundsFromKfasError') && getYearErrorMessages(rowIndex,'fundsFromKfasError')[selectedLanguage]"
                    class="formFieldError">
                    {{getYearErrorMessages(rowIndex, 'fundsFromKfasError')[selectedLanguage]}}
                  </span>
                    </div>
                  </td>
                  <td class="angular-table-td">
                    <span>{{row.grandTotalForOtherFunds}}</span>
                    <div
                      [ngClass]="getYearErrorMessages(rowIndex,'fundsFromOtherInstitutionError') && getYearErrorMessages(rowIndex,'fundsFromOtherInstitutionError')[selectedLanguage] ? 'hasError' : 'noError'">
                  <span
                    *ngIf="getYearErrorMessages(rowIndex,'fundsFromOtherInstitutionError') && getYearErrorMessages(rowIndex,'fundsFromOtherInstitutionError')[selectedLanguage]"
                    class="formFieldError">
                    {{getYearErrorMessages(rowIndex, 'fundsFromOtherInstitutionError')[selectedLanguage]}}
                  </span>
                    </div>
                  </td>

                  <ng-container>
                    <td class="angular-table-td wrap-table-text">
                      <div style="overflow: auto; max-height: 120px;">{{row.comments}}</div>
                    </td>
                  </ng-container>

                  <ng-container>
                    <td class="angular-table-td wrap-table-text">
                      <span>{{row.quantity}}</span>
                      <div
                        [ngClass]="row?.quantityError && row?.quantityError.error[selectedLanguage] ? 'hasError' : 'noError'">
                          <span *ngIf="row?.quantityError && row?.quantityError.error[selectedLanguage]"
                         class="formFieldError">{{row?.quantityError.error[selectedLanguage]}}</span>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container *ngIf="!table.isManPowerTable">
                    <td class="angular-table-td" style="font-weight: 500 !important;">
                    <span *ngIf="row.uploadFile?.length > 0" style="font-size: 1.1em">
                  <a href="/api/viewAttachedRepositoryDocument/{{row.uploadFile[0].id}}"
                     target="_blank">{{row.uploadFile[0].fileName}}
                  </a>
                </span>
                      <div
                        [ngClass]="row?.uploadError && row?.uploadError.error[selectedLanguage] ? 'hasError' : 'noError'">
                          <span *ngIf="row?.uploadError && row?.uploadError.error[selectedLanguage]"
                                class="formFieldError">{{row?.uploadError.error[selectedLanguage]}}</span>
                      </div>
                    </td>
                  </ng-container>

                  <td *ngIf="!table.isManPowerTable" class="angular-table-td">
                    <button #menuTrigger="matMenuTrigger" (click)="menuTrigger.openMenu();$event.stopPropagation()"
                            [matMenuTriggerFor]="menu" class="mat-icon-button" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button (click)="onEditTable(row, rowIndex)" mat-menu-item>
                        {{'edit' | translate}}
                      </button>
                      <button (click)="onDeleteTable(row, rowIndex)" mat-menu-item>
                        {{'ecase.admindashboard.study.deleteAction' | translate}}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>
              </tr>
              <ng-container *ngIf="row.isShowTableRow">
                <tr *ngFor="let expandableRows of row.expandRows" class="angular-table-tr">

                  <td class="angular-table-td" style="background-color: lightgrey;"></td>
                  <td class="angular-table-td" style="background-color: lightgrey;"></td>
                  <td class="angular-table-td" style="background-color: lightgrey;"></td>
                  <td class="angular-table-td" style="background-color: lightgrey;"></td>


                  <!--<ng-container>-->
                  <!--<td class="angular-table-td"></td>-->
                  <!--<td class="angular-table-td" style="text-align: left;">-->
                  <!--{{expandableRows.label}}-->
                  <!--</td>-->
                  <!--</ng-container>-->

                  <td class="angular-table-td">
                    <div>
                      <span>{{convertNumberToCurrencyFormat(expandableRows.subTotal, confChange.currency)}}</span>
                      <div
                        [ngClass]="expandableRows?.subTotalError && expandableRows?.subTotalError.error && expandableRows?.subTotalError.error[selectedLanguage] ? 'hasError' : 'noError'">
                    <span *ngIf="expandableRows?.subTotalError && expandableRows?.subTotalError.error[selectedLanguage]"
                          class="formFieldError">{{expandableRows?.subTotalError.error[selectedLanguage]}}
                    </span>
                      </div>
                    </div>
                  </td>

                  <td class="angular-table-td">
                    <div>
                      <span>{{convertNumberToCurrencyFormat(expandableRows.fundsFromKfas, confChange.currency)}}</span>
                      <div
                        [ngClass]="expandableRows?.fundsFromKfasError && expandableRows?.fundsFromKfasError.error && expandableRows?.fundsFromKfasError.error[selectedLanguage] ? 'hasError' : 'noError'">
                    <span
                      *ngIf="expandableRows?.fundsFromKfasError && expandableRows?.fundsFromKfasError.error[selectedLanguage]"
                      class="formFieldError">{{expandableRows?.fundsFromKfasError.error[selectedLanguage]}}
                    </span>
                      </div>
                    </div>
                  </td>

                  <td class="angular-table-td">
                    <div>
                      <span>{{convertNumberToCurrencyFormat(expandableRows.fundsFromOtherInstitution, confChange.currency)}}</span>
                      <div
                        [ngClass]="expandableRows?.fundsFromOtherInstitutionError && expandableRows?.fundsFromOtherInstitutionError.error && expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage] ? 'hasError' : 'noError'">
                    <span
                      *ngIf="expandableRows?.fundsFromOtherInstitutionError && expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage]"
                      class="formFieldError">
                      {{expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage]}}
                    </span>
                      </div>
                    </div>
                  </td>

                  <td class="angular-table-td" style=" background-color: lightgrey;"></td>
                  <td class="angular-table-td" style=" background-color: lightgrey;"></td>
                  <td class="angular-table-td" style=" background-color: lightgrey;"></td>

                </tr>
              </ng-container>

            </ng-container>
          </tbody>

        </ng-container>
      </table>

      <button (click)="onAddTable()" color="accent" id='budget_t2_addBtn' mat-raised-button
              style="margin-top: 20px;margin-bottom: 5px;">
        {{ getTranslatedLabel("add")}}
      </button>
    </ng-container>
  </ng-container>

  <div *ngIf="showSecondTable">
    <div>
      <span style="color: red">*</span> {{confChange.categoryLabel[selectedLanguage]}}
      <mat-form-field appearance="outline">
        <mat-select (ngModelChange)="onCategoryChange($event, false)" [(ngModel)]="tempObjectForNotManpowerTable.category.value">
          <mat-option *ngFor="let budgetItem of topLevelBudgetItems" [value]="budgetItem.value">
            {{budgetItem.label[selectedLanguage]}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <ng-container
      *ngIf="dataObjectForRecursiveSubCategorySelect.subCategories && dataObjectForRecursiveSubCategorySelect.subCategories.length > 0">
      <ng-container
        *ngTemplateOutlet="RecursiveSubCategorySelect; context:{ 'dataObject': dataObjectForRecursiveSubCategorySelect}"></ng-container>
    </ng-container>


    <ng-template #RecursiveSubCategorySelect let-dataObject="dataObject">
      <div>
        <span style="color: red">*</span> {{confChange.subCategoryLabel[selectedLanguage]}}
        <mat-form-field appearance="outline">
          <mat-select (ngModelChange)="onSubCategoryChange($event, dataObject)"
                      [(ngModel)]="tempObjectForNotManpowerTable.subCategory[dataObject.level].value">
            <mat-option *ngFor="let subCategory of dataObject.subCategories" [value]="subCategory.value">
              {{subCategory.label[selectedLanguage]}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <ng-container
        *ngIf="dataObject.dataObject && dataObject.dataObject.subCategories && dataObject.dataObject.subCategories.length > 0">
        <ng-container
          *ngTemplateOutlet="RecursiveSubCategorySelect; context:{ 'dataObject': dataObject.dataObject}"></ng-container>
      </ng-container>
    </ng-template>

    <div [style.width]="getWidthFromPlatform()" *ngIf="showQuantityField">
      <label><span style="color: red">*</span> {{confChange.quantityLabel[selectedLanguage]}}</label>
      <mat-form-field appearance="outline">
        <input (keyup)="preventMaxlengthViolation(tempObjectForNotManpowerTable.quantity, 'quantity', 9)"
               [(ngModel)]="tempObjectForNotManpowerTable.quantity" id="quantity"
               matInput maxlength="9"
               type="number">
      </mat-form-field>
      <p class="counter-style">
        {{getTextLength(tempObjectForNotManpowerTable.quantity)}} / 9
        {{'ecase.common.characters' | translate}}</p>
    </div>

    <!---->
    <div>
      <label><span style="color: red">*</span> {{confChange.descriptionLabel[selectedLanguage]}}</label>
      <mat-form-field appearance="outline">
        <textarea (keyup)="changeValueEvent(tempObjectForNotManpowerTable.description, 'description')"
                  [(ngModel)]="tempObjectForNotManpowerTable.description" [rows]="confChange.numberOfRowsForTextarea"
                  id='description'
                  matInput></textarea>
      </mat-form-field>
      <p *ngIf="confChange.isMaxLengthWords" class="counter-style">
        {{getWordCount(tempObjectForNotManpowerTable.description)}} / {{confChange.maxlength}} {{'ecase.common.words' |
        translate}}</p>
      <p *ngIf="!confChange.isMaxLengthWords" class="counter-style">
        {{getTextLength(tempObjectForNotManpowerTable.description)}} / {{confChange.maxlength}}
        {{'ecase.common.characters' | translate}}</p>
    </div>

    <div>
      <label>{{confChange.commentsLabel[selectedLanguage]}}</label>
      <mat-form-field appearance="outline">
        <textarea (keyup)="changeValueEvent(tempObjectForNotManpowerTable.comments, 'comments')"
                  [(ngModel)]="tempObjectForNotManpowerTable.comments" [rows]="confChange.numberOfRowsForTextarea"
                  id='comments'
                  matInput></textarea>
      </mat-form-field>
      <p *ngIf="confChange.isMaxLengthWords" class="counter-style">
        {{getWordCount(tempObjectForNotManpowerTable.comments)}} / {{confChange.maxlength}} {{'ecase.common.words' |
        translate}}</p>
      <p *ngIf="!confChange.isMaxLengthWords" class="counter-style">
        {{getTextLength(tempObjectForNotManpowerTable.comments)}} / {{confChange.maxlength}} {{'ecase.common.characters'
        | translate}}</p>

    </div>

    <div fxLayout="column" fxLayoutAlign="start" style="margin-bottom: 1.34375em;" *ngIf="showUploadField">
      <span><span style="color: red">*</span> {{confChange.documentationLabelInsideTable[selectedLanguage]}}</span>
      <label
        *ngIf="!(tempObjectForNotManpowerTable.uploadFile) || tempObjectForNotManpowerTable.uploadFile.length === 0"
        [style.width.px]="selectedLanguage=== 'en' ? 125 : 201"
        class="myLabel mat-elevation-z3">
        <mat-icon style="vertical-align: bottom; margin-right: 12px;">cloud_upload</mat-icon>
        <input (change)="uploadDone1($event, tempObjectForNotManpowerTable)" accept="application/pdf" id="upload_doc"
               type="file"/>
        <span style="vertical-align: text-bottom;">{{getTranslatedLabel("filetoUpload")}}</span>
      </label>
      <label *ngIf="tempObjectForNotManpowerTable.uploadFile?.length > 0">
        <a href="/api/viewAttachedRepositoryDocument/{{tempObjectForNotManpowerTable.uploadFile[0].id}}"
           target="_blank">{{tempObjectForNotManpowerTable.uploadFile[0].fileName}}</a>
        <button mat-icon-button>
          <mat-icon (click)="deleteFile(tempObjectForNotManpowerTable.uploadFile[0], tempObjectForNotManpowerTable)"
                    aria-label="Delete">
            delete
          </mat-icon>
        </button>
      </label>
    </div>

    <table class="angular-table showSecondTable" id="budget_t1_table" style="margin-bottom: 1.34375em;">
      <thead class="angular-table-thead">
      <tr class="angular-table-tr">
        <th class="angular-table-th"></th>
        <th class="angular-table-th"><span style="color: red">*</span> {{confChange.subtotalLabel[selectedLanguage]}}
        </th>
        <th class="angular-table-th"><span style="color: red">*</span>
          {{confChange.fundsFromKfasLabel[selectedLanguage]}}
        </th>
        <th class="angular-table-th"><span style="color: red">*</span>
          {{confChange.fundsFromOtherInstitutionLabel[selectedLanguage]}}
        </th>
      </tr>
      </thead>

      <!--{{expandableRows.label[selectedLanguage]}}-->
      <ng-container *ngFor="let expandableRows of tempObjectForNotManpowerTable.expandRows;let i = index">
        <tr class="angular-table-tr">
          <td class="angular-table-td">{{'ecase.common.year' | translate}} {{i+1}}</td>
          <td class="angular-table-td" style="padding: 2px 18px;">
            <div
              [ngClass]="expandableRows?.subTotalError && expandableRows?.subTotalError.error && expandableRows?.subTotalError.error[selectedLanguage] ? 'hasError' : 'noError'">
              <mat-form-field
                [ngClass]="expandableRows?.subTotalError && expandableRows?.subTotalError.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''"
                appearance="outline">
                <input
                  (input)="expandableRows.subTotal < 0 ? expandableRows.subTotal = 0 : onSubTotalInput(bphChange.numberOfBudgetTables[1], tempObjectForNotManpowerTable, expandableRows, $event);"
                  [(ngModel)]="expandableRows.subTotal" id="subtotal" matInput max="999999999"
                  maxlength="9"
                  min="0" type="number">
              </mat-form-field>
              <p class="counter-style" style="margin-top: 0px; text-align: right;">
                {{getTextLength2(expandableRows.subTotal)}} / 9</p>
              <div *ngIf="expandableRows?.subTotalError && expandableRows?.subTotalError.error[selectedLanguage]"
                   class="formFieldError">{{expandableRows?.subTotalError.error[selectedLanguage]}}
              </div>
            </div>
          </td>

          <td class="angular-table-td" style="padding: 2px 18px;">
            <div
              [ngClass]="expandableRows?.fundsFromKfasError && expandableRows?.fundsFromKfasError.error && expandableRows?.fundsFromKfasError.error[selectedLanguage] ? 'hasError' : 'noError'">
              <mat-form-field
                [ngClass]="expandableRows?.fundsFromKfasError && expandableRows?.fundsFromKfasError.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''"
                appearance="outline">
                <input
                  (input)="expandableRows.fundsFromKfas < 0 ? expandableRows.fundsFromKfas = 0 : onKfasFundsInput(bphChange.numberOfBudgetTables[1], tempObjectForNotManpowerTable, expandableRows, $event);"
                  [(ngModel)]="expandableRows.fundsFromKfas" id="kfas_funds" matInput max="999999999"
                  maxlength="9"
                  min="0" type="number">
              </mat-form-field>
              <p class="counter-style" style="margin-top: 0px; text-align: right;">
                {{getTextLength2(expandableRows.fundsFromKfas)}} / 9 </p>
              <div
                *ngIf="expandableRows?.fundsFromKfasError && expandableRows?.fundsFromKfasError.error[selectedLanguage]"
                class="formFieldError">{{expandableRows?.fundsFromKfasError.error[selectedLanguage]}}
              </div>
            </div>
          </td>

          <td class="angular-table-td" style="padding: 2px 18px;">
            <div
              [ngClass]="expandableRows?.fundsFromOtherInstitutionError && expandableRows?.fundsFromOtherInstitutionError.error && expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage] ? 'hasError' : 'noError'"
              style="padding-right: 15px">
              <mat-form-field
                [ngClass]="expandableRows?.fundsFromOtherInstitutionError && expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''"
                appearance="outline">
                <input
                  (input)="expandableRows.fundsFromOtherInstitution < 0 ? expandableRows.fundsFromOtherInstitution = 0 : onOtherInstitutionFundsInput(bphChange.numberOfBudgetTables[1], tempObjectForNotManpowerTable)"
                  [(ngModel)]="expandableRows.fundsFromOtherInstitution" [disabled]="true" id="other_funds" matInput
                  max="999999999"
                  maxlength="9"
                  min="0" type="number">
              </mat-form-field>
              <p class="counter-style" style="margin-top: 0px; text-align: right;">
                {{getTextLength2(expandableRows.fundsFromOtherInstitution)}} / 9</p>
              <div
                *ngIf="expandableRows?.fundsFromOtherInstitutionError && expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage]"
                class="formFieldError">
                {{expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage]}}
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
      <tr class="angular-table-tr">
        <td class="angular-table-td"><span>{{confChange.subtotalLabel[selectedLanguage]}}</span></td>
        <td class="angular-table-td">
          <span>{{tempObjectForNotManpowerTable.grandSubTotal}}</span>
        </td>
        <td class="angular-table-td">
          <span>{{tempObjectForNotManpowerTable.grandTotalForKfasFunds}}</span>
        </td>
        <td class="angular-table-td">
          <span>{{tempObjectForNotManpowerTable.grandTotalForOtherFunds}}</span>
        </td>
      </tr>

    </table>

    <button (click)="confirm()" class="eCase-dialog-positive-action-button" id="saveBtn" mat-raised-button
            style="margin-right: 1%;">
      {{getTranslatedLabel("save")}}
    </button>

    <button (click)="cancel()" color="accent" id="cancelBtn" mat-raised-button>
      {{'ecase.common.cancel' | translate}}
    </button>

  </div>

  <div class="field">
    <span *ngIf="bphChange?.error[selectedLanguage]" class="formFieldError">{{bphChange.error[selectedLanguage]}}</span>
  </div>
</div>
