<div [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
     *ngIf="!bphChange.hide && !confChange.showDetails && !showDetails"
     class="{{bphChange.error_class}} hide_{{bphChange.show}}">

  <table style="width: 100%;margin-bottom: 10px;margin-top: 20px"
         class="angular-table {{bphChange.error_class}}">
    <thead class="angular-table-thead">
    <tr class="angular-table-tr">
      <th id="headerRow" [attr.colspan]="bphChange.monthArray.length + 11" class="angular-table-th"
          style="border: 1px solid lightgrey !important;">{{confChange.monthLabel[selectedLanguage]}}
      </th>
    </tr>

    <tr class="angular-table-tr">
      <th class="angular-table-th" colspan="1" style="border: 1px solid lightgrey !important;">
        {{confChange.indexColumnLabel[selectedLanguage]}}
      </th>
      <ng-template ngFor let-aBlock [ngForOf]="filterOutMatSlider(confChange.subBlocks)">
        <th class="angular-table-th" colspan="1" *ngIf="!aBlock.hideCell"
            style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="aBlock.isRequired" style="color: red">* </span>
          <span [innerHTML]="aBlock.label[selectedLanguage]"></span>
          <fb-tooltip [lang]="selectedLanguage" [tooltipText]="aBlock.tooltipText"
                      *ngIf="aBlock.hasTooltip == true"></fb-tooltip>
        </th>
      </ng-template>
     <!-- <th class="angular-table-th" colspan="8" style="border: 1px solid lightgrey !important;">
        {{confChange.taskTitle[selectedLanguage]}}
      </th>-->
      <ng-container *ngFor="let month of bphChange.monthArray; let i = index;">
        <th colspan="1" class="angular-table-th test" style="text-align: center;border: 1px solid lightgrey !important;"
            style="text-align: center;border: 1px solid lightgrey !important;">
          <ng-container *ngIf="isCustomLabel && bphChange.customLabelList.length > 0">
            {{bphChange.customLabelList[i]}}
          </ng-container>
          <ng-container *ngIf="(!isCustomLabel || bphChange.customLabelList.length == 0)">
            {{i + 1}}
          </ng-container>
        </th>
      </ng-container>
      <th class="angular-table-th" *ngIf="confChange.readOnlyTable" style="border: 1px solid lightgrey !important;">
        {{confChange.editLabel[selectedLanguage]}}
      </th>
      <th class="angular-table-th" *ngIf="confChange.showDelete"
          style="text-align: center; border: 1px solid lightgrey !important;">
        {{confChange.deleteLabel[selectedLanguage]}}
      </th>
    </tr>

    </thead>


    <tbody>
    <tr class="angular-table-tr" *ngFor="let row of bphChange.rowsForSchedulerTable; let i = index"
        [class]="row.isTotal?'active':''"
        style="border-bottom: 1px solid rgba(0,0,0,.12);">
      <td class="scheduler-table-td" colspan="1" style="border: 1px solid lightgrey !important;">{{i + 1}}</td>
     <!-- <td class="scheduler-table-td" colspan="8"
          style="min-width: 10em; height: 1em; border: 1px solid lightgrey !important;">{{row.taskTitle}}
        <span *ngIf="bphChange.rows[i].text_task_title?.error[selectedLanguage]" class="tableFormFieldError">{{bphChange.rows[i].text_task_title?.error[selectedLanguage]}}</span>
      </td>-->
      <ng-container *ngFor="let subBlock of confChange.subBlocks">
        <ng-container [ngSwitch]="subBlock.templateName">
          <ng-container *ngSwitchCase="'statictext'">
            <ng-container
              *ngTemplateOutlet="statictext; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'textarea'">
            <ng-container
              *ngTemplateOutlet="textarea; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'Radiobutton'">
            <ng-container
              *ngTemplateOutlet="Radiobutton; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'select'">
            <ng-container
              *ngTemplateOutlet="select; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'Container'">
            <ng-container
              *ngTemplateOutlet="Container; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'Table'">
            <ng-container
              *ngTemplateOutlet="Table; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'SubmitButton'">
            <ng-container
              *ngTemplateOutlet="SubmitButton; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'upload'">
            <ng-container *ngTemplateOutlet="upload; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'text'">
            <ng-container *ngTemplateOutlet="text; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'Checkbox'">
            <ng-container *ngTemplateOutlet="Checkbox; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'datepicker'">
            <ng-container *ngTemplateOutlet="datepicker; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'AutoComplete'">
            <ng-container *ngTemplateOutlet="AutoComplete; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault></ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngFor="let month of bphChange.monthArray; let i = index;">
        <td colspan="1" class="scheduler-table-td" style="text-align: center;border: 1px solid lightgrey !important;"
            style="text-align: center;border: 1px solid lightgrey !important;">
          <ng-container *ngIf="row.userSelectedStartMonth !== row.userSelectedMaxMonth">
            <ng-container *ngIf="month >= row.userSelectedStartMonth - 1 && month <= row.userSelectedMaxMonth - 1">
              <div class="accent-800-bg"
                   style="padding-top: 45px; width:110%;"></div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="row.userSelectedStartMonth === row.userSelectedMaxMonth">
            <ng-container *ngIf="month === row.userSelectedStartMonth -1">
              <div class="accent-800-bg"
                   style="padding-top: 45px; width:110%;"></div>
            </ng-container>

            <ng-container *ngIf="i === row.endMonth && number === row.userSelectedMaxYear">
              <div class="accent-800-bg"
                   style="padding-top: 45px; width:100%;"></div>
            </ng-container>

          </ng-container>
        </td>
      </ng-container>

      <td class="scheduler-table-td" *ngIf="confChange.readOnlyTable "
          style="text-align: center; border: 1px solid lightgrey !important;">
        <button [disabled]="confChange.isReadOnlyField" mat-icon-button (click)="editRow(bphChange,confChange,i)">
          <mat-icon>create</mat-icon>
        </button>
      </td>
      <td class="scheduler-table-td" *ngIf="confChange.showDelete"
          style="text-align: center; border: 1px solid lightgrey !important;">
        <button [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode" mat-icon-button
                (click)="deleteRowForSchedulerTable(bphChange,confChange,i)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </tr>
    </tbody>


  </table>

  <div fxLayout="column" fxLayoutAlign="start">
    <button *ngIf="confChange.showAddRow" (click)="addRow(bphChange,confChange)"
            [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode"
            mat-raised-button color="accent" style="width:max-content">{{confChange.label[selectedLanguage]}}
    </button>
    <br>
    <span style="float: left;" class="tableSpanError">{{bphChange.error[selectedLanguage]}}</span>
  </div>
</div>


<div *ngIf="!bphChange.show && confChange.readOnlyTable && confChange.showDetails && showDetails"
     class="fb_table_dertails">
  <!-- _BPH_TE_SUBCONTENT_S --> <!-- _BPH_TE_SUBCONTENT_E -->
  <ng-container *ngFor="let subBlock of confChange.subBlocks">
    <ng-container [ngSwitch]="subBlock.templateName">
      <ng-container *ngSwitchCase="'statictext'">
        <ng-container
          *ngTemplateOutlet="statictext; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'textarea'">
        <ng-container
          *ngTemplateOutlet="textarea; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Radiobutton'">
        <ng-container
          *ngTemplateOutlet="Radiobutton; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'select'">
        <ng-container
          *ngTemplateOutlet="select; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Container'">
        <ng-container
          *ngTemplateOutlet="Container; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Table'">
        <ng-container
          *ngTemplateOutlet="Table; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'SubmitButton'">
        <ng-container
          *ngTemplateOutlet="SubmitButton; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'upload'">
        <ng-container
          *ngTemplateOutlet="upload; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'text'">
        <ng-container
          *ngTemplateOutlet="text; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Checkbox'">
        <ng-container
          *ngTemplateOutlet="Checkbox; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'datepicker'">
        <ng-container
          *ngTemplateOutlet="datepicker; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'AutoComplete'">
        <ng-container
          *ngTemplateOutlet="AutoComplete; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'MatSlider'">
        <ng-container
          *ngTemplateOutlet="MatSlider; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault></ng-container>
    </ng-container>
  </ng-container>
  <button style=" background-color: #4FC3F7; color: #FFFFFF; margin-right: 1%"
          (click)="closeDetails(confChange,bphChange)"
          mat-raised-button><span>{{getTranslatedLabel("ecase.common.save")}}</span>
  </button>
  <button (click)="cancel(confChange,bphChange)"
          mat-raised-button color="accent"><span>{{getTranslatedLabel("ecase.common.cancel")}}</span>
  </button>
</div>

<ng-template #SubmitButton let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-submit-button style='position: static;display: table-cell;vertical-align: middle;padding-right:20px;'
                    [selectedLanguage]='selectedLanguage'
                    [isValidHtml]='isValidHtml'
                    [isValidTable]='isValidTable' [isValidTableReadonly]='isValidTableReadonly'
                    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
                    [confChange]='confChange' [globalConf]='globalConf'
                    [globalPrj]='globalPrj'></fb-submit-button>
</ng-template>
<ng-template #statictext let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-static-text
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'"
    [selectedLanguage]='selectedLanguage'
    [isValidHtml]='isValidHtml'
    [isValidTable]='isValidTable'
    [isValidTableReadonly]='isValidTableReadonly'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'>
  </fb-static-text>
</ng-template>
<ng-template #textarea let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-text-area
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'"
    [selectedLanguage]='selectedLanguage'
    [isValidHtml]='isValidHtml'
    [isValidTable]='isValidTable'
    [isValidTableReadonly]='isValidTableReadonly'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'>
  </fb-text-area>
</ng-template>
<ng-template #Radiobutton let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-radiobutton
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'"
    [selectedLanguage]='selectedLanguage'
    [isValidHtml]='isValidHtml'
    [isValidTable]='isValidTable'
    [isValidTableReadonly]='isValidTableReadonly'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'>
  </fb-radiobutton>
</ng-template>
<ng-template #select let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-select
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'"
    [selectedLanguage]='selectedLanguage'
    [isValidHtml]='isValidHtml'
    [isValidTable]='isValidTable'
    [isValidTableReadonly]='isValidTableReadonly'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'>
  </fb-select>
</ng-template>
<ng-template #text let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-text
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'"
    [selectedLanguage]='selectedLanguage'
    [isValidHtml]='isValidHtml'
    [isValidTable]='isValidTable'
    [isValidTableReadonly]='isValidTableReadonly'
    [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'>
  </fb-text>
</ng-template>
<ng-template #upload let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-upload
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'"
    [selectedLanguage]='selectedLanguage'
    [isValidHtml]='isValidHtml'
    [isValidTable]='isValidTable'
    [isValidTableReadonly]='isValidTableReadonly'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'>
  </fb-upload>
</ng-template>
<ng-template #Checkbox let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-checkbox
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'"
    [selectedLanguage]='selectedLanguage'
    [isValidHtml]='isValidHtml'
    [isValidTable]='isValidTable'
    [isValidTableReadonly]='isValidTableReadonly'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'>
  </fb-checkbox>
</ng-template>
<ng-template #datepicker let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-datepicker
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'"
    [selectedLanguage]='selectedLanguage'
    [isValidHtml]='isValidHtml'
    [isValidTable]='isValidTable'
    [isValidTableReadonly]='isValidTableReadonly'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'>
  </fb-datepicker>
</ng-template>
<ng-template #AutoComplete let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-autoComplete
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'"
    [selectedLanguage]='selectedLanguage'
    [isValidHtml]='isValidHtml'
    [isValidTable]='isValidTable'
    [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
    [isValidTableReadonly]='isValidTableReadonly'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'>
  </fb-autoComplete>
</ng-template>
<ng-template #MatSlider let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-mat-slider
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'"
    [selectedLanguage]='selectedLanguage'
    [isValidHtml]='isValidHtml'
    [isValidTable]='isValidTable'
    [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
    [isValidTableReadonly]='isValidTableReadonly'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'>
  </fb-mat-slider>
</ng-template>

