import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ECaseUtils, PendingChangesGuard, RefreshDataService} from 'synto-common';


@Component({
  selector: 'fb-radiobutton',
  templateUrl: './fb-radiobutton.component.html',
  styleUrls: ['./fb-radiobutton.component.scss']
})
export class FbRadiobuttonComponent implements OnInit {
  @Input() url: any;
  @Input() selectedLanguage;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() globalConf: any;
  @Input() globalPrj: any;
  @Input() rowNumber;
  @Output() emitOutputEvent = new EventEmitter<any>();
  dataSqlList = [];
  row: any;
  sortedlov: any;
  list: any[] = [];
  isDynamicSql = false;
  searchResult = [];
  isDynamicList = false;
  dynamicList = [];


  constructor(private translate: TranslateService,
              private refreshDataService: RefreshDataService,
              private pendingChangesGuard: PendingChangesGuard,
              public dialog: MatDialog) {
    this.bphChange = {};
    this.confChange = {};
    this.bphChange.value = {};
    this.bphChange.listName = this.confChange.listName;
    this.bphChange.error = {};
    this.confChange.label = {};
    this.row = {};
  }

  removeItemsToHide(list: any[]): any[] {
    if (this.confChange.lovItemsToHide && this.confChange.lovItemsToHide.length > 0) {
      return list.filter(item => !this.confChange.lovItemsToHide.includes(item.value)).sort((a, b) => {
        if (a.option && b.option) {
          return a.option.sortingKey - b.option.sortingKey;
        } else {
          return 0;
        }
      });
    } else {
      return list.sort((a, b) => {
        if (a.option && b.option) {
          return a.option.sortingKey - b.option.sortingKey;
        } else {
          return 0;
        }
      });
    }
  }

  change(nv, c, s): void {
    if (s.value === '') {
      s.value = {};
    }
    s.value.value = nv;
    console.log(' ----> value changed check');
    if (this.confChange.valueChanged) {
      console.log(' ----> value changed check');
      try {
        this.confChange.valueChanged(nv, this.globalPrj);
      } catch (e) {
        console.error(e);
      }

    }

    let listToPass = [];
    if(this.isDynamicSql && !this.isDynamicList) {
      listToPass =  this.globalConf.lovs[this.confChange.lovSQL.name].list
    } else if(!this.isDynamicSql && !this.isDynamicList) {
      listToPass = this.globalConf.lovs[c.listName].list;
    } else if(!this.isDynamicSql && this.isDynamicList) {
      listToPass = this.dynamicList;
    }

    listToPass.forEach((e) => {
      if (e.value === nv) {
        s.value.label = e.label;
        s.value.source = e.source;
        s.value.source_id = e.source_id;
        if (c.subList) {
          // eslint-disable-next-line no-eval
          eval('globalPrj.' + c.subList + '.listName=e.subList;');
        }
      }
    });
    if (c && c.executeOnChange) {
      this.confChange.executeOnChange(this.globalConf, this.globalPrj, this.selectedLanguage, nv);
    }
    this.changeValueEvent();
  }

  disableOnValue(currentValue, localConf, i): boolean {
    if (localConf.isDisableOnValue) {
      try {
        let b = false;
        for (let j = 0; j < currentValue.value.length; j++) {
          if (currentValue.value[j].value && j === localConf.disableValue) {
            b = true;
            break;
          }
        }
        return b && localConf.disableValue !== i;
      } catch (e) {
        return false;
      }
    } else {
      return false;
    }

  }

  openDialog(): void {
    this.dialog.open(DialogComponent, {
      width: '600px',
      data:
        {
          dialog: this.confChange.dialogText,
          selectedLanguage: this.selectedLanguage
        }
    });
    console.log('donee');
  }

  ngOnInit(): void {
    console.log('This is the selected language');
    console.log(this.selectedLanguage);
    this.sortedlov = [];
    if (!(this.bphChange)) {
      this.bphChange = {};
      this.bphChange.value = {};
      this.bphChange.error = {};
    }
    if (this.bphChange.value === '') {
      this.bphChange.value = {};
    }
    if (!this.bphChange.value) {
      this.bphChange.value = {};
    }
    if (!this.bphChange.error) {
      this.bphChange.error = {};
    }

    this.bphChange.listName = this.confChange.listName;
    console.log(this.sortedlov);
    console.log(this.selectedLanguage);
    this.confChange.getValue = (currentValue, lang): string => {
      if (currentValue.value && currentValue.value.label) {
        return currentValue.value.label[lang];
      } else {
        return '';
      }
    };


    this.confChange.initValue = (currentValue, localConf): void => {
      currentValue.value = {};
      currentValue.error = {};
      currentValue.listName = localConf.listName;
      currentValue.getValue = (): any => this.bphChange.value.value;

    };
    if (this.confChange.tooltipText) {
      this.confChange.tooltipText[this.selectedLanguage] = ECaseUtils.encodeStringToUTF8String(this.confChange.tooltipText[this.selectedLanguage]);
    }

    if (this.confChange.lovSQL) {
      this.isDynamicSql = true;
      if (this.globalConf.lovs[this.confChange.lovSQL.name]) {
        this.list = this.searchResult.concat(this.globalConf.lovs[this.confChange.lovSQL.name].list);
        this.bphChange.listName = this.confChange.lovSQL.name;
      }
    } else {
      this.list = this.globalConf.lovs[this.bphChange.listName] && this.globalConf.lovs[this.bphChange.listName].list
        ? this.globalConf.lovs[this.bphChange.listName].list : [];
      if (this.list.length > 0) {
        if (this.isSortingKeySame(this.list)) {
          this.list = this.list.sort((a, b) => a.label[this.translate.getDefaultLang()].localeCompare(b.label[this.translate.getDefaultLang()]));
        } else {
          this.list = this.list.sort((a, b) => {
            if (a.sortingKey < b.sortingKey) {
              return -1;
            } else if (a.sortingKey > b.sortingKey) {
              return 1;
            }
            return 0;
          });
        }
      }
    }


    this.refreshDataService.getNewListForRadioButton().subscribe((data) => {
      console.log('change list of radio')
      if (data && (data.name === this.confChange.name) && (this.rowNumber === undefined || this.rowNumber === data.rowNumber) && data.list) {
        this.bphChange.list = data.list;
        this.list = this.bphChange.list;
        this.isDynamicList = true;
        this.dynamicList = data.list;
        this.bphChange.list = this.bphChange.list.sort((a, b) => {
          if (a.sortingKey && b.sortingKey){
            if(a.sortingKey > b.sortingKey) {
              return 1;
            } else if (a.sortingKey < b.sortingKey) {
              return -1;
            }
          } else {
            return a.label[this.selectedLanguage].localeCompare(b.label[this.selectedLanguage]);
          }
        });
      }
    });

  }

  isSortingKeySame(list: any[]): boolean {
    return list.every(item => item.sortingKey === list[0].sortingKey);
  }

  getValue(currentValue, lang): string {
    if (currentValue.value && currentValue.value.label) {
      return currentValue.value.label[lang];
    } else {
      return '';
    }
  }

  changeValueEvent(): void {
    this.pendingChangesGuard.isPristine = false;
    console.log("got inside change value event of radio button", this.bphChange);
    if (this.bphChange.isEnableOutputEvent || this.confChange.enableOutputEvent) {
      this.emitOutputEvent.emit(this.confChange);
    }
    this.refreshDataService.setNewValueForStaticText(this.confChange.name, this.getValue(this.bphChange, this.selectedLanguage));
  }


  showAditionalMessage(valuesList): boolean {
    if(valuesList){
      if(valuesList.includes(',')){
         let list = valuesList.split(',');
         list = list.map(it => Number(it.trim()));
         return list.includes(this.bphChange.value.value);
      } else {
        return Number(valuesList) === this.bphChange.value.value;
      }
    }
    return false;
  }
}
