<div *ngIf="!bphChange.hide && !confChange.showDetails && !bphChange.showDetails && !globalConf.showDetails"
     [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
     class="{{bphChange.error_class}} hide_{{bphChange.show}}"
     style="margin-bottom: 1.34375em">
  <div class="formLabel">
    <ng-container *ngIf="confChange.condensedLabel && confChange.condensedLabel[selectedLanguage]">
      <span style="font-weight: bolder" [innerHTML]="confChange.condensedLabel[selectedLanguage]"></span>
      <br>
    </ng-container>
    <span *ngIf="confChange.isRequired" style="color: red">* </span>
    <span *ngIf="confChange.tableLabel" [innerHTML]="confChange.tableLabel[selectedLanguage]"></span>
    <span *ngIf="confChange.hasTooltip" style="padding-top: 10px;">
             <mat-icon matTooltip="{{confChange.tooltipText[selectedLanguage]}}"
                       matTooltipClass="tooltip">help_outline</mat-icon>
          </span>

    <span *ngIf="confChange.hasDialog" style="padding-top: 10px;">
             <mat-icon (click)="openDialog(confChange)"
                       style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;">help_outline</mat-icon>
          </span>
  </div>
  <div *ngIf="confChange.showTotalRows && confChange.showTotalRowsVerticalAligment === 'top' || confChange.showTotalRowsVerticalAligment === 'both'"
       [style.float]="confChange.showTotalRowsHorizontalAlignment">
    <p style="font-weight: bolder; font-size: 12px;">{{confChange.totalRowsLabel[selectedLanguage]}} {{bphChange.rows.length}}</p>
  </div>
  <ng-container
    *ngIf="!confChange.hasShowNodata || (confChange.hasShowNodata && !confChange.showAddRow && bphChange.rows.length > 0)">
    <table [id]="selectedSectionId + '_' + confChange.name"
           class="angular-table {{bphChange.error_class}}" style="width: 100%;margin-bottom: 10px;margin-top: 5px">
      <thead class="angular-table-thead">
      <tr *ngIf="confChange.showSuperHeader" class="angular-table-tr">
        <th *ngIf="confChange.isRowsDraggable" class="angular-table-th"></th>
        <ng-template [ngForOf]="confChange.headers" let-aBlock ngFor>
          <th [attr.colspan]="aBlock.colspan"
              [ngClass]="aBlock.align ? (aBlock.align.includes('left') ? 'align-start' : (aBlock.align.includes('right') ? 'align-end' : 'align-center')) : 'align-center'"
              [style.border-left]="(aBlock.colspan && aBlock.colspan > 1) ? '1px solid rgba(0, 0, 0, .12)' : ''"
              [style.border-right]="(aBlock.colspan && aBlock.colspan > 1) ? '1px solid rgba(0, 0, 0, .12)' : ''"
              [style.width.%]="(confChange.isWidthSpecifiedForSubBlocks && aBlock.columnWidthPercentage) ? aBlock.columnWidthPercentage : ''"
              class="angular-table-th">
            <span *ngIf="aBlock.isRequired" style="color: red">* </span>
            <span *ngIf="!aBlock.isTableColumnLabel" [innerHTML]="aBlock.label[selectedLanguage]"></span>
            <span *ngIf="aBlock.isTableColumnLabel" [innerHTML]="aBlock.tableColumnLabel[selectedLanguage]"></span>
            <span *ngIf="aBlock.hasDialog" style="padding-top: 10px;">
             <mat-icon (click)="openDialog(aBlock)"
                       style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;">help_outline</mat-icon>
            </span>
            <span *ngIf="aBlock.isShowDateFormat" style="white-space: nowrap"><br>({{aBlock.dateFormatToBeShown[selectedLanguage]}}
              )</span>
          </th>
        </ng-template>
      </tr>
      <tr class="angular-table-tr">
        <th *ngIf="confChange.listName"
            [ngClass]="confChange.align ? (confChange.align.includes('left') ? 'align-start padding-left' : (confChange.align.includes('right') ? 'align-end' : 'align-center')) : 'align-center'"
            [style.min-width.px]="confChange.min_width ? confChange.min_width : ''"
            [style.width.%]="confChange.width_pct ? confChange.width_pct : ''"
            class="angular-table-th"
            style="min-width: 250px">{{confChange.listNameColumnHeaderLabel ? confChange.listNameColumnHeaderLabel[selectedLanguage] : confChange.listNameToShow}}</th>
        <th *ngIf="confChange.isRowsDraggable" class="angular-table-th"></th>

        <ng-template [ngForOf]="getFilteredSubBlocks()" let-aBlock ngFor>
          <th *ngIf="!aBlock.hideCell" [ngClass]="aBlock.align ? (aBlock.align.includes('left') ? 'align-start' : (aBlock.align.includes('right') ? 'align-end' : 'align-center')) : 'align-center'"
              [style.width.%]="(confChange.isWidthSpecifiedForSubBlocks && aBlock.columnWidthPercentage) ? aBlock.columnWidthPercentage : ''"
              class="angular-table-th"
              style="border-bottom: 1px solid rgba(0,0,0,.12);min-width: 80px;">
            <div fxLayout="row" style="align-items: center;"
                 [style.justify-content]="aBlock.textHeadAlignment ? 'left' : 'center'">
              <span *ngIf="aBlock.isRequired" style="color: red">* </span>
              <span *ngIf="!aBlock.isTableColumnLabel" [innerHTML]="aBlock.label[selectedLanguage]"
                    [style.cursor]="aBlock.isSortable ? 'pointer' : ''"
                    [style.font-weight]="aBlock.sortActive ? 'bolder' : ''"
                    (click)="sortRows(aBlock)"></span>
              <span *ngIf="aBlock.isTableColumnLabel" [innerHTML]="aBlock.tableColumnLabel[selectedLanguage]"
                    [style.cursor]="aBlock.isSortable ? 'pointer' : ''"
                    [style.font-weight]="aBlock.sortActive ? 'bolder' : ''"
                    (click)="sortRows(aBlock)"></span>
              <span *ngIf="aBlock.isShowDateFormat" style="white-space: nowrap"><br>({{aBlock.dateFormatToBeShown[selectedLanguage]}}
                )</span>
              <mat-icon *ngIf="aBlock.isSortable && aBlock.sortActive" style="cursor: pointer" (click)="sortRows(aBlock)">
                {{aBlock.sortDirection === 'asc' ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
              </mat-icon>
            </div>
            <fb-tooltip *ngIf="aBlock.hasTooltip == true && !aBlock.hideTooltipHeader" [lang]="selectedLanguage"
                        [tooltipText]="aBlock.tooltipText"></fb-tooltip>
            <span *ngIf="aBlock.hasDialog" style="padding-top: 10px;">
             <mat-icon (click)="openDialog(aBlock)"
                       style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;">help_outline</mat-icon>
            </span>
          </th>
        </ng-template>
        <th *ngIf="confChange.readOnlyTable" class="angular-table-th"> {{confChange.editLabel ? confChange.editLabel[selectedLanguage] : '' }}</th>
        <th *ngIf="confChange.showDelete" class="angular-table-th"
            style="text-align: center;padding-right: 10px !important; border-bottom: 1px solid rgba(0, 0, 0, 0.12); min-width: 80px; padding-bottom: 8px !important;">
          {{confChange.deleteLabel[selectedLanguage]}}
        </th>
      </tr>
      </thead>
      <thead *ngIf="confChange.isColumnsFiltered" class="angular-table-thead">
      <tr class="angular-table-tr">
        <th *ngIf="confChange.listName"
            [style.min-width.px]="confChange.min_width ? confChange.min_width : ''"
            [style.width.%]="confChange.width_pct ? confChange.width_pct : ''"
            class="mat-table_filtered_header_cell"
            style="min-width: 250px"></th>
        <th *ngIf="confChange.isRowsDraggable" class="angular-table-th mat-table_filtered_header_cell">
          <input *ngIf="showFilteredImage" alt="clear" class="row_filter_icon"
                 disabled matRipple
                 src="../../../../assets/images/etc/icons8-conversion.svg" type="image">
          <input (click)="resetFilters()" *ngIf="!showFilteredImage" alt="clear"
                 class="row_filter_icon" src="../../../../assets/images/etc/icons8-clear-filters.svg"
                 type="image">
        </th>

        <ng-container *ngFor="let aBlock of getFilteredSubBlocks(); let subBlockIndex=index">
          <th *ngIf="!aBlock.hideCell" [style.width.%]="(confChange.isWidthSpecifiedForSubBlocks && aBlock.columnWidthPercentage) ? aBlock.columnWidthPercentage : ''"
              class="angular-table-th mat-table_filtered_header_cell">
            <span class='nodisplay'>.</span>
            <input *ngIf="showFilteredImage && !confChange.isRowsDraggable && subBlockIndex === 0" alt="clear" class="row_filter_icon"
                   disabled matRipple
                   src="../../../../assets/images/etc/icons8-conversion.svg" type="image">
            <input (click)="resetFilters()" *ngIf="!showFilteredImage && !confChange.isRowsDraggable && subBlockIndex === 0" alt="clear"
                   class="row_filter_icon" src="../../../../assets/images/etc/icons8-clear-filters.svg"
                   type="image">
            <input  *ngIf="isFilterFieldVisible(aBlock)" class="searchFields" type="text"
                   [(ngModel)]="aBlock.valueToFilter"
                   [attr.aria-label]="'ecase.common.search' | eCaseTranslate"
                   [placeholder]="'ecase.common.search' | eCaseTranslate"
                   (keyup)="applyFilter($event, aBlock)">
          </th>
        </ng-container>
        <th *ngIf="confChange.readOnlyTable" class="angular-table-th mat-table_filtered_header_cell"></th>
        <th *ngIf="confChange.showDelete" class="angular-table-th mat-table_filtered_header_cell">
        </th>
      </tr>
      </thead>

      <tbody *ngIf="!confChange.isRowsDraggable">
      <ng-container *ngFor="let row of bphChange.rows; let i = index">
        <tr [class]="row.isTotal ? 'active': ''" class="angular-table-tr" [style.display]="row.isHidden ? 'none' : ''"
            [style.background-color]="row.isActivateTableRowColor ? row.tableRowColor : ''"
            [style.font-weight]="row.isActivateTableRowFont ? row.tableRowFont : ''"
            style="border-bottom: 1px solid rgba(0,0,0,.12);">
          <ng-container
            *ngTemplateOutlet="commonTableBody; context: {templateContext : row , rowIndex : i}"></ng-container>
        </tr>
        <ng-container *ngTemplateOutlet="columnAsRow; context: {templateContext : row , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngTemplateOutlet="otherTableRows;"></ng-container>
      </tbody>

      <tbody (cdkDropListDropped)="onDrop($event)" *ngIf="confChange.isRowsDraggable" cdkDropList class="drag-boundary">
      <ng-container *ngFor="let row of bphChange.rows; let i = index">
        <tr (cdkDragMoved)="updateDragStatus($event, i)"
            [id]="confChange.name + 'row' + i"
            [style.display]="row.isHidden ? 'none' : ''"
            [ngClass]="(isDragActive && rowIndexBeingDragged === i) ? 'angular-table-tr draggable-tr inDrag': 'angular-table-tr draggable-tr'" cdkDrag cdkDragBoundary=".drag-boundary" cdkDragLockAxis="y"
            style="border-bottom: 1px solid rgba(0,0,0,.12);">
          <td cdkDragHandle class="angular-table-td drag-handle"
              [ngClass]="confChange.align ? (confChange.align.includes('left') ? 'align-start' : (confChange.align.includes('right') ? 'align-end' : 'align-center')) : 'align-center'"
              style="border: 0;
              width: 10%;
              text-align: center;
              padding: 10px;">
            <mat-icon>menu</mat-icon>
          </td>
          <ng-container
            *ngTemplateOutlet="commonTableBody; context: {templateContext : row , rowIndex : i}"></ng-container>
        </tr>
        <ng-container *ngTemplateOutlet="columnAsRow; context: {templateContext : row , rowIndex : i}"></ng-container>
      </ng-container>
      <ng-container *ngTemplateOutlet="otherTableRows;"></ng-container>
      </tbody>
    </table>
    <div *ngIf="confChange.showTotalRows && confChange.showTotalRowsVerticalAligment === 'top' || confChange.showTotalRowsVerticalAligment === 'both'"
         [style.float]="confChange.showTotalRowsHorizontalAlignment">
      <p style="font-weight: bolder; font-size: 12px;">{{confChange.totalRowsLabel[selectedLanguage]}} {{bphChange.rows.length}}</p>
    </div>
   <ng-container *ngIf="confChange.isTablePaginated">
     <mat-paginator [length]="this.bphChange.rows.length"
                    [pageSize]="pageSize"
                    [pageIndex]="pageIndex"
                    [pageSizeOptions]="pageSizeOptions"
                    (page)="updatePagination($event)"
                    aria-label="Select page">
     </mat-paginator>
   </ng-container>
  </ng-container>
  <ng-container *ngIf="confChange.hasShowNodata && !confChange.showAddRow && bphChange.rows.length === 0">
    <span>{{confChange.showNodatatext[selectedLanguage]}}</span>
  </ng-container>
  <div fxLayout="column" style="align-items: flex-start">
    <button
      (click)="addRow(bphChange,confChange)"
      *ngIf="confChange.showAddRow && (!confChange.isLimitOnRowEnabled || (confChange.isLimitOnRowEnabled && bphChange.rows.length < confChange.rowLimit))"
      [disabled]="confChange.isReadOnlyField || (bphChange.globalReadOnlyMode && bphChange.globalReadOnlyMode === 'true')"
      [style.margin-bottom]="bphChange.error[selectedLanguage] ? '5px' : 0"
      color="accent"
      mat-raised-button style="width:max-content">{{confChange.label[selectedLanguage]}}
    </button>
    <span *ngIf="bphChange.error[selectedLanguage]" class="tableSpanError">{{bphChange.error[selectedLanguage]}}</span>
  </div>
</div>

<div
  *ngIf="!bphChange.show && confChange.readOnlyTable && confChange.showDetails && bphChange.showDetails && bphChange.rows[selectedTableRowIndex]"
  class="fb_table_dertails">
  <!-- _BPH_TE_SUBCONTENT_S --> <!-- _BPH_TE_SUBCONTENT_E -->

  <ng-container *ngFor="let subBlock of confChange.subBlocks">
      <ng-container [ngSwitch]="subBlock.templateName">
        <ng-container *ngSwitchCase="'statictext'">
          <ng-container
            *ngTemplateOutlet="statictext; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'textarea'">
          <ng-container
            *ngTemplateOutlet="textarea; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Radiobutton'">
          <ng-container
            *ngTemplateOutlet="Radiobutton; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'select'">
          <ng-container
            *ngTemplateOutlet="select; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Container'">
          <ng-container
            *ngTemplateOutlet="Container; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Table'">
          <ng-container
            *ngTemplateOutlet="Table; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'SubmitButton'">
          <ng-container
            *ngTemplateOutlet="SubmitButton; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'upload'">
          <ng-container
            *ngTemplateOutlet="upload; context: {templateContext : subBlock.templateContext2 , rowIndex : selectedTableRowIndex, blockIndex : (blockIndex + ((selectedTableRowIndex + 1) / 100))}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'text'">
          <ng-container
            *ngTemplateOutlet="text; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Checkbox'">
          <ng-container
            *ngTemplateOutlet="Checkbox; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'datepicker'">
          <ng-container
            *ngTemplateOutlet="datepicker; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'AutoComplete'">
          <ng-container
            *ngTemplateOutlet="AutoComplete; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'richtext'">
          <ng-container
            *ngTemplateOutlet="richtext; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'button'">
          <ng-container
            *ngTemplateOutlet="button; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault></ng-container>
      </ng-container>
  </ng-container>

  <button (click)="closeDetails(confChange,bphChange)"
          [disabled]="isCloseDetailsDisabled"
          *ngIf="isCloseDetailsVisible"
          mat-raised-button
          [style.background-color]="isCloseDetailsDisabled ? '#dddddd' : '#4FC3F7'"
          style="color: #FFFFFF; margin-right: 1%">
    <span>{{getTranslatedLabel("ecase.common.save")}}</span>
  </button>
  <button (click)="cancel(confChange,bphChange)"
          color="accent" mat-raised-button><span>{{getTranslatedLabel("ecase.common.cancel")}}</span>
  </button>
</div>

<ng-template #otherTableRows>
  <tr *ngIf="confChange.hasGlobalTotal" class="active angular-table-tr">

    <td *ngIf="confChange.listName" class="angular-table-td" style="font-weight: bold !important">
      {{confChange.listNameGlobalTotalLabel ? confChange.listNameGlobalTotalLabel[selectedLanguage] : ''}}
    </td>
    <td *ngIf="confChange.isRowsDraggable" class="angular-table-td" style="font-weight: bold !important"></td>
    <td *ngFor="let aBlock of filterHideCellSubBlocks(confChange.subBlocks); let i = index" [ngClass]="getAlignClass()"
        [style.width.%]="(confChange.isWidthSpecifiedForSubBlocks && aBlock.columnWidthPercentage) ? aBlock.columnWidthPercentage : ''"
        class="angular-table-td"
        style="white-space:nowrap; font-weight: bold !important ;"
    [style.padding-right] ="aBlock.isTotalText ? '10%'  : ''">
      <ng-template [ngIf]="aBlock.hasGlobalTotal && !aBlock.isTotalText">
       {{getColumnTotalLabel(aBlock)}} {{getGlobalTotal(bphChange.rows, aBlock.name, i, bphChange.rows[bphChange.rows.length - 1], aBlock.currency, aBlock.globalTotalText, aBlock)}}
      </ng-template>
      <ng-template [ngIf]="aBlock.hasGlobalTotal && aBlock.isTotalText" style="text-align: left !important;">
        {{aBlock.globalTotalText[selectedLanguage]}}
      </ng-template>
    </td>
    <td *ngIf="confChange.readOnlyTable" class="angular-table-td">

    </td>
    <td *ngIf="confChange.showDelete" class="angular-table-td">

    </td>
  </tr>
  <tr *ngIf="confChange.showTableRowCountRow" class="active angular-table-tr">
    <td class="angular-table-td"></td>
    <td class="angular-table-td">
      <span *ngIf="confChange.tableRowCountRowLabel">{{confChange.tableRowCountRowLabel[selectedLanguage]}}</span>
    </td>
    <td class="angular-table-td">
      <span>{{bphChange.rows.length}}</span>
    </td>
    <ng-container *ngIf="confChange.subBlocks.length > 2">
      <td
        *ngFor="let aBlock of createArrayOfLength(filterHideCellSubBlocks(confChange.subBlocks).length - 2); let i = index"
        class="angular-table-td">

      </td>
    </ng-container>
    <td *ngIf="confChange.readOnlyTable" class="angular-table-td">

    </td>
    <td *ngIf="confChange.showDelete" class="angular-table-td">

    </td>
  </tr>
</ng-template>

<ng-template #columnAsRow let-i="rowIndex" let-row="templateContext">
  <ng-container *ngFor="let subBlock of getColumnAsRowSubBlocks()">
    <ng-container *ngIf="subBlock.type == 'textarea' || subBlock.type == 'text'">
      <tr>
        <td class="angular-table-td">
          <span *ngIf="subBlock.isRequired" style="color: red">* </span>
          <span style="padding-top: 13px;width: 100%;">{{subBlock.label[selectedLanguage]}}</span>
        </td>
        <td [attr.colspan]="getColumnAsRowColspan()" class="angular-table-td" style="text-align: left !important;">
          <span style="padding-top: 13px;width: 100%;">{{ ( row[subBlock.name] && row[subBlock.name]['value'] ) ? row[subBlock.name]['value'] : '' }}</span>
        </td>
      </tr>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #commonTableBody let-i="rowIndex" let-row="templateContext">
  <td *ngIf="confChange.listName" class="angular-table-td"
      [ngClass]="confChange.align ? (confChange.align.includes('left') ? 'align-start' : (confChange.align.includes('right') ? 'align-end' : 'align-center')) : 'align-center'"
      style="border: 0;
            text-align: center;
            padding: 10px;"
      [style.width.%]="confChange.listWidthChange  ? 30 : 10">
    {{(this.globalConf.lovs[confChange.listName]).list[i].label[selectedLanguage]}}

    <ng-container *ngIf="isDialogOptionsEnabledForList((this.globalConf.lovs[confChange.listName]).list[i].value)">
        <span>
          <mat-icon (click)="openDialogForOptions((this.globalConf.lovs[confChange.listName]).list[i].value)"
                    style="cursor: pointer; vertical-align: middle !important; padding-left: 5px; !important;">help_outline
          </mat-icon>
        </span>
    </ng-container>
  </td>

  <ng-container *ngIf="!row.isTotal">
    <ng-container *ngFor="let subBlock of getFilteredSubBlocks()">
      <ng-container *ngIf="!subBlock.hideCell" [ngSwitch]="subBlock.templateName">
        <ng-container *ngSwitchCase="'statictext'">
          <ng-container
            *ngTemplateOutlet="statictext; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'textarea'">
          <ng-container
            *ngTemplateOutlet="textarea; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Radiobutton'">
          <ng-container
            *ngTemplateOutlet="Radiobutton; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'select'">
          <ng-container
            *ngTemplateOutlet="select; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Container'">
          <ng-container
            *ngTemplateOutlet="Container; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Table'">
          <ng-container
            *ngTemplateOutlet="Table; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'SubmitButton'">
          <ng-container
            *ngTemplateOutlet="SubmitButton; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'upload'">
          <ng-container
            *ngTemplateOutlet="upload; context: {templateContext : subBlock.templateContext , rowIndex : i, blockIndex : (blockIndex + ((i + 1) / 100))}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'text'">
          <ng-container
            *ngTemplateOutlet="text; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Checkbox'">
          <ng-container
            *ngTemplateOutlet="Checkbox; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'datepicker'">
          <ng-container
            *ngTemplateOutlet="datepicker; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'AutoComplete'">
          <ng-container
            *ngTemplateOutlet="AutoComplete; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'button'">
          <ng-container
            *ngTemplateOutlet="button; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'richtext'">
          <ng-container
            *ngTemplateOutlet="richtext; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- _BPH_T_SUBCONTENT_S --> <!-- _BPH_T_SUBCONTENT_E -->
  <ng-container *ngIf="row.isTotal">
    <ng-template [ngForOf]="confChange.subBlocks" let-p="index" let-subBlock ngFor>
      <ng-container *ngIf="subBlock.type!== 'text_dynamic_column' && !subBlock.hideCell">
        <td *ngIf="p>=row.position" [style.width.%]="(confChange.isWidthSpecifiedForSubBlocks && subBlock.columnWidthPercentage) ? subBlock.columnWidthPercentage : ''"
            class="angular-table-td"
            style="font-weight: bold !important">
          <ng-template [ngIf]="subBlock.hasGlobalTotal">
            {{getTotal(bphChange.rows, subBlock.name, i, row.column, row, subBlock.currency, subBlock.totalText, subBlock.type, subBlock)}}
          </ng-template>
        </td>
      </ng-container>
      <ng-container *ngIf="subBlock.type === 'text_dynamic_column' && !subBlock.hideCell">
        <ng-template [ngForOf]="subBlock.subBlocks" let-dynaIndex="index" let-subCell ngFor>
          <td *ngIf="p>=row.position" [style.width.%]="(confChange.isWidthSpecifiedForSubBlocks && subBlock.columnWidthPercentage) ? subBlock.columnWidthPercentage : ''"
              class="angular-table-td"
              style="font-weight: bold !important">
            <ng-container *ngIf="subBlock.hasGlobalTotal">
              {{getTotalDynamic(bphChange.rows, subBlock.name, i, dynaIndex, row, subBlock.currency, subBlock.totalText)}}
            </ng-container>
          </td>
        </ng-template>
      </ng-container>
    </ng-template>
  </ng-container>

  <td *ngIf="confChange.readOnlyTable && !row.isTotal && !confChange.hideEditButton" class="angular-table-td"
      style="text-align: center;">
    <button (click)="editRow(bphChange,confChange,i)"
            *ngIf="!row.rowEditButtonHide"
            [disabled]="confChange.isReadOnlyField || (bphChange.globalReadOnlyMode && bphChange.globalReadOnlyMode === 'true')"
            mat-icon-button>
      <mat-icon>create</mat-icon>
    </button>
  </td>
  <td *ngIf="confChange.showDelete && !row.isTotal " class="angular-table-td"
      style="text-align: center;">
    <!--*ngIf="!row.rowDeleteButtonHide"-->

    <button (click)="deleteRow(bphChange,confChange,i)"
            *ngIf="!row.rowDeleteButtonHide"
            [disabled]="confChange.isReadOnlyField || row.isDeleteDisabled || (bphChange.globalReadOnlyMode && bphChange.globalReadOnlyMode === 'true')"
            [style.opacity]="(confChange.isReadOnlyField || row.isDeleteDisabled || (bphChange.globalReadOnlyMode && bphChange.globalReadOnlyMode === 'true')) ? 0.5 : 1"
            mat-icon-button>
      <mat-icon>delete</mat-icon>
    </button>
  </td>
  <td *ngIf="row.isTotal && confChange.readOnlyTable" class="angular-table-td">
  </td>
  <td *ngIf="row.isTotal && confChange.showDelete" class="angular-table-td">
  </td>
</ng-template>


<ng-template #SubmitButton let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-submit-button
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj' [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    style='position: static;display: table-cell;vertical-align: middle;padding-right:20px;'></fb-submit-button>
</ng-template>
<ng-template #statictext let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-static-text
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-static-text>
</ng-template>
<ng-template #textarea let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-text-area
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-text-area>
</ng-template>
<ng-template #Radiobutton let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-radiobutton
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-radiobutton>
</ng-template>
<ng-template #select let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-select (emitOutputEvent)='(confChange.isEnableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
             *ngIf="!isValidTableReadonly"
             [bphChange]='((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name])'
             [confChange]='confChange'
             [globalConf]='globalConf'
             [globalPrj]='globalPrj'
             [isValidHtml]='isValidHtml'
             [isValidTableReadonly]='isValidTableReadonly'
             [isValidTable]='isValidTable'
             [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
             [selectedLanguage]='selectedLanguage'
             [selectedSectionId]='selectedSectionId'
             [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
             [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-select>


  <ng-container
    *eCaseVar="(((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]) ? ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]) : {}) as componentBphChange">
    <td *ngIf="isValidTableReadonly && !componentBphChange.hideCell && !confChange.hideCell"
        [attr.rowspan]="componentBphChange.rowspan"
        [ngClass]="(componentBphChange?.error && componentBphChange?.error[selectedLanguage]) ? 'hasError' : 'noError'"
        [style.border-right]="(componentBphChange.rowspan && componentBphChange.rowspan > 1) ? '1px solid rgba(0, 0, 0, .12)' : ''"
        [style.text-align]=" confChange.align ? confChange.align +'!important' : 'center !important'"
        [style.padding-left]=" confChange.align ? '' : '10px'"
        [style.font-weightr]="confChange.align  ? 'bold' : ''"
        [style.width.%]="confChange.isWidthCustom ? confChange.customWidthInPercentage : (100 / widthDividerForDynamicWidth)"
        style="border:0;text-align: center;padding-right:10px ;border-bottom: 1px solid rgba(0, 0, 0, 0.12); min-width: 80px;">
      <div class="field {{componentBphChange.error_class}} hide_{{bphChange.show}}">
        {{getValueForFbSelectSubBlock(componentBphChange, selectedLanguage)}}
        <span *ngIf="confChange.showOtherValueInReadOnlyTable">
<br>{{getOtherValueForFbSelectSubBlock(componentBphChange)}}
</span>
        <span *ngIf="(componentBphChange?.error && componentBphChange?.error[selectedLanguage])"
              class="tableFormFieldError">{{componentBphChange.error[selectedLanguage]}}</span>
      </div>
    </td>
  </ng-container>

  <!--<ng-container-->
  <!--*eCaseVar="(((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]) ? ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]) : {}) as componentBphChange">-->
  <!--<td *ngIf="isValidTableReadonly && !componentBphChange.hideCell && !confChange.hideCell"-->
  <!--[attr.rowspan]="componentBphChange.rowspan"-->
  <!--[ngClass]="(componentBphChange?.error && componentBphChange?.error[selectedLanguage]) ? 'hasError' : 'noError'"-->
  <!--[style.border-right]="(componentBphChange.rowspan && componentBphChange.rowspan > 1) ? '1px solid rgba(0, 0, 0, .12)' : ''"-->
  <!--[style.width.%]="confChange.isWidthCustom ? confChange.customWidthInPercentage : (100 / widthDividerForDynamicWidth)"-->
  <!--style="border:0;text-align: center;padding-left:10px;padding-right:10px">-->
  <!--<div class="field {{componentBphChange.error_class}} hide_{{bphChange.show}}">-->
  <!--{{getValueForFbSelectSubBlock(componentBphChange, selectedLanguage)}}-->
  <!--<span *ngIf="(componentBphChange?.error && componentBphChange?.error[selectedLanguage])"-->
  <!--class="tableFormFieldError">{{componentBphChange.error[selectedLanguage]}}</span>-->
  <!--</div>-->
  <!--</td>-->
  <!--</ng-container>-->
</ng-template>

<ng-template #text let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-text
    (keydown.enter)='handleEnterKey($event, confChange)'
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    [style.background-color]="confChange.backgroundColor ? confChange.backgroundColor : ''"
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'"
    [style.background-color]="confChange.backgroundColor ? confChange.backgroundColor : ''">
  </fb-text>
</ng-template>
<ng-template #upload let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-upload
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [offlineModeEnabled]='offlineModeEnabled'
    [selectedLanguage]='selectedLanguage'
    [blockIndex]='blockIndex'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-upload>
</ng-template>
<ng-template #button let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-button
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [sections]="sections"
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [offlineModeEnabled]='offlineModeEnabled'
    [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
    [selectedLanguage]='selectedLanguage'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-button>
</ng-template>
<ng-template #Checkbox let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-checkbox
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-checkbox>
</ng-template>
<ng-template #datepicker let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-datepicker
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-datepicker>
</ng-template>
<ng-template #AutoComplete let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-autoComplete
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-autoComplete>
</ng-template>
<ng-template #richtext let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-rich-text-quill
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-rich-text-quill>
</ng-template>
