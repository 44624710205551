import moment, {Moment} from 'moment';
import {NgxMatDateAdapter} from '@angular-material-components/datetime-picker';
import {Injectable} from '@angular/core';
import {ParametersService} from 'synto-common';

/**
 * Created by sahilb@evision.ca on 2021-09-09.
 */
@Injectable()
export class NotificationSenderNgxMatDateAdapter extends NgxMatDateAdapter<Moment> {

  constructor(private parametersService: ParametersService) {
    super();
  }

  addCalendarDays(_moment: Moment, days: number): Moment {
    return moment(_moment).add(days, 'days');
  }

  addCalendarMonths(_moment: Moment, months: number): Moment {
    return moment(_moment).add(months, 'months');
  }

  addCalendarYears(_moment: Moment, years: number): Moment {
    return moment(_moment).add(years, 'years');
  }

  clone(_moment: Moment): Moment {
    return moment(_moment).clone();
  }

  createDate(year: number, month: number, date: number): Moment {
    return moment({year: year, month: month, day: date});
  }

  format(date: Moment, displayFormat: any): string {
    if (displayFormat && (displayFormat.toString().includes(':ss') || displayFormat.toString().includes(':SS'))) {
      displayFormat = this.parametersService.parameter['/core/ui/default_date_time_with_timezone/format'].replace(':ss', '').replace(':SS', '');
    }
    if (date) {
      return displayFormat ? moment(date).tz(moment.tz.guess()).format(displayFormat) : moment(date).tz(moment.tz.guess()).format();
    } else {
      return '';
    }
  }

  getDate(date: Moment): number {
    return moment(date).date();
  }

  getDateNames(): string[] {
    return Array.from(Array(moment().daysInMonth()).keys()).map(e => (e + 1).toString(10));
  }

  getDayOfWeek(date: Moment): number {
    return date.isoWeekday();
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    switch (style) {
      case 'long':
        return moment.weekdays();
      case 'short':
        return moment.weekdaysShort();
      case 'narrow':
        return moment.weekdaysMin();
    }
  }

  getFirstDayOfWeek(): number {
    return moment().localeData().firstDayOfWeek();
  }

  getHour(date: Moment): number {
    return date ? moment(date).hour() : 0;
  }

  getMinute(date: Moment): number {
    return date ? moment(date).minute() : 0;
  }

  getMonth(date: Moment): number {
    return date ? moment(date).month() : 0;
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return moment.monthsShort(style);
  }

  getNumDaysInMonth(date: Moment): number {
    return moment(date).daysInMonth();
  }

  getSecond(date: Moment): number {
    return moment(date).second();
  }

  getYear(date: Moment): number {
    return moment(date).year();
  }

  getYearName(date: Moment): string {
    return moment(date).year().toString(10);
  }

  invalid(): Moment {
    return moment();
  }

  isDateInstance(obj: any): boolean {
    return moment(obj).isValid();
  }

  isValid(date: Moment): boolean {
    return moment(date).isValid();
  }

  parse(value: any, parseFormat: any): Moment | null {
    if (value) {
      return (parseFormat ? moment(value).tz(moment.tz.guess()) : moment(value).tz(moment.tz.guess()));
    } else {
      return null;
    }
  }

  setHour(date: Moment, value: number): void {
    if (date && !(date instanceof moment)) {
      moment(date).set({hour: value});
    } else {
      date.set({hour: value});
    }
  }

  setMinute(date: Moment, value: number): void {
    if (date && !(date instanceof moment)) {
      moment(date).set({minute: value});
    } else {
      date.set({minute: value});
    }
  }

  setSecond(date: Moment, value: number): void {
    if (date && !(date instanceof moment)) {
      moment(date).set({second: value});
    } else {
      date.set({second: value});
    }
  }

  toIso8601(date: Moment): string {
    return date.toISOString();
  }

  today(): Moment {
    return moment();
  }

}
