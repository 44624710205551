import {NgModule} from '@angular/core';
import {SharedModule} from 'synto-common';
import {ECaseWidgetComponent} from './widget.component';
import {ECaseWidgetToggleDirective} from './widget-toggle.directive';


@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    ECaseWidgetComponent,
    ECaseWidgetToggleDirective,
  ],
  declarations: [
    ECaseWidgetComponent,
    ECaseWidgetToggleDirective,
  ]
})
export class ECaseWidgetModule {
}
