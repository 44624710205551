import {Component, HostBinding, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {Platform} from '@angular/cdk/platform';
import {DOCUMENT} from '@angular/common';
import {
    eCaseAnimations,
    ECaseConfigService,
    EcaseHttpService, ECaseSnackBarService, ECaseUtils, ECaseUtilsGlobal, EligibilityService,
    ParametersService, TitleService
} from 'synto-common';

const ERROR_MESSAGE = 'ecase.reference.error.msg';
const SUCCESS_MESSAGE = 'ecase.reference.success.msg';

@Component({
    selector: 'ECase-external-referee',
    templateUrl: './external-referee.component.html',
    styleUrls: ['./external-referee.component.scss'],
    animations: eCaseAnimations
})
export class ECaseExternalRefereeComponent implements OnInit {
    @HostBinding('attr.eCase-layout-mode') layoutMode;
    token: string;
    lang: string;
    logoSrc = '';
    externalRefereeForm: FormGroup;
    supportedExtensions = ECaseUtilsGlobal.FILE_UPLOADER_SUPPORTED_EXTENSIONS;
    documentSelected=[];
    addNewDocumentForm: FormGroup;
    edmRepositoryDocumentId: number;
    isFormInfoLoaded = false;
    checkStudentEmail = '';
    constructor(private eCaseConfig: ECaseConfigService, private formBuilder: FormBuilder, public translate: TranslateService,
                private eCaseHttpService: EcaseHttpService,
                private eCaseSnackBarService: ECaseSnackBarService, private titleService: TitleService,
                public router: Router, private parametersService: ParametersService,
                private activatedRoute: ActivatedRoute, private eligibilityService: EligibilityService,
                @Inject(DOCUMENT) private document: Document, public platform: Platform) {

      this.lang = this.activatedRoute.snapshot.paramMap.get('lang');
      this.token = this.activatedRoute.snapshot.paramMap.get('token');
    }


    ngOnInit(): void {
      const obj = {
        'token': this.token
      };
      this.eCaseHttpService.post('/api/getRefereeInfo', obj).subscribe((response: any) => {
          if (response.status) {
            console.log('response', response);
            this.externalRefereeForm = this.formBuilder.group({
              name: {value: response.result.piName, disabled: true},
              email: {value: response.result.piEmail, disabled: true},
              caseApplicationId: {value: response.result.caseApplicationId, disabled: true},
              awardName: {value: response.result.programName[this.lang], disabled: true},
              uploadFile: ['', Validators.required],
              checkEmail: ['', Validators.required]
            });
            this.edmRepositoryDocumentId = response.result.edmDocumentRepositoryId;
            this.checkStudentEmail = response.result.studentEmail;
            this.isFormInfoLoaded = true;
          } else {
            this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, ERROR_MESSAGE));
            this.router.navigate(['/']);
          }
        },
        (error: any) => {
          this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate,ERROR_MESSAGE));
          this.router.navigate(['/']);
        }
      );
      setTimeout(() => {
        this.translate.use(this.lang);
      }, 50);

    }

    downloadFile(): void {
        const obj = {
            observe: 'response',
            responseType: 'blob'
        };
        this.eCaseHttpService.get('/api/downloadReferenceDoc/' + this.edmRepositoryDocumentId, obj).subscribe((blob: any) => {});
    }

    onFileInput(event): void {
        console.log(event);

        for (const file of event.target.files) {
            const reducer = (accumulator, currentValue): any => !accumulator ? (accumulator || file.name.endsWith(currentValue)) : accumulator;

            if (!this.supportedExtensions.reduce(reducer, false)) {
                alert(ECaseUtils.getTranslatedValueFromKey(this.translate, 'invalidTypeFile'));
                break;
            } else if (file.size > (25 * 1048576)) {
                alert(ECaseUtils.getTranslatedValueFromKey(this.translate, 'invalidSizeFile'));
                break;
            } else {
                this.documentSelected = event.target.files;
            }
        }
    }

  areStringsEqual(str1: string, str2: string): boolean {
    if (!str1 || !str2) {
      return false;
    }
    return str1.trim().toLowerCase() === str2.trim().toLowerCase();
  }

    onSubmit(): void {
        if (this.areStringsEqual(this.externalRefereeForm.value.checkEmail, this.checkStudentEmail)) {
            const formData = new FormData();
            const obj = {
                'token': this.token,
                'caseApplicationId': this.externalRefereeForm.controls.caseApplicationId.value,
                'email': this.checkStudentEmail
            };
            for (const file of this.documentSelected) {
                formData.append(file.name, file);
            }
            formData.append('referenceDetails', JSON.stringify(obj));
            this.eCaseHttpService.post('/api/submitReferenceDoc', formData).subscribe((response: any) => {
                if (response.hasOwnProperty('status') && response.message === 'Success') {
                    this.eCaseSnackBarService.show('success', ECaseUtils.getTranslatedValueFromKey(this.translate, SUCCESS_MESSAGE));
                    this.router.navigate(['/']);
                } else {
                    this.eCaseSnackBarService.show('failure',  ECaseUtils.getTranslatedValueFromKey(this.translate, ERROR_MESSAGE));
                }
            });
        } else {
            this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, ERROR_MESSAGE));
        }

    }

}
