import {NgModule} from '@angular/core';
import {ComponentModule, SharedModule} from "synto-common";
import {
  MultiValuedVariablesSelectDialog
} from "./multi-valued-variables-select-dialog/multi-valued-variables-select-dialog";
import {NotificationSenderComponent} from "./notification-sender/notification-sender.component";
import {DocumentListingComponent} from "./document-listing/document-listing.component";
import {TreeTableComponent} from "./treetable/treetable.component";
import {DeleteConfirmDialogComponent} from "./delete-confirm-dialog/delete-confirm-dialog.component";
import {BpmnViewerComponent} from "./bpmn-viewer/bpmn-viewer.component";
import {CodeEditorComponent} from "./code-editor/code-editor.component";
import {EcasePhoneNumberFieldComponent} from "./ecase-phone-number-field/ecase-phone-number-field.component";
import {
  EcaseMultiSelectAutocompleteFieldComponent
} from "./ecase-multi-select-autocomplete-field/ecase-multi-select-autocomplete-field.component";
import {ECaseThemeOptionsComponent} from "./theme-options/theme-options.component";
import {ECaseSearchBarModule} from "./search-bar/search-bar.module";
import {ECaseWidgetModule} from "./widget/widget.module";
import {ECaseCalendarModule} from "./calendar/calendar.module";
import {ECaseShortcutsModule} from "./shortcuts/shortcuts.module";
import {ECaseMaterialColorPickerComponent} from "./material-color-picker/material-color-picker.component";
import {TreeNodeComponent} from "./tree-node/tree-node.component";
import {ECaseHighlightComponent} from "./highlight/highlight.component";
import {ECaseCountdownComponent} from "./countdown/countdown.component";
import {BreadcrumbComponent} from "./breadcrumb/breadcrumb.component";
import {CrmPersonSearchDialogComponent} from "./crm-person-search-dialog/crm-person-search-dialog.component";
import {CrmPersonSearchComponent} from "./crm-person-search/crm-person-search.component";
import {RouterModule} from "@angular/router";
import { AddViewSignatureStatusDialogComponent } from './add-view-signature-status-dialog/add-view-signature-status-dialog.component';
import { AddNewSignerDialogComponent } from './add-view-signature-status-dialog/add-new-signer-dialog/add-new-signer-dialog.component';
import {AddNewDocumentDialogComponent} from "./document-listing/add-new-document-dialog/add-new-document-dialog";
import { EcaseFileRenamerComponent } from './ecase-file-renamer/ecase-file-renamer.component';
import {
  EcaseDynamicMultiSelectAutocompleteFieldComponent
} from "./ecase-dynamic-multi-select-autocomplete-field/ecase-dynamic-multi-select-autocomplete-field.component";

@NgModule({
  declarations: [MultiValuedVariablesSelectDialog,
    DeleteConfirmDialogComponent,
    CrmPersonSearchDialogComponent,
    CrmPersonSearchComponent,
    TreeTableComponent,
    DocumentListingComponent,
    NotificationSenderComponent,
    BpmnViewerComponent,
    CodeEditorComponent,
    EcasePhoneNumberFieldComponent,
    EcaseMultiSelectAutocompleteFieldComponent,
    EcaseDynamicMultiSelectAutocompleteFieldComponent,
    ECaseThemeOptionsComponent,
    ECaseCountdownComponent,
    ECaseHighlightComponent,
    TreeNodeComponent,
    ECaseMaterialColorPickerComponent,
    BreadcrumbComponent,
    AddViewSignatureStatusDialogComponent,
    AddNewSignerDialogComponent,
    AddNewDocumentDialogComponent,
    EcaseFileRenamerComponent
  ],
  imports: [SharedModule, ComponentModule, ECaseShortcutsModule,
    ECaseCalendarModule,
    ECaseWidgetModule,
    ECaseSearchBarModule, RouterModule],
  exports: [MultiValuedVariablesSelectDialog,
    DeleteConfirmDialogComponent,
    CrmPersonSearchDialogComponent,
    CrmPersonSearchComponent,
    TreeTableComponent,
    DocumentListingComponent,
    NotificationSenderComponent,
    BpmnViewerComponent,
    CodeEditorComponent,
    EcasePhoneNumberFieldComponent,
    EcaseMultiSelectAutocompleteFieldComponent,
    EcaseDynamicMultiSelectAutocompleteFieldComponent,
    ECaseThemeOptionsComponent,
    ECaseCountdownComponent,
    ECaseHighlightComponent,
    BreadcrumbComponent,
    TreeNodeComponent,
    ECaseMaterialColorPickerComponent,
    ECaseShortcutsModule,
    ECaseCalendarModule,
    ECaseWidgetModule,
    EcaseFileRenamerComponent,
    ECaseSearchBarModule]
})
export class SyntoCoreModule {
}
