import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import Quill from 'quill';
import {TranslateService} from '@ngx-translate/core';
import {ECaseUtils, PendingChangesGuard, RefreshDataService} from 'synto-common';


@Component({
  selector: 'fb-rich-text-quill',
  templateUrl: './fb-rich-text-quill.component.html',
  styleUrls: ['./fb-rich-text-quill.component.css']
})
export class FbRichTextQuillComponent implements OnInit, AfterViewInit {
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() globalPrj: any;
  @Input() selectedLanguage;
  @Input() selectedSectionId: string;
  @Input() rowNumber;
  modules = {};
  row: any;
  public editor;
  id;
  height = 154;

  constructor(private translate: TranslateService,private pendingChangesGuard: PendingChangesGuard, private refreshDataService: RefreshDataService) {
    this.confChange = {};
    this.bphChange = {};
    this.bphChange.value = '';
    this.confChange.label = {};
    this.bphChange.error = {};
    this.bphChange.source = {};
    this.row = {};
  }

  saveChanges($event: any) {
    if ($event.html || $event.html === null) {
      this.bphChange.value = $event.html === null ? '' : $event.html;
      this.pendingChangesGuard.isPristine = false;
      // this.changeValueEvent();
    }
  }

  ngOnInit() {
    this.id = this.selectedSectionId + '_' + this.confChange.name;
   /* this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;
    });*/
    const Font = Quill.import('formats/font');
    Font.whitelist = ['mirza', 'aref', 'sans-serif', 'monospace', 'serif'];
    Quill.register(Font, true);
    if (this.bphChange.isAdvanceOptionEnabled) {
      this.modules = {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],
          ['link', 'image', 'video']
        ]
      };
    } else {
      this.modules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          ['image'],
          [{ 'align': [] }]
        ]
      };
    }
    this.bphChange.getValue = function() {
      return this.bphChange.value;
    };
  }

  onEditorCreated(quill) {
    this.editor = quill;
  }

  onEditorChange(changedQuill) {
    this.editor = changedQuill;
  }

  changeValueEvent() {

    this.refreshDataService.setNewValueForStaticText(this.confChange.name, this.bphChange.value);
    const testVariable: any = window;
    testVariable.isFormModified = ECaseUtils.isGlobalPrjPristine(this.globalPrj, testVariable.pristinePrj);
  }

  focusInFunction() {
    const element = document.getElementById(this.id + '_quill_editor');
    if (this.bphChange.focusInHeight && this.bphChange.focusInHeight !== this.height) {
    } else {
      this.bphChange.focusInHeight = this.height;
    }
    if (element) {
      element.style.height = this.bphChange.focusInHeight.toString() + 'px';
    }
  }

  focusOutFunction() {
    const element = document.getElementById(this.id + '_quill_editor');
    if (element) {
      this.bphChange.focusInHeight = element.offsetHeight;
      element.style.height = this.height.toString() + 'px';
    }
  }

  ngAfterViewInit(): void {
    const size = document.getElementById(this.id).getElementsByClassName('ql-editor').length;
    for (let i = 0; i < size; i++) {
      document.getElementById(this.id).getElementsByClassName('ql-editor').item(i).setAttribute('style', 'background: white;\n' +
        '  overflow: hidden;\n' +
        '  resize: vertical;\n' +
        '  height: 120px;\n' +
        '  min-width: 800px;\n' +
        '  min-height: 90px;\n' +
        '  max-height: 300px;');
      document.getElementById(this.id).getElementsByClassName('ql-editor').item(i).setAttribute('id', this.id + '_quill_editor');

    }
  }


}

