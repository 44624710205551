<div *ngIf="!bphChange.hide && bphChange.globalShowDetail" [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'">
  <ng-container *ngIf="!showEditManpowerTable && !showSecondTable">
    <table class="angular-table"
           *ngFor="let table of tables; let i = index;">
      <ng-container *ngIf="false">
        <!--<thead class="angular-table-thead">-->
        <!--<tr class="angular-table-tr">-->
        <!--<th></th>-->
        <!--<th class="angular-table-th">{{confChange.categoryLabel[selectedLanguage]}}</th>-->
        <!--<th class="angular-table-th"><span style="color: red">* </span><span>{{confChange.subCategoryLabel[selectedLanguage]}}</span> </th>-->
        <!--<th class="angular-table-th">Qualification</th>-->
        <!--<th class="angular-table-th">First Name</th>-->
        <!--<th class="angular-table-th" >Last Name</th>-->
        <!--<th class="angular-table-th" ><span style="color: red">* </span><span>{{confChange.subtotalLabel[selectedLanguage]}}</span></th>-->
        <!--<th class="angular-table-th" ><span style="color: red">* </span><span>{{confChange.fundsFromKfasLabel[selectedLanguage]}}</span></th>-->
        <!--<th class="angular-table-th" ><span style="color: red">* </span><span>{{confChange.fundsFromOtherInstitutionLabel[selectedLanguage]}}</span></th>-->
        <!--<th class="angular-table-th" >-->
        <!--<span>Justification </span>-->
        <!--<span><mat-icon style="cursor: pointer; font-size: 21px;"-->
        <!--(click)="openDialog('<h3>Expense Justification</h3>')">help_outline-->
        <!--</mat-icon></span>-->
        <!--</th>-->
        <!--<th class="angular-table-th">{{'edit' | translate}}</th>-->
        <!--</tr>-->
        <!--</thead>-->
        <ng-container *ngFor="let row of table.rows; let rowIndex = index;">
          <tr class="angular-table-tr">

            <td class="angular-table-td">
              <mat-icon style="cursor: pointer; margin-right: 5px;" (click)="showTableRow(row, rowIndex)">
                {{row.isShowTableRow ? 'remove_circle_outline' : 'add_circle_outline'}}
              </mat-icon>
            </td>

            <td class="angular-table-td" *ngIf="rowIndex === 0 && table.isManPowerTable">
              <span>{{confChange.mainCategoryName[0].label[selectedLanguage]}}</span>
            </td>

            <td class="angular-table-td" *ngIf="rowIndex !== 0 && table.isManPowerTable">
            </td>


            <ng-container *ngIf="table.isManPowerTable">

              <td class="angular-table-td wrap-table-text">
                <span *ngIf="row.subCategoryValue.label">{{row.subCategoryValue.label[selectedLanguage]}}</span>
              </td>
              <td class="angular-table-td wrap-table-text">
                <span *ngIf="row.qualificationValue.label">{{row.qualificationValue.label[selectedLanguage]}}</span>
              </td>
              <td class="angular-table-td wrap-table-text">
                <span>{{row.firstName}}</span>
              </td>
              <td class="angular-table-td wrap-table-text">

                <span>{{row.lastName}}</span>
              </td>
            </ng-container>


            <ng-container *ngIf="row.category || table.hasOptionSelected">
              <td class="angular-table-td">
                <span>{{row.grandSubTotal}}</span>
                <div *ngIf="row?.error && row?.error[selectedLanguage]" class="formFieldError">
                  {{row?.error[selectedLanguage]}}
                </div>
              </td>
              <td class="angular-table-td">

                <span>{{row.grandTotalForKfasFunds}}</span>
                <div *ngIf="row?.error && row?.error[selectedLanguage]" class="formFieldError">
                  {{row?.error[selectedLanguage]}}
                </div>
              </td>
              <td class="angular-table-td">

                <span>{{row.grandTotalForOtherFunds}}</span>
                <div *ngIf="row?.error && row?.error[selectedLanguage]" class="formFieldError">
                  {{row?.error[selectedLanguage]}}
                </div>
              </td>

              <td class="angular-table-td wrap-table-text">

                <div style="overflow: auto; max-height: 120px;">{{row.comments}}</div>
              </td>

              <td class="angular-table-td">
                <mat-icon (click)="onEditManpowerTableRow(row, rowIndex, table)" style="cursor: pointer;">create
                </mat-icon>
              </td>

            </ng-container>
          </tr>
          <ng-container *ngIf="row.isShowTableRow">
            <tr class="angular-table-tr" *ngFor="let expandableRows of row.expandRows">

              <td class="angular-table-td" style="background-color: lightgrey;"></td>
              <td class="angular-table-td" style="background-color: lightgrey;"></td>
              <td class="angular-table-td" style="background-color: lightgrey;"></td>
              <td class="angular-table-td" style="background-color: lightgrey;"></td>
              <td class="angular-table-td" style="background-color: lightgrey;"></td>
              <td class="angular-table-td" style="background-color: lightgrey;"></td>

              <!--<ng-container>-->
              <!--<td class="angular-table-td"></td>-->
              <!--<td class="angular-table-td" style="text-align: left;">-->
              <!--{{expandableRows.label}}-->
              <!--</td>-->
              <!--</ng-container>-->

              <td class="angular-table-td">
                <div>
                  <span>{{convertNumberToCurrencyFormat(expandableRows.subTotal, confChange.currency)}}</span>
                  <div *ngIf="expandableRows?.subTotalError && expandableRows?.subTotalError.error[selectedLanguage]"
                       class="formFieldError">{{expandableRows?.subTotalError.error[selectedLanguage]}}
                  </div>
                </div>
              </td>

              <td class="angular-table-td">
                <div>
                  <span>{{convertNumberToCurrencyFormat(expandableRows.fundsFromKfas, confChange.currency)}}</span>
                  <div
                    *ngIf="expandableRows?.fundsFromKfasError && expandableRows?.fundsFromKfasError.error[selectedLanguage]"
                    class="formFieldError">{{expandableRows?.fundsFromKfasError.error[selectedLanguage]}}
                  </div>
                </div>
              </td>

              <td class="angular-table-td">
                <div>
                  <span>{{convertNumberToCurrencyFormat(expandableRows.fundsFromOtherInstitution, confChange.currency)}}</span>
                  <div
                    *ngIf="expandableRows?.fundsFromOtherInstitutionError && expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage]"
                    class="formFieldError">
                    {{expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage]}}
                  </div>
                </div>
              </td>

              <td class="angular-table-td" style=" background-color: lightgrey;"></td>
              <td class="angular-table-td" style=" background-color: lightgrey;"></td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </table>
    <ng-container *ngIf="!showSummaryTable && refreshDataService.showSecondTable">
      <table class="angular-table"
             *ngFor="let table of tables; let i = index;">
        <ng-container *ngIf="!table.isManPowerTable">
          <thead class="angular-table-thead">
          <tr class="angular-table-tr">
            <ng-container>
              <th></th>
              <th class="angular-table-th"><span style="color: red">*</span>
                {{confChange.categoryLabel[selectedLanguage]}}
              </th>
              <th class="angular-table-th"><span style="color: red">*</span>
                {{confChange.subCategoryLabel[selectedLanguage]}}

                <mat-icon *ngIf="confChange.hasDialogForSubCategory"
                          style="cursor: pointer; font-size: 21px;"
                          (click)="openDialog(confChange.dialogForSubCategory)">help_outline
                </mat-icon>

              </th>

              <th class="angular-table-th"><span style="color: red">*</span>
                {{confChange.descriptionLabel[selectedLanguage]}}
              </th>
              <th class="angular-table-th"><span style="color: red">*</span>
                {{confChange.subtotalLabel[selectedLanguage]}}
              </th>
              <th class="angular-table-th"><span style="color: red">*</span>
                {{confChange.fundsFromKfasLabel[selectedLanguage]}}
              </th>
              <th class="angular-table-th"><span style="color: red">*</span>
                {{confChange.fundsFromOtherInstitutionLabel[selectedLanguage]}}
              </th>
              <th class="angular-table-th">{{confChange.commentsLabel[selectedLanguage]}}</th>
              <th class="angular-table-th">{{confChange.documentaionLabel[selectedLanguage]}}</th>
              <th *ngIf="!table.isManPowerTable" class="angular-table-th">
                {{confChange.editLabel ? confChange.editLabel[selectedLanguage] : '' }}
              </th>
              <th *ngIf="!table.isManPowerTable" class="angular-table-th">
                {{confChange.deleteLabel ? confChange.deleteLabel[selectedLanguage]: '' }}
              </th>
            </ng-container>

          </tr>
          </thead>
          <ng-container *ngFor="let row of table.rows; let rowIndex = index;">
            <tr class="angular-table-tr">

              <td class="angular-table-td">
                <mat-icon style="cursor: pointer; margin-right: 5px;" (click)="showTableRow(row, rowIndex)">
                  {{row.isShowTableRow ? 'remove_circle_outline' : 'add_circle_outline'}}
                </mat-icon>
              </td>

              <td class="angular-table-td wrap-table-text" *ngIf="!table.isManPowerTable" width="10%">
                <span>{{row.category.label[selectedLanguage]}}</span>
                <div
                  [ngClass]="row?.categoryError && row?.categoryError.error[selectedLanguage] ? 'hasError' : 'noError'">
                   <span *ngIf="row?.categoryError && row?.categoryError.error[selectedLanguage]"
                         class="formFieldError">{{row?.categoryError.error[selectedLanguage]}}</span>
                </div>
              </td>


              <ng-container *ngIf="row.category || table.hasOptionSelected">
                <td class="angular-table-td wrap-table-text" *ngIf="!table.isManPowerTable">
                  <span>{{row?.subCategory?.label ? row?.subCategory?.label[selectedLanguage]: ''}}</span>
                  <div
                    [ngClass]="row?.subCategoryError && row?.subCategoryError.error[selectedLanguage] ? 'hasError' : 'noError'">
                  <span *ngIf="row?.subCategoryError && row?.subCategoryError.error[selectedLanguage]"
                        class="formFieldError">{{row?.subCategoryError.error[selectedLanguage]}}</span>
                  </div>
                </td>

                <td class="angular-table-td wrap-table-text" *ngIf="!table.isManPowerTable">
                  <div style="overflow: auto; max-height: 120px;">{{row.description}}</div>
                  <div
                    [ngClass]="row?.descriptionError && row?.descriptionError.error[selectedLanguage] ? 'hasError' : 'noError'">
                  <span *ngIf="row?.descriptionError && row?.descriptionError.error[selectedLanguage]"
                        class="formFieldError">{{row?.descriptionError.error[selectedLanguage]}}</span>
                  </div>
                </td>
                <td class="angular-table-td">
                  <span> {{convertNumberToCurrencyFormat(row.grandSubTotal, confChange.currency)}}</span>
                  <div
                    [ngClass]="getYearErrorMessages(rowIndex,'subTotalError') && getYearErrorMessages(rowIndex,'subTotalError')[selectedLanguage] ? 'hasError' : 'noError'">
                  <span
                    *ngIf="getYearErrorMessages(rowIndex,'subTotalError') && getYearErrorMessages(rowIndex,'subTotalError')[selectedLanguage]"
                    class="formFieldError">
                    {{getYearErrorMessages(rowIndex,'subTotalError')[selectedLanguage]}}
                  </span>
                  </div>
                </td>
                <td class="angular-table-td">
                  <span>{{convertNumberToCurrencyFormat(row.grandTotalForKfasFunds, confChange.currency)}}</span>
                  <div
                    [ngClass]="getYearErrorMessages(rowIndex,'fundsFromKfasError') && getYearErrorMessages(rowIndex,'fundsFromKfasError')[selectedLanguage] ? 'hasError' : 'noError'">
                  <span
                    *ngIf="getYearErrorMessages(rowIndex,'fundsFromKfasError') && getYearErrorMessages(rowIndex,'fundsFromKfasError')[selectedLanguage]"
                    class="formFieldError">
                    {{getYearErrorMessages(rowIndex,'fundsFromKfasError')[selectedLanguage]}}
                  </span>
                  </div>
                </td>
                <td class="angular-table-td">
                  <span>{{convertNumberToCurrencyFormat(row.grandTotalForOtherFunds, confChange.currency)}}</span>
                  <div
                    [ngClass]="getYearErrorMessages(rowIndex,'fundsFromOtherInstitutionError') && getYearErrorMessages(rowIndex,'fundsFromOtherInstitutionError')[selectedLanguage] ? 'hasError' : 'noError'">
                  <span
                    *ngIf="getYearErrorMessages(rowIndex,'fundsFromOtherInstitutionError') && getYearErrorMessages(rowIndex,'fundsFromOtherInstitutionError')[selectedLanguage]"
                    class="formFieldError">
                    {{getYearErrorMessages(rowIndex,'fundsFromOtherInstitutionError')[selectedLanguage]}}
                  </span>
                  </div>
                </td>

                <ng-container>
                  <td class="angular-table-td wrap-table-text">
                    <div style="overflow: auto; max-height: 120px;">{{row.comments}}</div>
                  </td>
                </ng-container>

                <ng-container *ngIf="!table.isManPowerTable">
                  <td class="angular-table-td" style="font-weight: 500 !important;">
                <span *ngIf="row.uploadFile?.length > 0" style="font-size: 1.1em">
                  <a href="/api/viewAttachedRepositoryDocument/{{row.uploadFile[0].id}}" target="_blank">{{row.uploadFile[0].fileName}}
                  </a>
                </span>

                  </td>
                </ng-container>

<!--                <td class="angular-table-td" *ngIf="!table.isManPowerTable">-->
<!--                  <button mat-icon-button (click)="menuTrigger.openMenu();$event.stopPropagation()"-->
<!--                          #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" class="mat-icon-button">-->
<!--                    <mat-icon>more_vert</mat-icon>-->
<!--                  </button>-->
<!--                  <mat-menu #menu="matMenu">-->
<!--                    <button mat-menu-item (click)="onEditTable(row, rowIndex)">-->
<!--                      {{'edit' | translate}}-->
<!--                    </button>-->
<!--                    <button mat-menu-item (click)="onDeleteTable(row, rowIndex)">-->
<!--                      {{'ecase.admindashboard.study.deleteAction' | translate}}-->
<!--                    </button>-->
<!--                  </mat-menu>-->
<!--                </td>-->
                <td *ngIf="!table.isManPowerTable" class="angular-table-td"
                    style="text-align: center;">
                  <button (click)="onEditTable(row, rowIndex)"
                          mat-icon-button>
                    <mat-icon>create</mat-icon>
                  </button>
                </td>
                <td *ngIf="!table.isManPowerTable" class="angular-table-td"
                    style="text-align: center;">
                  <button (click)="onDeleteTable(row, rowIndex)"
                          mat-icon-button>
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </ng-container>
            </tr>
            <ng-container *ngIf="row.isShowTableRow">
              <tr class="angular-table-tr" *ngFor="let expandableRows of row.expandRows">

                <td class="angular-table-td" style="background-color: lightgrey;"></td>
                <td class="angular-table-td" style="background-color: lightgrey;"></td>
                <td class="angular-table-td" style="background-color: lightgrey;"></td>
                <td class="angular-table-td" style="background-color: lightgrey;"></td>


                <!--<ng-container>-->
                <!--<td class="angular-table-td"></td>-->
                <!--<td class="angular-table-td" style="text-align: left;">-->
                <!--{{expandableRows.label}}-->
                <!--</td>-->
                <!--</ng-container>-->

                <td class="angular-table-td">
                  <div>
                    <span>{{convertNumberToCurrencyFormat(expandableRows.subTotal, confChange.currency)}}</span>
                    <div
                      [ngClass]="expandableRows?.subTotalError && expandableRows?.subTotalError.error && expandableRows?.subTotalError.error[selectedLanguage] ? 'hasError' : 'noError'">
                    <span *ngIf="expandableRows?.subTotalError && expandableRows?.subTotalError.error[selectedLanguage]"
                          class="formFieldError">{{expandableRows?.subTotalError.error[selectedLanguage]}}
                    </span>
                    </div>
                  </div>
                </td>

                <td class="angular-table-td">
                  <div>
                    <span>{{convertNumberToCurrencyFormat(expandableRows.fundsFromKfas, confChange.currency)}}</span>
                    <div
                      [ngClass]="expandableRows?.fundsFromKfasError && expandableRows?.fundsFromKfasError.error && expandableRows?.fundsFromKfasError.error[selectedLanguage] ? 'hasError' : 'noError'">
                    <span
                      *ngIf="expandableRows?.fundsFromKfasError && expandableRows?.fundsFromKfasError.error[selectedLanguage]"
                      class="formFieldError">{{expandableRows?.fundsFromKfasError.error[selectedLanguage]}}
                    </span>
                    </div>
                  </div>
                </td>

                <td class="angular-table-td">
                  <div>
                    <span>{{convertNumberToCurrencyFormat(expandableRows.fundsFromOtherInstitution, confChange.currency)}}</span>
                    <div
                      [ngClass]="expandableRows?.fundsFromOtherInstitutionError && expandableRows?.fundsFromOtherInstitutionError.error && expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage] ? 'hasError' : 'noError'">
                    <span
                      *ngIf="expandableRows?.fundsFromOtherInstitutionError && expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage]"
                      class="formFieldError">
                      {{expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage]}}
                    </span>
                    </div>
                  </div>
                </td>

                <td class="angular-table-td" style=" background-color: lightgrey;"></td>
                <td class="angular-table-td" style=" background-color: lightgrey;"></td>
                <td class="angular-table-td" style=" background-color: lightgrey;"></td>

              </tr>
            </ng-container>

          </ng-container>
        </ng-container>
      </table>

      <button id = 'budget_t1_addBtn' mat-raised-button color="accent" style="margin-top: 20px;margin-bottom: 5px;" (click)="onAddTable()">
        {{ getTranslatedLabel("add")}}
      </button>
    </ng-container>
    <div *ngIf="showSummaryTable" class="readOnlyTable">
      <table *ngIf="bphChange.numberOfBudgetTables[0].rows.length > 0 || bphChange.numberOfBudgetTables[1].rows.length > 0 || bphChange.numberOfBudgetTables[2].rows.length > 0" class="angular-table">
        <tr class="angular-table-tr">
          <th class="angular-table-th">{{confChange.categoryLabel[selectedLanguage]}}</th>
          <th class="angular-table-th">{{confChange.subCategoryLabel[selectedLanguage]}}</th>

          <ng-container *ngFor="let row of bphChange.yearRows;let i=index;">
            <th class="angular-table-th">{{confChange.yearLabel[selectedLanguage]}} {{i + 1}}</th>
          </ng-container>

          <th class="angular-table-th">{{confChange.subtotalLabel[selectedLanguage]}}</th>
          <th class="angular-table-th">{{confChange.fundsFromKfasLabel[selectedLanguage]}}</th>
          <th class="angular-table-th">{{confChange.fundsFromOtherInstitutionLabel[selectedLanguage]}}</th>
        </tr>
        <tr class="angular-table-tr" class="descriptionRow">
          <td class="angular-table-td"></td>
          <td class="angular-table-td"></td>
          <ng-container *ngFor="let row of bphChange.yearRows;">
            <td class="angular-table-td"></td>
          </ng-container>
          <td class="angular-table-td"> {{replaceDescriptionLabelWithYears(confChange.subTotalDescription)}}</td>
          <td class="angular-table-td">{{replaceDescriptionLabelWithYears(confChange.fundsFromKFASDescription)}}</td>
          <td class="angular-table-td">
            {{replaceDescriptionLabelWithYears(confChange.fundsFromOtherInstitutionDescription)}}
          </td>
        </tr>
        <ng-container *ngIf="bphChange.numberOfBudgetTables[0]?.rows.length > 0">
          <ng-container *ngFor="let row of bphChange.numberOfBudgetTables[0].rows; let i = index;">
            <tr class="angular-table-tr" class="regularCellStyle">
              <!--<td class="angular-table-td" style="visibility: visible !important;"><span *ngIf="i === 0">{{confChange?.mainCategoryName[0].label[selectedLanguage]}}</span></td>-->
              <td class="angular-table-td" style="visibility: visible !important;"><span *ngIf="i === 0">{{row.category?.label[selectedLanguage]}}</span>
              </td>
              <!--<ng-container *ngIf="i !== 0">-->
              <!--<td class="angular-table-td"><span></span></td>-->
              <!--</ng-container>-->

              <td class="angular-table-td"><span *ngIf="row.subCategory?.label">{{row.subCategory?.label[selectedLanguage]}}</span>
              </td>

              <ng-container *ngFor="let expandRow of row.expandRows;">
                <td class="angular-table-td">
                  {{convertNumberToCurrencyFormat(expandRow.subTotal, confChange.currency)}}
                </td>
              </ng-container>

            <td class="angular-table-td">{{convertNumberToCurrencyFormat(row.grandSubTotal, confChange.currency)}}</td>
            <td class="angular-table-td">{{convertNumberToCurrencyFormat(row.grandTotalForKfasFunds, confChange.currency)}}</td>
            <td class="angular-table-td">{{convertNumberToCurrencyFormat(row.grandTotalForOtherFunds, confChange.currency)}}</td>
          </tr>
        </ng-container>

          <tr class="angular-table-tr" class="subTotalStyles">
            <td class="angular-table-td">{{confChange.subtotalLabel[selectedLanguage]}}</td>
            <td class="angular-table-td" class="lightBackground"></td>
            <ng-container *ngFor="let yearTotal of bphChange.numberOfBudgetTables[0].yearRows;">
              <td class="angular-table-td">{{yearTotal}}</td>
            </ng-container>
            <td class="angular-table-td">{{convertNumberToCurrencyFormat(bphChange.numberOfBudgetTables[0].grandSubTotal, confChange.currency)}}</td>
            <td class="angular-table-td">
              {{convertNumberToCurrencyFormat(bphChange.numberOfBudgetTables[0].grandTotalForKfasFunds, confChange.currency)}}
            </td>
            <td class="angular-table-td">
              {{convertNumberToCurrencyFormat(bphChange.numberOfBudgetTables[0].grandTotalForOtherFunds, confChange.currency)}}
            </td>
          </tr>
        </ng-container>


        <ng-container *ngIf="bphChange.numberOfBudgetTables[1]?.rows.length > 0">

          <ng-container *ngFor="let category of searchResultForCategory;">
            <ng-container *ngFor="let row of getMapValue(category,1); let i = index;">
              <tr class="angular-table-tr" class="regularCellStyle">
                <ng-container *ngIf="i === 0">
                  <td class="angular-table-td">{{row.category.label[selectedLanguage]}}</td>
                </ng-container>
                <ng-container *ngIf="i !== 0">
                  <td class="angular-table-td"></td>
                </ng-container>

                <td class="angular-table-td">{{row.subCategory?.label[selectedLanguage]}}</td>

                <ng-container *ngFor="let expandRow of row.expandRows;">
                  <td class="angular-table-td">
                    {{convertNumberToCurrencyFormat(expandRow.subTotal, confChange.currency)}}
                  </td>
                </ng-container>

                <td class="angular-table-td">{{convertNumberToCurrencyFormat(row.grandSubTotal, confChange.currency)}}</td>
                <td class="angular-table-td">{{convertNumberToCurrencyFormat(row.grandTotalForKfasFunds, confChange.currency)}}</td>
                <td class="angular-table-td">{{convertNumberToCurrencyFormat(row.grandTotalForOtherFunds, confChange.currency)}}</td>
              </tr>
            </ng-container>
          </ng-container>
          <tr class="angular-table-tr" class="subTotalStyles">
            <td class="angular-table-td">{{confChange.subtotalLabel[selectedLanguage]}}</td>
            <td class="angular-table-td" class="lightBackground"></td>
            <ng-container *ngFor="let yearTotal of bphChange.numberOfBudgetTables[1].yearRows">
              <td class="angular-table-td">{{yearTotal}}</td>
            </ng-container>
            <td class="angular-table-td">{{convertNumberToCurrencyFormat(bphChange.numberOfBudgetTables[1].grandSubTotal, confChange.currency)}}
            </td>
            <td class="angular-table-td">
              {{convertNumberToCurrencyFormat(bphChange.numberOfBudgetTables[1].grandTotalForKfasFunds, confChange.currency)}}
            </td>
            <td class="angular-table-td">
              {{convertNumberToCurrencyFormat(bphChange.numberOfBudgetTables[1].grandTotalForOtherFunds, confChange.currency)}}
            </td>
          </tr>
        </ng-container>


        <ng-container *ngIf="bphChange.numberOfBudgetTables[2]?.rows.length > 0">

          <ng-container *ngFor="let category of searchResultForCategory;">
            <ng-container *ngFor="let row of getMapValue(category,2); let i = index;">
              <tr class="angular-table-tr" class="regularCellStyle">
                <ng-container *ngIf="i === 0">
                  <td class="angular-table-td">{{row.category.label[selectedLanguage]}}</td>
                </ng-container>
                <ng-container *ngIf="i !== 0">
                  <td class="angular-table-td"></td>
                </ng-container>

                <td class="angular-table-td">{{row.subCategory?.label[selectedLanguage]}}</td>

                <ng-container *ngFor="let expandRow of row.expandRows;">
                  <td class="angular-table-td">
                    {{convertNumberToCurrencyFormat(expandRow.subTotal, confChange.currency)}}
                  </td>
                </ng-container>

                <td class="angular-table-td">{{convertNumberToCurrencyFormat(row.grandSubTotal, confChange.currency)}}</td>
                <td class="angular-table-td">{{convertNumberToCurrencyFormat(row.grandTotalForKfasFunds, confChange.currency)}}</td>
                <td class="angular-table-td">{{convertNumberToCurrencyFormat(row.grandTotalForOtherFunds, confChange.currency)}}</td>
              </tr>
            </ng-container>
          </ng-container>
          <tr class="angular-table-tr" class="subTotalStyles">
            <td class="angular-table-td">{{confChange.subtotalLabel[selectedLanguage]}}</td>
            <td class="angular-table-td" class="lightBackground"></td>
            <ng-container *ngFor="let yearTotal of bphChange.numberOfBudgetTables[2].yearRows">
              <td class="angular-table-td">{{yearTotal}}</td>
            </ng-container>
            <td class="angular-table-td">{{convertNumberToCurrencyFormat(bphChange.numberOfBudgetTables[2].grandSubTotal, confChange.currency)}}
            </td>
            <td class="angular-table-td">
              {{convertNumberToCurrencyFormat(bphChange.numberOfBudgetTables[2].grandTotalForKfasFunds, confChange.currency)}}
            </td>
            <td class="angular-table-td">
              {{convertNumberToCurrencyFormat(bphChange.numberOfBudgetTables[2].grandTotalForOtherFunds, confChange.currency)}}
            </td>
          </tr>
        </ng-container>

        <tr class="angular-table-tr" class="subTotalStyles">
          <td class="angular-table-td">{{confChange.grandTotalLabel[selectedLanguage]}}</td>
          <td class="angular-table-td" class="lightBackground"></td>
          <ng-container *ngFor="let yearTotal of bphChange.yearRows;">
            <td class="angular-table-td">{{yearTotal}}</td>
          </ng-container>
          <td class="angular-table-td">{{convertNumberToCurrencyFormat(bphChange.grandSubTotal, confChange.currency)}}</td>
          <td class="angular-table-td">{{convertNumberToCurrencyFormat(bphChange.grandTotalForKfasFunds, confChange.currency)}}</td>
          <td class="angular-table-td">{{convertNumberToCurrencyFormat(bphChange.grandTotalForOtherFunds, confChange.currency)}}</td>
        </tr>

      </table>
      <span *ngIf="bphChange?.error[selectedLanguage]" style="color: red !important; font-weight: bold !important;">{{bphChange.error[selectedLanguage]}}</span>
    </div>
  </ng-container>

  <div *ngIf="showSecondTable">

    <div *ngIf="tempObjectForNotManpowerTable.category && tempObjectForNotManpowerTable.category.label">
      <span style="color: red">*</span> {{confChange.categoryLabel[selectedLanguage]}}
      <mat-form-field appearance="outline">
        <mat-select [(ngModel)]="tempObjectForNotManpowerTable.category.label[selectedLanguage]" [disabled]="true">
          <mat-option *ngFor="let category of searchResultForCategory" [value]="category.label[selectedLanguage]">
            {{category.label[selectedLanguage]}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div><span style="color: red">*</span> {{confChange.subCategoryLabel[selectedLanguage]}}
      <span *ngIf="confChange.hasDialogForSubCategory">
      <button  mat-icon-button (click)="openDialog(confChange.dialogForSubCategory)"  style="margin-left: -10px;margin-bottom: -10px;">
      <mat-icon
        style="cursor: pointer; font-size: 21px;"
        (click)="openDialog(confChange.dialogForSubCategory)">help_outline
      </mat-icon></button></span>
    </div>

    <mat-form-field appearance="outline">

      <ng-container
        *ngIf="confChange.isBudgetTypeInOpexSection !== undefined && confChange.isBudgetTypeInOpexSection === true">
        <mat-select id="subcategory" [(ngModel)]="tempObjectForNotManpowerTable.subCategory.value"
                    [disabled]="confChange.isBudgetTypeInCapexSection !== undefined && confChange.isBudgetTypeInCapexSection === true"
                    (ngModelChange)="checkSubCategory($event, tempObjectForNotManpowerTable)">

          <mat-option *ngFor="let subCategory of subCategoryList "
                      [value]="subCategory.value">
            {{subCategory.label[selectedLanguage]}}
          </mat-option>
        </mat-select>
      </ng-container>

      <ng-container
        *ngIf="confChange.isBudgetTypeInCapexSection !== undefined && confChange.isBudgetTypeInCapexSection === true">
        <mat-select id = 'subcategory' [(ngModel)]="tempObjectForNotManpowerTable.subCategory.label[selectedLanguage]"
                    [disabled]="confChange.isBudgetTypeInCapexSection !== undefined && confChange.isBudgetTypeInCapexSection === true"
                    (ngModelChange)="checkSubCategory($event, tempObjectForNotManpowerTable)">
          <mat-option *ngFor="let subCategory of subCategoryList"
                      [value]="subCategory.label[selectedLanguage]">
            {{subCategory.label[selectedLanguage]}}
          </mat-option>
        </mat-select>
      </ng-container>
    </mat-form-field>
    <div>
      <label><span style="color: red">*</span> {{confChange.descriptionLabel[selectedLanguage]}}</label>
      <mat-form-field appearance="outline">
        <textarea id = 'description' matInput [rows]="confChange.numberOfRowsForTextarea"
                  [(ngModel)]="tempObjectForNotManpowerTable.description"
                  (keyup)="changeValueEvent(tempObjectForNotManpowerTable.description, 'description')"></textarea>
      </mat-form-field>
      <p class="counter-style" *ngIf="confChange.isMaxLengthWords">
        {{getWordCount(tempObjectForNotManpowerTable.description)}} / {{confChange.maxlength}} {{'ecase.common.words' |
        translate}}</p>
      <p class="counter-style" *ngIf="!confChange.isMaxLengthWords">
        {{getTextLength(tempObjectForNotManpowerTable.description)}} / {{confChange.maxlength}}
        {{'ecase.common.characters' | translate}}</p>
    </div>

    <div>
      <label>{{confChange.commentsLabel[selectedLanguage]}}</label>
      <mat-form-field appearance="outline">
        <textarea id = 'comments' matInput [rows]="confChange.numberOfRowsForTextarea"
                  (keyup)="changeValueEvent(tempObjectForNotManpowerTable.comments, 'comments')"
                  [(ngModel)]="tempObjectForNotManpowerTable.comments"></textarea>
      </mat-form-field>
      <p class="counter-style" *ngIf="confChange.isMaxLengthWords">
        {{getWordCount(tempObjectForNotManpowerTable.comments)}} / {{confChange.maxlength}} {{'ecase.common.words' |
        translate}}</p>
      <p class="counter-style" *ngIf="!confChange.isMaxLengthWords">
        {{getTextLength(tempObjectForNotManpowerTable.comments)}} / {{confChange.maxlength}} {{'ecase.common.characters'
        | translate}}</p>

    </div>

    <div fxLayout="column" fxLayoutAlign="start" style="margin-bottom: 1.34375em;">
      <span>{{confChange.documentationLabelInsideTable[selectedLanguage]}}</span>
      <label
        *ngIf="!(tempObjectForNotManpowerTable.uploadFile) || tempObjectForNotManpowerTable.uploadFile.length === 0"
        [style.width.px]="selectedLanguage=== 'en' ? 125 : 201"
        class="myLabel mat-elevation-z3">
        <mat-icon style="vertical-align: bottom; margin-right: 12px;">cloud_upload</mat-icon>
        <input id="upload_doc" (change)="uploadDone1($event, tempObjectForNotManpowerTable)" type="file" accept="application/pdf"/>
        <span style="vertical-align: text-bottom;">{{getTranslatedLabel("filetoUpload")}}</span>
      </label>
      <label *ngIf="tempObjectForNotManpowerTable.uploadFile?.length > 0">
        <a href="/api/viewAttachedRepositoryDocument/{{tempObjectForNotManpowerTable.uploadFile[0].id}}"
           target="_blank">{{tempObjectForNotManpowerTable.uploadFile[0].fileName}}</a>
        <button mat-icon-button>
          <mat-icon aria-label="Delete"
                    (click)="deleteFile(tempObjectForNotManpowerTable.uploadFile[0], tempObjectForNotManpowerTable)">
            delete
          </mat-icon>
        </button>
      </label>
    </div>

    <table id="budget_t1_table" class="angular-table showSecondTable" style="margin-bottom: 1.34375em;">
      <thead class="angular-table-thead">
      <tr class="angular-table-tr">
        <th class="angular-table-th">{{confChange.yearLabel[selectedLanguage]}}</th>
        <th class="angular-table-th"><span style="color: red">*</span> {{confChange.subtotalLabel[selectedLanguage]}}
        </th>
        <th class="angular-table-th"><span style="color: red">*</span>
          {{confChange.fundsFromKfasLabel[selectedLanguage]}}
        </th>
        <th class="angular-table-th"><span style="color: red">*</span>
          {{confChange.fundsFromOtherInstitutionLabel[selectedLanguage]}}
        </th>
      </tr>
      </thead>


      <ng-container *ngFor="let expandableRows of tempObjectForNotManpowerTable.expandRows">
        <tr class="angular-table-tr">
          <td class="angular-table-td">{{expandableRows.label[selectedLanguage]}}</td>
          <td class="angular-table-td" style="padding: 2px 18px;">
            <div
              [ngClass]="expandableRows?.subTotalError && expandableRows?.subTotalError.error && expandableRows?.subTotalError.error[selectedLanguage] ? 'hasError' : 'noError'">
              <mat-form-field appearance="outline"
                              [ngClass]="expandableRows?.subTotalError && expandableRows?.subTotalError.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''">
                <input  id="subtotal" matInput type="number" min="0" max="999999999"
                        (input)="expandableRows.subTotal < 0 ? expandableRows.subTotal = 0 : onSubTotalInput(bphChange.numberOfBudgetTables[1], tempObjectForNotManpowerTable, expandableRows, $event);"
                        [(ngModel)]="expandableRows.subTotal" maxlength="9" >
              </mat-form-field>
              <p class="counter-style" style="margin-top: 0px; text-align: right;">
                {{getTextLength2(expandableRows.subTotal)}} / 9</p>
              <div *ngIf="expandableRows?.subTotalError && expandableRows?.subTotalError.error[selectedLanguage]"
                   class="formFieldError">{{expandableRows?.subTotalError.error[selectedLanguage]}}
              </div>
            </div>
          </td>

          <td class="angular-table-td" style="padding: 2px 18px;">
            <div
              [ngClass]="expandableRows?.fundsFromKfasError && expandableRows?.fundsFromKfasError.error && expandableRows?.fundsFromKfasError.error[selectedLanguage] ? 'hasError' : 'noError'">
              <mat-form-field appearance="outline"
                              [ngClass]="expandableRows?.fundsFromKfasError && expandableRows?.fundsFromKfasError.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''">
                <input id="kfas_funds" matInput type="number" min="0" max="999999999"
                       (input)="expandableRows.fundsFromKfas < 0 ? expandableRows.fundsFromKfas = 0 : onKfasFundsInput(bphChange.numberOfBudgetTables[1], tempObjectForNotManpowerTable, expandableRows, $event);"
                       [(ngModel)]="expandableRows.fundsFromKfas" maxlength="9">
              </mat-form-field>
              <p class="counter-style" style="margin-top: 0px; text-align: right;">
                {{getTextLength2(expandableRows.fundsFromKfas)}} / 9 </p>
              <div
                *ngIf="expandableRows?.fundsFromKfasError && expandableRows?.fundsFromKfasError.error[selectedLanguage]"
                class="formFieldError">{{expandableRows?.fundsFromKfasError.error[selectedLanguage]}}
              </div>
            </div>
          </td>

          <td class="angular-table-td" style="padding: 2px 18px;">
            <div style="padding-right: 15px"
                 [ngClass]="expandableRows?.fundsFromOtherInstitutionError && expandableRows?.fundsFromOtherInstitutionError.error && expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage] ? 'hasError' : 'noError'">
              <mat-form-field appearance="outline"
                              [ngClass]="expandableRows?.fundsFromOtherInstitutionError && expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''">
                <input id="other_funds" matInput type="number" min="0"  max="999999999" [disabled]="true"
                       (input)="expandableRows.fundsFromOtherInstitution < 0 ? expandableRows.fundsFromOtherInstitution = 0 : onOtherInstitutionFundsInput(bphChange.numberOfBudgetTables[1], tempObjectForNotManpowerTable)"
                       [(ngModel)]="expandableRows.fundsFromOtherInstitution" maxlength="9">
              </mat-form-field>
              <p class="counter-style" style="margin-top: 0px; text-align: right;">
                {{getTextLength2(expandableRows.fundsFromOtherInstitution)}} / 9</p>
              <div
                *ngIf="expandableRows?.fundsFromOtherInstitutionError && expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage]"
                class="formFieldError">
                {{expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage]}}
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
      <tr class="angular-table-tr">
        <td class="angular-table-td"><span>{{confChange.subtotalLabel[selectedLanguage]}}</span></td>
        <td class="angular-table-td">
          <span>{{tempObjectForNotManpowerTable.grandSubTotal}}</span>
        </td>
        <td class="angular-table-td">
          <span>{{tempObjectForNotManpowerTable.grandTotalForKfasFunds}}</span>
        </td>
        <td class="angular-table-td">
          <span>{{tempObjectForNotManpowerTable.grandTotalForOtherFunds}}</span>
        </td>
      </tr>

    </table>

    <button id="saveBtn" style="margin-right: 1%;" mat-raised-button class="eCase-dialog-positive-action-button" (click)="confirm()">
      {{getTranslatedLabel("save")}}
    </button>

    <button id="cancelBtn" mat-raised-button color="accent" (click)="cancel()">
      {{'ecase.common.cancel' | translate}}
    </button>

  </div>

  <!--<ng-container *ngIf="showEditManpowerTable">
    <table class="angular-table">

      <thead class="angular-table-thead">
      <tr class="angular-table-tr">
        <th class="angular-table-th">{{confChange.yearLabel[selectedLanguage]}}</th>
        <th class="angular-table-th"><span style="color: red">*</span>{{confChange.currency}} {{confChange.subtotalLabel[selectedLanguage]}}</th>
        <th class="angular-table-th"><span style="color: red">*</span>{{confChange.currency}} {{confChange.fundsFromKfasLabel[selectedLanguage]}}
        </th>
        <th class="angular-table-th"><span style="color: red">*</span>{{confChange.currency}} {{confChange.fundsFromOtherInstitutionLabel[selectedLanguage]}}
        </th>
      </tr>
      </thead>
      <tr *ngFor="let expandableRows of manPowerEditRow.expandRows" class="angular-table-tr">
        <td class="angular-table-td">{{expandableRows.label}}</td>
        <td class="angular-table-td">
          <mat-form-field appearance="outline" style="width:80%;" [ngClass]="expandableRows?.subTotalError && expandableRows?.subTotalError.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''">
            <input matInput type="number" min="0" (input)="expandableRows.subTotal < 0 ? expandableRows.subTotal = 0 : onSubTotalInput(selectedTableToEdit, manPowerEditRow)"
                   [(ngModel)]="expandableRows.subTotal">
          </mat-form-field>
          <div *ngIf="expandableRows?.subTotalError && expandableRows?.subTotalError.error[selectedLanguage]"
               class="formFieldError">{{expandableRows?.subTotalError.error[selectedLanguage]}}</div>
        </td>
        <td class="angular-table-td">
          <mat-form-field appearance="outline" style="width:80%;" [ngClass]="expandableRows?.fundsFromKfasError && expandableRows?.fundsFromKfasError.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''">
            <input matInput type="number" min="0"
                   (input)="expandableRows.fundsFromKfas < 0 ? expandableRows.fundsFromKfas = 0 : onKfasFundsInput(selectedTableToEdit, manPowerEditRow)"
                   [(ngModel)]="expandableRows.fundsFromKfas">
          </mat-form-field>
          <div *ngIf="expandableRows?.fundsFromKfasError && expandableRows?.fundsFromKfasError.error[selectedLanguage]"
               class="formFieldError">{{expandableRows?.fundsFromKfasError.error[selectedLanguage]}}</div>
        </td>
        <td class="angular-table-td">
          <mat-form-field appearance="outline" style="width:80%;" [ngClass]="expandableRows?.fundsFromOtherInstitutionError && expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''">
            <input matInput type="number" min="0"
                   (input)="expandableRows.fundsFromOtherInstitution < 0 ? expandableRows.fundsFromOtherInstitution = 0 : onOtherInstitutionFundsInput(selectedTableToEdit, manPowerEditRow)"
                   [(ngModel)]="expandableRows.fundsFromOtherInstitution">
          </mat-form-field>
          <div
            *ngIf="expandableRows?.fundsFromOtherInstitutionError && expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage]"
            class="formFieldError">{{expandableRows?.fundsFromOtherInstitutionError.error[selectedLanguage]}}</div>
        </td>
      </tr>
      <tr class="angular-table-tr">
        <td class="angular-table-td"><span>{{confChange.currency}} {{confChange.subtotalLabel[selectedLanguage]}}</span></td>
        <td class="angular-table-td">
          <span>{{confChange.currency}} {{manPowerEditRow.grandSubTotal}}</span>
        </td>
        <td class="angular-table-td">
          <span>{{confChange.currency}} {{manPowerEditRow.grandTotalForKfasFunds}}</span>
        </td>
        <td class="angular-table-td">
          <span>{{confChange.currency}} {{manPowerEditRow.grandTotalForOtherFunds}}</span>
        </td>
      </tr>
    </table>

    <div style="margin-top: 3%;">
      <button style=" background-color: #4FC3F7; color: #FFFFFF; margin-right: 1%;" (click)="confirmEditingTheManpowerTableRow(manPowerEditRow)"
              mat-raised-button >{{'save' | translate}}</button>
      <button (click)="cancel()"
              mat-raised-button color="accent">{{'cancel' | translate}}</button>
    </div>
  </ng-container>-->
  <div class="field">
    <span class="formFieldError" *ngIf="bphChange?.error[selectedLanguage]">{{bphChange.error[selectedLanguage]}}</span>
  </div>
</div>
