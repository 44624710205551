import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ArraySortPipe, ECaseUtils, PendingChangesGuard} from 'synto-common';


@Component({
  selector: 'fb-dynamic-matrix',
  templateUrl: './fb-dynamic-matrix.component.html',
  styleUrls: ['./fb-dynamic-matrix.component.css']
})
export class FbDynamicMatrixComponent implements OnInit {
  @Input() url: any;
  @Input() selectedLanguage;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() globalConf: any;
  @Input() globalPrj: any;
  row: any;
  lovRowArray = [];
  lovColumnArray = [];
  lovLabelArray = [];
  labelLovArray = [];
  rowLovArray;
  columnLovArray;
  rowLovHeaderText;
  columnLovHeaderText;
  isMultipleChoiceAllowed: boolean;
  selectedRadioLovObject: any = {};
  selectedCheckboxLovObject: any = {};
  spliceLovObject: any = {};
  i: number = -1;
  selectedRadio: boolean;

  constructor(private translate: TranslateService, public dialog: MatDialog, private pendingChangesGuard: PendingChangesGuard) {
    this.bphChange = {};
    this.bphChange.error = {};
  }

  getNewIndex() {
    this.i = this.i + 1;


    return this.i;
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '600px',
      data:
        {
          dialog: this.confChange.dialogText,
          selectedLanguage: this.selectedLanguage
        }
    });
    console.log('donee');
  }


  ngOnInit() {
    /*this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;
    });*/
    if (!this.bphChange) {
      this.bphChange = {};
      this.bphChange.error = {};
      this.bphChange.value = [];
    }
    if (!this.bphChange.error) {
      this.bphChange.error = {};
    }
    if (!this.bphChange.value) {
      this.bphChange.value = [];
    }


    this.isMultipleChoiceAllowed = this.confChange.isMultipleChoiceAllowed;
    for (const key in this.globalConf.lovs) {
      if (this.globalConf.lovs.hasOwnProperty(key)) {
        this.lovRowArray.push(this.globalConf.lovs[key]);
      }
    }
    for (const key in this.globalConf.lovs) {
      if (this.globalConf.lovs.hasOwnProperty(key)) {
        this.lovColumnArray.push(this.globalConf.lovs[key]);
      }
    }
    for (const key in this.globalConf.lovs) {
      if (this.globalConf.lovs.hasOwnProperty(key)) {
        this.lovLabelArray.push(this.globalConf.lovs[key]);
      }
    }
    this.lovRowArray = this.lovRowArray.filter(lov => lov.name == this.confChange.listNameForRowLov)[0].list;
    this.lovColumnArray = this.lovColumnArray.filter(lov => lov.name == this.confChange.listNameForColumnLov)[0].list;
    this.lovLabelArray = this.lovLabelArray.filter(lov => lov.name == this.confChange.listNameForLabelLov)[0].list;
    const arraySortPipe = new ArraySortPipe();
    this.lovLabelArray = arraySortPipe.transform(this.lovLabelArray, 'value');
    this.rowLovArray = this.lovRowArray.map(c => c.label[this.selectedLanguage]);
    this.columnLovArray = this.lovColumnArray.map(c => c.label[this.selectedLanguage]);
    this.labelLovArray = this.lovLabelArray.map(c => c.label[this.selectedLanguage]);
    console.log(this.bphChange);
    console.log(this.lovRowArray);
    console.log(this.lovColumnArray);
  }

  onRadioButtonChange($event, event1, index, index1) {
    this.bphChange.value = [];
    this.selectedRadioLovObject.rowLovSelected = $event.value;
    this.selectedRadioLovObject.columnLovSelected = event1;
    this.selectedRadioLovObject.index = index;
    this.selectedRadioLovObject.index1 = index1;
    this.selectedRadioLovObject.selection = (index1 * this.lovRowArray.length) + index;
    this.selectedRadioLovObject.selectionLabel = this.lovLabelArray[(index1 * this.lovRowArray.length) + index];
    this.bphChange.value.push(this.selectedRadioLovObject);
    console.log(this.bphChange);
    const testVariable: any = window;
    testVariable.isFormModified = ECaseUtils.isGlobalPrjPristine(this.globalPrj, testVariable.pristinePrj);
    this.pendingChangesGuard.isPristine = false;
  }

  onCheckboxLovSelection(event, event2) {
    console.log('insde the checkbox');
    if (event.checked) {
      this.selectedCheckboxLovObject.selectedRowLov = event.source.value;
      this.selectedCheckboxLovObject.selectedColumnLov = event2;
      this.bphChange.value.push(this.selectedCheckboxLovObject);
      this.selectedCheckboxLovObject = {};

    } else {
      this.spliceLovObject.selectedRowLov = event.source.value;
      this.spliceLovObject.selectedColumnLov = event2;
      for (const value of this.bphChange.value) {
        if (value.selectedColumnLov === this.spliceLovObject.selectedColumnLov && value.selectedRowLov === this.spliceLovObject.selectedRowLov) {
          const index = this.bphChange.value.indexOf(value);
          this.bphChange.value.splice(index, 1);

        }
      }
      this.spliceLovObject = {};
    }
    const testVariable: any = window;
    testVariable.isFormModified = ECaseUtils.isGlobalPrjPristine(this.globalPrj, testVariable.pristinePrj);
    this.pendingChangesGuard.isPristine = false;
  }


}
