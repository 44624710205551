import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'fb-warning-text',
  templateUrl: './fb-warning-text.component.html',
  styleUrls: ['./fb-warning-text.component.css']
})
export class FbWarningTextComponent implements OnInit {
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  lang: any;
  @Input() selectedLanguage;
  @Input() offlineFormConfigurationJSON;



  constructor(private translate: TranslateService) {
    this.confChange = {};
    this.bphChange = {};
}

  ngOnInit() {
   /* this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;
    });*/
  }
}
