import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'fb-tooltip',
  templateUrl: './fb-tooltip.component.html',
  styleUrls: ['./fb-tooltip.component.css']
})
export class FbTooltipComponent implements OnInit {
  @Input() tooltipText: any;
  @Input() selectedLanguage: any;

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    if (!(this.tooltipText)) {
      this.tooltipText = {};
    }
    /*this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;
    });*/
  }
}

