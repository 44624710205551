<form [formGroup]="multiValuedVariablesForm" autocomplete="off">
  <mat-dialog-content>
    <table>
      <tbody>
      <tr *ngFor="let key of keys; let i=index;">
        <td style="text-align: right !important;padding: 5px"><span style="color: red">* </span>{{key.label[selectedLang]}}</td>
        <td style="text-align: center;padding: 5px; width: 100% !important;">
          <mat-form-field appearance="outline">
            <mat-select
              [attr.aria-label]="'ecase.admindashboard.eligibility.awf.multivaluedselectfield '| eCaseTranslate"
              [formControlName]="key.name"
              [id]="'ecase_admindashboard_eligibility_awf_multivaluedselectfield_' + key.name">
              <mat-option role="option" selected
                          value="">{{'ecase.common.pleaseSelect' | eCaseTranslate }}</mat-option>
              <mat-option style="height:initial!important;" *ngFor="let item of key.values" [value]="item.result">
                <span style="word-wrap: break-word;white-space: pre-wrap;">{{item.resultWithUpdatedLabels}}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
      </tbody>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
    <button (click)="onVariablesSelection()"
            [disabled]="!multiValuedVariablesForm.valid"
            class="eCase-dialog-positive-action-button"
            id="ecase_admindashboard_eligiblity_projectOverView_addDocument_save" mat-raised-button
            style=" margin-right: 15px;">{{'ecase.admindashboard.eligiblity.projectOverView.addDocument.save'| translate }}
    </button>
    <button (click)="onCancel()"
            class="eCase-dialog-negative-action-button"
            id="ecase_admindashboard_eligiblity_projectOverView_addDocument_cancel"
            mat-raised-button>{{'ecase.admindashboard.eligiblity.projectOverView.addDocument.cancel'| translate }}
    </button>
  </mat-dialog-actions>
</form>
