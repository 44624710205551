<div *ngIf="bphChange.globalShowDetail" [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'">
  <span *ngIf="confChange.label[selectedLanguage]"><span *ngIf="confChange.isRequired" style="color:red;">* </span>{{confChange.label[selectedLanguage]}}</span>
  <mat-icon matTooltipClass="tooltip" matTooltip="{{confChange.tooltipText[selectedLanguage]}}"
            *ngIf="confChange.hasTooltip">
    help_outline
  </mat-icon>
  <span *ngIf="confChange.hasDialog" style="padding-top: 10px;">
    <mat-icon style="cursor: pointer;" (click)="openDialog()"> help_outline </mat-icon>
  </span>
  <mat-radio-group name="opList" [(ngModel)]="bphChange.selection">
  <table style="width: 100%; margin-bottom: 10px;" class="angular-table">
    <thead style="border:1px solid lightgray;" class="angular-table-thead">
    <tr class="angular-table-tr">
      <th rowspan="2" class="angular-table-th" style="border:1px solid lightgray; text-align: center; padding-bottom: 35px; font-weight: 900; font-size: 13px;">
        {{confChange.rowLovHeaderText[selectedLanguage]}}
        <!--<span *ngIf="confChange.hasDialog" style="padding-top: 10px;">-->
             <!--<mat-icon style="cursor: pointer;" (click)="openDialog()"> help_outline </mat-icon>-->
        <!--</span>-->
      </th>
      <th colspan="3"  class="angular-table-th" style="border:1px solid lightgray; text-align: center; font-weight: 900; font-size: 13px;">
        {{confChange.columnLovHeaderText[selectedLanguage]}}
      </th>
    </tr>
    <tr class="angular-table-tr">
      <th class="angular-table-th" *ngFor="let rowLov of lovRowArray | sort: 'value'"
          style="text-align: center; font-weight: 900; font-size: 13px;">
        {{rowLov.label[selectedLanguage]}}
      </th>
    </tr>
    </thead>
    <tbody>

    <tr class="angular-table-tr" *ngFor="let columnLov of lovColumnArray | sort: 'value';let i= index">
      <td class="angular-table-td" style="text-align: left; font-weight: 900; border-right: 1px solid lightgray; color: rgba(0,0,0,.54);">
        {{columnLov.label[selectedLanguage]}}
      </td>
      <ng-container *ngIf="!isMultipleChoiceAllowed">
        <td class="angular-table-td" *ngFor="let rowLov of lovRowArray;let j= index" style="text-align: center;">
          <label style="font-size: 16px;">{{labelLovArray[(i*lovRowArray.length)+j]}}</label>
            <mat-radio-button [attr.aria-label]="confChange.label[selectedLanguage]"  name="opList"
                              [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'" [value]="(i*lovRowArray.length)+j"
                              (change)="onRadioButtonChange($event, columnLov, j, i)"></mat-radio-button>
        </td>
      </ng-container>
      <ng-container *ngIf="isMultipleChoiceAllowed">
        <td class="angular-table-td" *ngFor="let rowLov of lovRowArray" style="text-align: center;">
          <mat-checkbox [value]="rowLov"  [attr.aria-label]="confChange.label[selectedLanguage]" [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true'" (change)="onCheckboxLovSelection($event, columnLov)"></mat-checkbox>
        </td>
      </ng-container>
    </tr>
    </tbody>
  </table>
  </mat-radio-group>
  <div style="font-weight: 700;color:red; margin-top: -5px !important; padding-bottom: 10px !important;" >{{bphChange.error[selectedLanguage]}}</div>


</div>
