<div fxLayout="row" fxLayoutAlign="start" id="ecase-phone-number-field">
  <mat-form-field #cdkOverlayOrigin="cdkOverlayOrigin" appearance="outline" cdkOverlayOrigin>
    <input [formControl]="phoneNumberCtrl" eCaseNDecimalNumber="0" matInput
           type="text">
    <div (click)="createOverlay();$event.stopPropagation()" matPrefix
         matRipple
         matTooltip="{{ selectedCountry ? selectedCountry.label[this.selectedLanguage] + ' ' + '+' + selectedCountry.callingCode : ''}}"
         class="centered cursor">
      <span *ngIf="selectedCountry" [ngClass]="'flag-icon-' + selectedCountry.countryCode" class="flag-icon mb-5 mr-5"></span>
      <span *ngIf="selectedCountry"> +{{selectedCountry.callingCode}}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
    <mat-error *ngIf="phoneNumberCtrl.errors?.required">
      {{'ecase.common.thisfieldismandatory' | translate }}
    </mat-error>
    <mat-error *ngIf="phoneNumberCtrl.errors?.invalidPhoneNumber">
      {{'ecase.common.invalid.phonenumber' | translate }}
    </mat-error>
  </mat-form-field>
</div>

<ng-template #overlayTemplate>
  <mat-card class="mt-20">
    <mat-card-content>
      <button (click)="closeOverlay()" class="mat-icon-button right"
              mat-icon-button aria-label="Close country selector">
        <mat-icon>close</mat-icon>
      </button>
      <div>
        <mat-form-field appearance="outline">
          <input aria-label="Country" [formControl]="countryText" matInput>
        </mat-form-field>

        <mat-divider></mat-divider>

        <mat-selection-list class="list" [multiple]="false" [formControl]="countryCtrl" (selectionChange)="updatePhoneNumberFormat($event)" >
          <mat-list-option *ngFor="let country of filteredCountries | async" [value]="country" class="list-option" (click)="selectionClicked()">
           <div class="list-item" fxLayout="row" fxLayoutAlign="start">
             <span [ngClass]="'flag-icon-' + country.countryCode" class="flag-icon"></span>
             <span class="ml-10">{{country.label[this.selectedLanguage]}}</span>
             <span class="ml-10 grey">(+{{country.callingCode}})</span>
           </div>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>