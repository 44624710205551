<div *ngIf="!bphChange.hide && !confChange.showDetails"
     [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
     class="{{bphChange.error_class}} hide_{{bphChange.show}}"
     style="margin-bottom: 1.34375em">
  <div class="formLabel">
    <span *ngIf="confChange.isRequired" style="color: red">* </span>
    <span *ngIf="confChange.tableLabel" [innerHTML]="confChange.tableLabel[selectedLanguage]"></span>
    <span *ngIf="confChange.hasTooltip" style="padding-top: 10px;">
             <mat-icon matTooltip="{{confChange.tooltipText[selectedLanguage]}}"
                       matTooltipClass="tooltip">help_outline</mat-icon>
          </span>

    <span *ngIf="confChange.hasDialog" style="padding-top: 10px;">
             <mat-icon (click)="openDialog(confChange)"
                       style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;">help_outline</mat-icon>
          </span>
  </div>

  <mat-accordion multi>
    <ng-container *ngFor="let row of bphChange.rows;let i = index;">

      <mat-expansion-panel (opened)="row.panelOpenState = true"
                           [expanded]="row.panelOpenState"
                           (closed)="row.panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>{{row.panelOpenState ? 'remove_circle_outline' : 'add_circle_outline'}}</mat-icon>
            <span style="margin-left: 10px">{{row.teamNameLabel ? row.teamNameLabel[selectedLanguage] : ''}}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container *ngFor="let subBlock of confChange.subBlocks;let j = index;">
          <fb-table [bphChange]='row[subBlock.name]'
                    [confChange]='subBlock'
                    [showSaveNavBar]='showSaveNavBar'
                    [globalConf]='globalConf'
                    [globalPrj]='globalPrj'
                    [teamTableIndex]="i + j"
                    [isValidHtml]='subBlock.isValidHtml'
                    [isValidTableReadonly]='subBlock.isValidTableReadonly'
                    [isValidTable]='subBlock.isValidTable'
                    [selectedLanguage]='selectedLanguage'
                    [selectedSectionId]='selectedSectionId'
                    style='position: static;'>
          </fb-table>
        </ng-container>

      </mat-expansion-panel>

    </ng-container>
  </mat-accordion>

  <span *ngIf="bphChange.error[selectedLanguage]" class="tableSpanError">{{bphChange.error[selectedLanguage]}}</span>

</div>
