import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {ECaseTranslationLoaderService, ECaseUtils, LoginAuthenticationService} from 'synto-common';
import {NestedTreeControl} from '@angular/cdk/tree';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.css']
})
export class TreeNodeComponent implements OnInit {

  TREE_DATA: TreeNode[] = [];
  treeControl = new NestedTreeControl<TreeNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<TreeNode>();
  allChildrenOfRoute: any;
  showTree = false;

  constructor(private translate: TranslateService, private router: Router,
              private translationLoader: ECaseTranslationLoaderService, private loginAuthenticationService: LoginAuthenticationService) {
    this.dataSource.data = this.TREE_DATA;
    this.createTreeNode(this.loginAuthenticationService.tabs);
  }

  hasChild = (_: any, node: TreeNode) => !!node.children && node.children.length > 0;

  ngOnInit() {
    this.translationLoader.loadTranslations(1);
    this.translationLoader.loadTranslations(3);
    this.translate.use(this.translate.getDefaultLang());
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.showTree = false;
      this.createTreeNode(this.loginAuthenticationService.tabs);
    });

  }

  createTreeNode(parentNode) {
    this.allChildrenOfRoute = ECaseUtils.eCaseCloneDeep(parentNode);
    this.allChildrenOfRoute = this.allChildrenOfRoute.filter((element) => {
      if (this.router.url === element.url && element.children.length > 0) {
        this.showTree = true;
        return element;
      } else if (this.router.url === '/dashboard') {
        this.showTree = true;
        return element;
      } else if (this.router.url.includes(element.url) && element.children.length > 0) {
        return element;
      }
    });
    console.log('inside tree node');
    if (this.showTree) {
      this.dataSource.data = this.allChildrenOfRoute;
      this.treeControl.expand(this.dataSource.data[0]);
    } else if (!this.showTree && this.allChildrenOfRoute.length > 0) {
      // console.log(this.allChildrenOfRoute);
      this.createTreeNode(this.allChildrenOfRoute[0].children);
    }
  }

  redirectLinks(url) {
    this.router.navigate([url]);
  }

}


interface TreeNode {
  name: string;
  children?: TreeNode[];
}
