<div *ngIf="isValidHtml && bphChange.globalShowDetail && !bphChange.hide"
     [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'">

  <div class="field {{ bphChange.error_class}} hide_{{ bphChange.show}}">
    <div class="field ui grid">
      <div class="formLabel five wide column righttext">
        <ng-container *ngIf="confChange.condensedLabel && confChange.condensedLabel[selectedLanguage]">
          <span style="font-weight: bolder" [innerHTML]="confChange.condensedLabel[selectedLanguage]"></span>
          <br>
        </ng-container>
        <span *ngIf="confChange.isRequired" style="color:red;">* </span>
        <span [innerHTML]="confChange.label[selectedLanguage]"></span>
        <span *ngIf="confChange.hasTooltip" style="padding-top: 10px;">
            <mat-icon matTooltip="{{confChange.tooltipText[selectedLanguage]}}" matTooltipClass="tooltip">
              help_outline
            </mat-icon>
          </span>
        <span *ngIf="confChange.hasDialog" style="padding-top: 10px;">
             <mat-icon (click)="openDialog()"
                       style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;">help_outline</mat-icon>
          </span>
      </div>
      <div [class.column]="!confChange.inline" [ngClass]="{'inline  fields ui ': confChange.inline}"
           class="nine wide column field">


        <mat-radio-group (ngModelChange)="change($event,confChange,bphChange)"
                         [(ngModel)]=" bphChange.value.value"
                         [ngClass]="bphChange.error[selectedLanguage] ? 'accentBorder' : 'noColor'">
          <div fxLayout="{{(!confChange.inline || confChange.inline === false) ? 'column' : 'row'}}">
            <!--<ng-container *ngFor="let option of list;let i = index">-->
            <div *ngFor="let option of removeItemsToHide(list);let i = index"
                 [style]="confChange.enableOnClickOnlyOnRadioButton ? 'display: flex; align-items: baseline;' : ''">
              <mat-radio-button *ngIf="(bphChange.value && bphChange.value.hideIndex !== i)"
                                [attr.aria-label]="confChange.label[selectedLanguage]"
                                [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true' || bphChange.disabled || (bphChange.value && bphChange.value.readOnlyIndex === i)"
                                [value]="option.value"
                                name="bphChange"
                                style="padding-bottom: 3px;padding-left: 10px;">
                <span *ngIf="!confChange.enableOnClickOnlyOnRadioButton"
                      class="wrap-mat-radio-label">{{option.label[selectedLanguage]}}</span>
              </mat-radio-button>
              <span *ngIf="confChange.enableOnClickOnlyOnRadioButton"
                    class="wrap-mat-radio-label">{{option.label[selectedLanguage]}}</span>
            </div>
          </div>
        </mat-radio-group>
        <div class="clearfix" *ngIf="bphChange.error[selectedLanguage]">
          <span class="formFieldError">{{ bphChange.error[selectedLanguage]}}</span>
        </div>
      </div>
      <ng-container *ngIf="confChange.additionalMessageValues && confChange.additionalMessage">
        <span style="color:red" *ngIf="showAditionalMessage(confChange.additionalMessageValues)">{{confChange.additionalMessage}}</span>
      </ng-container>
    </div>
  </div>
  <br/>
</div>

<td *ngIf="isValidTable && !row.isTotal " [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
    style="border:0;width: 10%;text-align: center;padding-right:20px">
  <div class="field {{ bphChange.error_class}} hide_{{ bphChange.show}}">
    <div [class.column]="!confChange.inline" [ngClass]="{'inline  fields ui ':confChange.inline}">
      <mat-radio-group (ngModelChange)="change($event,confChange,bphChange)"
                       [(ngModel)]=" bphChange.value.value"
                       [ngClass]="bphChange.error[selectedLanguage] ? 'accentBorder' : 'noColor'">
        <div fxLayout="{{(!confChange.inline || confChange.inline === false) ? 'column' : 'row'}}">
          <ng-container *ngFor="let option of list;let i = index">
            <mat-radio-button *ngIf="(bphChange.value && bphChange.value.hideIndex !== i)"
                              [attr.aria-label]="confChange.label[selectedLanguage]"
                              [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true' || (bphChange.value && bphChange.value.readOnlyIndex === i)"
                              [value]="option.value"
                              name="bphChange"
                              style="padding-bottom: 3px;">
              {{option.label[selectedLanguage]}}
            </mat-radio-button>
          </ng-container>
        </div>
      </mat-radio-group>
    </div>
    <span *ngIf="bphChange.error[selectedLanguage]"
          class="tableFormFieldError">{{ bphChange.error[selectedLanguage]}}</span>

    <ng-container *ngIf="confChange.additionalMessageValues && confChange.additionalMessage">
      <span style="color:red" *ngIf="showAditionalMessage(confChange.additionalMessageValues)">{{confChange.additionalMessage}}</span>
    </ng-container>
  </div>
</td>

<td *ngIf=" isValidTableReadonly && ! bphChange.hideCell && !confChange.hideCell && !row.isTotal "
    [attr.rowspan]=" bphChange.rowspan" [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
    style="border:0;width: 1%;text-align: center">
  <div class="field hide_{{ bphChange.show}} {{ bphChange.error_class}}" fxLayout="column">
    <span>{{getValue(bphChange, selectedLanguage)}}</span>
    <span *ngIf="bphChange.showInfoText">{{bphChange.infoText[selectedLanguage]}}</span>
    <span *ngIf="bphChange.error[selectedLanguage]"
          class="tableFormFieldError">{{ bphChange.error[selectedLanguage]}}</span>
    <ng-container *ngIf="confChange.additionalMessageValues && confChange.additionalMessage">
      <span style="color:red" *ngIf="showAditionalMessage(confChange.additionalMessageValues)">{{confChange.additionalMessage}}</span>
    </ng-container>
  </div>
</td>
