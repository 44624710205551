<mat-dialog-content>
  <div><span [innerHTML]="dialogData[selectedLanguage]" ></span><span style="color: red;">{{applicationId}}</span></div>
  <div *ngIf="DialogTextTwo"><span  [innerHTML]="DialogTextTwo[selectedLanguage]" ></span><span style="color: red;">spcsafm@afm-telethon.fr.</span></div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end" *ngIf="showActionButtons">
  <button class="eCase-dialog-positive-action-button" *ngIf="showConfirmOption"
          style=" margin-right: 15px;" (click)="onYesClick()"
          mat-raised-button>{{'ecase.common.confirm'| translate }}
  </button>
  <button class="eCase-dialog-negative-action-button"
          (click)="onNoClick()"
          mat-raised-button>{{'ecase.common.close'| translate }}
  </button>
</mat-dialog-actions>
