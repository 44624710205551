import {NgModule} from '@angular/core';
import {CustomDateFormatter, LanguageService, SharedModule} from 'synto-common';

import {ECaseCalendarComponent} from './calendar.component';
import {CalendarService} from './calendar.service';
import {ECaseCalendarEventFormDialogComponent} from './event-form/event-form.component';
import {CalendarDateFormatter, CalendarModule, DateAdapter} from 'angular-calendar';
import {TranslateService} from '@ngx-translate/core';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';


registerLocaleData(localeFr);

@NgModule({
  imports: [
    SharedModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
  ],
  declarations: [
    ECaseCalendarComponent,
    ECaseCalendarEventFormDialogComponent
  ],
  providers: [
    CalendarService, TranslateService, LanguageService,
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }

  ],
  entryComponents: [ECaseCalendarEventFormDialogComponent]
})
export class ECaseCalendarModule {
}
