import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'fb-separator',
  templateUrl: './fb-separator.component.html',
  styleUrls: ['./fb-separator.component.css']
})

export class FbSeparatorComponent implements OnInit {
  _CONF__CHANGE_: any;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() globalConf: any;

  constructor() {
    this.confChange = {};
    this.confChange.label = {};
    this.bphChange = {};

  }

  ngOnInit() {
  }

}
