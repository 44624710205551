<div *ngIf="!bphChange.hide && isValidHtml && bphChange.globalShowDetail" [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'">
    <div class="field {{bphChange.error_class}} hide_{{bphChange.show}}" [style.width.%]="confChange.isWidthCustom ? confChange.customWidthInPercentage : '100'">
      <div class="field ui grid">
        <div class="formLabel five wide column righttext">
          <ng-container *ngIf="confChange.condensedLabel && confChange.condensedLabel[selectedLanguage]">
            <span style="font-weight: bolder" [innerHTML]="confChange.condensedLabel[selectedLanguage]"></span>
            <br>
          </ng-container>
          <span *ngIf="confChange.isRequired" style="color: red">* </span>{{confChange.label[selectedLanguage]}}
          <span *ngIf="confChange.hasTooltip" style="padding-top: 10px;">
               <mat-icon  matTooltipClass="tooltip" matTooltip='{{confChange.tooltipText[selectedLanguage]}}' >help_outline</mat-icon>
          </span>
          <span *ngIf="confChange.hasDialog" style="padding-top: 10px;">
             <mat-icon style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;" (click)="openDialog()">help_outline</mat-icon>
          </span>
        </div>

        <div class="nine wide column field">
          <mat-form-field [id]="selectedSectionId + '_' + confChange.name" [ngClass]="bphChange.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid accentBorder' : ''" appearance="outline"
                          style="width: 100%">
            <mat-select (ngModelChange)="change($event,confChange,bphChange)" [(ngModel)]="bphChange.value.value" (opened)="removeHeightConstraint()"
                        [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true' || bphChange.disabled">
              <mat-option>{{getTranslatedValue('ecase.common.pleaseSelect')}}</mat-option>
              <ng-container *ngIf="!confChange.isDynamicList">
                <mat-option *ngFor="let option of removeItemsToHide(searchResult)" [value]="option.value">
                  <ng-container *ngIf="!confChange.isListUpperCase">{{option.label[selectedLanguage]}}</ng-container>
                  <ng-container *ngIf="confChange.isListUpperCase">{{option.label[selectedLanguage] | uppercase}}</ng-container>
                </mat-option>
              </ng-container>
              <ng-container *ngIf="confChange.isDynamicList && bphChange.dynamicList">
                <mat-option *ngFor="let option of bphChange.dynamicList" [value]="option.value">
                  <ng-container *ngIf="!confChange.isListUpperCase">{{option.label[selectedLanguage]}}</ng-container>
                  <ng-container *ngIf="confChange.isListUpperCase">{{option.label[selectedLanguage] | uppercase}}</ng-container>
                </mat-option>
              </ng-container>
              <mat-option *ngIf="confChange.hasOther && !bphChange.doNotShowOther" [value]="otherValue.value">
                {{confChange.customOtherLabel ? confChange.customOtherLabel[selectedLanguage] : getTranslatedValue('ecase.common.other')}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container *ngIf="(confChange.hasOther || confChange.hasOtherTerms) && isOtherValueSelected()">
            <br>
            <label [for]="confChange.blockName + 'other value'"><span *ngIf="confChange.isOtherFieldRequired || confChange.showOtherValueInReadOnlyTable"
                                                                      style="color: red">* </span>{{confChange.otherPlaceholder[selectedLanguage]}}</label>
            <mat-form-field appearance="outline">
              <input [(ngModel)]="bphChange.otherValue" [attr.aria-label]="confChange.otherPlaceholder[selectedLanguage]" [id]="confChange.blockName + 'other value'"
                     matInput [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true' || bphChange.disabled"
                     (input)="changeInputValueEvent($event)"
                     [maxLength]="confChange.otherMsxlength"
                     type="text"/>
            </mat-form-field>
            <div class="field">
              <p  class="counter-style">
                {{getTextLength(bphChange.otherValue)}} / {{confChange.otherMsxlength}}
                {{getTranslatedValue('ecase.common.characters')}}
              </p>
            </div>
            <div style="clear: right"></div>
          </ng-container>
          <div [style.margin-top]="bphChange?.error[selectedLanguage] ? '-20px' : '0'">
            <span *ngIf="bphChange?.error[selectedLanguage]"
                  class="tableFormFieldError">{{bphChange.error[selectedLanguage]}}</span>
          </div>
        </div>
      </div>
    </div>
</div>

<td *ngIf="isValidTable && !row.isTotal "
    [id]="'td_' + confChange.name + '_' + rowNumber" [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
    class="selectClearPadding"
    style="border:0;width: 10%;text-align: center;padding-right:10px;padding-left:10px;"
    [style.padding-bottom]="confChange.paddingAlignment ? '10px' : ''">
  <div class="field {{bphChange.error_class}} hide_{{bphChange.show}}">

    <mat-form-field [id]="selectedSectionId + '_' + confChange.name + '_' + rowNumber"
                    [ngClass]="bphChange.error[selectedLanguage] && (bphChange.value.value !== otherValue.value) ? 'ng-invalid mat-form-field-invalid' : ''"
                    appearance="outline">
      <mat-select (ngModelChange)="change($event,confChange,bphChange)" (opened)="removeHeightConstraint()"
                  [(ngModel)]="bphChange.value.value"
                  [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true' || searchResult.length === 0 || bphChange.disabled">
        <mat-option>{{getTranslatedValue('ecase.common.pleaseSelect')}}</mat-option>
        <ng-container *ngIf="!confChange.isDynamicList">
          <mat-option *ngFor="let option of removeItemsToHide(searchResult)" [value]="option.value">
            <ng-container *ngIf="!confChange.isListUpperCase">{{option.label[selectedLanguage]}}</ng-container>
            <ng-container *ngIf="confChange.isListUpperCase">{{option.label[selectedLanguage] | uppercase}}</ng-container>
          </mat-option>
        </ng-container>
        <ng-container *ngIf="confChange.isDynamicList && bphChange.dynamicList">
          <mat-option *ngFor="let option of bphChange.dynamicList" [value]="option.value">
            <ng-container *ngIf="!confChange.isListUpperCase">{{option.label[selectedLanguage]}}</ng-container>
            <ng-container *ngIf="confChange.isListUpperCase">{{option.label[selectedLanguage] | uppercase}}</ng-container>
          </mat-option>
        </ng-container>
        <mat-option *ngIf="confChange.hasOther && !bphChange.doNotShowOther"
                    [value]="otherValue.value">{{confChange.customOtherLabel ? confChange.customOtherLabel[selectedLanguage] : getTranslatedValue('ecase.common.other')}}</mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="(confChange.hasOther || confChange.hasOtherTerms) && !bphChange.doNotShowOther && isOtherValueSelected()">
      <br>
      <label [for]="confChange.blockName + 'other value'"><span *ngIf="confChange.isOtherFieldRequired || confChange.showOtherValueInReadOnlyTable"
                                                                style="color: red">* </span>{{confChange.otherPlaceholder[selectedLanguage]}}
      </label>
      <mat-form-field [ngClass]="bphChange.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''"
                      appearance="outline">
        <input [(ngModel)]="bphChange.otherValue" [attr.aria-label]="confChange.otherPlaceholder[selectedLanguage]" [id]="confChange.blockName + 'other value'"
               matInput [disabled]="confChange.isReadOnlyField || bphChange.globalReadOnlyMode === 'true' || bphChange.disabled"
               (input)="changeInputValueEvent($event)"
               [attr.maxlength]="confChange.otherMsxlength"
               type="text"/>
      </mat-form-field>
      <p  class="counter-style">
        {{getTextLength(bphChange.otherValue)}} / {{confChange.otherMsxlength}}
        {{'ecase.common.characters' | translate}}</p>
    </ng-container>
    <span *ngIf="bphChange?.error[selectedLanguage]" class="formFieldError">{{bphChange.error[selectedLanguage]}}</span>
  </div>
</td>


<!--This <td> is not used anymore for select refer to fb-table.component.html and look in select template-->
<td *ngIf="isValidTableReadonly && !bphChange.hideCell && !confChange.hideCell  && !row.isTotal "
    [attr.rowspan]="bphChange.rowspan"
    [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
    style="border:0;width: 10%;text-align: center;padding-left:10px;padding-right:10px">
  <div class="field {{bphChange.error_class}} hide_{{bphChange.show}}">
    {{getValue(bphChange, selectedLanguage)}}
    <span *ngIf="bphChange?.error[selectedLanguage]"
          class="tableFormFieldError">{{bphChange.error[selectedLanguage]}}</span>
  </div>
</td>
