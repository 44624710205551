import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {
  CrmPersonSearchService,
  eCaseAnimations,
  ECaseDataTable,
  ECaseSnackBarService,
  ECaseUtils,
  ParametersService
} from "synto-common";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CrmPersonSearchDialogComponent} from "../../crm-person-search-dialog/crm-person-search-dialog.component";
import {Subscription} from "rxjs/internal/Subscription";

/**
 * Created by sahilb@evision.ca on 2022-02-23.
 */
@Component({
  selector: 'app-add-new-signer-dialog',
  templateUrl: './add-new-signer-dialog.component.html',
  styleUrls: ['./add-new-signer-dialog.component.scss'],
  animations: eCaseAnimations
})
export class AddNewSignerDialogComponent extends ECaseDataTable implements OnInit, OnDestroy {

  displayedColumns = ['email', 'name', 'action'];
  filterObject = {
    'email': '',
    'name': ''
  }
  externalSigners: any[] = [];
  internalSigners: any[] = [];
  addNewSignerForm: FormGroup;
  subscriptions: Subscription[] = [];
  @Input() existingSigner: any[];

  constructor(public dialogRef: MatDialogRef<AddNewSignerDialogComponent>, translate: TranslateService,
              public crmPersonSearchService: CrmPersonSearchService, parameterService: ParametersService,
              private dialog: MatDialog, private formBuilder: FormBuilder, private eCaseSnackBarService: ECaseSnackBarService) {
    super(translate, parameterService);
    this.dialogRef.disableClose = true;
    this.externalSigners = this.externalSigners ? this.externalSigners : [];
    this.serviceArray = this.externalSigners;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  ngOnInit(): void {
    const obj = {
      'internalUserEmails': ['']
    };
    this.addNewSignerForm = this.formBuilder.group(obj);
    this.serviceArray = this.externalSigners;
    this.originalArray = this.externalSigners;
    this.setDataSourceAttributes();
  }

  getTextLength(l): number {
    try {
      return this.addNewSignerForm.controls[l].value ? this.addNewSignerForm.controls[l].value.length : 0;
    } catch (e) {
      return 0;
    }
  }

  addInternalUser(): void {
    this.crmPersonSearchService.isUserSearch = true;
    const dialogRef = this.dialog.open(CrmPersonSearchDialogComponent, {
      width: '800px'
    });
    dialogRef.afterClosed().subscribe((decision) => {
      if (decision) {
        const newUsers: any[] = decision.map(user => {
          return {
            'email': user.username,
            'name': user.fullName,
            'ssoUserId': user.ssoUserId,
          }
        });
        if (this.internalSigners.filter(user => newUsers.some(e => e.email === user.email)).length > 0) {
          this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.signeduseralreadyexists'));
        } else {
          this.internalSigners = this.internalSigners.concat(newUsers);
          this.addNewSignerForm.controls.internalUserEmails.patchValue(this.internalSigners.map(e => e.ssoUserId));
        }
      }
    });
  }

  removeUser(ssoUserId: number): void {
    this.internalSigners = this.internalSigners.filter(item => item.ssoUserId !== ssoUserId);
    this.addNewSignerForm.controls.internalUserEmails.patchValue(this.internalSigners.map(e => e.ssoUserId));
  }

  addExternalUser(): void {
    this.externalSigners.push({
      'name': '',
      'email': ''
    });
    this.reBuildForm();
  }

  reBuildForm(): void {
    const obj = {
      'internalUserEmails': [this.addNewSignerForm.get('internalUserEmails').value]
    };
    this.externalSigners.forEach((user, index) => {
      obj['email' + (index + 1)] = [user.email, [Validators.required, Validators.email]];
      obj['name' + (index + 1)] = [user.name, Validators.required];
    });
    this.addNewSignerForm = this.formBuilder.group(obj);
    this.ngOnDestroy();
    this.subscriptions = [];
    this.externalSigners.forEach((user, index) => {
      this.subscriptions.push(this.addNewSignerForm.get('name' + (index + 1)).valueChanges.subscribe((newValue) => {
        this.externalSigners[index].name = newValue;
      }));
      this.subscriptions.push(this.addNewSignerForm.get('email' + (index + 1)).valueChanges.subscribe((newValue) => {
        this.externalSigners[index].email = newValue;
      }));
    });
    this.serviceArray = this.externalSigners;
    this.originalArray = this.externalSigners;
    this.setDataSourceAttributes();
  }

  delete(email: string): void {
    this.externalSigners = this.externalSigners.filter(user => user.email !== email);
    this.reBuildForm();
  }

  onConfirm(): void {
    let isUsernameDuplicated = false;
    const group = ECaseUtils.groupBy(this.externalSigners, item => item.username);
    for (const [key, value] of group) {
        if (value.length > 1) {
          isUsernameDuplicated = true;
          break;
        }
    }
    if (this.existingSigner.some(user => this.internalSigners.some(_user => _user.email.trim() === user.email.trim())) ||
      this.existingSigner.some(user => this.externalSigners.some(_user => _user.email.trim() === user.email.trim()))) {
      this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.signeduseralreadyexists'));
    } else {
      if (isUsernameDuplicated) {
        this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.externalemailsduplicated'));
      } else if (this.internalSigners.some(internalSigner => this.externalSigners.some(externalSigner => externalSigner.email.trim() === internalSigner.email.trim()))) {
        this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.internalexternalemailsduplicated'));
      } else {
        const obj = {
          'internalUsers': this.internalSigners,
          'externalUsers': this.externalSigners
        }
        this.dialogRef.close(obj);
      }
    }
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

}

