import {Component, Input, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'fb-output',
  templateUrl: './fb-output.component.html',
  styleUrls: ['./fb-output.component.css']
})
export class FbOutputComponent implements OnInit {

  _CONF__CHANGE_: any;
  lang: any;
  @Input() selectedLanguage;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() bphChange: any;
  @Input() confChange: any;


  row: any = {};

  constructor(private translate: TranslateService) {
    this.confChange = {};
    this.isValidHtml = true;
    this.isValidTable = false;
    this.isValidTableReadonly = false;
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.value.label = {};
    this.confChange.label = {};
    this.bphChange.error = {};
    this.bphChange.source = {};
  }

  getOutput(label, lang) {
    const r = '';
    try {
      eval(' this.prj;');
      eval('r = this.prj.' + label);
    } catch (e) {
      console.warn(e);
    }
    return r;
  }

  getOutput2(label, lang, i) {
    const r = '';
    try {
      eval(' this.prj;');
      eval('r = this.prj.' + label);
    } catch (e) {
      console.warn(e);
    }
    return r;
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;

    });

    eval('this.bphChange={};\n' +
      'this.confChange.initValue =function(currentValue,localConf){\n' +
      '\t\n' +
      '}');
  }

}
