import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from '../dialog/dialog.component';
import {ECaseUtils, PendingChangesGuard, RefreshDataService} from 'synto-common';

@Component({
  selector: 'fb-text-area',
  templateUrl: './fb-text-area.component.html',
  styleUrls: ['./fb-text-area.component.scss']
})
export class FbTextAreaComponent implements OnInit {
  @Input() selectedLanguage: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() globalPrj: any;
  @Input() selectedSection: string;
  @Output() emitOutputEvent = new EventEmitter<any>();
  @Output() newTitle = new EventEmitter<any>();
  isValid: boolean;
  row: any;
  lang: any;
  id;
  height = 154;
  regexPattern: RegExp;


  constructor(private translate: TranslateService,
              private refreshDataService: RefreshDataService,
              private pendingChangesGuard: PendingChangesGuard,
              public dialog: MatDialog) {
    this.confChange = {};
    this.isValid = false;
    this.bphChange = {};
    this.bphChange.value = {};
    this.confChange.label = {};
    this.bphChange.error = {};
    this.row = {};
  }

  openDialog(): void {
    this.dialog.open(DialogComponent, {
      width: '600px',
      data:
        {
          dialog: this.confChange.dialogText,
          selectedLanguage: this.selectedLanguage
        }
    });
  }

  getTranslatedValue(key): string {
    return ECaseUtils.getTranslatedValueFromKey(this.translate, key, this.selectedLanguage);
  }

  ngOnInit(): void {
    if (!(this.bphChange)) {
      this.bphChange = {};
      this.bphChange.value = '';
      this.bphChange.error = {};
      this.bphChange.source = {};
    }


    this.bphChange.getValue = (): any => this.bphChange.value;
    if (!this.bphChange.error) {
      this.bphChange.error = {};
    }
    if (!this.confChange.textAlignment) {
      this.confChange.textAlignment = 'left';
    }

    if (!this.bphChange.value) {
      this.bphChange.value = '';
    }

    if (this.confChange) {
      this.confChange.initValue = (currentValue): void => {
        currentValue.value = '';
        currentValue.error = {};
        currentValue.getValue = (): any => this.bphChange.value;
      };
    }
    /* this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
       this.selectedLanguage = params.lang;

     });*/
    console.log('*************************************');
    console.log(this.confChange);
  }

  getTextLength(l): number {
    try {
      const myCharacters = l.replace(/(\r\n|\n|\r)/gm, '');
      return (l === '' ? 0 : myCharacters.length);
    } catch (e) {
      return 0;
    }
  }

  getWordCount(str): number {
    try {
      const myWords = str.trim().split(new RegExp('\\s+'));
      return (str === '' ? 0 : myWords.length);
    } catch (e) {
      return 0;
    }
  }

  changeValueEvent($event): void {
    console.log('inside change value event function');
    if (this.confChange.regexCode) {
      this.regexPattern = new RegExp(this.confChange.regexCode, 'g');
      this.bphChange.value = this.bphChange.value.replace(this.regexPattern, '');
    }

    if (this.confChange.countUnit === 'ecase.common.words') {
      if (this.getWordCount(this.bphChange.value) >= this.confChange.maxlength) {
        this.bphChange.value = this.bphChange.value.split(new RegExp('\\s+')).slice(0, this.confChange.maxlength).join(' ');
      }
    } else {
      if (this.getTextLength(this.bphChange.value) >= this.confChange.maxlength) {
        const difference = (this.bphChange.value.replace(/(\r\n|\n|\r)/gm, '')).length - this.confChange.maxlength;
        this.bphChange.value = (difference !== 0) ? this.bphChange.value.slice(0, -difference) : this.bphChange.value;
      }
    }

    if (this.confChange.isProjectTitle) {
      if (!this.globalPrj.formData) {
        this.globalPrj.formData = {};
      }
      this.globalPrj.formData['projectTitle'] = this.bphChange.value;
      this.globalPrj.formData['applicationTitle'] = this.bphChange.value;
      this.globalPrj.applicationTitle = this.bphChange.value;
      this.globalPrj.projectTitle = this.bphChange.value;
      this.newTitle.emit(this.bphChange.value);
    }

    if (this.confChange.isprojectNickName) {
      if (!this.globalPrj.formData) {
        this.globalPrj.formData = {};
      }
      this.globalPrj.formData['projectNickname'] = this.bphChange.value;
    }
    this.emitOutputEvent.emit({
      'event' : $event,
      'bphChange': this.bphChange,
      'confChange': this.confChange,
      'prj': this.globalPrj,
      'conf': this.globalConf,
      'refreshDataService': this.refreshDataService
    });
    this.refreshDataService.setNewValueForStaticText(this.confChange.name, this.bphChange.value);
    this.pendingChangesGuard.isPristine = false;
  }

  focusInFunction(): void {
    this.id = this.selectedSection + '_' + this.confChange.name;
    const element = document.getElementById(this.id);

    if (this.bphChange.focusInHeight && this.bphChange.focusInHeight !== this.height) {
      element.style.height = this.bphChange.focusInHeight.toString() + 'px';
    } else {
      this.bphChange.focusInHeight = this.height;
    }

  }

  focusOutFunction(): void {
    const element = document.getElementById(this.id);
    this.bphChange.focusInHeight = document.getElementById(this.id).offsetHeight;
    element.style.height = this.bphChange.focusInHeight.toString() + 'px';
  }
}
