<mat-form-field appearance="{{ appearance }}" class="mat-form-field" style="margin-top: 0% !important;">
  <mat-chip-list #chipList>
    <mat-chip *ngFor="let item of selectedItems" [selectable]="selectable" [removable]="removable" (removed)="remove(item)">
      {{ item[labelObjName][selectedLang] }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>

    <input type="text" #inputTrigger [placeholder]="placeholder" matInput
           [matAutocomplete]="autoComplete"
           class="inputSearchDynamicAutoComplete"
           (keyup)="filter()"
           [matChipInputFor]="chipList"
           [(ngModel)]="searchStringField">
  </mat-chip-list>
  <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="toggleSelection($event)">
    <mat-option *ngIf="filteredOptions.length > 1" >
      <mat-checkbox [checked]="isAllSelected" (change)="toggleSelectAll()" (click)="$event.stopPropagation()">
        Select All
      </mat-checkbox>
    </mat-option>
    <mat-option *ngFor="let item of filteredOptions" [value]="item[labelIdName]">
      <div>
        <mat-checkbox [checked]="item.selected">
          {{ item[labelObjName][selectedLang] }}
        </mat-checkbox>
      </div>
    </mat-option>
  </mat-autocomplete>
  <mat-hint style="color:red" *ngIf="showErrorMsg">{{ errorMsg }}</mat-hint>
</mat-form-field>
