<mat-form-field appearance="{{ appearance }}" class="mat-form-field" style="margin-top: 0% !important;">
  <mat-select
    #selectElem
    [placeholder]="placeholder"
    [formControl]="formControlInput"
    [multiple]="multiple"
    [(ngModel)]="selectedValue"
    (selectionChange)="onSelectionChange($event)"
  >
    <div class="box-search">
      <button mat-icon-button class="search-button">
      <mat-icon class="mat-24" aria-label="Search icon">search</mat-icon></button>
<!--      <mat-checkbox-->
<!--        *ngIf="multiple"-->
<!--        color="primary"-->
<!--        class="box-select-all"-->
<!--        [(ngModel)]="selectAllChecked"-->
<!--        (change)="toggleSelectAll($event)"-->
<!--      ></mat-checkbox>-->
      <input
        #searchInput
        type="text"
        [ngClass]="{ 'pl-1': !multiple }"
        [(ngModel)]="searchFieldValue"
        (input)="filterItem(searchInput.value)"
        [placeholder]="selectPlaceholder | eCaseTranslate"
      />
      <div
        class="box-search-icon"
        (click)="filterItem(''); searchInput.value = ''"
      >
        <button mat-icon-button class="search-button">
          <mat-icon class="mat-24" aria-label="Search icon">clear</mat-icon>
        </button>
      </div>
    </div>
    <mat-select-trigger>
      {{ onDisplayString() }}
    </mat-select-trigger>
    <mat-option
      *ngFor="let option of options; trackBy: trackByFn"
      [disabled]="option.disabled"
      [value]="option.id"
      [style.display]="hideOption(option) ? 'none' : 'flex'"
    >{{ option.fullName| eCaseTranslate:false }}
    </mat-option>
  </mat-select>
  <mat-hint style="color:red" *ngIf="showErrorMsg">{{ errorMsg }}</mat-hint>
</mat-form-field>
