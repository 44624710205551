import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'fb-objectives',
  templateUrl: './fb-objectives.component.html',
  styleUrls: ['./fb-objectives.component.css']
})
export class FbObjectivesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
