<div *ngIf="isValidHtml" [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'">
  <div *ngIf="bphChange.globalShowDetail">
    <div *ngIf="!bphChange.hide" class="ui form field {{bphChange.error_class}}">
      <div class="ui grid field">
        <div class="five wide column righttext">
          <ng-container *ngIf="confChange.condensedLabel && confChange.condensedLabel[selectedLanguage]">
            <span style="font-weight: bolder" [innerHTML]="confChange.condensedLabel[selectedLanguage]"></span>
            <br>
          </ng-container>
          <span *ngIf="confChange.isRequired" style="color: red">* </span>
          <span [innerHTML]="confChange.label[selectedLanguage]"></span>
          <fb-tooltip [lang]="selectedLanguage" [tooltipText]="confChange.tooltipText"
                      *ngIf="confChange.hasTooltip"></fb-tooltip>
          <label [lang]="selectedLanguage" matTooltip="confChange.tooltipText"
                 *ngIf="confChange.hasTooltip"></label>
        </div>
        <div class="nine wide column field">
          <quill-editor [id]="id"  (focusout)="focusOutFunction()" (focusin)="focusInFunction()" (onContentChanged)="saveChanges($event)" (onEditorCreated)="onEditorCreated($event)"
                        [(ngModel)]="bphChange.value">
            <div quill-editor-toolbar>
            <span class="ql-formats">
            <!-- SYNTODEV-5178 -->
              <button class="ql-bold" [title]="'Bold'"></button>
            <button class="ql-italic" [title]="'Italic'"></button>
            <button class="ql-underline" [title]="'Underline'"></button>
            <button class="ql-blockquote" [title]="'blockquote'"></button>
            <!--<button class="ql-code-block" [title]="'code-block'"></button>-->
            <button class="ql-script" value="sub"></button>
            <button class="ql-script" value="super"></button>
            </span>
              <span class="ql-formats">
                <select class="ql-align" [title]="'Alignment'">
                  <option selected></option>
                  <option value="center"></option>
                  <option value="right"></option>
                  <option value="justify"></option>
                </select>
              <select class="ql-size">
                  <option value="small"></option>
                  <option selected></option>
                  <option value="large"></option>
                  <option value="huge"></option>
               </select>
                <button class="ql-clean" [title]="'clean'"></button>
                <!--<button class="ql-link" [title]="'link'"></button>
                <button class="ql-image" [title]="'image'"></button>
                <button class="ql-video" [title]="'video'"></button>
                <select class="ql-header">
                  <option value="1"></option>
                  <option value="2"></option>
                  <option value="3"></option>
                  <option value="4"></option>
                  <option value="5"></option>
                  <option value="6"></option>
                  <option selected></option>
                </select>-->
              </span>
            </div>
          </quill-editor>


          <div id="counter"></div>
          <label style="float: left; font-weight: 700;">{{bphChange.error[selectedLanguage]}}</label>
        </div>
      </div>
    </div>
  </div>
</div>


<td style="border:0;width: 10%;text-align: center;padding-right:20px"
    *ngIf="isValidTable && !row.isTotal && !bphChange.hideCell  && !confChange.hideCell"
    [attr.rowspan]="bphChange.rowspan">
  <div class="field hide_{{bphChange.show}} {{bphChange.error_class}}">
    {{bphChange.value.label[selectedLanguage]}}
    <label>{{bphChange.error[selectedLanguage]}}</label>
  </div>
</td>


<td *ngIf="isValidTableReadonly && !row.isTotal">
  <div *ngIf="!bphChange.hide " class="ui form field {{bphChange.error_class}}">
    <div class="ui grid field">
      <label class="five wide column righttext"><span *ngIf="confChange.isRequired" style="color: red">* </span>{{confChange.label[selectedLanguage]}}
        <fb-tooltip [lang]="selectedLanguage" [tooltipText]="confChange.tooltipText"
                    *ngIf="confChange.hasTooltip == true"></fb-tooltip>
        <label [lang]="selectedLanguage" matTooltip="confChange.tooltipText"
               *ngIf="confChange.hasTooltip == true"></label>
      </label>
      <div class="nine wide column field">
        <!-- quill js container -->
        <div id="editor-container">
        </div>
        <label style="float: left; font-weight: 700;">{{bphChange.error[selectedLanguage]}}</label>
      </div>
    </div>
  </div>
</td>




