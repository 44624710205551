<div *ngIf="!bphChange.hide && !confChange.showDetails"
     [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
     class="{{bphChange.error_class}} hide_{{bphChange.show}}"
     style="margin-bottom: 1.34375em">
  <div class="formLabel">
    <ng-container *ngIf="confChange.condensedLabel && confChange.condensedLabel[selectedLanguage]">
      <span style="font-weight: bolder" [innerHTML]="confChange.condensedLabel[selectedLanguage]"></span>
      <br>
    </ng-container>
    <span [innerHTML]="confChange.label[selectedLanguage]"></span>
    <span *ngIf="confChange.hasTooltip" style="padding-top: 10px;">
             <mat-icon matTooltip="{{confChange.tooltipText[selectedLanguage]}}"
                       matTooltipClass="tooltip">help_outline</mat-icon>
          </span>

    <span *ngIf="confChange.hasDialog" style="padding-top: 10px;">
             <mat-icon (click)="openDialog()"
                       style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;">help_outline</mat-icon>
          </span>
  </div>

  <mat-accordion>
    <ng-container *ngFor="let row of bphChange.rows;let i = index;">
      <mat-expansion-panel (opened)="row.panelOpenState = true; bphChange.selectedRow = row; bphChange.selectedRow.index = i"
                           [expanded]="bphChange.rows.length > 1 ? row.panelOpenState : true"
                           [disabled]="bphChange.rows.length === 1"
                           (closed)="row.panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon  *ngIf="bphChange.rows.length > 1" >{{row.panelOpenState ? 'remove_circle_outline' : 'add_circle_outline'}}</mat-icon>
            <span style="margin-left: 10px; color: rgba(0, 0, 0, 0.87);">{{confChange.isAccordionLabelParametrized ? getAccordionLabel(i) : confChange?.accordionLabel[selectedLanguage]}}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container *ngFor="let subBlock of confChange.subBlocks;last as isLast;let j=index;">
          <ng-container [ngSwitch]="subBlock.templateName">
            <ng-container *ngSwitchCase="'statictext'">
              <ng-container *ngTemplateOutlet="statictext; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'textarea'">
              <ng-container *ngTemplateOutlet="textarea; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'Radiobutton'">
              <ng-container *ngTemplateOutlet="Radiobutton; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'Checkbox'">
              <ng-container
                *ngTemplateOutlet="Checkbox; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'select'">
              <ng-container *ngTemplateOutlet="select; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
            </ng-container>
            <!--  <ng-container *ngSwitchCase="'Container'">
                <ng-container *ngTemplateOutlet="Container; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'Table'">
                <ng-container *ngTemplateOutlet="Table; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
              </ng-container>-->
            <ng-container *ngSwitchCase="'datepicker'">
              <ng-container *ngTemplateOutlet="datepicker; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'SubmitButton'">
              <ng-container *ngTemplateOutlet="SubmitButton; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'upload'">
              <ng-container
                *ngTemplateOutlet="upload; context: {'templateContext' : subBlock.templateContext , rowIndex : i, 'blockIndex' : (blockIndex + ((j + 1) / 100))}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'text'">
              <ng-container *ngTemplateOutlet="text; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'Signature'">
              <ng-container *ngTemplateOutlet="Signature; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'AutoComplete'">
              <ng-container *ngTemplateOutlet="AutoComplete; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'button'">
              <ng-container
                *ngTemplateOutlet="button; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
            </ng-container>

            <ng-container *ngSwitchDefault></ng-container>
          </ng-container>
        </ng-container>

      </mat-expansion-panel>

    </ng-container>
  </mat-accordion>

  <span *ngIf="bphChange.error[selectedLanguage]" class="tableSpanError">{{bphChange.error[selectedLanguage]}}</span>

</div>

<ng-template #AutoComplete  let-confChange="templateContext.confChange" let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable" let-rowIndex="rowIndex" let-isValidTableReadonly="templateContext.isValidTableReadonly">
  <fb-autoComplete
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(rowIndex, confChange, (globalPrj[selectedSectionId])[containerConfChange.name][confChange.name]) : doNothing()'
    [bphChange]='(bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [rowNumber]='rowIndex'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    style='position: static;'>
  </fb-autoComplete>
</ng-template>
<ng-template #upload let-blockIndex="blockIndex" let-blockName="blockName" let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-upload
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(rowIndex, confChange,(bphChange.rows[rowIndex])[confChange.name]) : doNothing()'
    [blockIndex]="blockIndex" [bphChange]='(bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [rowNumber]='rowIndex'
    style='position: static;padding-right:20px;'>
  </fb-upload>
</ng-template>

<ng-template #Signature  let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-rowIndex="rowIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly">
  <fb-signature  [bphChange]='(bphChange.rows[rowIndex])[confChange.name]'
                 [confChange]='confChange'
                 [globalConf]='globalConf'
                 [globalPrj]='globalPrj'
                 [rowNumber]="rowIndex"
                 [isValidHtml]='isValidHtml'
                 [isValidTableReadonly]='isValidTableReadonly'
                 [isValidTable]='isValidTable'
                 [selectedLanguage]='selectedLanguage'
                 [selectedSectionId]='selectedSectionId'
                style='position: static;'>
  </fb-signature>
</ng-template>

<ng-template #text let-blockName="blockName"  let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-rowIndex="rowIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly">
  <fb-text
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(rowIndex,confChange,(bphChange.rows[rowIndex])[confChange.name]) : doNothing()'
    [bphChange]='(bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [rowNumber]="rowIndex"
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    style='position: static;padding-right:20px;'>
  </fb-text>
</ng-template>
<ng-template #button let-confChange="templateContext.confChange"
             let-isLastPosition="isLastPosition"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-button
    [bphChange]='(bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [rowNumber]="rowIndex"
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    [isFormbuilderModeEnabled]="isFormbuilderModeEnabled"
    [isLastPosition]="isLastPosition"
    [offlineModeEnabled]='offlineModeEnabled'
    style='position: static;'></fb-button>
</ng-template>
<ng-template #SubmitButton  let-confChange="templateContext.confChange" let-isLast="isLast"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-rowIndex="rowIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly">
  <fb-submit-button [bphChange]='(bphChange.rows[rowIndex])[confChange.name]' [confChange]='confChange'
                    [globalConf]='globalConf'
                    [globalPrj]='globalPrj'
                    [isFormbuilderModeEnabled]="isFormbuilderModeEnabled"
                    [isValidHtml]='isValidHtml'
                    [isValidTableReadonly]='isValidTableReadonly'
                    [isValidTable]='isValidTable'
                    [selectedLanguage]='selectedLanguage'
                    [selectedSectionId]='selectedSectionId'
                    [style.padding-right.px]="isLast ? 0 : 20"
                    style='position: static;'></fb-submit-button>
</ng-template>
<ng-template #statictext  let-confChange="templateContext.confChange" let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable" let-rowIndex="rowIndex" let-isValidTableReadonly="templateContext.isValidTableReadonly">
  <fb-static-text [bphChange]='(bphChange.rows[rowIndex])[confChange.name]'
                  [confChange]='confChange'
                  [globalConf]='globalConf'
                  [globalPrj]='globalPrj'
                  [isValidHtml]='isValidHtml'
                  [isValidTableReadonly]='isValidTableReadonly'
                  [isValidTable]='isValidTable'
                  [selectedLanguage]='selectedLanguage'
                  style='position: static;'>
  </fb-static-text>
</ng-template>
<ng-template #textarea let-blockName="blockName"  let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable" let-rowIndex="rowIndex" let-isValidTableReadonly="templateContext.isValidTableReadonly">
  <fb-text-area
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(rowIndex,confChange,(bphChange.rows[rowIndex])[confChange.name]) : doNothing()'
    [bphChange]='(bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    style='position: static;'>
  </fb-text-area>
</ng-template>
<ng-template #Radiobutton let-blockName="blockName"  let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable" let-rowIndex="rowIndex" let-isValidTableReadonly="templateContext.isValidTableReadonly">

  <fb-radiobutton (emitOutputEvent)="executeFunction(rowIndex,confChange,(bphChange.rows[rowIndex])[confChange.name])"
                  [bphChange]='(bphChange.rows[rowIndex])[confChange.name]'
                  [confChange]='confChange'
                  [globalConf]='globalConf'
                  [globalPrj]='globalPrj'
                  [rowNumber]='rowIndex'
                  [isValidHtml]='isValidHtml'
                  [isValidTableReadonly]='isValidTableReadonly'
                  [isValidTable]='isValidTable'
                  [selectedLanguage]='selectedLanguage'
                  style='position: static;'>
  </fb-radiobutton>
</ng-template>
<ng-template #Checkbox let-blockName="blockName"  let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml" let-isValidTable="templateContext.isValidTable" let-rowIndex="rowIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly">
  <fb-checkbox (emitOutputEvent)="executeFunction(rowIndex,confChange,(bphChange.rows[rowIndex])[confChange.name])"
               [bphChange]='(bphChange.rows[rowIndex])[confChange.name]'
               [confChange]='confChange'
               [globalConf]='globalConf'
               [globalPrj]='globalPrj'
               [isValidHtml]='isValidHtml'
               [isValidTableReadonly]='isValidTableReadonly'
               [isValidTable]='isValidTable'
               [selectedLanguage]='selectedLanguage'
               style='position: static;'>
  </fb-checkbox>
</ng-template>

<ng-template #select  let-confChange="templateContext.confChange" let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable" let-rowIndex="rowIndex" let-isValidTableReadonly="templateContext.isValidTableReadonly">
  <fb-select (emitOutputEvent)="executeFunction(rowIndex,confChange,(bphChange.rows[rowIndex])[confChange.name])"
             [bphChange]='(bphChange.rows[rowIndex])[confChange.name]'
             [confChange]='confChange'
             [globalConf]='globalConf'
             [globalPrj]='globalPrj'
             [rowNumber]="rowIndex"
             [isValidHtml]='isValidHtml'
             [isValidTableReadonly]='isValidTableReadonly'
             [isValidTable]='isValidTable'
             [selectedLanguage]='selectedLanguage'
             [selectedSectionId]='selectedSectionId'
             style='position: static;'>
  </fb-select>
</ng-template>
<ng-template #datepicker let-blockName="blockName"  let-confChange="templateContext.confChange" let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable" let-rowIndex="rowIndex" let-isValidTableReadonly="templateContext.isValidTableReadonly">
  <fb-datepicker
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(rowIndex,confChange,(bphChange.rows[rowIndex])[confChange.name]) : doNothing()'
    [bphChange]='(bphChange.rows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    style='position: static;'>
  </fb-datepicker>
</ng-template>
