<ng-container *ngIf="isLoading">
  <div style="margin: 0 auto">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-container>
<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="isSearch">
    <div *ngIf="displayForm">
      <mat-card-title>{{'ecase.common.component.crmPersonSearch.personSearch.title'| eCaseTranslate}}</mat-card-title>

      <div style="font-size: 1.1em; margin-bottom: 25px">
        {{'ecase.common.component.crmPersonSearch.subtitle.text'| eCaseTranslate}}
      </div>


      <mat-card-content style="margin-bottom: 20px;" >
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
          <div  class="action_buttons">
            <button mat-raised-button (click)="back()"
                    class="eCase-dialog-negative-action-button"
                    [attr.aria-label]="'ecase.common.component.crmPersonSearch.table.button.cancel.label' | eCaseTranslate"
            >{{'ecase.common.component.crmPersonSearch.table.button.cancel.label' | eCaseTranslate }}
            </button>
          </div>
        </mat-dialog-actions>

        <form [formGroup]="searchCrmPersonForm" (submit)="searchCrmPerson()" autocomplete="off" class="displaySearchContainerPerson">

          <div>
            <label>{{'ecase.common.component.crmPersonSearch.form.name.label'| eCaseTranslate}}</label>
            <mat-form-field appearance="outline" >
              <input autocomplete="off" id="ecase_common_crm_person_name" matInput
                     [formControlName]="'name'"
                     [attr.aria-label]="'ecase.common.component.crmPersonSearch.form.name.label'| eCaseTranslate">
            </mat-form-field>
          </div>

          <div>
            <label>{{'ecase.common.component.crmPersonSearch.form.phone.label'| eCaseTranslate}}</label>
            <mat-form-field appearance="outline" >
              <input autocomplete="off" id="ecase_common_crm_person_phoneNumber" matInput
                     [formControlName]="'phoneNumber'"
                     [attr.aria-label]="'ecase.common.component.crmPersonSearch.form.phone.label'| eCaseTranslate">
            </mat-form-field>
          </div>


          <div>
            <label>{{'ecase.common.component.crmPersonSearch.form.email.label'| eCaseTranslate}}</label>
            <mat-form-field appearance="outline" >
              <input autocomplete="off" id="ecase_common_crm_person_emailAddress" matInput
                     [formControlName]="'email'"
                     [attr.aria-label]="'ecase.common.component.crmPersonSearch.form.email.label'| eCaseTranslate">
            </mat-form-field>
          </div>

          <div>
            <label>{{'ecase.common.component.crmPersonSearch.form.country.label'| eCaseTranslate}}</label>
            <mat-form-field appearance="outline">
              <mat-select id="ecase_common_crm_person_country'"
                          [formControlName]="'country'"
                          (selectionChange)="onCountryChange($event)"
                          [attr.aria-label]="'ecase.common.component.crmPersonSearch.form.country.label'| eCaseTranslate">
                <mat-option>{{'ecase.common.pleaseSelect' | eCaseTranslate }}</mat-option>
                <mat-option *ngFor="let co of  countryLov" [value]="co.id">{{co.value[selectedLang]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <label>{{'ecase.common.component.crmPersonSearch.form.application.label'| eCaseTranslate}}</label>
            <mat-form-field appearance="outline" >
              <input autocomplete="off" id="ecase_common_crm_person_applicationNumber'" matInput
                     [formControlName]="'applicationNumber'"
                     type="number"
                     [attr.aria-label]="'ecase.common.component.crmPersonSearch.form.application.label'| eCaseTranslate">
            </mat-form-field>
          </div>


          <div>
            <label>{{'ecase.common.component.crmPersonSearch.form.province.label'| eCaseTranslate}}</label>
            <mat-form-field appearance="outline">
              <mat-select id="ecase_common_crm_person_province'"
                          [formControlName]="'province'"
                          (selectionChange)="onProvinceChange($event)"
                          [attr.aria-label]="'ecase.common.component.crmPersonSearch.form.province.label'| eCaseTranslate">
                <mat-option>{{'ecase.common.pleaseSelect' | eCaseTranslate }}</mat-option>
                <mat-option *ngFor="let p of  provinceArray" [value]="p.id">{{p.value[selectedLang]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <div>
            <label>{{'ecase.common.component.crmPersonSearch.form.position.label'| eCaseTranslate}}</label>
            <mat-form-field appearance="outline">
              <mat-select name="competition" id="ecase_common_crm_person_position'"
                          [formControlName]="'position'"
                          [attr.aria-label]="'ecase.common.component.crmPersonSearch.form.position.label'| eCaseTranslate">
                <mat-option>{{'ecase.common.pleaseSelect' | eCaseTranslate }}</mat-option>
                <mat-option *ngFor="let p of  positionLov" [value]="p.id">{{p.value[selectedLang]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <label>{{'ecase.common.component.crmPersonSearch.form.city.label'| eCaseTranslate}}</label>
            <mat-form-field appearance="outline" >
              <input autocomplete="off" id="ecase_common_crm_person_city" matInput
                     [formControlName]="'city'"
                     [attr.aria-label]="'ecase.common.component.crmPersonSearch.form.city.label'| eCaseTranslate">
            </mat-form-field>
          </div>

          <div>
            <label>{{'ecase.common.component.crmPersonSearch.form.organization.label'| eCaseTranslate}}</label>
            <mat-form-field class="mat-form-field" appearance="outline">
              <input matInput
                     [attr.aria-label]="'ecase.common.component.crmPersonSearch.form.organization.label' | eCaseTranslate"
                     [formControlName]="'organization'"
                     [matAutocomplete]="auto">
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.crmOrganizationId">
                  {{option.crmOrganizationLabel[selectedLang]}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

          </div>
          <div >
            <label>{{'ecase.common.component.crmPersonSearch.form.role.label'| eCaseTranslate}}</label>
            <mat-form-field appearance="outline">
              <mat-select [attr.aria-label]="'ecase.common.component.crmPersonSearch.form.role.label'| eCaseTranslate" [formControlName]="'role'"
                          id="ecase_adminDashboard_securityCenter_users_role'"
                          name="competition">
                <mat-option>{{'ecase.common.pleaseSelect' | eCaseTranslate }}</mat-option>
                <mat-option *ngFor="let role of  rolesList" [value]="role.ssoRoleId">{{role.ssoRoleLabel[selectedLang]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <div formGroupName="classification" class="classificationSet">
            <label style="display: block; margin-bottom: 5px;">{{'ecase.common.component.crmPersonSearch.form.orgClassification.label'| eCaseTranslate}}</label>
            <div style="display: flex; flex-direction: row; flex-wrap: wrap">
              <ng-container *ngFor="let classification of structureClassificationLov">
                <mat-checkbox style="font-size: 14px; width: 50%"
                              [formControlName]="classification.id"
                              id="ecase_common_crm_person_classification_{{classification.id}}"
                              [attr.aria-label]="'ecase.common.component.crmPersonSearch.form.orgClassification.label'| eCaseTranslate">
                  {{classification.value[selectedLang]}}
                </mat-checkbox>
              </ng-container>
            </div>
          </div>
          <div class="archivedRadioGroup">
            <label>{{'ecase.common.component.crmPersonSearch.form.isArchived.label'| eCaseTranslate}}</label>&nbsp;&nbsp;
            <mat-radio-group [formControlName]="'isArchived'"   id="ecase_common_crm_person_isArchived'" >
              <mat-radio-button value="A">
                {{'ecase.common.all' | eCaseTranslate}}
              </mat-radio-button> &nbsp;
              <mat-radio-button value="Y">
                {{'ecase.taxonomy.1.1' | eCaseTranslate:false}}
              </mat-radio-button> &nbsp;
              <mat-radio-button value="N">
                {{'ecase.taxonomy.1.2' | eCaseTranslate:false}}
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <div style="display: flex; flex-direction: column; margin-top: 15px;">
            <div>
              <label>{{'ecase.common.component.crmPersonSearch.form.program.label'| eCaseTranslate}}</label>
              <mat-form-field class="mat-form-field" appearance="outline">
                <input matInput
                       [attr.aria-label]="'ecase.common.component.crmPersonSearch.form.program.label' | eCaseTranslate"
                       [formControlName]="'program'"
                       [matAutocomplete]="auto2">
                <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" [displayWith]="displayFnForProgram.bind(this)"
                                  (optionSelected)="filterCompetitions($event.option.value)">
                  <mat-option *ngFor="let option of filteredOptionsForProgram | async" [value]="option.id">
                    {{option.programFullName[selectedLang]}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div>
              <label>{{'ecase.common.component.crmPersonSearch.form.competition.label'| eCaseTranslate}}</label>
              <mat-form-field appearance="outline">
                <mat-select id="Competition'"
                            [formControlName]="'competition'"
                            [attr.aria-label]="'ecase.common.component.crmPersonSearch.form.competition.label'| eCaseTranslate">
                  <mat-option>{{'ecase.common.pleaseSelect' | eCaseTranslate }}</mat-option>
                  <mat-option *ngFor="let co of competitionList" [value]="co.id">{{co.competitionFullName[selectedLang]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div style="margin-top: 15px;">
            <label>{{'ecase.adminDashboard.crm.person.application.status'| eCaseTranslate}}</label>
            <mat-form-field appearance="outline">
              <mat-select [attr.aria-label]="'ecase.adminDashboard.crm.person.application.status'| eCaseTranslate" [formControlName]="'status'"
                          id="ecase_adminDashboard_crm_person_application_status'"
                          name="status">
                <mat-option>{{'ecase.common.pleaseSelect' | eCaseTranslate }}</mat-option>
                <mat-option *ngFor="let status of statusList" [value]="status.id">{{status.value[selectedLang]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!--<div formGroupName="roles" class="classificationSet">-->
          <!--<label style="display: block; margin-bottom: 5px;">{{'ecase.common.component.crmPersonSearch.form.role.label'| eCaseTranslate}}****</label>-->
          <!--<div style="display: flex; flex-direction: row; flex-wrap: wrap">-->
          <!--<ng-container *ngFor="let role of roleList">-->
          <!--<mat-checkbox style="font-size: 14px; width: 50%"-->
          <!--[formControlName]="role.ssoRoleId"-->
          <!--id="ecase_common_crm_person_roles_{{role.ssoRoleId}}"-->
          <!--[attr.aria-label]="'ecase.common.component.crmPersonSearch.form.role.label'| eCaseTranslate">-->
          <!--{{role.ssoRoleLabel[selectedLang]}}-->
          <!--</mat-checkbox>-->
          <!--</ng-container>-->
          <!--</div>-->
          <!--</div>-->

          <!-- hidden button added here just to enable search on enter -->
          <button  (click)="searchCrmPerson()" style="display: none"
                   [disabled]="disableSearchBtn"
                   [attr.aria-label]="'ecase.common.component.crmPersonSearch.button.search.label' | eCaseTranslate"
                   color="primary">{{'ecase.common.component.crmPersonSearch.button.search.label' | eCaseTranslate }}
          </button>
        </form>
      </mat-card-content>
      <!--*eCaseNgIfWithSysActionIds="1261"-->
      <mat-card-actions fxLayoutAlign="center center" style="margin-bottom: 10px">
        <button
          mat-raised-button (click)="searchCrmPerson()" style="margin-right: 30px; width: fit-content"
          [disabled]="disableSearchBtn"
          id="ecase_common_crm_person_btnSearch"
          [attr.aria-label]="'ecase.common.component.crmPersonSearch.button.search.label' | eCaseTranslate"
          color="primary">{{'ecase.common.component.crmPersonSearch.button.search.label' | eCaseTranslate }}
        </button>
        <button mat-raised-button [disabled]="!searchCrmPersonForm?.dirty"
                id="ecase_common_crm_person_btnClear"
                [attr.aria-label]="'ecase.common.component.crmPersonSearch.button.clear.label' | eCaseTranslate" (click)="clear()" class="clearBtnStyles">
          {{'ecase.common.component.crmPersonSearch.button.clear.label' | eCaseTranslate }}
        </button>

      </mat-card-actions>


    </div>


    <!--start logic  for the  table -->


    <div *ngIf="displayResults">
      <mat-divider></mat-divider>
      <br>
      <span>{{dataSource.data.length }} {{'ecase.common.table.numberOfEntryFound' | eCaseTranslate}}</span>
      <mat-card-content class="responsive-table-style">
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
          <div  class="action_buttons">
            <button mat-raised-button (click)="addPeople()" [disabled]="usersSelected.length == 0"
                    class="eCase-dialog-positive-action-button"
                    [attr.aria-label]="'ecase.common.component.crmPersonSearch.table.button.add.label' | eCaseTranslate"
            >{{'ecase.common.component.crmPersonSearch.table.button.add.label' | eCaseTranslate }}
            </button>
            <button mat-raised-button (click)="back()"
                    class="eCase-dialog-negative-action-button"
                    [attr.aria-label]="'ecase.common.component.crmPersonSearch.table.button.cancel.label' | eCaseTranslate"
            >{{'ecase.common.component.crmPersonSearch.table.button.cancel.label' | eCaseTranslate }}
            </button>
          </div>
        </mat-dialog-actions>
        <br>
        <table mat-table class="ecase-mat-table resutTextStyle" id="crm-search-id-for-toggling"
               [dataSource]="dataSource"
               [@animateStagger]="{value:'50'}"
               matSort
               matSortActive="{{sortActive}}"
               (matSortChange)= "sortColumn($event)"
               matSortDirection="{{sortDirection}}" >

          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <u>{{'ecase.common.component.crmPersonSearch.table.column.select.label' | eCaseTranslate }}</u>
            </th>
            <td mat-cell *matCellDef="let row"
                [title]="'ecase.common.component.crmPersonSearch.table.column.select.label' | eCaseTranslate">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? onItemSelected($event, row) : null"
                            [checked]="row.selected"
                            [aria-label]="row.name"><span class='nodisplay'>.</span>
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="fullName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <u>{{'ecase.common.component.crmPersonSearch.table.column.name.label' | eCaseTranslate }}</u>
            </th>
            <td mat-cell *matCellDef="let row" class="tdStyle"
                [title]="'ecase.common.component.crmPersonSearch.table.column.name.label' | eCaseTranslate">
              <!--<a (click)="onItemSelected(row);">{{row.fullName}}</a>-->
              {{row.fullName}}
            </td>
          </ng-container>

          <ng-container matColumnDef="mainAffiliationOrganizationLabel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header><u>
              {{'ecase.common.component.crmPersonSearch.table.column.mainAffiliation.label' | eCaseTranslate }}</u>
            </th>
            <td mat-cell *matCellDef="let row" style="padding: 5px 0 5px 0;"
                [title]="'ecase.common.component.crmPersonSearch.table.column.mainAffiliation.label' | eCaseTranslate">
              <span>{{ row.mainAffiliationOrganizationLabel[selectedLang]}}</span>

            </td>
          </ng-container>

          <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef mat-sort-header><u>
              {{'ecase.common.component.crmPersonSearch.table.column.username.label' | eCaseTranslate }}</u>
            </th>
            <td mat-cell *matCellDef="let row"
                [title]="'ecase.common.component.crmPersonSearch.table.column.username.label' | eCaseTranslate">
              {{row.username}}
            </td>
          </ng-container>

          <ng-container matColumnDef="lastUpdateDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header><u>
              {{'ecase.common.component.crmPersonSearch.table.column.updateDate.label' | eCaseTranslate }}</u>
            </th>
            <td mat-cell *matCellDef="let row" class="tdStyle"
                [title]="'ecase.common.component.crmPersonSearch.table.column.updateDate.label' | eCaseTranslate">
              {{ row.lastUpdateDate | eCaseDateFormatter}}
            </td>
          </ng-container>

          <ng-container matColumnDef="lastUpdateBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header><u>
              {{'ecase.common.component.crmPersonSearch.table.column.updatedBy.label' | eCaseTranslate }}</u>
            </th>
            <td mat-cell *matCellDef="let row" class="tdStyle"
                [title]="'ecase.common.component.crmPersonSearch.table.column.updatedBy.label' | eCaseTranslate">
              {{ row.lastUpdateBy}}
            </td>
          </ng-container>

          <ng-container class="filter_row_first_cell mat-elevation-z4" matRipple matColumnDef="header-row-first-group">
            <th mat-header-cell *matHeaderCellDef class="mat-table_filtered_header_cell">
              <span class='nodisplay'>.</span>
              <input type="image" *ngIf="showImage" disabled
                     matRipple class="row_filter_icon"
                     src="../../../../assets/images/etc/icons8-conversion.svg" alt="clear">
              <input type="image" *ngIf="!showImage" class="row_filter_icon"
                     src="../../../../assets/images/etc/icons8-clear-filters.svg" alt="clear" (click)="resetFilters(true)">
              <span class='nodisplay'>.</span>
              <mat-checkbox style="padding-left: 4px;" (change)="$event ? masterToggle() : null"
                            id="ecase_competitions_program_select"
                            [checked]="selection.hasValue()"
                            [indeterminate]="selection.hasValue()"
                            [aria-label]="'ecase.common.table.checkbox.selectAll.label' | eCaseTranslate"><span class='nodisplay'>.</span></mat-checkbox>

            </th>
          </ng-container>

          <ng-container matColumnDef="header-row-second-group">
            <th mat-header-cell *matHeaderCellDef class="mat-table_filtered_header_cell" >
              <span class='nodisplay'>.</span>
              <input type="text"  class="searchFields"
                     id="ecase_common_crm_person_fullName"
                     [attr.aria-label]="'ecase.common.component.crmPersonSearch.table.column.name.label' | eCaseTranslate"
                     [(ngModel)]="filterObject.fullName"
                     [placeholder]="'ecase.common.table.filter.input.placeholder' | eCaseTranslate"
                     (keyup)="applyFilter()">
            </th>
          </ng-container>

          <!-- Header row second group -->
          <ng-container matColumnDef="header-row-third-group">
            <th mat-header-cell *matHeaderCellDef class="mat-table_filtered_header_cell" >
              <span class='nodisplay'>.</span>
              <input type="text" class="searchFields"
                     id="ecase_common_crm_person_mainAffiliation"
                     [attr.aria-label]="'ecase.adminDashboard.crm.person.mainAffiliation' | eCaseTranslate"
                     [(ngModel)]="filterObject.mainAffiliationOrganizationLabel"
                     [placeholder]="'ecase.common.table.filter.input.placeholder' | eCaseTranslate"
                     (keyup)="applyFilter()">
            </th>
          </ng-container>

          <ng-container matColumnDef="header-row-four-group">
            <th mat-header-cell *matHeaderCellDef class="mat-table_filtered_header_cell" ><span class='nodisplay'>.</span>
              <input type="text" class="searchFields"
                     id="ecase_common_crm_person_username"
                     [attr.aria-label]="'ecase.common.component.crmPersonSearch.table.column.username.label' | eCaseTranslate"
                     [(ngModel)]="filterObject.username"
                     [placeholder]="'ecase.common.table.filter.input.placeholder' | eCaseTranslate"
                     (keyup)="applyFilter()">
            </th>
          </ng-container>

          <ng-container matColumnDef="header-row-five-group">
            <th mat-header-cell *matHeaderCellDef class="mat-table_filtered_header_cell"><span class='nodisplay'>.</span>
              <input type="text" class="searchFields"
                     id="ecase_common_crm_person_updateDate"
                     [attr.aria-label]="'ecase.common.component.crmPersonSearch.table.column.updateDate.label' | eCaseTranslate"
                     [(ngModel)]="filterObject.lastUpdateDateFormatted"
                     [placeholder]="'ecase.common.table.filter.input.placeholder' | eCaseTranslate"
                     (keyup)="applyFilter()">
            </th>
          </ng-container>


          <ng-container matColumnDef="header-row-six-group">
            <th mat-header-cell *matHeaderCellDef class="mat-table_filtered_header_cell" ><span class='nodisplay'>.</span>
              <input type="text" class="searchFields"
                     id="ecase_common_crm_person_updatedBy"
                     [attr.aria-label]="'ecase.common.component.crmPersonSearch.table.column.updatedBy.label' | eCaseTranslate"
                     [(ngModel)]="filterObject.lastUpdateBy"
                     [placeholder]="'ecase.common.table.filter.input.placeholder' | eCaseTranslate"
                     (keyup)="applyFilter()">
            </th>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-header-row *matHeaderRowDef="['header-row-first-group','header-row-second-group',
      'header-row-third-group', 'header-row-four-group','header-row-five-group',
      'header-row-six-group']"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <!--  it was in the mat-paginator    -->
        <mat-paginator *ngIf="originalArray.length > 0"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions"
                       [length]=originalArray.length
                       showFirstLastButtons ></mat-paginator>

        <span>{{dataSource.data.length }} {{'ecase.common.table.numberOfEntryFound' | eCaseTranslate}}</span>

        <mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
          <div  class="action_buttons">
            <button mat-raised-button (click)="addPeople()"  [disabled]="usersSelected.length == 0"
                    class="eCase-dialog-positive-action-button"
                    [attr.aria-label]="'ecase.common.component.crmPersonSearch.table.button.add.label' | eCaseTranslate"
            >{{'ecase.common.component.crmPersonSearch.table.button.add.label' | eCaseTranslate }}
            </button>
            <button mat-raised-button (click)="back()"
                    class="eCase-dialog-negative-action-button"
                    [attr.aria-label]="'ecase.common.component.crmPersonSearch.table.button.cancel.label' | eCaseTranslate"
            >{{'ecase.common.component.crmPersonSearch.table.button.cancel.label' | eCaseTranslate }}
            </button>
          </div>
        </mat-dialog-actions>

      </mat-card-content>
    </div>

  </ng-container>
</ng-container>
