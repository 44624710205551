<div>
  <table class="angular-table">
    <thead class="angular-table-thead">
    <tr class="angular-table-tr">
      <th *ngFor="let displayedColumn of displayedColumnsI18nCodes;let i = index" class="angular-table-th"
          style="padding-left: 50px !important;">
        <span>{{displayedColumn | translate}}
          {{(isPropertyEnabled(displayedColumns[i], 'isDateField') ? getDataFormat(displayedColumns[i]) : "")}}
        </span>
      </th>
      <th *ngIf="isActionsEnabled" class="angular-table-th"
          style="padding-left: 50px !important;padding-right: 20px !important;">
        {{'ecase.common.action' | translate}}
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-template #recursiveList let-globalIndex="index" let-list>
      <ng-container *ngFor="let row of list;let i = index">
        <tr class="angular-table-tr">
          <td *ngFor="let displayedColumn of displayedColumns;let j = index"
              [ngClass]="(j === 0 ? 'alignLeft' : '')"
              [style.padding-left.px]="(50 + ((j === 0 && row.isChild) ?  (15 * (row.level + (row.level === 1 ? 1 : 2))) : 0))"
              [title]="displayedColumnsI18nCodes[j] | translate"
              class="angular-table-td">
            <!--              [ngClass]="j === 1 ? 'textAlignLeft' : ''"-->

            <ng-container *ngIf="displayedColumn !== 'actions'">
              <button (click)="toggleChildren(row)" *ngIf="row.children && row.children.length > 0 && j === 0"
                      id="{{ 'tree_table_keyboard_arrow_' + '_' + i}}"
                      mat-icon-button style="margin-left: -45px">
                <mat-icon>{{row.isChildrenVisible ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</mat-icon>
              </button>
            </ng-container>
            <ng-container *ngIf="displayedColumn !== 'actions'">
              <ng-container
                *ngIf="isPropertyEnabled(displayedColumn, 'isHyperLink')">
                <a (click)="onHyperLinkClick(i, displayedColumn, row)"
                   id="{{ 'hyper_link' + displayedColumn + '_' + i + '_' + j}}">
                  <span *ngIf="isObject(row[displayedColumn])">{{row[displayedColumn][selectedLanguage]}}</span>
                  <span
                    *ngIf="!isObject(row[displayedColumn])">{{isNumeric(row[displayedColumn]) ? convertNumberToNumericFormat(row[displayedColumn], displayedColumn) : row[displayedColumn]}}</span>
                </a>
              </ng-container>
              <ng-container
                *ngIf="isPropertyEnabled(displayedColumn, 'isDateField')">
                <span>{{convertToDate(row[displayedColumn], displayedColumn)}}</span>
              </ng-container>
              <ng-container
                *ngIf="isPropertyEnabled(displayedColumn, 'isCheckBoxEnabled') && isShownInChildrenOnly(displayedColumn, 'isCheckBoxEnabled', row)">
                <mat-checkbox (change)="onCheckBoxChange($event, i, displayedColumn, row)"
                              [checked]="(row[displayedColumn] === true || row[displayedColumn][selectedLanguage] === yesValue)"
                              [disabled]="getDisplayedColumnsPropertyValue(displayedColumn, 'isCheckBoxDisabled')"
                              aria-label="{{(displayedColumnsI18nCodes[j] | translate) + '_' + i + '_' + j}}"
                              style="color: transparent"><span style="display: none">c</span>
                </mat-checkbox>
                <!--id="{{ 'tree_table_checkbox_' + displayedColumn + '_' + i + '_' + j + '_' + getNodeLevel(row)}}"-->
              </ng-container>
              <ng-container
                *ngIf="isPropertyEnabled(displayedColumn, 'isTextField') && isShownInChildrenOnly(displayedColumn, 'isTextField', row)">
                <div>
                  <mat-form-field appearance="outline">
                    <input [attr.aria-label]="'tree_table_input_' + displayedColumn + i + '_' + j" (input)="onInputFieldChange($event, i, displayedColumn, row)"
                           autocomplete="off" [ngModel]="row[displayedColumn]"
                           [type]="getDisplayedColumnsPropertyValue(displayedColumn, 'type')"
                           [disabled]="getDisplayedColumnsPropertyValue(displayedColumn, 'isTextFieldDisabled')"
                           [maxLength]="getDisplayedColumnsPropertyValue(displayedColumn, 'maxLength')"
                           matInput>
                  </mat-form-field>
                  <div class="field">
                    <p class="counter-style">{{getMaxLengthForInputField(displayedColumn, row)}} / {{getDisplayedColumnsPropertyValue(displayedColumn, 'maxLength')}}</p>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="!isPropertyEnabled(displayedColumn, 'isCheckBoxEnabled') &&
                !isPropertyEnabled(displayedColumn, 'isHyperLink') &&
                !isPropertyEnabled(displayedColumn, 'isDateField') &&
                !isPropertyEnabled(displayedColumn, 'isTextField')">
                <span *ngIf="isObject(row[displayedColumn])">{{row[displayedColumn][selectedLanguage]}}</span>
                <span
                  *ngIf="!isObject(row[displayedColumn])">{{isNumeric(row[displayedColumn]) ? convertNumberToNumericFormat(row[displayedColumn], displayedColumn) : row[displayedColumn]}}</span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="displayedColumn === 'actions'">
              <button (click)="moveRow(list, i, 1,row); $event.stopPropagation()"
                      [disabled]="i === (list.length - 1)"
                      [ngStyle]="{'opacity': i === (list.length - 1) ? '0.58' : '1' }"
                      attr.aria-label="{{'ecase.common.down' | translate}}"
                      class="mat-icon-button"
                      id="{{ 'tree_table_action_down_' + displayedColumn + '_' + i + '_' + j}}"
                      mat-icon-button
                      matTooltip="{{'ecase.common.down' | translate}}">
                <mat-icon>arrow_downward</mat-icon>
              </button>
              <button (click)="moveRow(list, i, -1,row); $event.stopPropagation()"
                      [disabled]="i === 0" [ngStyle]="{'opacity': i === 0 ? '0.58' : '1' }"
                      attr.aria-label="{{'ecase.common.up' | translate}}"
                      class="mat-icon-button"
                      id="{{ 'tree_table_action_up_' + displayedColumn + '_' + i + '_' + j}}" mat-icon-button
                      matTooltip="{{'ecase.common.up' | translate}}">
                <mat-icon>arrow_upward</mat-icon>
              </button>
              <button (click)="editRow(row)" attr.aria-label="{{'ecase.common.edit'| translate}}"
                      id="{{ 'tree_table_edit' + displayedColumn + '_' + i}}"
                      mat-icon-button
                      matTooltip="{{'ecase.common.edit' | translate}}">
                <mat-icon>edit</mat-icon>
              </button>
              <button (click)="addRow(row)" attr.aria-label="{{'ecase.common.addsublevel'| translate}}"
                      id="{{ 'tree_table_addsublevel' + displayedColumn + '_' + i}}"
                      mat-icon-button
                      matTooltip="{{'ecase.common.addsublevel' | translate}}">
                <mat-icon>add</mat-icon>
              </button>
            </ng-container>
          </td>
          <td *ngIf="isActionsEnabled" [title]="'ecase.common.action'| translate" class="angular-table-td">
            <button #menuTrigger="matMenuTrigger" (click)="menuTrigger.openMenu();$event.stopPropagation()"
                    [matMenuTriggerFor]="menu" aria-label="More" class="mat-icon-button"
                    id="{{ 'tree_table_more' + '_' + i}}"
                    mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <ng-container *ngFor="let item of actions">
                <ng-container *ngIf="item.actionId">
                  <button (click)="triggerAction(row, item.action, i)" *eCaseNgIfWithSysActionIds="item.actionId"
                          [disabled]="item.isDisabled"
                          id="{{ 'tree_table_action_' + item.idForAction + '_' + i}}"
                          mat-menu-item>
                    {{item.action | eCaseTranslate}}
                  </button>
                </ng-container>
                <ng-container *ngIf="!item.actionId">
                  <button (click)="triggerAction(row, item.action, i)"
                          [disabled]="item.isDisabled"
                          id="{{ 'tree_table_action_' + item.idForAction + '_' + i}}" mat-menu-item>
                    {{item.action | eCaseTranslate}}
                  </button>
                </ng-container>
              </ng-container>
            </mat-menu>
          </td>
        </tr>
        <ng-container *ngIf="row.isChildrenVisible && row.children && row.children.length > 0">
          <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: row.children }"></ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
    <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: _dataSource }"></ng-container>
    </tbody>
  </table>


</div>
