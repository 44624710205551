<mat-dialog-content>
  <mat-card-title>{{'ecase.common.signers'| eCaseTranslate}}</mat-card-title>
  <div style="width: 100%">
    <button *eCaseNgIfWithSysActionIdsCustom="[1301]"
            mat-raised-button style="float: right;margin-right: 0!important;"
            class="eCase-dialog-positive-action-button" (click)="addNewSigner()"
            [disabled]="isReadOnlyModeEnabled || isAllSignersSigned()"
    >{{'ecase.common.addnewsigner'| eCaseTranslate}}
    </button>
  </div>

  <table mat-table class="ecase-mat-table"
         style="margin-top: 0px"
         id="ecase_e_signature_table"
         [dataSource]="dataSource"
         [@animateStagger]="{value:'50'}"
         matSortActive="{{sortActive}}"
         (matSortChange)="sortColumn($event)"
         matSortDirection="{{sortDirection}}"
         matSort>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><u>{{'ecase.common.email' | translate}}</u>
      </th>
      <ng-container *matCellDef="let row">
        <td mat-cell [title]="'ecase.common.email' | translate">
          {{row.email}}
        </td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><u>{{'ecase.common.name' |
        translate}}</u></th>
      <ng-container *matCellDef="let row">
        <td mat-cell [title]="'ecase.common.name' | translate">
          {{row.name}}
        </td>
      </ng-container>
    </ng-container>
    <!-- Position Column -->

    <ng-container matColumnDef="formattedSignatureDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><u>
        {{'ecase.common.signatureDate' |translate}} <br> ({{'ecase.defaultDatetimeWithMinutes' | translate}})</u></th>
      <ng-container *matCellDef="let row">
        <td mat-cell [title]="'ecase.common.signatureDate' | translate">
          {{row.formattedSignatureDate}}
        </td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef><span class='nodisplay'>.</span>
      </th>
      <ng-container *matCellDef="let row">
        <td mat-cell [title]="'ecase.common.table.action' | eCaseTranslate">
          <button (click)="menuTrigger.openMenu();$event.stopPropagation()"
                  #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"
                  class="mat-icon-button eCase-in-table-mat-button"
                  id="ecase_e_signature_table_delete"
                  aria-label="{{'ecase.common.table.actionMore'| eCaseTranslate}}"
                  matTooltip="{{'ecase.common.table.actionMore'| eCaseTranslate}}">
            <mat-icon>more_vert</mat-icon>
            <span>{{ 'ecase.common.table.action' | eCaseTranslate }}</span>
          </button>
          <mat-menu #menu="matMenu">
            <button *eCaseNgIfWithSysActionIdsCustom="[1301]"
                    mat-menu-item
                    [disabled]="isReadOnlyModeEnabled || row.isSignatureGiven"
                    (click)="delete(row)"
                    id="eCase_adminDashboard_crm.organization_action_edit">
              {{'ecase.common.delete' | eCaseTranslate}}
            </button>

          </mat-menu>
        </td>
      </ng-container>
    </ng-container>

    <!-- Header row first group -->
    <ng-container class="filter_row_first_cell mat-elevation-z4" matRipple matColumnDef="header-row-first-group">
      <th mat-header-cell *matHeaderCellDef
          class="mat-table_filtered_header_cell">
        <span class='nodisplay'>.</span>
        <input type="image" *ngIf="showImage" disabled
               matRipple class="row_filter_icon"
               src="../../../../assets/images/etc/icons8-conversion.svg" alt="clear">
        <input type="image" *ngIf="!showImage" class="row_filter_icon"
               src="../../../../assets/images/etc/icons8-clear-filters.svg" alt="clear" (click)="resetFilters(true)">
        <input type="text" class="searchFields"
               id="ecase_e_signature_table_name_filter"
               [attr.aria-label]="'ecase.common.name' | eCaseTranslate"
               [(ngModel)]="filterObject.name"
               [placeholder]="'ecase.common.search' | eCaseTranslate"
               (keyup)="applyFilter()">
      </th>
    </ng-container>

    <ng-container matColumnDef="header-row-second-group">
      <th mat-header-cell *matHeaderCellDef class="mat-table_filtered_header_cell"><span class='nodisplay'>.</span>
        <input type="text" class="searchFields"
               id="ecase_e_signature_table_email_filter"
               [attr.aria-label]="'ecase.common.email' | eCaseTranslate"
               [(ngModel)]="filterObject.email"
               [placeholder]="'ecase.common.search' | eCaseTranslate"
               (keyup)="applyFilter()">
      </th>
    </ng-container>

    <ng-container matColumnDef="header-row-third-group">
      <th mat-header-cell *matHeaderCellDef class="mat-table_filtered_header_cell"><span class='nodisplay'>.</span>
        <input type="text" class="searchFields"
               id="ecase_e_signature_table_date_filter"
               [attr.aria-label]="'ecase.common.signatureDate' | eCaseTranslate"
               [(ngModel)]="filterObject.formattedSignatureDate"
               [placeholder]="'ecase.common.search' | eCaseTranslate"
               (keyup)="applyFilter()">
      </th>
    </ng-container>

    <ng-container matColumnDef="header-row-fourth-group">
      <th mat-header-cell *matHeaderCellDef class="mat-table_filtered_header_cell"><span class='nodisplay'>.</span>

      </th>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-header-row *matHeaderRowDef="['header-row-first-group',
      'header-row-second-group', 'header-row-third-group','header-row-fourth-group']"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

  <mat-paginator *ngIf="signers.length > 0" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                 showFirstLastButtons></mat-paginator>
</mat-dialog-content>
<mat-dialog-actions>
  <div style="width: 100%">
    <button style="float: right" class="eCase-dialog-negative-action-button" (click)="onClose()"
            mat-button>
      {{'ecase.common.close' | translate }}
    </button>
  </div>
</mat-dialog-actions>
